import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Divider } from '@mui/material';
import { Close, DeleteForeverRounded, FileOpenRounded, PrintRounded, Check } from '@mui/icons-material';

export default function SavedPatternDialog(props) {
  const [openAreYouSureDeletePattern, setOpenAreYouSure] = React.useState(false);

function handleOpenAreYouSureDeletePattern() {
  setOpenAreYouSure(true);
}

function handleCloseAreYouSureDeletePattern() {
  setOpenAreYouSure(false);
}

    return (
      <div>
        <Dialog
        disableScrollLock={true}
                  open={props.openSavedPattern}
                  /* onClose={props.handleCloseSavedPatternPreview} */
                  keepMounted
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle /* dividers={true} */ sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}><div style={{display: 'flex', alignItems:'center', justifyContent:'space-between'}}>{"Mønster Oversigt"}</div><Button startIcon={<Close/>} onClick={props.handleCloseSavedPatternPreview}><span style={{marginTop: '2px'}}>luk</span></Button></DialogTitle>
                  <DialogContent dividers={true} sx={{padding: '16px 46px'}}>
                    <div className='savedPatternPreviewImageWrapper'>
                        {props.savedPatternPreviewData.length!==0 ? <img className='savedPatternPreviewImage' src={props.openSavedPattern ? props.savedPatternPreviewData.photoUrl : 'none'} alt=''/> : <div></div>}
              
              </div>
              <table className='infoTableSavedPattern' style={{margin: 'auto'}}>
                          <tbody>

                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>Antal korssting:</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{props.savedPatternPreviewData.totalStitches}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDividerSavedPattern' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>Bredden i korssting:</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{props.savedPatternPreviewData.stitchWidth}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDividerSavedPattern' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>Højden i korssting:</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{props.savedPatternPreviewData.stitchHeight}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDividerSavedPattern' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>Broderistof:</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{props.savedPatternPreviewData.fabric}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDividerSavedPattern' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>Bredden i cm:</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{props.savedPatternPreviewData.cmWidth}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDividerSavedPattern' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>Højden i cm:</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{props.savedPatternPreviewData.cmHeight}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDividerSavedPattern' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>Antal farver:</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{props.savedPatternPreviewData.colorCount}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDividerSavedPattern' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>Antal A4 sider til mønsteret:</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{props.savedPatternPreviewData.a4Pages}</td>
                            </tr>

                          </tbody>
                        </table>
                  </DialogContent>
                  <div className='savedPatternsTableActionButtons'>
                  <Button onClick={handleOpenAreYouSureDeletePattern}  startIcon={<DeleteForeverRounded/>}  className='no-print' size='large' variant="outlined" >Slet</Button>
            <Button  startIcon={<PrintRounded/>}  className='no-print' size='large' variant="contained" sx={{transition: 'box-shadow 150ms ease-in 0s', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>Print/Download</Button>
            
                  </div>
                </Dialog>

                <Dialog
              disableScrollLock={true}
              open={openAreYouSureDeletePattern}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle /* dividers={true} */ sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: '400'}}>Er du sikker?</DialogTitle>
              <DialogContent dividers={true} >
                <Typography gutterBottom variant="h7" align='center' sx={{ float: 'left' }}>Er du sikker på du vil slette dette mønster?<br></br>Denne handling kan ikke laves om</Typography>
              </DialogContent >
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button startIcon={<Close />} onClick={handleCloseAreYouSureDeletePattern}>Nej</Button>
                <Button startIcon={<Check />} onClick={()=>{let arr = []; arr.push(props.savedPatternPreviewData); handleCloseAreYouSureDeletePattern(); props.handleDeletePatterns(arr)}}>Ja</Button>
              </DialogActions>
            </Dialog>
                </div>
          
    );
  }