import React from "react";
import { Typography } from "@mui/material";
import { Link } from "@mui/material";

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{m: 'auto', bottom: '0px', width: '100%' }}>
        {'Copyright © '}
        <Link color="inherit" href="">
          Tobias Schou Svenninggaard
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography> 
    );
  }

  export default Copyright