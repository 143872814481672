import { Card } from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Box, Avatar, Typography, Container } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
export default function Policies(props) {
  const {t, i18n} = useTranslation('translation');
    const navigate = useNavigate();
    React.useEffect(() => {
      window.scrollTo(0,0);
    }, []);
    return (
        <Container component="main" sx={{position: 'unset', transform: 'unset', paddingTop: '40px', paddingBottom: '40px', height:'100vh', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card sx={{boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', maxWidth: '556px', padding: '0px 20px 50px 20px', margin: 'auto'}}>  
            <Button onClick={()=>{navigate(-1)}} startIcon={<ArrowBack/>} size='large' sx={{float: 'left',transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '0px' }}>{t('common.back_button')}</Button>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            {props.icon}
          </Avatar>
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>

          <Box component="form" noValidate sx={{ mt: 3, width: 'calc(100% - 37px)' }}>
            {props.sections.map((section)=>(
              <div key={section}>
          <Typography align='left' component="div" variant="h6">
          {section.title}
          
          </Typography>
          {section.text.map((textsection, i)=>(
            <Typography key={textsection} sx={{mt: '5px', mb: section.text.length===i+1 ? '14px' : '0px'}} align='left' color="text.secondary" component="div" variant="h7">
            {textsection}
            </Typography>
          ))}
            </div>
            ))}

          </Box>
        </Box>
        </Card>
          </Container>
          
    );
  }