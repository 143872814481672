import React, { Fragment } from "react";
import { Typography } from "@mui/material";
import './learn/HeadSection.css';
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ImportContactsRounded } from "@mui/icons-material";
// import { Card } from "@mui/material";
// import { Image } from "@mui/icons-material";
// import { Crop } from "@mui/icons-material";
// import { Edit } from "@mui/icons-material";
// import Download from "@mui/icons-material/Download";

function HeadSection(props) {
  const {t, i18n} = useTranslation('translation');
  const navigate = useNavigate();

  const handleGoToLogin = event => {
    navigate("/login");
  }

  const handleGoToCreate = event => {
    navigate("/create");
  }

  const handleGoToCatalog = event => {
    navigate("/catalog");
  }

  const handleGoToSignup = event => {
    navigate("/signup");
  }

  const handleGoToFreeMode = event => {
    navigate("/createfree");
  }
  return (
    <Fragment>
      <div className='headsection'>
      <ul className="cb-slideshow">
	<li>
		<span>Image 01</span>
	</li>
	<li>		<span>Image 01</span></li>
	<li>		<span>Image 01</span></li>
  <li>		<span>Image 01</span></li>
  <li>		<span>Image 01</span></li>
</ul>
      {/* <div class="slider">
        <div class="slide slide1">
        </div>
        <div class="slide slide2">
        </div>
        <div class="slide slide3">
        </div>
        <div class="slide slide4">
        </div>
        <div class="slide slide1">
        </div>
    </div> */}
        {/* <div id="bgslider">
      <figure>
        <img src={require('./images/slide1.jpg')} alt="Slider" />
        <img src={require('./images/slide2.jpg')} alt="Slider" />
        <img src={require('./images/slide3.jpg')} alt="Slider" />
        <img src={require('./images/slide4.jpg')} alt="Slider" />
        <img src={require('./images/slide1.jpg')} alt="Slider" />
    </figure>
    </div> */}
        <div className='headsectionWrapper' style={{width: 'inherit',position: 'absolute', top: props.isLoggedIn ? '356px' : '275px', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <div className="overskriftHjemWrapper" style={props.isLoggedIn ? {} : {maxWidth: '700px'}}>
      <div className="embrodiaOverskrift">
        <h1 className="korsstingOverskiftHjem">EMBRODIA</h1>
        {/* <Typography gutterBottom variant="h2" component="div" sx={{m:0}}>fabrikken</Typography> */}
        </div>
        <div className="marieSytrådWrapper">
        <div className="marieSytråd" style={props.isLoggedIn ? {} : {left: '-354px'}}></div>
        </div>
        <div className="headsectionText">
        <Typography className="homeSubtext" sx={props.isLoggedIn ? {mt: '28px'}: {m: 'auto',mt: '28px', padding: '0px 130px'}} variant="h5" color="text.secondary" component="p">{t('home.title_description')}</Typography>
        </div>
        {props.isLoggedIn ? 
        
        <div>
        <div className="homeButtons">
            <Button
            size='large'
            variant="contained"
            onClick={handleGoToCreate}
            sx={{ fontSize: '1.1rem',padding: '11px 54px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}  }}
          >
            {t('home.button_logged_in_start')}
          </Button>
          
          </div>
          <div className="homeButtons" style={{marginTop: "32px"}}>
          <Button
          startIcon={<ImportContactsRounded/>}
            size='large'
            variant="outlined"
            onClick={handleGoToCatalog} 
            sx={{fontSize: '1.1rem', padding: '11px 31px' }}
          >
           {t('home.button_pattern_catalog')}
          </Button>
          </div>
          </div>
        
        : 
        
        
        <div>
        <div className="homeButtons">
            <Button
            size='large'
            variant="contained"
            onClick={handleGoToLogin}
            sx={{ fontSize: '1.1rem',padding: '11px 54px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}  }}
          >
            {t('common.button_sign_in')}
          </Button>
          <Button
            size='large'
            variant="outlined"
            onClick={handleGoToSignup}
            sx={{fontSize: '1.1rem', padding: '11px 31px' }}
          >
            {t('common.button_create_profile')}
          </Button>
          <Button
            size='large'
            variant="outlined"
            onClick={handleGoToFreeMode}
            sx={{ fontSize: '1.1rem',padding: '11px 24px', borderStyle: 'dashed', '&:hover': {borderStyle: 'dashed' } /* backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}   */}}
          >
            {t('common.button_free')}
          </Button>
          
          </div>
          <div className="homeButtons" style={{marginTop: "32px"}}>
          <Button
          startIcon={<ImportContactsRounded/>}
            size='large'
            variant="outlined"
            onClick={handleGoToCatalog} 
            sx={{fontSize: '1.1rem', padding: '11px 31px' }}
          >
            {t('home.button_pattern_catalog')}
          </Button>
          </div>
          </div>
        }
        
          </div>
          {/* <div class="displayWrapper">
          <Card sx={{boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', maxWidth: '556px', padding: '30px', margin: 'auto'}}>  
          <div class="sådanFungererDetOverskrift">
          <Typography variant="h5" >Sådan fungerer det</Typography>
          <Divider sx={{width: '175px', m: '15px auto'}}></Divider>
          </div>
          <div class="sådanFungererDetWrapper">
              {/* <div class="sådanFungererDetVenstre">
                <Typography variant="h6" sx={{mb: '10px', height: '67px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>1</Typography>
                <Typography variant="h6" sx={{mb: '10px', height: '67px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>2</Typography>
                <Typography variant="h6" sx={{mb: '10px', height: '67px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>3</Typography>
                <Typography variant="h6" sx={{mb: '10px', height: '67px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>4</Typography>
              </div> 
              <div class="sådanFungererDetHøjre">
              <div class="sådanFungererDetItem">
                <Image sx={{color: '#979797', fontSize: '2rem'}}/>
                <Typography color="text.secondary" sx={{fontSize: '0.9rem', lineHeight: '1'}}>1. Vælg billede</Typography>
                </div>
                <div class="sådanFungererDetItem">
                <Crop sx={{color: '#979797', fontSize: '2rem'}}/>
                <Typography color="text.secondary" sx={{fontSize: '0.9rem', lineHeight: '1'}}>2. Beskær</Typography>
                </div>
                <div class="sådanFungererDetItem">
                <Edit sx={{color: '#979797', fontSize: '2rem'}}/>
            <Typography color="text.secondary" sx={{fontSize: '0.9rem', lineHeight: '1'}}>3. Rediger</Typography>
            </div>
            <div class="sådanFungererDetItem" style={{marginBottom:'0'}}>
            <Download sx={{color: '#979797', fontSize: '2rem'}}/>
            <Typography color="text.secondary" sx={{fontSize: '0.9rem', lineHeight: '1'}}>4. Gem mønster</Typography>
            </div>
              </div>
            </div>
          </Card>
          </div> */}
          </div>
      </div>
    </Fragment>
  );
}

export default HeadSection
