import { HomeRounded } from '@mui/icons-material';
import { Button, Container, Typography } from '@mui/material';
import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
export default function NotFoundPage(props) {

  const {t, i18n} = useTranslation('translation')
  const navigate = useNavigate();

    return (
        <React.Fragment>
          <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, display: 'block', justifyContent: 'center' }}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                        sx={{mr: '12px'}}
                    >
                       {t('404.title')}
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" component="p"
                        sx={{mr: '12px'}}
                    >
                        {t('404.description_1')}<br/>{t('404.description_2')}
                    </Typography> 
                </Container>
                <div className="homeButtons">
            <Button
            size='large'
            variant="contained"
            onClick={()=>{navigate('/home')}}
            startIcon={<HomeRounded/>}
            sx={{ fontSize: '1.1rem',padding: '11px 54px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}  }}
          >
            {t('404.button')}
          </Button>
          
          </div>
          </React.Fragment>
          
    );
  }