import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

export default function CheckboxCategoryList(props) {
  const {t, i18n} = useTranslation('translation');
  const categoryTranslator = {
    "Icon":   t('catalog.category_icon'),
    "Humor":  t('catalog.category_humor'),
    "Text":   t('catalog.category_text'),
    "Photo":  t('catalog.category_photo'),
    "Movie":  t('catalog.category_movie'),
    "Gaming": t('catalog.category_gaming'),
    "Music":  t('catalog.category_music'),
    "Art":    t('catalog.category_art'),
    "Square": t('catalog.category_square'),
    "Xmas":   t('catalog.category_xmas')
  }

  const handleToggle = (value) => () => {
    const currentIndex = props.checked.indexOf(value);
    const newChecked = [...props.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    props.selectCategory(value);
    props.setChecked(newChecked);
  };

  return (
    <List className='categoryClass' sx={{ width: '100%', maxWidth: 360, maxHeight: 300, overflow: 'auto', bgcolor: 'background.paper' }}>
      {["Icon", "Humor", "Text", "Photo", "Movie", "Gaming", "Music", "Art", "Square", "Xmas"].map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <ListItem
            key={value}
            disablePadding
          >
            <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={props.checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={categoryTranslator[value]} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}