import { Card, Divider, IconButton } from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Box, Avatar, Typography, Container } from '@mui/material';
import { ArrowBack, Facebook, InfoRounded, Instagram, LinkedIn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
export default function About() {
  const {t, i18n} = useTranslation('translation');
    const navigate = useNavigate();

    return (
        <Container component="main" sx={{position: 'unset', transform: 'unset', paddingTop: '40px', paddingBottom: '40px', height:'100vh', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Card sx={{boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', maxWidth: '556px', padding: '0px 20px 50px 20px', margin: 'auto'}}>  
            <Button onClick={()=>{navigate(-1)}} startIcon={<ArrowBack/>} size='large' sx={{float: 'left',transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '0px' }}>{t('common.back_button')}</Button>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <InfoRounded />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('about.title')}
          </Typography>

          <Box component="form" noValidate sx={{ mt: 3, width: 'calc(100% - 37px)' }}>
            <div style={{marginBottom: '20px'}}>
              <img style={{width: '240px', borderRadius: '164px', boxShadow: '0px 6px 12px rgb(0 0 0 / 30%)'}} src={require('./images/tobias.jpg')} alt=''/>
              <Typography component="div" variant="h7" sx={{marginTop: '10px'}}>
                Tobias Schou Svenninggaard
              </Typography>
              <div className='socialMediaWrapper'>
                <IconButton href='https://instagram.com/tobyschou' target={'_blank'}>
                  <Instagram/>
                </IconButton>
                <IconButton href='https://facebook.com/tobias.schou.3' target={'_blank'}>
                  <Facebook/>
                </IconButton>
                <IconButton href='https://www.linkedin.com/in/tobias-schou-svenninggaard' target={'_blank'}>
                  <LinkedIn/>
                </IconButton>
              </div>
            </div>
          <Typography color="text.secondary" component="div" variant="h7" align='left'>
          {t('about.introduction_1')}<br></br>{t('about.introduction_2')}
          </Typography>
          <Divider sx={{width: '120px', m: '20px auto'}}/>
          <Typography color="text.secondary" align='left' component="div" variant="h7">
            {t('about.main_1')}<br></br>
            {t('about.main_2')}<br></br>
            {t('about.main_3')}<br></br>
            {t('about.main_4')}
          </Typography>
          </Box>
        </Box>
        </Card>
          </Container>
          
    );
  }