import { Accordion, AccordionDetails, AccordionSummary, Card } from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Box, Avatar, Typography, Container } from '@mui/material';
import { ArrowBack, ExpandMoreRounded, QuestionMarkRounded } from '@mui/icons-material';
import { InfoOutlined } from '@mui/icons-material';
import { width } from '@mui/system';
import { useTranslation } from 'react-i18next';
export default function Help() {
  const [t, i18n] = useTranslation('translation');
    const navigate = useNavigate();

    return (
        <Container component="main" sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <Card sx={{boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', maxWidth: '556px', padding: '0px 20px 50px 20px', margin: 'auto'}}>  
            <Button onClick={()=>{navigate(-1)}} startIcon={<ArrowBack/>} size='large' sx={{float: 'left',transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '0px' }}>{t('common.back_button')}</Button>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <QuestionMarkRounded />
          </Avatar>
          <Typography component="h1" variant="h5">
          {t('help.title')}
          </Typography>

          <Box component="form" noValidate sx={{ mt: 3 }}>
          <Accordion elevation={4} className='helpAccordion'>
                            <AccordionSummary
                              expandIcon={<ExpandMoreRounded />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography variant='h7'><strong>{t('help.question_1')}</strong></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                                {t('help.answer_1')}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion elevation={4} className='helpAccordion' sx={{mt: '16px', ':before': {opacity: '0'}}}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreRounded />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography variant='h7'><strong>{t('help.question_2')}</strong></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('help.answer_2')}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
          </Box>
        </Box>
        </Card>
          </Container>
          
    );
  }