import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { ArrowForward } from '@mui/icons-material';
import { SaveRounded } from '@mui/icons-material';
import { PaletteRounded } from '@mui/icons-material';
import { Recommend } from '@mui/icons-material';
import { Grid4x4Rounded } from '@mui/icons-material';
import { AutoAwesomeRounded } from '@mui/icons-material';
import { EditRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const iconSize = '64px';
const iconColor = 'primary.main';
const iconOpacity = '1';


function FeaturesContent() {
    const {t, i18n} = useTranslation('translation');
    const tiers = [
        {
            title: t('home.section_2_feature_1_title'),
            icon: <EditRounded sx={{fontSize: iconSize, color: iconColor, opacity: iconOpacity}}/>,
            description: [
                t('home.section_2_feature_1_description'),
            ],
        },
        {
            title: t('home.section_2_feature_2_title'),
            icon: <Recommend sx={{fontSize: iconSize, color: iconColor, opacity: iconOpacity}}/>,
            description: [
                t('home.section_2_feature_2_description'),
            ],
        },
        {
            title: t('home.section_2_feature_3_title'),
            icon: <AutoAwesomeRounded sx={{fontSize: iconSize, color: iconColor, opacity: iconOpacity}}/>,
            description: [
                t('home.section_2_feature_3_description'),
            ],
        },
        {
            title: t('home.section_2_feature_4_title'),
            icon: <PaletteRounded sx={{fontSize: iconSize, color: iconColor, opacity: iconOpacity}}/>,
            description: [
                t('home.section_2_feature_4_description'),
            ],
        },
        {
            title: t('home.section_2_feature_5_title'),
            icon: <Grid4x4Rounded sx={{fontSize: iconSize, color: iconColor, opacity: iconOpacity}}/>,
            description: [
                t('home.section_2_feature_5_description'),
            ],
        },
        {
            title: t('home.section_2_feature_6_title'),
            icon: <SaveRounded sx={{fontSize: iconSize, color: iconColor, opacity: iconOpacity}}/>,
            description: [
                t('home.section_2_feature_6_description'),
            ],
        },
    ];
    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Card sx={{borderRadius: '0px', boxShadow: '0 0px 18px rgb(0 0 0 / 30%);'}}>
                <Container className='paddingSmallShowcase' disableGutters maxWidth="sm" component="main" sx={{ pt: 4, pb: 6, display: 'flex', justifyContent: 'center' }}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                        sx={{mr: '12px'}}
                        className='overskriftHome'
                    >
                        {t('home.section_2_title')}
                    </Typography><h1 className='korsstingHjerte'>{'<3'}</h1>
                </Container>
                {/* End hero unit */}
                <Container maxWidth="md" component="main" sx={{pb: '60px'}} className='paddingBottomShowcase'>
{/*                 <div class='showcaseImageWrapper'>
                    <img className='showcaseImage' src={require('./images/slide1.jpg')} alt="Slider" />
                    <ArrowForward sx={{fontSize: '64px', opacity: '0.4'}}/>
                    <img className='showcaseImage' src={require('./images/slide1.jpg')} alt="Slider" />
                    </div>  */}
                    <Grid container spacing={5} alignItems="flex-end">
                        {tiers.map((tier) => (
                            // Enterprise card is full width at sm breakpoint
                            <Grid
                                item
                                key={tier.title}
                                xs={12}
                                sm={tier.title === 'Enterprise' ? 12 : 6}
                                md={4}
                                className='featuresHomeCardsGrid'
                            >
                                <Card className='featuresHomeCards' sx={{ height: '225px', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);'}}>
                                    <CardHeader
                                        title={tier.title}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{
                                            align: 'center',
                                        }}
                                        sx={{padding: '16px 16px 0px 16px'}}
                                    />
                                    <CardContent>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'baseline',
                                                mb: 2,
                                            }}
                                        >
                                                {tier.icon}
                                        </Box>
                                        <ul>
                                            {tier.description.map((line) => (
                                                <Typography
                                                    component="li"
                                                    variant="subtitle1"
                                                    align="center"
                                                    key={line}
                                                >
                                                    {line}
                                                </Typography>
                                            ))}
                                        </ul>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                {/* Footer */}
                </Card>
            {/* End footer */}
        </React.Fragment>
    );
}

export default function Features() {
    return <FeaturesContent />;
}