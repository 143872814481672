import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { CropRotate } from '@mui/icons-material';
import { Chip, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';



function valueLabelFormat(value) {
  let scaledValue = value;
  return `${scaledValue}`;
}

function calculateValue(value) {
  return (value * 10) - 180;
}

export default function RotateSlider(props) {
  const [value, setValue] = React.useState(18);
  const {t, i18n} = useTranslation('translation');
  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
      props.handleRotateCrop(calculateValue(newValue));
    }
  };

  const resetValue = async (e) => {
    try {
      setValue(18);
      await props.handleRotateCrop(calculateValue(18));
      props.handleCropImage();
    }
    catch(err) {
      console.log(err);
    }
  }

  const handleUp = (e) => {
    props.handleCropImage();
  }

  return (
    <Box className='rotateSlider'>
      <Chip label={t('create_page_2.reset_button')} className='sliderReset' onClick={resetValue} />
        <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography>-180</Typography>
        </Grid>
        <Grid item xs>
      <Slider
        value={value}
        min={0}
        step={0.1}
        max={36}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
        onChangeCommitted={handleUp}
      />
      </Grid>
      <Grid item>
          <Typography>+180</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}