import * as React from 'react';
import HeadSection from './HeadSection';
import Pricing from './Pricing';
import Features from './Features';
import { useEffect } from 'react';
import Showcase from './Showcase';
import Broderist from './Broderist';
import { useUserAuth } from './UserAuthContext';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HomeUpdates from './HomeUpdates';
export default function Home(props) {

  const { user } = useUserAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
useEffect(() =>{
  
  if(user)
  {
    setIsLoggedIn(true);
  } else {
      //props.handleSetHomeMode();
    
    setIsLoggedIn(false);
  }
},[user]);

    return (
        <React.Fragment>
          <Helmet>
            <title>Embrodia - Create unique embroidery patterns from your own images</title>
            <meta name="description" content="Create your own unique cross stitch patterns. Quick and easy. Try it out for free!"/>
            
          </Helmet>
          <HeadSection isLoggedIn={isLoggedIn} />
          <Showcase/>
          <div style={{padding: '20px'}}></div>
          {/* {i18n.language=== 'da' && <div> */}
          <Broderist/>
          <div style={{padding: '20px'}}></div>
         {/*  </div>} */}
          <Features/>
{/*           <div style={{padding: '20px'}}></div>
          <HomeUpdates/> */}
          <div style={{padding: '20px'}}></div>
          <Pricing lngs={props.lngs} theme={props.theme}/>
          </React.Fragment>
          
    );
  }