import { Card } from '@mui/material';
import * as React from 'react';
import { Button, Box, Typography, Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import StarIcon from '@mui/icons-material/StarBorder';
import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import { useUserAuth } from './UserAuthContext';
import { CircularProgress, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, query, where, addDoc, orderBy, deleteDoc, doc, updateDoc } from "@firebase/firestore";
import { db } from './firebase';
import { useTranslation } from 'react-i18next';





export default function Welcome(props) {
  const navigate = useNavigate();
    const { createCheckoutSession } = useUserAuth();
    const [loading, setLoading] = React.useState(-1);
    const {t, i18n} = useTranslation('translation');
/*     const [isSubscribedConst, setIsSubscribed] = React.useState(true); */
 /*    const navigate = useNavigate(); */

/*     async function isSubscribed(user) {    
      const q = query(collection(db, "users",user.uid+'/subscriptions'), where('status', 'in', ['trialing', 'active']));
      const querySnapshot = await getDocs(q);
      if(querySnapshot.size === 0) {
        setIsSubscribed(false);
      }else {
        setIsSubscribed(true);
      }
  } */

  const tiers = [
    {
      title: t('welcome.tier1_title_1'),
      price: t('welcome.tier1_price_1'),
      description: [
        t('welcome.tier1_description_1'),
      ],
      buttonVariant: 'contained',
      plan: 'month',
      short: t('welcome.tier1_short_1')
    },
    {
      title: t('welcome.tier1_title_2'),
      price: t('welcome.tier1_price_2'),
      description: [
        t('welcome.tier1_description_2')
      ],
      buttonVariant: 'contained',
      plan: 'halfYear',
      short: t('welcome.tier1_short_2')
    },
    {
      title: t('welcome.tier1_title_3'),
      subheader: t('welcome.best_value'),
      price: t('welcome.tier1_price_3'),
      description: [
        t('welcome.tier1_description_3')
      ],
      buttonVariant: 'contained',
      plan: 'year',
      short: t('welcome.tier1_short_3')
    },
  ];

  const tiers2 = [
    {
      title: t('welcome.tier2_title_1'),
      price: t('welcome.tier2_price_1'),
      description: [
        t('welcome.tier2_description_1_1'),
        t('welcome.tier2_description_1_2')
      ],
      buttonVariant: 'outlined',
      plan: 'freePattern'
    },
    {
      title: t('welcome.tier2_title_2'),
      price: t('welcome.tier2_price_2'),
      description: [
        t('welcome.tier2_description_2_1'),
        t('welcome.tier2_description_2_2')
      ],
      buttonVariant: 'outlined',
      plan: 'onePattern'
    }/* ,
    {
      title: t('welcome.tier2_title_3'),
      price: t('welcome.tier2_price_3'),
      description: [
        t('welcome.tier2_description_3_1'),
        t('welcome.tier2_description_3_2'),
        t('welcome.tier2_description_3_3')
      ],
      buttonVariant: 'outlined',
      plan: 'threePatterns'
    }, */
  ];

  async function isFirstTime(user) {
    let isFirstTime = true;
    const q = query(collection(db, "users",user.uid+'/subscriptions'), where('status', 'in', ['trialing', 'active']));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size !== 0) {
      isFirstTime = false;
    }
    const q2 = query(collection(db, "users",user.uid+'/payments'), where('status', '==', 'succeeded'));
    const querySnapshot2 = await getDocs(q2);
    if(querySnapshot2.size !== 0) {
      isFirstTime = false;
    }
    if(isFirstTime===false) {
      return false;
    }

    return true;
  }

/*   React.useEffect( ()=> {
    console.log(props.isFirstTime);
      if(props.isFirstTime===false && tiers2.length===3) {
        tiers2.shift();
      }
},[]);   */

  async function useFreePattern(user) {
    /* let isFirst = await isFirstTime(user); */
    if(props.isFirstTime===true) {
      const docRef = collection(db, "users", user.uid+'/payments');
      const date = new Date().toLocaleString() + "";
      await addDoc(docRef, {
        status: 'succeeded',
        date: date,
        description: 'First pattern free',
        metadata: {
          valid: 1
        }
      })
      setLoading(-1);
      location.reload();
    }
  }



    return (
        <Container component="main" sx={{mb: '100px'}}>
          <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 0 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{marginBottom: '50px'}}
        >
          {t('welcome.title_2')}
        </Typography>
        <table style={{ margin: '20px', borderCollapse: 'collapse' }}>
                                <tbody>
                                  <tr>
                                    <th style={{border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}></th>
                                    <th style={{width: '90px', height: '70px', backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700], border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_plan_1')}</th>
                                    <th style={{width: '90px', height: '70px', backgroundColor: props.theme.palette.mode === 'light' ? 'white' : props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}>{t('pdf_page.upgrade_plan_2')}</th>
                                  </tr>
                                  

                                    <tr>
                                      <td style={{padding: '12px',textAlign: 'left', border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_1')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700],border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? 'white' : props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px',textAlign: 'left', border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_2')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700],border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{ opacity: '0.4', width: '2rem', height: '2rem'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? 'white' : props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}> <CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_9')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700],border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? 'white' : props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_8')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700],border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? 'white' : props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_3')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700],border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? 'white' : props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_4')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700],border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? 'white': props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_5')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700],border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? 'white' : props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                    </tr>


                                    <tr>
                                      <td style={{padding: '12px',textAlign: 'left', border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_6')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700],border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? 'white' : props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_7')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? props.theme.palette.grey[100] : props.theme.palette.grey[700],border: props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem' }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: props.theme.palette.mode === 'light' ? 'white' : props.theme.palette.grey[500], border: props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main'  }}/></td>
                                    </tr>
                                </tbody>
                              </table>
      </Container> 
                  <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 6, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{marginBottom: '50px'}}
        >
          {t('welcome.title')}
        </Typography>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {t('welcome.subscription')}
        </Typography>
{/*         <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{marginBottom: '12px'}}>
          Prøv i 3 dage <strong style={{color: 'var(--primary)'}}>helt gratis</strong> når du opretter et abonnement for første gang
        </Typography> */}
{/*         <Button size='large' variant='contained' onClick={()=>{navigate('/signup')}} sx={{ backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}}}>
                    Opret en ny profil her
                  </Button> */}
                  <Typography variant="h5" align="center" color="text.secondary" component="p">
          {t('welcome.subscription_description')}
        </Typography>
      </Container> 
                   <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier, i) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card sx={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);',transition:'all .5s ease-out', '&:hover': {transform: 'translate(0px,-10px)',boxShadow: '0 18px 28px rgba(0,0,0,0.15);'}}}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === t('welcome.tier1_title_3') ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    pointerEvents: 'none',
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary" sx={{pointerEvents: 'none'}}>
                      {tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{pointerEvents: 'none'}}>
                      {t('welcome.currency')+'/'+tier.short}
                    </Typography>
                  </Box>
                    {tier.description.map((line) => (
                      <Typography
                        component="p"
                        variant="subtitle1"
                        align="center"
                        key={line}
                        sx={{pointerEvents: 'none'}}
                      >
                        {line}
                      </Typography>
                    ))}
                </CardContent>
                <CardActions>
                {tier.buttonVariant==='contained' ? 
                  <Button disabled={loading===i ? false : (loading!==-1)} startIcon={loading === i ? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important', color: "#fff" }}/> : <CheckCircleRounded/>} onClick={()=>{createCheckoutSession(tier.plan); setLoading(i)}} size='large' fullWidth variant={tier.buttonVariant}  sx={{ backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}}}>
                    {loading === i ? t('common.loading') : t('welcome.button')}
                  </Button>
                  :
                  <Button disabled={loading!==-1} startIcon={<CheckCircleRounded/>} size='large' fullWidth variant={tier.buttonVariant} >
                    {t('welcome.button')}
                  </Button>
                                  }
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container> 
      <Typography variant="h6" align="center" color="text.secondary" component="p" sx={{mt: '40px', mb: '-40px'}}>
          <i>{t('welcome.or')}</i>
        </Typography>
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
      <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {t('welcome.pattern')}
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
        {t('welcome.pattern_description_1')}
        </Typography>
       {/*  {
        props.isFirstTime ? <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{marginTop: '12px'}}>
        {t('welcome.pattern_description_2')} <strong style={{color: 'var(--primary)'}}>{t('welcome.pattern_description_3')}</strong>
        </Typography> : ''
        } */}
        
        </Container>
        <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end" justifyContent={'center'}>
          {props.tiers2.map((tier, i) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card sx={{ /* background: i === 0 ?'rgba(233 30 99 / 15%)' : '', */ border: tier.plan === 'freePattern' ?'2px dashed var(--primary)':'none', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);',transition:'all .5s ease-out', '&:hover': {transform: 'translate(0px,-8px)',boxShadow: '0 18px 28px rgba(0,0,0,0.15);'}}}>
                <CardHeader
                
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'En gang om året' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    pointerEvents: 'none',
                    color: tier.plan === 'freePattern' ? '#fff' : '',
                    backgroundColor: (theme) =>
                    tier.plan === 'freePattern' ? 'var(--primary)' :
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary" sx={{pointerEvents: 'none'}}>
                      {tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{pointerEvents: 'none'}}>
                      {t('welcome.currency')}
                    </Typography>
                  </Box>
                    {tier.description.map((line) => (
                      <Typography
                        component="p"
                        variant="subtitle1"
                        align="center"
                        key={line}
                        sx={{pointerEvents: 'none'}}
                      >
                        {line}
                      </Typography>
                    ))}
                </CardContent>
                <CardActions>
                {
                tier.plan === 'freePattern' ? 
                <Button onClick={()=>{useFreePattern(props.user); setLoading(i+3)}} disabled={loading===i+3 ? false : (loading!==-1)}  startIcon={loading === i+3 ? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important' }}/> : <CheckCircleRounded/>} size='large' fullWidth variant={tier.buttonVariant} >
                {t('welcome.button')}
              </Button>
              :
                tier.buttonVariant==='contained' ? 
                  <Button onClick={()=>{createCheckoutSession(tier.plan); setLoading(i+3)}} disabled={loading===i+3 ? false : (loading!==-1)}  startIcon={loading === i+3 ? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important' }}/> : <CheckCircleRounded/>} size='large' fullWidth variant={tier.buttonVariant}  sx={{ backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}}}>
                    {t('welcome.button')}
                  </Button>
                  :
                  <Button onClick={()=>{createCheckoutSession(tier.plan); setLoading(i+3)}} disabled={loading===i+3 ? false : (loading!==-1)}  startIcon={loading === i+3 ? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important' }}/> : <CheckCircleRounded/>} size='large' fullWidth variant={tier.buttonVariant} >
                    {t('welcome.button')}
                  </Button>
                                  }
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container> 
      <Container maxWidth="md" component="main">
      <div style={{height: '50px'}}></div>
      <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center', mt: '15px' }}>
              <Grid item sx={{ display: 'flex' }}>
                <Typography variant="body2" color="text.secondary">
                  {t('welcome.by_continuing')} <Link sx={{cursor: 'pointer'}} onClick={()=>{navigate('/termsandconditions')}} variant="body2">
                  {t('common.terms')}
                  </Link></Typography>

                  
              </Grid>
            </Grid>
            <Typography variant="body2" color="text.secondary" sx={{mt: '20px'}}>
                  {t('welcome.note_1')}<br></br>{t('welcome.note_2')}<br></br>{t('welcome.note_3')}
                 
                  </Typography>
        <div style={{height: '100px'}}></div>
      </Container>
{/*       <Button disabled={loading!==-1} startIcon={<LogoutRounded/>} onClick={handleClickLogout} size='large' fullWidth variant={'contained'}  sx={{ maxWidth: '400px',  m:'100px 0px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}}}>Log ud</Button> */}
          </Container>
    );
  }