import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getDoc, doc, setDoc, deleteDoc, getDocs, where, collection, collectionGroup, query, addDoc, updateDoc, orderBy } from "@firebase/firestore";
import { db, storage as storageRef } from './firebase';
import { ref, getDownloadURL, uploadBytes, deleteObject, getBlob } from "@firebase/storage";
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, Paper, Skeleton, TextField, Toolbar, Typography } from '@mui/material';
import { AccountCircleRounded, ArrowBack, ChatBubbleRounded, CheckRounded, Close, DeleteRounded, DownloadRounded, EditRounded, ExitToAppRounded, FavoriteBorderRounded, FavoriteRounded, PersonRounded, SaveRounded, SendRounded, StarRounded } from '@mui/icons-material';
import { useUserAuth } from './UserAuthContext';
import { Link } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import UploadShareImageEdit from './UploadShareImageEdit';


function useStateCallback(initialState) {
  const [state, setState] = React.useState(initialState);
  const cbRef = React.useRef(null); // init mutable ref container for callbacks

  const setStateCallback = React.useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  React.useEffect(() => {
    // cb.current is `null` on initial render, 
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);
  
  return [state, setStateCallback];
}

//create your forceUpdate hook
function useForceUpdate(){
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}


export default function CatalogItem(props) {

  const {t, i18n} = useTranslation('translation');
  // call your hook here
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const { id } = useParams();
  const [patternInfo, setPatternInfo] = useStateCallback(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [patternInfoHasBeenLoaded, setPatternInfoHasBeenLoaded] = React.useState(false);
  const { user } = useUserAuth();
  const [patternIsOwned, setPatternIsOwned] = React.useState(false);
  const [loadingShare, setLoadingShare] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [pickedCategories, setPickedCategories] = React.useState([]);
  const [shareError, setShareError] = React.useState(null);
  const [nameValue, setNameValue] = React.useState("");
  const [shareUploadImage, setShareUploadImage] = React.useState([]);
  const [sharePreviewImage, setSharePreviewImage] = React.useState([]);
  const [descriptionValue, setDescriptionValue] = React.useState("");
  const [loadingAddPatternToCollection, setLoadingAddPatternToCollection] = React.useState(false);
  const [patternInCollection, setPatternInCollection] = React.useState(false);
  const [commentValue, setCommentValue] = React.useState("");
  const [commentEditValue, setCommentEditValue] = React.useState("");
  const [favorited, setFavorited] = React.useState(false);
  const [loadingAddComment, setLoadingAddComment] = React.useState(false);
  const [allComments,  setAllComments] = React.useState([]);

  const [shareCategories, setShareCategories] = React.useState(
    [{
      name: 'Icon',
      checked: false
    },
    {
      name: 'Humor',
      checked: false
    },
    {
      name: 'Text',
      checked: false
    },
    {
      name: 'Photo',
      checked: false
    },
    {
      name: 'Movie',
      checked: false
    },
    {
      name: 'Gaming',
      checked: false
    },
    {
      name: 'Music',
      checked: false
    },
    {
      name: 'Art',
      checked: false
    },
    {
      name: 'Square',
      checked: false
    },
    {
      name: 'Xmas',
      checked: false
    },
    ],
  )

const categoryTranslator = {
  "Icon":   t('catalog.category_icon'),
  "Humor":  t('catalog.category_humor'),
  "Text":   t('catalog.category_text'),
  "Photo":  t('catalog.category_photo'),
  "Movie":  t('catalog.category_movie'),
  "Gaming": t('catalog.category_gaming'),
  "Music":  t('catalog.category_music'),
  "Art":    t('catalog.category_art'),
  "Square": t('catalog.category_square'),
  "Xmas":   t('catalog.category_xmas')
}

  React.useEffect(() =>{
    if(user)
    {
      setIsLoggedIn(true);
      if(props.catalogArray.length===0) {
        handleFetchInfoOnEffect(user);
      } else {
        handleFetchComments(props.catalogArray[props.patternIndex]);
      }
/*       handleCheckIsSubscribed(); */
    } else {
      setIsLoggedIn(false);
      if(props.catalogArray.length===0) {
        handleFetchInfoOnEffect(user);
      } else {
        handleFetchComments(props.catalogArray[props.patternIndex]);
      }
    }
    
/*     if(!props.catalogArray) {
      //queryPattern();
      setPatternIsOwned(false);
      console.log("handleFecth!!");
      props.handleFetchCatalogArray();

    } else {
      props.handleSetPatternIndex(props.catalogArray.indexOf(id));
      console.log(id);
      if(user && props.patternIndex) {
        console.log(props.catalogArray[props.patternIndex]);
      if(user.uid===props.catalogArray[props.patternIndex].userId) {
        console.log('pattern is owned!')
        setPatternIsOwned(true);
      }
      handleFetchComments(props.catalogArray[props.patternIndex]);
    }
      
    }
     */

  },[user]);


/*   const handleCheckIsSubscribed = async () => {
    let isSubbed = false 
    const q = query(collection(db, "users",user.uid+'/subscriptions'), where('status', 'in', ['trialing', 'active']));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size !== 0) {
      isSubbed = true;
    }
    const q2 = query(collection(db, "users", user.uid + '/payments'), where('metadata.valid', '!=', '0'));
    const query2Snapshot = await getDocs(q2);
    if(query2Snapshot.size !== 0) {
      isSubbed = true;
    }
    console.log("You are subscribed!");
    setIsSubscribed(isSubbed);
  } */

  const handleFetchInfoOnEffect = async (user)=> {
    const arr = await props.handleFetchCatalogArray(user);
    if(arr) {
      //console.log(props.patternIndex);
    let index = null;
    arr.forEach((pattern)=>{
      if(pattern.share_milis.toString()===id) {
        
        index = arr.indexOf(pattern);
      }
    })
    
    if(index!==null) {
    await props.handleSetPatternIndex(index);
    if(user) {
    if(user.uid===arr[index].userId) {
      //console.log('pattern is owned!')
      setPatternIsOwned(true);
    }
  }
    handleFetchComments(arr[index]);
  } else {
    navigate('/notfound');
  }
} 
  }


const handleEditComment = (commentToEdit) => {

  setCommentEditValue(commentToEdit.text);
  var comments = allComments;
  comments.forEach((comment)=> {
    if(comment.commentId===commentToEdit.commentId) {
      comment.editMode = true;
    } else {
      comment.editMode = false;
    }
  });
  setAllComments(comments);
  forceUpdate();
}


const handleDeleteComment = async (commentToDelete)=> {
/*   var comments = allComments;
  comments.forEach((comment)=> {
    if(comment.commentId===commentToSave.commentId) {
      comment.text = commentToSave.text;
      comment.editMode = false;
    }
  });
  setAllComments(comments); */
  try {
    const docRef = doc(db, "shared_patterns",props.catalogArray[props.patternIndex].id+"/comments/"+commentToDelete.commentId);
        await deleteDoc(docRef);
        handleFetchComments(props.catalogArray[props.patternIndex]);
        forceUpdate();
      } catch(err) {
        console.log(err.message);
      }
      
}

const handleSaveComment = async (commentToSave)=> {

  try {
    if(commentToSave.text!==commentEditValue) {
    const docRef = doc(db, "shared_patterns",props.catalogArray[props.patternIndex].id+"/comments/"+commentToSave.commentId);
        await updateDoc(docRef, {
          text: commentEditValue,
          edited: true
        });
        handleFetchComments(props.catalogArray[props.patternIndex]);
        
      } else {
        var comments = allComments;
        comments.forEach((comment)=> {
          if(comment.commentId===commentToSave.commentId) {
            comment.editMode = false;
          }
        });
        setAllComments(comments); 
      }
      } catch(err) {
        console.log(err.message);
      }


      forceUpdate();
}

  const handleAddToCollection = async (e) => {
      setLoadingAddPatternToCollection(true);
      var exists = false;
     //Check if not already added
     const docRefAdded = query(collection(db, "users", user.uid+'/saved_patterns'), where('shareId', '==', props.catalogArray[props.patternIndex].id));
     const querySnapshot = await getDocs(docRefAdded);
     if(querySnapshot.size !== 0) {
        exists = true;
       }
       if(!exists) {
       /*  console.log("Adding to collection...") */
        const docRef = collection(db, "users", user.uid+'/saved_patterns');
        const date = new Date().toLocaleString() + "";
        const d = new Date();
        let time = d.getTime();
        
        //Create new image in storage and assign reference to the new doc.
        var currentTime = +new Date();
         const storageName = user.uid+currentTime+'';
        const imageRef = ref(storageRef, props.catalogArray[props.patternIndex].storageName);
        await getBlob(imageRef)
          .then((blob) => {
            const newImageRef = ref(storageRef, storageName);
            uploadBytes(newImageRef, blob);
                  });
        
        await addDoc(docRef, {
          name: props.catalogArray[props.patternIndex].name,
          storageName: storageName,
          created: date,
          totalStitches: props.catalogArray[props.patternIndex].totalStitches,
          stitchWidth: props.catalogArray[props.patternIndex].stitchWidth,
          stitchHeight: props.catalogArray[props.patternIndex].stitchHeight,
          fabric: props.catalogArray[props.patternIndex].fabric,
          cmWidth: props.catalogArray[props.patternIndex].cmWidth,
          cmHeight: props.catalogArray[props.patternIndex].cmHeight,
          colorCount: props.catalogArray[props.patternIndex].colorCount,
          a4Pages: props.catalogArray[props.patternIndex].a4Pages,
          milis: time,
          dmcArray: props.catalogArray[props.patternIndex].dmcArray,
          shared: true,
          shareId: props.catalogArray[props.patternIndex].id
        }) 
        //Increase downloads by one
        var isPresent = false;
        var downloadsVar = JSON.parse(props.catalogArray[props.patternIndex].downloads);
        if(downloadsVar==="[]") {
          downloadsVar = [];
        }
        var downloadsNumberVar = props.catalogArray[props.patternIndex].downloadsNumber;

        if(props.catalogArray[props.patternIndex].downloads) {

        /* console.log(downloadsVar); */
        
        if(downloadsVar!==null)
        {
          if(downloadsVar.length>0)
          {
            downloadsVar.forEach((el)=>{
              if(el===user.uid) {
                isPresent = true;
              }
            })
            if(isPresent===false) {
              downloadsVar.push(user.uid);
              downloadsNumberVar++;
            }
          }else {
            var downloadsVar = [];
            downloadsVar.push(user.uid);
            downloadsNumberVar++;
           /*  console.log(JSON.stringify(downloadsVar)); */
          }
        }
      } else {
        var downloadsVar = [];
        downloadsVar.push(user.uid);
        downloadsNumberVar++;
       /*  console.log(JSON.stringify(downloadsVar)); */
      }
/*          if(isPresent===false) {
           */

/*         }  */

                //Increase active users by one
                var isActiveUserPresent = false;
                var activeUsersVar = JSON.parse(props.catalogArray[props.patternIndex].activeUsers);
                if(props.catalogArray[props.patternIndex].downloads) {
                
                /* console.log(downloadsVar); */
                
                if(activeUsersVar!==null)
                {
                  if(activeUsersVar.length>0)
                  {
                    activeUsersVar.forEach((el)=>{
                      if(el===user.uid) {
                        isActiveUserPresent = true;
                      }
                    })
                    if(isActiveUserPresent===false) {
                      activeUsersVar.push(user.uid);
                    }
                  }else {
                    var activeUsersVar = [];
                    activeUsersVar.push(user.uid);
                   /*  console.log(JSON.stringify(downloadsVar)); */
                  }
                }
              } else {
                var activeUsersVar = [];
                activeUsersVar.push(user.uid);
               /*  console.log(JSON.stringify(downloadsVar)); */
              }
/*                  if(isActiveUserPresent===false) {
                   */
                  
/*                   const sharedPatternsRef = doc(db, 'shared_patterns',props.catalogArray[props.patternIndex].storageName)
                  await updateDoc(sharedPatternsRef, { 
                    activeUsers:  activeUsersVar
                  }); */
/*                 }  */
    
                //props.handleResetAllPatterns();

                //Add changes to catalogArray
                downloadsVar = JSON.stringify(downloadsVar);
                activeUsersVar = JSON.stringify(activeUsersVar);
                const sharedPatternsRef = doc(db, 'shared_patterns',props.catalogArray[props.patternIndex].id)
                await updateDoc(sharedPatternsRef, { 
                  downloads: downloadsVar,
                  downloadsNumber: downloadsNumberVar,
                  activeUsers:  activeUsersVar
                });
                //Update CatalogArray
                var catArr = props.catalogArray;
                catArr.forEach((pattern)=> {
                  if(pattern.id===props.catalogArray[props.patternIndex].id) {
                  pattern.downloads= downloadsVar;
                  pattern.downloadsNumber=downloadsNumberVar;
                  pattern.activeUsers=activeUsersVar;
                  pattern.inCollection=true;

                  }
                })
                props.handleSetCatalogArray(catArr);
                props.handleResetAllPatterns();
        /* queryPattern(); */
       }

    setLoadingAddPatternToCollection(false);
    
  }

  const handleSharePattern= async (e) => {
    e.preventDefault();
    let shouldLoad = true;
    let errorText = null;
    
    //Get input data from share form
    const data = new FormData(e.currentTarget);
    var name = data.get("name");
    var desc = data.get("description");
    var categories = JSON.stringify(pickedCategories);
    
    if(name==="") {
      shouldLoad = false;
      errorText = t('share_dialog.error');
    }
    if(shouldLoad) {
      setShareError(null);
      setLoadingShare(true);
 try {
    var shareImageStorageVar = props.catalogArray[props.patternIndex].shareImageStorage;
    var shareImageStorageUrlVar = props.catalogArray[props.patternIndex].shareImageStorageUrl;
    //Check if made change to image
    if(shareUploadImage.length>0) {
      //console.log('hello1');
      if(props.catalogArray[props.patternIndex].shareImageStorage!==null)
      {
        //console.log('hello3');
        //Delete and replace
        const imageRef = ref(storageRef, 'finished_embroideries/'+props.catalogArray[props.patternIndex].shareImageStorage);
        // Delete the file
        await deleteObject(imageRef).then(() => {
        }).catch((error) => {
          console.log(error);
        });
    }

      //Create new
      var currentTime = +new Date();
       var shareImageStorageTime = user.uid+currentTime+'';
        const newImageRef = ref(storageRef, 'finished_embroideries/'+shareImageStorageTime);
        await uploadBytes(newImageRef,shareUploadImage[0]);
        shareImageStorageVar = shareImageStorageTime;
        await getDownloadURL(newImageRef)
        .then((url) => {
          shareImageStorageUrlVar = url;
                });
    }
    //Check if has deleted share image
     else if(sharePreviewImage.length===0 && props.catalogArray[props.patternIndex].shareImageStorage!==null) {
      //console.log('hello2');
      const imageRef = ref(storageRef, 'finished_embroideries/'+props.catalogArray[props.patternIndex].shareImageStorage);
      // Delete the file
      await deleteObject(imageRef).then(() => {
      }).catch((error) => {
        console.log(error);
      });
      shareImageStorageVar = null;
      shareImageStorageUrlVar= '';
    }
    //console.log(props.catalogArray[props.patternIndex]);
      /* await new Promise(r => setTimeout(r, 1000)); */
      let docRef = doc(db, 'shared_patterns',props.catalogArray[props.patternIndex].id)
      await setDoc(docRef, { 
              a4Pages: props.catalogArray[props.patternIndex].a4Pages,
              cmHeight: props.catalogArray[props.patternIndex].cmHeight,
              cmWidth: props.catalogArray[props.patternIndex].cmWidth,
              colorCount: props.catalogArray[props.patternIndex].colorCount,
              shared_time: props.catalogArray[props.patternIndex].shared_time,
              fabric: props.catalogArray[props.patternIndex].fabric,
              name: props.catalogArray[props.patternIndex].name,
              stitchHeight: props.catalogArray[props.patternIndex].stitchHeight,
              stitchWidth: props.catalogArray[props.patternIndex].stitchWidth,
              storageName: props.catalogArray[props.patternIndex].storageName,
              photoUrl: '',
              shareImageStorageUrl: shareImageStorageUrlVar,
              totalStitches: props.catalogArray[props.patternIndex].totalStitches,
              dmcArray: props.catalogArray[props.patternIndex].dmcArray,
              userName: props.catalogArray[props.patternIndex].userName,
              userId: props.catalogArray[props.patternIndex].userId,
              share_name: name, 
              share_description: desc, 
              share_categories: categories,
              share_image_storage: shareImageStorageVar,
              share_milis : props.catalogArray[props.patternIndex].share_milis,
              downloads: props.catalogArray[props.patternIndex].downloads,
              downloadsNumber: props.catalogArray[props.patternIndex].downloadsNumber,
              activeUsers: props.catalogArray[props.patternIndex].activeUsers,
              id: props.catalogArray[props.patternIndex].id });
              setLoadingShare(false);
      
            handleCloseShare();
      props.handleOpenSnackbar("success","Success! Thank you for sharing your pattern!");
/*     queryPattern(); */



        


    var catArr = props.catalogArray;
    catArr.forEach((pattern) => {
      if(pattern.id===props.catalogArray[props.patternIndex].id) {

/*         pattern.a4Pages = props.catalogArray[props.patternIndex].a4Pages
        pattern.cmHeight= props.catalogArray[props.patternIndex].cmHeight,
        pattern.cmWidth= props.catalogArray[props.patternIndex].cmWidth,
        pattern.colorCount= props.catalogArray[props.patternIndex].colorCount,
        pattern.shared_time= props.catalogArray[props.patternIndex].sharedTime,
        pattern.fabric= props.catalogArray[props.patternIndex].fabric,
        pattern.name= props.catalogArray[props.patternIndex].name,
        pattern.stitchHeight= props.catalogArray[props.patternIndex].stitchHeight,
        pattern.stitchWidth: props.catalogArray[props.patternIndex].stitchWidth,
        pattern.storageName: props.catalogArray[props.patternIndex].storageName,
        pattern.photoUrl: '',
        pattern.shareImageStorageUrl: '',
        pattern.totalStitches: props.catalogArray[props.patternIndex].totalStitches,
        pattern.dmcArray: props.catalogArray[props.patternIndex].dmcArray,
        pattern.userName: props.catalogArray[props.patternIndex].userName,
        pattern.userId: props.catalogArray[props.patternIndex].userId, */
        pattern.shareName= name;
        pattern.shareDescription= desc; 
        pattern.shareCategories= categories;
        pattern.shareImageStorage= shareImageStorageVar;
        pattern.shareImageStorageUrl= shareImageStorageUrlVar;
/*         pattern.share_milis : props.catalogArray[props.patternIndex].shareMilis,
        pattern.downloads: props.catalogArray[props.patternIndex].downloads,
        pattern.downloadsNumber: props.catalogArray[props.patternIndex].downloadsNumber,
        pattern.activeUsers: props.catalogArray[props.patternIndex].activeUsers */
      }
    });
    props.handleSetCatalogArray(catArr);
    } catch(err) {
      console.log(err.message);
      setLoadingShare(false);
      props.handleOpenSnackbar("error","There has been an error:( Please refresh the page and try again");
    }
  
    } else {
      setShareError(errorText);
    }
   
  }


  const handleUnshare = async () => 
  {
    setLoadingDelete(true);
    try {

      //Delete the second image of the finished embroidery if it has one
      if(props.catalogArray[props.patternIndex].shareImageStorage!==null) {
        const imageRef = ref(storageRef, 'finished_embroideries/'+props.catalogArray[props.patternIndex].shareImageStorage);
        // Delete the file
         await deleteObject(imageRef).then(() => {
          //console.log("Successfully deleted image file!");
        }).catch((error) => {
          console.log(error);
        }); 
      }
      //Delete share Image
      const cloudStorageRef = ref(storageRef, props.catalogArray[props.patternIndex].storageName);
          await deleteObject(cloudStorageRef).then(() => {
            //console.log("Successfully deleted image file!");
          }).catch((error) => {
            console.log(error);
          });

          //See if there are any comments first and delete all of them i there are
          let commentRef = collection(db, 'shared_patterns',props.catalogArray[props.patternIndex].id+'/comments');
          const allComments = await getDocs(commentRef);
          if(allComments.size>0) {
            /* for(let i = 0; i<allComments.size; i++){} */
            allComments.forEach(async (comment)=> {
              await deleteDoc(comment.ref);
            });
          }
      //Delete doc from shared_patterns
    let docRef = doc(db, 'shared_patterns',props.catalogArray[props.patternIndex].id)
    await deleteDoc(docRef); 
    setLoadingDelete(false);
    
    props.handleOpenSnackbar("success","Success! Pattern is now unshared");
    handleCloseShare();
    var catArr = props.catalogArray;
    catArr.forEach((pattern) => {
      if(pattern.id===props.catalogArray[props.patternIndex].id) {
        const index = catArr.indexOf(pattern);
        catArr.splice(index,1);
      }
    });
    props.handleSetCatalogArray(catArr);
    navigate('/catalog'+props.filterSearchParams);
    } catch(err) {
      console.log(err.message);
      setLoadingDelete(false);
      props.handleOpenSnackbar("error","There has been an error:( Please refresh the page and try again");
    }
  }


  const handleSetFavorite = () => {
    let favoriteVar = favorited;
    //Set favorite to false
    if(favoriteVar) {     
            handleDeleteFavorite(props.catalogArray[props.patternIndex].id);
    } 
    //Set favorite to true
    else {
      
         handleSaveFavorite(props.catalogArray[props.patternIndex].id);
    
    }
    setFavorited(!favoriteVar);
    var catArr = props.catalogArray;
    catArr.forEach((pattern) => {
      if(pattern.id===props.catalogArray[props.patternIndex].id) {
        pattern.favorited = !favoriteVar
      }
    });
    props.handleSetCatalogArray(catArr);
    forceUpdate();
  }

/*   const handleReflectChangesToCatalogarray = (valueToChange)=> {
    
  } */

  const handleSaveFavorite = async (storageName) => {
    const docRef = doc(db, "users/"+user.uid+'/favorite_patterns', storageName);
        await setDoc(docRef, {
        storageName: storageName
        });
  }

  const handleDeleteFavorite = async (storageName) => {
    const q = query(collection(db, "users",user.uid+'/favorite_patterns'), where('storageName', '==', storageName));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size !== 0) {
      await deleteDoc(querySnapshot.docs[0].ref)
    }
  }

/*   const queryPattern = async () => {
    try {
        const docRef = doc(db, "shared_patterns", id);
      const docSnap = await getDoc(docRef);
        if(docSnap.exists()) {
            let arr = [];
            arr.push({
                a4Pages: docSnap.data().a4Pages,
                cmHeight: docSnap.data().cmHeight,
                cmWidth: docSnap.data().cmWidth,
                colorCount: docSnap.data().colorCount,
                sharedTime: docSnap.data().shared_time,
                fabric: docSnap.data().fabric,
                name: docSnap.data().name,
                stitchHeight: docSnap.data().stitchHeight,
                stitchWidth: docSnap.data().stitchWidth,
                storageName: docSnap.data().storageName,
                photoUrl: '',
                shareImageStorageUrl: '',
                totalStitches: docSnap.data().totalStitches,
                shareMilis: docSnap.data().share_milis,
                dmcArray: docSnap.data().dmcArray,
                dbRef: docSnap.ref,
                shareName: docSnap.data().share_name,
                shareDescription: docSnap.data().share_description,
                shareCategories: docSnap.data().share_categories,
                shareImageStorage: docSnap.data().share_image_storage,
                userName: docSnap.data().userName,
                userId: docSnap.data().userId,
                downloads: docSnap.data().downloads===undefined ? JSON.stringify([]) : docSnap.data().downloads,
                downloadsNumber: docSnap.data().downloadsNumber,
                activeUsers: docSnap.data().activeUsers===undefined ? JSON.stringify([]) : docSnap.data().activeUsers
              });

              const imageRef = ref(storageRef, arr[0].storageName);
        await getDownloadURL(imageRef)
          .then((url) => {
              arr[0].photoUrl = url;
                  });
                  if(arr[0].shareImageStorage) {
                    const imageShareRef = ref(storageRef, 'finished_embroideries/'+arr[0].shareImageStorage);
                    await getDownloadURL(imageShareRef)
                      .then((url) => {
                          arr[0].shareImageStorageUrl = url;
                          setSharePreviewImage([url]);
                              });
                            }
                            /* console.log(arr); */
                 /* setPatternInfo(arr,()=>{setPatternInfoHasBeenLoaded(true);});
                  if(user && arr.length>0) {
                    //check if owned by us
                    if(user.uid===arr[0].userId) {
                      setPatternIsOwned(true);

                    } else {*/
                    //Check if we have it in our collection already since it is not owned by us
                  /*  const docRefAdded = query(collection(db, "users", user.uid+'/saved_patterns'), where('storageName', '==', arr[0].storageName));
                    const querySnapshot = await getDocs(docRefAdded);
                    if(querySnapshot.size !== 0) {
                       setPatternInCollection(true);
                      } else {
                        setPatternInCollection(false);
                      }
                    }

                      const docSnap = collection(db, "users",user.uid+'/favorite_patterns');
                      const querySnap = await getDocs(docSnap);
                      if(querySnap.size !== 0) {
                        querySnap.forEach((doc)=>{
                            if(arr[0].storageName===doc.id) {
                              setFavorited(true);
                            }
                          });
                      }
                    handleFetchComments(arr[0]);
                  }

                  


        } else {

        }
    
    }
    catch(err) {
      console.log(err.message);
    }
  } */

  const handleAddComment = async () => {
    setLoadingAddComment(true);
    try {
    var currentTime = +new Date();
    const docRef = collection(db, "shared_patterns",props.catalogArray[props.patternIndex].id+"/comments");
        await addDoc(docRef, {
        userId: user.uid,
        displayName: user.displayName,
        date: currentTime,
        text: commentValue,
        edited: false
        });
        setCommentValue("");
        setLoadingAddComment(false);  
        handleFetchComments(props.catalogArray[props.patternIndex]);
      } catch(err) {
        console.log(err.message);
        setLoadingAddComment(false);  
      }
        
    
  }

 const handleFetchComments = async (pattern) => {
    const docRef = query(collection(db, "shared_patterns",pattern.id+"/comments"),orderBy("date","desc"));
    const docSnap = await getDocs(docRef);
    var commentArr = [];
    if(docSnap.size!==0) {
      docSnap.forEach((doc)=> {
        commentArr.push({
          name: doc.data().displayName,
          uid: doc.data().userId,
          date: doc.data().date,
          text: doc.data().text,
          editMode: false,
          commentId: doc.id,
          edited: doc.data().edited
        })
      })
    }
   
    setAllComments(commentArr);
    forceUpdate();
  }

  const handleSelectShareCategory = (e) => {
    e.preventDefault();
    var target = e.target;
    if(e.target.tagName==="DIV") {
      target = e.target.children[0];
    }
    var catObj = shareCategories;
    var categoriesThatHaveBeenPicked = pickedCategories;


    catObj.forEach(category => {
      if(target.innerHTML===categoryTranslator[category.name]) {
        var checked = category.checked;
        if(checked) {
          const index = categoriesThatHaveBeenPicked.indexOf(category.name);
          categoriesThatHaveBeenPicked.splice(index,1);
        } else {
          if(!categoriesThatHaveBeenPicked.includes(category.name)) {
            categoriesThatHaveBeenPicked.push(category.name);
          }
        }
        category.checked = !checked;
      /*   console.log("Setting "+category.name+" to: "+!checked); */
      }
    })
    setShareCategories(catObj);
    setPickedCategories(categoriesThatHaveBeenPicked);
    forceUpdate();
  }

  const handleRemoveCategory = (e) => {
    e.preventDefault();
    var target = null;
    if(e.target.tagName==="svg") {
      target = e.target.parentElement.children[0];
    } else {
      target = e.target.parentElement.parentElement.children[0];
    }
    var categoriesThatHaveBeenPicked = pickedCategories;
    var translatedPickedCategories = [];
    categoriesThatHaveBeenPicked.forEach((cat)=>{
      translatedPickedCategories.push(categoryTranslator[cat]);
    })
    var catObj = shareCategories;
     if(translatedPickedCategories.includes(target.innerHTML)) {
      catObj.forEach(category => {
        if(target.innerHTML===categoryTranslator[category.name]) {
          category.checked = false;
        }
      })
      const index = translatedPickedCategories.indexOf(target.innerHTML);
      categoriesThatHaveBeenPicked.splice(index,1);
    }
    setShareCategories(catObj);
    setPickedCategories(categoriesThatHaveBeenPicked);
    forceUpdate();
  }

  const handleCloseShare = () => {
    setOpenShare(false);
  }

  const handleSetUploadShareImage = (img) => {
    setShareUploadImage(img);
    const newImageURL = [];
        img.forEach(img => newImageURL.push(URL.createObjectURL(img)));
        setSharePreviewImage(newImageURL);
  } 

  const handleRemovePreviewImage = () => {
    setSharePreviewImage([]);
  }

  const handleSetPreviewImage = () => {
    if(props.catalogArray[props.patternIndex]!==null)
    {
      if(props.catalogArray[props.patternIndex].shareImageStorageUrl!=="")
      {
        setSharePreviewImage([props.catalogArray[props.patternIndex].shareImageStorageUrl]);
      }
    }
  }

  const handleOpenShare = () => {
    setOpenShare(true);
    setDescriptionValue(props.catalogArray[props.patternIndex].shareDescription);
    let catArr = JSON.parse(props.catalogArray[props.patternIndex].shareCategories);
    let arr = [{
      name: 'Icon',
      checked: false
    },
    {
      name: 'Humor',
      checked: false
    },
    {
      name: 'Text',
      checked: false
    },
    {
      name: 'Photo',
      checked: false
    },
    {
      name: 'Movie',
      checked: false
    },
    {
      name: 'Gaming',
      checked: false
    },
    {
      name: 'Music',
      checked: false
    },
    {
      name: 'Art',
      checked: false
    },
    {
      name: 'Square',
      checked: false
    },
    {
      name: 'Xmas',
      checked: false
    },
    ];
    let pCat = [];
    catArr.forEach((dbEl)=>{
      arr.forEach((el)=>{
        if(el.name===dbEl) {
          el.checked=true;
          if(!pCat.includes(el.name))
          {
            pCat.push(el.name);
          }
          
        }
      })
    }) 
    setShareCategories(arr);
    setPickedCategories(pCat);
    setNameValue(props.catalogArray[props.patternIndex].shareName);

    handleSetPreviewImage();
  }

    return (
        <React.Fragment>
          { /*isLoggedIn===true ? <div></div> :
          <Box className='no-print' sx={{ flexGrow: 1, position: 'absolute'  }}>
          <Paper position="static" sx={{ boxShadow: '0 4px 12px rgba(0,0,0,0.2);', borderRadius: '0px' }}>
            <Toolbar sx={{ height: '80px', justifyContent: 'space-between', zIndex: '1' }}>
              <div className="left" style={{ display: 'flex', alignItems: 'center' }}>
              <h1 className="korsstingOverskift" style={{cursor: "pointer"}} onClick={()=>{navigate('/home')}}>EMBRODIA</h1>
            
              </div>

              <div className="right">
                 
                <div> 
                  
                  </div>  
              </div>          
            </Toolbar>
          </Paper>
    </Box>*/ }
          
            {props.catalogArray[props.patternIndex] ?
            <div>
              <Dialog
      disableScrollLock={true}
                open={openShare}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                
                PaperProps={{
                  sx:{ minHeight: '300px'}
                }}
              >
                <DialogTitle sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '3', borderBottom: '1px solid rgba(0 0 0 / 10%)'}}><div style={{display: 'flex', alignItems:'center'}}><EditRounded sx={{mr: '10px'}}/>{t('catalog_item.edit')}</div><Button disabled={loadingShare} startIcon={<Close/>} onClick={handleCloseShare}><span style={{marginTop: '2px' }}>{t('common.close')}</span></Button></DialogTitle>
                <DialogContent  dividers={false}  sx={{ border: 'none!important', padding: '0px!important'}}>
              
                            <div className='sharePreviewImageWrapper' style={{background: props.theme.palette.mode === 'light' ? props.theme.palette.grey[400] : props.theme.palette.grey[900] }}>
                              {props.catalogArray[props.patternIndex]!==null && <img src={props.catalogArray[props.patternIndex].photoUrl} className='sharePreviewImage'/>}
                              
                            </div>
                          
                <Box component="form" noValidate onSubmit={(e)=>{handleSharePattern(e)}} sx={{ m: 'auto', width: 'calc(100% - 37px)', padding: '20px' }}>
                        <Grid container spacing={2}>
                        
                          <Grid item xs={12} sx={{mt: 1}}>
                            <TextField
                              key="name"
                              /* value={this.state.shareName}
                              onChange={(e)=>{this.handleChangeShareInput(e,"name")}} */
                              /* inputRef={this.shareNameRef} */
                              value={nameValue}
                              onChange={e => setNameValue(e.target.value)}
                              error={shareError !== null ? true : false}
                              helperText={shareError}
                              required
                              fullWidth
                              id="share_name"
                              label={t('share_dialog.name')}
                              name="name"
                              
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              /* ref="shareDescription" */
                              key="description"
                              /* inputRef={this.shareDescriptionRef} */
                              /* value={this.state.shareDescription}
                              onChange={(e)=>{this.handleChangeShareInput(e,"description")}} */
                              value={descriptionValue}
                              onChange={e => setDescriptionValue(e.target.value)}
                              multiline
                              minRows={4}
                              maxRows={12}
                              fullWidth
                              id="share_description"
                              label={t('share_dialog.desc')}
                              name="description"
                              
                            />
                          </Grid>
                          <Grid item xs={12}>
                              <div className='shareCategoryWrapper' style={pickedCategories.length===0 ? {padding: '14.5px 14px', border: 'none'} : {padding: '11px 14px 3px 14px', borderColor: props.theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}}>
                                {pickedCategories.length===0 && <span className='shareCategoryTextfieldText' style={{color: props.theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.7)'}}>{t('share_dialog.categories')}</span>}
                              {pickedCategories.map((category, id) => (

                                  <Chip key={"pickedCategory"+id} size='medium' sx={{mr: 1, mb: 1, width: '90px', justifyContent: 'space-between', "& .MuiChip-label": {paddingRight: '7px'}}} label={categoryTranslator[category]} variant="outlined"  onDelete={handleRemoveCategory} />

                                  ))}
                              </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div>
                              {shareCategories.map((category, id) => (

                                <Chip key={"shareCategory"+id} size='medium' color={category.checked ? 'primary' : 'default'} sx={{mr: 1, mb: 1, padding: category.checked ? '1px' : '0px'}} label={categoryTranslator[category.name]} variant={category.checked ? "filled" : "outlined"} onClick={handleSelectShareCategory} />

                              ))}
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div>
                          
                          <UploadShareImageEdit imageUrl={props.catalogArray[props.patternIndex]!==null ? sharePreviewImage : []} setUploadImage={handleSetUploadShareImage} handleRemovePreviewImage={handleRemovePreviewImage} />
                            </div>
                          </Grid>
                        </Grid>
                        
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button
                          
                          onClick={handleUnshare}
                          size='large'
                          disabled={loadingDelete}
                          variant="outlined"
                          startIcon={!loadingDelete && <DeleteRounded/>}
                          sx={{width: '250px', mt: 3, mb: 3 }}
                        >
                           {loadingDelete ? <CircularProgress size={26.25} sx={{ color: 'white' }} /> : t('share_dialog.unshare')}
                        </Button>
                        <Button
                          type="submit"
                          
                          size='large'
                          disabled={loadingShare}
                          variant="contained"
                          startIcon={!loadingShare && <SaveRounded/>}
                          sx={{ width: '250px',mt: 3, mb: 3, backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;' } }}
                        >
                           {loadingShare ? <CircularProgress size={26.25} sx={{ color: 'white' }} /> : t('share_dialog.save')}
                        </Button>
                        
                        </div>
                        
                        {/* <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center', mt: '15px' }}>
                          <Grid item sx={{ display: 'flex' }}>
                            <Typography variant="body2" color="text.secondary">
                              Ved at trykke del accepterer du Embrodia's <Link sx={{cursor: 'pointer'}} onClick={()=>{window.open("https://www.embrodia.com/privacypolicy", "_blank");}} variant="body2">
                              {t('common.privacypolicy')}
                              </Link> {t('create_profile_email.by_creating_2')} <Link sx={{cursor: 'pointer'}} onClick={()=>{window.open("https://www.embrodia.com/cookiepolicy", "_blank");}} variant="body2" >
                              {t('common.cookiepolicy')}
                              </Link></Typography>
                          </Grid>
                        </Grid> */}
                      </Box>
                </DialogContent>
              </Dialog>

            <div className='catalogItemPageWrapper'>
                <div className='catalogItemPageImageWraper'>
                <div className='catalogItemPageNavigation'>
            <Button onClick={()=>{navigate('/catalog'+props.filterSearchParams)}} startIcon={<ArrowBack/>} size='large' sx={{float: 'left',transition: 'all 150ms ease-in 0s' }}>{t('common.back_button')}</Button>
            </div>
            <div className='catalogItemPageImageWrapper'>
            {props.catalogArray[props.patternIndex].shareImageStorageUrl ?
            <Splide options={ {width: 600, height: 600, type: 'loop', autoplay: true, speed: 800 } } >
            <SplideSlide>
            <img className='catalogItemPageImage' alt='Image 1' src={props.catalogArray[props.patternIndex].photoUrl}/>
            </SplideSlide>
            {props.catalogArray[props.patternIndex].shareImageStorageUrl &&
            <SplideSlide>
             <img alt='Image 2' src={props.catalogArray[props.patternIndex].shareImageStorageUrl} className='catalogItemPageImage' style={{imageRendering: 'auto'}}/>
            </SplideSlide>
                            }
          </Splide>
            :
            <img className='catalogItemPageImage' src={props.catalogArray[props.patternIndex].photoUrl}/>}
           
            </div>

                          <div className='commentsWrapper'>
                          <div className='commentSectionHeadline'>
                          <ChatBubbleRounded sx={{mr: 1}}/>
                          <Typography sx={{m: 0}}  gutterBottom variant="h6" component="div" align='left'>
               {allComments.length+ t('catalog_item.comment_title')}
                </Typography>
                </div>
                {user &&
                <div className='writeCommentWrapper'>
                  <div className='writeCommentAuthor'>
                  <AccountCircleRounded sx={{mr: 1}}/>
                  <Typography gutterBottom variant="h7" align='left' sx={{m:0, fontWeight: '900', fontSize: '0.9rem'}}>
                    {t("catalog_item.comment_as") + user.displayName=== null ? t("catalog_item.anonymous_user") : user.displayName}
                </Typography>  
                  </div>
                <TextField
                              key="comment"
                              value={commentValue}
                              onChange={e => setCommentValue(e.target.value)}
                              multiline
                              minRows={3}
                              maxRows={8}
                              fullWidth
                              id="share_description"
                              label={t("catalog_item.write_comment")}
                              name="comment"
                              
                            />
                            <div className='writeCommentButtonWrapper'>
                            {commentValue==="" ? 
                            <Button disabled  className='no-print' size='large' variant="contained" startIcon={<SendRounded />}  sx={{ marginTop: '10px', marginBottom: '6px', width: '166px'}}/* startIcon={<ExitToAppRounded />} */ >{t('catalog_item.comment_button')}</Button>
                           : 
                           <Button onClick={handleAddComment}  className='no-print' size='large' variant="contained" startIcon={!loadingAddComment && <SendRounded />} sx={{ transition: 'box-shadow 150ms ease-in 0s',width: '166px', marginTop: '10px', marginBottom: '6px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>
                            {loadingAddComment ? <CircularProgress size={26.25} sx={{ color: 'white' }} /> : t("catalog_item.comment_button")}</Button>
                           } 
                           </div>
                </div>}
                {allComments.map((comment)=>(

                  <div className='commentWrapper'>
                  <div className='commentAuthorWrapper' style={user ? comment.uid===user.uid ? {justifyContent:'space-between'} : {justifyContent: 'flex-start'} : {justifyContent: 'flex-start'}}>
                  <div style={{display: 'flex', alignItems: 'end'}}>
                    <AccountCircleRounded sx={{mr: 1}}/>
                  <Typography gutterBottom variant="h7" align='left' sx={{m:0, fontWeight: '900', fontSize: '0.9rem'}}>
                    {comment.name=== null?"Anonym Bruger • "+ new Date(comment.date).getHours()+":"+new Date(comment.date).getMinutes()+ "  "+ new Date(comment.date).getDay()+"/" + new Date(comment.date).getMonth() + "-" + new Date(comment.date).getFullYear()
                    :
                    comment.name + " • " + new Date(comment.date).getHours()+":"+new Date(comment.date).getMinutes()+ "  "+ new Date(comment.date).getDay()+"/" + new Date(comment.date).getMonth() + "-" + new Date(comment.date).getFullYear()}
                </Typography>  
                <Typography gutterBottom variant="h7" align='left' color="text.secondary" sx={{m:0, fontSize: '0.9rem', ml: 1}}>
                    {comment.edited===true ? t('catalog_item.edited_comment') : ""}
                </Typography>  
                
                </div>
                {user && comment.uid===user.uid ? comment.editMode===true ? 
                <div><Button onClick={()=>{handleDeleteComment(comment)}} startIcon={<DeleteRounded/>} size="small" sx={{float: 'left',transition: 'all 150ms ease-in 0s' }}>{t('catalog_item.delete')}</Button><Button onClick={()=>{handleSaveComment(comment)}} startIcon={<SaveRounded/>} size="small" sx={{float: 'left',transition: 'all 150ms ease-in 0s' }}>{t('catalog_item.save')}</Button></div> : 
                <div><Button onClick={()=>{handleEditComment(comment)}} startIcon={<EditRounded/>} size="small" sx={{float: 'left',transition: 'all 150ms ease-in 0s' }}>{t('top_bar_menu.my_profile_edit_button')}</Button></div> :
                <></>}
                  </div>
                  <div className='commentTextWrapper'>
                    { comment.editMode===true ? 
                    <TextField
                              key="commentEdit"
/*                               defaultValue={comment.text} */
                              value={commentEditValue}
                              onChange={e => setCommentEditValue(e.target.value)}
                              multiline
                              minRows={2}
                              maxRows={8}
                              fullWidth
                              id="share_description"
                              label={t('catalog_item.edit_comment')}
                              name="commentEdit"
                              
                            /> 
                            :
                <Typography gutterBottom align='left'>
                    {comment.text}
                </Typography>  }
                </div>
                </div>


                ))}
                          </div>

            </div>
            <div className='catalogItemPageText' style={{textAlign: 'left', width: '460px'}}>
            <div className='catalogItemPageEdit'>
           {user && user.uid===props.catalogArray[props.patternIndex].userId===true ? /* !patternInfoHasBeenLoaded ?  */
          
           <Button onClick={handleOpenShare} startIcon={<EditRounded/>} size='large' sx={{float: 'left',transition: 'all 150ms ease-in 0s' }}>{t('top_bar_menu.my_profile_edit_button')}</Button>
          : <div></div>} 
            </div>
            <Typography gutterBottom variant="h4" sx={{mb: '0.2em'}} component="div" align='left'>
            {props.catalogArray[props.patternIndex].shareName}
                   

                </Typography>
                
                <Typography gutterBottom variant="h7" align='left' color="text.secondary">
            {t('catalog_item.by')}
                </Typography>
                <Typography gutterBottom variant="h7" align='left'>
                {props.catalogArray[props.patternIndex].userName=== null ? t('catalog.anonymous')  : props.catalogArray[props.patternIndex].userName}
                </Typography>
                 <Typography gutterBottom variant="h7" align='left' color="text.secondary">
            {" • "+props.catalogArray[props.patternIndex].shared_time.split(" ")[0].replace(",","")}
                </Typography> 
                <Typography sx={{mt: '0.5em'}}  gutterBottom variant="h6" component="div" align='left'>
                {t('catalog_item.description')}
                </Typography>
                <Typography gutterBottom variant="h7" component="div" align='left' color="text.secondary">
                {props.catalogArray[props.patternIndex].shareDescription}
                </Typography>
          <Typography gutterBottom variant="h6" component="div" align='left'>
          {t('catalog_item.info')}
                </Typography>
                <Typography gutterBottom variant="h7" component="div" align='left' color="text.secondary">
                {props.catalogArray[props.patternIndex].stitchHeight + " x " + props.catalogArray[props.patternIndex].stitchWidth + ", " +props.catalogArray[props.patternIndex].totalStitches+ t('catalog.stitches')+" • "+props.catalogArray[props.patternIndex].cmHeight+" x "+props.catalogArray[props.patternIndex].cmWidth+ " cm "+t('catalog_item.with') + props.catalogArray[props.patternIndex].fabric}
                </Typography>
                <Typography gutterBottom variant="h7" component="div" align='left' color="text.secondary">
                {props.catalogArray[props.patternIndex].colorCount + t('catalog.col')}
                </Typography>
                <Typography gutterBottom variant="h6" component="div" align='left'>
                {t('catalog_item.categories')}
                </Typography>
    {JSON.parse(props.catalogArray[props.patternIndex].shareCategories).length>0 && JSON.parse(props.catalogArray[props.patternIndex].shareCategories).map((category, id)=>(
        <Chip key={"categoryChip"+id} label={categoryTranslator[category]} sx={{mr: '8px'}} onClick={()=>{navigate('/catalog?filter='+category)}}/>

    ))}
    <div onClick={user ? handleSetFavorite : ()=>{navigate('/login')}} className='favoriteButtonCatalogItem' >
    { props.catalogArray[props.patternIndex].favorited===true ? 
                   <FavoriteRounded sx={{mr: 0.5}} color='primary'/> : 
                   <FavoriteBorderRounded sx={{mr: 0.5}}  />
                   }
                   <Typography gutterBottom variant="h7" sx={{m: 0}} align='left' >
                {props.catalogArray[props.patternIndex].favorited===true ? t("catalog_item.remove_favorite") : t("catalog_item.add_favorite")}
                </Typography>
                   </div>
    <div style={{display: 'flex', alignItems: 'center', marginTop: '1.25em'}}>
      <DownloadRounded color='text.secondary' sx={{opacity: '0.2'}}/>
    <Typography gutterBottom  align='left' color="text.secondary" sx={{mb: 0, fontSize: '0.8rem', opacity: '0.8'}}>
            {props.catalogArray[props.patternIndex].downloadsNumber + " downloads"}
                </Typography> 
                </div>
               {/*  <Typography gutterBottom  align='left' color="text.secondary" sx={{mb: 0, fontSize: '0.8rem', opacity: '0.8'}}>
            {"Current users have this pattern: " + JSON.parse(props.catalogArray[props.patternIndex].activeUsers)}
                </Typography>  */}
<Divider sx={{ maxWidth: '80%',mt: .5, mb: 0}}/>
    <div>
      {isLoggedIn===true ? user && 
      

      user.uid===props.catalogArray[props.patternIndex].userId===true ? 
      <div>
        <Button startIcon={<CheckRounded/>} className='no-print' size='large' variant="contained" disabled sx={{ marginTop: '18px', marginBottom: '6px'}}>{t('catalog_item.your_pattern')}</Button>
      <Button onClick={()=>{navigate('/create?open='+props.catalogArray[props.patternIndex].id)}} className='no-print' size='large' variant="contained" startIcon={<ExitToAppRounded />} sx={{ transition: 'box-shadow 150ms ease-in 0s', marginTop: '18px', marginBottom: '6px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('catalog_item.open_pattern')}</Button>
       </div> 
       : 
       
       props.catalogArray[props.patternIndex].inCollection===true ? 
       <div style={{maxWidth: '300px'}}>
       <Button startIcon={<CheckRounded/>} className='no-print' size='large' variant="contained" disabled sx={{ marginTop: '18px', marginBottom: '6px'}}>{t('catalog_item.in_collection')}</Button>
       <Button disabled={!props.catalogArray[props.patternIndex].isSubscribed} onClick={()=>{navigate('/create?open='+props.catalogArray[props.patternIndex].id)}} className='no-print' size='large' variant="contained" startIcon={<ExitToAppRounded />} sx={{ transition: 'box-shadow 150ms ease-in 0s', marginTop: '18px', marginBottom: '6px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('catalog_item.open_pattern')}</Button>
       </div> 
       : 
       props.catalogArray[props.patternIndex].isSubscribed===true ?
       <Button onClick={handleAddToCollection} className='no-print' size='large' variant="contained" startIcon={!loadingAddPatternToCollection && <DownloadRounded />} sx={{ width: '254px', height: '42px', transition: 'box-shadow 150ms ease-in 0s', marginTop: '18px', marginBottom: '6px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{loadingAddPatternToCollection? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important', color: "#fff" }} /> : t('catalog_item.add')}</Button> 
       : <Button onClick={()=>{ if(user){navigate('/create') } else {navigate('/login') } }} className='no-print' size='large' variant="contained" startIcon={<DownloadRounded />} sx={{ transition: 'box-shadow 150ms ease-in 0s', marginTop: '18px', marginBottom: '6px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('catalog_item.get_pattern')}</Button>

      
       : 
     <Button onClick={()=>{if(user){navigate('/create') } else {navigate('/login') }}} className='no-print' size='large' variant="contained" startIcon={<DownloadRounded />} sx={{ transition: 'box-shadow 150ms ease-in 0s', marginTop: '18px', marginBottom: '6px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('catalog_item.get_pattern')}</Button>}
     </div>
    </div>
          </div>


          





          </div> :           <div className='catalogItemPageWrapper' /* style={{marginTop: '100px'}} */>

<div className='catalogItemPageImageWraper'>
      <div className='catalogItemPageNavigation'>
      {/* <Skeleton variant='rectangle' width={88} height={42} sx={{borderRadius: '12px'}}/> */}
  </div>
  <div className='catalogItemPageImageWrapper'>
  <Skeleton variant='rectangle' width={600} height={600} sx={{borderRadius: '12px'}}/>

 
  </div>


  <div className='commentsWrapper'>
                <div className='commentSectionHeadline' style={{marginTop: '-6px'}}>
                <Skeleton  width={"184px"} height={'44px'}/>
      </div>

      <div className='writeCommentWrapper' style={{marginTop: '-5px'}}>
        <div className='writeCommentAuthor' >
        <Skeleton  variant='circular' width={24} height={24} sx={{mr: 1}}/>
        <Skeleton  width={"33%"} />
        </div>
        <Skeleton width={"100%"} height={'176px'} sx={{mt: '-40px'}}/>
                  <div className='writeCommentButtonWrapper'>
                  <Skeleton  width={"166px"} height={'68px'} sx={{mt: '-38px'}}/>
                 </div>
      </div>
                </div>







  </div>
  <div style={{textAlign: 'left', width: '460px'}}>
  <div className='catalogItemPageEdit'>
  </div>
  <Skeleton sx={{mt: '0px', mb: 0}} width={"100%"} height={'60px'}/>
      
  <Skeleton sx={{mt: '0px'}} width={"50%"}/>
  <Skeleton sx={{mt: '10px'}} width={"25%"} height={'33px'}/>
  <Skeleton sx={{mt: '-20px'}} width={"100%"} height={'140px'}/>
  <Skeleton sx={{mt: '-10px'}} width={"15%"} height={'33px'}/>
  <Skeleton sx={{mt: '-10px'}} width={"75%"} height={'100px'}/>
  <Skeleton sx={{mt: '0px'}} width={"25%"} height={'33px'}/>
{/*             <Skeleton sx={{mt: '6px'}} width={"75%"}/>
  <Skeleton sx={{mt: '6px'}} width={"75%"}/>
  <Skeleton sx={{mt: '6px'}} width={"75%"}/> */}

</div>

</div>
            }
          </React.Fragment>
          
    );
  }