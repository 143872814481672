import React, { Component } from 'react'
// import PostSorting from './posts/PostSorting'
import './App.css'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Backdrop, Box, CircularProgress, CssBaseline, Paper, Slide, Snackbar, Toolbar } from '@mui/material'
import './components/Header/Header.css'
import ImgDropAndCrop from './learn/ImgDropAndCrop'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import SignUp from './SignUp'
import SignIn from './SignIn'
import { auth } from './firebase'
import { signOut } from 'firebase/auth'
import MuiAlert from '@mui/material/Alert';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedRouteSubscription from './components/ProtectedRouteSubscription';
import { userAuthContext, UserAuthContextProvider, useUserAuth } from './UserAuthContext';
import SignUpWithEmail from './SignUpWithEmail'
import ForgotPassword from './ForgotPassword'
import RedirectPage from './RedirectPage'
import { Dialog, DialogActions, DialogTitle, DialogContent, Typography, Button } from '@mui/material'
import { Link } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { CookieRounded, DescriptionRounded, ExpandMore, PolicyRounded, Close, SaveRounded } from '@mui/icons-material'
import About from './About'
import Policies from './Policies'
import Welcome from './Welcome'
import Help from './Help'
import { withTranslation, Trans } from 'react-i18next';
import AccountMenu from './AccountMenu'
import NotFoundPage from './404Page'
import Catalog from './Catalog'
import TopBar from './TopBar'
import CatalogItem from './CatalogItem'
import EnhancedTablePatterns from './savedPatternsTable'
import { getDocs, collection, query, where, orderBy, deleteDoc, doc, updateDoc, getDoc } from "@firebase/firestore";
import { db, storage as storageRef } from './firebase';
import { ref, uploadBytes, getDownloadURL, getMetadata, deleteObject } from "@firebase/storage";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const lngs = {
  en: { nativeName: 'English' },
  da: { nativeName: 'Dansk' }
};

class App extends Component {

  static contextType = userAuthContext;

  constructor (props) {
    super(props)
    this.state = {
      navigate: false,
      openCookiePolicy: false,
      user: {},
      leftDrawer: false,
      openLogIn: false,
      openCreateProfile: false,
      loadSignUp: false,
      loadSignIn: false,
      openSnackbar: false,
      snackbarSeverity: "error",
      snackbarMessage: "Nothing",
      openCookie: false,
      fullscreenLoading: false,
      allSavedPatterns: [],
      savedPatterns: [],
      catalogArray: [],
      catalogFilterArray: [],
      patternIndex: "",
      filterSearchParams: "",
      sortValue: "nye",
      statistikcookies: false,
      navigateProp: false,
      madeChange: false,
      isSubscribed: false,
      settings: {
        isDarkMode: false,
        isUncapped: false,
        printSettings: {
          colorPrint: "onlySymbols"
        }/* ,
        advancedSettings: {
          symbolPicker: false
        } */
      }
    }
    this.handleOpenCreateProfile = this.handleOpenCreateProfile.bind(this);
    this.handleOpenLogIn = this.handleOpenLogIn.bind(this);
    this.handleSwapDialogues = this.handleSwapDialogues.bind(this);
    this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.handleColorPrintModeChange = this.handleColorPrintModeChange.bind(this);
    this.handleFetchAllPatterns = this.handleFetchAllPatterns.bind(this);
    this.handleFetchLatestPatterns = this.handleFetchLatestPatterns.bind(this);
    this.handleResetAllPatterns = this.handleResetAllPatterns.bind(this);
/*     this.handleSymbolPickerModeChange = this.handleSymbolPickerModeChange.bind(this); */
    this.handleSetHomeMode = this.handleSetHomeMode.bind(this);
    this.setModeRight = this.setModeRight.bind(this);
    this.handleSwitchLanguage = this-this.handleSwitchLanguage.bind(this);
    this.handleSetNavigateProp = this.handleSetNavigateProp.bind(this);
    this.handleFetchCatalogArray = this.handleFetchCatalogArray.bind(this);
    this.handleSetCatalogArray = this.handleSetCatalogArray.bind(this);
    this.handleSetSortValue = this.handleSetSortValue.bind(this);
    this.handleSetCatalogFilterArray = this.handleSetCatalogFilterArray.bind(this);
    this.handleShowAllPatternsInCatalogArray = this.handleShowAllPatternsInCatalogArray.bind(this);
    this.handleSetSearchParamsCatalogFilter = this.handleSetSearchParamsCatalogFilter.bind(this);
    this.handleSetMadeChange = this.handleSetMadeChange.bind(this);
    this.handleSetPatternIndex = this.handleSetPatternIndex.bind(this);
  }
  
  componentDidMount() {
      const settings = JSON.parse(localStorage.getItem('settings'));
      
      if(settings!==null) {
        var tempSettings = {
          ...this.state.settings,
          ...settings
        };
        if(settings.printSettings.colorPrint===false || settings.printSettings.colorPrint===true ) {
          tempSettings.printSettings.colorPrint = "onlySymbols";
        }
        this.setState({
          settings : tempSettings
        });    
      }
  
  const cookieConsentSettings = JSON.parse(localStorage.getItem('cookieSettings'));
    if(cookieConsentSettings===null) {
      this.setState({
        openCookie : true,
      });    
    }else if(cookieConsentSettings instanceof Object) {
      this.setState({
        statistikcookies : cookieConsentSettings.statistikcookies,
      });    
    }


/*     else {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      this.setState({
        isDarkMode : prefersDarkMode,
      });  
      } */

/*       if(window.location.pathname.includes('home') || window.location.pathname==="/") {
        this.setState({
          isHome: true
        });
      } */
      
}

handleResetAllPatterns = () => {
  this.setState({allSavedPatterns: [], savedPatterns: []});
}



handleSetNavigateProp = (val) => {
  this.setState({navigateProp: val});
}

handleFetchAllPatterns = async (user) => {
/*   try { */
    this.setState({fullscreenLoading: true});
    
    const q = query(collection(db, "users",user.uid+'/saved_patterns'),orderBy("created", "desc"));
    const querySnapshot = await getDocs(q);
    let arr = [];
    if(querySnapshot.size !== 0) {
      
      querySnapshot.forEach((doc) => {
          arr.push({
            a4Pages: doc.data().a4Pages,
            cmHeight: doc.data().cmHeight,
            cmWidth: doc.data().cmWidth,
            colorCount: doc.data().colorCount,
            created: doc.data().created,
            fabric: doc.data().fabric,
            name: doc.data().name,
            stitchHeight: doc.data().stitchHeight,
            stitchWidth: doc.data().stitchWidth,
            storageName: doc.data().storageName,
            photoUrl: '',
            totalStitches: doc.data().totalStitches,
            milis: doc.data().milis,
            dmcArray: doc.data().dmcArray,
            shared: doc.data().shared,
            patternId: doc.id,
            shareId: doc.data().shareId
          });
      });
    let indexesToRemove = [];
     for(let i = 0; i<arr.length; i++) {
      const imageRef = ref(storageRef, arr[i].storageName);
      await getDownloadURL(imageRef)
        .then((url) => {
            arr[i].photoUrl = url;
                }).catch((err)=> {
                  arr[i].photoUrl = '';
                  indexesToRemove.push(i);
                  switch (err.code) {
                    case 'storage/object-not-found':
                      console.log('File doesnt exist');
                      break;
                    case 'storage/unauthorized':
                      console.log('User doesnt have permission to access the object');
                      break;
                    case 'storage/canceled':
                      console.log('User canceled the upload');
                      break;
                    case 'storage/unknown':
                      console.log('Unknown error occurred, inspect the server response');
                      break;
                  }
                });
    } 
    if(indexesToRemove.length>0) {
      for (var i = indexesToRemove.length -1; i >= 0; i--) {
        arr.splice(indexesToRemove[i],1);
      }
    }
  }
  let isSubbed = false;
  if(user) {
    //Check if subscribed
    isSubbed = await this.handleCheckIsSubscribed(user);
    
  }
  this.setState({allSavedPatterns: arr, fullscreenLoading: false, isSubscribed: isSubbed });

  
  return arr;
/*   } */
/*   catch(err) {
    console.log(err.message);
  } */
}

getNumberOfDays = (start) =>{
  const date1 = new Date(start);
  const date2 = new Date();

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}


handleFetchLatestPatterns = async (user) => {

    const q = query(collection(db, "users",user.uid+'/saved_patterns'),orderBy("milis", "desc"));
    const querySnapshot = await getDocs(q);
    let stateArray = [];
    if(querySnapshot.size !== 0) {
      let arr = [];
      let count = 0;
      querySnapshot.forEach((doc) => {
        if(count<3)
        {
          arr.push({storageName :doc.data().storageName, shared: doc.data().shared, date: doc.data().created});
          count++;
        }
      });
    
    for(let i = 0; i<arr.length; i++) {
      let daysAgo = 0;
      const imageRef = ref(storageRef, arr[i].storageName);
      if(arr[i].shared !==undefined)
      {
        if(arr[i].shared ===true) {
          daysAgo = this.getNumberOfDays(arr[i].date);
        } else {
          let dateCreated = null;
          await getMetadata(imageRef).then((data)=> {
            dateCreated = data;
          }).catch((err)=>{
            dateCreated = null;
            console.log(err.message)});
            if(dateCreated!==null) {
              daysAgo = this.getNumberOfDays(dateCreated.timeCreated);
            }
          
        }
      } else {
        let dateCreated = null;
        await getMetadata(imageRef).then((data)=> {
          dateCreated = data;
        }).catch((err)=>{
          dateCreated = null;
          console.log(err.message)});
          if(dateCreated!==null) {
            daysAgo = this.getNumberOfDays(dateCreated.timeCreated);
          }
      }

      await getDownloadURL(imageRef)
        .then((url) => {
            stateArray.push({photoUrl: url, created: daysAgo, shared: arr[i].shared });
                })
                .catch((error) => {
                  switch (error.code) {
                    case 'storage/object-not-found':
                      console.log('File doesnt exist');
                      break;
                    case 'storage/unauthorized':
                      console.log('User doesnt have permission to access the object');
                      break;
                    case 'storage/canceled':
                      console.log('User canceled the upload');
                      break;
                    case 'storage/unknown':
                      console.log('Unknown error occurred, inspect the server response');
                      break;
                  }
                });
    }
  this.setState({savedPatterns: stateArray});
  }

}
  
handleSwitchLanguage = event => {
  if (event) event.preventDefault();
  
}

handleGoHome = () => {
  this.setState({
    navigate: true
  })
}

  handleModeChange = event => {
    if (event) event.preventDefault();
    let dark = this.state.settings.isDarkMode;
    let settings = this.state.settings;
    switch(dark) {
      case false:
          settings.isDarkMode = true;
          localStorage.setItem('settings', JSON.stringify(settings));
          this.setState({ settings: settings });
        break;
      case true:
        settings.isDarkMode = false;
        localStorage.setItem('settings', JSON.stringify(settings));
        this.setState({ settings: settings });
      break;
      default:
        settings.isDarkMode = false;
        localStorage.setItem('settings', JSON.stringify(settings));
        this.setState({ settings: settings });
      break;
    }
  }

  handleChangeCappedPatternSetting = event => {
    if (event) event.preventDefault();
    let capped = this.state.settings.isUncapped;
    let settings = this.state.settings;
    switch(capped) {
      case false:
          settings.isUncapped = true;
          localStorage.setItem('settings', JSON.stringify(settings));
          this.setState({ settings: settings });
        break;
      case true:
        settings.isUncapped = false;
        localStorage.setItem('settings', JSON.stringify(settings));
        this.setState({ settings: settings });
      break;
      default:
        settings.isUncapped = false;
        localStorage.setItem('settings', JSON.stringify(settings));
        this.setState({ settings: settings });
      break;
    }
  }

  handleColorPrintModeChange = (event, mode) => {
    if (event) event.preventDefault();
    let settings = this.state.settings;
    settings.printSettings.colorPrint = mode;
    localStorage.setItem('settings', JSON.stringify(settings));
    this.setState({ settings: settings });
  }

/*   handleSymbolPickerModeChange = event => {
    if (event) event.preventDefault();
    let symbolPicker = this.state.settings.advancedSettings.symbolPicker;
    let settings = this.state.settings;
    switch(symbolPicker) {
      case false:
          settings.advancedSettings.symbolPicker = true;
          localStorage.setItem('settings', JSON.stringify(settings));
          this.setState({ settings: settings });
        break;
      case true:
        settings.advancedSettings.symbolPicker = false;
        localStorage.setItem('settings', JSON.stringify(settings));
        this.setState({ settings: settings });
      break;
      default:
        settings.advancedSettings.symbolPicker = false;
        localStorage.setItem('settings', JSON.stringify(settings));
        this.setState({ settings: settings });
      break;
    }
  } */

  

  handleSetHomeMode = (e) => {
    if(e) e.preventDefault();
    let settings = this.state.settings;
    settings.isDarkMode = false;
    this.setState({ settings: settings });
  }


  handleSetSortValue = (sortVal) => {
    this.setState({
      sortValue: sortVal
    });
  }

  handleSetMadeChange = (val) => {
    this.setState({
      madeChange: val
    });
  }

  handleSetPatternIndex = (index) => {
    this.setState({
      patternIndex: index
    });
  }

  handleSetCatalogArray = (catArr) => {
    this.setState({
      catalogArray: catArr
    });
  }


  handleSetSearchParamsCatalogFilter = (searchParams) => {
    this.setState({
      filterSearchParams: searchParams
    });
  }


  handleSetCatalogFilterArray = (filterArr) => {
    this.setState({
      catalogFilterArray: filterArr
    });
  }


  handleShowAllPatternsInCatalogArray = () => {
    var catArr = this.state.catalogArray;
    catArr.forEach((pattern)=>{
      pattern.show = true;
    });
    this.handleSetCatalogArray(catArr);
  }

  handleFetchCatalogArray = async (user, sortVal) => {
      //console.log("Fetching....")
      let q = null;
      switch(sortVal) {
        case "nye":
          q = query(collection(db, "shared_patterns"),orderBy("share_milis", "desc"));
          break;
        case "gamle":
          q = query(collection(db, "shared_patterns"),orderBy("share_milis", "asc"));
          break;
          case "popularitet":
          q = query(collection(db, "shared_patterns"),orderBy("downloadsNumber", "desc"));
          break;
        case "korssting":
          q = query(collection(db, "shared_patterns"),orderBy("totalStitches", "asc"));
        break;
        case "farver":
          q = query(collection(db, "shared_patterns"),orderBy("colorCount", "asc"));
          break;
        default:
          q = query(collection(db, "shared_patterns"),orderBy("share_milis", "desc"));
          break;
      }
      let isSubbed = false;
      if(user) {
        //Check if subscribed
        isSubbed = await this.handleCheckIsSubscribed(user);
        
      }

      let arr = [];
      const querySnapshot = await getDocs(q);
      /* var parentElement = document.getElementById(""); */
      if(querySnapshot.size !== 0) {

        querySnapshot.forEach((doc) => {
            arr.push({
              a4Pages: doc.data().a4Pages,
              cmHeight: doc.data().cmHeight,
              cmWidth: doc.data().cmWidth,
              colorCount: doc.data().colorCount,
              shared_time: doc.data().shared_time,
              fabric: doc.data().fabric,
              name: doc.data().name,
              stitchHeight: doc.data().stitchHeight,
              stitchWidth: doc.data().stitchWidth,
              storageName: doc.data().storageName,
              photoUrl: '',
              shareImageStorageUrl: '',
              totalStitches: doc.data().totalStitches,
              share_milis: doc.data().share_milis,
              dmcArray: doc.data().dmcArray,
              dbRef: doc.ref,
              shareName: doc.data().share_name,
              shareDescription: doc.data().share_description,
              shareCategories: doc.data().share_categories,
              shareImageStorage: doc.data().share_image_storage,
              userName: doc.data().userName,
              downloads: doc.data().downloads,
              downloadsNumber: doc.data().downloadsNumber,
              activeUsers: doc.data().activeUsers,
              userId: doc.data().userId,
              show: true,
              favorited: false,
              inCollection: false,
              isSubscribed: isSubbed,
              id: doc.id,
              sensitiveContent: doc.data().sensitiveContent
            });
        });
        let indexesToRemove = [];
      for(let i = 0; i<arr.length; i++) {
        let sTime = arr[i].shared_time;
        if(sTime.includes(".")) {
          let dateArr = sTime.split(".");
          arr[i].shared_time = dateArr[1]+"/"+dateArr[0]+"/"+dateArr[2]
        }
        const imageRef = ref(storageRef, arr[i].storageName);
        await getDownloadURL(imageRef)
          .then((url) => {
              arr[i].photoUrl = url;
                  }).catch((err)=> {
                    arr[i].photoUrl = '';
                  indexesToRemove.push(i);
                  switch (err.code) {
                    case 'storage/object-not-found':
                      console.log('File doesnt exist');
                      break;
                    case 'storage/unauthorized':
                      console.log('User doesnt have permission to access the object');
                      break;
                    case 'storage/canceled':
                      console.log('User canceled the upload');
                      break;
                    case 'storage/unknown':
                      console.log('Unknown error occurred, inspect the server response');
                      break;
                  }
                  })
                  if(arr[i].shareImageStorage) {
        const imageShareRef = ref(storageRef, 'finished_embroideries/'+arr[i].shareImageStorage);
        await getDownloadURL(imageShareRef)
          .then((url) => {
              arr[i].shareImageStorageUrl = url;
                  }).catch((err)=> {
                    arr[i].shareImageStorageUrl = '';
                    switch (err.code) {
                      case 'storage/object-not-found':
                        console.log('File doesnt exist');
                        break;
                      case 'storage/unauthorized':
                        console.log('User doesnt have permission to access the object');
                        break;
                      case 'storage/canceled':
                        console.log('User canceled the upload');
                        break;
                      case 'storage/unknown':
                        console.log('Unknown error occurred, inspect the server response');
                        break;
                    }
                  });
                }


      }
      if(indexesToRemove.length>0) {
        for (var i = indexesToRemove.length -1; i >= 0; i--) {
          arr.splice(indexesToRemove[i],1);
        }
      }

      //Check which is favorited

      if(user) {
        //console.log("Setting favorites");
      const docSnap = collection(db, "users",user.uid+'/favorite_patterns');
      const querySnap = await getDocs(docSnap);
      if(querySnap.size !== 0) {
        querySnap.forEach((doc)=>{
          arr.forEach((pattern)=>{
            if(pattern.id===doc.id) {
              pattern.favorited = true;
            }
          })
        })
      }



      //Check which is in collection
      
              arr.forEach((pattern)=>{
                if(pattern.activeUsers!==undefined) {

                
                let activeUserVar = JSON.parse(pattern.activeUsers);
                if(activeUserVar.length>0){
                  if(activeUserVar.includes(user.uid)) {
                    pattern.inCollection = true;
                  }
                }
              }
                
              })
            
            
    }

      //console.log(window.location.pathname);


    }

    this.setState({
    catalogArray: arr,
    isSubscribed: isSubbed
    });
    //console.log("returning catalog array!");
    return arr;
    //handleSelectCategoryFilterLogicInit(filterArr,arr, searchVal);
  }
  

  setModeRight = (e) => {
    if(e) e.preventDefault();
    const settings = JSON.parse(localStorage.getItem('settings'));
    if(settings!==null) {
      var tempSettings = {
        ...this.state.settings,
        ...settings
      };
      this.setState({
        settings : tempSettings
      });    
    }
  }

  handleLogOut = () => {
    signOut(auth);
    this.handleOpenSnackbar("success", this.props.t('snackbars.signout'));
  }

  toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ leftDrawer: open });
  };


  handleCheckIsSubscribed = async (user) => {
    let isSubbed = false 

    const q2 = query(collection(db, "users", user.uid + '/payments'), where('metadata.valid', '!=', '0'));
    const query2Snapshot = await getDocs(q2);
    if(query2Snapshot.size !== 0) {
      isSubbed = true;
    }

    const q = query(collection(db, "users",user.uid+'/subscriptions'), where('status', 'in', ['trialing', 'active']));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size !== 0) {
      isSubbed = true;
    }
    return isSubbed;
  }



  handleCloseSnackbar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({openSnackbar: false});
  }


  handleAcceptAllCookies = () => {
    this.setState({statistikcookies: true});
    let settings = {nødvendige: true, statistikcookies: true};
    localStorage.setItem('cookieSettings', JSON.stringify(settings));
    setTimeout((e)=> {this.handleCloseCookie(e)}, 100);
  }

  handleConfirmNecessaryCookies = () => {
    let settings = {nødvendige: true, statistikcookies: this.state.statistikcookies};
    localStorage.setItem('cookieSettings', JSON.stringify(settings));
    setTimeout((e)=> {this.handleCloseCookie(e)}, 100);
  }

  handleCloseCookie = (e) => {
    if(e) e.preventDefault();
    this.setState({openCookie: false});
  }

  handleOpenCookiePolicy = (e) => {
    if(e) e.preventDefault();
    this.setState({openCookiePolicy: true});
  }

  handleCloseCookiePolicy = (e) => {
    if(e) e.preventDefault();
    this.setState({openCookiePolicy: false});
  }



  handleStatistikcookiesSwitch = () => {
    var temp = this.state.statistikcookies;
    temp = !temp;
    this.setState({statistikcookies: temp});
  }

    handleOpenSnackbar = (severity, message) => {
      this.setState({openSnackbar: true, snackbarSeverity: severity, snackbarMessage: message, loadSignIn: false, loadSignUp: false});
    }

  handleOpenLogIn = () => {
    this.setState({ openLogIn: !this.state.openLogIn });
  };

  handleSwapDialogues = () => {
    this.setState({ openLogIn: !this.state.openLogIn,
    openCreateProfile: !this.state.openCreateProfile });
  };

  handleOpenCreateProfile = () => {
    this.setState({ openCreateProfile: !this.state.openCreateProfile });
  };





  render () {
    const {navigate} = this.state;
    if (navigate) {
      return <Navigate to="/home" />
    }
    const { t } = this.props;
    
    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
      width: 62,
      height: 34,
      padding: 7,
      '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
          color: '#fff',
          transform: 'translateX(22px)',
          '& .MuiSwitch-thumb:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
              '#fff',
            )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
          },
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
          },
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
      },
      '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
      },
    }));



    const theme = createTheme({
      //  shape: {
      //    borderRadius: 8,
      //  }, 
/*       components: {
         MuiBackdrop: {
          styleOverrides: {
            root: {
              backgroundColor: "rgba(0,0,0,0.25)",
            },
          },
        }, 
      }, */
      palette: {
        appBar: {
          backgroundColor: 'white'
        },
        mode: this.state.settings.isDarkMode ? 'dark' : 'light',
        primary: {
          main: 'rgb(233, 30, 99)',
        },
        secondary: {
          main: '#f44336',
        },
        ...(this.state.settings.isDarkMode === true && {
          background: {
            default: 'rgb(40,40,40)',
            paper: 'rgb(45,45,45)',
            
          },
        }),
      },
    });

    const policiesData = [
    {
      title: t('common.privacypolicy'),
      sections: [
        {
          title: t('policy_privacy.title_1'),
          text: [t('policy_privacy.text_1_1'),
                  t('policy_privacy.text_1_2'),
                  t('policy_privacy.text_1_3')]
        },
        {
          title: t('policy_privacy.title_2'),
          text: [t('policy_privacy.text_2_1'),
          t('policy_privacy.text_2_2'),
          t('policy_privacy.text_2_3')]
        },
        {
          title: t('policy_privacy.title_3'),
          text: [t('policy_privacy.text_3')]
        },
        {
          title: t('policy_privacy.title_4'),
          text: [t('policy_privacy.text_4')]
        },
        {
          title: t('policy_privacy.title_5'),
          text: [t('policy_privacy.text_5')]
        },
        {
          title: t('policy_privacy.title_6'),
          text: [t('policy_privacy.text_6_1'),
          t('policy_privacy.text_6_2'),
          t('policy_privacy.text_6_3'),
          t('policy_privacy.text_6_4'),
          t('policy_privacy.text_6_5')]
        },
        {
          title: t('policy_privacy.title_7'),
          text: [t('policy_privacy.text_7_1'),
          t('policy_privacy.text_7_2')]
        }
      ]
    },
    {
      title: t('common.terms'),
      sections: [
        {
          title: t('policy_terms.title_1'),
          text: [t('policy_terms.text_1_1'),
          /* t('policy_terms.text_1_2'), */
          t('policy_terms.text_1_3')]
        },
        {
          title: t('policy_terms.title_2'),
          text: [t('policy_terms.text_2_1'),
          t('policy_terms.text_2_2'),
          t('policy_terms.text_2_3')]
        },
        {
          title: t('policy_terms.title_3'),
          text: [t('policy_terms.text_3')]
        },
        {
          title: t('policy_terms.title_4'),
          text: [t('policy_terms.text_4_1'),
          t('policy_terms.text_4_2')]
        },
        {
          title: t('policy_terms.title_5'),
          text: [t('policy_terms.text_5_1'),
          t('policy_terms.text_5_2')]
        },
/*         {
          title: 'Prøveperioder',
          text: ['Vi kan tilbyde nye kunder et prøveabonnement, fx uden omkostninger eller til en reduceret pris i en bestemt periode (“Prøveperioden“). For at benytte dig af Prøveperioden må du eller andre i din husstand ikke tidligere have benyttet sig af en Prøveperiode eller andre lignende markedsføringstilbud fra os. Hvis du allerede har benyttet dig af en Prøveperiode, vil der blive trukket penge fra dit betalingsmiddel, som om du købte et standardabonnement. Vi forbeholder os retten til at kontrollere, om du overholder kravene baseret på alle de oplysninger, du har givet os.',
          'Når Prøveperioden udløber, vil Prøveperioden automatisk blive ændret til et abonnement mod betaling med eller uden en bindingsperiode, medmindre du fortryder eller opsiger abonnementet, før Prøveperioden slutter. Du kan fortryde eller opsige via “Min Profil” i øverste højre hjørne under “Mit abonnement”.']
        }, */
        {
          title: t('policy_terms.title_6'),
          text: [t('policy_terms.text_6_1'),
          t('policy_terms.text_6_2'),
          t('policy_terms.text_6_3')]
        },
      ]
    },
    {
      title: t('common.cookiepolicy'),
      sections: [
        {
          title: t('policy_cookie.title_1'),
          text: [t('policy_cookie.text_1')]
        },
        {
          title: t('policy_cookie.title_2'),
          text: [t('policy_cookie.text_2_1'),
          t('policy_cookie.text_2_2'),
          t('policy_cookie.text_2_3'),
          t('policy_cookie.text_2_4'),
          t('policy_cookie.text_2_5'),
          t('policy_cookie.text_2_6'),
          t('policy_cookie.text_2_7')]
        },
        {
          title: t('policy_cookie.title_3'),
          text: []
        },
        {
          title: t('policy_cookie.title_4'),
          text: [t('policy_cookie.text_4_1')
          ,t('policy_cookie.text_4_2')]
        },
        {
          title: t('policy_cookie.title_5'),
          text: [t('policy_cookie.text_5_1'),
          t('policy_cookie.text_5_2'),
          t('policy_cookie.text_2_3')]
        },
        {
          title: t('policy_cookie.title_6'),
          text: [t('policy_cookie.text_6')]
        },
        {
          title: t('policy_cookie.title_7'),
          text: [t('policy_cookie.text_7')]
        },
        {
          title: t('policy_cookie.title_8'),
          text: [t('policy_cookie.text_8_1'),
          t('policy_cookie.text_8_2'),
          t('policy_cookie.text_8_3')]
        },
        {
          title: t('policy_cookie.title_9'),
          text: [t('policy_cookie.text_9_1'),
          t('policy_cookie.text_9_2')]
        },
      ]
    }
  ]


    return (
      <div className='App'>
        <ThemeProvider theme={theme}>
        <CssBaseline />
          <TopBar isSubscribed={this.state.isSubscribed} handleFetchCatalogArray={this.handleFetchCatalogArray} handleFetchLatestPatterns={this.handleFetchLatestPatterns} handleSetCatalogArray={this.handleSetCatalogArray} handleSetNavigateProp={this.handleSetNavigateProp} theme={theme} settings={this.state.settings} handleResetAllPatterns={this.handleResetAllPatterns} handleFetchAllPatterns={this.handleFetchAllPatterns} allSavedPatterns={this.state.allSavedPatterns} handleSetHomeMode = {this.handleSetHomeMode} handleSwitchLanguage={this.handleSwitchLanguage} handleModeChange={this.handleModeChange} handleChangeCappedPatternSetting={this.handleChangeCappedPatternSetting} handleOpenSnackbar={this.handleOpenSnackbar} handleOpenSavedPatterns={this.handleOpenSavedPatterns} lngs={lngs}/>
        
      <Routes>
      <Route path={'/'} element={<RedirectPage/>}/>
      <Route path={'*'} element={<NotFoundPage/>}/>
        <Route path={'home'} element={<Home theme={theme}  settings={this.state.settings} handleSetHomeMode = {this.handleSetHomeMode} handleSwitchLanguage={this.handleSwitchLanguage} handleModeChange={this.handleModeChange} handleOpenSnackbar={this.handleOpenSnackbar} lngs={lngs}/>}/>
        <Route path={'about'} element={<About />}/>
        <Route path={'help'} element={<Help />}/>
        <Route path={'privacypolicy'} element={<Policies icon={<PolicyRounded/>} title={policiesData[0].title} sections={policiesData[0].sections}/>}/>
        <Route path={'termsandconditions'} element={<Policies icon={<DescriptionRounded/>} title={policiesData[1].title} sections={policiesData[1].sections} />}/>
        <Route path={'cookiepolicy'} element={<Policies icon={<CookieRounded/>} title={policiesData[2].title} sections={policiesData[2].sections} />}/>
        <Route path={'signup'} element={<SignUp handleOpenSnackbar={this.handleOpenSnackbar} />}/>
        <Route path={'signup/email'} element={<SignUpWithEmail handleOpenSnackbar={this.handleOpenSnackbar} />}/>
          <Route path={'login'} element={<SignIn handleOpenSnackbar={this.handleOpenSnackbar} />}/>
          <Route path={'login/forgotpassword'} element={<ForgotPassword handleOpenSnackbar={this.handleOpenSnackbar} />}/>
{/*           <Route path={'/welcome'} element={<ProtectedRouteSubscription><Welcome handleOpenSnackbar={this.handleOpenSnackbar}/></ProtectedRouteSubscription>}/> */}
          <Route path={'create'} element={<ProtectedRoute><ImgDropAndCrop handleSetNavigateProp={this.handleSetNavigateProp} navigateProp={this.state.navigateProp} handleResetAllPatterns={this.handleResetAllPatterns} savedPatterns={this.state.savedPatterns} allSavedPatterns={this.state.allSavedPatterns} freeMode={false} theme={theme} settings={this.state.settings} setModeRight={this.setModeRight} handleModeChange={this.handleModeChange} handleSwitchLanguage={this.handleSwitchLanguage} handleColorPrintModeChange={this.handleColorPrintModeChange} handleFetchAllPatterns={this.handleFetchAllPatterns} handleFetchLatestPatterns={this.handleFetchLatestPatterns} /* handleSymbolPickerModeChange={this.handleSymbolPickerModeChange} */ handleOpenSnackbar={this.handleOpenSnackbar}/></ProtectedRoute>}/>
          <Route path={'createfree'} element={<ImgDropAndCrop   freeMode={true} handleSetNavigateProp={this.handleSetNavigateProp} navigateProp={this.state.navigateProp} handleResetAllPatterns={this.handleResetAllPatterns} savedPatterns={this.state.savedPatterns} allSavedPatterns={this.state.allSavedPatterns}  theme={theme} settings={this.state.settings} setModeRight={this.setModeRight} handleModeChange={this.handleModeChange} handleSwitchLanguage={this.handleSwitchLanguage} handleColorPrintModeChange={this.handleColorPrintModeChange} handleFetchAllPatterns={this.handleFetchAllPatterns} handleFetchLatestPatterns={this.handleFetchLatestPatterns} /* handleSymbolPickerModeChange={this.handleSymbolPickerModeChange} */ handleOpenSnackbar={this.handleOpenSnackbar}/>}/>
          <Route path={'catalog'} element={<Catalog handleSetPatternIndex={this.handleSetPatternIndex} handleSetSearchParamsCatalogFilter={this.handleSetSearchParamsCatalogFilter} filterArray={this.state.catalogFilterArray} handleSetFilterArray={this.handleSetCatalogFilterArray} handleShowAllPatternsInCatalogArray={this.handleShowAllPatternsInCatalogArray} sortValue={this.state.sortValue} handleSetSortValue={this.handleSetSortValue} handleFetchCatalogArray={this.handleFetchCatalogArray} handleSetCatalogArray={this.handleSetCatalogArray} catalogArray={this.state.catalogArray} theme={theme} settings={this.state.settings} handleSetHomeMode = {this.handleSetHomeMode} handleSwitchLanguage={this.handleSwitchLanguage} handleModeChange={this.handleModeChange} handleOpenSnackbar={this.handleOpenSnackbar} lngs={lngs}/>}/>
          <Route path={'catalog/:id'} element={<CatalogItem handleSetPatternIndex={this.handleSetPatternIndex} handleFetchCatalogArray={this.handleFetchCatalogArray} patternIndex={this.state.patternIndex} catalogArray={this.state.catalogArray} handleSetCatalogArray={this.handleSetCatalogArray} filterSearchParams={this.state.filterSearchParams} theme={theme} handleResetAllPatterns={this.handleResetAllPatterns}  handleOpenSnackbar={this.handleOpenSnackbar}/>}/>
            
      </Routes>
      
      <Dialog
      sx={{maxWidth:'520px', m: 'auto'}}
      disableScrollLock={true}
                open={this.state.openCookie}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
              
              >
                <DialogTitle sx={{minWidth:'300px', display: 'flex', alignItems: 'center'}}>{t('cookie_popup.title')}</DialogTitle>
                <DialogContent dividers={true} sx={{padding: '16px 24px 0px 24px'}}>
                <Typography sx={{fontSize: '0.95rem'}} color="text.secondary"  component="div" align='left'>{t('cookie_popup.description_1')}<br></br><br></br>{t('cookie_popup.description_2')}<br></br>{t('cookie_popup.description_3')}<br></br><br></br>{t('cookie_popup.description_4')}</Typography>
                <Link sx={{cursor: 'pointer'}} onClick={this.handleOpenCookiePolicy} variant="body2" >
                    {t('cookie_popup.link')}
                  </Link>
                <div className='cookieSwitchesWrapper'>
                  <div>
                <Typography variant="h7" component="div" align='left'>
              {t('cookie_popup.cookie_type_1')}
            </Typography>
            <div className='disabledSwitchDiv'>
                  <Switch checked={true} className='disabledSwitch'/>
                  </div>
                  </div>
                  <div>
                  <Typography variant="h7" component="div" align='left'>
                  {t('cookie_popup.cookie_type_2')}
            </Typography>
            
                  <Switch checked={this.state.statistikcookies} onClick={this.handleStatistikcookiesSwitch}/>
                  </div>
                  </div>
                  <Accordion className='cookieDetaljer' elevation={0} sx={{backgroundColor: 'rgba(0 0 0 / 0%)'}}>
                            <AccordionSummary
                            sx={{display:'flex', justifyContent: 'center', width: 'fit-content', margin: 'auto'}}
                              expandIcon={<ExpandMore sx={{fontSize: '1.2rem'}} />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography  sx={{fontSize: '0.9rem'}}>{t('cookie_popup.details')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{padding: '0px 16px 16px'}}>
                            <Typography variant="h7" component="div" align='left'>
                            {t('cookie_popup.cookie_type_1')}
            </Typography>
            <Typography color="text.secondary" sx={{ textAlign: 'left', fontSize: '14px' }}>
            {t('cookie_popup.cookie_type_1_description')}
                              </Typography>
            <Typography sx={{marginTop: '10px'}} variant="h7" component="div" align='left'>
            {t('cookie_popup.cookie_type_2')}
            </Typography>
                              <Typography color="text.secondary" sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('cookie_popup.cookie_type_2_description')}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                </DialogContent>
                <DialogActions className='cookieButtons'>
                  <Button size='large' variant="contained" onClick={this.handleAcceptAllCookies} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('cookie_popup.button_1')}</Button>
                  <Button onClick={this.handleConfirmNecessaryCookies} size='large' variant="outlined"  sx={{ transition: 'background-color border 150ms ease-in 0s', margin: '8px', }}>{t('cookie_popup.button_2')}</Button>
                </DialogActions>
              </Dialog>

              <Dialog
      sx={{maxWidth:'520px', m: 'auto', height: '900px'}}
      disableScrollLock={true}
                open={this.state.openCookiePolicy}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
              
              >
                <DialogTitle /* dividers={true} */ sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}><div style={{display: 'flex', alignItems:'center', justifyContent:'space-between'}}>{"Cookiepolitik"}</div><Button startIcon={<Close/>} onClick={this.handleCloseCookiePolicy}><span style={{marginTop: '2px'}}>luk</span></Button></DialogTitle>
                <DialogContent dividers={true} sx={{padding: '16px 24px 0px 24px'}}>
                {policiesData[2].sections.map((section, i)=>(
              <div key={i}>
          <Typography align='left' component="div" variant="h6">
          {section.title}
          
          </Typography>
          {section.text.map((textsection, i)=>(
            <Typography key={i} sx={{mt: '5px', mb: section.text.length===i+1 ? '14px' : '0px'}} align='left' color="text.secondary" component="div" variant="h7">
            {textsection}
            </Typography>
          ))}
            </div>
            ))}
                </DialogContent>
              </Dialog>
          <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} TransitionComponent={Slide}

      ><Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
      {this.state.snackbarMessage}
    </Alert></Snackbar>
    <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.fullscreenLoading}
        >
          <CircularProgress disableShrink={true} color="inherit" />
        </Backdrop>
        </ThemeProvider>
      </div>
    )
  }
}
export default withTranslation('translation')(App)
