import { Backdrop, Chip, CircularProgress, FormControl, Grid, InputBase, InputLabel, MenuItem, Paper, Select, Skeleton, Slider, styled, alpha , TextField, Toolbar, Typography, IconButton, FormGroup, FormControlLabel, Checkbox, Button, Slide, Dialog, DialogTitle, DialogContent, Drawer } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useUserAuth } from './UserAuthContext';
import { getDocs, collection, query, where, orderBy, deleteDoc, addDoc, setDoc, doc } from "@firebase/firestore";
import { db, storage as storageRef } from './firebase';
import { ref, getDownloadURL } from "@firebase/storage";
import { CancelRounded, ColorLensRounded, DownloadRounded, FavoriteBorderRounded, FavoriteRounded, Grid3x3Rounded, InfoRounded, PersonRounded, SearchRounded, VisibilityOffRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/system';
import MuiInput from '@mui/material/Input';
import CheckboxCategoryList from './CheckboxCategoryList';
import { Helmet } from 'react-helmet-async';

const Input = styled(MuiInput)`
width: 42px;
`;

//create your forceUpdate hook
function useForceUpdate(){
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 0,
    width: 'auto',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '26ch',
      '&:focus': {
        width: '38ch',
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Catalog(props) {

  const {t, i18n} = useTranslation('translation');
  const categoryTranslator = {
    "Icon":       t('catalog.category_icon'),
    "Humor":      t('catalog.category_humor'),
    "Text":       t('catalog.category_text'),
    "Photo":      t('catalog.category_photo'),
    "Movie":      t('catalog.category_movie'),
    "Gaming":     t('catalog.category_gaming'),
    "Music":      t('catalog.category_music'),
    "Art":        t('catalog.category_art'),
    "Square":     t('catalog.category_square'),
    "Xmas":       t('catalog.category_xmas'),
    "Favorites":  t('catalog.category_favorites')
  }
  const { user } = useUserAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  // call your hook here
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState([0]);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isFirstTime, setIsFirstTime] = React.useState(true);
/*   const [catalogArray, setCatalogArray] = React.useState([]); */
  const [loadingCatalog, setLoadingCatalog] = React.useState(false);
  const children = [];  
  const [slideValue1, setSlideValue1] = React.useState(1);
  const [slideValue2, setSlideValue2] = React.useState(400);
/*   const [sortValue, setSortValue] = React.useState("nye"); */
/*   const [filterArray, setFilterArray] = React.useState([]); */
  const [fullscreenLoading, setFullScreenLoading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [favoriteFilter, setFavoriteFilter] = React.useState(false);
  const [openFiltersMobile, setOpenFiltersMobile] = React.useState(false);

  const handleChangeSlider = (event, newValue) => {
    setSlideValue1(newValue[0]);
    setSlideValue2(newValue[1]);
  };

  function valuetext(value, index) {
    return `${value}`;
  }

  const handleBlur1 = () => {
    if (slideValue1 < 1) {
      setSlideValue1(1);
    } else if (slideValue1 > 400) {
      setSlideValue1(400);
    }
  };

  const handleBlur2 = () => {
    if (slideValue2 < 1) {
      setSlideValue2(1);
    } else if (slideValue2 > 400) {
      setSlideValue2(400);
    }
  };

  const handleSetFavorite = (patternToSetFavorite) => {
    //console.log(patternToSetFavorite);
    var cat = props.catalogArray;
    //console.log(cat);
    //Set favorite to false
    if(patternToSetFavorite.favorited) {
      
       cat.forEach(pattern => {
          if(pattern.id === patternToSetFavorite.id) {
            pattern.favorited = false;
            
            handleDeleteFavorite(pattern.id);
            
          }
      });
      handleSelectCategoryFilterLogic(props.filterArray,searchValue);
      props.handleSetCatalogArray(cat);
      forceUpdate(); 
    } 
    //Set favorite to true
    else {
      
    cat.forEach(pattern => {
        if(pattern.id === patternToSetFavorite.id) {
          pattern.favorited = true;
          handleSaveFavorite(pattern.id);
        }
    });
    props.handleSetCatalogArray(cat);
    forceUpdate(); 
    }
  }

  const handleSaveFavorite = async (storageName) => {
    const docRef = doc(db, "users/"+user.uid+'/favorite_patterns', storageName);
        await setDoc(docRef, {
        storageName: storageName
        });
  }

  const handleDeleteFavorite = async (storageName) => {
    const q = query(collection(db, "users",user.uid+'/favorite_patterns'), where('storageName', '==', storageName));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size !== 0) {
      await deleteDoc(querySnapshot.docs[0].ref)
    }
  }

  const handleSortSelect = async (event) => {
      props.handleSetSortValue(event.target.value);
      setFullScreenLoading(true);
      await queryPatterns(event.target.value, props.filterArray, searchValue);
      setFullScreenLoading(false);
  }

  const handleInputChange1 = (event) => {
    setSlideValue1(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleInputChange2 = (event) => {
    setSlideValue2(event.target.value === '' ? '' : Number(event.target.value));
  };
  for(let i = 0;i < 25; i++) {
    children.push(<div className='catalogItemLoadWrapper'>
    <Skeleton className='catalogSkeleton' variant='rectangle' width={210} height={210} sx={{borderRadius: '12px'}}/>
    <Skeleton sx={{mt: '6px'}} width={"75%"}/>
    <Skeleton width={"50%"}/>
    <Skeleton width={"50%"}/>
    </div>); 
  }
  React.useEffect(() =>{
    const categories = searchParams.getAll('filter');

      props.handleSetFilterArray(categories);
    
    
    const searchVal = searchParams.get("search");
    if(searchVal!==null && searchVal!==undefined)
    {
      setSearchValue(searchVal);
    }
    let checkedArr = checked;
    let newChecked = [...checkedArr];
    categories.forEach((cat)=>{
      const currentIndex = checkedArr.indexOf(cat);
      newChecked = [...checkedArr];
      if (currentIndex === -1) {
        newChecked.push(cat);
      }
      checkedArr = newChecked;
      if(cat==='Favorites') {
        setFavoriteFilter(true);
      }
    })
    setChecked(newChecked);

    if(user)
    {

      setIsLoggedIn(true);
      if(isFirstTime) {
        if(!props.catalogArray.length>0)
        {
          setLoadingCatalog(true);
        
          queryPatterns(props.sortValue, categories, searchVal);

        } else {
          handleSelectCategoryFilterLogicInit(categories,props.catalogArray, searchVal);
        } /* else {
          props.handleShowAllPatternsInCatalogArray();
        } */
        

        setIsFirstTime(false);
      }
    } else {
      if(!props.catalogArray.length>0)
      {
        //setFullScreenLoading(true);
        setLoadingCatalog(true);
        queryPatterns(props.sortValue, categories, searchVal);
      } else {
        handleSelectCategoryFilterLogicInit(categories,props.catalogArray, searchVal);
      } /* else {
        props.handleShowAllPatternsInCatalogArray();
      } */

      setIsLoggedIn(false);
    }
  },[user]);

  const handleSelectCategory = async (val) => {
    let arr = props.filterArray;
    if(!arr.includes(val)) {
      arr.push(val);
    } else {
      let index = arr.indexOf(val);
      arr.splice(index,1);
    }
    var search = searchParams.get("search");
    if(search) {
      setSearchParams({"search": search, "filter": arr}, {replace: false}); 
      var searchP = "?search=";
      searchP = searchP.concat(search);
      arr.forEach((el)=>{
        searchP = searchP.concat("&filter="+el);
      });
      props.handleSetSearchParamsCatalogFilter(searchP);
    } else {
      setSearchParams({"filter": arr}, {replace: false}); 
      var searchP = "?filter=";
      
      if(arr.length!==0)
      {
        let first = true;
      arr.forEach((el)=>{
        if(first===true){
          searchP = searchP.concat(el);
        } else {
          searchP = searchP.concat("&filter="+el);
        }
        first=false;
      });
      } else {
        searchP = "";
      }
      props.handleSetSearchParamsCatalogFilter(searchP);
    }

    props.handleSetFilterArray(arr);
    setFullScreenLoading(true);
    //Get indexes from filtered array to remove
/*     let filteredCatalogArr = catalogArray;
    let indexesToRemove = [];
    filteredCatalogArr.forEach((el)=>{
      console.log(JSON.parse(el.shareCategories));
      let cats = JSON.parse(el.shareCategories);
      if(!cats.includes(val)) {
        el.show = false;

      }
    }) */
    //Remove indexes from filtered array
/*     filteredCatalogArr = filteredCatalogArr.filter(function(value, index) {
      return indexesToRemove.indexOf(index) == -1;
 }) */
    handleSelectCategoryFilterLogic(arr, searchValue);
/*     setCatalogArray(filteredCatalogArr); */
    setFullScreenLoading(false);
    forceUpdate();
  }

  const handleSelectCategoryFilterLogic = (newArr, searchVal) => {
    let catArr = props.catalogArray;
    catArr.forEach((el)=>{
        let cats = JSON.parse(el.shareCategories);
        let isPresent = false;
        let searchIsPresent = true;
        let name = el.shareName;

        //If there are filters to apply
        if(newArr.length>0) {

        if(newArr.includes('Favorites')) {

        
        newArr.forEach((fEl)=>{
          if(newArr.length===1) {
            if(fEl==='Favorites') {
              if(el.favorited===true) {
                  isPresent = true;
              } 
            }
          } else {
              if(el.favorited===true) {
                if(cats.includes(fEl)) {
                  isPresent = true;
                } 
                
              }  else {
                isPresent = false;
              }
            
          }
           
        })
      } else {
        newArr.forEach((fEl)=>{
          if(cats.includes(fEl)) {
            isPresent = true;
          }
        }) 
      }
        
        if(searchVal !=="" && name!==null && name!==undefined)
        {
          if(name.toLowerCase().includes(searchVal.toLowerCase())) {
            searchIsPresent = true;
          } else {
            searchIsPresent = false;
          }
        }
      } 
      //If there are NO filters to apply
      else {
        isPresent = true;
        if(searchVal !=="" && name!==null && name!==undefined)
        {
          if(name.toLowerCase().includes(searchVal.toLowerCase())) {
            searchIsPresent = true;
          } else {
            searchIsPresent = false;
          }
        }
      }
        if(isPresent && searchIsPresent) {
          el.show = true;
        } else {
          el.show = false;
        }
    })

    props.handleSetCatalogArray(catArr);
  }


  const handleSelectCategoryFilterLogicInit = (newArr, catArr, searchVal) => {
    //console.log("Setting things!");
    if(!catArr) {
      if(props.catalogArray.length>0) {
        catArr=props.catalogArray;
      }
    }
    //console.log(catArr);
    catArr.forEach((el)=>{
        let cats = JSON.parse(el.shareCategories);
        let isPresent = false;
        let searchIsPresent = true;
        let name = el.shareName;

//If there are filters to apply
if(newArr.length>0) {

  if(newArr.includes('Favorites')) {

  
  newArr.forEach((fEl)=>{
    if(newArr.length===1) {
      if(fEl==='Favorites') {
        if(el.favorited===true) {
            isPresent = true;
        } 
      }
    } else {
        if(el.favorited===true) {
          if(cats.includes(fEl)) {
            isPresent = true;
          } else {
/*                   console.log("Im a favorited but I dont have: "+fEl);
            isPresent = false; */
          }
          
        }  else {
          isPresent = false;
        }
      
    }
     
  })
} else {
  newArr.forEach((fEl)=>{
    if(cats.includes(fEl)) {
      isPresent = true;
    }
  }) 
}
        
        if(searchVal !=="" && searchVal!==null && name!==null && name!==undefined)
        {
          if(name.toLowerCase().includes(searchVal.toLowerCase())) {
            searchIsPresent = true;
          } else {
            searchIsPresent = false;
          }
        }

      } 
      //If there are no filters to apply
      else {
        isPresent = true;
        if(searchVal !=="" && searchVal!==null  &&  name!==null && name!==undefined)
        {
          if(name.toLowerCase().includes(searchVal.toLowerCase())) {
            searchIsPresent = true;
          } else {
            searchIsPresent = false;
          }
        }
      }
        if(isPresent && searchIsPresent) {
          el.show = true;
        } else {
          el.show = false;
        }
    })

    props.handleSetCatalogArray(catArr);
  }

  const handleRemoveCategory = (event) => {
    event.preventDefault();
    var target = null;
    if(event.target.tagName==="svg") {
      target = event.target.parentElement.children[0];
    } else {
      target = event.target.parentElement.parentElement.children[0];
    }
    let arr = props.filterArray;
    var translatedFilterArr = [];
    const newChecked = [...checked];
    arr.forEach((filter)=>{
      translatedFilterArr.push(categoryTranslator[filter]);
    if(translatedFilterArr.includes(target.innerHTML)) {
      if(categoryTranslator[filter]===target.innerHTML) {

      
      const currentIndex = checked.indexOf(filter);
      
      newChecked.splice(currentIndex, 1);

      //Get indexes from original array to bring back
/*       let orgArr = catalogArray;
      let filteredArr = filteredCatalogArray;
      catalogArray.forEach((el)=>{
        let cats = JSON.parse(el.shareCategories);
        if(cats.includes(target.innerHTML)) {
          let index = catalogArray.indexOf(el);
          filteredArr.splice(index, 0, el);
        }
      }) */
      let index = translatedFilterArr.indexOf(target.innerHTML);
      arr.splice(index,1);

    }
    }

    });
  

      handleSelectCategoryFilterLogic(arr,searchValue);
      var search = searchParams.get("search");
      if(search) {
        setSearchParams({"search": search, "filter": arr}, {replace: false}); 
        var searchP = "?search=";
        searchP = searchP.concat(search);
        arr.forEach((el)=>{
          searchP = searchP.concat("&filter="+el);
        });
        props.handleSetSearchParamsCatalogFilter(searchP);
      } else {
        setSearchParams({"filter": arr}, {replace: false}); 
        var searchP = "?filter=";
        if(arr.length!==0)
        {
        let first = true;
        arr.forEach((el)=>{
          if(first===true){
            searchP = searchP.concat(el);
          } else {
            searchP = searchP.concat("&filter="+el);
          }
          first=false;
        }); } else {
          searchP = "";
        }
        props.handleSetSearchParamsCatalogFilter(searchP);
      }
      if(target.innerHTML===categoryTranslator['Favorites']) {
        setFavoriteFilter(false);
      }
      props.handleSetFilterArray(arr);
      setChecked(newChecked);
      forceUpdate();
    
  }

  const handleSetChecked = (val) => {
    setChecked(val);
  }

  const handleSearch = (val) => {
    var cats = searchParams.getAll("filter");
    if(val==="")
    {
      if(cats) {
        setSearchParams({"filter": cats}, {replace: false}); 
        var searchP = "?filter=";
        if(cats.length!==0)
        {
      let first = true;
      cats.forEach((el)=>{
        //console.log(el);
        if(first===true){
          searchP = searchP.concat(el);
        } else {
          searchP = searchP.concat("&filter="+el);
        }
        first=false;
      });
    } else {
      searchP = "";
    }
      props.handleSetSearchParamsCatalogFilter(searchP);
      } else {
        setSearchParams({}, {replace: false}); 
        props.handleSetSearchParamsCatalogFilter("");
      }
    }
    else{
    if(cats) {
      setSearchParams({"search": val, "filter": cats}, {replace: false}); 
      var searchP = "?search=";
      searchP = searchP.concat(val);
      cats.forEach((el)=>{
        searchP = searchP.concat("&filter="+el);
      });
      props.handleSetSearchParamsCatalogFilter(searchP);
    } else {
      setSearchParams({"search": val}, {replace: false}); 
      var searchP = "?search=";

      searchP = searchP.concat(val);
      props.handleSetSearchParamsCatalogFilter(searchP);
    }
  }

    /* setSearchParams(searchParams.append("search", val)); */
    handleSelectCategoryFilterLogic(props.filterArray,val);
    forceUpdate();
  }


  const handleOpenFiltersMobile = () => {
    setOpenFiltersMobile(true);
    //var elementToMove = document.querySelector(".catalogFilterWrapper");

  }

  const handleCloseFiltersMobile = () => {
    setOpenFiltersMobile(false);
  }


  const queryPatterns = async (sortVal, filterArr, searchVal) => {
    //console.log("queriyng");
    /* setLoadingCatalog(true);
    try {
      let q = null;
      switch(sortVal) {
        case "nye":
          q = query(collection(db, "shared_patterns"),orderBy("share_milis", "desc"));
          break;
        case "gamle":
          q = query(collection(db, "shared_patterns"),orderBy("share_milis", "asc"));
          break;
          case "popularitet":
          q = query(collection(db, "shared_patterns"),orderBy("downloadsNumber", "desc"));
          break;
        case "korssting":
          q = query(collection(db, "shared_patterns"),orderBy("totalStitches", "asc"));
        break;
        case "farver":
          q = query(collection(db, "shared_patterns"),orderBy("colorCount", "asc"));
          break;
        default:
          q = query(collection(db, "shared_patterns"),orderBy("share_milis", "desc"));
          break;
      }
      const querySnapshot = await getDocs(q);
      let arr = [];
      /* var parentElement = document.getElementById(""); */
      /*if(querySnapshot.size !== 0) {

        querySnapshot.forEach((doc) => {
            arr.push({
              a4Pages: doc.data().a4Pages,
              cmHeight: doc.data().cmHeight,
              cmWidth: doc.data().cmWidth,
              colorCount: doc.data().colorCount,
              shared_time: doc.data().shared_time,
              fabric: doc.data().fabric,
              name: doc.data().name,
              stitchHeight: doc.data().stitchHeight,
              stitchWidth: doc.data().stitchWidth,
              storageName: doc.data().storageName,
              photoUrl: '',
              shareImageStorageUrl: '',
              totalStitches: doc.data().totalStitches,
              share_milis: doc.data().share_milis,
              dmcArray: doc.data().dmcArray,
              dbRef: doc.ref,
              shareName: doc.data().share_name,
              shareDescription: doc.data().share_description,
              shareCategories: doc.data().share_categories,
              shareImageStorage: doc.data().share_image_storage,
              userName: doc.data().userName,
              downloadsNumber: doc.data().downloadsNumber,
              show: true,
              favorited: false
            });
        });
      
      for(let i = 0; i<arr.length; i++) {
        const imageRef = ref(storageRef, arr[i].storageName);
        await getDownloadURL(imageRef)
          .then((url) => {
              arr[i].photoUrl = url;
                  })
                  if(arr[i].shareImageStorage) {
        const imageShareRef = ref(storageRef, 'finished_embroideries/'+arr[i].shareImageStorage);
        await getDownloadURL(imageShareRef)
          .then((url) => {
              arr[i].shareImageStorageUrl = url;
                  });
                }
      }

      //Check which is favorited
      if(user) {
      const docSnap = collection(db, "users",user.uid+'/favorite_patterns');
      const querySnap = await getDocs(docSnap);
      if(querySnap.size !== 0) {
        querySnap.forEach((doc)=>{
          arr.forEach((pattern)=>{
            if(pattern.storageName===doc.id) {
              pattern.favorited = true;
            }
          })
        })
      }
    }*/

      /* var imgElement = document.createElement("img");
      imgElement.classList.add("catalogItemImage");
      append
      imgElement.addEventListener("load", ()=>{setLoadingCatalog(false);});
      imgElement.src = arr[i].photoUrl; */
   // }
/*     setLoadingCatalog(false); */
    //setCatalogArray(arr);
    
    /* await new Promise(r => setTimeout(r, 100));  */
   /* setLoadingCatalog(false);
    setFullScreenLoading(false);
    handleSelectCategoryFilterLogicInit(filterArr,arr, searchVal);
    
    }
    catch(err) {
      console.log(err.message);
    } */

    var arr = await props.handleFetchCatalogArray(user, sortVal);
    //console.log(arr);
    setLoadingCatalog(false);
    setFullScreenLoading(false);
    handleSelectCategoryFilterLogicInit(filterArr,arr, searchVal);
  }
    return (
        <React.Fragment>
          <Helmet>
            <title>Pattern Catalog</title>
            <meta name="description" content="Browse user-created patterns and get inspired! Join the community and share your creations with others."/>
            <link rel='canonical' href='/catalog'/>
          </Helmet>
          <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={fullscreenLoading}
        >
          <CircularProgress /* disableShrink={true} */ color="inherit" />
        </Backdrop>
        <Drawer
      anchor={'bottom'}
      open={openFiltersMobile}
      onClose={handleCloseFiltersMobile}
      PaperProps={{
        sx:{backgroundImage: 'none!important', padding: '40px!important', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}
      }}
      
    >
      <Typography gutterBottom variant="h6" align='left' sx={{m: 0, mb: '30px'}}>
              {t('catalog.filter')}
              </Typography>
      <Typography gutterBottom variant="h7" align='left' sx={{m: 0}}>
{t("catalog.categories")}
              </Typography>
<Box sx={{ width: '100%' }}>
<CheckboxCategoryList checked={checked} setChecked={handleSetChecked} selectCategory={handleSelectCategory}/>
</Box>
<div style={{marginTop: '40px'}}>
<Typography component={'div'} gutterBottom variant="h7" align='left' sx={{ m: 0, display: 'flex', alignItems: 'center'}}>
{t('catalog.other_filters')}
              </Typography>
              <Box sx={{ width: '100%', ml: '16px'  }}>
              <FormGroup>
      <FormControlLabel control={<Checkbox checked={favoriteFilter}  onClick={()=>{let f = favoriteFilter; setFavoriteFilter(!f); handleSelectCategory('Favorites')}} />} label={<div style={{display: 'flex', alignItems: 'center'}}><FavoriteRounded sx={{mr: 1}}/> <span>{t('catalog.only_favorites')}</span></div>} sx={{"& .MuiFormControlLabel-label": {fontWeight: '400', fontSize: '0.875rem', letterSpacing: '0.01071em'}}} />
    </FormGroup>
</Box>
</div>
    </Drawer>
          {/* isLoggedIn ? <div></div> :
          <Box className='no-print' sx={{ flexGrow: 1, position: 'absolute' }}>
          <Paper position="static" sx={{ boxShadow: '0 4px 12px rgba(0,0,0,0.2);', borderRadius: '0px' }}>
            <Toolbar sx={{ height: '80px', justifyContent: 'space-between', zIndex: '1' }}>
              <div className="left" style={{ display: 'flex', alignItems: 'center' }}>
              <h1 className="korsstingOverskift" style={{cursor: "pointer"}} onClick={()=>{navigate('/home')}}>EMBRODIA</h1>
            
              </div>

              <div className="right">
                 
                <div> 
                  
                  </div>  
              </div>          
            </Toolbar>
          </Paper>
    </Box> */}
            <div className='catalogFilterWrapper'>
              
            <Typography gutterBottom variant="h6" align='left' sx={{m: 0, mb: '30px'}}>
              {t('catalog.filter')}
              </Typography>
{/*               <Typography gutterBottom variant="h7" align='left' sx={{m: 0, mt: '20px'}}>
              {t("catalog.colors")}
              </Typography>
              <Box sx={{ width: 200, marginBottom: '40px' }}>
              <Slider
              min={1}
              max={400}
  getAriaLabel={() => 'Temperature range'}
  value={[Number(slideValue1),Number(slideValue2)]}
  onChange={handleChangeSlider}
  valueLabelDisplay="auto"
  getAriaValueText={valuetext}
/>
              <Grid container spacing={2} alignItems="center" sx={{justifyContent: 'space-between'}}>

              <Grid item>
              <Input
            value={slideValue1}
            size="small"
            onChange={handleInputChange1}
            onBlur={handleBlur1}
            type={'number'}
            inputProps={{
              step: 10,
              min: 1,
              max: 400,
              type: 'number'
            }}
          />

</Grid>
<Grid item>
<Typography gutterBottom variant="h7" align='left' >
              {"-"}
              </Typography>
          </Grid>
<Grid item>
<Input
            value={slideValue2}
            size="small"
            onChange={handleInputChange2}
            onBlur={handleBlur2}
            type={'number'}
            inputProps={{
              step: 10,
              min: 1,
              max: 400,
              type: 'number'
            }}
          />
          </Grid>

          </Grid>
          
</Box> */}
<Typography gutterBottom variant="h7" align='left' sx={{m: 0}}>
{t("catalog.categories")}
              </Typography>
<Box sx={{ width: 200 }}>
<CheckboxCategoryList checked={checked} setChecked={handleSetChecked} selectCategory={handleSelectCategory}/>
</Box>
<div style={{marginTop: '40px'}}>
<Typography component={'div'} gutterBottom variant="h7" align='left' sx={{ m: 0, display: 'flex', alignItems: 'center'}}>
{t('catalog.other_filters')}
              </Typography>
              <Box sx={{ width: 200, ml: '16px'  }}>
              <FormGroup>
      <FormControlLabel control={<Checkbox checked={favoriteFilter}  onClick={()=>{let f = favoriteFilter; setFavoriteFilter(!f); handleSelectCategory('Favorites')}} />} label={<div style={{display: 'flex', alignItems: 'center'}}><FavoriteRounded sx={{mr: 1}}/> <span>{t('catalog.only_favorites')}</span></div>} sx={{"& .MuiFormControlLabel-label": {fontWeight: '400', fontSize: '0.875rem', letterSpacing: '0.01071em'}}} />
    </FormGroup>
</Box>
</div>
            </div>
            <div className='catalogMarginLeftDiv'>
            <div className='topSortWrapper' style={props.filterArray.length>0 ? {padding: '30px 50px 0px 0px'}:{padding: '30px 50px 0px 0px'}}>
              {/* <TextField label="Search" variant='outlined' sx={{minWidth: '500px'}}/> */}
              <div style={{display: 'flex'}} className='searchDivCatalog'> 
              <Search className='searchBarCatalog' sx={props.theme.palette.mode === 'dark' ? {}:{boxShadow: '0px 4px 6px rgb(0 0 0 / 15%)', border: '1px solid rgba(0, 0, 0, 0.23)'}}>
            <SearchIconWrapper>
              <SearchRounded color='text.secondary' sx={{opacity: '0.8'}}/>
            </SearchIconWrapper>
            <StyledInputBase
            className='searchBarCatalogInput'
              placeholder={t('catalog.search')}
              inputProps={{ 'aria-label': 'search', className: 'searchBarCatalogInput' }}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onBlur={e => handleSearch(e.target.value)}
              onKeyDown={e => { if(e.key === "Enter") handleSearch(e.target.value)}}
            />
            
          </Search>
          { searchValue!=="" && <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}><IconButton onClick={(e)=>{setSearchValue(""); handleSearch("");}} sx={{position: 'absolute', right: '4px'}} size="small"><CancelRounded/></IconButton></div>}
          </div>
              </div>
              <div className='topSortWrapper' style={props.filterArray.length>0 ? {padding: '16px 50px 10px 0px'}:{padding: '16px 50px 30px 0px'}}>
            <Typography gutterBottom variant="h7" align='left' sx={{m: 0}}>
              {t('catalog.showing_1')+props.catalogArray.filter(el => {if (el.show) {return true;}return false;}).length + (props.catalogArray.filter(el => {if (el.show) {return true;}return false;}).length===1?t('catalog.showing_3') : t('catalog.showing_2') )}
              </Typography>
              <div style={{display: 'flex', marginLeft: '10px'}}>
              <Button sx={props.theme.palette.mode === 'light'? {color: 'rgba(0, 0, 0, 0.87)', borderColor: 'rgba(0, 0, 0, 0.23)', boxShadow: '0px 4px 6px rgb(0, 0, 0, 15%)', textTransform: 'none'} : {color: 'white', borderColor: 'rgba(255, 255, 255, 0.23)', boxShadow: '0px 4px 6px rgb(0, 0, 0, 15%)', textTransform: 'none'}} onClick={handleOpenFiltersMobile} size='large' className='buttonFilterMobile' variant='outlined'>{t('catalog.filter')}</Button>
              <FormControl>
              <InputLabel id="sort-label">{t('catalog.sort')}</InputLabel>
              <Select
                              className='selectInputSort'
                              label={t('catalog.sort')}
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="sort-label"
                              id="sort"
                              
                              value={props.sortValue}
                              onChange={handleSortSelect}
                            >
                              <MenuItem value="nye">{t("catalog.sort_1")}</MenuItem>
                              <MenuItem value="gamle">{t("catalog.sort_2")}</MenuItem>
                              <MenuItem value="popularitet">{t("catalog.sort_5")}</MenuItem>
                              <MenuItem value="korssting">{t("catalog.sort_3")}</MenuItem>
                              <MenuItem value="farver">{t("catalog.sort_4")}</MenuItem>
                            </Select>
                            </FormControl>
                            </div>
              {/* <Typography gutterBottom variant="h7" align='left' sx={{m: 0}}>
              {"Sorter efter..."}
              </Typography> */}
              </div>
              <div className='topFilterWrapper' style={props.filterArray.length>0 ? {padding: '0px 50px 30px 0px'}:{padding: '0px'}}>
            {props.filterArray.map((filter,id)=>(
              <Chip key={"filterChip"+id} size='medium' color='primary' label={/* filter==='Favorites' ? <div style={{display:'flex', alignItems: 'center'}}><FavoriteRounded color='white' sx={{width:'0.7em', mr: 1}}/> <span>{categoryTranslator[filter]}</span></div> : */ categoryTranslator[filter]} sx={{mr: 1}} onDelete={handleRemoveCategory}/>
            ))}
              </div>
          <div className='catalogWrapper'>
            {loadingCatalog ? children :
            props.catalogArray.length!==0 && 
            props.catalogArray.map((item, id)=>(
              item.show &&
              <Paper key={"catalogPaper"+id} className='catalogItemPaper' sx={props.theme.palette.mode === 'dark' ? { position: 'relative', borderRadius: '12px', boxShadow: "0px 4px 12px rgb(0 0 0 / 0%)", width: '240px', height: '330px'} : { position: 'relative', borderRadius: '12px', boxShadow: "0px 4px 12px rgb(0 0 0 / 0%)", width: '240px', height: '330px', backgroundColor: 'rgb(240,240,240)'} }>
                        
                <Link key={"catalogLink"+id} to={'/catalog/'+item.share_milis} onClick={()=>{props.handleSetPatternIndex(props.catalogArray.indexOf(item))}} className={'catalogItemSelector'}>
                <div /* style={{zIndex: '1'}} */ className={props.theme.palette.mode === 'dark' ? 'catalogPointerDiv catalogPointerDivBlack': 'catalogPointerDiv catalogPointerDivWhite'}></div>
              <div className='catalogItemWrapperDiv' style={{padding: '15px', textAlign: 'left'}}>
                <div className='catalogItemImageWrapper' style={props.theme.palette.mode === 'dark' ? {  boxShadow: "0px 6px 18px rgb(0 0 0 / 40%)",background: 'rgb(45,45,45)', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'} : {boxShadow: "0px 4px 18px rgb(0 0 0 / 20%)",background: 'white'}}>
                
                {item.shareImageStorageUrl ? 
                <>
                  <img src={item.shareImageStorageUrl}  className="catalogItemImageHideOnHover" style={props.theme.palette.mode === 'dark' ? {background: 'rgb(45,45,45)', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'} : {background: "rgb(255,255,255)"}} />
                  <img src={item.photoUrl}  className="catalogItemImage" style={item.sensitiveContent === true ? {filter: 'blur(25px)'} : {filter: 'none'}}/>
                </> 
                : 
                <>
                  <img src={item.photoUrl}  className={ "catalogItemImage"} style={item.sensitiveContent === true ? {filter: 'blur(25px)'} : {filter: 'none'}}/>
                </>}
                {item.sensitiveContent && <div className='sensitiveContentWrapper'>
                  <VisibilityOffRounded sx={{fontSize: '3.5rem'}}/>
                  <Typography className='catalogItemText' display="block" color="text.secondary" textAlign={'center'}>{t('catalog.sensitive_1')}<br></br>{t('catalog.sensitive_2')}</Typography>
                  </div>}
                </div>
                <Typography className='catalogItemTitle' gutterBottom variant="body2" sx={{mt: '12px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '210px'}}>{item.shareName}</Typography>
                <div style={{display: 'flex'}}>
                <PersonRounded sx={{opacity: '0.2', width: '0.7em', height: '0.7em', mr: 0.5}}/>
                <Typography className='catalogItemText' display="block" variant="caption" color="text.secondary">{item.userName===null ? t('catalog.anonymous')+" • " +item.shared_time.split(" ")[0].replace(",","") : item.userName +" • " +item.shared_time.split(" ")[0].replace(",","") }</Typography>
                <Typography className='catalogItemText' display="block" variant="caption" color="text.secondary"></Typography>
                </div>
                <div style={{display: 'flex'}}>
                <Grid3x3Rounded sx={{opacity: '0.2', width: '0.7em', height: '0.7em', mr: 0.5}}/>
                <Typography className='catalogItemText' variant="caption" color="text.secondary">{item.stitchHeight+" x "+item.stitchWidth +", "+item.totalStitches+ t('catalog.stitches')/* +" • " + item.colorCount+ t('catalog.col') */} </Typography>
                </div>
                <div style={{display: 'flex'}}>
                <ColorLensRounded sx={{opacity: '0.2', width: '0.7em', height: '0.7em', mr: 0.5}}/>
                <Typography className='catalogItemText' variant="caption" color="text.secondary">{item.colorCount+ t('catalog.col')} </Typography>
                </div>
{/*                 <div style={{display: 'flex'}}>
                <DownloadRounded sx={{opacity: '0.2', width: '0.7em', height: '0.7em', mr: 0.5}}/>
                <Typography display="block"  variant="caption" color="text.secondary">{item.downloadsNumber} </Typography>
                </div> */}
              </div>
              </Link>
              <div onClick={user ? ()=>{handleSetFavorite(item)} : ()=>{navigate('/login')}} className={item.favorited===true ? 'favoriteButtonFavorited' : 'favoriteButton'} style={props.theme.palette.mode === 'dark' ? {background: 'white'}: {background: 'white'}}>
                   { item.favorited===true ? <FavoriteRounded color='primary'/> : <FavoriteBorderRounded color='primary'/>}
                  </div>
              </Paper>
            ))
            
            }
           
            
            
           {/*  <div className='catalogItemLoadWrapper'>
    <Skeleton variant='rectangle' width={210} height={210} sx={{borderRadius: '4px'}}/>
    <Skeleton width={"75%"}/>
    <Skeleton width={"50%"}/>
    <Skeleton width={"50%"}/>
    </div> */}
          </div>
         {/*  <p style={{marginTop: '40px', textAlign: 'center'}}>{"<    1/1   >"}</p> */}
          </div>
          
          </React.Fragment>
          
    );
  }