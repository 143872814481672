import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from './UserAuthContext';
import { Card, Divider } from '@mui/material';
import { ArrowBack, Email, Facebook, Google } from '@mui/icons-material';
import Copyright from './copyright';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export default function SignUp(props) {
  const {t, i18n} = useTranslation('translation');
  const { googleSignIn, facebookSignIn } = useUserAuth();
  const navigate = useNavigate();



  const handleGoToLogin = (e) => {
    e.preventDefault();
    navigate('/login');
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();

    try {
      let res = await googleSignIn();
      if(res===true) {
        props.handleOpenSnackbar("success", t('snackbars.signin'));
        navigate("/create");
    }else if(res==="Firebase: Error (auth/account-exists-with-different-credential).") {
      props.handleOpenSnackbar("error", t('snackbars.already'));
    }
    else {
      props.handleOpenSnackbar("error", t('snackbars.error'));
    }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleFacebookSignIn = async (e) => {
    e.preventDefault();
    try {
      let res = await facebookSignIn();
      if(res===true) {
        props.handleOpenSnackbar("success", t('snackbars.signin'));
        navigate("/create");
    }else if(res==="Firebase: Error (auth/account-exists-with-different-credential).") {
      props.handleOpenSnackbar("error", t('snackbars.already'));
    }
    else {
      props.handleOpenSnackbar("error", t('snackbars.error'));
    }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleGoToSignup = (e) => {
    e.preventDefault();
    navigate('/signup/email');
  };

  const handleGoToHome = (e) => {
    e.preventDefault();
    navigate('/home');
  };

  return (
    <>
    <Helmet>
    <title>Create Profile</title>
            <meta name="description" content="Sign up and start creating your dream patterns!"/>
            <link rel='canonical' href='/signup'/>
    </Helmet>
    
    <Container component="main" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <CssBaseline />
      <Card sx={{ boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', maxWidth: '556px', padding: '0px 20px 50px 20px', margin: 'auto' }}>
        {/* <Button
              size='large'
              variant="contained"
              onClick={handleGoToHome} 
              
              sx={{padding: '13px 13px',minWidth:'0',borderRadius: '64px', float: 'left',transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '20px' , backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}  }}
            >
              <ArrowBack/>
            </Button> */}
        <Button onClick={handleGoToHome} startIcon={<ArrowBack />} size='large' sx={{ float: 'left', transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '0px' }}>{t('common.back_button')}</Button>
        <Box
          sx={{
            margin: 'auto',
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('create_profile.title')}
          </Typography>

          <Box component="form" noValidate sx={{ mt: 3, width: 'calc(100% - 37px)' }}>
            <Button
              fullWidth
              startIcon={<Facebook />}
              size='large'
              variant="outlined"
              onClick={handleFacebookSignIn}
            >
              {t('common.facebook_button')}
            </Button>
            <Button
              fullWidth
              startIcon={<Google />}
              size='large'
              variant="outlined"
              sx={{ mt: 2, mb: 2 }}
              onClick={handleGoogleSignIn}
            >
              {t('common.google_button')}
            </Button>
            <Divider sx={{ margin: '6px 0', color: 'text.secondary', fontSize: '14px' }}>{t('common.or')}</Divider>
            <Button
              fullWidth
              startIcon={<Email />}
              size='large'
              variant="outlined"
              sx={{ mt: 2, mb: 2 }}
              onClick={handleGoToSignup}
            >
              {t('common.email_button')}
            </Button>

            <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center', mt: '15px' }}>
              <Grid item sx={{ display: 'flex' }}>
                <Typography variant="body2" color="text.secondary">
                {t('common.by_continuing_1')} <Link sx={{cursor: 'pointer'}} onClick={()=>{navigate('/privacypolicy')}} variant="body2">
                {t('common.privacypolicy')}
                  </Link> {t('common.by_continuing_2')} <Link sx={{cursor: 'pointer'}} onClick={()=>{navigate('/cookiepolicy')}} variant="body2" >
                  {t('common.cookiepolicy')}
                  </Link></Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      <Grid item xs={12} sx={{ mt: 4 }}></Grid>
      <Copyright />
    </Container>
    </>
  );
}