import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail, FacebookAuthProvider } from 'firebase/auth';
import { auth, db, functions } from './firebase';
import { useNavigate } from "react-router-dom";
import { getAnalytics } from "firebase/analytics";
import { getDoc, doc, setDoc, updateDoc, addDoc, collection, onSnapshot, getFirestore, arrayUnion, query, where, getDocs } from "@firebase/firestore";
import  getStripe from './stripe';
import { loadStripe } from "@stripe/stripe-js";
import { httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";


const userAuthContext = createContext();

export function UserAuthContextProvider( { children } ) {
    const analytics = getAnalytics();
const [user, setUser] = useState("");
const {t, i18n} = useTranslation('translation');
/* const [isSubscribedConst, setIsSubscribed] = useState(false) */


const navigate = useNavigate();
    async function signUp(email, password) {
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);
            
            const userCredentials  = res.user;
            const docRef = doc(db, "users", userCredentials.uid);

            await setDoc(docRef, {
              uid: userCredentials.uid,
              email,
              name: '',
              authProvider: userCredentials.providerData[0].providerId,
              photoUrl: '',
              dateOfBirth: '',
              }); 
            return true;
          } catch (err) {
              console.error(err.message);
              return err.message;
            }
          }
        
    

    async function logIn(email, password) {
        const res = await signInWithEmailAndPassword(auth, email, password);
    }

    function logOut() {
        return signOut(auth);
    }

    async function googleSignIn() {
        const googleAuthProvider = new GoogleAuthProvider();
        try {

            const res = await signInWithPopup(auth, googleAuthProvider);
            const userCredentials  = res.user;
            const docRef = doc(db, "users", userCredentials.uid);
            const docs = await getDoc(docRef);
            if (docs.exists() === true) { 
                await updateDoc(docRef, {
                name: userCredentials.displayName,
                photoUrl: userCredentials.photoURL,
                }); 
            } else {
            await setDoc(docRef, {
                uid: userCredentials.uid,
                email: userCredentials.email,
                name: userCredentials.displayName,
                authProvider: userCredentials.providerData[0].providerId,
                photoUrl: userCredentials.photoURL,
                dateOfBirth: ''
                }); 
            }
            return true;
        }
        catch(err) {
            console.log(err.message);
            return err.message;
        }
    }

    async function facebookSignIn() {
        const facebookauthProvider = new FacebookAuthProvider();
        try {

            const res = await signInWithPopup(auth, facebookauthProvider);
            const userCredentials  = res.user;
            const docRef = doc(db, "users", userCredentials.uid);
            const credential = FacebookAuthProvider.credentialFromResult(res);
            const accessToken = credential.accessToken;
            const docs = await getDoc(docRef);
            if (docs.exists() === true) { 
                await updateDoc(docRef, {
                name: userCredentials.displayName,
                photoUrl: userCredentials.photoURL+"?access_token="+accessToken,
                }); 
            } else {
            await setDoc(docRef, {
                uid: userCredentials.uid,
                email: userCredentials.email,
                name: userCredentials.displayName,
                authProvider: userCredentials.providerData[0].providerId,
                photoUrl: userCredentials.photoURL+"?access_token="+accessToken,
                dateOfBirth: ''
                }); 
            }
            return true;
        }
        catch(err) {
            console.log(err.message);
            return err.message;
        }
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

    async function createCheckoutSession(price) {
         const docRefD = doc(db, "users", user.uid);
        const docs = await getDoc(docRefD);
        const data = docs.data();
        const docRef = collection(db, "users", user.uid+'/checkout_sessions');
        let checkoutSessionRef = null;
        switch(price) {
            case 'month':
             checkoutSessionRef = await addDoc(docRef,{ 
                price: 'price_1MPPhiI6LvEAUFmo0W49r2uP',
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                allow_promotion_codes: true,
                })
            break;
            case 'halfYear':
                checkoutSessionRef = await addDoc(docRef,{ 
                    price: 'price_1Mrip9I6LvEAUFmoIDAayx0q',
                    success_url: window.location.origin,
                    cancel_url: window.location.origin,
                    allow_promotion_codes: true,
                    })
            break;
            case 'year':
            checkoutSessionRef = await addDoc(docRef,{ 
                price: 'price_1MrisXI6LvEAUFmoREiYyVri',
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                allow_promotion_codes: true,
                })
            break;
            case 'onePattern':
                checkoutSessionRef = await addDoc(docRef,{ 
                    mode: "payment",
                    metadata: {valid: 1},
                    price: 'price_1MOLMzI6LvEAUFmoVzP3QenV',
                    success_url: window.location.origin,
                    cancel_url: window.location.origin,
                    allow_promotion_codes: true,
                    })
                break;
            case 'threePatterns':
                checkoutSessionRef = await addDoc(docRef,{ 
                    mode: "payment",
                    metadata: {valid: 3},
                    price: 'price_1Lo69NI6LvEAUFmo9ZTndoZc',
                    success_url: window.location.origin,
                    cancel_url: window.location.origin,
                    allow_promotion_codes: true,
                    })
                break;
            default:
            checkoutSessionRef = await addDoc(docRef,{
                price: 'price_1LlveqI6LvEAUFmo8Rbg5rZx',
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                allow_promotion_codes: true,
                /* ,
                automatic_tax: {
                    enabled: true,
                  }, */
                })
            break;
        }
        const unsub = onSnapshot(checkoutSessionRef, async (snap) => {
            const d = snap.data();
          if (d.sessionId) {
            const stripe = await loadStripe('pk_live_51L5EERI6LvEAUFmoi6lAJB5AmCDViIrUH1F00MghYD7yBJ18mOs8oxntz9URL0YmNCL91DjWbyTIZKMZuSUNOBng00GufkG9lP');
            stripe.redirectToCheckout({sessionId: d.sessionId});  
          }
        });
      }

      async function redirectToCustomerPortal() {
          functions.region = 'europe-west1';
        const functionRef = httpsCallable(functions,'ext-firestore-stripe-payments-createPortalLink');
        const { data } = await functionRef({
        returnUrl: window.location.origin,
      });
      window.location.assign(data.url);
     }

/*     async function getDbUser(user) {
        try {
            const docRef = doc(db, "users", user.uid);
            const docs = await getDoc(docRef);
            setDbUser(docs.data());
        } catch(err) {
            console.log(err);
        }
    } */
/*     async function isSubscribed(user) {
        const q = query(collection(db, "users",user.uid+'/subscriptions'), where('status', 'in', ['trialing', 'active']));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.size === 0) {
                setIsSubscribed(false);
            } else {
                setIsSubscribed(true);
            }
          // Sleep for 3 seconds
           /*  await new Promise(r => setTimeout(r, 100)); */
        /*} */

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            


        });
        return () => {
            unsubscribe();
        }
    }, []);
    return (
        <userAuthContext.Provider value={{user, signUp, logIn, logOut, googleSignIn, resetPassword, facebookSignIn, analytics, createCheckoutSession, redirectToCustomerPortal}}>
            {children}
        </userAuthContext.Provider>
    )
}

export function useUserAuth() {
    return useContext(userAuthContext);
}

export {userAuthContext};