import { CancelRounded, UploadRounded } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

export default function UploadShareImage(props) {
    const [image, setImage] = useState([]);
    const {t, i18n} = useTranslation('translation');
    const [imageURL, setImageURL] = useState([]);
    const [labelValue, setLabelValue] = useState(t('share_dialog.upload'));


    useEffect(() => {
        if (image.length < 1) return;
        const newImageURL = [];
        image.forEach(img => newImageURL.push(URL.createObjectURL(img)));
        setImageURL(newImageURL);

    },[image]);

    function onImageChange(e) {
        setImage([...e.target.files]);
        props.setUploadImage([...e.target.files]);
        var fileName = e.target.value.split("\\").pop();
            if(fileName)
            {
                setLabelValue(fileName);
            }
    }

    const handleRemoveButtonClick = () => {
        setLabelValue(t('share_dialog.upload'));
        setImageURL([]);
        setImage([]);
        props.setUploadImage([]);
    }

    return (
        <>
        <label for="shareImageInput" class="custom-file-upload">
            <UploadRounded sx={{mr: 1}} color="primary"/><Typography sx={{fontWeight: '500', fontSize: '0.9375rem', lineHeight: '1.75', letterSpacing: '0.02857em'}} color={"primary"}>{labelValue}</Typography>
        </label>
        <input id="shareImageInput" type="file" accept="image/*" onChange={onImageChange}/>
        {imageURL.map(imageSrc => <div className="imageShareUploadWrapper" ><IconButton sx={{position:'absolute', top: '-20px', left: '180px'}} onClick={handleRemoveButtonClick}><CancelRounded/> </IconButton><img style={{width: 'inherit', height: 'inherit', objectFit: 'contain'}} src={imageSrc}/></div>)}
        </>
    );
}