import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from './UserAuthContext';
import { Card, Divider } from '@mui/material';
import { ArrowBack, Facebook, Google } from '@mui/icons-material';
import Copyright from './copyright';
import VisibilityPasswordTextField from './components/VisibilityPasswordTextField';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export default function SignIn(props) {
  const {t, i18n} = useTranslation('translation');
  const [values, setValues] = React.useState({
    password: "",
    email: "",
    loading: false,
  });

  const [emailText, setEmailText] = React.useState(null);
  const [passwordText, setPasswordText] = React.useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const noEmailError = values.email === "";
  const noPasswordError = values.password === "";
  const { logIn, googleSignIn, facebookSignIn } = useUserAuth();
  const navigate = useNavigate();
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setEmailText(null);
    setPasswordText(null);
    let shouldLoad = true;
    const data = new FormData(event.currentTarget);
    if(noEmailError)
    {
      setEmailText(t('common.validation_1'));
      shouldLoad = false;
    } else if(!emailValidation()) {
      setEmailText(t('common.validation_2'));
      shouldLoad = false;
    } 
    if(noPasswordError)
    {
      setPasswordText(t('common.validation_1'));
      shouldLoad = false;
    }
    // eslint-disable-next-line no-console
    if(shouldLoad)
    {
      logInWithEmailAndPassword(data.get('email'),data.get('password'))
    }
  };

  const logInWithEmailAndPassword = async (email, password) => {
    setValues({ ...values, loading: true });
    try {
      await logIn(email, password);
      props.handleOpenSnackbar("success", t('snackbars.signin'));
      navigate("/create");
    } catch (err) {
      setValues({ ...values, loading: false });
      if(err.message === "Firebase: Error (auth/wrong-password)." || err.message === "Firebase: Error (auth/user-not-found).")
      {
        props.handleOpenSnackbar("error", t('snackbars.wrong'));
      } else if(err.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).") 
      {
        props.handleOpenSnackbar("error", t('snackbars.too_many'));
      }
      else
      {
        props.handleOpenSnackbar("error", t('snackbars.error'));
      console.error(err.message);
      }
    }
  };

  const emailValidation = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(regex.test(values.email) === false){
        return false;
    }
    return true;
}

  const handleChange = change => event => {
    setValues({ ...values, [change]: event.target.value });
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      let res = await googleSignIn();
      if(res===true) {
        props.handleOpenSnackbar("success", t('snackbars.signin'));
        navigate("/create");
    }else if(res==="Firebase: Error (auth/account-exists-with-different-credential).") {
      props.handleOpenSnackbar("error", t('snackbars.already'));
    }
    else {
      props.handleOpenSnackbar("error", t('snackbars.error'));
    }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleFacebookSignIn = async (e) => {
    e.preventDefault();
    try {
      let res = await facebookSignIn();
      if(res===true) {
        props.handleOpenSnackbar("success", t('snackbars.signin'));
        navigate("/create");
    }else if(res==="Firebase: Error (auth/account-exists-with-different-credential).") {
      props.handleOpenSnackbar("error", t('snackbars.already'));
    }
    else {
      props.handleOpenSnackbar("error", t('snackbars.error'));
    }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleGoToSignup = (e) => {
    e.preventDefault();
    navigate('/signup');
  };

  const handleGoToHome = (e) => {
    e.preventDefault();
    navigate('/home');
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    navigate('/login/forgotpassword');
  };

  return (
    <>
    <Helmet>
    <title>Sign In</title>
            <meta name="description" content="Sign in using Facebook, Google or email"/>
            <link rel='canonical' href='/login'/>
    </Helmet>
      <Container component="main" sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <CssBaseline />
        <Card sx={{boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', maxWidth: '556px', padding: '0px 20px 50px 20px', margin: 'auto'}}>  
        {/* <Button
              size='large'
              variant="contained"
              onClick={handleGoToHome} 
              
              sx={{padding: '13px 13px',minWidth:'0',borderRadius: '64px', float: 'left',transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '20px' , backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}  }}
            >
              <ArrowBack/>
            </Button> */}
            <Button onClick={handleGoToHome} startIcon={<ArrowBack/>} size='large' sx={{float: 'left',transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '0px' }}>{t('common.back_button')}</Button>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('sign_in.title')}
          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: 'calc(100% - 37px)' }}>
          <Button
              fullWidth
              startIcon={<Facebook />}
              size='large'
              variant="outlined"
              onClick={ handleFacebookSignIn}
            >
              {t('common.facebook_button')}
            </Button>
            <Button
              fullWidth
              startIcon={<Google />}
              size='large'
              variant="outlined"
              sx={{ mt: 2, mb: 2 }}
              onClick={ handleGoogleSignIn}
            >
              {t('common.google_button')}
            </Button>
            <Divider sx={{margin: '6px 0', color: 'text.secondary', fontSize: '14px'}}>{t('common.or')}</Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2}}>
                <TextField
                  required
                  fullWidth
                  value={values.email}
                  onChange={handleChange("email")}
                  error={emailText!==null ? true : false}
                  helperText={emailText}
                  id="email"
                  label={t('common.email')}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <VisibilityPasswordTextField
                  required
                  fullWidth
                  value={values.password}
                  onChange={handleChange("password")}
                  error={passwordText!==null ? true : false}
                  helperText={passwordText}
                  name="password"
                  label={t('common.password')}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onVisibilityChange={setIsPasswordVisible}
                  isVisible={isPasswordVisible}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              size='large'
              variant="contained"
              sx={{ mt: 3, mb: 3, backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}  }}
            >
              {values.loading ? <CircularProgress size={26.25} sx={{color: 'white'}}/> : t('common.button_sign_in')}
            </Button>
            <Grid container sx={{alignItems: 'baseline', justifyContent: 'center'}}>
              <Grid item xs>
                <Link href="#" onClick={handlePasswordReset} variant="body2">
                  {t('common.forgot_password')}
                </Link>
              </Grid>
              <Grid item sx={{display: 'flex'}}>
              <Typography variant="body2" color="text.secondary">
                {t('sign_in.have_account_1')} </Typography>
                <Link href="" variant="body2" onClick={handleGoToSignup} sx={{width: '94px'}}>
                {t('sign_in.have_account_2')} 
                </Link>
              </Grid>
              
              
            </Grid>
            <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center', mt: '15px' }}>
              <Grid item sx={{ display: 'flex' }}>
                <Typography variant="body2" color="text.secondary">
                  {t('common.by_continuing_1')} <Link sx={{cursor: 'pointer'}} onClick={()=>{navigate('/privacypolicy')}} variant="body2">
                    {t('common.privacypolicy')}
                  </Link> {t('common.by_continuing_2')} <Link sx={{cursor: 'pointer'}} onClick={()=>{navigate('/cookiepolicy')}} variant="body2" >
                  {t('common.cookiepolicy')}
                  </Link></Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        </Card>
        <Grid item xs={12} sx={{ mt: 4 }}></Grid>
        <Copyright />
      </Container>
      </>

  );
}