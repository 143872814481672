import * as React from 'react';

export const DMCJSONColorsHex = 
{

    "1": 
       
          "#E3E3E6"
       
    ,
    "2": 
       
          "#D7D7D8"
       
    ,
    "3": 
       
          "#B8B8BB"
       
    ,
    "4": 
       
          "#AEAEB1"
       
    ,
    "5": 
       
          "#E3CCBE"
       
    ,
    "6": 
       
          "#DCC6B8"
       
    ,
    "7": 
       
          "#8F7B6E"
       
    ,
    "8": 
       
          "#6A5046"
       
    ,
    "9": 
       
          "#55200E"
       
    ,
    "10": 
       
          "#EDFED9"
       
    ,
    "11": 
       
          "#E2EDB5"
       
    ,
    "12": 
       
          "#CDD99A"
       
    ,
    "13": 
       
          "#BFF6E0"
       
    ,
    "14": 
       
          "#D0FBB2"
       
    ,
    "15": 
       
          "#D1EDA4"
       
    ,
    "16": 
       
          "#C9C258"
       
    ,
    "17": 
       
          "#E5E272"
       
    ,
    "18": 
       
          "#D9D56D"
       
    ,
    "19": 
       
          "#F7C95F"
       
    ,
    "20": 
       
          "#F7AF93"
       
    ,
    "21": 
       
          "#D79982"
       
    ,
    "22": 
       
          "#BC604E"
       
    ,
    "23": 
       
          "#EDE2ED"
       
    ,
    "24": 
       
          "#E0D7EE"
       
    ,
    "25": 
       
          "#DAD2E9"
       
    ,
    "26": 
       
          "#D7CAE6"
       
    ,
    "27": 
       
          "#F0EEF9"
       
    ,
    "28": 
       
          "#9086A9"
       
    ,
    "29": 
       
          "#674076"
       
    ,
    "30": 
       
          "#7D77A5"
       
    ,
    "31": 
       
          "#50518D"
       
    ,
    "32": 
       
          "#4D2E8A"
       
    ,
    "33": 
       
          "#9C599E"
       
    ,
    "34": 
       
          "#7D3064"
       
    ,
    "35": 
       
          "#46052D"
       
    ,
    "150": 
       
          "#AB0249"
       
    ,
    "151": 
       
          "#F0CED4"
       
    ,
    "152": 
       
          "#E2A099"
       
    ,
    "153": 
       
          "#E6CCD9"
       
    ,
    "154": 
       
          "#572433"
       
    ,
    "155": 
       
          "#9891B6"
       
    ,
    "156": 
       
          "#A3AED1"
       
    ,
    "157": 
       
          "#BBC3D9"
       
    ,
    "158": 
       
          "#4C526E"
       
    ,
    "159": 
       
          "#C7CAD7"
       
    ,
    "160": 
       
          "#999FB7"
       
    ,
    "161": 
       
          "#7880A4"
       
    ,
    "162": 
       
          "#DBECF5"
       
    ,
    "163": 
       
          "#4D8361"
       
    ,
    "164": 
       
          "#C8D8B8"
       
    ,
    "165": 
       
          "#EFF4A4"
       
    ,
    "166": 
       
          "#C0C840"
       
    ,
    "167": 
       
          "#A77C49"
       
    ,
    "168": 
       
          "#D1D1D1"
       
    ,
    "169": 
       
          "#848484"
       
    ,
    "208": 
       
          "#835B8B"
       
    ,
    "209": 
       
          "#A37BA7"
       
    ,
    "210": 
       
          "#C39FC3"
       
    ,
    "211": 
       
          "#E3CBE3"
       
    ,
    "221": 
       
          "#883E43"
       
    ,
    "223": 
       
          "#CC847C"
       
    ,
    "224": 
       
          "#EBB7AF"
       
    ,
    "225": 
       
          "#FFDFD5"
       
    ,
    "300": 
       
          "#6F2F00"
       
    ,
    "301": 
       
          "#B35F2B"
       
    ,
    "304": 
       
          "#B71F33"
       
    ,
    "307": 
       
          "#FDED54"
       
    ,
    "309": 
       
          "#564A4A"
       
    ,
    "310": 
       
          "#000000"
       
    ,
    "311": 
       
          "#1C5066"
       
    ,
    "312": 
       
          "#35668B"
       
    ,
    "315": 
       
          "#814952"
       
    ,
    "316": 
       
          "#B7737F"
       
    ,
    "317": 
       
          "#6C6C6C"
       
    ,
    "318": 
       
          "#ABABAB"
       
    ,
    "319": 
       
          "#205F2E"
       
    ,
    "320": 
       
          "#69885A"
       
    ,
    "321": 
       
          "#C72B3B"
       
    ,
    "322": 
       
          "#5A8FB8"
       
    ,
    "326": 
       
          "#B33B4B"
       
    ,
    "327": 
       
          "#633666"
       
    ,
    "333": 
       
          "#5C5478"
       
    ,
    "334": 
       
          "#739FC1"
       
    ,
    "335": 
       
          "#EE546E"
       
    ,
    "336": 
       
          "#253B73"
       
    ,
    "340": 
       
          "#ADA7C7"
       
    ,
    "341": 
       
          "#B7BFDD"
       
    ,
    "347": 
       
          "#BF2D2D"
       
    ,
    "349": 
       
          "#D21035"
       
    ,
    "350": 
       
          "#E04848"
       
    ,
    "351": 
       
          "#E96A67"
       
    ,
    "352": 
       
          "#FD9C97"
       
    ,
    "353": 
       
          "#FED7CC"
       
    ,
    "355": 
       
          "#984436"
       
    ,
    "356": 
       
          "#C56A5B"
       
    ,
    "367": 
       
          "#617A52"
       
    ,
    "368": 
       
          "#A6C298"
       
    ,
    "369": 
       
          "#D7EDCC"
       
    ,
    "370": 
       
          "#B89D64"
       
    ,
    "371": 
       
          "#BFA671"
       
    ,
    "372": 
       
          "#CCB784"
       
    ,
    "400": 
       
          "#8F430F"
       
    ,
    "402": 
       
          "#F7A777"
       
    ,
    "407": 
       
          "#BB8161"
       
    ,
    "413": 
       
          "#565656"
       
    ,
    "414": 
       
          "#8C8C8C"
       
    ,
    "415": 
       
          "#D3D3D6"
       
    ,
    "420": 
       
          "#A07042"
       
    ,
    "422": 
       
          "#C69F7B"
       
    ,
    "433": 
       
          "#7A451F"
       
    ,
    "434": 
       
          "#985E33"
       
    ,
    "435": 
       
          "#B87748"
       
    ,
    "436": 
       
          "#CB9051"
       
    ,
    "437": 
       
          "#E4BB8E"
       
    ,
    "444": 
       
          "#FFD600"
       
    ,
    "445": 
       
          "#FFFB8B"
       
    ,
    "451": 
       
          "#917B73"
       
    ,
    "452": 
       
          "#C0B3AE"
       
    ,
    "453": 
       
          "#D7CECB"
       
    ,
    "469": 
       
          "#72843C"
       
    ,
    "470": 
       
          "#94AB4F"
       
    ,
    "471": 
       
          "#AEBF79"
       
    ,
    "472": 
       
          "#D8E498"
       
    ,
    "498": 
       
          "#A7132B"
       
    ,
    "500": 
       
          "#044D33"
       
    ,
    "501": 
       
          "#396F52"
       
    ,
    "502": 
       
          "#5B9071"
       
    ,
    "503": 
       
          "#7BAC94"
       
    ,
    "504": 
       
          "#C4DECC"
       
    ,
    "505": 
       
          "#338362"
       
    ,
    "517": 
       
          "#3B768F"
       
    ,
    "518": 
       
          "#4F93A7"
       
    ,
    "519": 
       
          "#7EB1C8"
       
    ,
    "520": 
       
          "#666D4F"
       
    ,
    "522": 
       
          "#969E7E"
       
    ,
    "523": 
       
          "#ABB197"
       
    ,
    "524": 
       
          "#C4CDAC"
       
    ,
    "535": 
       
          "#636458"
       
    ,
    "543": 
       
          "#F2E3CE"
       
    ,
    "550": 
       
          "#5C184E"
       
    ,
    "552": 
       
          "#803A6B"
       
    ,
    "553": 
       
          "#A3638B"
       
    ,
    "554": 
       
          "#DBB3CB"
       
    ,
    "561": 
       
          "#2C6A45"
       
    ,
    "562": 
       
          "#53976A"
       
    ,
    "563": 
       
          "#8FC098"
       
    ,
    "564": 
       
          "#A7CDAF"
       
    ,
    "580": 
       
          "#888D33"
       
    ,
    "581": 
       
          "#A7AE38"
       
    ,
    "597": 
       
          "#5BA3B3"
       
    ,
    "598": 
       
          "#90C3CC"
       
    ,
    "600": 
       
          "#CD2F63"
       
    ,
    "601": 
       
          "#D1286A"
       
    ,
    "602": 
       
          "#E24874"
       
    ,
    "603": 
       
          "#FFA4BE"
       
    ,
    "604": 
       
          "#FFB0BE"
       
    ,
    "605": 
       
          "#FFC0CD"
       
    ,
    "606": 
       
          "#FA3203"
       
    ,
    "608": 
       
          "#FD5D35"
       
    ,
    "610": 
       
          "#796047"
       
    ,
    "611": 
       
          "#967656"
       
    ,
    "612": 
       
          "#BC9A78"
       
    ,
    "613": 
       
          "#DCC4AA"
       
    ,
    "632": 
       
          "#875539"
       
    ,
    "640": 
       
          "#857B61"
       
    ,
    "642": 
       
          "#A49878"
       
    ,
    "644": 
       
          "#DDD8CB"
       
    ,
    "645": 
       
          "#6E655C"
       
    ,
    "646": 
       
          "#877D73"
       
    ,
    "647": 
       
          "#B0A69C"
       
    ,
    "648": 
       
          "#BCB4AC"
       
    ,
    "666": 
       
          "#E31D42"
       
    ,
    "676": 
       
          "#E5CE97"
       
    ,
    "677": 
       
          "#F5ECCB"
       
    ,
    "680": 
       
          "#BC8D0E"
       
    ,
    "699": 
       
          "#056517"
       
    ,
    "700": 
       
          "#07731B"
       
    ,
    "701": 
       
          "#3F8F29"
       
    ,
    "702": 
       
          "#47A72F"
       
    ,
    "703": 
       
          "#7BB547"
       
    ,
    "704": 
       
          "#9ECF34"
       
    ,
    "712": 
       
          "#FFFBEF"
       
    ,
    "718": 
       
          "#9C2462"
       
    ,
    "720": 
       
          "#E55C1F"
       
    ,
    "721": 
       
          "#F27842"
       
    ,
    "722": 
       
          "#F7976F"
       
    ,
    "725": 
       
          "#FFC840"
       
    ,
    "726": 
       
          "#FDD755"
       
    ,
    "727": 
       
          "#FFF1AF"
       
    ,
    "728": 
       
          "#E4B468"
       
    ,
    "729": 
       
          "#D0A53E"
       
    ,
    "730": 
       
          "#827B30"
       
    ,
    "731": 
       
          "#938B37"
       
    ,
    "732": 
       
          "#948C36"
       
    ,
    "733": 
       
          "#BCB34C"
       
    ,
    "734": 
       
          "#C7C077"
       
    ,
    "738": 
       
          "#ECCC9E"
       
    ,
    "739": 
       
          "#F8E4C8"
       
    ,
    "740": 
       
          "#FF8B00"
       
    ,
    "741": 
       
          "#FFA32B"
       
    ,
    "742": 
       
          "#FFBF57"
       
    ,
    "743": 
       
          "#FED376"
       
    ,
    "744": 
       
          "#FFE793"
       
    ,
    "745": 
       
          "#FFE9AD"
       
    ,
    "746": 
       
          "#FCFCEE"
       
    ,
    "747": 
       
          "#E5FCFD"
       
    ,
    "754": 
       
          "#F7CBBF"
       
    ,
    "758": 
       
          "#EEAA9B"
       
    ,
    "760": 
       
          "#F5ADAD"
       
    ,
    "761": 
       
          "#FFC9C9"
       
    ,
    "762": 
       
          "#ECECEC"
       
    ,
    "772": 
       
          "#E4ECD4"
       
    ,
    "775": 
       
          "#D9EBF1"
       
    ,
    "776": 
       
          "#FCB0B9"
       
    ,
    "777": 
       
          "#913546"
       
    ,
    "778": 
       
          "#DFB3BB"
       
    ,
    "779": 
       
          "#624B45"
       
    ,
    "780": 
       
          "#94631A"
       
    ,
    "781": 
       
          "#A26D20"
       
    ,
    "782": 
       
          "#AE7720"
       
    ,
    "783": 
       
          "#CE9124"
       
    ,
    "791": 
       
          "#464563"
       
    ,
    "792": 
       
          "#555B7B"
       
    ,
    "793": 
       
          "#707DA2"
       
    ,
    "794": 
       
          "#8F9CC1"
       
    ,
    "796": 
       
          "#11416D"
       
    ,
    "797": 
       
          "#13477D"
       
    ,
    "798": 
       
          "#466A8E"
       
    ,
    "799": 
       
          "#748EB6"
       
    ,
    "800": 
       
          "#C0CCDE"
       
    ,
    "801": 
       
          "#653919"
       
    ,
    "803": 
       
          "#2C597C"
       
    ,
    "806": 
       
          "#3D95A5"
       
    ,
    "807": 
       
          "#64ABBA"
       
    ,
    "809": 
       
          "#94A8C6"
       
    ,
    "813": 
       
          "#A1C2D7"
       
    ,
    "814": 
       
          "#7B001B"
       
    ,
    "815": 
       
          "#87071F"
       
    ,
    "816": 
       
          "#970B23"
       
    ,
    "817": 
       
          "#BB051F"
       
    ,
    "818": 
       
          "#FFDFD9"
       
    ,
    "819": 
       
          "#FFEEEB"
       
    ,
    "820": 
       
          "#0E365C"
       
    ,
    "822": 
       
          "#E7E2D3"
       
    ,
    "823": 
       
          "#213063"
       
    ,
    "824": 
       
          "#396987"
       
    ,
    "825": 
       
          "#4781A5"
       
    ,
    "826": 
       
          "#6B9EBF"
       
    ,
    "827": 
       
          "#BDDDED"
       
    ,
    "828": 
       
          "#C5E8ED"
       
    ,
    "829": 
       
          "#7E6B42"
       
    ,
    "830": 
       
          "#8D784B"
       
    ,
    "831": 
       
          "#AA8F56"
       
    ,
    "832": 
       
          "#BD9B51"
       
    ,
    "833": 
       
          "#C8AB6C"
       
    ,
    "834": 
       
          "#DBBE7F"
       
    ,
    "838": 
       
          "#594937"
       
    ,
    "839": 
       
          "#675541"
       
    ,
    "840": 
       
          "#9A7C5C"
       
    ,
    "841": 
       
          "#B69B7E"
       
    ,
    "842": 
       
          "#D1BAA1"
       
    ,
    "844": 
       
          "#484848"
       
    ,
    "869": 
       
          "#835E39"
       
    ,
    "890": 
       
          "#174923"
       
    ,
    "891": 
       
          "#FF5773"
       
    ,
    "892": 
       
          "#FF798C"
       
    ,
    "893": 
       
          "#FC90A2"
       
    ,
    "894": 
       
          "#FFB2BB"
       
    ,
    "895": 
       
          "#1B5300"
       
    ,
    "898": 
       
          "#492A13"
       
    ,
    "899": 
       
          "#F27688"
       
    ,
    "900": 
       
          "#D15807"
       
    ,
    "902": 
       
          "#822637"
       
    ,
    "904": 
       
          "#557822"
       
    ,
    "905": 
       
          "#628A28"
       
    ,
    "906": 
       
          "#7FB335"
       
    ,
    "907": 
       
          "#C7E666"
       
    ,
    "909": 
       
          "#156F49"
       
    ,
    "910": 
       
          "#187E56"
       
    ,
    "911": 
       
          "#189065"
       
    ,
    "912": 
       
          "#1B9D6B"
       
    ,
    "913": 
       
          "#6DAB77"
       
    ,
    "915": 
       
          "#820043"
       
    ,
    "917": 
       
          "#9B1359"
       
    ,
    "918": 
       
          "#82340A"
       
    ,
    "919": 
       
          "#A64510"
       
    ,
    "920": 
       
          "#AC5414"
       
    ,
    "921": 
       
          "#C66218"
       
    ,
    "922": 
       
          "#E27323"
       
    ,
    "924": 
       
          "#566A6A"
       
    ,
    "926": 
       
          "#98AEAE"
       
    ,
    "927": 
       
          "#BDCBCB"
       
    ,
    "928": 
       
          "#DDE3E3"
       
    ,
    "930": 
       
          "#455C71"
       
    ,
    "931": 
       
          "#6A859E"
       
    ,
    "932": 
       
          "#A2B5C6"
       
    ,
    "934": 
       
          "#313919"
       
    ,
    "935": 
       
          "#424D21"
       
    ,
    "936": 
       
          "#4C5826"
       
    ,
    "937": 
       
          "#627133"
       
    ,
    "938": 
       
          "#361F0E"
       
    ,
    "939": 
       
          "#1B2853"
       
    ,
    "943": 
       
          "#3D9384"
       
    ,
    "945": 
       
          "#FBD5BB"
       
    ,
    "946": 
       
          "#EB6307"
       
    ,
    "947": 
       
          "#FF7B4D"
       
    ,
    "948": 
       
          "#FEE7DA"
       
    ,
    "950": 
       
          "#EED3C4"
       
    ,
    "951": 
       
          "#FFE2CF"
       
    ,
    "954": 
       
          "#88BA91"
       
    ,
    "955": 
       
          "#A2D6AD"
       
    ,
    "956": 
       
          "#FF9191"
       
    ,
    "957": 
       
          "#FDB5B5"
       
    ,
    "958": 
       
          "#3EB6A1"
       
    ,
    "959": 
       
          "#59C7B4"
       
    ,
    "961": 
       
          "#CF7373"
       
    ,
    "962": 
       
          "#E68A8A"
       
    ,
    "963": 
       
          "#FFD7D7"
       
    ,
    "964": 
       
          "#A9E2D8"
       
    ,
    "966": 
       
          "#B9D7C0"
       
    ,
    "967": 
       
          "#FFDED5"
       
    ,
    "970": 
       
          "#F78B13"
       
    ,
    "971": 
       
          "#F67F00"
       
    ,
    "972": 
       
          "#FFB515"
       
    ,
    "973": 
       
          "#FFE300"
       
    ,
    "975": 
       
          "#914F12"
       
    ,
    "976": 
       
          "#C28142"
       
    ,
    "977": 
       
          "#DC9C56"
       
    ,
    "986": 
       
          "#405230"
       
    ,
    "987": 
       
          "#587141"
       
    ,
    "988": 
       
          "#738B5B"
       
    ,
    "989": 
       
          "#8DA675"
       
    ,
    "991": 
       
          "#477B6E"
       
    ,
    "992": 
       
          "#6FAE9F"
       
    ,
    "993": 
       
          "#90C0B4"
       
    ,
    "995": 
       
          "#2696B6"
       
    ,
    "996": 
       
          "#30C2EC"
       
    ,
    "3011": 
       
          "#898A58"
       
    ,
    "3012": 
       
          "#A6A75D"
       
    ,
    "3013": 
       
          "#B9B982"
       
    ,
    "3021": 
       
          "#4F4B41"
       
    ,
    "3022": 
       
          "#8E9078"
       
    ,
    "3023": 
       
          "#B1AA97"
       
    ,
    "3024": 
       
          "#EBEAE7"
       
    ,
    "3031": 
       
          "#4B3C2A"
       
    ,
    "3032": 
       
          "#B39F8B"
       
    ,
    "3033": 
       
          "#E3D8CC"
       
    ,
    "3041": 
       
          "#956F7C"
       
    ,
    "3042": 
       
          "#B79DA7"
       
    ,
    "3045": 
       
          "#BC966A"
       
    ,
    "3046": 
       
          "#D8BC9A"
       
    ,
    "3047": 
       
          "#E7D6C1"
       
    ,
    "3051": 
       
          "#5F6648"
       
    ,
    "3052": 
       
          "#889268"
       
    ,
    "3053": 
       
          "#9CA482"
       
    ,
    "3064": 
       
          "#C48E70"
       
    ,
    "3072": 
       
          "#E6E8E8"
       
    ,
    "3078": 
       
          "#FDF9CD"
       
    ,
    "3325": 
       
          "#B8D2E6"
       
    ,
    "3326": 
       
          "#FBADB4"
       
    ,
    "3328": 
       
          "#E36D6D"
       
    ,
    "3340": 
       
          "#FF836F"
       
    ,
    "3341": 
       
          "#FCAB98"
       
    ,
    "3345": 
       
          "#1B5915"
       
    ,
    "3346": 
       
          "#406A3A"
       
    ,
    "3347": 
       
          "#71935C"
       
    ,
    "3348": 
       
          "#CCD9B1"
       
    ,
    "3350": 
       
          "#BC4365"
       
    ,
    "3354": 
       
          "#E4A6AC"
       
    ,
    "3362": 
       
          "#5E6B47"
       
    ,
    "3363": 
       
          "#728256"
       
    ,
    "3364": 
       
          "#83975F"
       
    ,
    "3371": 
       
          "#1E1108"
       
    ,
    "3607": 
       
          "#C54989"
       
    ,
    "3608": 
       
          "#EA9CC4"
       
    ,
    "3609": 
       
          "#F4AED7"
       
    ,
    "3685": 
       
          "#881531"
       
    ,
    "3687": 
       
          "#C96B70"
       
    ,
    "3688": 
       
          "#E7A9AC"
       
    ,
    "3689": 
       
          "#FBBFC2"
       
    ,
    "3705": 
       
          "#FF7992"
       
    ,
    "3706": 
       
          "#FFADBC"
       
    ,
    "3708": 
       
          "#FFCBD5"
       
    ,
    "3712": 
       
          "#F18787"
       
    ,
    "3713": 
       
          "#FFE2E2"
       
    ,
    "3716": 
       
          "#FFBDBD"
       
    ,
    "3721": 
       
          "#A14B51"
       
    ,
    "3722": 
       
          "#BC6C64"
       
    ,
    "3726": 
       
          "#9B5B66"
       
    ,
    "3727": 
       
          "#DBA9B2"
       
    ,
    "3731": 
       
          "#DA6783"
       
    ,
    "3733": 
       
          "#E8879B"
       
    ,
    "3740": 
       
          "#785762"
       
    ,
    "3743": 
       
          "#D7CBD3"
       
    ,
    "3746": 
       
          "#776B98"
       
    ,
    "3747": 
       
          "#D3D7ED"
       
    ,
    "3750": 
       
          "#384C5E"
       
    ,
    "3752": 
       
          "#C7D1DB"
       
    ,
    "3753": 
       
          "#DBE2E9"
       
    ,
    "3755": 
       
          "#93B4CE"
       
    ,
    "3756": 
       
          "#EEFCFC"
       
    ,
    "3760": 
       
          "#3E85A2"
       
    ,
    "3761": 
       
          "#ACD8E2"
       
    ,
    "3765": 
       
          "#347F8C"
       
    ,
    "3766": 
       
          "#99CFD9"
       
    ,
    "3768": 
       
          "#657F7F"
       
    ,
    "3770": 
       
          "#FFEEE3"
       
    ,
    "3771": 
       
          "#F4BBA9"
       
    ,
    "3772": 
       
          "#A06C50"
       
    ,
    "3773": 
       
          "#B67552"
       
    ,
    "3774": 
       
          "#F3E1D7"
       
    ,
    "3776": 
       
          "#CF7939"
       
    ,
    "3777": 
       
          "#863022"
       
    ,
    "3778": 
       
          "#D98978"
       
    ,
    "3779": 
       
          "#F8CAC8"
       
    ,
    "3781": 
       
          "#6B5743"
       
    ,
    "3782": 
       
          "#D2BCA6"
       
    ,
    "3787": 
       
          "#625D50"
       
    ,
    "3790": 
       
          "#7F6A55"
       
    ,
    "3799": 
       
          "#424242"
       
    ,
    "3801": 
       
          "#E74967"
       
    ,
    "3802": 
       
          "#714149"
       
    ,
    "3803": 
       
          "#AB3357"
       
    ,
    "3804": 
       
          "#E02876"
       
    ,
    "3805": 
       
          "#F3478B"
       
    ,
    "3806": 
       
          "#FF8CAE"
       
    ,
    "3807": 
       
          "#60678C"
       
    ,
    "3808": 
       
          "#366970"
       
    ,
    "3809": 
       
          "#3F7C85"
       
    ,
    "3810": 
       
          "#488E9A"
       
    ,
    "3811": 
       
          "#BCE3E6"
       
    ,
    "3812": 
       
          "#2F8C84"
       
    ,
    "3813": 
       
          "#B2D4BD"
       
    ,
    "3814": 
       
          "#508B7D"
       
    ,
    "3815": 
       
          "#477759"
       
    ,
    "3816": 
       
          "#65A57D"
       
    ,
    "3817": 
       
          "#99C3AA"
       
    ,
    "3818": 
       
          "#115A3B"
       
    ,
    "3819": 
       
          "#E0E868"
       
    ,
    "3820": 
       
          "#DFB65F"
       
    ,
    "3821": 
       
          "#F3CE75"
       
    ,
    "3822": 
       
          "#F6DC98"
       
    ,
    "3823": 
       
          "#FFFDE3"
       
    ,
    "3824": 
       
          "#FECDC2"
       
    ,
    "3825": 
       
          "#FDBD96"
       
    ,
    "3826": 
       
          "#AD7239"
       
    ,
    "3827": 
       
          "#F7BB77"
       
    ,
    "3828": 
       
          "#B78B61"
       
    ,
    "3829": 
       
          "#A98204"
       
    ,
    "3830": 
       
          "#B95544"
       
    ,
    "3831": 
       
          "#B32F48"
       
    ,
    "3832": 
       
          "#DB556E"
       
    ,
    "3833": 
       
          "#EA8699"
       
    ,
    "3834": 
       
          "#72375D"
       
    ,
    "3835": 
       
          "#946083"
       
    ,
    "3836": 
       
          "#BA91AA"
       
    ,
    "3837": 
       
          "#6C3A6E"
       
    ,
    "3838": 
       
          "#5C7294"
       
    ,
    "3839": 
       
          "#7B8EAB"
       
    ,
    "3840": 
       
          "#B0C0DA"
       
    ,
    "3841": 
       
          "#CDDFED"
       
    ,
    "3842": 
       
          "#32667C"
       
    ,
    "3843": 
       
          "#14AAD0"
       
    ,
    "3844": 
       
          "#12AEBA"
       
    ,
    "3845": 
       
          "#04C4CA"
       
    ,
    "3846": 
       
          "#06E3E6"
       
    ,
    "3847": 
       
          "#347D75"
       
    ,
    "3848": 
       
          "#559392"
       
    ,
    "3849": 
       
          "#52B3A4"
       
    ,
    "3850": 
       
          "#378477"
       
    ,
    "3851": 
       
          "#49B3A1"
       
    ,
    "3852": 
       
          "#CD9D37"
       
    ,
    "3853": 
       
          "#F29746"
       
    ,
    "3854": 
       
          "#F2AF68"
       
    ,
    "3855": 
       
          "#FAD396"
       
    ,
    "3856": 
       
          "#FFD3B5"
       
    ,
    "3857": 
       
          "#68251A"
       
    ,
    "3858": 
       
          "#964A3F"
       
    ,
    "3859": 
       
          "#BA8B7C"
       
    ,
    "3860": 
       
          "#7D5D57"
       
    ,
    "3861": 
       
          "#A68881"
       
    ,
    "3862": 
       
          "#8A6E4E"
       
    ,
    "3863": 
       
          "#A4835C"
       
    ,
    "3864": 
       
          "#CBB69C"
       
    ,
    "3865": 
       
          "#F9F7F1"
       
    ,
    "3866": 
       
          "#FAF6F0"
       
    ,
    "B5200": 
    
          "#FFFFFF"

    ,
    "White": 
    
          "#FCFBF8"
          
    ,
    "Ecru": 
    
          "#F0EADA"
       
}
 