import * as React from 'react';
import { useUserAuth } from './UserAuthContext';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from 'react';

export default function RedirectPage() {
    const navigate = useNavigate();
    const { user } = useUserAuth();
    const [numberOfTimesUserUpdated, setUserUpdated] = useState(0);
  useEffect(() =>{
    if(user)
    {
        navigate('/create');
    } else if(user===null) {
        navigate('/home');
    }
    if(numberOfTimesUserUpdated>=2){
        navigate('/create');
    }
    var num = numberOfTimesUserUpdated + 1;
    setUserUpdated(num);
    
  },[user]);

    return (
        <React.Fragment>
          </React.Fragment>
          
    );
  }