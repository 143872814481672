import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';



function valueLabelFormat(value) {
  let scaledValue = value;
  return `${scaledValue}`;
}

function calculateValue(value) {
  return (value);
}

export default function TextSizeSlider(props) {
  const [value, setValue] = React.useState(32);
  const {t, i18n} = useTranslation('translation');
  
  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
      //props.handleSetTextSize(calculateValue(newValue));
    }
  };

  const handleUp = (e) => {
    props.handleSetTextSize(value);
  }

  return (
    <Box sx={{width: '600px'}}>
        <Grid container spacing={2} alignItems="center">
{/*          <Grid item>
          <Typography>0%</Typography>
        </Grid>  */}
        <Grid item xs>
      <Slider
        value={value}
        min={0}
        step={1}
        max={100}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
        onChangeCommitted={handleUp}
      />
      </Grid>
      <Grid item>
          <Typography>{value+"%"}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}