import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import {BrowserRouter as Router} from 'react-router-dom';


import './i18n';
import { HelmetProvider } from 'react-helmet-async';
import { UserAuthContextProvider } from './UserAuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<HelmetProvider><I18nextProvider><Router><UserAuthContextProvider><App /></UserAuthContextProvider></Router></I18nextProvider></HelmetProvider>);