import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { Button, MenuItem, Select } from '@mui/material';
import { ShoppingCartRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const {t, i18n} = useTranslation('translation');
const headCells = [
    {
        id: 'hexCode',
        numeric: false,
        disablePadding: true,
        width: '0px',
        label: t('create_page_3.table_color'),
      },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    width: '60px',
    label: "DMC",
  },
  {
    id: 'flossName',
    numeric: false,
    disablePadding: true,
    width: '80px',
    label: t('create_page_3.table_name'),
  },
  {
    id: 'sting',
    numeric: true,
    disablePadding: true,
    width: '50px',
    label: t('create_page_3.table_stitches'),
  }
  
];

  return (
    <TableHead>
      
      <TableRow>
        <TableCell sx={{background: props.theme.palette.background.paper, display: 'none'}} padding="checkbox">
          <Checkbox
            color="primary"
            /* indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount} */
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Vælg alle farver',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
          sx={{fontSize: 'unset', fontWeight: 'normal',background: props.theme.palette.background.paper, width: headCell.width, padding: '4px', paddingLeft: headCell.id === 'hexCode' && '12px' }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
   /* numSelected: PropTypes.number.isRequired,  */
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {

  return (
    <Toolbar
      sx={{
        height: "32px",
        minHeight: "32px!important"
      }}
    >
    </Toolbar>
  );
};

/* EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}; */

export default function EnhancedTableColorReplacement(props) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('sting');
  const [selected, setSelected] = React.useState("");
  const [dense, setDense] = React.useState(true);
  const {t, i18n} = useTranslation('translation');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
/*     if (event.target.checked) {
      const newSelecteds = props.data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]); */
  };

  const handleClick = (event, index, name) => {
    setSelected(index);
    props.handleOpenColorPickerReplaceMode(event,name);
  };

  const isSelected = (name) => selected === name;

  return (
    <Box className='no-print' >
      <Paper className='dmcTablePaperDrawColor' sx={{boxShadow: 'none!important', mb: '8px!important'}}>
        
        <TableContainer className='dmcTableContainerDrawColor'>
          <Table stickyHeader
            sx={{ maxHeight: 420 }}
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <EnhancedTableHead
              
              theme={props.theme}
              /* numSelected={selected.length} */
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(props.data, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(index);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                    
                    id={"dmcRow"}
                      hover
                      onClick={(event) => {handleClick(event, index, row.name)}}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{
                        cursor: 'pointer',
                        '.MuiTableRow-root':{ backgroundColor: 'white!important'},
                        /* '&.Mui-selected': {
                          backgroundColor: 'red!important'
                        }, */
                      }}
                    >
                      <TableCell
                      sx={{display: 'none'}}
                      padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell sx={(isItemSelected===true && props.replaceMode===true ) ? {borderBottom: '1px solid var(--primary)',borderLeft: '1px solid var(--primary)',borderTop: '1px solid var(--primary)',paddingLeft: '10px', borderBottomLeftRadius: '4px', borderTopLeftRadius: '4px'} : {borderBottom: '1px solid rgba(224, 224, 224, 1)',borderLeft: '1px solid rgba(0,0,0,0)',borderTop: '1px solid rgba(224, 224, 224, 1)',paddingLeft: '10px', borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px'}}><div style={{backgroundColor: row.hexCode, height: '20px', width:'30px',float:'left', borderRadius:'8px', padding: '0px!important'}}></div></TableCell>
                      <TableCell
                      sx={(isItemSelected===true && props.replaceMode===true ) ? {borderBottom: '1px solid var(--primary)',borderTop: '1px solid var(--primary)', padding: '0px!important'}:{ borderBottom: '1px solid rgba(224, 224, 224, 1)',borderTop: '1px solid rgba(224, 224, 224, 1)',padding: '0px!important'}}
                      className='dmcTableRowText'
                        component="th"
                        id={labelId}
                        scope="row"
                        
                      >
                        {row.name}
                      </TableCell>
                      
                      <TableCell className='dmcTableRowText' padding='none' sx={(isItemSelected===true && props.replaceMode===true ) ?{borderBottom: '1px solid var(--primary)',borderTop: '1px solid var(--primary)',paddingRight: '0px', textOverflow: 'ellipsis', overflow: 'hidden', height:'1.2em', whiteSpace: 'nowrap', maxWidth: '80px' } : {borderBottom: '1px solid rgba(224, 224, 224, 1)',borderTop: '1px solid rgba(224, 224, 224, 1)',paddingRight: '0px', textOverflow: 'ellipsis', overflow: 'hidden', height:'1.2em', whiteSpace: 'nowrap', maxWidth: '80px' }}>{row.flossName}</TableCell>
                      
                      <TableCell className='dmcTableRowText'padding="none" align='right' sx={(isItemSelected===true && props.replaceMode===true ) ? {borderBottom: '1px solid var(--primary)',borderRight: '1px solid var(--primary)',borderTop: '1px solid var(--primary)',paddingRight: '10px!important', borderBottomRightRadius: '4px', borderTopRightRadius: '4px'} : {borderBottom: '1px solid rgba(224, 224, 224, 1)',borderRight: '1px solid rgba(0,0,0,0)',borderTop: '1px solid rgba(224, 224, 224, 1)',paddingRight: '10px!important', borderBottomRightRadius: '0px', borderTopRightRadius: '0px'}}>{row.sting}</TableCell>
                      
                    </TableRow>
                  );
                })}

            </TableBody>

          </Table>

        </TableContainer>     
      </Paper>
    </Box>
  );
}