import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { Button, MenuItem, Select } from '@mui/material';
import { ShoppingCartRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const {t, i18n} = useTranslation('translation');
const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: t('create_page_3.table_dmc'),
  },
  {
    id: 'flossName',
    numeric: false,
    disablePadding: true,
    label: t('create_page_3.table_name'),
  },
  {
    id: 'dukke',
    numeric: false,
    disablePadding: true,
    label: t('create_page_3.table_floss'),
  },
  {
    id: 'sting',
    numeric: false,
    disablePadding: true,
    label: t('create_page_3.table_stitches'),
  },
  {
    id: 'symbol',
    numeric: false,
    disablePadding: true,
    label: t('create_page_3.table_symbol'),
  },
  {
    id: 'hexCode',
    numeric: false,
    disablePadding: false,
    label: t('create_page_3.table_color'),
  },
];

  return (
    <TableHead>
      
      <TableRow>
        <TableCell sx={{background: props.theme.palette.background.paper, display: 'none'}} padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Vælg alle farver',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
          sx={{background: props.theme.palette.background.paper , display: headCell.id==='symbol' ?  props.freeMode === false ? '' : 'none' :  '' }}
            key={headCell.id}
            className={headCell.id==="flossName" ? "dmcTableHeadHide" : ""}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {

  return (
    <Toolbar
      sx={{
        height: "32px",
        minHeight: "32px!important"
      }}
    >
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const [selectedNeedle, setSelectedNeedle] = React.useState(0);
  const [selectedFabric, setSelectedFabric] = React.useState(0);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('sting');
  const [selected, setSelected] = React.useState(props.data.map((n) => n.name));
  const [dense, setDense] = React.useState(true);
  const {t, i18n} = useTranslation('translation');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectNeedle = (event) => {
    let selected = selectedNeedle;
    switch(selected) {
      case 0:
        setSelectedNeedle(1);
        break;
        case 1:
        setSelectedNeedle(0);
          break;
    }
  }

  const handleSelectFabric = (event) => {
    let selected = selectedFabric;
    switch(selected) {
      case 0:
        setSelectedFabric(1);
        break;
        case 1:
          setSelectedFabric(0);
          break;
    }
  }

  const handleSelectSymbolChange = (event, name) => {
    for(var i = 0; i<props.data.length; i++) {
      if(props.data[i].flossName===name) 
      {
        props.data[i].symbol= event.target.value;
      }
    }
    props.handleChangeSymbols(event);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box className='no-print' >
      <Paper className='dmcTablePaper'>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className='dmcTableContainer'>
          <Table stickyHeader
            sx={{ maxHeight: 420 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              freeMode={props.freeMode}
              theme={props.theme}
              settings={props.settings}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(props.data, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                    id={"dmcRow"}
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{
                        '.MuiTableRow-root':{ backgroundColor: 'white!important'},
                        '& .Mui-selected': {
                          backgroundColor: 'red!important',
                        },
                      }}
                    >
                      <TableCell
                      sx={{display: 'none'}}
                      padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                      className='dmcTableRowText'
                        component="th"
                        id={labelId}
                        scope="row"
                        
                      >
                        {row.name}
                      </TableCell>
                      
                      <TableCell className='dmcTableRowTextHide' padding='none' sx={{paddingRight: '5px'}}>{row.flossName}</TableCell>
                      <TableCell className='dmcTableRowText'padding="none">{row.dukke}</TableCell>
                      <TableCell className='dmcTableRowText'padding="none">{row.sting}</TableCell>
                      <TableCell padding="none"  sx={{display: props.freeMode === false ? '' : 'none' }}>
                      <Select
                              className='selectSymbols'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              id="demo-simple-select-helper"
                              /* defaultValue={row.symbol} */
                              value={row.symbol}
                              onChange={(e)=>{handleSelectSymbolChange(e,row.flossName)}}
                            >
                              {props.dmcSymbols.map((symbol) => (
                                <MenuItem value={symbol}>{<i className="material-icons" style={{fontSize: '21px', marginTop: '5px'}}>{symbol}</i>}</MenuItem>
                              ))}
                              
                            </Select>
                        
                        </TableCell> 
                      <TableCell ><div style={{backgroundColor: row.hexCode, height: '20px', width:'40px',float:'left', borderRadius:'4px'}}></div></TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>

          </Table>

        </TableContainer>
        {i18n.language=== 'da' ? 
        <div>
        {props.theme.palette.mode === 'dark' ? 
        
        <img src={require('./images/broderist-light.png')} style={{maxHeight: '50px', marginTop:'20px'}}></img>
        :
        <img src={require('./images/broderist.png')} style={{maxHeight: '50px', marginTop:'20px'}}></img>
        }
        
        <Typography color="text.secondary" sx={{m:0, padding: '0px 30px'}}>Køb det garn du mangler på webshoppen Broderist.com{/* I samarbejde med Broderist.com kan du med et enkelt klik,<br></br>lægge det garn du skal bruge direkte i kurven på Broderistens webshop. */}</Typography>
{/*         <div className='ekstraTilføjTilKurven'>
        <Checkbox
                          color="primary"
                          checked={selectedNeedle}
                           onClick={handleSelectNeedle}
                        />      <Typography color="text.secondary" sx={{m:0}}>Tilføj nål til kurven?</Typography>
        </div>
        <div className='ekstraTilføjTilKurven'>
        <Checkbox
                          color="primary"
                          checked={selectedFabric}
                           onClick={handleSelectFabric}
                          
                        />      <Typography color="text.secondary" sx={{m:0}}>Tilføj broderistof til kurven?</Typography>
        </div> */}
{/*         {(selected.length+selectedFabric+selectedNeedle)>0 ? 
            <Button className='no-print' size='large' variant="contained" startIcon={<ShoppingCartRounded />}  sx={{transition: 'box-shadow 150ms ease-in 0s', margin: '8px 8px 20px 8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>Læg {selected.length+selectedFabric+selectedNeedle} produkter i kurven</Button>
           :
           <Button className='no-print' size='large' disabled={true} variant="outlined" startIcon={<ShoppingCartRounded />}  sx={{transition: 'all 150ms ease-in 0s', margin: '8px 8px 20px 8px'  }}>Ingen produkter valgt</Button>
        } */}
         <Button href='https://broderist.com/vare-kategori/broderigarn/' target={'_blank'} className='no-print' size='large' variant="contained" startIcon={<ShoppingCartRounded />}  sx={{transition: 'box-shadow 150ms ease-in 0s', margin: '8px 8px 20px 8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>Køb dit garn her</Button>
         </div>
        : <div></div>}
        
        
      </Paper>
    </Box>
  );
}