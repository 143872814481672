import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { createUserWithEmailAndPassword } from '@firebase/auth'
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebase'
import { Card, Divider, Switch } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Copyright from './copyright';
import VisibilityPasswordTextField from './components/VisibilityPasswordTextField';
import { query, getDocs, collection, where, addDoc, doc, getDoc, setDoc } from "@firebase/firestore";
import { useUserAuth } from './UserAuthContext';
import { useTranslation } from 'react-i18next';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

export default function SignUpWithEmail(props) {
  const {t, i18n} = useTranslation('translation');
  const [values, setValues] = React.useState({
    password1: "",
    password2: "",
    email: "",
    loading: false,
  });
  const { signUp } = useUserAuth();
  const [emailText, setEmailText] = React.useState(null);
  const [password1Text, setPassword1Text] = React.useState(null);
  const [password2Text, setPassword2Text] = React.useState(null);
  const [newsletter, setNewsletter] = React.useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const samePasswordError = values.password1 !== values.password2;
  const noEmailError = values.email === "";
  const noPassword1Error = values.password1 === "";
  const noPassword2Error = values.password2 === "";
  const navigate = useNavigate();

  const handleSubmit = async (event, subscribe) => {
    event.preventDefault();
    setEmailText(null);
    setPassword1Text(null);
    setPassword2Text(null);
    let shouldLoad = true;
    const data = new FormData(event.currentTarget);
    if (noEmailError) {
      setEmailText(t('common.validation_1'));
      shouldLoad = false;
    } else if (!emailValidation()) {
      setEmailText(t('common.validation_2'));
      shouldLoad = false;
    }

    if (noPassword1Error) {
      setPassword1Text(t('common.validation_1'));
      shouldLoad = false;
    } else if (samePasswordError) {
      setPassword1Text(t('common.validation_3'));
      shouldLoad = false;
    }
    if (noPassword2Error) {
      setPassword2Text(t('common.validation_1'));
      shouldLoad = false;
    } else if (samePasswordError) {
      setPassword2Text(t('common.validation_3'));
      shouldLoad = false;
    }
    if (shouldLoad) {
      if(newsletter===true) {
        await subscribe({EMAIL: values.email});
      }
      registerWithEmailAndPassword(data.get("email"), data.get("password"));
    }

  };

  const handleGoToLogin = (e) => {
    e.preventDefault();
    navigate('/login');
  };

  const handleChange = change => event => {
    setValues({ ...values, [change]: event.target.value });
  };

  const registerWithEmailAndPassword = async (email, password) => {
    setValues({ ...values, loading: true });
    try {
      let res = await signUp(email, password);
      if(res === true ) {
        props.handleOpenSnackbar("success", t('snackbars.created'));
        navigate("/create");
        return true;
      } else if (res === "Firebase: Error (auth/email-already-in-use).") {
        setValues({ ...values, loading: false });
        props.handleOpenSnackbar("error", t('snackbars.already'));
        return false;
      }
      else {
        setValues({ ...values, loading: false });
        props.handleOpenSnackbar("error", t('snackbars.error'));
        return false;
      }
      
      // await addDoc(collection(db, "users"), {
      //   uid: user.uid,
      //   authProvider: "local",
      //   email,
      // });
    } catch (err) {
      setValues({ ...values, loading: false });
      if (err.message === "Firebase: Error (auth/email-already-in-use).") {
        props.handleOpenSnackbar("error", t('snackbars.already'));
        return false;
      }
      else {
        props.handleOpenSnackbar("error", t('snackbars.error'));
        console.error(err.message);
        return false;
      }
    }
  };

  const handleGoToHome = (e) => {
    e.preventDefault();
    navigate('/signup');
  };

  const handleToggleNewsletter = (e) => {
    e.preventDefault();
    let nletter = newsletter;
    setNewsletter(!nletter);
  };

  const emailValidation = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(values.email) === false) {
      return false;
    }
    return true;
  }


  // a basic form



  return (

    <Container component="main" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <CssBaseline />
{/*       <Card sx={{ boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', maxWidth: '556px', padding: '0px 20px 50px 20px', margin: 'auto' }}>
        <Button onClick={handleGoToHome} startIcon={<ArrowBack />} size='large' sx={{ float: 'left', transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '0px' }}>{t('common.back_button')}</Button>
        <Box
          sx={{
            margin: 'auto',
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('create_profile_email.title')}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: 'calc(100% - 37px)' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={values.email}
                  onChange={handleChange("email")}
                  error={emailText !== null ? true : false}
                  helperText={emailText}
                  required
                  fullWidth
                  id="email"
                  label={t('common.email')}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <VisibilityPasswordTextField
                  value={values.password1}
                  onChange={handleChange("password1")}
                  error={password1Text !== null ? true : false}
                  helperText={password1Text}
                  required
                  fullWidth
                  name="password"
                  label={t('common.password')}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onVisibilityChange={setIsPasswordVisible}
                  isVisible={isPasswordVisible}
                />
              </Grid>
              <Grid item xs={12}>
                <VisibilityPasswordTextField
                  value={values.password2}
                  onChange={handleChange("password2")}
                  error={password2Text !== null ? true : false}
                  helperText={password2Text}
                  required
                  fullWidth
                  name="repassword"
                  label={t('common.repeat_password')}
                  type="password"
                  id="repassword"
                  autoComplete="new-password"
                  onVisibilityChange={setIsPasswordVisible}
                  isVisible={isPasswordVisible}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{mt: '12px'}}>
                <FormControlLabel
                  control={<Checkbox onClick={handleToggleNewsletter} checked={newsletter} value={newsletter} color="primary" />}
                  label={t('common.newsletter')}
                />
              </Grid>
            <Button
              type="submit"
              fullWidth
              size='large'
              disabled={values.loading}
              variant="contained"
              sx={{ mt: 3, mb: 3, backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;' } }}
            >
              {values.loading ? <CircularProgress size={26.25} sx={{ color: 'white' }} /> : t('common.button_create_profile')}
            </Button>
            <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center' }}>
              <Grid item sx={{ display: 'flex' }}>
                <Typography variant="body2" color="text.secondary">
                  {t('create_profile_email.already_have_1')} </Typography>
                <Link href="" variant="body2" onClick={handleGoToLogin} sx={{ width: '94px' }}>
                {t('create_profile_email.already_have_2')}
                </Link>
              </Grid>
            </Grid>
            <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center', mt: '15px' }}>
              <Grid item sx={{ display: 'flex' }}>
                <Typography variant="body2" color="text.secondary">
                  {t('create_profile_email.by_creating_1')} <Link sx={{cursor: 'pointer'}} onClick={()=>{navigate('/privacypolicy')}} variant="body2">
                  {t('common.privacypolicy')}
                  </Link> {t('create_profile_email.by_creating_2')} <Link sx={{cursor: 'pointer'}} onClick={()=>{navigate('/cookiepolicy')}} variant="body2" >
                  {t('common.cookiepolicy')}
                  </Link></Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
       
      </Card> */}
      <MailchimpSubscribe url={'https://embrodia.us8.list-manage.com/subscribe/post?u=8ce4e345decc79088aa61e7ce&amp;id=e196aa187d&amp;f_id=00005ce0f0'} render={
          ({ subscribe }) => 
          (
                <Card sx={{ boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', maxWidth: '556px', padding: '0px 20px 50px 20px', margin: 'auto' }}>
                    <Button onClick={handleGoToHome} startIcon={<ArrowBack />} size='large' sx={{ float: 'left', transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '0px' }}>{t('common.back_button')}</Button>
                    <Box
                      sx={{
                        margin: 'auto',
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5">
                        {t('create_profile_email.title')}
                      </Typography>
                      <Box component="form" noValidate onSubmit={(e)=>{handleSubmit(e,subscribe)}} sx={{ mt: 3, width: 'calc(100% - 37px)' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              key="email"
                              value={values.email}
                              onChange={handleChange("email")}
                              error={emailText !== null ? true : false}
                              helperText={emailText}
                              required
                              fullWidth
                              id="email"
                              label={t('common.email')}
                              name="email"
                              autoComplete="email"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <VisibilityPasswordTextField
                              value={values.password1}
                              onChange={handleChange("password1")}
                              error={password1Text !== null ? true : false}
                              helperText={password1Text}
                              required
                              fullWidth
                              key="password"
                              name="password"
                              label={t('common.password')}
                              type="password"
                              id="password"
                              autoComplete="new-password"
                              onVisibilityChange={setIsPasswordVisible}
                              isVisible={isPasswordVisible}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <VisibilityPasswordTextField
                              value={values.password2}
                              onChange={handleChange("password2")}
                              error={password2Text !== null ? true : false}
                              helperText={password2Text}
                              required
                              fullWidth
                              key="repassword"
                              name="repassword"
                              label={t('common.repeat_password')}
                              type="password"
                              id="repassword"
                              autoComplete="new-password"
                              onVisibilityChange={setIsPasswordVisible}
                              isVisible={isPasswordVisible}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{mt: '12px'}}>
                            <FormControlLabel
                              control={<Checkbox onClick={handleToggleNewsletter} checked={newsletter} value={newsletter} color="primary" />}
                              label={t('common.newsletter')}
                            />
                          </Grid>
                        <Button
                          type="submit"
                          fullWidth
                          size='large'
                          disabled={values.loading}
                          variant="contained"
                          sx={{ mt: 3, mb: 3, backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;' } }}
                        >
                          {values.loading ? <CircularProgress size={26.25} sx={{ color: 'white' }} /> : t('common.button_create_profile')}
                        </Button>
                        <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center' }}>
                          <Grid item sx={{ display: 'flex' }}>
                            <Typography variant="body2" color="text.secondary">
                              {t('create_profile_email.already_have_1')} </Typography>
                            <Link href="" variant="body2" onClick={handleGoToLogin} sx={{ width: '94px' }}>
                            {t('create_profile_email.already_have_2')}
                            </Link>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center', mt: '15px' }}>
                          <Grid item sx={{ display: 'flex' }}>
                            <Typography variant="body2" color="text.secondary">
                              {t('create_profile_email.by_creating_1')} <Link sx={{cursor: 'pointer'}} onClick={()=>{navigate('/privacypolicy')}} variant="body2">
                              {t('common.privacypolicy')}
                              </Link> {t('create_profile_email.by_creating_2')} <Link sx={{cursor: 'pointer'}} onClick={()=>{navigate('/cookiepolicy')}} variant="body2" >
                              {t('common.cookiepolicy')}
                              </Link></Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                   
                  </Card>
              )}/>
      <Grid item xs={12} sx={{ mt: 4 }}></Grid>
      <Copyright />
    </Container>

  );
}