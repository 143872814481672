import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Button, FormLabel, Select, TextField } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserAuth } from './UserAuthContext';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Switch } from '@mui/material';
import { Check, HelpRounded, QuestionMarkRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { EditRounded, SaveRounded } from '@mui/icons-material';
import { getDoc, doc, updateDoc, getDocs, query, collection, where } from "@firebase/firestore";
import { db } from './firebase';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { CheckCircleRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
export default function AccountMenu(props) {
  const { logOut, user, createCheckoutSession, redirectToCustomerPortal } = useUserAuth();
  const [name, setName] = React.useState("");
  const [photoURL, setPhotoURL] = React.useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = React.useState(false);
  const [canceledSubscription, setCanceledSubscription] = React.useState(false);
  const [subscriptionDate, setSubscriptionDate] = React.useState("");
  const [subscriptionType, setSubscriptionType] = React.useState("");
  const [patternsBought, setPatternsBought] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [dateOfBirth, setDateOfBirth] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mode, setMode] = React.useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();
  const [t, i18n] = useTranslation('translation');
  const handleClick = (event) => {
    if(location.pathname==="/create") {
      setMode(true);
    } else {
      setMode(false);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (user instanceof Object) {
      handleFetchPhoto();
    }
  }, []);

  const [openSettings, setOpenSettings] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const handleOpenSettings = () => {


    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  const handleFetchPhoto = async () => {
    try {
      const docRef = doc(db, "users", user.uid);
      const docs = await getDoc(docRef);
      const data = docs.data();
      if (docs.exists() === true) {
        setPhotoURL(data.photoUrl);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleOpenProfile = async () => {

    const docRef = doc(db, "users", user.uid);
    const docs = await getDoc(docRef);
    if (docs.data() instanceof Object) {
      setName(docs.data().name);
      setEmail(docs.data().email);
      setPhotoURL(docs.data().photoUrl);
      const q = query(collection(db, "users", user.uid + '/subscriptions'), where('status', 'in', ['trialing', 'active']));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        // doc.data() is never undefined for query doc snapshots
        /*         console.log(doc.id, " => ", doc.data()); */
        setSubscriptionStatus(true);
        const date = doc.data().current_period_end.toDate();
        setSubscriptionDate(date.toLocaleString());
        setCanceledSubscription(doc.data().cancel_at_period_end);
        switch (doc.data().items[0].plan.product) {
          case 'prod_M7JivbkPr8mNNl':
            setSubscriptionType(t('top_bar_menu.my_profile_subscription_1'));
            break;
          case 'prod_M7Jh5hfrkSRqvx':
            setSubscriptionType(t('top_bar_menu.my_profile_subscription_2'));
            break;
          case 'prod_M7JhuJdcUKVRyk':
            setSubscriptionType(t('top_bar_menu.my_profile_subscription_3'));
            break;
          default:
            setSubscriptionType(t('top_bar_menu.my_profile_subscription_4'));
            break;
        }

      });

      const q2 = query(collection(db, "users", user.uid + '/payments'), where('metadata.valid', '!=', '0'));
      const query2Snapshot = await getDocs(q2);
      let countPatternsBought = 0;
      query2Snapshot.forEach(async (doc) => {
        countPatternsBought += parseInt(doc.data().metadata.valid);
      });
      setPatternsBought(countPatternsBought);

      if (docs.data().dateOfBirth !== undefined && docs.data().dateOfBirth !== "") {
        setDateOfBirth(docs.data().dateOfBirth.toDate());
      }
    }
    setOpenProfile(true);

  };
  const handleOpenSavedPatternsThis = () => {
    handleCloseProfile();
    props.handleOpenSavedPatterns();
  };


  const handleEditProfile = () => {
    setEditMode(true);
  };

  /*   const checkIfMadeChange = (data) => {
      let madeChange = false;
      if(data.name!==name) {
        madeChange = true;
      }
      if(data.dateOfBirth!=="") {
        if(data.dateOfBirth.toDate()!==dateOfBirth) {
          madeChange = true;
        }
      }
      return madeChange;
    } */

  async function handleSaveProfile() {
    try {
      const docRef = doc(db, "users", user.uid);
      const docs = await getDoc(docRef);
      /* const data = docs.data(); */
      let nameToSave = "";
      if(name!==undefined) {
        nameToSave = name;
      }
      if (docs.exists() === true) {
        if (dateOfBirth instanceof Date) {
          await updateDoc(docRef, {
            name: nameToSave,
            dateOfBirth: dateOfBirth
          });
        } else {
          await updateDoc(docRef, {
            name: nameToSave
          });
        }
      }
      setEditMode(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  }

  const handleCloseProfile = () => {
    setEditMode(false);
    setOpenProfile(false);
  };

  const handleSwitchLanguage = e => {
    e.preventDefault();
    changeLanguage(e.target.value);
  }
  const handleClickLogout = async () => {
    try {
      await logOut();
      document.body.classList = '';
      props.handleOpenSnackbarTheSnackBar("success", t('snackbars.signout'));
      props.handleResetAllPatterns();
      navigate("/home");

    } catch (err) {
      props.handleOpenSnackbarTheSnackBar("error", t('snackbars.error'));
      console.log(err.message);
    }
  };
  return (

    <React.Fragment>



      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Min Profil">
          {/* <Button onClick={handleClick} end={<AccountCircle/>} size='medium' sx={{float: 'right',transition: 'all 150ms ease-in 0s', margin: '8px' }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>Profil</Button> */}
          <Button
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, minWidth: 0, borderRadius: '64px', padding: '0px' }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <Avatar src={photoURL} sx={{ width: 40, height: 40 }} />
          </Button>

        </Tooltip>

      </Box>
      <Menu
        disableScrollLock={true}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleOpenProfile}>
          <Avatar src={photoURL} /> {t('top_bar_menu.my_profile')}

        </MenuItem>
        <Divider />
        <MenuItem onClick={handleOpenSettings}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t('top_bar_menu.settings')}
        </MenuItem>
        { mode===true &&
        <MenuItem onClick={props.handleOpenGuide}>
          <ListItemIcon>
            <HelpRounded fontSize="small" />
          </ListItemIcon>
          {t('top_bar_menu.worth_knowing')}
        </MenuItem> }
        <MenuItem onClick={handleClickLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('top_bar_menu.sign_out')}
        </MenuItem>
      </Menu>

      <Dialog
        disableScrollLock={true}
        open={openSettings}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        

      >
        <DialogTitle sx={{ minWidth: '300px', display: 'flex', alignItems: 'center' }}><Settings sx={{ mr: '10px' }} />{t('top_bar_menu.settings')}</DialogTitle>
        <DialogContent dividers={true} sx={{maxWidth: '300px'}}>
          <Typography variant="h6" component="div" align='left'>
          {t('top_bar_menu.settings_1')}
          </Typography>
          <Typography variant="h7" component="div" align='left' color={'text.secondary'}>
          {t('top_bar_menu.settings_1_language')}
          </Typography>
          <Select
                                        className='selectInputLanguages'
                                        MenuProps={{ disableScrollLock: true }}
                                        size='small'
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        /* defaultValue="en" */
                                        value={i18n.language}
                                        onChange={handleSwitchLanguage}
                                        SelectDisplayProps={{style:{display: 'flex', alignItems: 'center'}}}
                                        sx={{mb: '12px', mt: '6px'}}
                                        
                                      >
                                        
                                        <MenuItem value={'en'}><img style={{height: '20px', marginRight: '6px'}} src={require('./images/english.png')}/>English</MenuItem>
                                        <MenuItem value={'da'}><img style={{height: '20px', marginRight: '6px'}} src={require('./images/danish.png')}/>Dansk</MenuItem>
                                      </Select>
          <Typography variant="h7" component="div" align='left' color={'text.secondary'}>
          {t('top_bar_menu.settings_1_darkmode')}
          </Typography>
          <FormLabel>{t('top_bar_menu.settings_1_darkmode_off')}</FormLabel>
          <Switch checked={props.settings.isDarkMode ? true : false} onChange={props.handleModeChange} />
          <FormLabel>{t('top_bar_menu.settings_1_darkmode_on')}</FormLabel>
          
            <Typography variant="h7" component="div" align='left' color={'text.secondary'}>
          {t('top_bar_menu.settings_1_uncapped')}
          </Typography>
          <FormLabel>{t('top_bar_menu.settings_1_off')}</FormLabel>
          <Switch checked={props.settings.isUncapped ? true : false} onChange={props.handleChangeCappedPatternSetting} />
          <FormLabel>{t('top_bar_menu.settings_1_on')}</FormLabel>
          {props.settings.isUncapped &&
          <Typography variant="h7" component="div" align='left' color={'red'} sx={{}}>
           {t('top_bar_menu.settings_1_uncapped_warning')}
          </Typography>}
          {/* <Divider sx={{ m: '14px 0px' }} />
          <Typography variant="h6" component="div" align='left'>
          {t('top_bar_menu.settings_2')}
          </Typography>
          <Typography variant="h7" component="div" align='left' color={'text.secondary'}>
          {t('top_bar_menu.settings_2_color_under_symbols')}
          </Typography>
          <FormLabel>{t('top_bar_menu.settings_2_color_under_symbols_off')}</FormLabel>
          <Switch checked={props.settings.printSettings.colorPrint ? true : false} onChange={props.handleColorPrintModeChange} />
          <FormLabel>{t('top_bar_menu.settings_2_color_under_symbols_on')}</FormLabel> */}
 {/*          <Divider sx={{ m: '14px 0px' }} />
          <Typography variant="h6" component="div" align='left'>
            Advancerede Indstillinger
          </Typography>
          <Typography variant="h7" component="div" align='left' color={'text.secondary'}>
            Mulighed for valg af symboler
          </Typography>
          <FormLabel>Fra</FormLabel>
          <Switch checked={props.settings.advancedSettings.symbolPicker ? true : false} onChange={props.handleSymbolPickerModeChange} />
          <FormLabel>Til</FormLabel>
 */}
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Check />} onClick={handleCloseSettings}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableScrollLock={true}
        open={openProfile}
        keepMounted
        aria-describedby="alert-dialog-slide-description"

      >
        <DialogTitle sx={{ minWidth: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><div style={{ display: 'flex', alignItems: 'center' }}><Avatar src={photoURL} sx={{ mr: '10px' }} />{t('top_bar_menu.my_profile')}</div>{editMode ? <Button sx={{ width: '100px' }} startIcon={<SaveRounded />} onClick={handleSaveProfile}>{t('top_bar_menu.my_profile_save_button')}</Button> : <Button sx={{ width: '100px' }} startIcon={<EditRounded />} onClick={handleEditProfile}>{t('top_bar_menu.my_profile_edit_button')}</Button>}</DialogTitle>

        <DialogContent dividers={true}>
          {/*               <Typography variant="h7" component="div" align='left'>
              Billede
            </Typography>    
            <Avatar src={dbUser.photoUrl} sx={{width: '60px', height: '60px'}}/>
            <Divider sx={{mb:'15px', mt: '15px'}}/> */}
          <Typography variant="h7" component="div" align='left'>
          {t('top_bar_menu.my_profile_name')}
          </Typography>
          <TextField
            fullWidth
            size='small'
            disabled={!editMode}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            /* helperText={t('top_bar_menu.my_profile_name_default')} */
            value={name}
            id="demo-helper-text-aligned"
            variant='standard'
            onChange={handleChangeName}
          />
          {/*             <Divider sx={{mb:'15px', mt: '15px'}}/>
            <Typography variant="h7" component="div" align='left'>
              Email 
            </Typography>
            
            <TextField
            fullWidth
                size='small'
                disabled
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                defaultValue={dbUser.email}
                value={email}
                id="demo-helper-text-aligned"
                variant='standard'
              />     */}
        </DialogContent>
        <DialogContent sx={{ borderTop: 'none' }} dividers={true}>
          <Typography variant="h7" component="div" align='left'>
            Email
          </Typography>

          <TextField
            fullWidth
            size='small'
            disabled
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            /* helperText={t('top_bar_menu.my_profile_email_default')}   */
            value={email}
            id="demo-helper-text-aligned"
            variant='standard'
          />
        </DialogContent>
        <DialogContent sx={{ borderTop: 'none' }} dividers={true}>
          <Typography variant="h7" component="div" align='left'>
          {t('top_bar_menu.my_profile_date_of_birth')}
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={!editMode}
              value={dateOfBirth}
              onChange={(newValue) => {
                setDateOfBirth(newValue);
              }}
              renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
            />
          </LocalizationProvider>
        </DialogContent>
        
         {/*  { mode===true && */}
        <DialogContent sx={{ borderTop: 'none' }} dividers={true}>
          <Typography variant="h7" component="div" align='left' sx={{ marginBottom: '5px' }}>
          {t('top_bar_menu.my_profile_my_saved_patterns')}
          </Typography>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
            <Button onClick={handleOpenSavedPatternsThis} className='no-print' size='large' variant="contained" startIcon={<SaveRounded />} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>
            {t('top_bar_menu.my_profile_show_saved_patterns')}</Button>
          </div>
        </DialogContent>{/*  } */}
        <DialogContent sx={{ borderTop: 'none' }} dividers={true}>
          <Typography variant="h7" component="div" align='left' sx={{ marginBottom: '5px' }}>
          {t('top_bar_menu.my_profile_my_subscription')}
          </Typography>
          <Typography variant="h7" component="div" align='left' color={'text.secondary'}>
          {t('top_bar_menu.my_profile_my_subscription_status')} {subscriptionStatus ? (canceledSubscription ? t('top_bar_menu.my_profile_subscription_canceled') : t('top_bar_menu.my_profile_subscription_active')) : t('top_bar_menu.my_profile_subscription_not_active')}
          </Typography>
          {subscriptionStatus ?
            <div>
              <Typography variant="h7" component="div" align='left' color={'text.secondary'}>
              {t('top_bar_menu.my_profile_my_subscription_type')} {subscriptionType}
              </Typography>
              <Typography variant="h7" component="div" align='left' color={'text.secondary'}>
                {canceledSubscription ? t('top_bar_menu.my_profile_subscription_ends') : t('top_bar_menu.my_profile_subscription_next_payment')} {subscriptionDate}
              </Typography>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <Button size='large' variant="contained" startIcon={loading ? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important', color: "#fff" }} /> : <Settings />} onClick={() => { redirectToCustomerPortal(); setLoading(true) }} sx={{ width: '100%', transition: 'box-shadow 150ms ease-in 0s', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{loading ? t('common.loading') : t('top_bar_menu.my_profile_my_subscription_settings')}</Button>
              </div>
            </div>
            :
            <div>

            </div>
          }
        </DialogContent>
        {patternsBought>0 ? 
        <DialogContent sx={{ borderTop: 'none' }} dividers={true}>
          <Typography variant="h7" component="div" align='left' sx={{ marginBottom: '5px' }}>
          {t('top_bar_menu.my_profile_my_patterns_left')}
          </Typography>
          <Typography variant="h7" component="div" align='left' color={'text.secondary'}>
            {t('top_bar_menu.my_profile_my_patterns_left_number')+ patternsBought}
          </Typography>
        </DialogContent>
         : <div></div>}

        <DialogActions>
          <Button disabled={loading} startIcon={<Check />} onClick={handleCloseProfile}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}