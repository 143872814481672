import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from './UserAuthContext';
import { Card } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Copyright from './copyright';
import { useTranslation } from 'react-i18next';

export default function ForgotPassword(props) {
  const {t, i18n} = useTranslation('translation');
  const [values, setValues] = React.useState({
    email: "",
    loading: false,
  });

  const [emailText, setEmailText] = React.useState(null);
  const noEmailError = values.email === "";
  const { resetPassword } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmailText(null);
    let shouldLoad = true;
    const data = new FormData(event.currentTarget);
    if(noEmailError)
    {
      setEmailText(t('common.validation_1'));
      shouldLoad = false;
    } else if(!emailValidation()) {
      setEmailText(t('common.validation_2'));
      shouldLoad = false;
    } 
    // eslint-disable-next-line no-console
    if(shouldLoad)
    {
        handleResetPassword(data.get('email'));
    }
  };

  const handleResetPassword = async (email) => {
    setValues({ ...values, loading: true });
    try {
      await resetPassword(email);
      navigate('/login');
      props.handleOpenSnackbar("success", t('snackbars.forgot'));
    } catch (err) {
      setValues({ ...values, loading: false });
      if(err.message === "Firebase: Error (auth/user-not-found).")
      {
        props.handleOpenSnackbar("error", t('snackbars.no_user'));
      }
      else {
        props.handleOpenSnackbar("error", t('snackbars.error'));
      console.error(err.message);
      }
    }
  };

  const emailValidation = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(regex.test(values.email) === false){
        return false;
    }
    return true;
}

  const handleChange = change => event => {
    setValues({ ...values, [change]: event.target.value });
  };

  const handleGoToLogin = (e) => {
    e.preventDefault();
    navigate('/login');
  };

  return (
      <Container component="main" sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <CssBaseline />
        <Card sx={{boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', maxWidth: '556px', padding: '0px 20px 50px 20px', margin: 'auto'}}>  
        {/* <Button
              size='large'
              variant="contained"
              onClick={handleGoToHome} 
              
              sx={{padding: '13px 13px',minWidth:'0',borderRadius: '64px', float: 'left',transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '20px' , backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}  }}
            >
              <ArrowBack/>
            </Button> */}
            <Button onClick={handleGoToLogin} startIcon={<ArrowBack/>} size='large' sx={{float: 'left',transition: 'all 150ms ease-in 0s', marginTop: '20px', marginLeft: '0px' }}>{t('common.back_button')}</Button>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('forgot_password.title')}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 , width: 'calc(100% - 37px)'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2}}>
                <TextField
                  required
                  fullWidth
                  value={values.email}
                  onChange={handleChange("email")}
                  error={emailText!==null ? true : false}
                  helperText={emailText}
                  id="email"
                  label={t('forgot_password.email')}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              size='large'
              variant="contained"
              sx={{ mt: 3, mb: 3, backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}  }}
            >
              {values.loading ? <CircularProgress size={26.25} sx={{color: 'white'}}/> : t('forgot_password.new_password')}
            </Button>
          </Box>
        </Box>
        </Card>
        <Grid item xs={12} sx={{ mt: 4 }}></Grid>
        <Copyright />
      </Container>

  );
}