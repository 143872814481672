import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import Card from '@mui/material/Card';
import { CheckRounded, EditRounded, FileOpenRounded, Close, Check, PeopleAltRounded, DownloadRounded } from '@mui/icons-material';
import { DeleteForeverRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Divider, Tooltip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {


  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const {t, i18n} = useTranslation('translation');

  const headCells = [
    {
      id: 'photoUrl',
      numeric: false,
      disablePadding: false,
      label: t('saved_patterns_menu.pattern'),
    },
    {
      id: 'milis',
      numeric: false,
      disablePadding: true,
      label: '',
    },
    {
      id: 'totalStitches',
      numeric: true,
      disablePadding: true,
      label: t('saved_patterns_menu.stitchcount'),
    },
    {
      id: 'colorCount',
      numeric: true,
      disablePadding: false,
      label: t('saved_patterns_menu.dmc_colors'),
    },
  ];

  return (
    <TableHead>
      
      <TableRow>
        {props.editMode ?         <TableCell sx={{background: props.theme.palette.background.paper}} padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Vælg alle farver',
            }}
          />
        </TableCell> : <div></div>}

        {headCells.map((headCell) => (
          <TableCell
          sx={{background: props.theme.palette.background.paper, height: '60px'}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{visibility: headCell.id==='milis' ? 'hidden' : 'visible', pointerEvents: props.openSavedPatterns ? 'auto' : 'none'}}
              active={headCell.id==='photoUrl' ? orderBy === 'milis' : orderBy === headCell.id}
              direction={headCell.id==='photoUrl' ? orderBy === 'milis' ? order : 'asc' : orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.id==='photoUrl' ? createSortHandler('milis') : createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

/* const EnhancedTableToolbar = () => {

  return (
    <Toolbar
      sx={{
        height: "32px",
        minHeight: "32px!important"
      }}
    >
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}; */

export default function EnhancedTablePatterns(props) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('milis');
  const [selected, setSelected] = React.useState(props.data.map((n) => n.milis));
  const [dense, setDense] = React.useState(true);
  const [openAreYouSure, setOpenAreYouSure] = React.useState(false);
  const [openSharedNoSubscription, setOpenSharedNoSubscription] = React.useState(false);
const {t, i18n} = useTranslation('translation');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.data.map((n) => n.milis);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteSelected = async () => {
    handleCloseAreYouSure();
    let arr = [];
    for(let i=0; i<selected.length;i++) {
      for(let a=0; a<props.data.length;a++) {
        if(selected[i]===props.data[a].milis) {
          arr.push(props.data[a]);
        }
      }
    }
    await props.handleDeletePatterns(arr);
    setSelected([]);
  };

  const handleOpenSelected = (sel) => {
    
    props.handleOpenSavedPatternPreview(sel);
  }

  const handleCloseAreYouSure = () => {
    setOpenAreYouSure(false);
  }

  const handleOpenAreYouSure = () => {
    setOpenAreYouSure(true);
  }

/*   const handleSelectNeedle = (event) => {
    let selected = selectedNeedle;
    switch(selected) {
      case 0:
        setSelectedNeedle(1);
        break;
        case 1:
        setSelectedNeedle(0);
          break;
    }
  }

  const handleSelectFabric = (event) => {
    let selected = selectedFabric;
    switch(selected) {
      case 0:
        setSelectedFabric(1);
        break;
        case 1:
          setSelectedFabric(0);
          break;
    }
  } */

  const handleClick = (event, milis, shared) => {
    /* const d = Date.parse(created); */
    const selectedIndex = selected.indexOf(milis);
    let newSelected = [];
    if(props.editMode===true) {
     if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, milis);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    } 
    setSelected(newSelected);
  } else {
    
    if(shared) {
      
      if(props.isSubscribed===true) {
        newSelected.push(milis);
        //console.log("Helloo2!!");
        handleOpenSelected(newSelected);
      } else {
        //console.log("Helloo!!");
        setOpenSharedNoSubscription(true);
      }
      
    } else {
      newSelected.push(milis);
      handleOpenSelected(newSelected);
    }
    
  }
  };

  const isSelected = (milis) => selected.indexOf(milis) !== -1;

  return (
    <div>
    <Box className='no-print' >
      <Paper className='savedPatternsTablePaper' sx={{borderRadius: '0px!important', mb: '0px!important'}}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer sx={{maxHeight: '500px'}} className='savedPatternsTable'>
          <Table stickyHeader
            sx={{ maxHeight: 420 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            
          >
            <EnhancedTableHead
              theme={props.theme}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
              editMode={props.editMode}
              openSavedPatterns={props.openSavedPatterns}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(props.data, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.milis);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                    
                    id={"dmcRow"}
                      hover
                      onClick={(event) => handleClick(event, row.milis, row.shared)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.milis}
                      selected={isItemSelected}
                      sx={{
                         cursor: 'pointer',
                        '.MuiTableRow-root':{ backgroundColor: 'white!important'},
                        '& .Mui-selected': {
                          backgroundColor: 'red!important'
                        },
                      }}
                    >
                      {props.editMode ?                       <TableCell
    
    padding="checkbox">
      <Checkbox
        color="primary"
        checked={isItemSelected}
        inputProps={{
          'aria-labelledby': labelId,
        }}
      />
    </TableCell> : <div></div>}

                      <TableCell className='dmcTableRowText' sx={{paddingRight: '5px'}}><Card sx={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2)', width:'100px', minHeight: '100px'}}>
                        <div className='savedPatternsImageDiv' style={{position: 'relative'}}>
                        {row.shared && <Tooltip arrow title={t('common.downloaded_catalog')}><IconButton sx={{ cursor: 'default', position: 'absolute', zIndex: '1', padding: '4px', m: '0px', background: 'rgba(0,0,0,30%)',bottom: '4px', right: '4px', color: 'white', '&:hover': {background: 'rgba(0,0,0,40%)'} }}><DownloadRounded sx={{height: '0.7em', width: '0.7em'}}/>  </IconButton></Tooltip>}
                          <img style={{width: 'inherit', height: '100px',objectFit: 'contain', imageRendering: 'pixelated'}} src={row.photoUrl}></img>
                          </div></Card></TableCell>
                      <TableCell
                      className='dmcTableRowText'
                        component="th"
                        id={labelId}
                        scope="row"
                        width='100px'
                      >
                        {row.created}
                      </TableCell>
                      {/* <TableCell padding="none">{<i class="material-icons" style={{fontSize: '21px'}}>{row.symbol}</i>}</TableCell> */}

                      
                      <TableCell className='dmcTableRowText' sx={{textAlign: 'right'}}>{row.totalStitches}</TableCell>
                      <TableCell className='dmcTableRowText' sx={{textAlign: 'right'}}>{row.colorCount}</TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>

          </Table>

        </TableContainer>    

           <div style={{padding: '14px', boxShadow: '0px -2px 12px rgb(0 0 0 / 10%)'}}>
            {props.editMode? <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems:'center'}}>
              <Button onClick={handleOpenAreYouSure} startIcon={<DeleteForeverRounded/>}  disabled={selected.length>0 ? false : true} className='no-print' size='large' variant="outlined" >{t('saved_patterns_menu.delete')} {selected.length} </Button>
            <Button onClick={()=>{props.handleSetEditModeFalseSavedPatterns(); setSelected([]);}} startIcon={<CheckRounded/>}  className='no-print' size='large' variant="contained" sx={{ transition: 'box-shadow 150ms ease-in 0s', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>{t('saved_patterns_menu.done')}</Button>
            </div> : <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems:'center'}}>
            <Button onClick={()=>{props.handleSetEditModeTrueSavedPatterns(); setSelected([]);}} startIcon={<EditRounded/>} className='no-print' size='large' variant="outlined" >{t('saved_patterns_menu.edit')}</Button>
           {/*  <Button onClick={handleOpenSelected} disabled={selected.length===1 ? false : true} startIcon={<FileOpenRounded/>}  className='no-print' size='large' variant="contained" sx={{width: '210px', transition: 'box-shadow 150ms ease-in 0s', backgroundImage: selected.length===1 ? "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))" : 'none', boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>Åben Mønster</Button> */}
            
              </div>}
            </div>
      </Paper>
    </Box>
    <Dialog
        disableScrollLock={true}
        open={openSharedNoSubscription}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle /* dividers={true} */ sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: '400'}}>{t('saved_patterns_menu.notopen_title')}</DialogTitle>
        <DialogContent dividers={true} >
          <Typography gutterBottom variant="h7" align='center' sx={{ float: 'left' }}>{t('saved_patterns_menu.notopen_description')}</Typography>
        </DialogContent >
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button startIcon={<Check />} onClick={()=>{setOpenSharedNoSubscription(false)}}>{t('common.okay')}</Button>
        </DialogActions>
      </Dialog>
        <Dialog
        disableScrollLock={true}
        open={openAreYouSure}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle /* dividers={true} */ sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: '400'}}>{t('common.are_you_sure')}</DialogTitle>
        <DialogContent dividers={true} >
          <Typography gutterBottom variant="h7" align='center' sx={{ float: 'left' }}>{selected.length>1 ? t('saved_patterns_menu.are_you_sure_plural_1')+selected.length+t('saved_patterns_menu.are_you_sure_plural_2') : t('saved_patterns_menu.are_you_sure_singular')}<br></br>{t('saved_patterns_menu.not_be_undone')}</Typography>
        </DialogContent >
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button startIcon={<Close />} onClick={handleCloseAreYouSure}>{t('common.no')}</Button>
          <Button startIcon={<Check />} onClick={handleDeleteSelected}>{t('common.yes')}</Button>
        </DialogActions>
      </Dialog>
        </div>
  );
}