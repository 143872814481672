import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import Copyright from './copyright';
import { Divider, IconButton, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import { Facebook, Instagram } from '@mui/icons-material';
/* const tiers = [
  {
    title: 'Hver måned',
    price: '20',
    description: [
      'Ubegrænset adgang i en måned',
    ],
    buttonText: 'Vælg abonnement',
    buttonVariant: 'contained',
  },
  {
    title: 'Hvert halve år',
    price: '110',
    description: [
      'Ubegrænset adgang i et halvt år'
    ],
    buttonText: 'Vælg abonnement',
    buttonVariant: 'contained',
  },
  {
    title: 'En gang om året',
    subheader: 'Mest populær',
    price: '200',
    description: [
      'Ubegrænset adgang i et helt år'
    ],
    buttonText: 'Vælg abonnement',
    buttonVariant: 'contained',
  },
]; */






function PricingContent(props) {
  const [t, i18n] = useTranslation('translation');
  const navigate = useNavigate();
  const footers = [
    {
      title: t('home.footer_1_title'),
      description: [],
      link: [],
      text: ['Embrodia v. Tobias Schou Svenninggaard', 'Tåsingegade 14', '8200 Aarhus N DK' /*, t('home.footer_1_description_1') */, 'E-mail: tobyschou@gmail.com'],
    },
    {
      title: t('home.footer_2_title'),
      description: [t('home.footer_2_description_1'), t('home.footer_2_description_2')],
      link: ['/about','/help'],
      text: []
    },
    {
      title: t('home.footer_3_title'),
      description: [t('home.footer_3_description_1'), t('home.footer_3_description_2'), t('home.footer_3_description_3')],
      link: ['/privacypolicy','/termsandconditions','/cookiepolicy'],
      text: []
    },
    {
      title: t('home.footer_4_title'),
      description: ['English', 'Dansk'],
      link: ['en','da'],
      text: [],
      src: [require('./images/english.png'),require('./images/danish.png')]
    },
    {
      title: t('home.footer_5_title'),
      description: ['Facebook', 'Instagram'],
      link: ['https://www.facebook.com/embrodia','https://instagram.com/embrodia', 'https://instagram.com/embrodia_dk'],
      text: [],
      icons: [<Facebook/>, <Instagram/>]
    },
  ];
/*   const darkMode = (theme) => {
    if (theme.palette.mode === 'light') {
      return 'white'
    }
    return 'black'
  }

  const handleNavigate = e => {
    e.preventDefault();
    let text = e.target.innerHTML;
    if(text.includes('Opret profil nu')) {
      navigate('/signup')
    }
  } */

  const handleSwitchLanguage = e => {
    e.preventDefault();
    changeLanguage(e.target.value);
  }

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
        <Card sx={{borderRadius: '0px', boxShadow: '0 0px 18px rgb(0 0 0 / 30%);'}}>
          {/* 
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Priser
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Få 7 dage gratis prøveperiode når du opretter dig som ny bruger
        </Typography> */}
{/*         <Button size='large' variant='contained' onClick={()=>{navigate('/signup')}} sx={{ backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}}}>
                    Opret en ny profil her
                  </Button> */}
{/*                   <Typography variant="h5" align="center" color="text.secondary" component="p">
          Du kan altid afmelde dit abonnement når som helst
        </Typography>
      </Container>  */}
      {/* End hero unit */}
      {/*  <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card sx={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);'}}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'En gang om året' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      DKK
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                {tier.buttonVariant==='contained' ? 
                  <Button size='large' fullWidth variant={tier.buttonVariant} onClick={handleNavigate} sx={{ backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}}}>
                    {tier.buttonText}
                  </Button>
                  :
                  <Button size='large' fullWidth variant={tier.buttonVariant} onClick={handleNavigate}>
                    {tier.buttonText}
                  </Button>
                                  }
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>  */}
      
      {/* Footer */}
      
      <Container
        maxWidth="lg"
        component="footer"
        sx={{
         /*  borderTop: (theme) => `1px solid ${theme.palette.divider}`, */
          mt: 8,
          py: [3, 6]
        }}
      >
{/* <Divider sx={{marginTop: '-32px', marginBottom: '64px'}}/> */}
        <Grid sx={{ml: '0px!important'}} padding={0} container spacing={4} justifyContent="space-evenly">
          {footers.map((footer, i) => (
            <Grid sx={{padding: '0px!important'}} item xs={6} sm={2} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              {i === 3 ? 
                          <ul>
                                        <Select
                                        className='selectInputLanguages'
                                        MenuProps={{ disableScrollLock: true }}
                                        size='small'
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        /* defaultValue="en" */
                                        value={i18n.language}
                                        onChange={handleSwitchLanguage}
                                        SelectDisplayProps={{style:{display: 'flex', alignItems: 'center'}}}
                                        
                                      >
                                        {footer.description.map((item,i) => (
                                        <MenuItem value={footer.link[i]}><img style={{height: '20px', marginRight: '6px'}} src={footer.src[i]}/>{item}</MenuItem>
                                        ))}
                                      </Select>

                          
                        </ul>
                        :
                        i=== 4 ? 
                        <ul style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '32px'}}>
                        {footer.description.map((item,i) => (
                        <li key={item}>
                            
                        <IconButton href={item==="Instagram" ? i18n.language==='da' ? footer.link[2]: footer.link[1] : footer.link[i]} target={'_blank'}>
                          {footer.icons[i]}
                        </IconButton>
                      </li> 
                        ))}
                      </ul>  :
                       <ul>
                       {footer.description.map((item,i) => (
                       <li key={item}>
                           
                       <Link onClick={()=>navigate(footer.link[i])} sx={{cursor: 'pointer'}} variant="subtitle1" color="text.secondary">
                         {item}
                       </Link>
                     </li> 
                       ))}
                       
                     </ul> 
                     
            }

              <ul>
                {footer.text.map((item,i) => (
                  <li key={item} style={{textAlign: 'left'}}>
                    <Typography align='left' variant="h7" color="text.secondary">
                      {item}
                    </Typography>
                  </li>
                ))}
               {/* {i===4 && 
                        <li key={"trustpilot"} style={{textAlign: 'left'}}>
                       <div class="trustpilot-widget" data-locale={i18n.language==='da'? "da-DK" : "en-US"} data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="643b14fa85b039aeec271b8b" data-style-height="50px" data-style-width="100%" data-theme={props.theme.palette.mode === "dark" ? "dark" : "light"} data-min-review-count="0" data-style-alignment="center">
                         <a href="https://www.trustpilot.com/review/embrodia.com" target="_blank" rel="noopener">Trustpilot</a>
                       </div>
                       </li> 
                       }*/}
              </ul>
            </Grid>
          ))}
        </Grid>
      </Container>
      
      <Copyright />
      </Card>
      {/* End footer */}
    </React.Fragment>
  );
}

export default function Pricing(props) {
  return <PricingContent props={props} theme={props.theme}/>;
}