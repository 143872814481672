import * as React from 'react';
import Card from '@mui/material/Card';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { ArrowForward, Grid4x4Rounded, PaletteRounded } from '@mui/icons-material';
import { Fade } from 'react-slideshow-image';
import { useTranslation } from 'react-i18next';

function ShowcaseContent() {
    const {t, i18n} = useTranslation('translation');
const [fadeImagesAfter, setFadeImagesAfter] = React.useState([]);
const [fadeImagesBefore, setFadeImagesBefore] = React.useState([]);

    function shuffle(obj1, obj2) {
        var index = obj1.length;
        var rnd, tmp1, tmp2;
      
        while (index) {
          rnd = Math.floor(Math.random() * index);
          index -= 1;
          tmp1 = obj1[index];
          tmp2 = obj2[index];
          obj1[index] = obj1[rnd];
          obj2[index] = obj2[rnd];
          obj1[rnd] = tmp1;
          obj2[rnd] = tmp2;
        }
        setFadeImagesAfter(imagesAfter);
        setFadeImagesBefore(imagesBefore);
      }

    var imagesAfter = [
        {
        url: require('./images/picAfter1.png'),
        caption: '15',
        caption2: '28x28cm'
        },
        {
        url: require('./images/picAfter4.jpg'),
        caption: '1',
        caption2: '4.6x5.6cm'
        },
        {
        url: require('./images/picAfter3.png'),
        caption: '9',
        caption2: '18x18cm'
        }
      ];
    var imagesBefore = [
        {
        url: require('./images/picBefore1.jpg')
        },
        {
        url: require('./images/picBefore4.png')
        },
        {
        url: require('./images/picBefore3.jpg')
        }
      ];

      React.useEffect(()=>{
        shuffle(imagesAfter,imagesBefore);
      }, []);
    const fadeProperties = {
        cssClass: 'fadeContainer',
        duration: 3000,
        transitionDuration: 400,
        infinite: true,
        arrows: false,
        indicators: false,
        pauseOnHover: false,
/*         onChange: (oldIndex, newIndex) => {
          console.log(`fade transition from ${oldIndex} to ${newIndex}`);
        } */
      }

    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Card sx={{borderRadius: '0px', boxShadow: '0 0px 18px rgb(0 0 0 / 30%);'}}>
                <Container className='paddingSmallShowcase' disableGutters maxWidth="sm" component="main" sx={{ pt: 4, pb: 6, display: 'block', justifyContent: 'center' }}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                        sx={{mr: '0px'}}
                        className='overskriftHome'
                    >
                        {t('home.section_1_title')}{/* <h1 className='mønsterSkrift'>{'MØNSTER'}</h1> */}
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" component="p"
                        sx={{mr: '0px'}}
                        className='textHome'
                    >
                        {t('home.section_1_description')}
                    </Typography> 
                </Container>
                {/* End hero unit */}
                <Container className='paddingBottomShowcase' maxWidth="md" component="main" sx={{pb: '60px'}}>
                <div className='showcaseImageWrapper'>
{/*                 <Card sx={{ display: 'flex', alignItems: 'center', height: '300px', width: '300px', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);'}}> */}
                    <Fade {...fadeProperties}>
{/*                     <Card sx={{ display: 'flex', alignItems: 'center', height: '300px', width: '300px', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);'}}>
                    <img className='showcaseImage' src={require(fadeImagesBefore[0])} alt="Slider" />
                    </Card> */}

                    {fadeImagesBefore.map((fadeImage, index) => (
                    <div className="each-fade" key={index}>
                        <div className="image-container-showcase" >
                        <img className='showcaseImage' src={fadeImage.url} />
                        </div>
                    </div>
                    ))}
                    
                    </Fade>

                   {/*  </Card> */}
                    <ArrowForward sx={{fontSize: '64px', opacity: '0.4'}}/>
                    <div>
                    <Fade {...fadeProperties}>
{/*                     <Card sx={{ display: 'flex', alignItems: 'center', height: '300px', width: '300px', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);'}}>
                    <img className='showcaseImage' src={require(fadeImagesBefore[0])} alt="Slider" />
                    </Card> */}

                    {fadeImagesAfter.map((fadeImage, index) => (
                    <div className="each-fade" key={index}>
                        <div className="image-container-showcase">
                        <img className='showcaseImage' src={fadeImage.url} style={{imageRendering: 'auto'}} />
                        {/* <div style={{width: '100%', display: 'flex', justifyContent: 'center', top: '300px'}}>
                        
                    </div> */}
                        </div>
                        {/* <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Card className='showcaseStats' sx={{position: 'absolute', mt: '324px', padding:'14px', height: '80px', width: '200px', borderRadius: '12px', boxShadow: '0 4px 14px rgba(0,0,0,0.15);'}}>
                    <Typography variant="h7" align="left" color="text.secondary" component="p" sx={{display: 'flex', alignItems: 'center', mb: '4px'}}>
                      <PaletteRounded sx={{mr: '6px'}}/>{t('home.section_1_info_1') + fadeImage.caption}
                        </Typography>
                        <Typography variant="h7" align="left" color="text.secondary" component="p" sx={{display: 'flex', alignItems: 'center'}}>
                       <Grid4x4Rounded sx={{mr: '6px'}}/> {t('home.section_1_info_2') + fadeImage.caption2}
                        </Typography>
                    </Card>
                    </div> */}
                    </div>
                    ))}
                    
                    </Fade>
{/*                     <Card sx={{ display: 'flex', alignItems: 'center', height: '300px', width: '300px', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);'}}>
                    <img className='showcaseImage' src={require('./images/picAfter2.png')} alt="Slider" />
                    </Card> */}
                    
                    </div>
                    </div> 
                </Container>
                {/* Footer */}
                </Card>
            {/* End footer */}
        </React.Fragment>
    );
}

export default function Showcase() {
    return <ShowcaseContent />;
}