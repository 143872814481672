import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Paper, Toolbar, Typography } from '@mui/material';
import AccountMenu from './AccountMenu';
import { useUserAuth } from './UserAuthContext';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import EnhancedTablePatterns from './savedPatternsTable';
import { Close, CopyrightRounded, CropRounded, EditRounded, HelpRounded, SaveRounded } from '@mui/icons-material';
import { getDocs, collection, query, where, orderBy, deleteDoc, doc, updateDoc, getDoc } from "@firebase/firestore";
import { db, storage as storageRef } from './firebase';
import { ref, getDownloadURL, deleteObject } from "@firebase/storage";
export default function TopBar(props) {

  const {t, i18n} = useTranslation('translation')
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openSavedPatterns, setOpenSavedPatterns] = useState(false);
  const [openDeletePatterns, setOpenDeletePatterns] = useState(false);
  const [numberOfPatternsToDelete, setNumberOfPatternToDelete] = useState(1);
  const [openGuide, setOpenGuide] = useState(false);
/*   const [fullscreenLoading, setFullScreenLoading] = useState(false); */
  const [editModeSavedPatterns, setEditModeSavedPatterns] = useState(false);
useEffect(() =>{
  if(user)
  {
    setIsLoggedIn(true);
  } else {
      //props.handleSetHomeMode();
    setIsLoggedIn(false);
  }
},[user]);



 const handleModeChangeChild = () => {
  props.handleModeChange();
 }

 const handleChangeCappedPatternSettingChild = () => {
  props.handleChangeCappedPatternSetting();
 }


 const handleDeletePatterns = async (patternsToDelete) => {

  try {
    setOpenDeletePatterns(true);
    setNumberOfPatternToDelete(patternsToDelete.length);
    let arr = [];
    for(let i=0; i<patternsToDelete.length; i++) {
      const q = query(collection(db, "users",user.uid+'/saved_patterns'),where("storageName", "==", patternsToDelete[i].storageName));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let mockObject = {id: doc.id, storage: doc.data().storageName, shared: doc.data().shared, shareId: doc.data().shareId};
        arr.push(mockObject);
      });
    }
    if(arr.length>0) {
      for(let i=0; i<arr.length; i++) {
        //console.log(arr[i]);
        // Delete doc
        const docRef = doc(db, "users",user.uid+'/saved_patterns/'+arr[i].id);
        await deleteDoc(docRef);
        
        const cloudStorageRef = ref(storageRef, arr[i].storage);
        await deleteObject(cloudStorageRef).then(() => {
          /* console.log("Successfully deleted image file!"); */
        }).catch((error) => {
          console.log(error);
        });
        if(arr[i].shared===true && arr[i].shareId!==undefined) {
        const dRef = doc(db, 'shared_patterns',arr[i].shareId);
        const docSnapper = await getDoc(dRef);
        if(docSnapper.exists()) {
          let docArr = [];
          docArr.push({
              activeUsers: docSnapper.data().activeUsers===undefined ? JSON.stringify([]) : docSnapper.data().activeUsers
            });
            var activeUsersVar = JSON.parse(docArr[0].activeUsers);
            if(activeUsersVar!==undefined)
              {
                if(activeUsersVar.length>0)
                {
                  activeUsersVar.forEach((el)=>{
                    if(el===user.uid) {
                      const index = activeUsersVar.indexOf(el);
                      activeUsersVar.splice(index,1);
                    }
                  })

                  await updateDoc(dRef, { 
                    activeUsers:  JSON.stringify(activeUsersVar)
                  });
                  
                }
              }
            } 
          }
        //You should not be able to delete the storage file if: 1. you dont own the pattern 2. you own the pattern but has shared it
        
        //Check if it is a pattern you do not own but have in your collection
/*         if(arr[i].shared !== undefined){
          if(arr[i].shared === false) {
          // You own the pattern so now check if you have shared it before deleting
          const docRef = doc(db, "shared_patterns", arr[i].storage);
          const docSnap = await getDoc(docRef);
          if(!docSnap.exists()) {
              // Delete the file from storage
              console.log("Deleting image!");
              const cloudStorageRef = ref(storageRef, arr[i].storage);
              await deleteObject(cloudStorageRef).then(() => {
              }).catch((error) => {
                console.log(error);
              });
            }
          } else { */
            //You do not own the pattern so remove you from the list of active users having the pattern

/*             const dRef = doc(db, 'shared_patterns',arr[i].storage);
            const docSnapper = await getDoc(dRef);
            if(docSnapper.exists()) {
              let docArr = [];
              docArr.push({
                  activeUsers: docSnapper.data().activeUsers===undefined ? JSON.stringify([]) : docSnapper.data().activeUsers
                });
                var activeUsersVar = JSON.parse(docArr[0].activeUsers);
                console.log(activeUsersVar);
                if(activeUsersVar!==undefined)
                  {
                    if(activeUsersVar.length>0)
                    {
                      activeUsersVar.forEach((el)=>{
                        if(el===user.uid) {
                          const index = activeUsersVar.indexOf(el);
                          activeUsersVar.splice(index,1);
                        }
                      })

                      await updateDoc(dRef, { 
                        activeUsers:  JSON.stringify(activeUsersVar)
                      });
                      
                    }
                  }



              } */
/* 
          }
        } else { */
          // You own the pattern so now check if you have shared it before deleting
/*           const docRef = doc(db, "shared_patterns", arr[i].storage);
          const docSnap = await getDoc(docRef);
          if(!docSnap.exists()) {
              // Delete the file from storage
              console.log("Deleting image!");
              const cloudStorageRef = ref(storageRef, arr[i].storage);
              await deleteObject(cloudStorageRef).then(() => {
              }).catch((error) => {
                console.log(error);
              });
            } */


/*         } */

        //You should however be able to delete it if no one else has the image but you and you press delete


      }
    }
/*      props.handleResetAllPatterns(); */
    await props.handleFetchAllPatterns(user);
    await props.handleFetchLatestPatterns(user);
    if(window.location.pathname.includes('catalog')) {
      await props.handleFetchCatalogArray(user);
    }
/*     setFullScreenLoading(false); */
    setOpenDeletePatterns(false);
    setEditModeSavedPatterns(false);
  }
  catch(err) {
/*     setFullScreenLoading(false); */
    setOpenDeletePatterns(false);
    console.log(err.message);
  }
}


const handleOpenSavedPatterns = async (e) => {
  if (e) e.preventDefault()
  if(props.allSavedPatterns.length===0) {
    await props.handleFetchAllPatterns(user);
  }
  setOpenSavedPatterns(true);
/*   setFullScreenLoading(false); */
}

const handleOpenSavedPatternPreview = (pattern) => {
  let patternToGet = null;
    for(let i = 0; i<props.allSavedPatterns.length; i++) {
      if(pattern[0]===props.allSavedPatterns[i].milis) {
        patternToGet = props.allSavedPatterns[i];
      }
    }
    if(patternToGet!==null) {
      navigate('/create?open='+patternToGet.storageName);
      setOpenSavedPatterns(false);
      if(location.pathname.includes("/create")) {
        props.handleSetNavigateProp(true);
      }
    }
  
}

const handleCloseSavedPatterns = async (e) => {
  if (e) e.preventDefault()
  setOpenSavedPatterns(false);
    await new Promise(r => setTimeout(r, 100));
    setEditModeSavedPatterns(false);
}

const handleSetEditModeTrueSavedPatterns = (e) => {
  if (e) e.preventDefault()
  setEditModeSavedPatterns(true);
}

const handleSetEditModeFalseSavedPatterns = (e) => {
  if (e) e.preventDefault()
  setEditModeSavedPatterns(false);
}

const handleOpenGuide = (e) => {
  if (e) e.preventDefault()
  setOpenGuide(true);
}

const handleCloseGuide = (e) => {
  if (e) e.preventDefault()
  setOpenGuide(false);
}
/* const handleFetchAllPatterns = async (user) => {
  try {
    if(openDeletePatterns!== true) {
      setFullScreenLoading(true);
    }
    const q = query(collection(db, "users",user.uid+'/saved_patterns'),orderBy("created", "desc"));
    const querySnapshot = await getDocs(q);
    let arr = [];
    if(querySnapshot.size !== 0) {
      
      querySnapshot.forEach((doc) => {
          arr.push({
            a4Pages: doc.data().a4Pages,
            cmHeight: doc.data().cmHeight,
            cmWidth: doc.data().cmWidth,
            colorCount: doc.data().colorCount,
            created: doc.data().created,
            fabric: doc.data().fabric,
            name: doc.data().name,
            stitchHeight: doc.data().stitchHeight,
            stitchWidth: doc.data().stitchWidth,
            storageName: doc.data().storageName,
            photoUrl: '',
            totalStitches: doc.data().totalStitches,
            milis: doc.data().milis,
            dmcArray: doc.data().dmcArray,
            shared: doc.data().shared
          });
      });
    
    for(let i = 0; i<arr.length; i++) {
      const imageRef = ref(storageRef, arr[i].storageName);
      await getDownloadURL(imageRef)
        .then((url) => {
            arr[i].photoUrl = url;
                });
    }
  }
  setAllSavedPatterns(arr);
  return arr;
  }
  catch(err) {
    console.log(err.message);
  }
} */

if(isLoggedIn===false && (location.pathname==="/home" || location.pathname==="/" || location.pathname==="/about"
|| location.pathname==="/help" || location.pathname==="/privacypolicy" || location.pathname==="/cookiepolicy"
|| location.pathname==="/termsandconditions" || location.pathname==="/login" || location.pathname==="/forgotpassword"
|| location.pathname==="/signup" || location.pathname==="/signup/email")) {

  return (<></>);
} else {


    return (
        <React.Fragment>
          { 
          <Box className='no-print' sx={{ flexGrow: 1 }}>
          <Paper position="static" sx={{ boxShadow: '0 4px 12px rgba(0,0,0,0.2);', borderRadius: '0px' }}>
            <Toolbar sx={{ height: '80px', justifyContent: 'space-between', zIndex: '1' }}>
              <div className="left">
             
              <h1 className="korsstingOverskift" style={{cursor: "pointer"}} onClick={()=>{navigate('/home'); props.handleSetCatalogArray([]); document.body.classList = '';}} >EMBRODIA</h1>
                {/* <Typography className='fabrikkenTekst' gutterBottom variant="h2" component="h1" sx={{ mb: 0, ml: '4px', fontSize: '25px' }}>fabrikken</Typography> */}
              </div>

              <div className="right">
                { isLoggedIn &&
                <AccountMenu createMode={false} handleResetAllPatterns={props.handleResetAllPatterns}   theme={props.theme} settings={props.settings} handleModeChange={handleModeChangeChild} handleChangeCappedPatternSetting={handleChangeCappedPatternSettingChild}  handleOpenSnackbarTheSnackBar={props.handleOpenSnackbar} handleOpenSavedPatterns={handleOpenSavedPatterns} handleOpenGuide={handleOpenGuide}  />
    } {location.pathname==="/createfree" && <div> 
      <h1 className="korsstingOverskift">{t('common.free_version')}</h1>
      </div>}
                
              </div>
              {/*            
            <Button size='medium' variant="contained" sx={{float: 'right',transition: 'all 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));", boxShadow: 'rgb(76 175 80 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(76 175 80 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(76 175 80 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(76 175 80 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(76 175 80 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(76 175 80 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>Sign Up</Button>  */}
            </Toolbar>
          </Paper>
        </Box>  }
        <Dialog
      disableScrollLock={true}
                open={openSavedPatterns}
                keepMounted
                aria-describedby="alert-dialog-slide-description"

              >
                <DialogTitle sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '3', boxShadow: '0px 2px 12px rgb(0 0 0 / 10%)', borderBottom: '1px solid rgba(0 0 0 / 10%)'}}><div style={{display: 'flex', alignItems:'center'}}><SaveRounded sx={{mr: '10px'}}/>{t('saved_patterns_menu.title')}</div><Button startIcon={<Close/>} onClick={handleCloseSavedPatterns}><span style={{marginTop: '2px'}}>{t('common.close')}</span></Button></DialogTitle>
                <DialogContent  dividers={false}  sx={{padding: '0px', border: 'none!important'}}>
                <EnhancedTablePatterns isSubscribed={props.isSubscribed} openSavedPatterns={openSavedPatterns} handleSetEditModeFalseSavedPatterns={handleSetEditModeFalseSavedPatterns} handleSetEditModeTrueSavedPatterns={handleSetEditModeTrueSavedPatterns} editMode={editModeSavedPatterns} handleDeletePatterns={handleDeletePatterns} handleOpenSavedPatternPreview={handleOpenSavedPatternPreview} data={props.allSavedPatterns} theme={props.theme}/>
                </DialogContent>
              </Dialog>
              <Dialog
                      disableScrollLock={true}
                      className='no-print'
                      open={openDeletePatterns}
                      keepMounted
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <Typography gutterBottom variant="h5" component="h1" align='center' sx={{ m: 0 }}>{ numberOfPatternsToDelete>1 ? t('saved_patterns_menu.deleting')+t('saved_patterns_menu.pattern_plural') : t('saved_patterns_menu.deleting')+t('saved_patterns_menu.pattern_singular')}</Typography>
                        <div className="progressDiv">
                          <CircularProgress disableShrink width={80} height={80} sx={{ height: '80px!important', width: '80px!important' }} />

                        </div>
                        <Typography variant="h7" component="div" align='center'>
                        {t('saved_patterns_menu.deleting') +numberOfPatternsToDelete + (numberOfPatternsToDelete>1 ? ' '+t('saved_patterns_menu.pattern_plural') : ' '+t('saved_patterns_menu.pattern_singular'))}<br></br>{t('saved_patterns_menu.please_wait')}
                        </Typography>
                      </DialogContent>
                    </Dialog>
                    <Dialog
      disableScrollLock={true}
                open={openGuide}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                
                PaperProps={{
                  sx:{height: 'calc(100% - 200px)', minHeight: '300px'}
                }}
              >
                <DialogTitle sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '3', borderBottom: '1px solid rgba(0 0 0 / 10%)'}}><div style={{display: 'flex', alignItems:'center'}}><HelpRounded sx={{mr: '10px'}}/>{t('worth_knowing.title')}</div><Button startIcon={<Close/>} onClick={handleCloseGuide}><span style={{marginTop: '2px'}}>{t('common.close')}</span></Button></DialogTitle>
                <DialogContent className='manualWrapper'  dividers={false}  sx={{padding: '20px', paddingTop: '20px!important', border: 'none!important'}}>
                <Typography align='left' component="div" variant="h6">
                {t('worth_knowing.title_1')}
                </Typography>
                <Typography sx={{mt: '5px', mb: '14px' }} align='left' color="text.secondary" component="div" variant="h7">
                {t('worth_knowing.text_1_1')}<br></br>{t('worth_knowing.text_1_2')}<br></br>
                {t('worth_knowing.text_1_3')}
            </Typography>
                <Typography align='left' component="div" variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
                {t('worth_knowing.title_2')}   <CropRounded sx={{ml:'10px'}}/>
                </Typography>
                <Typography sx={{mt: '5px', mb: '14px' }} align='left' color="text.secondary" component="div" variant="h7">
                {t('worth_knowing.text_2_1')}<br></br>
                {t('worth_knowing.text_2_2')}<br></br>
                {t('worth_knowing.text_2_3')}<br></br>
                {t('worth_knowing.text_2_4')}<br></br>
                {t('worth_knowing.text_2_5')}
            </Typography>
            <Typography align='left' component="div" variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
            {t('worth_knowing.title_3')} <EditRounded sx={{ml:'10px'}}/>
                </Typography>
                <Typography sx={{mt: '5px', mb: '14px' }} align='left' color="text.secondary" component="div" variant="h7">
                {t('worth_knowing.text_3_1')}<br></br>
              <strong>{t('worth_knowing.text_3_2')}</strong><br></br>
              {t('worth_knowing.text_3_3')}
              {t('worth_knowing.text_3_4')}
              {t('worth_knowing.text_3_5')}<br></br>
              <strong>{t('worth_knowing.text_3_6')}</strong><br></br>
              {t('worth_knowing.text_3_7')}<br></br>{t('worth_knowing.text_3_8')}<br></br>
              {t('worth_knowing.text_3_9')}<br></br>
              {t('worth_knowing.text_3_10')}
            </Typography>
            <Typography align='left' component="div" variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
            {t('worth_knowing.title_4')} <CopyrightRounded sx={{ml:'10px'}}/>
                </Typography>
                <Typography sx={{mt: '5px', mb: '14px' }} align='left' color="text.secondary" component="div" variant="h7">
                {t('worth_knowing.text_4_1')}<br></br>
                {t('worth_knowing.text_4_2')}
            </Typography>
                </DialogContent>
              </Dialog>
{/*                     <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={fullscreenLoading}
        >
          <CircularProgress disableShrink={true} color="inherit" />
        </Backdrop> */}
          </React.Fragment>
          
    );}
  }