import * as React from 'react';
import Card from '@mui/material/Card';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

function BroderistContent() {
    const {t, i18n} = useTranslation('translation');

    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Card sx={{borderRadius: '0px', boxShadow: '0 0px 18px rgb(0 0 0 / 30%);'}}>
                <div className='broderistWrapper'>
                <div className='broderistWrapperLeft'>

                <img style={{width:'80px'}} src={require('./images/broderist.png')}/>
                    <Typography
                    className='broderistTextHead'
                        component="h1"
                        variant="h2"
                        
                        color="text.primary"
                        gutterBottom
                        sx={{mr: '12px'}}
                    >
                        {t("trine.title")}
                    </Typography>
                    <Typography variant="h7"  color="text.secondary" component="p" className='broderistText'
                        sx={{mr: '12px'}}
                    >
                        {t("trine.text_1")}<br></br><br></br>{t("trine.text_2")}<br></br>{t("trine.text_3")}<br></br><br></br>{t("trine.text_4")}
                    </Typography> 
                    <Button
            size='large'
            variant="contained"
            onClick={()=>{window.open('https://broderist.com', '_blank')}}
            sx={{ marginTop: '32px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;'}  }}
          >
            {t("trine.button")}
          </Button>
                </div>
                {/* End hero unit */}
                <div className='broderistWrapperRight'>
                    <img className='trine' src={require('./images/TrineBroderistCropped.png')} alt=''/>
                    <p className='trineCopyright'>@2022 Broderist</p>
                </div>
                </div>
                {/* Footer */}
                </Card>
            {/* End footer */}
        </React.Fragment>
    );
}

export default function Broderist() {
    return <BroderistContent />;
}