import React, { Component } from 'react'
import RgbQuant from 'rgbquant'
import Dropzone from 'react-dropzone'
import './custom-image-crop.css';
import { ImagePixelated } from "react-pixelate"
import Cropper from "react-cropper";
import "./cropper.css";
import Button from '@mui/material/Button';
import { Chip, CircularProgress, IconButton, InputLabel, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle, Drawer, Skeleton, Container, Link, Backdrop, CardHeader, Slider, Switch, Grid, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { AddRounded, ArrowBack, ArrowDownwardRounded, BrushRounded, CallToActionRounded, Cancel, CancelRounded, ChangeCircleRounded, Check, CheckCircleRounded, CircleRounded, ClearRounded, Close, ColorizeRounded, CopyrightRounded, CropPortraitRounded, CropRounded, EditRounded, ExitToApp, FiberManualRecordRounded, FormatColorFillRounded, Grid4x4Rounded, HelpRounded, HomeRounded, StarRounded, PaletteRounded, PrintRounded, RemoveRounded, SaveRounded, SwapHorizontalCircleRounded, ThirtyFpsSelect, UndoRounded, VisibilityRounded, UploadRounded, PeopleAltRounded, DownloadRounded, RedoRounded, HorizontalRuleRounded, HighlightAltRounded, CropSquareRounded, ZoomInRounded, ZoomOutRounded, CreateRounded, CheckRounded, CheckBox, CheckBoxOutlineBlankRounded, SquareRounded, PhotoSizeSelectLargeRounded, ImportContactsRounded, RadioButtonUncheckedRounded, RotateLeftRounded, RotateRightRounded, FlipRounded, ContentCopyRounded, ContentPasteRounded, DeleteRounded, TextFieldsRounded } from '@mui/icons-material';
import { TextField } from '@mui/material';
import Card from '@mui/material/Card';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AccountMenu from '../AccountMenu'
import { Flip } from '@mui/icons-material';
import RotateSlider from './RotateSlider';
import { Done } from '@mui/icons-material';
import EnhancedTable from '../dmcTable';
import EnhancedTableDrawColor from '../colorPickerTable';
import EnhancedTableColorReplacement from '../colorReplacementTable';
import EnhancedTablePatterns from '../savedPatternsTable';
import { DMCJSONColors } from './DMCColors';
import { DMCJSONColorsHex } from './DMCColorsHex';
import rgbHex from 'rgb-hex';
import { ErrorRounded } from '@mui/icons-material';
import imageCompression from 'browser-image-compression';
import { getDocs, collection, query, where, addDoc, orderBy, deleteDoc, doc, updateDoc, setDoc, getDoc } from "@firebase/firestore";
import { db, storage as storageRef } from '../firebase';
import { ref, uploadBytes, getDownloadURL, getMetadata, deleteObject, getBlob } from "@firebase/storage";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {
  base64StringtoFile,
  downloadBase64File,
  extractImageFileExtensionFromBase64,
  image64toCanvasRef
} from './ResuableUtils'
import Welcome from '../Welcome';
import { userAuthContext } from '../UserAuthContext';
import SavedPatternDialog from '../SavedPatternDialog';
import { withTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { t } from 'i18next';
import UploadShareImage from '../UploadShareImage';
import { pink, red } from '@mui/material/colors';
import TextSizeSlider from './TextSizeSlider';

const imageMaxSize = 100000000 // bytes
const stitchDarkAmount = -25;
const stitchPercentBright = 10;
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const dmcSymbols = ["adjust", "brightness_1", "change_history", "favorite", "filter_vintage", "flag", "flash_on", "flight",
  "forward", "free_breakfast", "grade", "headset", "home", "mail", "music_note", "attach_money", "remove_red_eye",
  "security", "thumb_up", "vpn_key", "build", "pets", "whatshot", "watch", "undo", "traffic", "stop", "straighten", "style", "all_inclusive", "attachment",
  "beach_access", "bluetooth", "cake", "confirmation_number", "content_cut", "extension", "fitness_center", "format_paint",
  "hourglass_full", "layers", "local_bar", "local_drink", "local_pizza", "looks", "memory", "nature", "pages", "pan_tool",
  "polymer", "restaurant", "palette", "settings_input_antenna", "settings_input_svideo", "settings_input_component",
  "swap_calls", "toll", "videogame_asset", "voicemail", "cloud", "ac_unit", "account_balance", "account_balance_wallet",
  "adb", "border_all", "broken_image", "business", "camera", "	card_giftcard", "desktop_mac", "directions_boat", "directions_bike",
  "dns", "dock", "ev_station", "euro_symbol", "event_seat", "filter_b_and_w", "filter_hdr", "accessible", "airplay", "android", "battery_full",
  "clear_all", "code", "credit_card", "developer_mode", "eject", "find_in_page", "flare", "format_bold", "format_quote", "functions", "gamepad",
  "gesture", "g_translate", "healing", "hdr_strong", "keyboard", "kitchen", "leak_add", "local_library", "send", "screen_rotation", "settings_brightness",
  "low_priority", "map", "loyalty", "loupe", "location_city", "motorcycle", "multiline_chart", "school", "rv_hookup", "smoking_rooms", "snooze", "speaker",
  "space_bar", "stars", "strikethrough_s", "subway", "thumb_down", "timer_10", "usb", "videocam_off", "view_stream", "voice_chat", "wb_auto", "wb_iridescent",
  "widgets", "wallpaper", "vpn_lock", "explicit", "donut_small", "3d_rotation", "border_color", "child_care", "cloud_circle", "compare_arrows", "copyright", "favorite_border", "insert_emoticon",
  "alarm_add", "backspace", "assignment_ind", "art_track", "blur_off", "border_outer", "border_vertical", "brightness_7", "call_merge", "call_split", "call", "call_end", "contact_phone", "speaker_phone",
  "call_to_action", "phonelink_erase", "drafts", "explore", "face", "find_replace", "flight_land", "flip_to_back", "flip_to_front", "format_clear", "format_italic", "format_line_spacing",
  "format_color_reset", "format_color_fill", "format_shapes", "format_textdirection_l_to_r", "format_strikethrough", "format_underlined", "text_format", "format_size", "dialpad", "directions",
  "gradient", "grain", "grid_off", "hearing", "hot_tub", "indeterminate_check_box", "leak_remove", "linear_scale", "local_gas_station", "local_laundry_service", "local_pharmacy", "local_movies", "local_shipping",
  "more_horiz", "more_vert", "open_in_browser", "person_pin_circle", "person", "perm_identity", "person_add", "pin_drop", "plus_one", "repeat", "reply", "room_service", "streetview", "settings_applications",
  "settings_system_daydream", "settings_ethernet", "settings_remote", "power_settings_new", "thumbs_up_down", "timer_3", "timelapse", "timer_off", "vertical_align_bottom", "vertical_align_center", "vertical_align_top",
  "wc", "av_timer", "timer", "alarm_off", "delete_sweep", "delete_forever", "accessibility", "account_circle", "account_box", "airline_seat_legroom_extra", "airline_seat_recline_normal", "assistant",
  "assignment_turned_in", "bluetooth_audio", "bluetooth_connected", "bookmark_border", "beenhere", "brightness_3", "burst_mode", "call_missed_outgoing", "call_missed", "camera_enhance",
  "camera_front", "camera_rear", "camera_roll", "filter_1", "filter_2", "filter_3", "filter_4", "filter_5", "filter_6", "filter_7", "filter_8", "filter_9","looks_one", "looks_two", "looks_3", "looks_4", "looks_5", "looks_6"];
  const dmcrgb = [
  [255, 226, 226],
  [255, 201, 201],
  [245, 173, 173],
  [241, 135, 135],
  [227, 109, 109],
  [191, 45, 45],
  [254, 215, 204],
  [253, 156, 151],
  [233, 106, 103],
  [224, 72, 72],
  [210, 16, 53],
  [187, 5, 31],
  [255, 203, 213],
  [255, 173, 188],
  [255, 121, 146],
  [231, 73, 103],
  [227, 29, 66],
  [199, 43, 59],
  [183, 31, 51],
  [167, 19, 43],
  [151, 11, 35],
  [135, 7, 31],
  [123, 0, 27],
  [255, 178, 187],
  [252, 144, 162],
  [255, 121, 140],
  [255, 87, 115],
  [255, 223, 217],
  [253, 181, 181],
  [255, 145, 145],
  [86, 74, 74],
  [255, 215, 215],
  [255, 189, 189],
  [230, 138, 138],
  [207, 115, 115],
  [234, 134, 153],
  [219, 85, 110],
  [179, 47, 72],
  [145, 53, 70],
  [255, 238, 235],
  [251, 173, 180],
  [252, 176, 185],
  [242, 118, 136],
  [238, 84, 110],
  [179, 59, 75],
  [240, 206, 212],
  [228, 166, 172],
  [232, 135, 155],
  [218, 103, 131],
  [188, 67, 101],
  [171, 2, 73],
  [251, 191, 194],
  [231, 169, 172],
  [201, 107, 112],
  [171, 51, 87],
  [136, 21, 49],
  [255, 192, 205],
  [255, 176, 190],
  [255, 164, 190],
  [226, 72, 116],
  [209, 40, 106],
  [205, 47, 99],
  [255, 140, 174],
  [243, 71, 139],
  [224, 40, 118],
  [244, 174, 213],
  [234, 156, 196],
  [197, 73, 137],
  [156, 36, 98],
  [155, 19, 89],
  [130, 0, 67],
  [255, 223, 213],
  [235, 183, 175],
  [226, 160, 153],
  [204, 132, 124],
  [188, 108, 100],
  [161, 75, 81],
  [136, 62, 67],
  [223, 179, 187],
  [219, 169, 178],
  [183, 115, 127],
  [155, 91, 102],
  [129, 73, 82],
  [113, 65, 73],
  [130, 38, 55],
  [215, 203, 211],
  [183, 157, 167],
  [149, 111, 124],
  [120, 87, 98],
  [186, 145, 170],
  [148, 96, 131],
  [114, 55, 93],
  [87, 36, 51],
  [227, 203, 227],
  [195, 159, 195],
  [163, 123, 167],
  [131, 91, 139],
  [108, 58, 110],
  [99, 54, 102],
  [230, 204, 217],
  [219, 179, 203],
  [163, 99, 139],
  [128, 58, 107],
  [92, 24, 78],
  [211, 215, 237],
  [183, 191, 221],
  [163, 174, 209],
  [173, 167, 199],
  [152, 145, 182],
  [119, 107, 152],
  [92, 84, 120],
  [187, 195, 217],
  [143, 156, 193],
  [112, 125, 162],
  [96, 103, 140],
  [85, 91, 123],
  [76, 82, 110],
  [70, 69, 99],
  [176, 192, 218],
  [123, 142, 171],
  [92, 114, 148],
  [192, 204, 222],
  [148, 168, 198],
  [116, 142, 182],
  [70, 106, 142],
  [19, 71, 125],
  [17, 65, 109],
  [14, 54, 92],
  [219, 236, 245],
  [189, 221, 237],
  [161, 194, 215],
  [107, 158, 191],
  [71, 129, 165],
  [57, 105, 135],
  [48, 194, 236],
  [20, 170, 208],
  [38, 150, 182],
  [6, 227, 230],
  [4, 196, 202],
  [18, 174, 186],
  [199, 202, 215],
  [153, 159, 183],
  [120, 128, 164],
  [238, 252, 252],
  [217, 235, 241],
  [205, 223, 237],
  [184, 210, 230],
  [147, 180, 206],
  [115, 159, 193],
  [90, 143, 184],
  [53, 102, 139],
  [44, 89, 124],
  [37, 59, 115],
  [33, 48, 99],
  [199, 209, 219],
  [162, 181, 198],
  [106, 133, 158],
  [69, 92, 113],
  [56, 76, 94],
  [197, 232, 237],
  [172, 216, 226],
  [126, 177, 200],
  [79, 147, 167],
  [62, 133, 162],
  [59, 118, 143],
  [50, 102, 124],
  [28, 80, 102],
  [229, 252, 253],
  [153, 207, 217],
  [100, 171, 186],
  [61, 149, 165],
  [52, 127, 140],
  [188, 227, 230],
  [144, 195, 204],
  [91, 163, 179],
  [72, 142, 154],
  [63, 124, 133],
  [54, 105, 112],
  [221, 227, 227],
  [189, 203, 203],
  [152, 174, 174],
  [101, 127, 127],
  [86, 106, 106],
  [82, 179, 164],
  [85, 147, 146],
  [52, 125, 117],
  [169, 226, 216],
  [89, 199, 180],
  [62, 182, 161],
  [47, 140, 132],
  [73, 179, 161],
  [61, 147, 132],
  [55, 132, 119],
  [144, 192, 180],
  [111, 174, 159],
  [80, 139, 125],
  [71, 123, 110],
  [185, 215, 192],
  [167, 205, 175],
  [143, 192, 152],
  [83, 151, 106],
  [51, 131, 98],
  [153, 195, 170],
  [101, 165, 125],
  [77, 131, 97],
  [71, 119, 89],
  [44, 106, 69],
  [196, 222, 204],
  [178, 212, 189],
  [123, 172, 148],
  [91, 144, 113],
  [57, 111, 82],
  [4, 77, 51],
  [162, 214, 173],
  [136, 186, 145],
  [109, 171, 119],
  [27, 157, 107],
  [24, 144, 101],
  [24, 126, 86],
  [21, 111, 73],
  [17, 90, 59],
  [215, 237, 204],
  [166, 194, 152],
  [105, 136, 90],
  [97, 122, 82],
  [32, 95, 46],
  [23, 73, 35],
  [200, 216, 184],
  [141, 166, 117],
  [115, 139, 91],
  [88, 113, 65],
  [64, 82, 48],
  [228, 236, 212],
  [204, 217, 177],
  [113, 147, 92],
  [64, 106, 58],
  [27, 89, 21],
  [27, 83, 0],
  [158, 207, 52],
  [123, 181, 71],
  [71, 167, 47],
  [63, 143, 41],
  [7, 115, 27],
  [5, 101, 23],
  [199, 230, 102],
  [127, 179, 53],
  [98, 138, 40],
  [85, 120, 34],
  [216, 228, 152],
  [174, 191, 121],
  [148, 171, 79],
  [114, 132, 60],
  [98, 113, 51],
  [76, 88, 38],
  [66, 77, 33],
  [49, 57, 25],
  [171, 177, 151],
  [156, 164, 130],
  [136, 146, 104],
  [95, 102, 72],
  [196, 205, 172],
  [150, 158, 126],
  [102, 109, 79],
  [131, 151, 95],
  [114, 130, 86],
  [94, 107, 71],
  [239, 244, 164],
  [224, 232, 104],
  [192, 200, 64],
  [167, 174, 56],
  [136, 141, 51],
  [199, 192, 119],
  [188, 179, 76],
  [148, 140, 54],
  [147, 139, 55],
  [130, 123, 48],
  [185, 185, 130],
  [166, 167, 93],
  [137, 138, 88],
  [204, 183, 132],
  [191, 166, 113],
  [184, 157, 100],
  [219, 190, 127],
  [200, 171, 108],
  [189, 155, 81],
  [170, 143, 86],
  [141, 120, 75],
  [126, 107, 66],
  [220, 196, 170],
  [188, 154, 120],
  [150, 118, 86],
  [121, 96, 71],
  [231, 214, 193],
  [216, 188, 154],
  [188, 150, 106],
  [167, 124, 73],
  [252, 252, 238],
  [245, 236, 203],
  [198, 159, 123],
  [183, 139, 97],
  [160, 112, 66],
  [131, 94, 57],
  [228, 180, 104],
  [206, 145, 36],
  [174, 119, 32],
  [162, 109, 32],
  [148, 99, 26],
  [229, 206, 151],
  [208, 165, 62],
  [188, 141, 14],
  [169, 130, 4],
  [246, 220, 152],
  [243, 206, 117],
  [223, 182, 95],
  [205, 157, 55],
  [255, 251, 139],
  [253, 237, 84],
  [255, 227, 0],
  [255, 214, 0],
  [253, 249, 205],
  [255, 241, 175],
  [253, 215, 85],
  [255, 200, 64],
  [255, 181, 21],
  [255, 233, 173],
  [255, 231, 147],
  [254, 211, 118],
  [255, 191, 87],
  [255, 163, 43],
  [255, 139, 0],
  [247, 139, 19],
  [246, 127, 0],
  [255, 123, 77],
  [235, 99, 7],
  [209, 88, 7],
  [255, 222, 213],
  [254, 205, 194],
  [252, 171, 152],
  [255, 131, 111],
  [253, 93, 53],
  [250, 50, 3],
  [255, 226, 207],
  [255, 211, 181],
  [247, 151, 111],
  [242, 120, 66],
  [229, 92, 31],
  [253, 189, 150],
  [226, 115, 35],
  [198, 98, 24],
  [172, 84, 20],
  [166, 69, 16],
  [130, 52, 10],
  [255, 238, 227],
  [251, 213, 187],
  [247, 167, 119],
  [207, 121, 57],
  [179, 95, 43],
  [143, 67, 15],
  [111, 47, 0],
  [255, 253, 227],
  [250, 211, 150],
  [242, 175, 104],
  [242, 151, 70],
  [247, 187, 119],
  [220, 156, 86],
  [194, 129, 66],
  [173, 114, 57],
  [145, 79, 18],
  [254, 231, 218],
  [247, 203, 191],
  [244, 187, 169],
  [238, 170, 155],
  [217, 137, 120],
  [197, 106, 91],
  [185, 85, 68],
  [152, 68, 54],
  [134, 48, 34],
  [248, 202, 200],
  [186, 139, 124],
  [150, 74, 63],
  [104, 37, 26],
  [243, 225, 215],
  [238, 211, 196],
  [196, 142, 112],
  [187, 129, 97],
  [182, 117, 82],
  [160, 108, 80],
  [135, 85, 57],
  [215, 206, 203],
  [192, 179, 174],
  [145, 123, 115],
  [166, 136, 129],
  [125, 93, 87],
  [98, 75, 69],
  [255, 251, 239],
  [248, 228, 200],
  [236, 204, 158],
  [228, 187, 142],
  [203, 144, 81],
  [184, 119, 72],
  [152, 94, 51],
  [122, 69, 31],
  [101, 57, 25],
  [73, 42, 19],
  [54, 31, 14],
  [30, 17, 8],
  [242, 227, 206],
  [203, 182, 156],
  [164, 131, 92],
  [138, 110, 78],
  [75, 60, 42],
  [255, 255, 255],
  [252, 251, 248],
  [249, 247, 241],
  [240, 234, 218],
  [231, 226, 211],
  [221, 216, 203],
  [164, 152, 120],
  [133, 123, 97],
  [98, 93, 80],
  [79, 75, 65],
  [235, 234, 231],
  [177, 170, 151],
  [142, 144, 120],
  [99, 100, 88],
  [227, 216, 204],
  [210, 188, 166],
  [179, 159, 139],
  [127, 106, 85],
  [107, 87, 67],
  [250, 246, 240],
  [209, 186, 161],
  [182, 155, 126],
  [154, 124, 92],
  [103, 85, 65],
  [89, 73, 55],
  [230, 232, 232],
  [188, 180, 172],
  [176, 166, 156],
  [135, 125, 115],
  [110, 101, 92],
  [72, 72, 72],
  [236, 236, 236],
  [211, 211, 214],
  [171, 171, 171],
  [140, 140, 140],
  [209, 209, 209],
  [132, 132, 132],
  [108, 108, 108],
  [86, 86, 86],
  [66, 66, 66],
  [0, 0, 0],
  [227, 227, 230],
  [215, 215, 216],
  [184, 184, 187],
  [174, 174, 177],
  [227, 204, 190],
  [220, 198, 184],
  [143, 123, 110],
  [106, 80, 70],
  [85, 32, 14],
  [237, 254, 217],
  [226, 237, 181],
  [205, 217, 154],
  [191, 246, 224],
  [208, 251, 178],
  [209, 237, 164],
  [201, 194, 88],
  [229, 226, 114],
  [217, 213, 109],
  [247, 201, 95],
  [247, 175, 147],
  [215, 153, 130],
  [188, 96, 78],
  [237, 226, 237],
  [224, 215, 238],
  [218, 210, 233],
  [215, 202, 230],
  [240, 238, 249],
  [144, 134, 169],
  [103, 64, 118],
  [125, 119, 165],
  [80, 81, 141],
  [77, 46, 138],
  [156, 89, 158],
  [125, 48, 100],
  [70, 5, 45],
];


class ImgDropAndCrop extends Component {

static contextType = userAuthContext;

  constructor(props) {
    super(props)
/*     this.shareNameRef = React.createRef();
    this.shareDescriptionRef = React.createRef(); */
    this.imagePreviewCanvasRef = React.createRef()
    this.fileInputRef = React.createRef()
    this.pixelRef = React.createRef()
    this.state = {
      tiers2: [
        {
          title: this.props.t('welcome.tier2_title_2'),
          price: this.props.t('welcome.tier2_price_2'),
          description: [
            this.props.t('welcome.tier2_description_2_1'),
            this.props.t('welcome.tier2_description_2_2')
          ],
          buttonVariant: 'outlined',
          plan: 'onePattern'
        }/* ,
        {
          title: this.props.t('welcome.tier2_title_3'),
          price: this.props.t('welcome.tier2_price_3'),
          description: [
            this.props.t('welcome.tier2_description_3_1'),
            this.props.t('welcome.tier2_description_3_2'),
            this.props.t('welcome.tier2_description_3_3')
          ],
          buttonVariant: 'outlined',
          plan: 'threePatterns'
        }, */
      ],
      colorClusters: [],
      categoryTranslator: {
        "Icon":   t('catalog.category_icon'),
        "Humor":  t('catalog.category_humor'),
        "Text":   t('catalog.category_text'),
        "Photo":  t('catalog.category_photo'),
        "Movie":  t('catalog.category_movie'),
        "Gaming": t('catalog.category_gaming'),
        "Music":  t('catalog.category_music'),
        "Art":    t('catalog.category_art'),
        "Square": t('catalog.category_square'),
        "Xmas":   t('catalog.category_xmas')
      },
      openAreYouSureGoingBackFromAdvancedEdit: false,
      showLoadingAdvanced: false,
      madeChangeSavedPattern: false,
      drawGrid: true,
      wasInAdvancedEdit: false,
      pixelMatrix: [],
      stitchMode: false,
      canClear: false,
      openColorPicker: false,
      noEditOpen: true,
      replaceMode: false,
      colorToReplace: "",
      selectionBoxCopyData: null,
      selectionBoxCopyMode: false,
      drawSize: 1,
      undo: 0,
      drawColorName: 'Black',
      drawColorCode: '310',
      drawZoom: 10,
      drawColor: "rgb(0,0,0)",
      drawColorDark: "rgb(0,0,0)",
      drawColorBright: "rgb(0,0,0)",
      canvasDrawHeight: 400,
      canvasDrawWidth: 400,
      canvasDrawColor: 'black',
      selectBox: false,
      selectingArea: false,
      selectionChange: false,
      selectionCanStart: true,
      quantSrc: null,
      advancedMode: 'draw',
      openGuide: false,
      openUpgrade: false,
      goHome: false,
      goToUpgrade: false,
      goToCatalog: false,
      freeModeMax: 3600,
      isFirstTime: true,
      loadingCreateCanvas: false,
      openAreYouSureUseTicket: false,
      openingOldPattern: false,
      savedPatternMode: false,
      editModeSavedPatterns: false,
      numberOfPatternsToDelete: 1,
      openDeletePatterns: false,
      savedPatternPreviewData: [],
      openSavedPattern: false,
      openSavedPatterns: false,
      drawGridSettingCenter: true,
      drawGridSettingGrid: true,
      drawGridSettingRulers: true,
      //NEW canvas draw method states
      rgbArrayCanvas: [],
      widthCanvasDraw: 0,
      heightCanvasDraw: 0,
      drawCanvasUndoStates: [],
      madeChangeDrawCanvas: false,
      totalStitches: 0,
      showWelcome: false,
      isSubscribed: false,
      isSubscribedCheck: false,
      compressionLoading: false,
      showLoading: true,
      madeChange: false,
      canCrop: true,
      openDrawerCrop: false,
      openDrawerEdit: false,
      showError: false,
      maxStitchCount: 160000,
      freeAspect: true,
      xCounter: 0,
      yCounter: 0,
      a4PagesArray: [],
      openAreYouSure: false,
      selectMeasuringUnitBool: true,
      selectMeasuringUnit: this.props.t('common.unit_1_small'),
      selectMeasuringUnitCreateCanvas: this.props.t('common.unit_1_small'),
      selectBroderistofCreateCanvas: 5.4,
      selectBroderistof: 5.4,
      selectBroderistofName: 'AIDA 5.4',
      widthInputErrorText: "Antal korssting i bredden",
      widthInputErrorTextSmall: "Indstil bredden",
      widthInputError: false,
      widthInput: 100,
      toolStartX: 0,
      toolStartY: 0,
      toolStartBoundingClientLeft: 0,
      toolStartBoundingClientTop: 0,
      cellSize: 16,
      parentDivSizeX: 60,
      parentDivSizeY: 80,
      createPDFProgress: 0,
      numberOfPages: 1,
      loadingCreatePDF: false,
      dmcSymbolTableIsMultiple: false,
      dmcSymbolTable: [],
      squareToolOutline: false,
      circleToolOutline: false,
      shiftKey: false,
      dmcArray: [],
      dmcArrayDraw: [],
      statePalette: [],
      openResizer: false,
      openTextTool: false,
      textToolFontSelected: "'Times New Roman',serif",
      textToolText: "",
      textToolSize: 32,
      textToolImageData: null,
      numberOfDMCColors: 0,
      donePage: true,
      PDFPage: true,
      displayStyleEdit: {
        visibility: 'visible',
        position: 'static'
      },
      displayStyleDonePage: {
        visibility: 'visible',
        position: 'static'
      },
      displayStylePDFPage: {
        visibility: 'hidden',
        position: 'fixed'
      },
      shareDatabaseRef: null,
      sharePreviewImg: null,
      shareName: "",
      shareDescription: "",
      shareUploadImage: [],
      shareError: null,
      loadingShare: false,
      isASharedPattern: false,
      isAlreadySharedByYou: false,
      openCreateNewBlankCanvas: false,
      widthInputCreateCanvas: 100,
      heightInputCreateCanvas: 100,
      widthInputErrorCreateCanvas: false,
      heightInputErrorCreateCanvas: false,
      createBlankCanvasMode: false,
      pickedCategories: [],
      shareCategories: [{
        name: 'Icon',
        checked: false
      },
      {
        name: 'Humor',
        checked: false
      },
      {
        name: 'Text',
        checked: false
      },
      {
        name: 'Photo',
        checked: false
      },
      {
        name: 'Movie',
        checked: false
      },
      {
        name: 'Gaming',
        checked: false
      },
      {
        name: 'Music',
        checked: false
      },
      {
        name: 'Art',
        checked: false
      },
      {
        name: 'Square',
        checked: false
      },
      {
        name: 'Xmas',
        checked: false
      },
      ],
      cropScaleX: 1,
      cropScaleY: 1,
      cropAspect: 'free',
      selectValue: 'realistisk1',
      selectColorValue: 'profil1',
      quant_dithKern: "FloydSteinberg",
      quant_dithSerp: false,
      quant_method: 1,
      quant_minHueCols: 9999,
      quant_boxSize: [32, 32],
      quant_initColors: 4096,
      quant_boxPxls: 2,
      quant_colors: 25,
      quant_palette: 0,
      showing: false,
      pixel64data: null,
      cropHeight: 100,
      cropWidth: 100,
      resizeValueTop: 0,
      resizeValueLeft: 0,
      resizeValueRight: 0,
      resizeValueBottom: 0,
      resizeValueWidth: 0,
      resizeValueHeight: 0,
      resizeInputWidthError: false,
      resizeInputHeightError: false,
      aspectRatio1: 1,
      aspectRatio2: 1,
      imgSrc: null,
      imgSrcExt: null,
      cropResult: null,
      quantImg: null,
      openShare: false,
      opts: {

        colors: 25,             // desired palette size
        method: 1,               // histogram method, 2: min-population threshold within subregions; 1: global top-population
        boxSize: [32, 32],        // subregion dims (if method = 2)
        boxPxls: 2,              // min-population threshold (if method = 2)
        initColors: 4096,        // # of top-occurring colors  to start with (if method = 1)
        minHueCols: 256,           // # of colors per hue group to evaluate regardless of counts, to retain low-count hues
        dithKern: "FloydSteinberg",          // dithering kernel name, see available kernels in docs below
        dithDelta: 0,            // dithering threshhold (0-1) e.g: 0.05 will not dither colors with <= 5% difference
        dithSerp: false,         // enable serpentine pattern dithering
        palette: 0,             // a predefined palette to start with in r,g,b tuple format: [[r,g,b],[r,g,b]...]
        reIndex: false,          // affects predefined palettes only. if true, allows compacting of sparsed palette once target palette size is reached. also enables palette sorting.
        useCache: false,          // enables caching for perf usually, but can reduce perf in some cases, like pre-def palettes
        cacheFreq: 10,           // min color occurance count needed to qualify for caching
        colorDist: "euclidean",  // method used to determine color distance, can also be "manhattan"
      },
      advancedEdit: false
    }
    this.cropImage = this.cropImage.bind(this);
    this.handleChangeOnInputFieldWidth = this.handleChangeOnInputFieldWidth.bind(this);
    this.handleChangeOnInputFieldHeight = this.handleChangeOnInputFieldHeight.bind(this);
    this.handleOpenSnackbarTheSnackBar = this.handleOpenSnackbarTheSnackBar.bind(this);
    this.handleRotateCrop = this.handleRotateCrop.bind(this);
    this.handleSetTextSize = this.handleSetTextSize.bind(this);
    this.handleModeChangeChild = this.handleModeChangeChild.bind(this);
/*     this.handleSymbolPickerModeChangeChild = this.handleSymbolPickerModeChangeChild.bind(this); */
    this.handleOpenSavedPatterns = this.handleOpenSavedPatterns.bind(this);
    this.handleCloseSavedPatternPreview = this.handleCloseSavedPatternPreview.bind(this);
    this.handleOpenSavedPatternPreview = this.handleOpenSavedPatternPreview.bind(this);
    this.handleDeletePatterns = this.handleDeletePatterns.bind(this);
    this.handleSetEditModeFalseSavedPatterns = this.handleSetEditModeFalseSavedPatterns.bind(this);
    this.handleSetEditModeTrueSavedPatterns = this.handleSetEditModeTrueSavedPatterns.bind(this);
    this.handleOpenGuide = this.handleOpenGuide.bind(this);
    this.handleChangeSymbols = this.handleChangeSymbols.bind(this);
    this.bucketTool = this.bucketTool.bind(this);
    this.handleOpenColorPickerReplaceMode = this.handleOpenColorPickerReplaceMode.bind(this);
    this.handleSetUploadShareImage = this.handleSetUploadShareImage.bind(this);
    this.handleSelectColorFromListCanvas = this.handleSelectColorFromListCanvas.bind(this);
  }

  

  clamp(n, min, max) {
    if (n > max) {
      return max;
    } else if (n < min) {
      return min
    } else {
      return n;
    }
  }

  handleOpenSnackbarTheSnackBar = (error, message) => {
    window.removeEventListener('beforeunload', this.handleDontCloseWindow);
    this.props.handleOpenSnackbar(error, message);
  }

  handleModeChangeChild = () => {
    this.props.handleModeChange();
  }

  handleSetUploadShareImage = (img) => {
    this.setState({
      shareUploadImage: img
    })
  } 

  handleColorPrintModeChangeNewMethod = (event) => {
    var mode = event.target.value;
    this.setState({compressionLoading: true});
    this.props.handleColorPrintModeChange(event, mode);

    //Remove old pdf pages
     const container = document.getElementById("pdfDiv");
    while (container.children.length>=1) {
      container.removeChild(container.children[0]);
    } 
    
    //Run the generate method again
    this.makeRows(this.state.cropWidth, this.state.cropHeight, true);
  }

  handleColorPrintModeChangeChild = (event) => {
    var mode = event.target.value;
    var els = document.querySelectorAll(".grid-item");
    switch(mode) {
      case "onlySymbols":
        els.forEach(el => {
          var symbol = null;
          if(el.children.length!==0)
          {
            symbol = el.children[el.children.length-1];
            symbol.style.color = "black";
          }
            el.style.backgroundColor = "white";
        })
        break;
      case "symbolsAndColor":
        els.forEach(el => {
          var color = el.getAttribute("data-id");
          var rgb = color.replace('rgb(','');
          rgb = rgb.replace(')','');
          rgb = rgb.replace(' ','');
          rgb = rgb.split(',');
          rgb[0] = Number.parseInt(rgb[0],10);
          rgb[1] = Number.parseInt(rgb[1],10);
          rgb[2] = Number.parseInt(rgb[2],10);
          let symbolColor = this.pickTextColorBasedOnBgColorAdvanced(rgb,'white','black');
          var symbol = null;
          if(el.children.length!==0)
          {
            symbol = el.children[el.children.length-1];
            symbol.style.color = symbolColor;
          }
          el.style.backgroundColor = color;
      })
        break;
      case "onlyColor":
        els.forEach(el => {
        var color = el.getAttribute("data-id");
        var symbol = null;
        if(el.children.length!==0)
        {
          symbol = el.children[el.children.length-1];
          symbol.style.color = color;
        }
        el.style.backgroundColor = color;
        
        });
        break;
        default:
          els.forEach(el => {
            var symbol = null;
            if(el.children.length!==0)
            {
              symbol = el.children[el.children.length-1];
              symbol.style.color = "black";
            }
            el.style.backgroundColor = "white";
            
        })
          break;
    }

    this.props.handleColorPrintModeChange(event, mode);
  }

/*   handleSymbolPickerModeChangeChild = () => {
    this.props.handleSymbolPickerModeChange();
  } */

  createData = (name, flossName, dukke, sting, hexCode, red, green, blue, symbol) => {
    return {
      name,
      flossName,
      dukke,
      sting,
      hexCode,
      red,
      green,
      blue,
      symbol,
    };
  }

  isSubscribed = async(user, init=false) =>{   
    let isSubbed = false 
    let isFirst = true;
    const q = query(collection(db, "users",user.uid+'/subscriptions'), where('status', 'in', ['trialing', 'active']));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size !== 0) {
      isSubbed = true;
      isFirst = false;
      this.setState({isSubscribedCheck: true });
    }

    const q2 = query(collection(db, "users", user.uid + '/payments'), where('metadata.valid', '!=', '0'));
    const query2Snapshot = await getDocs(q2);
    if(query2Snapshot.size !== 0) {
      isSubbed = true;
      isFirst = false;
    }
    const q3 = query(collection(db, "users",user.uid+'/payments'), where('status', '==', 'succeeded'));
    const querySnapshot2 = await getDocs(q3);
    if(querySnapshot2.size !== 0) {
      isFirst = false;
    }
    if(isSubbed === false ) {
      let tierTemp = this.state.tiers2;
      if(isFirst===true) {
     /*  tierTemp.unshift(    {
        title: this.props.t('welcome.tier2_title_1'),
        price: this.props.t('welcome.tier2_price_1'),
        description: [
          this.props.t('welcome.tier2_description_1_1'),
          this.props.t('welcome.tier2_description_1_2')
        ],
        buttonVariant: 'outlined',
        plan: 'freePattern'
      },); */
    } const queryParams = new URLSearchParams(window.location.search);
    const openVal = queryParams.get("open");
    if(openVal && openVal!==""){
      this.setState({isSubscribed: true, showWelcome: false, isFirstTime: isFirst, tiers2: tierTemp, noEditOpen: false});
      this.handleOpenSavedPatternPreviewFromSearchParams(openVal, false);
    } else {
      this.setState({isSubscribed: false, showWelcome: true, isFirstTime: isFirst, tiers2: tierTemp});
    }
      
    } else {
      this.props.handleFetchLatestPatterns(this.context.user);
      this.setState({isSubscribed: true});
      const queryParams = new URLSearchParams(window.location.search);
    const openVal = queryParams.get("open");
    if(openVal && openVal!==""){
      this.handleOpenSavedPatternPreviewFromSearchParams(openVal, true);
    }
    }
}

checkAndUseBoughtPatterns = async(user) => {
  const q = query(collection(db, "users", user.uid + '/payments'), where('metadata.valid', '!=', '0'));
  const querySnapshot = await getDocs(q);
  if(querySnapshot.size !== 0) {
        let tmp = parseInt(querySnapshot.docs[0].data().metadata.valid);
        tmp = tmp - 1 ;
        let valueToUpdate = tmp.toString();
        await updateDoc(querySnapshot.docs[0].ref,{"metadata.valid":valueToUpdate});
  }
}

/* useBoughtPattern = async(user) => {
  const q = query(collection(db, "users", user.uid + '/payments'), where('metadata.valid', '!=', '0'));
  const querySnapshot = await getDocs(q);
  onlyOne = true;
  querySnapshot.forEach(async (doc) => {
    if(onlyOne===true) {
      let tmp = doc.data();
      tmp.metadata.valid = tmp.metadata.valid - 1;
      await doc.ref.update(tmp);
      onlyOne = false;
      console.log('used a ticket!!')
    }
  });
} */

 getNumberOfDays = (start) =>{
  const date1 = new Date(start);
  const date2 = new Date();

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

/*   handleFetchLatestPatterns = async (user) => {
    try {
      const q = query(collection(db, "users",user.uid+'/saved_patterns'),orderBy("milis", "desc"));
      const querySnapshot = await getDocs(q);
      let stateArray = [];
      if(querySnapshot.size !== 0) {
        let arr = [];
        let count = 0;
        querySnapshot.forEach((doc) => {
          if(count<3)
          {
            arr.push({storageName :doc.data().storageName, shared: doc.data().shared, date: doc.data().created});
            count++;
          }
        });
      
      for(let i = 0; i<arr.length; i++) {
        let daysAgo = 0;
        const imageRef = ref(storageRef, arr[i].storageName);
        if(arr[i].shared !==undefined)
        {
          if(arr[i].shared ===true) {
            daysAgo = this.getNumberOfDays(arr[i].date);
          } else {
            let dateCreated = await getMetadata(imageRef);
            daysAgo = this.getNumberOfDays(dateCreated.timeCreated);
          }
        } else {
          let dateCreated = await getMetadata(imageRef);
          daysAgo = this.getNumberOfDays(dateCreated.timeCreated);
        }

        await getDownloadURL(imageRef)
          .then((url) => {
              stateArray.push({photoUrl: url, created: daysAgo, shared: arr[i].shared });
                  })
                  .catch((error) => {
                    switch (error.code) {
                      case 'storage/object-not-found':
                        console.log('File doesnt exist');
                        break;
                      case 'storage/unauthorized':
                        console.log('User doesnt have permission to access the object');
                        break;
                      case 'storage/canceled':
                        console.log('User canceled the upload');
                        break;
                      case 'storage/unknown':
                        console.log('Unknown error occurred, inspect the server response');
                        break;
                    }
                  });
      }
    }
    this.setState({savedPatterns: stateArray});
    }
    catch(err) {
      console.log(err.message);
    }
  } */


/*   handleFetchAllPatterns = async (user) => {
    try {
      if(this.state.openDeletePatterns!== true) {
        this.setState({compressionLoading: true});
      }
      const q = query(collection(db, "users",user.uid+'/saved_patterns'),orderBy("created", "desc"));
      const querySnapshot = await getDocs(q);
      let arr = [];
      if(querySnapshot.size !== 0) {
        
        querySnapshot.forEach((doc) => {
            arr.push({
              a4Pages: doc.data().a4Pages,
              cmHeight: doc.data().cmHeight,
              cmWidth: doc.data().cmWidth,
              colorCount: doc.data().colorCount,
              created: doc.data().created,
              fabric: doc.data().fabric,
              name: doc.data().name,
              stitchHeight: doc.data().stitchHeight,
              stitchWidth: doc.data().stitchWidth,
              storageName: doc.data().storageName,
              photoUrl: '',
              totalStitches: doc.data().totalStitches,
              milis: doc.data().milis,
              dmcArray: doc.data().dmcArray,
              shared: doc.data().shared
            });
        });
      
      for(let i = 0; i<arr.length; i++) {
        const imageRef = ref(storageRef, arr[i].storageName);
        await getDownloadURL(imageRef)
          .then((url) => {
              arr[i].photoUrl = url;
                  });
      }
    }
    this.setState({allSavedPatterns: arr});
    console.log(arr);
    return arr;
    }
    catch(err) {
      console.log(err.message);
    }
  } */

  handleGoHome = (e) => {
if(e) {
  e.preventDefault();
}
/* if(this.props.freeMode === true) { */
  document.body.classList = '';
  this.setState({
    goHome: true
  })
/* } */

  }


  handleUpgrade = (e) => {
    if(e) {
      e.preventDefault();
    }

      this.props.setModeRight();
      document.body.classList = '';
      this.setState({
        goToUpgrade: true
      }, ()=> {
        //console.log("Going to upgrade!");
      })
      }

      handleGoToCatalog = (e) => {
        if(e) {
          e.preventDefault();
        }
          this.setState({
            goToCatalog: true
          });
          }


  handleDeletePatterns = async (patternsToDelete) => {
    //console.log("Deleting in imgdropcrop");
    try {
      this.setState({openSavedPattern: false, openDeletePatterns: true, numberOfPatternsToDelete: patternsToDelete.length});
      let arr = [];
      for(let i=0; i<patternsToDelete.length; i++) {
        const q = query(collection(db, "users",this.context.user.uid+'/saved_patterns'),where("storageName", "==", patternsToDelete[i].storageName));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let mockObject = {id: doc.id, storage: doc.data().storageName, shared: doc.data().shared, shareId: doc.data().shareId};
          arr.push(mockObject);
        });
      }

      if(arr.length>0) {
        for(let i=0; i<arr.length; i++) {
          //console.log(arr[i]);

          
          // Delete doc
          const docRef = doc(db, "users",this.context.user.uid+'/saved_patterns/'+arr[i].id);
          await deleteDoc(docRef);
          const cloudStorageRef = ref(storageRef, arr[i].storage);
          await deleteObject(cloudStorageRef).then(() => {
            //console.log("Successfully deleted image file!");
          }).catch((error) => {
            console.log(error);
          });

          if(arr[i].shared===true && arr[i].shareId!==undefined) {
          const dRef = doc(db, 'shared_patterns',arr[i].shareId);
          const docSnapper = await getDoc(dRef);
          if(docSnapper.exists()) {
            let docArr = [];
            docArr.push({
                activeUsers: docSnapper.data().activeUsers===undefined ? JSON.stringify([]) : docSnapper.data().activeUsers
              });
              var activeUsersVar = JSON.parse(docArr[0].activeUsers);
              if(activeUsersVar!==undefined)
                {
                  if(activeUsersVar.length>0)
                  {
                    activeUsersVar.forEach((el)=>{
                      if(el===this.context.user.uid) {
                        const index = activeUsersVar.indexOf(el);
                        activeUsersVar.splice(index,1);
                      }
                    })

                    await updateDoc(dRef, { 
                      activeUsers:  JSON.stringify(activeUsersVar)
                    });
                    
                  }
                }
              } 
            }

          //You should not be able to delete the storage file if: 1. you dont own the pattern 2. you own the pattern but has shared it
           
          //Check if is a pattern you have made
/*           if(arr[i].storage.includes(this.context.user.uid)){ */
            //You have made the pattern so now check if you have shared it before deleting

          /*   if(arr[i].shared === false) { */
/*             const docRef = doc(db, "shared_patterns", arr[i].storage);
            const docSnap = await getDoc(docRef);
            if(!docSnap.exists()) { */
                // You have made the pattern but have not shared it so delete the storage file
/*                 console.log("You own the pattern but have not shared it so deleting storage file!");
                const cloudStorageRef = ref(storageRef, arr[i].storage);
                await deleteObject(cloudStorageRef).then(() => {
                }).catch((error) => {
                  console.log(error);
                });
              }else{ */
                //You HAVE shared it and it is still in the catalog remove yourself from active users and dont delete storage image
/*                 console.log("You HAVE shared it and it is still in the catalog remove yourself from active users and dont delete storage image");
                let activeUsersArr = JSON.parse(docSnap.data().activeUsers);
                activeUsersArr.forEach((activeUser)=>{
                  if(activeUser===this.context.user.uid) {
                    let index = activeUsersArr.indexOf(activeUser);
                    activeUsersArr.splice(index,1); 
                  }
                });

                await updateDoc(docRef, {
                  activeUsers:  JSON.stringify(activeUsersArr)
                })
              } */

           /*  } else {
              //You do NOT own the pattern so remove you from the list of active users having the pattern
              console.log("You do NOT own the pattern so remove you from the list of active users having the pattern");
              const dRef = doc(db, 'shared_patterns',arr[i].storage);
              const docSnapper = await getDoc(dRef);
              if(docSnapper.exists()) {
                let docArr = [];
                docArr.push({
                    activeUsers: docSnapper.data().activeUsers===undefined ? JSON.stringify([]) : docSnapper.data().activeUsers
                  });
                  var activeUsersVar = JSON.parse(docArr[0].activeUsers);
                  console.log(activeUsersVar);
                  if(activeUsersVar!==undefined)
                    {
                      if(activeUsersVar.length>0)
                      {
                        activeUsersVar.forEach((el)=>{
                          if(el===this.context.user.uid) {
                            const index = activeUsersVar.indexOf(el);
                            activeUsersVar.splice(index,1);
                          }
                        })

                        await updateDoc(dRef, { 
                          activeUsers:  JSON.stringify(activeUsersVar)
                        });
                        
                      }
                    }



                }

            } */
         /*  } else { */
            //You have NOT made the pattern so check if it is still in the catalog and remove yourself from active users
/*             const docRef = doc(db, "shared_patterns", arr[i].storage);
            const docSnap = await getDoc(docRef);
            if(!docSnap.exists()) { */
                // The pattern is NOT in the catalog
/*                 console.log("You have NOT shared it so delete the file from storage");
                const cloudStorageRef = ref(storageRef, arr[i].storage);
                await deleteObject(cloudStorageRef).then(() => {
                }).catch((error) => {
                  console.log(error);
                }); */
           /*    } 
              else{ */
                //The pattern IS in the catalog so remove yourself from active users and dont delete storage image
/*                 console.log("You have not made the pattern and the pattern IS in the catalog so remove yourself from active users and dont delete storage image");
                let activeUsersArr = JSON.parse(docSnap.data().activeUsers);
                activeUsersArr.forEach((activeUser)=>{
                  if(activeUser===this.context.user.uid) {
                    let index = activeUsersArr.indexOf(activeUser);
                    activeUsersArr.splice(index,1); 
                  }
                });

                await updateDoc(docRef, {
                  activeUsers:  JSON.stringify(activeUsersArr)
                })
              }


          }*/

          //You should however be able to delete it if no one else has the image but you and you press delete


         } 
      }
      /* this.props.handleResetAllPatterns(); */
      await this.props.handleFetchAllPatterns(this.context.user);
      await this.props.handleFetchLatestPatterns(this.context.user);
      this.setState({compressionLoading: false, openDeletePatterns: false, editModeSavedPatterns: false});
    }
    catch(err) {
      this.setState({compressionLoading: false, openDeletePatterns: false});
      console.log(err.message);
    }
  }

  componentDidMount = () => {

    if (this.props.freeMode === false) {
      this.isSubscribed(this.context.user, true);
      /* this.handleFetchLatestPatterns(this.context.user); */

    } else {
      this.setState({
        showWelcome: false,
        isSubscribed: true,
        widthInput: 40
      })
    }
    
    this.props.setModeRight();
    document.body.classList = '';
  }

  componentDidUpdate = (prevProps,prevState ) => {
    if(prevProps.navigateProp !== this.props.navigateProp) {
      if(this.props.navigateProp===true) {
        if (this.props.freeMode === false) {
          this.isSubscribed(this.context.user, true);
          /* this.handleFetchLatestPatterns(this.context.user); */
    
        } else {
          this.setState({
            showWelcome: false,
            isSubscribed: true,
            widthInput: 40
          })
        }
        
        this.props.setModeRight();
        document.body.classList = '';
        this.props.handleSetNavigateProp(false);
      }

    }
  }

  createDMCDataDrawingCanvas = async (e, firstTime=false) => 
  {
    const canvas = document.getElementById("drawerCanvas");
    const ctx = canvas.getContext("2d");
    const palette = new Set();
    const tempRows = [];
    const pixelCountObject = {}

    for(let yy=0; yy<this.state.heightCanvasDraw; yy++) {
      for(let xx=0; xx<this.state.widthCanvasDraw; xx++) {
          const pixel = ctx.getImageData(xx*this.state.drawZoom, yy*this.state.drawZoom, 1, 1).data;
          const rgb = [pixel[0],pixel[1],pixel[2],pixel[3]];
          if(pixel[3]!==0) {
            var hexColor = '#'+rgbHex(rgb[0],rgb[1],rgb[2]);
          hexColor = hexColor.toUpperCase();
          if(palette.has(hexColor)){
            pixelCountObject[hexColor]++;
          } else {
            pixelCountObject[hexColor] = 1;
          }
          palette.add(hexColor);
          }
          
      } 
  }

  var i=0;
      for (const pkey of palette) {
        for (let dmckey in DMCJSONColors) {
          if (DMCJSONColors[dmckey]["Hex Code"] === pkey) {
            tempRows.push(this.createData(dmckey, DMCJSONColors[dmckey]["Floss Name"], pixelCountObject[pkey] >= 200 ? Math.round(((pixelCountObject[pkey] / 2000) + 0.1) * 10) / 10 : 0.1, pixelCountObject[pkey], DMCJSONColors[dmckey]["Hex Code"], DMCJSONColors[dmckey]["Red"], DMCJSONColors[dmckey]["Green"], DMCJSONColors[dmckey]["Blue"], dmcSymbols[i]));
          }
        }
      i++;
    }
    let totalStitchesVar = 0;
/*     console.log(pixelCountObject); */
    Object.keys(pixelCountObject).forEach((el) => {
/*       console.log(pixelCountObject[el]); */
      totalStitchesVar+=pixelCountObject[el];
    })

    if(firstTime === true) {
      var color = "rgb(0,0,0)";
      var name = "Black";
      var code = '310';
      const highest = Math.max(...tempRows.map(o => o.sting));
      Object.keys(tempRows).forEach(key => {
        if(tempRows[key].sting===highest) {
          color = "rgb("+tempRows[key].red+","+tempRows[key].green+","+tempRows[key].blue+")";
          name = tempRows[key].flossName;
          code = tempRows[key].name;
        }
      })
      var bColor = this.makeRgbStringDarkerOrLighter(color, stitchPercentBright)
      var dColor = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount);
       this.setState({
        dmcArrayDraw: tempRows,
        drawColor: color,
        drawColorDark: dColor,
        drawColorBright: bColor,
        drawColorName: name,
        drawColorCode: code,
        totalStitches: totalStitchesVar
      });
    } else {
      this.setState({
        dmcArrayDraw: tempRows,
        totalStitches: totalStitchesVar
      });
    }
    
    return true;  

  }



/*   createDMCDataDrawing = async (e,firstTime=false) => { */
    
    //This function updates the dmcArray state and makes it possible to display the current pixel count of the different colors in the drawer!
    //First we need to get the current palette
/*     var divs = document.querySelectorAll(".drawDiv");
    const palette = new Set();
    const tempRows = [];
    const pixelCountObject = {}
    divs.forEach(div => {
      var color = null;
    if(this.state.stitchMode===true) {
      color = div.style.color;
    } else {
      color = div.style.background;
    }
      var rgb = color.replace('rgb(','');
      rgb = rgb.replace(')','');
      rgb = rgb.replace(' ','');
      rgb = rgb.split(',');
      var red = Number.parseInt(rgb[0],10);
      var green = Number.parseInt(rgb[1],10);
      var blue = Number.parseInt(rgb[2],10);
      var hexColor = '#'+rgbHex(red,green,blue);
      hexColor = hexColor.toUpperCase();
      if(palette.has(hexColor)){
        pixelCountObject[hexColor]++;
      } else {
        pixelCountObject[hexColor] = 1;
      }
      palette.add(hexColor);
    }); */
    //console.log(palette);
    //console.log(pixelCountObject);
    //If profile 1 do hexcodes
/*     var i=0;
      for (const pkey of palette) {
        for (let dmckey in DMCJSONColors) {
          if (DMCJSONColors[dmckey]["Hex Code"] === pkey) {
            tempRows.push(this.createData(dmckey, DMCJSONColors[dmckey]["Floss Name"], pixelCountObject[pkey] >= 200 ? Math.round(((pixelCountObject[pkey] / 2000) + 0.1) * 10) / 10 : 0.1, pixelCountObject[pkey], DMCJSONColors[dmckey]["Hex Code"], DMCJSONColors[dmckey]["Red"], DMCJSONColors[dmckey]["Green"], DMCJSONColors[dmckey]["Blue"], dmcSymbols[i]));
          }
        }
      i++;
    }
    if(firstTime === true) {
      var color = "rgb(0,0,0)";
      var name = "Black";
      var code = '310';
      const highest = Math.max(...tempRows.map(o => o.sting));
      Object.keys(tempRows).forEach(key => {
        if(tempRows[key].sting===highest) {
          color = "rgb("+tempRows[key].red+","+tempRows[key].green+","+tempRows[key].blue+")";
          name = tempRows[key].flossName;
          code = tempRows[key].name;
        }
      })
      var bColor = this.makeRgbStringDarkerOrLighter(color, 20)
      var dColor = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount);
       this.setState({
        dmcArrayDraw: tempRows,
        drawColor: color,
        drawColorDark: dColor,
        drawColorBright: bColor,
        drawColorName: name,
        drawColorCode: code
      });
    } else {
      this.setState({
        dmcArrayDraw: tempRows,
      });
    }
    
    return true;   
  } */

  createDMCData = async () => {
    //console.log(dmcSymbols.length);
    const tempRows = [];
    /*     const tempRowsMultiple = [];
        let isMultiple = false; */
    //If profile 2 do rgb values
    if (Array.isArray(this.state.statePalette[0])) {
      for (let i = 0; i < this.state.statePalette.length; i++) {
        for (let dmckey in DMCJSONColors) {
          if (DMCJSONColors[dmckey]["Red"] === this.state.statePalette[i][0] && DMCJSONColors[dmckey]["Green"] === this.state.statePalette[i][1] && DMCJSONColors[dmckey]["Blue"] === this.state.statePalette[i][2]) {
            tempRows.push(this.createData(dmckey, DMCJSONColors[dmckey]["Floss Name"], 0.1, 233, DMCJSONColors[dmckey]["Hex Code"], DMCJSONColors[dmckey]["Red"], DMCJSONColors[dmckey]["Green"], DMCJSONColors[dmckey]["Blue"], dmcSymbols[i]));
          }
        }
      }
    }
    //If profile 1 do hexcodes
    else {
      for (let pkey in this.state.statePalette) {
        for (let dmckey in DMCJSONColors) {
          if (DMCJSONColors[dmckey]["Hex Code"] === this.state.statePalette[pkey]) {
            tempRows.push(this.createData(dmckey, DMCJSONColors[dmckey]["Floss Name"], 0.1, 233, DMCJSONColors[dmckey]["Hex Code"], DMCJSONColors[dmckey]["Red"], DMCJSONColors[dmckey]["Green"], DMCJSONColors[dmckey]["Blue"], dmcSymbols[pkey]));
          }
        }
      }
    }
    /*     if (tempRows.length > 60) {
          isMultiple = true;
          let tempTemp = [...tempRows];
          for (let i = Math.floor(tempRows.length / 60) + 1; i > 0; i--) {
            tempRowsMultiple.push(tempTemp.splice(0, Math.ceil(tempTemp.length / i)));
          }
        } */
    this.setState({
      /*       dmcSymbolTableIsMultiple: isMultiple,
            dmcSymbolTable: tempRowsMultiple, */
      dmcArray: tempRows,
    }, (e) => {
      this.getNumberOfPixels(e);
    });
    return true;
  }

  getNumberOfPixels = (e) => {
    if (e) e.preventDefault();
    var can = document.getElementById("pal_final");
    if (can !== null) {
      var ctx = can.getContext('2d');
      var pixelData = ctx.getImageData(0, 0, can.width, can.height).data;
      var rgbArray = []
      for (var i = 0; i < pixelData.length; i += 4) {
        rgbArray.push([pixelData[i], pixelData[i + 1], pixelData[i + 2]])
      }
      //console.log(rgbArray);
      //console.log(this.state.dmcArray);
      //var tempCount = [];
      //console.log(this.state.dmcArray);
      for (let key in this.state.dmcArray) {
        var colorCounter = 0;
        //var obj = { name: this.state.dmcArray[key]['name'], colorCount: 0};
        for (let key2 in rgbArray) {
          if (this.state.dmcArray[key]["red"] === rgbArray[key2][0] && this.state.dmcArray[key]["green"] === rgbArray[key2][1] && this.state.dmcArray[key]["blue"] === rgbArray[key2][2]) {
            //obj['colorCount']+=1;
            colorCounter++;
          }
        }
        //console.log(colorCounter);
        //tempCount.push(obj);
        this.state.dmcArray[key]["sting"] = colorCounter;
        if (colorCounter >= 200) {
          this.state.dmcArray[key]["dukke"] = Math.round(((colorCounter / 2000) + 0.1) * 10) / 10;
        }
      }
      /*       if(this.state.selectColorValue === 'profil2') {
              setTimeout((e) =>{this.handleSplice0Stitches(e);},100);
            }  */
      //console.log(tempCount);
      //console.log(rgbArray);

    }

    //console.log(this.state.dmcArray);
  }

  /*   handleSplice0Stitches = (e) => {
      if (e) e.preventDefault();
      let temp = this.state.dmcArray.slice(0,this.state.dmcArray.length);
      //CLEAN UP colors with 0 stitches!
    for (let i = 0; i < temp.length; i++) {
      console.log(temp[i]['flossName']+' Sting: '+temp[i]['sting']);
      if (temp[i]['sting']===0) {
        //console.log("Spliced: "+temp[i]['flossName']);
        temp.splice(i,1);
      }
    }
    this.setState({dmcArray:temp});             
    } */

  /*     mouseClicked = () => {
        document.querySelector('body').classList.add('noFocus');
        window.removeEventListener('mousedown', this.mouseClicked);
        window.addEventListener('keydown', this.tabKeyPressed);
      } */
  /*     onChange = (item, name) => { console.log(item, name); } */
/*   cropImageCopy = () => {
    if(this.state.canCrop===false) return;
    this.setState({madeChange:false});
        if (typeof this.cropper.getCroppedCanvas() === "undefined") {
          return;
        }
        const cropRes = this.cropper.getCroppedCanvas().toDataURL();
        const widthInput = document.getElementById("widthInput")
        let widthValue;
        let heightValue = this.state.cropHeight;
        switch (this.state.selectMeasuringUnit) {
          case 'korssting':
            widthValue = widthInput.value
            break;
    
          case 'centimeter':
            widthValue = Math.round(widthInput.value * this.state.selectBroderistof);
            break;
          default:
            widthValue = widthInput.value;
            break;
        }
        if (widthValue <= 0) {
          widthValue = 1
        } */
    
        /*     var ratio = widthValue / this.state.aspectRatio1
            let heightValue = Math.round(ratio * this.state.aspectRatio2) */
    
/*         if (this.state.freeAspect === true) {
          let data = this.cropper.getData(true);
          let tempRatio = 1;
          if (data['width'] < data['height']) {
            tempRatio = data['height'] / data['width'];
            heightValue = Math.round(widthValue * tempRatio);
          } else if (data['width'] > data['height']) {
            tempRatio = data['width'] / data['height'];
            heightValue = Math.round(widthValue * (1 / tempRatio));
          } else {
            heightValue = widthValue;
          } */
          /*       let scale = 100;
                heightValue =  Math.round((data['height']/750)*scale);
                widthValue =  Math.round((data['width']/750)*scale); */
    
/*           if (heightValue <= 0) {
            heightValue = 1
          }
    
        } else if (this.state.cropAspect === '11') {
          heightValue = widthValue
        }
    
        if (heightValue <= 0) {
          heightValue = 1
        }
    
        if (this.handleValidateStitchCount() === false) {
          return;
        }
        
         this.setState({
            showing: true,
            cropHeight: heightValue,
            cropWidth: widthValue,
            cropResult: cropRes
          },() => {
            setTimeout(function () { this.handleQuant() }.bind(this), 10);
          });
          
    
      } */

      handleGetCroppedCanvasData = async () => {
        try{
          if(this.cropper.getCroppedCanvas()===null)
          {
            setTimeout((e)=>{this.handleGetCroppedCanvasData()},10);
          }else {
            return this.cropper.getCroppedCanvas().toDataURL();
          }
        }
        catch(err) {
          console.log(err.message);
        }
      }


      
      handleChangeSymbols = (event) => {
        const container = document.getElementById("pdfDiv");
        container.innerHTML = "";
      }

      handleChangeAdvancedEditMode = (event, nextMode) => {
        if (nextMode !== null) {
          var prevMode = this.state.advancedMode;

          if(this.state.selectBox===true && prevMode === "selectiontool") {
            this.handleApplySelectionToolToCanvasNoMouse(event);
            if(this.state.selectionChange===true) {
              this.handleMakeChangeDrawCanvas();
              this.handleMouseUpDrawingCanvas();
            }
            
          }
          const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
          if(topSelectionParent) {
            while(topSelectionParent.firstChild) {
              topSelectionParent.removeChild(topSelectionParent.lastChild);
            }
          } 

          
          
        this.setState({
          madeChangeDrawCanvas: false,
          advancedMode: nextMode,
          selectBox: false,
          selectingArea: false
        },(e)=>{
          if(prevMode!=="colorreplacer" && nextMode==="colorreplacer") {
            this.createDMCDataDrawingCanvas(e);
            this.handleFetchColorsReplace(e);  
          } else if(prevMode==="selectiontool")  {
            const canvasTop = document.getElementById("drawerWrapperCanvasTop");
            if(canvasTop) {
              canvasTop.style.mixBlendMode = "normal";
            }
            
            //this.createDrawingCanvasSavedPattern(this.state.drawZoom);
          }
          else {
            this.setState({
              madeChangeDrawCanvas: false,
              replaceMode: false
            })
          }
        })
      }
      };


  cropImage = async () => {
    //console.log("hello1");
if(this.state.canCrop===false) return;
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    if (this.handleValidateStitchCount() === false) {
      return;
    }
    //console.log("hello2");
    const cropRes = await this.handleGetCroppedCanvasData();
    const widthInput = document.getElementById("widthInput")
    let widthValue;
    let heightValue = this.state.cropHeight;
    switch (this.state.selectMeasuringUnit) {
      case this.props.t('common.unit_1_small'):
        widthValue = widthInput.value
        break;

      case this.props.t('common.unit_2_small'):
        widthValue = Math.round(widthInput.value * this.state.selectBroderistof);
        break;

        case this.props.t('common.unit_3_small'):
        widthValue = Math.round(widthInput.value * (this.state.selectBroderistof * 2.54));
        break;
      default:
        widthValue = widthInput.value;
        break;
    }
    if (widthValue <= 0) {
      widthValue = 1
    }

    /*     var ratio = widthValue / this.state.aspectRatio1
        let heightValue = Math.round(ratio * this.state.aspectRatio2) */

    if (this.state.freeAspect === true) {
      let data = this.cropper.getData(true);
      let tempRatio = 1;
      if (data['width'] < data['height']) {
        tempRatio = data['height'] / data['width'];
        heightValue = Math.round(widthValue * tempRatio);
      } else if (data['width'] > data['height']) {
        tempRatio = data['width'] / data['height'];
        heightValue = Math.round(widthValue * (1 / tempRatio));
      } else {
        heightValue = widthValue;
      }
      /*       let scale = 100;
            heightValue =  Math.round((data['height']/750)*scale);
            widthValue =  Math.round((data['width']/750)*scale); */

      if (heightValue <= 0) {
        heightValue = 1
      }

    } else if (this.state.cropAspect === '11') {
      heightValue = widthValue
    }

    if (heightValue <= 0) {
      heightValue = 1
    }

    //console.log("hello");
    this.setState({
      showLoading: true,
      madeChange: false,
      cropWidth: widthValue,
      cropHeight: heightValue,
      cropResult: cropRes,
      opts: {
        colors: this.state.quant_colors,             // desired palette size
        method: this.state.quant_method,               // histogram method, 2: min-population threshold within subregions; 1: global top-population
        boxSize: this.state.quant_boxSize,        // subregion dims (if method = 2)
        boxPxls: this.state.quant_boxPxls,              // min-population threshold (if method = 2)
        initColors: this.state.quant_initColors,        // # of top-occurring colors  to start with (if method = 1)
        minHueCols: this.state.quant_minHueCols,           // # of colors per hue group to evaluate regardless of counts, to retain low-count hues
        dithKern: this.state.quant_dithKern,          // dithering kernel name, see available kernels in docs below
        dithDelta: 0,            // dithering threshhold (0-1) e.g: 0.05 will not dither colors with <= 5% difference
        dithSerp: this.state.quant_dithSerp,         // enable serpentine pattern dithering
        palette: this.state.quant_palette,             // a predefined palette to start with in r,g,b tuple format: [[r,g,b],[r,g,b]...]
        reIndex: true,          // affects predefined palettes only. if true, allows compacting of sparsed palette once target palette size is reached. also enables palette sorting.
        useCache: true,          // enables caching for perf usually, but can reduce perf in some cases, like pre-def palettes
        cacheFreq: 10,           // min color occurance count needed to qualify for caching
        colorDist: "euclidean",  // method used to determine color distance, can also be "manhattan"
      }
    })
    // Sleep for 3 seconds
    await new Promise(r => setTimeout(r, 100));
      var q = new RgbQuant(this.state.opts);
      var img = document.getElementById("pixelDiv").children[0].children[0];
      try{
        q.sample(img);
      }
      catch(err) {
        console.log("Error!: "+err.message);
        await new Promise(r => setTimeout(r, 100));
        this.cropImage();
        return;
      }
      
      var pal = q.palette(true, false);
      //this.handleGetNearestColor(pal);
      // var pal1 = q.palette();
      var reduced = q.reduce(img);
/*       q.sample(img);
      var pal = q.palette(true, false); */
      /* var pal = await this.handleGetPalette(q, img); */
      //this.handleGetNearestColor(pal);
      // var pal1 = q.palette();
      /* var reduced = await this.handleGetReduced(q, img); */

      /* MAGICCCC BEGINS HERE */
      var newPal = [];
      if (this.state.selectColorValue === 'profil1') {
        var newHexPal = await this.handlePaletteCalculation(pal, reduced);
  
        for (let key in newHexPal) {
          for (let key2 in DMCJSONColors) {
            if (DMCJSONColors[key2]['Hex Code'] === newHexPal[key]) {
              newPal.push(DMCJSONColors[key2]['Hex Code'])
            }
          }
        }
        this.setState({
          statePalette: newPal,
          numberOfDMCColors: newPal.length
        });
      }
      else {
        this.setState({
          statePalette: pal,
          numberOfDMCColors: pal.length
        });
      }
          // Sleep for 3 seconds
    await new Promise(r => setTimeout(r, 10));
      /* MAGICCCC END HERE */
      var id = "pal_final";
      var element = document.getElementById(id);
      if (typeof (element) !== 'undefined' && element !== null) {
        element.remove()
      }
      var child = null;
  
      var can = document.createElement("canvas");
      
      id && can.setAttribute("id", id);
      can.width = widthValue;
      can.height = heightValue;
      var ctx = can.getContext("2d");
      var imgd = await this.handleGetImageData(ctx, widthValue, heightValue);
      if(reduced.length!==imgd.data.length) {
        await new Promise(r => setTimeout(r, 1000));
        //console.log("hellooooooooo");
        this.cropImage();
        return;
        /* img = document.getElementById("pixelDiv").children[0].children[0];
        reduced = await this.handleGetReduced(q, img); */
      }

      imgd.data.set(reduced);
  
      ctx.putImageData(imgd, 0, 0);
  
      if (quantDiv !== null) {
        child = quantDiv.appendChild(can);
      }
      else {
        child = document.body.appendChild(can);
      }
  
        let propWidth = widthValue;
        let propHeight = heightValue;
        let ratio;
        if (widthValue > heightValue) {
          ratio = widthValue / heightValue;
          propWidth = 100;
          propHeight = 100 * (1 / ratio);
        } else if (heightValue > widthValue) {
          ratio = heightValue / widthValue;
          propHeight = 100;
          propWidth = 100 * (1 / ratio);
  
        } else {
          propWidth = 100;
          propHeight = 100;
        }
  
        child.style.setProperty("--cropWidth", propWidth);
        child.style.setProperty("--cropHeight", propHeight);
        child.classList.add('upscaledCanvas1to1');
        this.setState({showLoading: false});
        //console.log("helloFINISHED!");
}
  //pixelRef = this.cropper.getCroppedCanvas()
  // getHeight = () => {
  //   const width = this.state.width
  //   var ratio = width / this.state.aspectRatio1
  //   var calculate_height = ratio * this.aspectRatio2
  //   return calculate_height
  // }

  updateUpscaledPixel = () => {
    const canvasRef = document.getElementById("pal_final")
    //const {imgSrcExt} =  this.state
    if (canvasRef !== null) {
      const imageData64 = canvasRef.toDataURL('image/png')
      this.setState({
        pixel64data: imageData64
      });
    }
  }
  cropImageMaybe = () => {
    this.setState({madeChange: true},(e)=> {
     this.cropImage(e);
    });
 
  }

/*   handleGetPalette = async (q,img) => {
    let pal = await q.sample(img);
    return pal;
  } */

/*   handleGetReduced = async (q,img) => {
    let reduced = await q.reduce(img);
    return reduced;
  } */

  
  handleGetImageData = async (ctx, width, height) => {
    let imgd = await ctx.createImageData(width, height);
    return imgd;
  }


  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0]
      const currentFileType = currentFile.type
      const currentFileSize = currentFile.size
      if (currentFileSize > imageMaxSize) {
        alert("This file is not allowed. " + currentFileSize + " bytes is too large")
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        alert("This file is not allowed. Only images are allowed.")
        return false
      }
      return true
    }
  }
/*   putPixels = (subpxArr, width, height, id, parent = null) => {
    //const previewDiv = document.getElementById("patternPreviewDoneDiv")

    if (height <= 0 || width <= 0) return;
    if (this.handleValidateStitchCount() === false) {
      return;
    }
    var element = document.getElementById(id);
    if (typeof (element) !== 'undefined' && element !== null) {
      element.remove()
    }
    var child = null;

    var can = document.createElement("canvas");

    id && can.setAttribute("id", id);
    can.width = width;
    can.height = height;
    var ctx = can.getContext("2d");
    var imgd = ctx.createImageData(can.width, can.height);


    imgd.data.set(subpxArr);

    ctx.putImageData(imgd, 0, 0);

    if (parent !== null) {
      child = parent.appendChild(can);
    }
    else {
      child = document.body.appendChild(can);
    }


    if (id === 'pal_final') {

      let propWidth = width;
      let propHeight = height;
      let ratio;
      if (width > height) {
        ratio = width / height;
        propWidth = 100;
        propHeight = 100 * (1 / ratio);
      } else if (height > width) {
        ratio = height / width;
        propHeight = 100;
        propWidth = 100 * (1 / ratio);

      } else {
        propWidth = 100;
        propHeight = 100;
      }

      child.style.setProperty("--cropWidth", propWidth);
      child.style.setProperty("--cropHeight", propHeight);
      child.classList.add('upscaledCanvas1to1')


    }
  } */
  handleUpdateQuant = event => {
    if (event) event.preventDefault()
    this.setState({
      opts: {
        colors: this.state.quant_colors,             // desired palette size
        method: this.state.quant_method,               // histogram method, 2: min-population threshold within subregions; 1: global top-population
        boxSize: this.state.quant_boxSize,        // subregion dims (if method = 2)
        boxPxls: this.state.quant_boxPxls,              // min-population threshold (if method = 2)
        initColors: this.state.quant_initColors,        // # of top-occurring colors  to start with (if method = 1)
        minHueCols: this.state.quant_minHueCols,           // # of colors per hue group to evaluate regardless of counts, to retain low-count hues
        dithKern: this.state.quant_dithKern,          // dithering kernel name, see available kernels in docs below
        dithDelta: 0,            // dithering threshhold (0-1) e.g: 0.05 will not dither colors with <= 5% difference
        dithSerp: this.state.quant_dithSerp,         // enable serpentine pattern dithering
        palette: this.state.quant_palette,             // a predefined palette to start with in r,g,b tuple format: [[r,g,b],[r,g,b]...]
        reIndex: true,          // affects predefined palettes only. if true, allows compacting of sparsed palette once target palette size is reached. also enables palette sorting.
        useCache: true,          // enables caching for perf usually, but can reduce perf in some cases, like pre-def palettes
        cacheFreq: 10,           // min color occurance count needed to qualify for caching
        colorDist: "euclidean",  // method used to determine color distance, can also be "manhattan"
      }
    })
  }


/*   handleQuant = (event) => {
    if (event) event.preventDefault()

    if (this.handleValidateStitchCount() === false) {
      return;
    }
    const colorInput = document.getElementById("colorInput")
    //pixelImg
    var img = document.getElementById("pixelDiv").children[0].children[0];
    let colorValue = colorInput.value
    if (colorValue <= 0) {
      colorValue = 1
    } else if (colorValue >= 256) {
      colorValue = 256
    }


    this.setState({
      quant_colors: colorValue,
    }, (e) => {
      this.handleUpdateQuant(e); 
      setTimeout(function () { this.updateUpscaledPixel() }.bind(this), 10);
    })

    //console.time("quant");
    var q = new RgbQuant(this.state.opts);
    if (img !== null) {
      q.sample(img);
    }
    var pal = q.palette(true, false);
    //this.handleGetNearestColor(pal);
    // var pal1 = q.palette();
    var reduced = q.reduce(img);

    /* MAGICCCC BEGINS HERE */
/*     var newPal = [];
    if (this.state.selectColorValue === 'profil1') {
      var newHexPal = this.handlePaletteCalculation(pal, reduced);

      for (let key in newHexPal) {
        for (let key2 in DMCJSONColors) {
          if (DMCJSONColors[key2]['Hex Code'] === newHexPal[key]) {

            newPal.push(DMCJSONColors[key2]['Hex Code'])
          }
        }
      }

      this.setState({
        statePalette: newPal,
        numberOfDMCColors: newPal.length
      })
    }
    else {
      this.setState({
        statePalette: pal,
        numberOfDMCColors: pal.length
      })
    }
    this.putPixels(reduced, this.state.cropWidth, this.state.cropHeight, "pal_final", quantDiv); */
    /* MAGICCCC END HERE */
  /*} */

  handlePaletteCalculation = async (pal, reduced) => {
    var nearestColor = require('nearest-color').from(DMCJSONColorsHex);
    var newHexPal = [];
    for (var i = 0; i < reduced.length; i += 4) {
      for (let key in pal) {
        if (reduced[i] === pal[key][0] && reduced[i + 1] === pal[key][1] && reduced[i + 2] === pal[key][2]) {
          var col = nearestColor('#' + rgbHex(pal[key][0], pal[key][1], pal[key][2]));
          // Check if key exists
          let hasKey = col.hasOwnProperty('rgb');
          if (hasKey) {
            reduced[i + 0] = col['rgb']['r'];
            reduced[i + 1] = col['rgb']['g'];
            reduced[i + 2] = col['rgb']['b'];
          }
          if (!newHexPal.includes(col['value'])) {
            newHexPal.push(col['value']);
          }
        }
      }
      //reduced.push([pixelData[i], pixelData[i+1], pixelData[i+2]])
    }
    return newHexPal;

  }

  handleDontCloseWindow = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  }

   handleImageUpload = async (files) => {

    const imageFile = files[0];
/*     console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`); */
  
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 2000,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
     /*  console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB */
  
      return compressedFile; // write your own logic
    } catch (error) {
      console.log(error);
    }
  
  }

  checkIfMobile = () => {
    const element = document.querySelector(".overskrift");
/*     console.log(getComputedStyle(element).margin.split(" ")[0]); */
    if(getComputedStyle(element).margin.split(" ")[0] === '20px'){
      return true;
    }
    return false;
  } 

  handleOpenCreateNewBlankCanvas = () => {
    this.setState({
      openCreateNewBlankCanvas: true
    });
  }

  handleCloseCreateNewBlankCanvas = () => {
    this.setState({
      openCreateNewBlankCanvas: false
    });
  }

  handleOpenResizer = () => {
    this.setState({
      openResizer: true,
      resizeValueWidth: this.state.cropWidth,
      resizeValueHeight: this.state.cropHeight,
      resizeValueBottom: 0,
      resizeValueLeft: 0,
      resizeValueRight: 0,
      resizeValueTop: 0
    });
  }

  handleCloseResizer = () => {
    this.setState({
      openResizer: false
    });
  }
  getTextCanvasData = () => {
    // var w = 300, h = 150, ratio = 2;
    // _canvas.width = w * ratio;
    // _canvas.height = h * ratio;
    // _canvas.style.width = w + "px";
    // _canvas.style.height = h + "px";
    let _canvas = document.getElementById("textToolCanvasTextTemp");
    let _ctx = _canvas.getContext("2d");
    _ctx.imageSmoothingEnabled= false;
    //_ctx.fillStyle = "rgb(0, 154, 253)";
    // _ctx.setTransform(ratio, 0, 0, ratio, 0, 0);

    var str = "stackoverflow";
    _ctx.font = "32px EB Garamond";
    _ctx.fillText(str,0,23);

    let _width = _canvas.width;
    let _height = _canvas.height;

    var pixels = _ctx.getImageData(0, 0, _width, _height).data;
    var data32 = new Uint32Array(pixels.buffer);
    var positions = [];
        for(let i = 0; i < data32.length; i++) {
        if (data32[i] & 0xffff0000) {
            positions.push({
                x: (i % _width),
                y: ((i / _width)|0),
                a: pixels[i*4 + 3] / 255
            });
        }
    }

    return positions;
}
setupParticles = (positions, ctx) =>{
  //var i = positions.length;
  //var particles = [];
  for(let i = 0; i<positions.length; i++){
      var p = {x: positions[i].x-1,y: positions[i].y-1};
      //particles.push(p);
      ctx.fillRect(p.x,p.y,1,1);
      //this.drawParticle(p, ctx);
  }
}

drawParticle = (particle,_ctx) =>{
  var x = particle.x;
  var y = particle.y;

  _ctx.beginPath();
  _ctx.fillStyle = `rgba(0,128,0,${particle.alpha})`;
  
  _ctx.fillRect(x, y, 10, 10);
}

Particle = () =>{
  this.init = function(pos){
      this.x = pos.x;
      this.y = pos.y + 30;
      this.x0 = this.x;
      this.y0 = this.y;
      this.xDelta = 0;
      this.yDelta = 0;
      this.alpha = pos.a;
  }
}

handleGetBBOxWidth = (ctx,text) => {
  const measure = ctx.measureText(text);
  const supportExtendedMetrics = 'actualBoundingBoxRight' in TextMetrics.prototype;
  return supportExtendedMetrics ? 
    (measure.actualBoundingBoxLeft + measure.actualBoundingBoxRight) :
    measure.width;
}
  
trimCanvasNormal = (ctx,pSize) => {
  var canvas = ctx.canvas, 
    w = canvas.width, h = canvas.height,
    pix = {x:[], y:[]},
    imageData = ctx.getImageData(0,0,canvas.width,canvas.height),
    x, y, index;

  for (y = 0; y < h; y++) {
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4;
      if (imageData.data[index+3] > 0) {
        pix.x.push(x);
        pix.y.push(y);
      } 
    }
  }
  pix.x.sort(function(a,b){return a-b});
  pix.y.sort(function(a,b){return a-b});
  var n = pix.x.length-1;
  
  w = 1 + pix.x[n] - pix.x[0];
  h = 1 + pix.y[n] - pix.y[0];
  //console.log(w+ " / "+h);
  if(w===NaN || h===NaN) {return;}
  var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

  canvas.width = w+(10*pSize);
  canvas.height = h+(20*pSize);
/*   rulerCanvas.width = w;
  rulerCanvas.height = h; */
  ctx.putImageData(cut, 0, 0);

  //var image = canvas.toDataURL();
}

handleUpdateTextTool = () => {
  const canvas = document.getElementById("textToolCanvas");
  //const rulerCanvas = document.getElementById("textToolCanvasRulers");
    const ctx = canvas.getContext("2d");
    //const rulerCtx = rulerCanvas.getContext("2d");
    

    //rulerCanvas.width = 600;
    //rulerCanvas.height = 300;
/*     ctx.fillStyle = "#fff"; */
    
    //rulerCtx.clearRect(0,0,canvas.width,canvas.height);


    //this.setupParticles(this.getTextCanvasData(),_ctx);
    let _canvas = document.getElementById("textToolCanvasTextTemp");
    let _ctx = _canvas.getContext("2d");
    _ctx.clearRect(0,0,_canvas.width,_canvas.height);
    var txt = this.state.textToolText;
    _ctx.font = ((this.state.textToolSize/100)*64)+16+"px "+this.state.textToolFontSelected;
     let metrics = _ctx.measureText(txt);
     let textWidth = this.handleGetBBOxWidth(_ctx,txt);
    let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    const pixelSize = 8;
    canvas.width = textWidth*pixelSize*2+(10*pixelSize);
    canvas.height = fontHeight*pixelSize*2+(20*pixelSize);
    ctx.clearRect(0,0,canvas.width,canvas.height);
/*     const back = document.getElementById("textToolCanvasWrapper"); */
    //canvas.style.setProperty("--textToolBackSize",pixelSize*2+"px");
/*     _canvas.width = metrics.width;
    _canvas.height = actualHeight;  */
/*     _ctx.fillStyle = "#fff";
    _ctx.fillRect(0,0,_canvas.width,_canvas.height); */
    _ctx.imageSmoothingEnabled= false;
    _ctx.textRendering = "geometricPrecision";
    
    _ctx.textBaseline = "top";

    _ctx.fillStyle = "#000";

    _ctx.fillText(txt,10,20);

    var pixelData = _ctx.getImageData(0, 0, _canvas.width, fontHeight+20).data;

      var rgbArray = []
      for (var i = 0; i < pixelData.length; i += 4) {
        rgbArray.push({r: pixelData[i], g: pixelData[i + 1], b: pixelData[i + 2], a: pixelData[i + 3]})
      }

/*       let y = 0;
      let rows = {}
      for(let i = 0; i< rgbArray.length; i++) {
        let arr = row[y.toString()];
        arr.push(rgbArray());
        row[y.toString()] = 
        if(y%_canvas.width===0) {
          y++;
        }
      } */
      const perChunk = _canvas.width // items per chunk  
      const result = rgbArray.reduce((all,one,i) => {
        const ch = Math.floor(i/perChunk); 
        all[ch] = [].concat((all[ch]||[]),one); 
        return all
     }, [])

     ctx.fillStyle = this.state.drawColor;
     for (let y=0; y<fontHeight+20; y++) {
      for(let i = 0; i<result[y].length; i++) {
        if(result[y][i].a>=50) {
          ctx.fillStyle = this.state.drawColor;
          ctx.fillRect(pixelSize*i,y*pixelSize,pixelSize,pixelSize);
        } else if(y===0 && i===0) {
          ctx.fillStyle = "rgba(0,0,0,0.1)";
          ctx.fillRect(pixelSize*i,y*pixelSize,1,1);
        } 
      }
    }
if(txt!=="") {
  this.trimCanvasNormal(ctx,pixelSize);

   

   
   let counterWidth = 0;
    for(let i=0; i<canvas.width;i+=pixelSize) {
      if(counterWidth>=10) {
        ctx.fillStyle = "rgba(0,0,0,1)";
        ctx.fillRect(i,0,1.5,canvas.height);
        counterWidth=0;
      } else {
/*         ctx.fillStyle = "rgba(0,0,0,0.5)"; */
        ctx.fillRect(i,0,0.5,canvas.height);
        counterWidth++;
      }
    }
    let counterHeight = 0;

    for(let i=0; i<canvas.height;i+=pixelSize) {
      if(counterHeight>=10) {
        ctx.fillStyle = "rgba(0,0,0,1)";
        ctx.fillRect(0,i,canvas.width,1.5);
        counterHeight=0;
      } else {
/*         ctx.fillStyle = "rgba(0,0,0,0.5)"; */
        ctx.fillRect(0,i,canvas.width,0.5);
        counterHeight++;
      }
    }
  }
}

  handleOpenTextTool = () => {

    this.handleUpdateTextTool();
/*     ctx.font = "4px serif";
    ctx.textRendering = "geometricPrecision";
    ctx.textBaseline = "top";
    ctx.scale(10,10);
    ctx.fillText("Hello world", 10, 10); */
/*     let _canvas = document.getElementById("textToolCanvasText"); */
    // _canvas.width = w * ratio;
    // _canvas.height = h * ratio;
    // _canvas.style.width = w + "px";
    // _canvas.style.height = h + "px";

/*     let _ctx = _canvas.getContext("2d"); */

    
/* _canvas.style.setProperty("--textToolWidth",_canvas.width*10+"px");
    _canvas.style.setProperty("--textToolHeight",_canvas.height*10+"px"); */
/*     let pixels = [];
    for(let i = textPixels.length-1; i>0;i--) {
      let o = {x:textPixels[i].x-1, y: textPixels[i].y-1}
      pixels.push(o);
    } */
    //console.log(textPixels);
/* let rows = {}
     for(let y =0; y<=textPixels[textPixels.length-1].y;y++) {
      let row = []
      for(let i=0;i<textPixels.length; i++) {
        if(y===textPixels[i].y) {
          row.push(textPixels[i].x);
          
        }
    }
    rows[y.toString()] = row;
    }   */
    
/*     for (let [y, x] of Object.entries(rows)) {
      for(let i = 0; i<x.length; i++) {
        ctx.fillRect(x[i]+(2*i),parseInt(y)*2,2,2);
      } */
/*       for(let i = 0; i<rows[key].length; i++) {
        ctx.fillRect(rows[key][i]+(i*2),parseInt(key)*2,2,2);
        console.log(rows[key][i]);
      } */
   // }
    
    this.setState({
      openTextTool: true,
    });
  }

  handleCloseTextTool = (e) => {
    e.preventDefault();
    this.setState({
      openTextTool: false
    });
  }

  handleResizePattern = (e) => {
    e.preventDefault();
    var newHVal = parseInt(this.state.resizeValueHeight);
    var newWVal = parseInt(this.state.resizeValueWidth);
    var originalWidth = this.state.cropWidth;
    var originalHeight = this.state.cropHeight;
    var addToTop = parseInt(this.state.resizeValueTop);
    var addToLeft = parseInt(this.state.resizeValueLeft);
    var addToRight = parseInt(this.state.resizeValueRight);
    var addToBottom = parseInt(this.state.resizeValueBottom);

    if(addToBottom===0 && addToLeft===0 && addToRight===0 && addToTop===0) { 
      this.setState({openResizer: false});
      return; }

    this.setState({
      cropHeight: newHVal, 
      cropWidth: newWVal, 
      heightCanvasDraw: newHVal,
      widthCanvasDraw: newWVal,
      openResizer: false}, (e)=> {
        this.createDrawingCanvasResize(addToTop,addToLeft,addToRight,addToBottom, parseInt(originalWidth), parseInt(originalHeight));
      });
  }

  handleOnUploadImageDrop = async (e) => {
    let files = e.target.files;

    const isMobile = this.checkIfMobile();
    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {

        window.addEventListener('beforeunload', this.handleDontCloseWindow);

        // imageBase64Data 
        /* const currentFile = files[0] */
        let currentFile;
        if(isMobile===true)  {
          this.setState({compressionLoading: true});
          currentFile = await this.handleImageUpload(files);
        }
        else {
          currentFile = files[0]
        }
        var widthIn = 100;
        
        const myFileItemReader = new FileReader()
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)



          const myResult = myFileItemReader.result


          if(this.props.freeMode===true) {
            widthIn = 60;
            var img = new Image();
            img.src = myResult;
            // Wait for the image to load
            img.onload = () => {
              // Get the width and height of the image
              var width = img.width;
              var height = img.height;

              let ratio;
              if (height > width) {
                ratio = height / width;
                let propWidth = (1 / ratio);
                
                

                var startWidth = Math.round(60*propWidth);
              let tempRatio = width / height;
              let heightValue = Math.round(startWidth * (1 / tempRatio));
              //console.log(heightValue);
              while(startWidth*heightValue<=3600) {
                startWidth = startWidth+=1;
                heightValue = Math.round(startWidth * (1 / tempRatio));
              } 
              widthIn = startWidth-1;
              } else if (height < width) {
                //Get how much bigger the width is over the height
                let tempRatio = height / width;
                var startWidth = Math.round(60 * tempRatio);
                let heightValue = Math.round(startWidth * tempRatio);
                while(startWidth*heightValue<=3600) {
                  /* console.log(startWidth+" * "+ heightValue + " = "+ startWidth*heightValue); */
                  startWidth = startWidth+=1;
                  heightValue = Math.round(startWidth * tempRatio);
                }

                widthIn = startWidth-1; 
              }
              
              this.setState({
                savedPatternMode: false,
                compressionLoading: false,
                widthInput: widthIn,
                imgSrc: myResult,
                imgSrcExt: extractImageFileExtensionFromBase64(myResult)
              }, (e) => {
                if(isMobile===true)  {
                setTimeout(function () { this.cropImage(e); }.bind(this), 500);
                } else {
                  setTimeout(function () { this.cropImage(e); }.bind(this), 10);
                }
              });
           

              

            };
          } else {
            this.setState({
              savedPatternMode: false,
              compressionLoading: false,
              widthInput: widthIn,
              imgSrc: myResult,
              imgSrcExt: extractImageFileExtensionFromBase64(myResult)
            }, (e) => {
              if(isMobile===true)  {
              setTimeout(function () { this.cropImage(e); }.bind(this), 500);
              } else {
                setTimeout(function () { this.cropImage(e); }.bind(this), 10);
              }
            });
          }

         

        }, false)

        myFileItemReader.readAsDataURL(currentFile)
      }
    }

  }

  handleOnDrop = async (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }
    const isMobile = this.checkIfMobile();

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {

        window.addEventListener('beforeunload', this.handleDontCloseWindow);

        // imageBase64Data 
        /* const currentFile = files[0] */
        let currentFile;
        if(isMobile===true)  {
          this.setState({compressionLoading: true});
          currentFile = await this.handleImageUpload(files);
        }
        else {
          currentFile = files[0]
        }
        var widthIn = 100;
        if(this.props.freeMode===true) {
          widthIn = 60;
          //console.log(currentFile);
        }
        const myFileItemReader = new FileReader()
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result
          this.setState({
            savedPatternMode: false,
            compressionLoading: false,
            widthInput: widthIn,
            imgSrc: myResult,
            imgSrcExt: extractImageFileExtensionFromBase64(myResult)
          }, (e) => {
            if(isMobile===true)  {
            setTimeout(function () { this.cropImage(e); }.bind(this), 500);
            } else {
              setTimeout(function () { this.cropImage(e); }.bind(this), 10);
            }
          })
        }, false)

        myFileItemReader.readAsDataURL(currentFile)
      }
    }
  }

  handleSetTextSize = (textSize) => {
      this.setState({textToolSize: textSize},()=> {
        this.handleUpdateTextTool();
      });
  }

  handleOnCropChange = (crop) => {
    this.setState({ crop: crop, madeChange: true })
  }

  handleRotateCrop = (rotation) => {
    if(this.state.madeChange===false) {
      this.setState({madeChange: true});
    }
    this.cropper.rotateTo(rotation);
  }

  

  handleResetRotation = (e) => {
    e.preventDefault();
    this.cropper.rotateTo(0);
  }

  handleFlipCropperHorizontal = (e) => {
    e.preventDefault();

    if (this.state.cropScaleX === 1) {
      this.setState({ cropScaleX: -1, madeChange: true }, (e) => {
        this.cropper.scale(this.state.cropScaleX, this.state.cropScaleY);
        setTimeout(function () { this.cropImage(e) }.bind(this), 100);
      })
    } else {
      this.setState({ cropScaleX: 1, madeChange: true }, (e) => {
        this.cropper.scale(this.state.cropScaleX, this.state.cropScaleY);
        setTimeout(function () { this.cropImage(e) }.bind(this), 100);
      })
    }
  }


  handleResetFlip = (e) => {
    e.preventDefault();
    this.setState({
      madeChange: true,
      cropScaleX: 1,
      cropScaleY: 1
    }, (e) => {
      this.cropper.scale(this.state.cropScaleX, this.state.cropScaleY);
      setTimeout(function () { this.cropImage(e) }.bind(this), 100);
    })
  }

  handleResetCrop = (e) => {
    e.preventDefault();
    this.setState({
      madeChange: true
    }, (e) => {
      
      this.cropper.zoomTo(0);
      this.handleResetFlip;
      this.handleResetRotation;
      this.cropper.reset();
      setTimeout(function () { this.cropImage(e) }.bind(this), 100);
    })
  }

  handleFlipCropperVertical = (e) => {
    e.preventDefault();
    if (this.state.cropScaleY === 1) {
      this.setState({ cropScaleY: -1, madeChange: true }, (e) => {
        this.cropper.scale(this.state.cropScaleX, this.state.cropScaleY);
        setTimeout(function () { this.cropImage(e) }.bind(this), 100);
      })
    } else {
      this.setState({ cropScaleY: 1, madeChange: true }, (e) => {
        this.cropper.scale(this.state.cropScaleX, this.state.cropScaleY);
        setTimeout(function () { this.cropImage(e) }.bind(this), 100);
      })
    }
  }
  handleOnCropComplete = (pixelCrop) => {

    const canvasRef = this.imagePreviewCanvasRef.current
    const { imgSrc } = this.state
    image64toCanvasRef(canvasRef, imgSrc, pixelCrop)

  }
  handleDownloadClick = (event) => {
    event.preventDefault()
    const { imgSrc } = this.state
    if (imgSrc) {

      const canvasRef = document.getElementById("pal_final")

      const { imgSrcExt } = this.state
      const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)
      const myFilename = "previewFile." + imgSrcExt

      // file to be uploaded
      const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
      //console.log(myNewCroppedFile)
      // download file
      downloadBase64File(imageData64, myFilename)
    }
  }


  handleChangePalette = (event) => {
    if (event) event.preventDefault()
    switch (this.state.selectColorValue) {
      case 'profil1':
        this.setState({
          quant_palette: 0,
        }, (e) => {
          this.handleUpdateQuant(e);
          this.cropImage(e);
        }
        )
        break;

      case 'profil2':
        this.setState({
          quant_palette: dmcrgb,
        }, (e) => {
          this.handleUpdateQuant(e);
          this.cropImage(e);
        })
        break;
      default:
        this.setState({
          quant_palette: 0,
        }, (e) => {
          this.handleUpdateQuant(e);
          this.cropImage(e);
        }
        )
        break;
    }
    //     //this.fileInputRef.current.value = null
  }
  draw = (e, ctx) => {
    var pal = document.getElementById('pal_final');
    
    const bounding = pal.getBoundingClientRect();
    const x = e.clientX - bounding.left;
    const y = e.clientY - bounding.top;
    const pixel = ctx.getImageData(x, y, 1, 1);
    const data = pixel.data;
    const rgba = `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${data[3] / 255})`;
    const hoveredColor = document.getElementById("colorPicker");
    hoveredColor.style.background = rgba;
  }





handleConvertPixelDrawingToCanvas = async () => {
  /* const pixels = document.querySelectorAll(".drawDiv");
  const canvas = document.getElementById('pal_final');
  var rows = canvas.width;
  var cols = canvas.height;
  const rgbArr = await this.createPixelRGBMatrix(pixels,rows, cols);
  canvas.width = rows;
  canvas.height = cols;
  const ctx = canvas.getContext("2d");
  for(var i=0; i< rgbArr.length; i++){ 
    for(var j=0; j< rgbArr[0].length; j++){ 
        ctx.fillStyle = rgbArr[i][j];
        ctx.fillRect(i,
                 j,
                 1,
                 1);            
    }
} */
const sourceCanvas = document.getElementById('drawerCanvas');
const sourceCtx = sourceCanvas.getContext("2d");
/* console.log(this.state.heightCanvasDraw);
console.log(this.state.widthCanvasDraw); */
let pixelRgbArray = [];
  for(let yy=0; yy<this.state.heightCanvasDraw; yy++) {
    for(let xx = 0; xx<this.state.widthCanvasDraw; xx++) {
    const x = xx*this.state.drawZoom;
    const y = yy*this.state.drawZoom;
    const cellX = Math.floor(x / this.state.drawZoom);
    const cellY = Math.floor(y / this.state.drawZoom);
    const startX = cellX * this.state.drawZoom;
    const startY = cellY * this.state.drawZoom; 
    const color = sourceCtx.getImageData(startX,startY,1,1).data;
      pixelRgbArray.push(color);
    }
  }
const canvas = document.getElementById('pal_final');
 canvas.width = this.state.widthCanvasDraw;
canvas.height = this.state.heightCanvasDraw; 

const ctx = canvas.getContext("2d");
const rgbMatrix = this.createPixelRGBMatrixFromRGBArray(pixelRgbArray,this.state.widthCanvasDraw,this.state.heightCanvasDraw);


for(let xx = 0; xx<this.state.widthCanvasDraw; xx++) {
  for(let yy = 0; yy<this.state.heightCanvasDraw; yy++) {
    

  const currentColor = rgbMatrix[xx][yy];
  ctx.fillStyle = currentColor;
  if(currentColor==="rgba(0,0,0,0)") {

    ctx.clearRect(xx, yy, 1, 1);
  } else {

      ctx.fillRect(xx, yy, 1, 1);
  }
  
}
}

/* await this.createDMCDataDrawing(); */
await this.createDMCDataDrawingCanvas();
var dmcData = this.state.dmcArrayDraw;
this.setState({
  dmcArray: dmcData,
  wasInAdvancedEdit: true,
  displayStyleDonePage: {
    visibility: 'visible',
    position: 'static'
  }, 
})
this.handleToggleDonePage();
}



handleFetchColorsReplace = () => {
  const redParentDivReplace = document.getElementById("redWrapperReplace");
  if(!redParentDivReplace) {return;}
  if(redParentDivReplace.children.length>2) {
    return;
  } 
  var redObject = this.handleFormatColors("red");
    var yellowObject = this.handleFormatColors("yellow");
    var greenObject = this.handleFormatColors("green");
    var blueObject = this.handleFormatColors("blue");
    var violetObject = this.handleFormatColors("violet");
    var brownObject = this.handleFormatColors("brown");
    var grayObject = this.handleFormatColors("gray");
  const yellowParentDivReplace = document.getElementById("yellowWrapperReplace");
  const greenParentDivReplace = document.getElementById("greenWrapperReplace");
  const blueParentDivReplace = document.getElementById("blueWrapperReplace");
  const violetParentDivReplace = document.getElementById("violetWrapperReplace");
  const brownParentDivReplace = document.getElementById("brownWrapperReplace");
  const grayParentDivReplace = document.getElementById("grayWrapperReplace");
  const noColorDivReplace = document.getElementById("noColorWrapperReplace");
  Object.keys(redObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]==="Red") {
      var newDivColor = document.createElement("div");
      redParentDivReplace.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(redObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]!=="Red") {
      var newDivColor = document.createElement("div");
      redParentDivReplace.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(yellowObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]==="Lemon") {
    var newDivColor = document.createElement("div");
    yellowParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(yellowObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]!=="Lemon") {
    var newDivColor = document.createElement("div");
    yellowParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(greenObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]==="Green") {
    var newDivColor = document.createElement("div");
    greenParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(greenObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]!=="Green") {
    var newDivColor = document.createElement("div");
    greenParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(blueObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]==="Blue Dark") {
    var newDivColor = document.createElement("div");
    blueParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(blueObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]!=="Blue Dark") {
    var newDivColor = document.createElement("div");
    blueParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(violetObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]==="Blueberry") {
    var newDivColor = document.createElement("div");
    violetParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(violetObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]!=="Blueberry") {
    var newDivColor = document.createElement("div");
    violetParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(brownObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]==="Brown Medium") {
    var newDivColor = document.createElement("div");
    brownParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(brownObject).forEach(key => {
    if(DMCJSONColors[key]["Floss Name"]!=="Brown Medium") {
    var newDivColor = document.createElement("div");
    brownParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(grayObject).forEach(key => {
    if((DMCJSONColors[key]["Floss Name"]==="White" || DMCJSONColors[key]["Floss Name"]==="Black" ||
    DMCJSONColors[key]["Floss Name"]==="Snow White")) {
    var newDivColor = document.createElement("div");
    grayParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
  Object.keys(grayObject).forEach(key => {
    if((DMCJSONColors[key]["Floss Name"]!=="White" && DMCJSONColors[key]["Floss Name"]!=="Black" &&
    DMCJSONColors[key]["Floss Name"]!=="Snow White")) {
    var newDivColor = document.createElement("div");
    grayParentDivReplace.appendChild(newDivColor);
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
    newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
    }
  });
    //Add no color option
    var newDivColor = document.createElement("div");
    var newDivColorX = document.createElement("span");
    noColorDivReplace.appendChild(newDivColor);
    newDivColor.appendChild(newDivColorX);
    newDivColorX.innerHTML = "x";
    newDivColorX.style.color = "var(--primary)";
    newDivColorX.style.pointerEvents = "none";
    newDivColor.classList.add("colorPickerDiv");
    newDivColor.setAttribute("title","No Color:00000");
    newDivColor.style.display = "flex";
    newDivColor.style.justifyContent = "center";
    newDivColor.style.alignItems = "center";
    newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
}



  handleFetchColors = () => {
    

    const colorDuplicatesArray = [];
    const redParentDiv = document.getElementById("redWrapper");
    if(redParentDiv.children.length>2) {
      return;
    } 
    var redObject = this.handleFormatColors("red");
    const yellowParentDiv = document.getElementById("yellowWrapper");
    var yellowObject = this.handleFormatColors("yellow");
    const greenParentDiv = document.getElementById("greenWrapper");
    var greenObject = this.handleFormatColors("green");
    const blueParentDiv = document.getElementById("blueWrapper");
    var blueObject = this.handleFormatColors("blue");
    const violetParentDiv = document.getElementById("violetWrapper");
    var violetObject = this.handleFormatColors("violet");
    const brownParentDiv = document.getElementById("brownWrapper");
    var brownObject = this.handleFormatColors("brown");
    const grayParentDiv = document.getElementById("grayWrapper");
    var grayObject = this.handleFormatColors("gray");
    Object.keys(redObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]==="Red") {
        var newDivColor = document.createElement("div");
        redParentDiv.appendChild(newDivColor);
        newDivColor.classList.add("colorPickerDiv");
        newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
        newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
        newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(redObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]!=="Red") {
        var newDivColor = document.createElement("div");
        redParentDiv.appendChild(newDivColor);
        newDivColor.classList.add("colorPickerDiv");
        newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
        newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
        newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(yellowObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]==="Lemon") {
      var newDivColor = document.createElement("div");
      yellowParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(yellowObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]!=="Lemon") {
      var newDivColor = document.createElement("div");
      yellowParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(greenObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]==="Green") {
      var newDivColor = document.createElement("div");
      greenParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(greenObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]!=="Green") {
      var newDivColor = document.createElement("div");
      greenParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(blueObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]==="Blue Dark") {
      var newDivColor = document.createElement("div");
      blueParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(blueObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]!=="Blue Dark") {
      var newDivColor = document.createElement("div");
      blueParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(violetObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]==="Blueberry") {
      var newDivColor = document.createElement("div");
      violetParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(violetObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]!=="Blueberry") {
      var newDivColor = document.createElement("div");
      violetParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(brownObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]==="Brown Medium") {
      var newDivColor = document.createElement("div");
      brownParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(brownObject).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]!=="Brown Medium") {
      var newDivColor = document.createElement("div");
      brownParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(grayObject).forEach(key => {
      if((DMCJSONColors[key]["Floss Name"]==="White" || DMCJSONColors[key]["Floss Name"]==="Black" ||
      DMCJSONColors[key]["Floss Name"]==="Snow White")) {
      var newDivColor = document.createElement("div");
      grayParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
    Object.keys(grayObject).forEach(key => {
      if((DMCJSONColors[key]["Floss Name"]!=="White" && DMCJSONColors[key]["Floss Name"]!=="Black" &&
      DMCJSONColors[key]["Floss Name"]!=="Snow White")) {
      var newDivColor = document.createElement("div");
      grayParentDiv.appendChild(newDivColor);
      newDivColor.classList.add("colorPickerDiv");
      newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"] + ':'+key);
      newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
      newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
    });
/*     Object.keys(DMCJSONColors).forEach(key => {
      var exists = false;
      Object.keys(redObject).forEach(k => {

      
        
        if(k===key)
        {
          if(colorDuplicatesArray[k]!== undefined) {
            var countTemp = colorDuplicatesArray[k].count+1
            colorDuplicatesArray[k] = {count: countTemp, name: DMCJSONColors[k]["Floss Name"], category: "red"};
          } else {
            colorDuplicatesArray[k] = {count: 1, name: DMCJSONColors[k]["Floss Name"], category: "red"};
          }
          exists = true;
        }
      });
      Object.keys(yellowObject).forEach(k => {

        if(k===key)
        {
          if(colorDuplicatesArray[k]!== undefined) {
            var countTemp = colorDuplicatesArray[k].count+1
            colorDuplicatesArray[k] = {count: countTemp, name: DMCJSONColors[k]["Floss Name"], category: "yellow"};
          } else {
            colorDuplicatesArray[k] = {count: 1, name: DMCJSONColors[k]["Floss Name"], category: "yellow"};
          }
          exists = true;
        }
      });
      Object.keys(greenObject).forEach(k => {

        if(k===key)
        {
          if(colorDuplicatesArray[k]!== undefined) {
            var countTemp = colorDuplicatesArray[k].count+1
            colorDuplicatesArray[k] = {count: countTemp, name: DMCJSONColors[k]["Floss Name"], category: "green"};
          } else {
            colorDuplicatesArray[k] = {count: 1, name: DMCJSONColors[k]["Floss Name"], category: "green"};
          }
          exists = true;
        }
      });
      Object.keys(blueObject).forEach(k => {

        if(k===key)
        {
          if(colorDuplicatesArray[k]!== undefined) {
            var countTemp = colorDuplicatesArray[k].count+1
            colorDuplicatesArray[k] = {count: countTemp, name: DMCJSONColors[k]["Floss Name"], category: "blue"};
          } else {
            colorDuplicatesArray[k] = {count: 1, name: DMCJSONColors[k]["Floss Name"], category: "blue"};
          }
          exists = true;
        }
      });
      Object.keys(violetObject).forEach(k => {

        if(k===key)
        {
          if(colorDuplicatesArray[k]!== undefined) {
            var countTemp = colorDuplicatesArray[k].count+1
            colorDuplicatesArray[k] = {count: countTemp, name: DMCJSONColors[k]["Floss Name"], category: "violet"};
          } else {
            colorDuplicatesArray[k] = {count: 1, name: DMCJSONColors[k]["Floss Name"], category: "violet"};
          }
          exists = true;
        }
      });
      Object.keys(brownObject).forEach(k => {

        if(k===key)
        {
          if(colorDuplicatesArray[k]!== undefined) {
            var countTemp = colorDuplicatesArray[k].count+1
            colorDuplicatesArray[k] = {count: countTemp, name: DMCJSONColors[k]["Floss Name"], category: "brown"};
          } else {
            colorDuplicatesArray[k] = {count: 1, name: DMCJSONColors[k]["Floss Name"], category: "brown"};
          }
          exists = true;
        }
      });
      Object.keys(grayObject).forEach(k => {

        if(k===key)
        {
          if(colorDuplicatesArray[k]!== undefined) {
            var countTemp = colorDuplicatesArray[k].count+1
            colorDuplicatesArray[k] = {count: countTemp, name: DMCJSONColors[k]["Floss Name"], category: "gray"};
          } else {
            colorDuplicatesArray[k] = {count: 1, name: DMCJSONColors[k]["Floss Name"], category: "gray"};
          }
          exists = true;
        }
      });



      if(exists===false) {
        var newDivColor = document.createElement("div");
        parentDiv.appendChild(newDivColor);
        newDivColor.classList.add("colorPickerDiv");
        newDivColor.setAttribute("title",DMCJSONColors[key]["Floss Name"]);
        newDivColor.style.background = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
        newDivColor.addEventListener("click", (e)=> {this.handleColorPickClickCanvas(e)})
      }
      
    });
    //Check for duplicates!
    Object.keys(colorDuplicatesArray).forEach(k => {
      if(colorDuplicatesArray[k].count>1) {
        console.log(colorDuplicatesArray[k].name + " - "+ colorDuplicatesArray[k].category);
      }
    }) */

    //console.log(Object.keys(DMCJSONColors).length);
  }

  handleFormatColors = (color) => {
    var colorObject = {};
    switch(color) {
      case "green":
        Object.keys(DMCJSONColors).forEach(key => {
          if((DMCJSONColors[key]["Floss Name"].includes("Green") || DMCJSONColors[key]["Floss Name"].includes("Grn") 
          || DMCJSONColors[key]["Floss Name"].includes("Jade") || DMCJSONColors[key]["Floss Name"].includes("Chartreuse")
          || DMCJSONColors[key]["Floss Name"].includes("Blue Green") || DMCJSONColors[key]["Floss Name"].includes("Aquamarine")) 
          ) {
            colorObject[key] = {
              "Floss Name": DMCJSONColors[key]["Floss Name"],
              Red: DMCJSONColors[key].Red,
              Green: DMCJSONColors[key].Green,
              Blue: DMCJSONColors[key].Blue,
            }
          }
        });
        return colorObject;
        break;
        case "red":
        Object.keys(DMCJSONColors).forEach(key => {
          if((DMCJSONColors[key]["Floss Name"].includes("Red")|| DMCJSONColors[key]["Floss Name"].includes("Garnet")
          || DMCJSONColors[key]["Floss Name"].includes("Coral")|| DMCJSONColors[key]["Floss Name"].includes("Salmon")
          || DMCJSONColors[key]["Floss Name"].includes("Cranberry")|| DMCJSONColors[key]["Floss Name"].includes("Carnation")
          || DMCJSONColors[key]["Floss Name"].includes("Rose")|| DMCJSONColors[key]["Floss Name"].includes("Pink")
          || DMCJSONColors[key]["Floss Name"].includes("Peach")|| DMCJSONColors[key]["Floss Name"].includes("Raspberry")
          || DMCJSONColors[key]["Floss Name"].includes("Melon")
          || DMCJSONColors[key]["Floss Name"].includes("Mauve") || DMCJSONColors[key]["Floss Name"].includes("Burnt Orange Bright")
          || DMCJSONColors[key]["Floss Name"].includes("Geranium") || DMCJSONColors[key]["Floss Name"].includes("Apricot")
          || DMCJSONColors[key]["Floss Name"].includes("Shrimp") || DMCJSONColors[key]["Floss Name"].includes("Terra Cotta")) 
          && !DMCJSONColors[key]["Floss Name"].includes("Yellow")&& !DMCJSONColors[key]["Floss Name"].includes("Rose Dark") &&
          !DMCJSONColors[key]["Floss Name"].includes("Terra Cotta Dark") && !DMCJSONColors[key]["Floss Name"].includes("Rosewood")) {
            colorObject[key] = {
              "Floss Name": DMCJSONColors[key]["Floss Name"],
              Red: DMCJSONColors[key].Red,
              Green: DMCJSONColors[key].Green,
              Blue: DMCJSONColors[key].Blue,
            }
          }
          else if(DMCJSONColors[key]["Floss Name"].includes("Dusty Rose Dark")){
            colorObject[key] = {
              "Floss Name": DMCJSONColors[key]["Floss Name"],
              Red: DMCJSONColors[key].Red,
              Green: DMCJSONColors[key].Green,
              Blue: DMCJSONColors[key].Blue,
            }
          }
        });
        return colorObject;
        break;
        case "yellow":
          Object.keys(DMCJSONColors).forEach(key => {
            if((DMCJSONColors[key]["Floss Name"].includes("Canary") || DMCJSONColors[key]["Floss Name"].includes("Pumpkin")
            || DMCJSONColors[key]["Floss Name"].includes("Lemon") || DMCJSONColors[key]["Floss Name"].includes("Orange")
            || DMCJSONColors[key]["Floss Name"].includes("Straw") || DMCJSONColors[key]["Floss Name"].includes("Topaz Light")
            || DMCJSONColors[key]["Floss Name"].includes("Topaz Very Light") || DMCJSONColors[key]["Floss Name"].includes("Topaz Medium Light")
            || DMCJSONColors[key]["Floss Name"].includes("Tangerine") || DMCJSONColors[key]["Floss Name"].includes("Yellow")
            || DMCJSONColors[key]["Floss Name"].includes("Copper") || DMCJSONColors[key]["Floss Name"].includes("Autumn Gold")) && 
            !DMCJSONColors[key]["Floss Name"].includes("Copper Medium") &&
            !DMCJSONColors[key]["Floss Name"].includes("Red") && !DMCJSONColors[key]["Floss Name"].includes("Green") &&
            !DMCJSONColors[key]["Floss Name"].includes("Beige") && !DMCJSONColors[key]["Floss Name"].includes("Burnt Orange Bright")  ) {
              colorObject[key] = {
                "Floss Name": DMCJSONColors[key]["Floss Name"],
                Red: DMCJSONColors[key].Red,
                Green: DMCJSONColors[key].Green,
                Blue: DMCJSONColors[key].Blue,
              }
            }
          });
          return colorObject;
          break;
        case "blue":
        Object.keys(DMCJSONColors).forEach(key => {
          if((DMCJSONColors[key]["Floss Name"].includes("Blue")|| DMCJSONColors[key]["Floss Name"].includes("Turquoise") ||
          DMCJSONColors[key]["Floss Name"].includes("Wedgewood")) && 
          !DMCJSONColors[key]["Floss Name"].includes("Green") && !DMCJSONColors[key]["Floss Name"].includes("Blueberry")
          && !DMCJSONColors[key]["Floss Name"].includes("Violet")) {
            colorObject[key] = {
              "Floss Name": DMCJSONColors[key]["Floss Name"],
              Red: DMCJSONColors[key].Red,
              Green: DMCJSONColors[key].Green,
              Blue: DMCJSONColors[key].Blue,
            }
          }
        });
        return colorObject;
        break;
        case "violet":
          Object.keys(DMCJSONColors).forEach(key => {
            if((DMCJSONColors[key]["Floss Name"].includes("Violet") || DMCJSONColors[key]["Floss Name"].includes("Fuschia") ||
            DMCJSONColors[key]["Floss Name"].includes("Grape") || DMCJSONColors[key]["Floss Name"].includes("Eggplant")
            || DMCJSONColors[key]["Floss Name"].includes("Apple Blossom") || DMCJSONColors[key]["Floss Name"].includes("Plum")
            || DMCJSONColors[key]["Floss Name"].includes("Lavender") || DMCJSONColors[key]["Floss Name"].includes("Blueberry")) 
            && !DMCJSONColors[key]["Floss Name"].includes("Yellow") && !DMCJSONColors[key]["Floss Name"].includes("Lavender Blue")) {
              colorObject[key] = {
                "Floss Name": DMCJSONColors[key]["Floss Name"],
                Red: DMCJSONColors[key].Red,
                Green: DMCJSONColors[key].Green,
                Blue: DMCJSONColors[key].Blue,
              }
            }
          });
          return colorObject;
          break;
        case "brown":
        Object.keys(DMCJSONColors).forEach(key => {
          if((DMCJSONColors[key]["Floss Name"].includes("Brown")|| DMCJSONColors[key]["Floss Name"].includes("Mocha") ||
          DMCJSONColors[key]["Floss Name"].includes("Mustard") || DMCJSONColors[key]["Floss Name"].includes("Beige") || 
          DMCJSONColors[key]["Floss Name"].includes("Cocoa") || DMCJSONColors[key]["Floss Name"].includes("Driftwood") || DMCJSONColors[key]["Floss Name"].includes("Golden Olive")
          || DMCJSONColors[key]["Floss Name"].includes("Topaz") || DMCJSONColors[key]["Floss Name"].includes("Old Gold")
          || DMCJSONColors[key]["Floss Name"].includes("Desert Sand") || DMCJSONColors[key]["Floss Name"].includes("Mahogany")
          || DMCJSONColors[key]["Floss Name"].includes("Tan") || DMCJSONColors[key]["Floss Name"].includes("Copper Medium")
          || DMCJSONColors[key]["Floss Name"].includes("Terra Cotta Dark") || DMCJSONColors[key]["Floss Name"].includes("Tawny")
          || DMCJSONColors[key]["Floss Name"].includes("Alizarin") || DMCJSONColors[key]["Floss Name"].includes("Rosewood"))
          && !DMCJSONColors[key]["Floss Name"].includes("Topaz Light") && !DMCJSONColors[key]["Floss Name"].includes("Topaz Very Light") &&
          !DMCJSONColors[key]["Floss Name"].includes("Tangerine") && !DMCJSONColors[key]["Floss Name"].includes("Topaz Medium Light")) {
            colorObject[key] = {
              "Floss Name": DMCJSONColors[key]["Floss Name"],
              Red: DMCJSONColors[key].Red,
              Green: DMCJSONColors[key].Green,
              Blue: DMCJSONColors[key].Blue,
            }
          }
        });
        return colorObject;
        break;
        case "gray":
        Object.keys(DMCJSONColors).forEach(key => {
          if((DMCJSONColors[key]["Floss Name"].includes("Gray")|| DMCJSONColors[key]["Floss Name"].includes("Tin")
          || DMCJSONColors[key]["Floss Name"].includes("White") || DMCJSONColors[key]["Floss Name"].includes("Cream") 
          || DMCJSONColors[key]["Floss Name"].includes("Black") || DMCJSONColors[key]["Floss Name"].includes("Ecru")
          || DMCJSONColors[key]["Floss Name"].includes("Pewter") || DMCJSONColors[key]["Floss Name"].includes("Rose Dark")) &&
          !DMCJSONColors[key]["Floss Name"].includes("Green") 
          && !DMCJSONColors[key]["Floss Name"].includes("Blue") && !DMCJSONColors[key]["Floss Name"].includes("Beige")
          && !DMCJSONColors[key]["Floss Name"].includes("Brown") && !DMCJSONColors[key]["Floss Name"].includes("Lavender")
          && !DMCJSONColors[key]["Floss Name"].includes("Violet") && !DMCJSONColors[key]["Floss Name"].includes("Dusty Rose Dark")
          && !DMCJSONColors[key]["Floss Name"].includes("Avocado")) {
            colorObject[key] = {
              "Floss Name": DMCJSONColors[key]["Floss Name"],
              Red: DMCJSONColors[key].Red,
              Green: DMCJSONColors[key].Green,
              Blue: DMCJSONColors[key].Blue,
            }
          }
        });
        return colorObject;
        break;
        default:
          Object.keys(DMCJSONColors).forEach(key => {
            if(DMCJSONColors[key]["Floss Name"].includes("Green") || DMCJSONColors[key]["Floss Name"].includes("Grn")) {
              colorObject[key] = {
                "Floss Name": DMCJSONColors[key]["Floss Name"],
                Red: DMCJSONColors[key].Red,
                Green: DMCJSONColors[key].Green,
                Blue: DMCJSONColors[key].Blue,
              }
            }
          });
          return colorObject;
        break;
    }
    
    
  }



/*   handleColorPickClick = (e) => {
     if (e) e.preventDefault()
    var color = e.target.style.background;
    var bColor = this.makeRgbStringDarkerOrLighter(color, 20)
    var dColor = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount);
    var name = e.target.title;
    var code = '310';
    Object.keys(DMCJSONColors).forEach(key => {
      if(DMCJSONColors[key]["Floss Name"]===name) {
        code = key;
      }
    });
    if(this.state.replaceMode===false) 
    {
    this.setState({
      drawColor: color,
      drawColorDark: dColor,
      drawColorBright: bColor,
      drawColorName: name,
      drawColorCode: code
    })
    } else {

      const pixelsInPattern = document.querySelectorAll(".drawDiv");
      pixelsInPattern.forEach(pixel => {
        var pixelColor = null;
        if(this.state.stitchMode===true) {
          pixelColor = pixel.style.color.replace(/ /g,'');
        } else {
          pixelColor = pixel.style.background.replace(/ /g,'');
        }
        var cToReplace = this.state.colorToReplace.replace(/ /g,'');
        if(pixelColor!==cToReplace) {
        return;
        }
        var state = this.state.undo+':'+pixelColor;
        if(pixel.getAttribute("data-id")!== null) {
          if (!pixel.getAttribute("data-id").includes(this.state.undo+':')) {
            pixel.setAttribute("data-id",`${pixel.getAttribute("data-id")}`+state+' ');
          }
        } else {
          pixel.setAttribute("data-id",state+' ');
        }
        if(this.state.stitchMode===true) {
          var bCol = this.makeRgbStringDarkerOrLighter(color, 20)
          var dCol = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount);
          pixel.style.background = '';
          pixel.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
          pixel.style.color = color;
          
        } else {
          pixel.style.background = color;
          pixel.style.color = color;
        }
        
        
      });
      this.setState({
        colorToReplace: color
      },(e)=> {
        this.createDMCDataDrawingCanvas(e); */
/*         this.handleMouseUpDrawing(e);
        this.createDMCDataDrawing(e); */
/*       })
    } 
  } */

  handleColorPickerClick = (e) => {
/*     if (e) e.preventDefault()
    var color = null;
    if(this.state.stitchMode===true) {
      color = e.target.style.color;
    } else {
      color = e.target.style.background;
    }
    var bColor = this.makeRgbStringDarkerOrLighter(color, 20)
    var dColor = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount);
    var rgb = color.replace('rgb(','');
    rgb = rgb.replace(')','');
    rgb = rgb.replace(' ','');
    rgb = rgb.split(',');

    var name = undefined;
    var code = undefined;
    Object.keys(DMCJSONColors).forEach(key => {
      if(DMCJSONColors[key].Red===parseInt(rgb[0]) && DMCJSONColors[key].Green === parseInt(rgb[1]) && DMCJSONColors[key].Blue === parseInt(rgb[2])) {
        code = key;
        name = DMCJSONColors[key]["Floss Name"];
      }
    });
    this.setState({
      drawColor: color,
      drawColorDark: dColor,
      drawColorBright: bColor,
      drawColorName: name,
      drawColorCode: code
    }) */
  }

  handleColorPickerClickCanvas = (e, cRounded) => {
    if (e) e.preventDefault()
    let color = cRounded.style.backgroundColor;
    //console.log("you clicked");
    //console.log(cRounded.style.backgroundColor);
    var bColor = this.makeRgbStringDarkerOrLighter(color, stitchPercentBright)
    var dColor = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount); 
    var rgb = color.replace('rgb(','');
    rgb = rgb.replace(')','');
    rgb = rgb.replace(' ','');
    rgb = rgb.split(',');

    var name = undefined;
    var code = undefined;
    Object.keys(DMCJSONColors).forEach(key => {
      if(DMCJSONColors[key].Red===parseInt(rgb[0]) && DMCJSONColors[key].Green === parseInt(rgb[1]) && DMCJSONColors[key].Blue === parseInt(rgb[2])) {
        code = key;
        name = DMCJSONColors[key]["Floss Name"];
      }
    });
    this.setState({
      madeChangeDrawCanvas: false,
      drawColor: color,
       drawColorDark: dColor,
      drawColorBright: bColor, 
      drawColorName: name,
      drawColorCode: code
    })
  }

  handleColorPickClickCanvas = (e) => {
    if (e) e.preventDefault()
    //console.log("I am being run here!");
    var didReplace = false;
    var color = e.target.style.background;
    //console.log(e.target);
    if(e.target.title=== "No Color:00000") {
      color = "rgba(0,0,0,0)"
    }
   
   var bColor = this.makeRgbStringDarkerOrLighter(color, stitchPercentBright)
   var dColor = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount);
   var name = e.target.title;
   var code = '310';
   Object.keys(DMCJSONColors).forEach(key => {
     if(DMCJSONColors[key]["Floss Name"]===name) {
       code = key;
     }
   });
   if(this.state.replaceMode===false) 
   {
   this.setState({
     drawColor: color,
     drawColorDark: dColor,
     drawColorBright: bColor,
     drawColorName: name,
     drawColorCode: code
   })
   } else {
    const canvas = document.getElementById("drawerCanvas");
    const ctx = canvas.getContext("2d");
    const palWidth = this.state.widthCanvasDraw;
    const palHeight = this.state.heightCanvasDraw;
    let pixelArr = [];
    for(let yy=0; yy<palHeight; yy++) {
      for(let xx=0; xx<palWidth; xx++) {

          const pixel = ctx.getImageData(xx*this.state.drawZoom, yy*this.state.drawZoom, 1, 1).data;
          const rgb = [pixel[0],pixel[1],pixel[2],pixel[3]];
          pixelArr.push(rgb);
      } 
  }
  const rgbMatrix = this.createPixelRGBMatrixFromRGBArray(pixelArr,palWidth,palHeight);
  for(let xx = 0; xx<palWidth; xx++) {
    for(let yy = 0; yy<palHeight; yy++) {
      const currentColor = rgbMatrix[xx][yy];
/*       console.log(this.state.colorToReplace); */
      var cToReplace = this.state.colorToReplace.replace(/ /g,'');

        if(currentColor===cToReplace) {

        didReplace = true;
    ctx.fillStyle = color;
    if(currentColor==="rgba(0,0,0,0)") {
      ctx.clearRect(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), this.state.drawZoom, this.state.drawZoom);
      let col = ctx.getImageData(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), 1, 1).data;
      color = "rgba("+col[0]+","+ col[1]+","+ col[2]+","+ col[3]+")";
    } else {

      if(this.state.stitchMode===true) {      
        if(color==="rgba(0,0,0,0)") {
          ctx.clearRect(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), this.state.drawZoom, this.state.drawZoom);
        } else {

        
        var drawZm = this.state.drawZoom;   
        const img = document.getElementById('stitchImage');
        ctx.clearRect((xx)*drawZm,(yy)*drawZm,drawZm,drawZm);
        
        ctx.beginPath();
/*         ctx.filter = "saturate(120%)"; */
        ctx.fillStyle = bColor;
        ctx.ellipse((xx)*drawZm+ (drawZm/2),(yy)*drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
        ctx.ellipse((xx)*drawZm+ (drawZm/2),(yy)*drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
        ctx.fill();
        ctx.drawImage(img, (xx)*drawZm, (yy)*drawZm, drawZm, drawZm); 
 /*        ctx.filter = "saturate(100%)"; */
        ctx.fillStyle = color;
          ctx.fillRect((xx)*drawZm,(yy)*drawZm,1,1);
          ctx.fillRect((xx)*drawZm+drawZm-1,(yy)*drawZm,1,1);
          ctx.fillRect((xx)*drawZm+drawZm-1,(yy)*drawZm+drawZm-1,1,1);
          ctx.fillRect((xx)*drawZm,(yy)*drawZm+drawZm-1,1,1);
        }
        
        
    } else {
      if(color==="rgba(0,0,0,0)") {
        ctx.clearRect(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), this.state.drawZoom, this.state.drawZoom);
      } else {
        ctx.fillRect(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), this.state.drawZoom, this.state.drawZoom);
      }
        
      }

      
      let col = ctx.getImageData(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), 1, 1).data;
      color = "rgba("+col[0]+","+ col[1]+","+ col[2]+","+ col[3]+")";
    }
  }
    
  }
}
if(didReplace===true) {
/* let undoStateColorReplaceArr = this.state.drawCanvasUndoColorReplacerStates;

let undoCounter = this.state.undoColorReplacerCounter;
console.log(undoCounter);
console.log(undoStateColorReplaceArr.length);
if(undoStateColorReplaceArr.length!==0  && undoCounter<undoStateColorReplaceArr.length && undoCounter!==undoStateColorReplaceArr.length ) {
  while(undoStateColorReplaceArr.length-1!==undoCounter) {
    //Delete all the states after the current state when creating a new state if you have undone an action
    undoStateColorReplaceArr.pop();
  }
} 
undoStateColorReplaceArr.push(cToReplace); */

/* undoCounter++; */
     this.setState({
/*       undoColorReplacerCounter: undoCounter,
      drawCanvasUndoColorReplacerStates: undoStateColorReplaceArr, */
      colorToReplace: color,
      madeChangeDrawCanvas: true
     },(e)=> {
      this.handleMouseUpDrawingCanvas(e);
      this.createDMCDataDrawingCanvas(e); 
     })
   }
  }
 }


  handleSelectColorFromListCanvas = (code) => {
    //console.log("I am being run!");
    var color = 'rgba(0,0,0,255)';
    var name = 'Black';
    Object.keys(DMCJSONColors).forEach(key => {
      if(key===code) {
        color = "rgba("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+",255)";
        name = DMCJSONColors[key]["Floss Name"];
      }
    });
    var bColor = this.makeRgbStringDarkerOrLighter(color, stitchPercentBright)
    var dColor = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount);
    if(this.state.replaceMode===false) 
    {
    this.setState({
      drawColor: color,
      drawColorDark: dColor,
      drawColorBright: bColor,
      drawColorName: name,
      drawColorCode: code
    })
  } else {
    const canvas = document.getElementById("drawerCanvas");
    const ctx = canvas.getContext("2d");
    const palWidth = this.state.widthCanvasDraw;
    const palHeight = this.state.heightCanvasDraw;
    let pixelArr = [];
    for(let yy=0; yy<palHeight; yy++) {
      for(let xx=0; xx<palWidth; xx++) {

          const pixel = ctx.getImageData(xx*this.state.drawZoom, yy*this.state.drawZoom, 1, 1).data;
          const rgb = [pixel[0],pixel[1],pixel[2],pixel[3]];
          pixelArr.push(rgb);
      } 
  }
  const rgbMatrix = this.createPixelRGBMatrixFromRGBArray(pixelArr,palWidth,palHeight);
  for(let xx = 0; xx<palWidth; xx++) {
    for(let yy = 0; yy<palHeight; yy++) {
      const currentColor = rgbMatrix[xx][yy];
      var cToReplace = this.state.colorToReplace.replace(/ /g,'');
        if(currentColor===cToReplace) {
        
    ctx.fillStyle = color;
    if(currentColor==="rgba(0,0,0,0)") {
      ctx.clearRect(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), this.state.drawZoom, this.state.drawZoom);
      let col = ctx.getImageData(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), 1, 1).data;
      color = "rgba("+col[0]+","+ col[1]+","+ col[2]+","+ col[3]+")";
    } else {
      if(this.state.stitchMode===true) {      
        var drawZm = this.state.drawZoom;   
        const img = document.getElementById('stitchImage');
        ctx.clearRect((xx)*drawZm,(yy)*drawZm,drawZm,drawZm);
        
        ctx.beginPath();
  /*       ctx.filter = "saturate(120%)"; */
        ctx.fillStyle = bColor;
        ctx.ellipse((xx)*drawZm+ (drawZm/2),(yy)*drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
        ctx.ellipse((xx)*drawZm+ (drawZm/2),(yy)*drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
        ctx.fill();
        ctx.drawImage(img, (xx)*drawZm, (yy)*drawZm, drawZm, drawZm); 
/*         ctx.filter = "saturate(100%)"; */
        ctx.fillStyle = color;
        ctx.fillRect((xx)*drawZm,(yy)*drawZm,1,1);
        ctx.fillRect((xx)*drawZm+drawZm-1,(yy)*drawZm,1,1);
        ctx.fillRect((xx)*drawZm+drawZm-1,(yy)*drawZm+drawZm-1,1,1);
        ctx.fillRect((xx)*drawZm,(yy)*drawZm+drawZm-1,1,1);
    }else {
        ctx.fillRect(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), this.state.drawZoom, this.state.drawZoom);
      }
      
      let col = ctx.getImageData(((xx)*this.state.drawZoom), ((yy)*this.state.drawZoom), 1, 1).data;
      color = "rgba("+col[0]+","+ col[1]+","+ col[2]+","+ col[3]+")";
    }
  }
    
  }
}
/* let undoStateColorReplaceArr = this.state.drawCanvasUndoColorReplacerStates;

let undoCounter = this.state.undoColorReplacerCounter;
if(undoStateColorReplaceArr.length!==0 && undoCounter<undoStateColorReplaceArr.length && undoCounter!==undoStateColorReplaceArr.length) {
  while(undoStateColorReplaceArr.length-1!==undoCounter) {
    //Delete all the states after the current state when creating a new state if you have undone an action
    undoStateColorReplaceArr.pop();
  }
  }
undoStateColorReplaceArr.push(cToReplace); */
    this.setState({
/*       undoColorReplacerCounter: undoCounter,
      drawCanvasUndoColorReplacerStates: undoStateColorReplaceArr, */
      colorToReplace: color,
      madeChangeDrawCanvas: true
    },(e)=> {
         this.handleMouseUpDrawingCanvas(e);
      this.createDMCDataDrawingCanvas(e); 
    })
  }
  }

/*   handleSelectColorFromList = (code) => {
    var color = 'rgb(0,0,0)';
    var name = '';
    Object.keys(DMCJSONColors).forEach(key => {
      if(key===code) {
        color = "rgb("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+")";
        name = DMCJSONColors[key]["Floss Name"];
      }
    });
    var bColor = this.makeRgbStringDarkerOrLighter(color, 20)
    var dColor = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount);
    if(this.state.replaceMode===false) 
    {
    this.setState({
      drawColor: color,
      drawColorDark: dColor,
      drawColorBright: bColor,
      drawColorName: name,
      drawColorCode: code
    })
    } else {
      const pixelsInPattern = document.querySelectorAll(".drawDiv");
      pixelsInPattern.forEach(pixel => {
        var pixelColor = null;
        if(this.state.stitchMode===true) {
          pixelColor = pixel.style.color.replace(/ /g,'');
        } else {
          pixelColor = pixel.style.background.replace(/ /g,'');
        }
        var cToReplace = this.state.colorToReplace.replace(/ /g,'');
        if(pixelColor!==cToReplace) {
        return;
        }
        var state = this.state.undo+':'+pixelColor;
        if(pixel.getAttribute("data-id")!== null) {
          if (!pixel.getAttribute("data-id").includes(this.state.undo+':')) {
            pixel.setAttribute("data-id",`${pixel.getAttribute("data-id")}`+state+' ');
          }
        } else {
          pixel.setAttribute("data-id",state+' ');
        }
        if(this.state.stitchMode===true) {
          var bCol = this.makeRgbStringDarkerOrLighter(color, 20)
          var dCol = this.makeRgbStringDarkerOrLighter(color,stitchDarkAmount);
          pixel.style.background = '';
          pixel.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
          pixel.style.color = color;
        } else {
          pixel.style.background = color;
          pixel.style.color = color;
        }
        
      });
      this.setState({
        colorToReplace: color
      },(e)=> { */
/*         this.handleMouseUpDrawing(e);
        this.createDMCDataDrawing(e); */
/*       })
    }
  }
 */


  handleResetDrawCanvas = () => {
/*     const drawCanvasParent = document.getElementById("drawewr") */
  }


  handleOpenAdvancedEditSavedPattern = async (e) => {
    
    var id = "pal_final";
    var element = document.getElementById(id);
    var quantDiv = document.getElementById("quantDiv");
    if (typeof (element) !== 'undefined' && element !== null) {
      element.remove()
    }
    var child = null;

    var can = document.createElement("canvas");
    var widthValue = this.state.cropWidth;
    var heightValue = this.state.cropHeight;
    this.setState({
      widthInput: widthValue,
      madeChangeSavedPattern: false,
      advancedMode: 'draw',

      replaceMode: false,
      showLoading: false,
      stitchMode: false
    })
    id && can.setAttribute("id", id);
    can.width = widthValue;
    can.height = heightValue;

    if(quantDiv!==null) {
      child = quantDiv.appendChild(can);
    } else {
      child = document.body.appendChild(can);
    }
    let propWidth = widthValue;
    let propHeight = heightValue;
    let ratio;
    if (widthValue > heightValue) {
      ratio = widthValue / heightValue;
      propWidth = 100;
      propHeight = 100 * (1 / ratio);
    } else if (heightValue > widthValue) {
      ratio = heightValue / widthValue;
      propHeight = 100;
      propWidth = 100 * (1 / ratio);

    } else {
      propWidth = 100;
      propHeight = 100;
    }
    
    child.style.setProperty("--cropWidth", propWidth);
    child.style.setProperty("--cropHeight", propHeight);
    child.classList.add('upscaledCanvas1to1');

    if (e) e.preventDefault();
    this.setState({ 
      showLoadingAdvanced: true,
      canClear: false,
      rgbArrayCanvas: []
    });
    await new Promise(r => setTimeout(r, 100));

    var wait = await this.createDMCData();
    this.handleFetchColors();

    /* const parentDiv = document.getElementById("drawerWrapper"); */
    var pal = document.getElementById('pal_final');
    var multiplier = 10;
    var w = pal.width;
    if(w>=120){
      multiplier = 4;
    } else if (w<=50 && w>30){
      multiplier = 16;
    } else if(w<=30) {
      multiplier = 20;
    }

    
    
    this.createDrawingCanvasSavedPattern(multiplier);
    //this.createDrawingSavedPattern();
    this.setState({ 
      undo: 0,
      donePage: true,
      advancedEdit: true,
      drawGrid: true,
      selectionBoxCopyData: null,
      drawGridSettingCenter: true,
      drawGridSettingGrid: true,
      drawGridSettingRulers: true,
      drawZoom: multiplier,
      displayStyleEdit: {
        visibility: 'visible',
        position: 'static'
      },
    },(e) => {
/*       parentDiv.style.position = 'unset';
      parentDiv.style.visibility = 'inherit'; */
      /* document.addEventListener('pointerup', (e)=>{this.handleMouseUpDrawing(e)}); */
      pal.style.zIndex = 5;
      pal.style.position = 'absolute';
      pal.style.visibility = 'hidden';
      /* this.createDMCDataDrawing(e, true); */
      this.createDMCDataDrawingCanvas(e, true);
/*       this.updateDrawDivs(e);
      this.handleUpdateDrawDivsStitchModeAndGrid(); */
      
    });
    
  }

  handleUpdateDrawDivsStitchModeAndGrid = () => {
    const elements = document.querySelectorAll(".drawDiv");
    const canvasGuideElements = document.querySelectorAll(".drawGuideElement");
    const mode = this.state.stitchMode;
    if(mode===true) {
      elements.forEach(element => {
        var col = element.style.color;
        element.style.background = '';
        element.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ col+' '+stitchPercentBright+'%,'+ col+', black)';
        element.style.fontSize = 'var(--drawzoom-stitches)';
      });
      
    } else {
      elements.forEach(element => {
        var col = element.style.color;
        element.style.background = col;
        element.style.fontSize = '0px!important';
      });
      
    }
    const grid = this.state.drawGrid;
    if(grid===true) {
      elements.forEach(element => {
        element.style.border = '1px solid rgba(0,0,0,0.1)';
      });
      canvasGuideElements.forEach(element => {
        element.style.border = '1px solid rgba(0,0,0,0.1)';
      });
      
    } else {
      elements.forEach(element => {
        element.style.border = 'none';
      });
      canvasGuideElements.forEach(element => {
        element.style.border = 'none';
      });
      
    }
  }



  handleOpenAdvancedEdit = async (e) => {
    if (e) e.preventDefault();
    this.setState({ 
      showLoadingAdvanced: true,
      undo: 0,
      
      canClear: false,
      showLoading: false,
      stitchMode: false
    });
    await new Promise(r => setTimeout(r, 100));
/*     var pal = document.getElementById('pal_final');
    var dUrl = null;
    if(pal !== null) {
      dUrl = pal.toDataURL();
    }
    var img = new Image();
    img.src = dUrl;
    var wait = await this.handleWaitForLoad(img, dUrl);
    if(wait===true ) {
      var w = img.width;
      var h = img.height;
  
      this.setState({ 
        advancedEdit: true, 
        quantSrc: dUrl,
        canvasDrawHeight: h,
        canvasDrawWidth: w 
      });
    } else {
      this.setState({ 
        advancedEdit: true
      });
    } */
    var wait = await this.createDMCData();
    this.handleFetchColors();
    //var colorObject = await this.handleSortColors();
/*     var tempArr = []
    Object.keys(colorObject).forEach(key => {
      colorObject[key].colors
    })  */
    //console.log(colorObject);
     const parentDiv = document.getElementById("drawerWrapperCanvas"); 
    var pal = document.getElementById('pal_final');
    var multiplier = 10;
    var w = pal.width;
    if(w>=120){
      multiplier = 4;
    } else if (w<=50 && w>30){
      multiplier = 16;
    } else if(w<=30) {
      multiplier = 20;
    }
    //ctx.drawImage( pal, 0,0, 2*pal.width, 2*pal.height)
    //this.resizePixels();
    
    this.setState({ 
      donePage: true,
      advancedEdit: true,
      drawGrid: true,
      selectionBoxCopyData: null,
      drawGridSettingCenter: true,
      drawGridSettingGrid: true,
      drawGridSettingRulers: true,
      drawZoom: multiplier,
      displayStyleEdit: {
        visibility: 'visible',
        position: 'static'
      }
      //colorClusters: colorObject
    },(e) => {
      this.createDrawingCanvas(multiplier);
       parentDiv.style.position = 'unset';
      parentDiv.style.visibility = 'inherit'; 
      //document.addEventListener('pointerup', (e)=>{this.handleMouseUpDrawing(e)});
      pal.style.zIndex = 5;
      pal.style.position = 'absolute';
      pal.style.visibility = 'hidden';
      
      this.createDMCDataDrawingCanvas(e, true);
      /* this.createDMCDataDrawing(e, true); */
/*       this.updateDrawDivs(e);
      this.handleUpdateDrawDivsStitchModeAndGrid(); */
    });
    
  }


  handleSortColors = async () => {
    var colorUtil = require('color-util');
    const clusters = [
      { name: 'red', leadColor: [255, 0, 0], colors: [] },
/*       { name: 'orange', leadColor: [255, 128, 0], colors: [] }, */
      { name: 'yellow', leadColor: [255, 255, 0], colors: [] },
/*       { name: 'chartreuse', leadColor: [128, 255, 0], colors: [] }, */
      { name: 'green', leadColor: [0, 255, 0], colors: [] },
/*       { name: 'spring green', leadColor: [0, 255, 128], colors: [] }, */
/*       { name: 'cyan', leadColor: [0, 255, 255], colors: [] },
      { name: 'azure', leadColor: [0, 127, 255], colors: [] }, */
      { name: 'blue', leadColor: [0, 0, 255], colors: [] },
      { name: 'violet', leadColor: [127, 0, 255], colors: [] },
/*       { name: 'magenta', leadColor: [255, 0, 255], colors: [] }, */
/*       { name: 'rose', leadColor: [255, 0, 128], colors: [] }, */
      { name: 'black', leadColor: [0, 0, 0], colors: [] },
      /* { name: 'grey', leadColor: [235, 235, 235], colors: [] }, */
      { name: 'white', leadColor: [255, 255, 255], colors: [] },
    ];

    var hexCols = [];
    Object.keys(DMCJSONColors).forEach(key => {
      hexCols.push(DMCJSONColors[key]["Hex Code"]);
    })


    const mappedColors = hexCols
    .map((color) => {
        return color;
    })
    .map(colorUtil.color);

    mappedColors.forEach((color) => {
    let minDistance;
    let minDistanceClusterIndex;
    clusters.forEach((cluster, clusterIndex) => {
      const colorRgbArr = [color.rgb.r, color.rgb.g, color.rgb.b];
      const distance = this.colorDistance(colorRgbArr, cluster.leadColor);
      if (typeof minDistance === 'undefined' || minDistance > distance) {
        minDistance = distance;
        minDistanceClusterIndex = clusterIndex;
      }
    });
    clusters[minDistanceClusterIndex].colors.push(color);
  });
  clusters.forEach((cluster) => {
    const dim = ['white', 'grey', 'black'].includes(cluster.name) ? 'l' : 's';
    cluster.colors = this.oneDimensionSorting(cluster.colors, dim)
  });
  return clusters;
  }

  colorDistance = (color1, color2) => {
    const x =
      Math.pow(color1[0] - color2[0], 2) +
      Math.pow(color1[1] - color2[1], 2) +
      Math.pow(color1[2] - color2[2], 2);
    return Math.sqrt(x);
  }

  oneDimensionSorting = (colors, dim) => {
    return colors
      .sort((colorA, colorB) => {
        if (colorA.hsl[dim] < colorB.hsl[dim]) {
          return -1;
        } else if (colorA.hsl[dim] > colorB.hsl[dim]) {
          return 1;
        } else {
          return 0;
        }
      });
  }

  handleMouseUpDrawing = (e) => {

/*     var didDraw = false;
    var getEls = document.querySelectorAll('[data-id]');
    getEls.forEach(element => {
      if(didDraw===false) {
      var mainArray = element.dataset.id.split(" ");
      if(mainArray.length>1)
      {
          var arr = mainArray[mainArray.length-2].split(":");
      } else {
        var arr = mainArray[mainArray.length-1].split(":");
      }
          var state = this.state.undo;
          if(arr[0]===state.toString()) {
            didDraw = true;
          }
        }
        });

    if(didDraw === false) {return;}
    var undoNumber = this.state.undo;
    if(this.state.savedPatternMode===true) {
      this.setState({
        madeChangeSavedPattern: true
      })
    }

    undoNumber++;
    this.setState({
      canClear: true,
      undo: undoNumber
    }) */
  }

  compareArrays = (a, b) => {
    if(a.length!==b.length) {
      return false;
    }
    return JSON.stringify(a) === JSON.stringify(b);
/*     return a.length === b.length && a.every((element, index) => element === b[index]); */
  }

   createBlankImage = (width, height) => {

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'rgba(0, 0, 0, 0)'
    ctx.fillRect(0, 0, width, height)
  
    const img = new Image(width, height)
    img.src = canvas.toDataURL()
  
    return img
  }


  handleCreateNewBlankCanvas = async () => {
    var width = Number(this.state.widthInputCreateCanvas);
    var height = Number(this.state.heightInputCreateCanvas);
    var validate = true
    if(width<=0) {
      this.setState({widthInputErrorCreateCanvas:true});
      validate = false;
    }
    if(height<=0) {
      this.setState({heightInputErrorCreateCanvas:true});
      validate = false;
    }

    if(validate===false) {return;}

    //We are ready and should create canvas!
    var unit = this.state.selectMeasuringUnitCreateCanvas;

    switch (unit) {
      case this.props.t('common.unit_1_small'):
        /*Nothing happens */
        break;
      case this.props.t('common.unit_2_small'):
        width = Math.round(width*this.state.selectBroderistofCreateCanvas);
        height = Math.round(height*this.state.selectBroderistofCreateCanvas);
        break;
        case this.props.t('common.unit_3_small'):
          width = Math.round(width*this.state.selectBroderistofCreateCanvas*2.54);
          height = Math.round(height*this.state.selectBroderistofCreateCanvas*2.54);
          break;
      default:
        /*Nothing happens */
        break;
    }

    
    var blankImage = this.createBlankImage(width,height);
    this.setState({
      widthInputErrorCreateCanvas:false,
      heightInputErrorCreateCanvas: false,
      loadingCreateCanvas: true,
      widthInput: width,
      madeChangeSavedPattern: false,
      advancedMode: 'draw',
      imgSrc: blankImage.src,
      cropWidth: width,
      cropHeight: height,
      replaceMode: false,
      showLoading: false,
      stitchMode: false,
      donePage: false,
         displayStyleEdit: {
          visibility: 'hidden',
          position: 'fixed'
        }, 
        displayStyleDonePage: {
          visibility: 'hidden',
          position: 'fixed'
        }, 
        showLoadingAdvanced: true,
        canClear: false,
        rgbArrayCanvas: []
    },()=> {
      var id = "pal_final";
    var child = null;
      var can = document.createElement("canvas");
      id && can.setAttribute("id", id);
      can.width = width;
      can.height = height;
      var quantDiv = document.getElementById("quantDiv");
      if(quantDiv!==null) {
        child = quantDiv.appendChild(can);
      } else {
        child = document.body.appendChild(can);
      }
  
      let propWidth = width;
      let propHeight = height;
      let ratio;
      if (width > height) {
        ratio = width / height;
        propWidth = 100;
        propHeight = 100 * (1 / ratio);
      } else if (height > width) {
        ratio = height / width;
        propHeight = 100;
        propWidth = 100 * (1 / ratio);
  
      } else {
        propWidth = 100;
        propHeight = 100;
      }
      
      child.style.setProperty("--cropWidth", propWidth);
      child.style.setProperty("--cropHeight", propHeight);
      child.classList.add('upscaledCanvas1to1');
    });
    

    await new Promise(r => setTimeout(r, 100));

    var wait = await this.createDMCData();
     this.handleFetchColors(); 

    var multiplier = 10;
    var w = width;
    if(w>=120){
      multiplier = 4;
    } else if (w<=50 && w>30){
      multiplier = 16;
    } else if(w<=30) {
      multiplier = 20;
    }

    
    
    
    //this.createDrawingSavedPattern();
    this.setState({ 
      undo: 0,
      donePage: true,
      createBlankCanvasMode: true,
      advancedEdit: true,
      drawGrid: true,
      selectionBoxCopyData: null,
      drawGridSettingCenter: true,
      drawGridSettingGrid: true,
      drawGridSettingRulers: true,
      drawZoom: multiplier,
      heightCanvasDraw: height,
      widthCanvasDraw: width,
      displayStyleEdit: {
        visibility: 'visible',
        position: 'static'
      },
      openCreateNewBlankCanvas: false,
      loadingCreateCanvas: false
      
    },(e) => {
      this.createDrawingCanvasSavedPattern(multiplier,true,width,height);
/*       parentDiv.style.position = 'unset';
      parentDiv.style.visibility = 'inherit'; */
      /* document.addEventListener('pointerup', (e)=>{this.handleMouseUpDrawing(e)}); */
/*       pal.style.zIndex = 5;
      pal.style.position = 'absolute';
      pal.style.visibility = 'hidden'; */
      /* this.createDMCDataDrawing(e, true); */
      this.createDMCDataDrawingCanvas(e, true);
/*       this.updateDrawDivs(e);
      this.handleUpdateDrawDivsStitchModeAndGrid(); */
      
    });
    



    //Create prerequisets for going to advanced edit.


  }

  handleApplySelectionToolToCanvasNoMouse = (e) => {
    
      const canvasToApply = document.getElementById("selectionBox");

    if(!canvasToApply) {return}

      const canvas = document.getElementById("drawerCanvas");
      const ctx = canvas.getContext("2d");
/*         const canvasBoundingRect = canvas.getBoundingClientRect();
        const x = e.clientX - canvasBoundingRect.left;
        const y = e.clientY - canvasBoundingRect.top;
    
        const xx = x - this.state.toolStartX;
        const yy = y - this.state.toolStartY; */
        const cellXX = Math.floor(parseInt(canvasToApply.style.left.replace("px","")) / this.state.drawZoom);
        const cellYY = Math.floor(parseInt(canvasToApply.style.top.replace("px","")) / this.state.drawZoom);
    
        const startXX = cellXX * this.state.drawZoom;
        const startYY = cellYY * this.state.drawZoom;

      ctx.drawImage(canvasToApply,startXX,startYY);
      
    
     const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
      if(topSelectionParent) {
        while(topSelectionParent.firstChild) {
          topSelectionParent.removeChild(topSelectionParent.lastChild);
        }
      } 
      if(this.state.selectionChange===true) {
        this.handleMakeChangeDrawCanvas();
      }
      
      this.setState({selectBox: false, selectingArea: false}, ()=> {
        if(this.state.selectionChange===true) {
        this.handleMouseUpDrawingCanvas(e);
        }
      });
  }


  handleApplySelectionToolToCanvas = (e) => {
    //console.log("Hello!!");
    if(this.state.selectBox===false) {return;}
      const canvasToApply = document.getElementById("selectionBox");
    if(canvasToApply) {
      const canvas = document.getElementById("drawerCanvas");
      const ctx = canvas.getContext("2d");
        const canvasBoundingRect = canvas.getBoundingClientRect();
        const x = e.clientX - canvasBoundingRect.left;
        const y = e.clientY - canvasBoundingRect.top;
    
        const xx = x - this.state.toolStartX;
        const yy = y - this.state.toolStartY;
        const cellXX = Math.floor(xx / this.state.drawZoom);
        const cellYY = Math.floor(yy / this.state.drawZoom);
    
        const startXX = cellXX * this.state.drawZoom;
        const startYY = cellYY * this.state.drawZoom;

      ctx.drawImage(canvasToApply,startXX,startYY);
      
    }
     const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
      if(topSelectionParent) {
        while(topSelectionParent.firstChild) {
          topSelectionParent.removeChild(topSelectionParent.lastChild);
        }
      } 
      if(this.state.selectionChange===true) {
      this.handleMakeChangeDrawCanvas();
      }
      this.setState({selectBox: false, selectingArea: false}, ()=> {
        if(this.state.selectionChange===true) {
        this.handleMouseUpDrawingCanvas(e);
        }
      });
      
  }

  handleMoveSelectionBox = (e) => {
    if(this.state.selectBox===false || this.state.selectingArea===true) {return}

    
        const canvas = document.getElementById("drawerCanvas");
        const canvasBoundingRect = canvas.getBoundingClientRect();
        const x = e.clientX - canvasBoundingRect.left;
        const y = e.clientY - canvasBoundingRect.top;
        if((Math.floor(x / this.state.drawZoom)*this.state.drawZoom!==Math.floor((this.state.toolStartX+this.state.toolStartBoundingClientLeft-canvasBoundingRect.left)/ this.state.drawZoom)*this.state.drawZoom || 
        Math.floor(y / this.state.drawZoom)*this.state.drawZoom!==Math.floor((this.state.toolStartY+this.state.toolStartBoundingClientTop-canvasBoundingRect.left)/ this.state.drawZoom)*this.state.drawZoom) && 
        this.state.selectionChange===false) {
          
          this.setState({selectionChange: true});
        }
        
        const canvasToMove = document.getElementById("selectionBox");
    if(canvasToMove) {
        const xx = x - this.state.toolStartX;
        const yy = y - this.state.toolStartY;
        const cellXX = Math.floor(xx / this.state.drawZoom);
        const cellYY = Math.floor(yy / this.state.drawZoom);
    
        const startXX = cellXX * this.state.drawZoom;
        const startYY = cellYY * this.state.drawZoom;


        const selectionBoxBorderDiv = document.getElementById("selectionBoxBorderDiv");
/*         console.log(x);
        console.log(xx);*/

        canvasToMove.style.left = startXX+"px";
        canvasToMove.style.top = startYY+"px";
        selectionBoxBorderDiv.style.left = startXX+"px";
        selectionBoxBorderDiv.style.top = startYY+"px";
    }
  }


  handleMouseUpDrawingCanvasSelectionTool = (e) => {
     if(this.state.selectBox===true) {return} 
    const canvas = document.getElementById('drawerCanvas');
    var imgData = null;
    if(canvas) {
      const drawContext = canvas.getContext("2d");
      const canvasBoundingRect = canvas.getBoundingClientRect();
        const x = e.clientX - canvasBoundingRect.left;
        const y = e.clientY - canvasBoundingRect.top;
        const cellX = Math.floor(x / this.state.drawZoom);
        const cellY = Math.floor(y / this.state.drawZoom);
    
        const startX = cellX * this.state.drawZoom;
        const startY = cellY * this.state.drawZoom;

        const xx = this.state.toolStartX - canvasBoundingRect.left;
        const yy = this.state.toolStartY - canvasBoundingRect.top;
        const cellXX = Math.floor(xx / this.state.drawZoom);
        const cellYY = Math.floor(yy / this.state.drawZoom);
    
        const startXX = cellXX * this.state.drawZoom;
        const startYY = cellYY * this.state.drawZoom;
    
        var left = false;
        var bottom = false;
        //Right
        if(startXX<=startX){
          //Top
          if(startYY>startY) {
            imgData = drawContext.getImageData(startXX,startYY+this.state.drawZoom,(startX+this.state.drawZoom)-startXX,startY-startYY-this.state.drawZoom);
            //drawContext.clearRect(startXX,startYY+this.state.drawZoom,(startX+this.state.drawZoom)-startXX,startY-startYY-this.state.drawZoom);
          }
          //Bottom 
          else {
            bottom = true;
            imgData = drawContext.getImageData(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
            //drawContext.clearRect(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
          }
        }
        //Left 
        else if(startXX>startX) {
          left = true;
          //Top
          if(startYY>startY) {
            imgData = drawContext.getImageData(startXX+this.state.drawZoom,startYY+this.state.drawZoom,startX-startXX-this.state.drawZoom,startY-startYY-this.state.drawZoom);
            //drawContext.clearRect(startXX+this.state.drawZoom,startYY+this.state.drawZoom,startX-startXX-this.state.drawZoom,startY-startYY-this.state.drawZoom);
          } 
          //Bottom
          else {
            bottom = true;
            imgData = drawContext.getImageData(startXX+this.state.drawZoom,startYY,startX-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
            //drawContext.clearRect(startXX+this.state.drawZoom,startYY,startX-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
          }
        }

      const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
      if(topSelectionParent && imgData!==null) {
        while(topSelectionParent.firstChild) {
          topSelectionParent.removeChild(topSelectionParent.lastChild);
        }
      const newTopCanvas = document.createElement("canvas");
      const newTopCanvasBorderElement = document.createElement("div");
      newTopCanvasBorderElement.classList.add("selectionBoxBorderDiv");
      newTopCanvasBorderElement.id = "selectionBoxBorderDiv";
      newTopCanvasBorderElement.style.width = imgData.width+"px";
      newTopCanvasBorderElement.style.height = imgData.height+"px";
      newTopCanvasBorderElement.style.setProperty("--drawzoom-pixels","2px");
      newTopCanvas.classList.add("selectionBox");
      newTopCanvas.id = "selectionBox";

      newTopCanvas.width = imgData.width;
      newTopCanvas.height = imgData.height;
      if(bottom===true) {
        newTopCanvas.style.top = startY-newTopCanvas.height+this.state.drawZoom+"px";
        newTopCanvasBorderElement.style.top = startY-newTopCanvas.height+this.state.drawZoom+"px";
      } else {
        newTopCanvas.style.top = startY+"px";
        newTopCanvasBorderElement.style.top = startY+"px";
      }
      
      if(left===true) {
        newTopCanvas.style.left = startX+"px";
        newTopCanvasBorderElement.style.left = startX+"px";
      } else {
        newTopCanvas.style.left = startX-newTopCanvas.width+this.state.drawZoom+"px";
        newTopCanvasBorderElement.style.left = startX-newTopCanvas.width+this.state.drawZoom+"px";
      }
      
      topSelectionParent.appendChild(newTopCanvas);
      topSelectionParent.appendChild(newTopCanvasBorderElement);

      newTopCanvas.addEventListener("pointerdown", (e)=> {
/*         if (this.state.selectBox===false) { */

          this.setState({toolStartX: e.clientX-e.target.getBoundingClientRect().left, 
            toolStartY: e.clientY-e.target.getBoundingClientRect().top,
          toolStartBoundingClientLeft: e.target.getBoundingClientRect().left,
        toolStartBoundingClientTop: e.target.getBoundingClientRect().top});
         
       /*  } */
      });


      newTopCanvas.addEventListener("pointermove", (e)=> {
        if(this.state.advancedMode==="selectiontool") {
          if(e.buttons===1)
    {
          this.handleMoveSelectionBox(e);
    }
        }
      });
      /* newTopCanvas.addEventListener("pointerup",(e)=> {
        if(this.state.advancedMode==="selectiontool") {
          this.handleApplySelectionToolToCanvas(e);
        }

      }); */
      const ctx = newTopCanvas.getContext("2d");
      ctx.putImageData(imgData,0,0);
      this.setState({selectBox: true, selectingArea: false, toolStartX: e.clientX-e.target.getBoundingClientRect().left, 
        toolStartY: e.clientY-e.target.getBoundingClientRect().top,
        toolStartBoundingClientLeft: e.target.getBoundingClientRect().left,
      toolStartBoundingClientTop: e.target.getBoundingClientRect().top});
      //Right
      if(startXX<=startX){
        //Top
        if(startYY>startY) {
          
          drawContext.clearRect(startXX,startYY+this.state.drawZoom,(startX+this.state.drawZoom)-startXX,startY-startYY-this.state.drawZoom);
        }
        //Bottom 
        else {

         
          drawContext.clearRect(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
        }
      }
      //Left 
      else if(startXX>startX) {

        //Top
        if(startYY>startY) {
         
          drawContext.clearRect(startXX+this.state.drawZoom,startYY+this.state.drawZoom,startX-startXX-this.state.drawZoom,startY-startYY-this.state.drawZoom);
        } 
        //Bottom
        else {

        
          drawContext.clearRect(startXX+this.state.drawZoom,startYY,startX-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
        }
      }
    }
    }
    //Remove outline from select area!
    const canvasTop = document.getElementById("drawerCanvasTop");
    const ctxTop = canvasTop.getContext("2d");
    ctxTop.clearRect(0,0,canvasTop.width,canvasTop.height);

    this.setState({selectingArea: false, selectionChange: false, selectionCanStart: false});
  }


  handleMouseUpDrawingCanvas = async (e) => {
    if(this.state.madeChangeDrawCanvas===false || this.state.advancedMode==="colorpicker"){ return; }
    var undoStateArray = this.state.drawCanvasUndoStates;
    const canvas = document.getElementById('drawerCanvas');
    const ctx = canvas.getContext("2d");

    if(this.state.advancedMode==="linetool" || this.state.advancedMode==="squaretool" || this.state.advancedMode==="circletool" || this.state.advancedMode==="selectiontool") {
      const canvasTop = document.getElementById('drawerCanvasTop');
      if(canvasTop) {
        if(this.state.advancedMode!=="selectiontool") {

          ctx.drawImage(canvasTop,0,0);
        }
        
        
      }

    }


     
    let pixelArr = [];
    for(let yy=0; yy<this.state.heightCanvasDraw; yy++) {
      for(let xx=0; xx<this.state.widthCanvasDraw; xx++) {

          const pixel = ctx.getImageData(xx*this.state.drawZoom, yy*this.state.drawZoom, 1, 1).data;
          const rgb = [pixel[0],pixel[1],pixel[2],pixel[3]];
          pixelArr.push(rgb);
      } 
  }
    /*var checkIfSame = this.compareArrays(pixelArr,undoStateArray[this.state.undo]);
    */
    

    var undoNumber = this.state.undo;
    while(undoStateArray.length-1!==undoNumber) {
      //Delete all the states after the current state when creating a new state if you have undone an action
      undoStateArray.pop();
    }
    undoStateArray.push({rgb: pixelArr, canvasSize: {top:0,left:0,right:0,bottom:0,orgHeight:this.state.heightCanvasDraw,orgWidth:this.state.widthCanvasDraw}});
    if(this.state.savedPatternMode===true) {
      this.setState({
        madeChangeSavedPattern: true
      })
    }
    undoNumber++;
    //console.log("increasing undo!");
    this.setState({
      canClear: true,
      undo: undoNumber,
      drawCanvasUndoStates: undoStateArray,
      madeChangeDrawCanvas: false
    },()=> {
      if(this.state.advancedMode==="linetool" || this.state.advancedMode==="squaretool" || this.state.advancedMode==="circletool" || this.state.advancedMode==="selectiontool") {
      this.createDrawingCanvasSavedPattern(this.state.drawZoom);
      }
    })

  }

/* 
  resizePixels = () => { */
/*     var pal = document.getElementById('pal_final');
    var ctx = pal.getContext("2d");
    const pixel = ctx.getImageData(0, 0, pal.width, pal.height);
    const data = pixel.data;
    var rgbArray = []
      for (var i = 0; i < data.length; i += 4) {
        rgbArray.push([data[i], data[i + 1], data[i + 2]])
      }
    var imgData = new Uint8ClampedArray(pal.width*pal.height*4*4);
    var num = 0;
    rgbArray.forEach(element => {
          for(let r = 0; r < 4; r++) {
            for(let x = 0;x < 4; x++) {
              for(let y = 0; y< 4; y++) {

              
              if(r===3) {
                imgData[r+(4*x)+(pal.width*y)] = 255;
              }
              else {
                imgData[r+(4*x)+(pal.width*y)] = element[r];
              }
            }
          }
          }

    });

    let imgDataTrue = new ImageData(imgData, pal.width*4);
    ctx.putImageData(imgDataTrue,0,0); */

/*     var pal = document.getElementById('pal_final');

    var imageDataSource = pal.getContext('2d');
    var imgData = imageDataSource.getImageData(0,0,pal.width,pal.height).data;
    var upscaledImageCan = document.getElementById('upscaledimagedata');
    var upscaledImageData = upscaledImageCan.getContext('2d');
  
    for (var x = 0; x < pal.width; ++x) {
      for (var y = 0 ; y < pal.height; ++y) {
        var i = (y * pal.width + x) * 4;
        var r = imgData[i];
        var g = imgData[i + 1];
        var b = imgData[i + 2];
        var a = imgData[i + 3];
        upscaledImageData.fillStyle = "rgba(" + r + ", " + g + ", " + b + ", " + (a / 255) + ")";
        upscaledImageData.fillRect(x * 15, y * 15, 15, 15);
      }
    }
    
  } */


  handleColorPickerCursorMove = (e, cRounded) => {

  var mouseX = e.clientX;
  var mouseY = e.clientY;
  cRounded.style.left = mouseX + 'px';
  cRounded.style.top = mouseY + 'px';
  const canvas = document.getElementById("drawerCanvas");
  const ctx = canvas.getContext("2d");
  const canvasBoundingRect = canvas.getBoundingClientRect();
  const x = mouseX - canvasBoundingRect.left;
  const y = mouseY - canvasBoundingRect.top;
  const cellX = Math.floor(x / this.state.drawZoom);
  const cellY = Math.floor(y / this.state.drawZoom);
  const startX = cellX * this.state.drawZoom;
  const startY = cellY * this.state.drawZoom; 
  let color = ctx.getImageData(startX,startY,1,1).data;
  if(color[3]===0) {
    //Transparent so return;
    return;
  }
  let rgb = "rgb("+color[0]+","+color[1]+","+color[2]+")";
  if(rgb===cRounded.style.backgroundColor) {return;}
  cRounded.style.backgroundColor = rgb;
  /* cPointed.style.left = mouseX + 'px';
  cPointed.style.top = mouseY + 'px'; */
  } 

  

  handleColorPickerCursorColor = (e, el) => {
    if(this.state.stitchMode===true) {
      el.style.backgroundColor = e.target.style.color;
    } else {
      el.style.backgroundColor = e.target.style.background;
    }
    }


createPixelMatrix = (divArray, width, height) => {
  var arr = new Array(width);
  for (var i = 0; i < arr.length; i++) {
    arr[i] = new Array(length);
}

var number = 0;
   do { 
      for(var x=0; x<width;x++) {
        var num = x+(width*number);  
        arr[x][number] = divArray[num];
        //arr[x][y]=rgbArray[x+(x*y)];
      }
      number++;
    } while(number<height);
    return arr;
/*    */
}

createPixelRGBMatrix = (divArray, width, height) => {
  var arr = new Array(width);
  for (var i = 0; i < arr.length; i++) {
    arr[i] = new Array(length);
}

var number = 0;
   do { 
      for(var x=0; x<width;x++) {
        var num = x+(width*number);  
        if(this.state.stitchMode===true) {
          arr[x][number] = divArray[num].style.color;
        } else {
          arr[x][number] = divArray[num].style.background;
        }
        
        //arr[x][y]=rgbArray[x+(x*y)];
      }
      number++;
    } while(number<height);
    return arr;
/*     */
}

 keyHandlerUndoRedo = (e) => {
  if(e.repeat) return;
  if (e.key == "z" && e.ctrlKey && this.state.undo>=1) {
    this.handleUndoDrawing(e);
  } else if (e.key == "y" && e.ctrlKey && this.state.undo!==this.state.drawCanvasUndoStates.length-1) {
    this.handleRedoDrawing(e);
  }
  
  // do something
}
keyHandlerDelete = (e) => {
  if(e.repeat) return;
  if (e.key === 'Delete') {
    if(this.state.selectBox===true) {
      const canvasToApply = document.getElementById("selectionBox");
      if(!canvasToApply) {return}
      const ctx = canvasToApply.getContext("2d");
      const cellXX = Math.floor(parseInt(canvasToApply.style.left.replace("px","")) / this.state.drawZoom);
        const cellYY = Math.floor(parseInt(canvasToApply.style.top.replace("px","")) / this.state.drawZoom);
    
        const startXX = cellXX * this.state.drawZoom;
        const startYY = cellYY * this.state.drawZoom;
        ctx.clearRect(startXX,startYY,canvasToApply.width,canvasToApply.height);
        const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      if(topSelectionParent) {
        while(topSelectionParent.firstChild) {
          topSelectionParent.removeChild(topSelectionParent.lastChild);
        }
      } 
      
      
      this.setState({madeChangeDrawCanvas: true, selectBox: false, selectingArea: false, selectionCanStart: true}, (e)=> {
        this.handleMouseUpDrawingCanvas(e);
      });
    }
    
  } 
}

handleDeleteSelection = () => {
    if(this.state.selectBox===true) {
      const canvasToApply = document.getElementById("selectionBox");
      if(!canvasToApply) {return}
      const ctx = canvasToApply.getContext("2d");
      const cellXX = Math.floor(parseInt(canvasToApply.style.left.replace("px","")) / this.state.drawZoom);
        const cellYY = Math.floor(parseInt(canvasToApply.style.top.replace("px","")) / this.state.drawZoom);
    
        const startXX = cellXX * this.state.drawZoom;
        const startYY = cellYY * this.state.drawZoom;
        ctx.clearRect(startXX,startYY,canvasToApply.width,canvasToApply.height);
        const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      if(topSelectionParent) {
        while(topSelectionParent.firstChild) {
          topSelectionParent.removeChild(topSelectionParent.lastChild);
        }
      } 
      
      this.setState({madeChangeDrawCanvas: true, selectBox: false, selectingArea: false, selectionCanStart: true}, (e)=> {
        this.handleMouseUpDrawingCanvas(e);
      });
    }
    

}

keyHandlerShift = (e) => {
  if(e.repeat) return;
  if (e.shiftKey) {
    if(this.state.shiftKey===false) {
      this.setState({shiftKey: true});
    }
    
  } else {
    if(this.state.shiftKey===true) {
      this.setState({shiftKey: false});
    }
  }
  
  // do something
}

handleCopySelectionButton = () => {
  const selectionBox = document.getElementById("selectionBox");
  if(selectionBox) {
    var ctx = selectionBox.getContext("2d");
    var data = ctx.getImageData(0,0,selectionBox.width,selectionBox.height);
    this.setState({selectionBoxCopyData: data},()=> {
      //console.log("copied!!");
    });
  }
}

handlePasteSelectionButton = () => {
  if (this.state.selectionBoxCopyData!==null ) {
    this.setState({selectionBoxCopyMode: true, advancedMode: "selectiontool"},()=> {
      this.handlePasteSelectionBox();
    });
  }
}

keyHandlerCopyPasteSelection = (e) => {
  if(e.repeat) return;
  if (e.key == "c" && e.ctrlKey) {
    const selectionBox = document.getElementById("selectionBox");
    if(selectionBox) {
      var ctx = selectionBox.getContext("2d");
      var data = ctx.getImageData(0,0,selectionBox.width,selectionBox.height);
      this.setState({selectionBoxCopyData: data},()=> {
        //console.log("copied!!");
      });
    } /* else {
      console.log("selectionbox not present... cant copy...");
    } */
    
  } else if (e.key == "v" && e.ctrlKey && this.state.selectionBoxCopyData!==null ) {
    this.setState({selectionBoxCopyMode: true, advancedMode: "selectiontool"},()=> {
      this.handlePasteSelectionBox();
    });
  }
}





handlePasteSelectionBox = (e) => {
  //console.log("pasted!");
/*   if(this.state.selectBox===true) 
  {
    
    return}  */
  const canvas = document.getElementById('drawerCanvas');
  var imgData = this.state.selectionBoxCopyData;
  if(canvas) {
    const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
    
    if(topSelectionParent && imgData!==null) {
      this.handleApplySelectionToolToCanvasNoMouse(e);
      while(topSelectionParent.firstChild) {
        topSelectionParent.removeChild(topSelectionParent.lastChild);
      }
    const newTopCanvas = document.createElement("canvas");
    const newTopCanvasBorderElement = document.createElement("div");
    newTopCanvasBorderElement.classList.add("selectionBoxBorderDiv");
    newTopCanvasBorderElement.id = "selectionBoxBorderDiv";
    newTopCanvasBorderElement.style.width = imgData.width+"px";
    newTopCanvasBorderElement.style.height = imgData.height+"px";
    newTopCanvasBorderElement.style.setProperty("--drawzoom-pixels","2px");
    newTopCanvas.classList.add("selectionBox");
    newTopCanvas.id = "selectionBox";

    newTopCanvas.width = imgData.width;
    newTopCanvas.height = imgData.height;
    
      newTopCanvas.style.top = "0px";//startY-newTopCanvas.height+this.state.drawZoom+"px";
      newTopCanvasBorderElement.style.top = "0px";//startY-newTopCanvas.height+this.state.drawZoom+"px";

    
    topSelectionParent.appendChild(newTopCanvas);
    topSelectionParent.appendChild(newTopCanvasBorderElement);

    newTopCanvas.addEventListener("pointerdown", (e)=> {
      /* if (this.state.selectBox===false) { */
        //console.log("toolstart set!");
        this.setState({selectBox: true, selectingArea: false, toolStartX: e.clientX-e.target.getBoundingClientRect().left, 
          toolStartY: e.clientY-e.target.getBoundingClientRect().top,
          toolStartBoundingClientLeft: e.target.getBoundingClientRect().left,
        toolStartBoundingClientTop: e.target.getBoundingClientRect().top});
     /*  } */
    });


    newTopCanvas.addEventListener("pointermove", (e)=> {
      if(this.state.advancedMode==="selectiontool") {
        if(e.buttons===1)
  {
        this.handleMoveSelectionBox(e);
  }
      }
    });
    newTopCanvas.addEventListener("pointerup",(e)=> {

/*       if(this.state.advancedMode==="selectiontool") {
        this.handleApplySelectionToolToCanvas(e);
      } */

    });
    const ctx = newTopCanvas.getContext("2d");
    ctx.putImageData(imgData,0,0);
  }
  }
  //Remove outline from select area!
  const canvasTop = document.getElementById("drawerCanvasTop");
  const ctxTop = canvasTop.getContext("2d");
  ctxTop.clearRect(0,0,canvasTop.width,canvasTop.height);

  this.setState({selectingArea: false, selectBox: true});
}

handleCloseAndInsertText = (e) => {
  e.preventDefault();
  this.setState({selectionBoxCopyMode: true, advancedMode: "selectiontool"},()=> {
    this.handleInsertText();
  });
}


 trimCanvas = (ctx) => {
  var canvas = ctx.canvas, 
    w = canvas.width, h = canvas.height,
    pix = {x:[], y:[]},
    imageData = ctx.getImageData(0,0,canvas.width,canvas.height),
    x, y, index;
  
  for (y = 0; y < h; y++) {
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4;
      if (imageData.data[index+3] > 0) {
        pix.x.push(x);
        pix.y.push(y);
      } 
    }
  }
  pix.x.sort(function(a,b){return a-b});
  pix.y.sort(function(a,b){return a-b});
  var n = pix.x.length-1;
  
  w = 1 + pix.x[n] - pix.x[0];
  h = 1 + pix.y[n] - pix.y[0];
  var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

  canvas.width = w;
  canvas.height = h;

  ctx.putImageData(cut, 0, 0);
  var border = document.getElementById("selectionBoxBorderDiv");
  border.style.width = w+"px";
  border.style.height = h+"px";

  //var image = canvas.toDataURL();
}

handleInsertText = () => {
   //console.log("pasted!");
/*   if(this.state.selectBox===true) 
  {
    
    return}  */
    const canvas = document.getElementById('drawerCanvas');
    let _canvas = document.getElementById("textToolCanvasTextTemp");
    let _ctx = _canvas.getContext("2d");
    var txt = this.state.textToolText;
    _ctx.font = ((this.state.textToolSize/100)*64)+16+"px "+this.state.textToolFontSelected;
     let metrics = _ctx.measureText(txt);
    let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    let heightDiff = fontHeight-actualHeight-1;
    var imgData = _ctx.getImageData(0,0,_canvas.width,fontHeight+20);
    var rgbArray = []
    for (var i = 0; i < imgData.data.length; i += 4) {
      rgbArray.push({r: imgData.data[i], g: imgData.data[i + 1], b: imgData.data[i + 2], a: imgData.data[i + 3]})
    }
    const perChunk = _canvas.width // items per chunk  
      const result = rgbArray.reduce((all,one,i) => {
        const ch = Math.floor(i/perChunk); 
        all[ch] = [].concat((all[ch]||[]),one); 
        return all
     }, [])

    if(canvas) {
      const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
      if(topSelectionParent && imgData!==null) {
        this.handleApplySelectionToolToCanvasNoMouse();
        while(topSelectionParent.firstChild) {
          topSelectionParent.removeChild(topSelectionParent.lastChild);
        }
      const newTopCanvas = document.createElement("canvas");
      const newTopCanvasBorderElement = document.createElement("div");
      newTopCanvasBorderElement.classList.add("selectionBoxBorderDiv");
      newTopCanvasBorderElement.id = "selectionBoxBorderDiv";
/*       newTopCanvasBorderElement.style.width = (metrics.width*this.state.drawZoom+(this.state.drawZoom*10))+"px";
      newTopCanvasBorderElement.style.height = ((fontHeight+10)*this.state.drawZoom)+"px"; */
      newTopCanvasBorderElement.style.setProperty("--drawzoom-pixels","2px");
      newTopCanvas.classList.add("selectionBox");
      newTopCanvas.id = "selectionBox";
  
      newTopCanvas.width = metrics.width*this.state.drawZoom+(this.state.drawZoom*20);
      newTopCanvas.height = (fontHeight+20)*this.state.drawZoom;
      
        newTopCanvas.style.top = "0px";//startY-newTopCanvas.height+this.state.drawZoom+"px";
        newTopCanvasBorderElement.style.top = "0px";//startY-newTopCanvas.height+this.state.drawZoom+"px";
  
      
      topSelectionParent.appendChild(newTopCanvas);
      topSelectionParent.appendChild(newTopCanvasBorderElement);
  
      newTopCanvas.addEventListener("pointerdown", (e)=> {
        /* if (this.state.selectBox===false) { */
          //console.log("toolstart set!");
          this.setState({selectBox: true, selectingArea: false, toolStartX: e.clientX-e.target.getBoundingClientRect().left, 
            toolStartY: e.clientY-e.target.getBoundingClientRect().top,
            toolStartBoundingClientLeft: e.target.getBoundingClientRect().left,
          toolStartBoundingClientTop: e.target.getBoundingClientRect().top});
       /*  } */
      });
  
  
      newTopCanvas.addEventListener("pointermove", (e)=> {
        if(this.state.advancedMode==="selectiontool") {
          if(e.buttons===1)
    {
          this.handleMoveSelectionBox(e);
    }
        }
      });
      newTopCanvas.addEventListener("pointerup",(e)=> {
  
  /*       if(this.state.advancedMode==="selectiontool") {
          this.handleApplySelectionToolToCanvas(e);
        } */
  
      });
      const ctx = newTopCanvas.getContext("2d");
      ctx.fillStyle = this.state.drawColor;
      //ctx.putImageData(imgData,0,0);
      for (let y=0; y<fontHeight+20; y++) {
        for(let i = 0; i<result[y].length; i++) {
          if(result[y][i].a>=50) {
            ctx.fillRect(this.state.drawZoom*i,y*this.state.drawZoom,this.state.drawZoom,this.state.drawZoom);
          }
        }
      }
      this.trimCanvas(ctx);
    }
    }
    
    //Remove outline from select area!
    const canvasTop = document.getElementById("drawerCanvasTop");
    const ctxTop = canvasTop.getContext("2d");
    ctxTop.clearRect(0,0,canvasTop.width,canvasTop.height);
  
    this.setState({selectingArea: false, selectBox: true, openTextTool: false});
}



createDrawingCanvas = (zoomVal) => {
  var rgbArray = [];
  var palWidth= 0;
  var palHeight= 0;
  const parentDiv = document.getElementById('drawerWrapperCanvas');
  const parentDivTop = document.getElementById('drawerWrapperCanvasTop');
  const cursorRounded = document.querySelector('.cRounded');
  if(this.state.rgbArrayCanvas.length<=0) {

    parentDiv.addEventListener('mouseenter', (e)=> {
      if(this.state.advancedMode!=='colorpicker') return;
      cursorRounded.style.visibility = 'visible'
    });
    parentDiv.addEventListener('mouseleave', (e)=> {
      if(this.state.advancedMode!=='colorpicker') return;
      cursorRounded.style.visibility = 'hidden'
    });
  var pal = document.getElementById('pal_final');
  var ctx = pal.getContext("2d");
  palWidth = pal.width;
  palHeight = pal.height;
  const pixel = ctx.getImageData(0, 0, palWidth, palHeight);
  const data = pixel.data;
  for (var i = 0; i < data.length; i += 4) {
    rgbArray.push([data[i], data[i + 1], data[i + 2], data[[i + 3]]])
  }
  let undoStateArr = [];
  undoStateArr.push({rgb: rgbArray, canvasSize: {top:0,left:0,right:0,bottom:0,orgHeight:pal.height,orgWidth:pal.width}});

  document.removeEventListener("keydown", (e)=> {
    this.keyHandlerUndoRedo(e);
    this.keyHandlerShift(e);
    this.keyHandlerDelete(e);
    this.keyHandlerCopyPasteSelection(e);
});
document.addEventListener("keyup", (e)=> {
  this.keyHandlerShift(e);
});
  document.addEventListener('pointerup', (e)=>{
    if(this.state.advancedMode==="draw" || this.state.advancedMode==="eraser" || this.state.advancedMode==="buckettool"  || this.state.advancedMode==="linetool" 
    || this.state.advancedMode==="squaretool" || this.state.advancedMode==="circletool")
    {
      this.handleMouseUpDrawingCanvas(e);
    } /* else if(this.state.advancedMode==="selectiontool" && this.state.selectBox===false) {
      this.handleMouseUpDrawingCanvasSelectionTool(e);
    } */
  });
  document.addEventListener("keydown", (e)=> {
      this.keyHandlerUndoRedo(e);
      this.keyHandlerShift(e);
      this.keyHandlerDelete(e);
      this.keyHandlerCopyPasteSelection(e);
  });
  document.addEventListener("keyup", (e)=> {
    this.keyHandlerShift(e);
  });

  this.setState({
    drawCanvasUndoStates: undoStateArr,
    rgbArrayCanvas: rgbArray,
    widthCanvasDraw: pal.width,
    heightCanvasDraw: pal.height
  });
} else {
/*   */
  rgbArray = this.state.drawCanvasUndoStates[this.state.undo].rgb;
  palWidth = this.state.widthCanvasDraw;
  palHeight = this.state.heightCanvasDraw;
}

  const rgbMatrix = this.createPixelRGBMatrixFromRGBArray(rgbArray,palWidth,palHeight);

  //Fix transparent background!
  const transparentBackground = document.querySelector(".transparentBackground");
  transparentBackground.style.setProperty('--drawzoom-pixels', zoomVal*2+"px");
  if(this.state.advancedMode==="colorreplacer") {
    for(let xx = 0; xx<palWidth; xx++) {
      for(let yy = 0; yy<palHeight; yy++) {
        const currentColor = rgbMatrix[xx][yy];
        const ctx = parentDiv.children[0].getContext("2d");
        const pixels = ctx.getImageData(xx*zoomVal,yy*zoomVal,1,1).data;
        var col = "rgba("+pixels[0]+","+pixels[1]+","+pixels[2]+","+pixels[3]+")";
        if(currentColor!==col) {
          this.setState({
            colorToReplace: currentColor
          })
        }
    }
  }
  }
    if(parentDiv.children.length>=1) {
    parentDiv.removeChild(parentDiv.children[0]);
  }  
  if(parentDivTop.children.length>=1) {
    parentDivTop.removeChild(parentDivTop.children[0]);
  }  

 /*  if(parentDiv.children.length<1) { */
    const drawCanvas = document.createElement("canvas");
    drawCanvas.id = "drawerCanvas";
     if(this.state.stitchMode===true) {
      drawCanvas.style.filter = "contrast(1.1)";
    } 
    drawCanvas.width = palWidth*zoomVal;
    drawCanvas.height = palHeight*zoomVal;
    parentDiv.appendChild(drawCanvas);

    //Top canvas
    const drawCanvasTop = document.createElement("canvas");
    drawCanvasTop.id = "drawerCanvasTop";
    drawCanvasTop.width = palWidth*zoomVal;
    drawCanvasTop.height = palHeight*zoomVal;
    parentDivTop.appendChild(drawCanvasTop);

    drawCanvas.addEventListener("pointerdown", (e)=>{
      if(this.state.advancedMode==="draw" || this.state.advancedMode==="eraser")
      {
        this.handleMouseDownDrawCanvas(e); 
      } else if(this.state.advancedMode==="colorpicker") {
        this.handleColorPickerClickCanvas(e,cursorRounded);
      } else if(this.state.advancedMode==="buckettool") {
        this.floodFillCanvas(e);
      }
      this.handleMakeChangeDrawCanvas();});

      drawCanvasTop.addEventListener("pointerdown", (e)=>{
        if(this.state.advancedMode==="linetool" || this.state.advancedMode==="selectiontool" || this.state.advancedMode==="squaretool" || this.state.advancedMode==="circletool")
        {
          this.handleMouseDownLineToolCanvas(e); 
        } 
        if(this.state.advancedMode==="selectiontool") {
          if (this.state.selectBox===true) {
            this.handleApplySelectionToolToCanvasNoMouse(e);
            
          } else if (this.state.selectingArea===false) {
            this.setState({selectingArea: true});
          }
        }
        
        this.handleMakeChangeDrawCanvas();
        });

    drawCanvas.addEventListener("pointermove", this.handleOnMouseMove.bind(this),{ passive: true });
    
    drawCanvasTop.addEventListener("pointermove", this.handleOnMouseMoveTop.bind(this),{ passive: true });

    drawCanvasTop.addEventListener("pointerup", (e)=> {
      if(this.state.advancedMode==="selectiontool" && this.state.selectBox===false && this.state.selectionCanStart===true) {
        this.handleMouseUpDrawingCanvasSelectionTool(e);
      }
    })
    
    const drawContext = drawCanvas.getContext("2d");
    drawContext.fillStyle = "#ffffff";
    drawContext.fillRect(0, 0, drawCanvas.width, drawCanvas.height);


    //Rulers!
    const rulerParentX = document.getElementById('drawRulerXWrapper');
    const rulerParentY = document.getElementById('drawRulerYWrapper');
    rulerParentY.style.setProperty("--zoomval",zoomVal+"px");
    rulerParentX.style.setProperty("--zoomval",zoomVal+"px");
    rulerParentY.style.setProperty("--rulernumber",-11+"px");
    rulerParentX.style.setProperty("--rulernumber",-10+"px");
    rulerParentX.style.width = drawCanvas.width+"px";
    rulerParentY.style.height = drawCanvas.height+"px";

    const rulerLineParentX = document.getElementById('drawRulerXLineWrapper');
    const rulerLineParentY = document.getElementById('drawRulerYLineWrapper');
    rulerLineParentX.style.width = drawCanvas.width+zoomVal+"px";
    rulerLineParentY.style.height = drawCanvas.height+zoomVal+"px";

    const rulerLineParentXChildren = rulerLineParentX.children;
    if(rulerLineParentXChildren.length<=0) {
    for(let xx = 0; xx<palWidth+1; xx++) {
      const numberElement = document.createElement('span');

      numberElement.innerHTML = xx;
      numberElement.style.width = "var(--zoomval)";
      numberElement.style.height = "var(--zoomval)";
      numberElement.style.position = "absolute";
      numberElement.style.left = "var(--rulernumber)";
      numberElement.style.top = "16px";
      numberElement.classList.add("no-selection");
      numberElement.style.pointerEvents = 'none';
    const lineElementX = document.createElement('div');
    lineElementX.style.height = "12px";
    lineElementX.style.width = "var(--zoomval)";
    lineElementX.style.borderLeft = "1px solid";
    lineElementX.style.position = "relative";
    rulerLineParentX.appendChild(lineElementX);
    if(xx === Math.round(palWidth / 2)) {
      lineElementX.style.height = "20px";
  lineElementX.style.borderLeft = "3px solid ";
  numberElement.style.top = "20px";

  lineElementX.appendChild(numberElement);
      numberElement.style.fontWeight = "bold";
    }
      else if(xx % 10 === 0 && xx!==0) {
        lineElementX.style.height = "15px";
        lineElementX.style.borderLeft = "3px solid";
        lineElementX.appendChild(numberElement);
      } 
      if(xx===palWidth) {
         lineElementX.appendChild(numberElement);
      }
      
    }
    for(let yy = 0; yy<palHeight+1; yy++) {
      const numberElement = document.createElement('span');
      numberElement.innerHTML = yy;
      numberElement.style.width = "200px";
      numberElement.style.height = "var(--zoomval)";
      numberElement.style.position = "absolute";
      numberElement.style.top = "var(--rulernumber)";
      numberElement.style.left = "-208px";
      numberElement.style.textAlign = "right";
      numberElement.style.display = "inline-block";
      numberElement.classList.add("no-selection");
      numberElement.style.pointerEvents = 'none';
      const lineElementY = document.createElement('div');
      
        lineElementY.style.width = "12px";
        lineElementY.style.borderTop = "1px solid";
        lineElementY.style.position = "relative";
        rulerLineParentY.appendChild(lineElementY);

    if(yy === Math.round(palHeight / 2)) {
      lineElementY.style.width = "20px";
  lineElementY.style.borderTop = "3px solid";
  lineElementY.appendChild(numberElement);
      numberElement.style.fontWeight = "bold";
    } 
      else if(yy % 10 === 0 && yy!==0) {
        lineElementY.appendChild(numberElement);
        lineElementY.style.width = "15px";
        lineElementY.style.borderTop = "3px solid";
      } 
      if(yy===palHeight) {
        lineElementY.appendChild(numberElement);
     }
    }
  }
    //Pixel Guide!
    const guideParentX = document.getElementById('drawGuideWrapperX');
    const guideParentY = document.getElementById('drawGuideWrapperY');
    guideParentY.style.setProperty("--linewidth",drawCanvas.width+"px");
    guideParentY.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--lineheight",drawCanvas.height+"px");
    guideParentX.style.width = drawCanvas.width+"px";
    guideParentX.style.height = drawCanvas.height+"px";
    guideParentY.style.width = drawCanvas.width+"px";
    guideParentY.style.height = drawCanvas.height+"px";

    const guideParentChildren = guideParentX.children;
    if(guideParentChildren.length<=0) {
    for(let xx = 0; xx<palWidth; xx++) {
      const guideElementX = document.createElement('div');
      guideParentX.appendChild(guideElementX);
      /* guideElementX.style.left=xx*zoomVal-1+"px"; */
      guideElementX.style.height = "var(--lineheight)";
      guideElementX.style.width = "var(--linegap)";
      guideElementX.style.position = "relative";
      guideElementX.classList.add("drawGuideElement");
      if(xx!==0) {
      guideElementX.style.borderLeft = "1px solid rgba(0, 0, 0, 0.2)";
      if(xx % 10 === 0) {
        guideElementX.style.borderLeft = "1px solid rgba(0, 0, 0, 0.6)";
      }
      if(xx === Math.round(palWidth / 2)) {
        const guideElementXCenter = document.createElement('div');
        guideElementX.appendChild(guideElementXCenter);
        guideElementXCenter.style.borderLeft = "1px solid rgba(255, 0, 0, 0.6)";
        guideElementXCenter.classList.add("drawGuideElementCenter");
      }
    }
    }
    for(let yy = 0; yy<palHeight; yy++) {
      const guideElementY = document.createElement('div');
      guideParentY.appendChild(guideElementY);
      /* guideElementY.style.top=yy*zoomVal-1+"px"; */
      guideElementY.style.width = "var(--linewidth)";
      guideElementY.style.position = "relative";
      guideElementY.classList.add("drawGuideElement");
      if(yy!==0) {
      guideElementY.style.borderTop = "1px solid rgba(0, 0, 0, 0.2)";
      if(yy % 10 === 0) {
        guideElementY.style.borderTop = "1px solid rgba(0, 0, 0, 0.6)";
      }
      if(yy === Math.round(palHeight / 2)) {
        const guideElementYCenter = document.createElement('div');
        guideElementY.appendChild(guideElementYCenter);
        guideElementYCenter.style.borderTop = "1px solid rgba(255, 0, 0, 0.6)";
        guideElementYCenter.classList.add("drawGuideElementCenter");
      }
    }
    }
  } else {

    const guideParentX = document.getElementById('drawGuideWrapperX');
    const guideParentY = document.getElementById('drawGuideWrapperY');
    guideParentY.style.setProperty("--linewidth",drawCanvas.width+"px");
    guideParentY.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--lineheight",drawCanvas.height+"px");
    guideParentX.style.width = drawCanvas.width+"px";
    guideParentX.style.height = drawCanvas.height+"px";
    guideParentY.style.width = drawCanvas.width+"px";
    guideParentY.style.height = drawCanvas.height+"px";

  }
    //Draw the whole image
    for(let xx = 0; xx<palWidth; xx++) {
      for(let yy = 0; yy<palHeight; yy++) {
        

      const currentColor = rgbMatrix[xx][yy];
      const bColor = this.makeRgbStringDarkerOrLighter(currentColor, stitchPercentBright);
      drawContext.fillStyle = currentColor;
      if(currentColor==="rgba(0,0,0,0)") {
        drawContext.clearRect(((xx)*zoomVal), ((yy)*zoomVal), zoomVal, zoomVal);
      } else {
        if(this.state.stitchMode===true) {      
            var drawZm = zoomVal;   
            const img = document.getElementById('stitchImage');
            drawContext.clearRect((xx)*drawZm,(yy)*drawZm,drawZm,drawZm);
            
            drawContext.beginPath();
/*             drawContext.filter = "saturate(120%)"; */
            drawContext.fillStyle = bColor;
            drawContext.ellipse((xx)*drawZm+ (drawZm/2),(yy)*drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
            drawContext.ellipse((xx)*drawZm+ (drawZm/2),(yy)*drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
            drawContext.fill();
            drawContext.drawImage(img, (xx)*drawZm, (yy)*drawZm, drawZm, drawZm); 
/*             drawContext.filter = "saturate(100%)"; */
            drawContext.fillStyle = currentColor;
            drawContext.fillRect((xx)*drawZm,(yy)*drawZm,1,1);
            drawContext.fillRect((xx)*drawZm+drawZm-1,(yy)*drawZm,1,1);
            drawContext.fillRect((xx)*drawZm+drawZm-1,(yy)*drawZm+drawZm-1,1,1);
            drawContext.fillRect((xx)*drawZm,(yy)*drawZm+drawZm-1,1,1);
        } else {
          drawContext.fillRect(((xx)*zoomVal), ((yy)*zoomVal), zoomVal, zoomVal);
        }
        
      }
      
    }
  }
  if(this.state.advancedMode==='colorreplacer') {
    this.createDMCDataDrawingCanvas();
  }
    
 if(this.state.showLoadingAdvanced===true) {
  this.setState({
    pixelMatrix: rgbMatrix,
    showLoadingAdvanced: false
  })
 }

}

createDrawingCanvasResize = (addToTop, addToLeft, addToRight, addToBottom, originalWidth, originalHeight, undo=false, redo=false) => {
  var palWidth = this.state.widthCanvasDraw;
  var palHeight = this.state.heightCanvasDraw;
  var zoomVal = this.state.drawZoom;
  var rgbArray = [];

/*   if(this.state.savedPatternMode) { */
if(undo && !redo) {
  rgbArray = this.state.drawCanvasUndoStates[this.state.undo+1].rgb;
} else if(redo && undo) {
  rgbArray = this.state.drawCanvasUndoStates[this.state.undo].rgb;
}
else{
  rgbArray = this.state.drawCanvasUndoStates[this.state.undo].rgb;
}
    
/*   } else {
    let pixelArr = [];
    const canvas = document.getElementById("drawerCanvas");
    const ctx = canvas.getContext("2d");
  for(let yy=0; yy<this.state.heightCanvasDraw; yy++) {
    for(let xx=0; xx<this.state.widthCanvasDraw; xx++) {

        const pixel = ctx.getImageData(xx*this.state.drawZoom, yy*this.state.drawZoom, 1, 1).data;
        const rgb = [pixel[0],pixel[1],pixel[2],pixel[3]];
        pixelArr.push(rgb);
    } 
}
rgbArray = pixelArr;
  } */

  palWidth = this.state.widthCanvasDraw;
  palHeight = this.state.heightCanvasDraw;

/*   console.log("Add to top: "+ addToTop);
  console.log("Add to left: "+ addToLeft);
  console.log("Add to right: "+ addToRight);
  console.log("Add to bottom: "+ addToBottom);
  console.log("original width: "+ originalWidth);
  console.log("original height: "+ originalHeight);
  console.log("Heightcanvasdraw: "+ this.state.heightCanvasDraw);
  console.log("Widthcanvasdraw: "+this.state.widthCanvasDraw);
  console.log("cropwidth: "+ this.state.cropWidth);
  console.log("cropheight: "+this.state.cropHeight);  */

  const rgbMatrix = this.createPixelRGBMatrixFromRGBArray(rgbArray,originalWidth,originalHeight);


  //Fix transparent background!
  const transparentBackground = document.querySelector(".transparentBackground");
  transparentBackground.style.setProperty('--drawzoom-pixels', zoomVal*2+"px");


/*   if(this.state.advancedMode==="colorreplacer") {
    for(let xx = 0; xx<palWidth; xx++) {
      for(let yy = 0; yy<palHeight; yy++) {
        const currentColor = rgbMatrix[xx][yy];
        const ctx = parentDiv.children[0].getContext("2d");
        const pixels = ctx.getImageData(xx*zoomVal,yy*zoomVal,1,1).data;
        var col = "rgba("+pixels[0]+","+pixels[1]+","+pixels[2]+","+pixels[3]+")";
        if(currentColor!==col) {
          this.setState({
            colorToReplace: currentColor
          })
        }
    }
  }
  } */
  
  const parentDiv = document.getElementById('drawerWrapperCanvas');
  const parentDivTop = document.getElementById('drawerWrapperCanvasTop');
  const cursorRounded = document.querySelector('.cRounded');

  if(parentDiv.children.length>=1) {
    parentDiv.removeChild(parentDiv.children[0]);
  }  
  if(parentDivTop.children.length>=1) {
    parentDivTop.removeChild(parentDivTop.children[0]);
  }  

 /*  if(parentDiv.children.length<1) { */
    const drawCanvas = document.createElement("canvas");
    drawCanvas.id = "drawerCanvas";
     if(this.state.stitchMode===true) {
      drawCanvas.style.filter = "contrast(1.1)";
    } 
    drawCanvas.width = palWidth*zoomVal;
    drawCanvas.height = palHeight*zoomVal;
    parentDiv.appendChild(drawCanvas);
    //Top canvas
    const drawCanvasTop = document.createElement("canvas");
    drawCanvasTop.id = "drawerCanvasTop";
    drawCanvasTop.width = palWidth*zoomVal;
    drawCanvasTop.height = palHeight*zoomVal;
    parentDivTop.appendChild(drawCanvasTop);


    drawCanvas.addEventListener("pointerdown", (e)=>{
      if(this.state.advancedMode==="draw" || this.state.advancedMode==="eraser")
      {
        this.handleMouseDownDrawCanvas(e); 
      } else if(this.state.advancedMode==="colorpicker") {
        this.handleColorPickerClickCanvas(e,cursorRounded);
      } else if(this.state.advancedMode==="buckettool") {
        this.floodFillCanvas(e);
      }
      this.handleMakeChangeDrawCanvas();});

      drawCanvasTop.addEventListener("pointerdown", (e)=>{
        if(this.state.advancedMode==="linetool" || this.state.advancedMode==="selectiontool" || this.state.advancedMode==="squaretool" || this.state.advancedMode==="circletool")
        {
          this.handleMouseDownLineToolCanvas(e); 
        } 
        if(this.state.advancedMode==="selectiontool") {
          if (this.state.selectBox===true) {
            this.handleApplySelectionToolToCanvasNoMouse(e);
            
          } else if (this.state.selectingArea===false) {
            this.setState({selectingArea: true});
          }
        }
        this.handleMakeChangeDrawCanvas();
        });

    drawCanvas.addEventListener("pointermove", this.handleOnMouseMove.bind(this),{ passive: true });

     drawCanvasTop.addEventListener("pointermove", this.handleOnMouseMoveTop.bind(this),{ passive: true });
     
     drawCanvasTop.addEventListener("pointerup", (e)=> {
      if(this.state.advancedMode==="selectiontool" && this.state.selectBox===false && this.state.selectionCanStart===true) {
        this.handleMouseUpDrawingCanvasSelectionTool(e);
      }
    })
    
    const drawContext = drawCanvas.getContext("2d");
    drawContext.fillStyle = "#ffffff";
    drawContext.fillRect(0, 0, drawCanvas.width, drawCanvas.height);


    //Rulers!
    const rulerParentX = document.getElementById('drawRulerXWrapper');
    const rulerParentY = document.getElementById('drawRulerYWrapper');
    rulerParentY.style.setProperty("--zoomval",zoomVal+"px");
    rulerParentX.style.setProperty("--zoomval",zoomVal+"px");
    rulerParentY.style.setProperty("--rulernumber",-11+"px");
    rulerParentX.style.setProperty("--rulernumber",-10+"px");
    rulerParentX.style.width = drawCanvas.width+"px";
    rulerParentY.style.height = drawCanvas.height+"px";

    const rulerLineParentX = document.getElementById('drawRulerXLineWrapper');
    const rulerLineParentY = document.getElementById('drawRulerYLineWrapper');
    rulerLineParentX.style.width = drawCanvas.width+zoomVal+"px";
    rulerLineParentY.style.height = drawCanvas.height+zoomVal+"px";
    
    //const rulerLineParentXChildren = rulerLineParentX.children;

    while(rulerLineParentX.children.length>=1) {
      rulerLineParentX.removeChild(rulerLineParentX.children[0]);
    }  
    while(rulerLineParentY.children.length>=1) {
      rulerLineParentY.removeChild(rulerLineParentY.children[0]);
    }  

    for(let xx = 0; xx<palWidth+1; xx++) {
      const numberElement = document.createElement('span');

      numberElement.innerHTML = xx;
      numberElement.style.width = "var(--zoomval)";
      numberElement.style.height = "var(--zoomval)";
      numberElement.style.position = "absolute";
      numberElement.style.left = "var(--rulernumber)";
      numberElement.style.top = "16px";
      numberElement.classList.add("no-selection");
      numberElement.style.pointerEvents = 'none';
    const lineElementX = document.createElement('div');
    lineElementX.style.height = "12px";
    lineElementX.style.width = "var(--zoomval)";
    lineElementX.style.borderLeft = "1px solid";
    lineElementX.style.position = "relative";
    rulerLineParentX.appendChild(lineElementX);

    if(xx === Math.round(palWidth / 2)) {
      lineElementX.style.height = "20px";
  lineElementX.style.borderLeft = "3px solid ";
  numberElement.style.top = "20px";

  lineElementX.appendChild(numberElement);
      numberElement.style.fontWeight = "bold";
    }
      else if(xx % 10 === 0 && xx!==0) {
        lineElementX.style.height = "15px";
        lineElementX.style.borderLeft = "3px solid";
        lineElementX.appendChild(numberElement);
      } 
      if(xx===palWidth) {
         lineElementX.appendChild(numberElement);
      }
      
    }
    for(let yy = 0; yy<palHeight+1; yy++) {
      const numberElement = document.createElement('span');
      numberElement.innerHTML = yy;
      numberElement.style.width = "200px";
      numberElement.style.height = "var(--zoomval)";
      numberElement.style.position = "absolute";
      numberElement.style.top = "var(--rulernumber)";
      numberElement.style.left = "-208px";
      numberElement.style.textAlign = "right";
      numberElement.style.display = "inline-block";
      numberElement.classList.add("no-selection");
      numberElement.style.pointerEvents = 'none';
      const lineElementY = document.createElement('div');
      
        lineElementY.style.width = "12px";
        lineElementY.style.borderTop = "1px solid";
        lineElementY.style.position = "relative";
        rulerLineParentY.appendChild(lineElementY);

    if(yy === Math.round(palHeight / 2)) {
      lineElementY.style.width = "20px";
  lineElementY.style.borderTop = "3px solid";
  lineElementY.appendChild(numberElement);
      numberElement.style.fontWeight = "bold";
    } 
      else if(yy % 10 === 0 && yy!==0) {
        lineElementY.appendChild(numberElement);
        lineElementY.style.width = "15px";
        lineElementY.style.borderTop = "3px solid";
      } 
      if(yy===palHeight) {
        lineElementY.appendChild(numberElement);
     }
    }

    //Pixel Guide!
    const guideParentX = document.getElementById('drawGuideWrapperX');
    const guideParentY = document.getElementById('drawGuideWrapperY');
    guideParentY.style.setProperty("--linewidth",drawCanvas.width+"px");
    guideParentY.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--lineheight",drawCanvas.height+"px");
    guideParentX.style.width = drawCanvas.width+"px";
    guideParentX.style.height = drawCanvas.height+"px";
    guideParentY.style.width = drawCanvas.width+"px";
    guideParentY.style.height = drawCanvas.height+"px";

    //const guideParentChildren = guideParentX.children;

    while(guideParentX.children.length>=1) {
      guideParentX.removeChild(guideParentX.children[0]);
    }  
    while(guideParentY.children.length>=1) {
      guideParentY.removeChild(guideParentY.children[0]);
    }  

    for(let xx = 0; xx<palWidth; xx++) {
      const guideElementX = document.createElement('div');
      guideParentX.appendChild(guideElementX);
      /* guideElementX.style.left=xx*zoomVal-1+"px"; */
      guideElementX.style.height = "var(--lineheight)";
      guideElementX.style.width = "var(--linegap)";
      guideElementX.style.position = "relative";
      guideElementX.classList.add("drawGuideElement");
      if(xx!==0) {
      guideElementX.style.borderLeft = "1px solid rgba(0, 0, 0, 0.2)";
      if(xx % 10 === 0) {
        guideElementX.style.borderLeft = "1px solid rgba(0, 0, 0, 0.6)";
      }
      if(xx === Math.round(palWidth / 2)) {
        const guideElementXCenter = document.createElement('div');
        guideElementX.appendChild(guideElementXCenter);
        guideElementXCenter.style.borderLeft = "1px solid rgba(255, 0, 0, 0.6)";
        guideElementXCenter.classList.add("drawGuideElementCenter");
      }
    }
    }
    for(let yy = 0; yy<palHeight; yy++) {
      const guideElementY = document.createElement('div');
      guideParentY.appendChild(guideElementY);
      /* guideElementY.style.top=yy*zoomVal-1+"px"; */
      guideElementY.style.width = "var(--linewidth)";
      guideElementY.style.position = "relative";
      guideElementY.classList.add("drawGuideElement");
      if(yy!==0) {
      guideElementY.style.borderTop = "1px solid rgba(0, 0, 0, 0.2)";
      if(yy % 10 === 0) {
        guideElementY.style.borderTop = "1px solid rgba(0, 0, 0, 0.6)";
      }
      if(yy === Math.round(palHeight / 2)) {
        const guideElementYCenter = document.createElement('div');
        guideElementY.appendChild(guideElementYCenter);
        guideElementYCenter.style.borderTop = "1px solid rgba(255, 0, 0, 0.6)";
        guideElementYCenter.classList.add("drawGuideElementCenter");
      }
    }
    }

     //Draw the whole image
     let extraTop = 0;
     let extraLeft = 0;

     if(addToTop<0) {
      extraTop = Math.abs(addToTop);
     }
     if(addToLeft<0) {
      extraLeft = Math.abs(addToLeft);
     }

     for(let xx = 0; xx<originalWidth+addToRight+addToLeft+extraLeft; xx++) {
      for(let yy = 0; yy<originalHeight+addToBottom+addToTop+extraTop; yy++) {
        
      const currentColor = rgbMatrix?.[xx]?.[yy];

      if(xx<addToLeft) {
        drawContext.clearRect(((xx)*zoomVal), ((yy)*zoomVal), zoomVal, zoomVal);
      }
      if(yy<addToTop) {
        drawContext.clearRect(((xx)*zoomVal), ((yy)*zoomVal), zoomVal, zoomVal);
      }
      if(yy>=originalHeight+addToTop) {
        drawContext.clearRect(((xx)*zoomVal), ((yy)*zoomVal), zoomVal, zoomVal);
      }

       if(xx>=originalWidth+addToLeft) {
        drawContext.clearRect(((xx)*zoomVal), ((yy)*zoomVal), zoomVal, zoomVal);
      } 
      if(currentColor==="rgba(0,0,0,0)") {
        drawContext.clearRect(((xx+addToLeft)*zoomVal), ((yy+addToTop)*zoomVal), zoomVal, zoomVal);
      } else if(currentColor===undefined) {
        drawContext.clearRect(((xx+addToLeft)*zoomVal), ((yy+addToTop)*zoomVal), zoomVal, zoomVal);
      }
      else {
        drawContext.fillStyle = currentColor;
        if(this.state.stitchMode===true) {  
          const bColor = this.makeRgbStringDarkerOrLighter(currentColor, stitchPercentBright);
            var drawZm = zoomVal;   
            const img = document.getElementById('stitchImage');
            drawContext.clearRect((xx+addToLeft)*drawZm,(yy+addToTop)*drawZm,drawZm,drawZm);
            
            drawContext.beginPath();
/*             drawContext.filter = "saturate(120%)"; */
            drawContext.fillStyle = bColor;
            drawContext.ellipse((xx+addToLeft)*drawZm+ (drawZm/2),(yy+addToTop)*drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
            drawContext.ellipse((xx+addToLeft)*drawZm+ (drawZm/2),(yy+addToTop)*drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
            drawContext.fill();
            drawContext.drawImage(img, (xx+addToLeft)*drawZm, (yy+addToTop)*drawZm, drawZm, drawZm); 
/*             drawContext.filter = "saturate(100%)"; */
            drawContext.fillStyle = currentColor;
            drawContext.fillRect((xx+addToLeft)*drawZm,(yy+addToTop)*drawZm,1,1);
            drawContext.fillRect((xx+addToLeft)*drawZm+drawZm-1,(yy+addToTop)*drawZm,1,1);
            drawContext.fillRect((xx+addToLeft)*drawZm+drawZm-1,(yy+addToTop)*drawZm+drawZm-1,1,1);
            drawContext.fillRect((xx+addToLeft)*drawZm,(yy+addToTop)*drawZm+drawZm-1,1,1);
        } else {
          drawContext.fillRect(((xx+addToLeft)*zoomVal), ((yy+addToTop)*zoomVal), zoomVal, zoomVal);
        }
        
      }
      
    }
  }
  let pixelArr = [];
  for(let yy=0; yy<this.state.heightCanvasDraw; yy++) {
    for(let xx=0; xx<this.state.widthCanvasDraw; xx++) {

        const pixel = drawContext.getImageData(xx*this.state.drawZoom, yy*this.state.drawZoom, 1, 1).data;
        const rgb = [pixel[0],pixel[1],pixel[2],pixel[3]];
        pixelArr.push(rgb);
    } 
}


  //Reset undo and start fresh
  var pal_canvas = document.getElementById("pal_final");
  pal_canvas.width = this.state.widthCanvasDraw;
  pal_canvas.height = this.state.heightCanvasDraw;


  var undoNumber = this.state.undo;
  let undoStateArr = this.state.drawCanvasUndoStates;
  var clear = false
  if(!undo) {
  while(undoStateArr.length-1!==undoNumber) {
    //Delete all the states after the current state when creating a new state if you have undone an action
    undoStateArr.pop();
  }
  clear = true;
  undoNumber++;
  undoStateArr.push({rgb: pixelArr, canvasSize: {top:addToTop,left: addToLeft,right:addToRight,bottom:addToBottom,orgHeight:this.state.heightCanvasDraw,orgWidth:this.state.widthCanvasDraw}});

  this.setState({
    canClear: clear,
    undo: undoNumber,
    drawCanvasUndoStates: undoStateArr,
    rgbArrayCanvas: pixelArr,
    rgbArray: pixelArr,
    madeChangeDrawCanvas: false,
    madeChangeSavedPattern: true
  },()=> {
    this.createDMCDataDrawingCanvas();
  });
} else {
  this.createDrawingCanvasSavedPattern(this.state.drawZoom);
}
  
}


createDrawingCanvasSavedPattern = (zoomVal, fromBlank=false, blankWidth, blankHeight) => {
  var rgbArray = [];
  var palWidth= 0;
  var palHeight= 0;
  const parentDiv = document.getElementById('drawerWrapperCanvas');
  const parentDivTop = document.getElementById('drawerWrapperCanvasTop');
  const cursorRounded = document.querySelector('.cRounded');
  if(this.state.rgbArrayCanvas.length<=0) {

/*  const cursorPointed = document.querySelector('.cPointed');  */

    parentDiv.addEventListener('mouseenter', (e)=> {
      if(this.state.advancedMode!=='colorpicker') return;
      cursorRounded.style.visibility = 'visible'
    });
    parentDiv.addEventListener('mouseleave', (e)=> {
      if(this.state.advancedMode!=='colorpicker') return;
      cursorRounded.style.visibility = 'hidden'
    });
    if(fromBlank===true) {
      palWidth = blankWidth;
      palHeight = blankHeight;
      for (var yy = 0; yy < blankHeight*blankWidth; yy++) {
          rgbArray.push([0, 0, 0, 0]);
      }
      //console.log(rgbArray);
    } else {
      var pal = document.getElementById('previewCanvasPattern');
      var ctx = pal.getContext("2d");
      palWidth = pal.width;
      palHeight = pal.height;
      const pixel = ctx.getImageData(0, 0, palWidth, palHeight);
      const data = pixel.data;
      for (var i = 0; i < data.length; i += 4) {
        rgbArray.push([data[i], data[i + 1], data[i + 2], data[[i + 3]]])
      }
      //console.log(rgbArray);
    }
  
  let undoStateArr = [];
  undoStateArr.push({rgb: rgbArray, canvasSize: {top:0,left:0,right:0,bottom:0,orgHeight:this.state.cropHeight,orgWidth:this.state.cropWidth}});

  document.removeEventListener("keydown", (e)=> {
    this.keyHandlerUndoRedo(e);
    this.keyHandlerShift(e);
    this.keyHandlerDelete(e);
    this.keyHandlerCopyPasteSelection(e);
});
document.addEventListener("keyup", (e)=> {
  this.keyHandlerShift(e);
});
  document.addEventListener('pointerup', (e)=>{
    if(this.state.advancedMode==="draw" || this.state.advancedMode==="eraser" || this.state.advancedMode==="buckettool" || this.state.advancedMode==="linetool" 
    || this.state.advancedMode==="squaretool" || this.state.advancedMode==="circletool")
    {
      this.handleMouseUpDrawingCanvas(e);
    } /* else if(this.state.advancedMode==="selectiontool" && this.state.selectBox===false) {
      this.handleMouseUpDrawingCanvasSelectionTool(e);
    } */
  });
  document.addEventListener("keydown", (e)=> {
      this.keyHandlerUndoRedo(e);
      this.keyHandlerShift(e);
      this.keyHandlerDelete(e);
      this.keyHandlerCopyPasteSelection(e);
  });
  document.addEventListener("keyup", (e)=> {
    this.keyHandlerShift(e);
  });

  this.setState({
    drawCanvasUndoStates: undoStateArr,
    rgbArrayCanvas: rgbArray,
    widthCanvasDraw: palWidth,
    heightCanvasDraw: palHeight
  });
} else {
/*   */
  rgbArray = this.state.drawCanvasUndoStates[this.state.undo].rgb;
  palWidth = this.state.widthCanvasDraw;
  palHeight = this.state.heightCanvasDraw;
}
  /* console.log(rgbArray);
  console.log(palWidth);
  console.log(palHeight); */
  const rgbMatrix = this.createPixelRGBMatrixFromRGBArray(rgbArray,palWidth,palHeight);

  //Fix transparent background!
  const transparentBackground = document.querySelector(".transparentBackground");
  transparentBackground.style.setProperty('--drawzoom-pixels', zoomVal*2+"px");
  if(this.state.advancedMode==="colorreplacer") {
    for(let xx = 0; xx<palWidth; xx++) {
      for(let yy = 0; yy<palHeight; yy++) {
        const currentColor = rgbMatrix[xx][yy];
        const ctx = parentDiv.children[0].getContext("2d");
        const pixels = ctx.getImageData(xx*zoomVal,yy*zoomVal,1,1).data;
        var col = "rgba("+pixels[0]+","+pixels[1]+","+pixels[2]+","+pixels[3]+")";
        if(currentColor!==col) {
          this.setState({
            colorToReplace: currentColor
          })
        }
    }
  }
  }
    if(parentDiv.children.length>=1) {
    parentDiv.removeChild(parentDiv.children[0]);
  }  
  if(parentDivTop.children.length>=1) {
    parentDivTop.removeChild(parentDivTop.children[0]);
  }  

 /*  if(parentDiv.children.length<1) { */
    const drawCanvas = document.createElement("canvas");
    drawCanvas.id = "drawerCanvas";
     if(this.state.stitchMode===true) {
      drawCanvas.style.filter = "contrast(1.1)";
    } 
    drawCanvas.width = palWidth*zoomVal;
    drawCanvas.height = palHeight*zoomVal;
    parentDiv.appendChild(drawCanvas);
    //Top canvas
    const drawCanvasTop = document.createElement("canvas");
    drawCanvasTop.id = "drawerCanvasTop";
    drawCanvasTop.width = palWidth*zoomVal;
    drawCanvasTop.height = palHeight*zoomVal;
    parentDivTop.appendChild(drawCanvasTop);


    drawCanvas.addEventListener("pointerdown", (e)=>{
      if(this.state.advancedMode==="draw" || this.state.advancedMode==="eraser")
      {
        this.handleMouseDownDrawCanvas(e); 
      } else if(this.state.advancedMode==="colorpicker") {
        this.handleColorPickerClickCanvas(e,cursorRounded);
      } else if(this.state.advancedMode==="buckettool") {
        this.floodFillCanvas(e);
      }
      this.handleMakeChangeDrawCanvas();});

      drawCanvasTop.addEventListener("pointerdown", (e)=>{
        if(this.state.advancedMode==="linetool" || this.state.advancedMode==="selectiontool" || this.state.advancedMode==="squaretool" || this.state.advancedMode==="circletool")
        {
          this.handleMouseDownLineToolCanvas(e); 
        } 
        if(this.state.advancedMode==="selectiontool") {
          if (this.state.selectBox===true) {
            this.handleApplySelectionToolToCanvasNoMouse(e);
            
          } else if (this.state.selectingArea===false) {
            this.setState({selectingArea: true});
          }
        }
        this.handleMakeChangeDrawCanvas();
        });

    drawCanvas.addEventListener("pointermove", this.handleOnMouseMove.bind(this),{ passive: true });

     drawCanvasTop.addEventListener("pointermove", this.handleOnMouseMoveTop.bind(this),{ passive: true });
     
     drawCanvasTop.addEventListener("pointerup", (e)=> {
      if(this.state.advancedMode==="selectiontool" && this.state.selectBox===false && this.state.selectionCanStart===true) {
        this.handleMouseUpDrawingCanvasSelectionTool(e);
      }
    })

/*     document.addEventListener("keydown", (e)=> {
      if (e.repeat) return;
     
      if (e.key == "z" && e.ctrlKey && this.state.undo>=1) {
        this.handleUndoDrawing(e);
      } else if (e.key == "y" && e.ctrlKey && this.state.undo!==this.state.drawCanvasUndoStates.length-1) {
        this.handleRedoDrawing(e);
      } /* else if (e.ctrlKey && this.state.advancedMode==="draw") {
        this.setState({advancedMode: "colorpicker"});
      } */
      /*
    }, false); */


/*     document.addEventListener("keyup", (e)=> { 
      if(e.ctrlKey && this.state.advancedMode==="colorpicker") {
        this.setState({advancedMode: "draw"});
      }
    }, false) */
    
    const drawContext = drawCanvas.getContext("2d");
    drawContext.fillStyle = "#ffffff";
    drawContext.fillRect(0, 0, drawCanvas.width, drawCanvas.height);
/*     const CELL_SIDE_COUNT = pal.width;
    const cellPixelLength = drawCanvas.width / CELL_SIDE_COUNT; */

    //Rulers!
    const rulerParentX = document.getElementById('drawRulerXWrapper');
    const rulerParentY = document.getElementById('drawRulerYWrapper');
    rulerParentY.style.setProperty("--zoomval",zoomVal+"px");
    rulerParentX.style.setProperty("--zoomval",zoomVal+"px");
    rulerParentY.style.setProperty("--rulernumber",-11+"px");
    rulerParentX.style.setProperty("--rulernumber",-10+"px");
    rulerParentX.style.width = drawCanvas.width+"px";
    rulerParentY.style.height = drawCanvas.height+"px";

    const rulerLineParentX = document.getElementById('drawRulerXLineWrapper');
    const rulerLineParentY = document.getElementById('drawRulerYLineWrapper');
    rulerLineParentX.style.width = drawCanvas.width+zoomVal+"px";
    rulerLineParentY.style.height = drawCanvas.height+zoomVal+"px";
    
    const rulerLineParentXChildren = rulerLineParentX.children;
    if(rulerLineParentXChildren.length<=0) {
    for(let xx = 0; xx<palWidth+1; xx++) {
      const numberElement = document.createElement('span');

      numberElement.innerHTML = xx;
      numberElement.style.width = "var(--zoomval)";
      numberElement.style.height = "var(--zoomval)";
      numberElement.style.position = "absolute";
      numberElement.style.left = "var(--rulernumber)";
      numberElement.style.top = "16px";
      numberElement.classList.add("no-selection");
      numberElement.style.pointerEvents = 'none';
    const lineElementX = document.createElement('div');
    lineElementX.style.height = "12px";
    lineElementX.style.width = "var(--zoomval)";
    lineElementX.style.borderLeft = "1px solid";
    lineElementX.style.position = "relative";
    rulerLineParentX.appendChild(lineElementX);
/*     const rulerNumberDiv = document.createElement("div");
  rulerNumberDiv.style.position = "relative";
  rulerNumberDiv.style.width = "var(--zoomval)";
  rulerNumberDiv.style.textAlign = "end"; */
    if(xx === Math.round(palWidth / 2)) {
      lineElementX.style.height = "20px";
  lineElementX.style.borderLeft = "3px solid ";
  numberElement.style.top = "20px";

  lineElementX.appendChild(numberElement);
      numberElement.style.fontWeight = "bold";
    }
      else if(xx % 10 === 0 && xx!==0) {
        lineElementX.style.height = "15px";
        lineElementX.style.borderLeft = "3px solid";
        lineElementX.appendChild(numberElement);
      } 
      if(xx===palWidth) {
         lineElementX.appendChild(numberElement);
      }
      
    }
    for(let yy = 0; yy<palHeight+1; yy++) {
      const numberElement = document.createElement('span');
      numberElement.innerHTML = yy;
      numberElement.style.width = "200px";
      numberElement.style.height = "var(--zoomval)";
      numberElement.style.position = "absolute";
      numberElement.style.top = "var(--rulernumber)";
      numberElement.style.left = "-208px";
      numberElement.style.textAlign = "right";
      numberElement.style.display = "inline-block";
      numberElement.classList.add("no-selection");
      numberElement.style.pointerEvents = 'none';
      const lineElementY = document.createElement('div');
      
        lineElementY.style.width = "12px";
        lineElementY.style.borderTop = "1px solid";
        lineElementY.style.position = "relative";
        rulerLineParentY.appendChild(lineElementY);

    if(yy === Math.round(palHeight / 2)) {
      lineElementY.style.width = "20px";
  lineElementY.style.borderTop = "3px solid";
  lineElementY.appendChild(numberElement);
      numberElement.style.fontWeight = "bold";
    } 
      else if(yy % 10 === 0 && yy!==0) {
        lineElementY.appendChild(numberElement);
        lineElementY.style.width = "15px";
        lineElementY.style.borderTop = "3px solid";
      } 
      if(yy===palHeight) {
        lineElementY.appendChild(numberElement);
     }
    }
  }
    //Pixel Guide!
    const guideParentX = document.getElementById('drawGuideWrapperX');
    const guideParentY = document.getElementById('drawGuideWrapperY');
    guideParentY.style.setProperty("--linewidth",drawCanvas.width+"px");
    guideParentY.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--lineheight",drawCanvas.height+"px");
    guideParentX.style.width = drawCanvas.width+"px";
    guideParentX.style.height = drawCanvas.height+"px";
    guideParentY.style.width = drawCanvas.width+"px";
    guideParentY.style.height = drawCanvas.height+"px";

    const guideParentChildren = guideParentX.children;
    if(guideParentChildren.length<=0) {
    for(let xx = 0; xx<palWidth; xx++) {
      const guideElementX = document.createElement('div');
      guideParentX.appendChild(guideElementX);
      /* guideElementX.style.left=xx*zoomVal-1+"px"; */
      guideElementX.style.height = "var(--lineheight)";
      guideElementX.style.width = "var(--linegap)";
      guideElementX.style.position = "relative";
      guideElementX.classList.add("drawGuideElement");
      if(xx!==0) {
      guideElementX.style.borderLeft = "1px solid rgba(0, 0, 0, 0.2)";
      if(xx % 10 === 0) {
        guideElementX.style.borderLeft = "1px solid rgba(0, 0, 0, 0.6)";
      }
      if(xx === Math.round(palWidth / 2)) {
        const guideElementXCenter = document.createElement('div');
        guideElementX.appendChild(guideElementXCenter);
        guideElementXCenter.style.borderLeft = "1px solid rgba(255, 0, 0, 0.6)";
        guideElementXCenter.classList.add("drawGuideElementCenter");
      }
    }
    }
    for(let yy = 0; yy<palHeight; yy++) {
      const guideElementY = document.createElement('div');
      guideParentY.appendChild(guideElementY);
      /* guideElementY.style.top=yy*zoomVal-1+"px"; */
      guideElementY.style.width = "var(--linewidth)";
      guideElementY.style.position = "relative";
      guideElementY.classList.add("drawGuideElement");
      if(yy!==0) {
      guideElementY.style.borderTop = "1px solid rgba(0, 0, 0, 0.2)";
      if(yy % 10 === 0) {
        guideElementY.style.borderTop = "1px solid rgba(0, 0, 0, 0.6)";
      }
      if(yy === Math.round(palHeight / 2)) {
        const guideElementYCenter = document.createElement('div');
        guideElementY.appendChild(guideElementYCenter);
        guideElementYCenter.style.borderTop = "1px solid rgba(255, 0, 0, 0.6)";
        guideElementYCenter.classList.add("drawGuideElementCenter");
      }
    }
    }
  } else {

    const guideParentX = document.getElementById('drawGuideWrapperX');
    const guideParentY = document.getElementById('drawGuideWrapperY');
    guideParentY.style.setProperty("--linewidth",drawCanvas.width+"px");
    guideParentY.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--linegap",zoomVal+"px");
    guideParentX.style.setProperty("--lineheight",drawCanvas.height+"px");
    guideParentX.style.width = drawCanvas.width+"px";
    guideParentX.style.height = drawCanvas.height+"px";
    guideParentY.style.width = drawCanvas.width+"px";
    guideParentY.style.height = drawCanvas.height+"px";
    /* while(guideParent.firstChild) {
      guideParent.removeChild(guideParent.lastChild);
    }
    for(let xx = 0; xx<palWidth; xx++) {
      const guideElementX = document.createElement('div');
      guideParent.appendChild(guideElementX);
      guideElementX.style.left=xx*zoomVal-1+"px";
      guideElementX.style.height = drawCanvas.height+"px";
      guideElementX.classList.add("drawGuideElement");
      if(this.state.drawGrid===true) {
        if(xx % 10 === 0) {
          guideElementX.style.border = "1px solid rgba(0, 0, 0, 0.2)";
        } else {
          guideElementX.style.border = "1px solid rgba(0, 0, 0, 0.1)";
        }
        if(xx === Math.round(palWidth / 2)) {
          guideElementX.style.border = "1px solid rgba(255, 0, 0, 0.3)";
        }
      } else {
        if(xx % 10 === 0) {
          guideElementX.style.border = "1px dashed rgba(0, 0, 0, 0)";
        } else {
          guideElementX.style.border = "1px solid rgba(0, 0, 0, 0)";
        }
        if(xx === Math.round(palWidth / 2)) {
          guideElementX.style.border = "1px solid rgba(255, 0, 0, 0)";
        }
      }
    }
    for(let yy = 0; yy<palHeight; yy++) {
      const guideElementY = document.createElement('div');
      guideParent.appendChild(guideElementY);
      guideElementY.style.top=yy*zoomVal-1+"px";
      guideElementY.style.width = drawCanvas.width+"px";
      guideElementY.classList.add("drawGuideElement");
      if(this.state.drawGrid===true) {
      if(yy % 10 === 0) {
              guideElementY.style.border = "1px solid rgba(0, 0, 0, 0.2)";
            } else {
              guideElementY.style.border = "1px solid rgba(0, 0, 0, 0.1)";
            }
            if(yy === Math.round(palHeight / 2)) {
              guideElementY.style.border = "1px solid rgba(255, 0, 0, 0.3)";
            }
            
        
      } else {
        if(yy % 10 === 0) {
          guideElementY.style.border = "1px dashed rgba(0, 0, 0, 0)";
        } else {
          guideElementY.style.border = "1px solid rgba(0, 0, 0, 0)";
        }
        if(yy === Math.round(palHeight / 2)) {
          guideElementY.style.border = "1px solid rgba(255, 0, 0, 0)";
        }
      }
    } */
    /* guideParentChildren.forEach((el)=> {
      el.style.top=yy*zoomVal-1+"px";
      el.style.width = drawCanvas.width+"px";
    }); */
  }

/*      guide.style.width = `${drawCanvas.width}px`;
    guide.style.height = `${drawCanvas.height}px`;
    guide.style.gridTemplateColumns = `repeat(${CELL_SIDE_COUNT}, 1fr)`;
    guide.style.gridTemplateRows = `repeat(${CELL_SIDE_COUNT}, 1fr)`;
    guide.style.setProperty('--drawergrid-cols', pal.width); */

/*     [...Array(CELL_SIDE_COUNT ** 2)].forEach(() =>
      guide.insertAdjacentHTML("beforeend", "<div></div>")
    );  */

    //Draw the whole image
    for(let xx = 0; xx<palWidth; xx++) {
      for(let yy = 0; yy<palHeight; yy++) {
        
        
/*       const x = xx*20-1 - canvasBoundingRect.left;
      const y = yy*20-1 - canvasBoundingRect.top; */
/*       const cellX = Math.floor(xx*20-1 / cellPixelLength);
      const cellY = Math.floor(yy*20-1 / cellPixelLength);
      const currentColor = rgbMatrix[xx-1][yy-1];
      const startX = cellX * cellPixelLength;
      const startY = cellY * cellPixelLength; */
      const currentColor = rgbMatrix[xx][yy];
      const bColor = this.makeRgbStringDarkerOrLighter(currentColor, stitchPercentBright);
      drawContext.fillStyle = currentColor;
      if(currentColor==="rgba(0,0,0,0)") {
        drawContext.clearRect(((xx)*zoomVal), ((yy)*zoomVal), zoomVal, zoomVal);
      } else {
        if(this.state.stitchMode===true) {  
            var drawZm = zoomVal;   
            const img = document.getElementById('stitchImage');
            drawContext.clearRect((xx)*drawZm,(yy)*drawZm,drawZm,drawZm);
            
            drawContext.beginPath();
/*             drawContext.filter = "saturate(120%)"; */
            drawContext.fillStyle = bColor;
            drawContext.ellipse((xx)*drawZm+ (drawZm/2),(yy)*drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
            drawContext.ellipse((xx)*drawZm+ (drawZm/2),(yy)*drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
            drawContext.fill();
            drawContext.drawImage(img, (xx)*drawZm, (yy)*drawZm, drawZm, drawZm); 
/*             drawContext.filter = "saturate(100%)"; */
            drawContext.fillStyle = currentColor;
            drawContext.fillRect((xx)*drawZm,(yy)*drawZm,1,1);
            drawContext.fillRect((xx)*drawZm+drawZm-1,(yy)*drawZm,1,1);
            drawContext.fillRect((xx)*drawZm+drawZm-1,(yy)*drawZm+drawZm-1,1,1);
            drawContext.fillRect((xx)*drawZm,(yy)*drawZm+drawZm-1,1,1);
        } else {
          drawContext.fillRect(((xx)*zoomVal), ((yy)*zoomVal), zoomVal, zoomVal);
        }
        
      }
      
    }
  }
  if(this.state.advancedMode==='colorreplacer') {
    this.createDMCDataDrawingCanvas();
    /* if(col!==null) {
      this.setState({colorToReplace: col});
    } */
  }
    
 /*  } */
 if(this.state.showLoadingAdvanced===true) {
  this.setState({
    pixelMatrix: rgbMatrix,
    showLoadingAdvanced: false
  })
 }

}

handleOnMouseMove=(event)=> {
  const events = event.getCoalescedEvents();
  for (let i = 0; i < events.length; i++)
  {
    
    if(this.state.advancedMode==="draw" || this.state.advancedMode==="eraser") {
      if(events[i].buttons===1 && event.buttons===1)
      {
        this.handleMouseDownDrawCanvas(events[i]);
      }
    } else if(this.state.advancedMode==="colorpicker") {
      const cursorRounded = document.querySelector('.cRounded');
      this.handleColorPickerCursorMove(events[i],cursorRounded);
    }
  }
}

handleOnMouseMoveTop=(event)=> {
  const events = event.getCoalescedEvents();
  for (let i = 0; i < events.length; i++)
  {
    if(events[i].buttons===1 && event.buttons===1)
    {
    if(this.state.advancedMode==="linetool") {
        this.handleMouseDownLineToolCanvasMouseMove(events[i]);
    } else if(this.state.advancedMode==="squaretool") {
        this.handleMouseDownSquareToolCanvasMouseMove(events[i]);
    } else if(this.state.advancedMode==="circletool") {
      this.handleMouseDownCircleToolCanvasMouseMove(events[i]);
    }
     else if(this.state.advancedMode==="selectiontool" && this.state.selectBox===false) {
      this.handleMouseDownSelectionToolCanvasMouseMove(events[i]);
    }
  }
  }
}


handleMakeChangeDrawCanvas = async () => {
  if(this.state.madeChangeDrawCanvas===false) {
    this.setState({
      madeChangeDrawCanvas: true
    });
}
}


drawX = (ctx, shape, x, y, size, width) => {
  // Start at the top left corner and draw an X
  ctx.beginPath();
  x -= size;
  y -= size;
  ctx.moveTo(x, y);
  x += width / 2;
  y -= width / 2;
  ctx.lineTo(x, y);
  x += size;
  y += size;
  ctx.lineTo(x, y);
  x += size;
  y -= size;
  ctx.lineTo(x, y);
  x += width / 2;
  y += width / 2;
  ctx.lineTo(x, y);
  x -= size;
  y += size;
  ctx.lineTo(x, y);
  x += size;
  y += size;
  ctx.lineTo(x, y);
  x -= width / 2;
  y += width / 2;
  ctx.lineTo(x, y);
  x -= size;
  y -= size;
  ctx.lineTo(x, y);
  x -= size;
  y += size;
  ctx.lineTo(x, y);
  x -= width / 2;
  y -= width / 2;
  ctx.lineTo(x, y);
  x += size;
  y -= size;
  ctx.lineTo(x, y);
  x -= size;
  y -= size;
  ctx.lineTo(x, y);
  ctx.stroke();
  ctx.closePath();
  /* ctx.fillStrokeShape(shape); */
};

 handleMouseDownDrawCanvas = (e) => {
  
  
  // Ensure user is using their primary mouse button
/*   if (e.button !== 0) {
    return;
  } */
/*   const CELL_SIDE_COUNT = e.target.width/20;
  const cellPixelLength = e.target.width / CELL_SIDE_COUNT; */
  const canvasBoundingRect = e.target.getBoundingClientRect();
  const x = e.clientX - canvasBoundingRect.left;
  const y = e.clientY - canvasBoundingRect.top;
  const cellX = Math.floor(x / this.state.drawZoom);
  const cellY = Math.floor(y / this.state.drawZoom);

  const startX = cellX * this.state.drawZoom;
  const startY = cellY * this.state.drawZoom;

  const drawContext = e.target.getContext("2d");
  if(this.state.advancedMode==="draw") {
    drawContext.fillStyle = this.state.drawColor;
    if(this.state.drawSize===1) {
      if(this.state.stitchMode===true) {

          
        /* drawContext.lineWidth = 6;
        drawContext.strokeStyle = this.state.drawColor;
        drawContext.beginPath();

        drawContext.moveTo(startX - (this.state.drawZoom/3)+(this.state.drawZoom/2), startY - (this.state.drawZoom/3)+(this.state.drawZoom/2));
        drawContext.lineTo(startX + (this.state.drawZoom/3)+(this.state.drawZoom/2), startY + (this.state.drawZoom/3)+(this.state.drawZoom/2));

        drawContext.moveTo(startX + (this.state.drawZoom/3)+(this.state.drawZoom/2), startY - (this.state.drawZoom/3)+(this.state.drawZoom/2));
        drawContext.lineTo(startX - (this.state.drawZoom/3)+(this.state.drawZoom/2), startY + (this.state.drawZoom/3)+(this.state.drawZoom/2));
        drawContext.stroke();  */
        const img = document.getElementById('stitchImage');
        var drawZm = this.state.drawZoom;
        if(img) {
          drawContext.clearRect(startX,startY,drawZm,drawZm);
/*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX, startY, drawZm, drawZm);
/*           drawContext.filter = "saturate(100%)";  */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX,startY,1,1);
          drawContext.fillRect(startX+drawZm-1,startY,1,1);
          drawContext.fillRect(startX+drawZm-1,startY+drawZm-1,1,1);
          drawContext.fillRect(startX,startY+drawZm-1,1,1);
        }
     
        
      
      
         
        
        
      } else {
      drawContext.fillRect(startX, startY, this.state.drawZoom, this.state.drawZoom);
      }
    } else if (this.state.drawSize===2) {
      if(this.state.stitchMode===true) {

          
        /* drawContext.lineWidth = 6;
        drawContext.strokeStyle = this.state.drawColor;
        drawContext.beginPath();

        drawContext.moveTo(startX - (this.state.drawZoom/3)+(this.state.drawZoom/2), startY - (this.state.drawZoom/3)+(this.state.drawZoom/2));
        drawContext.lineTo(startX + (this.state.drawZoom/3)+(this.state.drawZoom/2), startY + (this.state.drawZoom/3)+(this.state.drawZoom/2));

        drawContext.moveTo(startX + (this.state.drawZoom/3)+(this.state.drawZoom/2), startY - (this.state.drawZoom/3)+(this.state.drawZoom/2));
        drawContext.lineTo(startX - (this.state.drawZoom/3)+(this.state.drawZoom/2), startY + (this.state.drawZoom/3)+(this.state.drawZoom/2));
        drawContext.stroke();  */
        
        var drawZm = this.state.drawZoom;
        const img = document.getElementById('stitchImage');
        if(img) {
          drawContext.clearRect(startX-drawZm, startY-drawZm, drawZm*3, drawZm*3);

          //Top Left
/*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY-drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX -drawZm + (drawZm/2),startY-drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-drawZm, startY-drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-drawZm,startY-drawZm,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY-drawZm,1,1);
          drawContext.fillRect(startX-drawZm,startY-drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY-drawZm+drawZm-1,1,1);
          //Top Middle
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX + (drawZm/2),startY-drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX + (drawZm/2),startY-drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX, startY-drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX,startY-drawZm,1,1);
          drawContext.fillRect(startX+drawZm-1,startY-drawZm,1,1);
          drawContext.fillRect(startX+drawZm-1,startY-drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX,startY-drawZm+drawZm-1,1,1);

          //Top Right
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY-drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY-drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+drawZm, startY-drawZm, drawZm, drawZm);
/*           drawContext.filter = "saturate(100%)";  */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+drawZm,startY-drawZm,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY-drawZm,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY-drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX+drawZm,startY-drawZm+drawZm-1,1,1);
          //Middle Left
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-drawZm, startY, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-drawZm,startY,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY+drawZm-1,1,1);
          drawContext.fillRect(startX-drawZm,startY+drawZm-1,1,1);
          //Middle
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX, startY, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX,startY,1,1);
          drawContext.fillRect(startX+drawZm-1,startY,1,1);
          drawContext.fillRect(startX+drawZm-1,startY+drawZm-1,1,1);
          drawContext.fillRect(startX,startY+drawZm-1,1,1);

          //Middle Right
         
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+drawZm, startY, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+drawZm,startY,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY+drawZm-1,1,1);
          drawContext.fillRect(startX+drawZm,startY+drawZm-1,1,1);
          //Bottom Left
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY+drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY+drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-drawZm, startY+drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-drawZm,startY+drawZm,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY+drawZm,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY+drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX-drawZm,startY+drawZm+drawZm-1,1,1);
          //Bottom Middle
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX + (drawZm/2),startY+drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX + (drawZm/2),startY+drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX, startY+drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX,startY+drawZm,1,1);
          drawContext.fillRect(startX+drawZm-1,startY+drawZm,1,1);
          drawContext.fillRect(startX+drawZm-1,startY+drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX,startY+drawZm+drawZm-1,1,1);
          //Bottom Right
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY+drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY+drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+drawZm, startY+drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+drawZm,startY+drawZm,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY+drawZm,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY+drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX+drawZm,startY+drawZm+drawZm-1,1,1);
        }
        
      } else {
        drawContext.fillRect(startX-this.state.drawZoom, startY-this.state.drawZoom, this.state.drawZoom*3, this.state.drawZoom*3);
      }
      
    } else {

      if(this.state.stitchMode===true) {

          
        /* drawContext.lineWidth = 6;
        drawContext.strokeStyle = this.state.drawColor;
        drawContext.beginPath();

        drawContext.moveTo(startX - (this.state.drawZoom/3)+(this.state.drawZoom/2), startY - (this.state.drawZoom/3)+(this.state.drawZoom/2));
        drawContext.lineTo(startX + (this.state.drawZoom/3)+(this.state.drawZoom/2), startY + (this.state.drawZoom/3)+(this.state.drawZoom/2));

        drawContext.moveTo(startX + (this.state.drawZoom/3)+(this.state.drawZoom/2), startY - (this.state.drawZoom/3)+(this.state.drawZoom/2));
        drawContext.lineTo(startX - (this.state.drawZoom/3)+(this.state.drawZoom/2), startY + (this.state.drawZoom/3)+(this.state.drawZoom/2));
        drawContext.stroke();  */
        
        var drawZm = this.state.drawZoom;
        const img = document.getElementById('stitchImage');
        if(img) {
          drawContext.clearRect(startX-(this.state.drawZoom*2), startY-(this.state.drawZoom*2), this.state.drawZoom*5, this.state.drawZoom*5);

          //Top Top Left Left
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-(drawZm*2) + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX -(drawZm*2) + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-(drawZm*2), startY-(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-(drawZm*2),startY-(drawZm*2),1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY-(drawZm*2),1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY-(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX-(drawZm*2),startY-(drawZm*2)+drawZm-1,1,1);

          //Top Top Left
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-(drawZm*1) + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX -(drawZm*1) + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-(drawZm*1), startY-(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-(drawZm*1),startY-(drawZm*2),1,1);
          drawContext.fillRect(startX-(drawZm*1)+drawZm-1,startY-(drawZm*2),1,1);
          drawContext.fillRect(startX-(drawZm*1)+drawZm-1,startY-(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX-(drawZm*1),startY-(drawZm*2)+drawZm-1,1,1);

           //Top Top Middle
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX, startY-(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX,startY-(drawZm*2),1,1);
          drawContext.fillRect(startX+drawZm-1,startY-(drawZm*2),1,1);
          drawContext.fillRect(startX+drawZm-1,startY-(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX,startY-(drawZm*2)+drawZm-1,1,1);

          //Top Top Right
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+(drawZm*1) + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX +(drawZm*1) + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+(drawZm*1), startY-(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+(drawZm*1),startY-(drawZm*2),1,1);
          drawContext.fillRect(startX+(drawZm*1)+drawZm-1,startY-(drawZm*2),1,1);
          drawContext.fillRect(startX+(drawZm*1)+drawZm-1,startY-(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX+(drawZm*1),startY-(drawZm*2)+drawZm-1,1,1);

          //Top Top Right Right
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+(drawZm*2) + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX +(drawZm*2) + (drawZm/2),startY-(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+(drawZm*2), startY-(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+(drawZm*2),startY-(drawZm*2),1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY-(drawZm*2),1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY-(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX+(drawZm*2),startY-(drawZm*2)+drawZm-1,1,1);

          //Left Left middle top
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-(drawZm*2) + (drawZm/2),startY-(drawZm)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX -(drawZm*2) + (drawZm/2),startY-(drawZm)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-(drawZm*2), startY-(drawZm), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-(drawZm*2),startY-(drawZm),1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY-(drawZm),1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY-(drawZm)+drawZm-1,1,1);
          drawContext.fillRect(startX-(drawZm*2),startY-(drawZm)+drawZm-1,1,1);

        //Left Left middle middle
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-(drawZm*2) + (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX -(drawZm*2) + (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-(drawZm*2), startY, drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-(drawZm*2),startY,1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY,1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY+drawZm-1,1,1);
          drawContext.fillRect(startX-(drawZm*2),startY+drawZm-1,1,1);

          //Left Left middle bottom
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-(drawZm*2) + (drawZm/2),startY+(drawZm) + (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX -(drawZm*2) + (drawZm/2),startY+(drawZm) + (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-(drawZm*2), startY+(drawZm) , drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-(drawZm*2),startY+(drawZm) ,1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY+(drawZm) ,1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY+(drawZm)+drawZm-1 ,1,1);
          drawContext.fillRect(startX-(drawZm*2),startY+(drawZm)+drawZm-1 ,1,1);


          //Right Right middle top
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+(drawZm*2) + (drawZm/2),startY-(drawZm)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX +(drawZm*2) + (drawZm/2),startY-(drawZm)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+(drawZm*2), startY-(drawZm), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+(drawZm*2),startY-(drawZm),1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY-(drawZm),1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY-(drawZm)+drawZm-1,1,1);
          drawContext.fillRect(startX+(drawZm*2),startY-(drawZm)+drawZm-1,1,1);

        //Right Right middle middle
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+(drawZm*2) + (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX +(drawZm*2) + (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+(drawZm*2), startY, drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+(drawZm*2),startY,1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY,1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY+drawZm-1,1,1);
          drawContext.fillRect(startX+(drawZm*2),startY+drawZm-1,1,1);

          //Right Right middle bottom
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+(drawZm*2) + (drawZm/2),startY+(drawZm) + (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX +(drawZm*2) + (drawZm/2),startY+(drawZm) + (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+(drawZm*2), startY+(drawZm) , drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+(drawZm*2),startY+(drawZm) ,1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY+(drawZm) ,1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY+(drawZm)+drawZm-1 ,1,1);
          drawContext.fillRect(startX+(drawZm*2),startY+(drawZm)+drawZm-1 ,1,1);


          //Bottom Bottom Left Left
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-(drawZm*2) + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX -(drawZm*2) + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-(drawZm*2), startY+(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-(drawZm*2),startY+(drawZm*2),1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY+(drawZm*2),1,1);
          drawContext.fillRect(startX-(drawZm*2)+drawZm-1,startY+(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX-(drawZm*2),startY+(drawZm*2)+drawZm-1,1,1);

          //Bottom Bottom Left
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-(drawZm*1) + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX -(drawZm*1) + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-(drawZm*1), startY+(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-(drawZm*1),startY+(drawZm*2),1,1);
          drawContext.fillRect(startX-(drawZm*1)+drawZm-1,startY+(drawZm*2),1,1);
          drawContext.fillRect(startX-(drawZm*1)+drawZm-1,startY+(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX-(drawZm*1),startY+(drawZm*2)+drawZm-1,1,1);

           //Bottom Bottom Middle
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX, startY+(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX,startY+(drawZm*2),1,1);
          drawContext.fillRect(startX+drawZm-1,startY+(drawZm*2),1,1);
          drawContext.fillRect(startX+drawZm-1,startY+(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX,startY+(drawZm*2)+drawZm-1,1,1);

          //Bottom Bottom Right
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+(drawZm*1) + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX +(drawZm*1) + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+(drawZm*1), startY+(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+(drawZm*1),startY+(drawZm*2),1,1);
          drawContext.fillRect(startX+(drawZm*1)+drawZm-1,startY+(drawZm*2),1,1);
          drawContext.fillRect(startX+(drawZm*1)+drawZm-1,startY+(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX+(drawZm*1),startY+(drawZm*2)+drawZm-1,1,1);

          //Bottom Bottom Right Right
          /*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+(drawZm*2) + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX +(drawZm*2) + (drawZm/2),startY+(drawZm*2)+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+(drawZm*2), startY+(drawZm*2), drawZm, drawZm); 
          /*           drawContext.filter = "saturate(100%)"; */
          drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+(drawZm*2),startY+(drawZm*2),1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY+(drawZm*2),1,1);
          drawContext.fillRect(startX+(drawZm*2)+drawZm-1,startY+(drawZm*2)+drawZm-1,1,1);
          drawContext.fillRect(startX+(drawZm*2),startY+(drawZm*2)+drawZm-1,1,1);



          //Top Left
/*           drawContext.filter = "saturate(120%)"; */
          drawContext.beginPath();
          drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY-drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX -drawZm + (drawZm/2),startY-drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-drawZm, startY-drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-drawZm,startY-drawZm,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY-drawZm,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY-drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX-drawZm,startY-drawZm+drawZm-1,1,1);

          //Top Middle
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX + (drawZm/2),startY-drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX + (drawZm/2),startY-drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX, startY-drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX,startY-drawZm,1,1);
          drawContext.fillRect(startX+drawZm-1,startY-drawZm,1,1);
          drawContext.fillRect(startX+drawZm-1,startY-drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX,startY-drawZm+drawZm-1,1,1);

          //Top Right
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY-drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY-drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+drawZm, startY-drawZm, drawZm, drawZm);
/*           drawContext.filter = "saturate(100%)";  */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+drawZm,startY-drawZm,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY-drawZm,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY-drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX+drawZm,startY-drawZm+drawZm-1,1,1);

          //Middle Left
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-drawZm, startY, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-drawZm,startY,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY+drawZm-1,1,1);
          drawContext.fillRect(startX-drawZm,startY+drawZm-1,1,1);

          //Middle
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX, startY, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX,startY,1,1);
          drawContext.fillRect(startX+drawZm-1,startY,1,1);
          drawContext.fillRect(startX+drawZm-1,startY+drawZm-1,1,1);
          drawContext.fillRect(startX,startY+drawZm-1,1,1);

          //Middle Right
         
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+drawZm, startY, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+drawZm,startY,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY+drawZm-1,1,1);
          drawContext.fillRect(startX+drawZm,startY+drawZm-1,1,1);

          //Bottom Left
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY+drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX-drawZm + (drawZm/2),startY+drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX-drawZm, startY+drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX-drawZm,startY+drawZm,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY+drawZm,1,1);
          drawContext.fillRect(startX-drawZm+drawZm-1,startY+drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX-drawZm,startY+drawZm+drawZm-1,1,1);

          //Bottom Middle
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX + (drawZm/2),startY+drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX + (drawZm/2),startY+drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX, startY+drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX,startY+drawZm,1,1);
          drawContext.fillRect(startX+drawZm-1,startY+drawZm,1,1);
          drawContext.fillRect(startX+drawZm-1,startY+drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX,startY+drawZm+drawZm-1,1,1);

          //Bottom Right
          
          drawContext.beginPath();
/*           drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY+drawZm+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
          drawContext.ellipse(startX+drawZm + (drawZm/2),startY+drawZm+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
          drawContext.fill();
          drawContext.drawImage(img, startX+drawZm, startY+drawZm, drawZm, drawZm); 
/*           drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
          drawContext.fillRect(startX+drawZm,startY+drawZm,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY+drawZm,1,1);
          drawContext.fillRect(startX+drawZm+drawZm-1,startY+drawZm+drawZm-1,1,1);
          drawContext.fillRect(startX+drawZm,startY+drawZm+drawZm-1,1,1);
        }
        
      } else {
        drawContext.fillRect(startX-(this.state.drawZoom*2), startY-(this.state.drawZoom*2), this.state.drawZoom*5, this.state.drawZoom*5);
      }

      
    }
    
  } else if (this.state.advancedMode==="eraser") {
    if(this.state.drawSize===1) {
      drawContext.clearRect(startX, startY, this.state.drawZoom, this.state.drawZoom);
    } else if (this.state.drawSize===2) {
      drawContext.clearRect(startX-this.state.drawZoom, startY-this.state.drawZoom, this.state.drawZoom*3, this.state.drawZoom*3);
    } else {
      drawContext.clearRect(startX-(this.state.drawZoom*2), startY-(this.state.drawZoom*2), this.state.drawZoom*5, this.state.drawZoom*5);
    }
  }
  

}

handleMouseDownSelectionToolCanvasMouseMove = (e) => {

  if(this.state.selectBox===true) {return;}

  const drawContext = e.target.getContext("2d");
  drawContext.clearRect(0,0,e.target.width,e.target.height);
  const canvasBoundingRect = e.target.getBoundingClientRect();
    const x = e.clientX - canvasBoundingRect.left;
    const y = e.clientY - canvasBoundingRect.top;
    const cellX = Math.floor(x / this.state.drawZoom);
    const cellY = Math.floor(y / this.state.drawZoom);

    const startX = cellX * this.state.drawZoom;
    const startY = cellY * this.state.drawZoom;

    const xx = this.state.toolStartX - canvasBoundingRect.left;
    const yy = this.state.toolStartY - canvasBoundingRect.top;
    const cellXX = Math.floor(xx / this.state.drawZoom);
    const cellYY = Math.floor(yy / this.state.drawZoom);

    if((Math.floor(x / this.state.drawZoom)*this.state.drawZoom!==Math.floor((this.state.toolStartX+this.state.toolStartBoundingClientLeft-canvasBoundingRect.left)/ this.state.drawZoom)*this.state.drawZoom || 
        Math.floor(y / this.state.drawZoom)*this.state.drawZoom!==Math.floor((this.state.toolStartY+this.state.toolStartBoundingClientTop-canvasBoundingRect.left)/ this.state.drawZoom)*this.state.drawZoom) && 
        this.state.selectionCanStart===false) {
          
          this.setState({selectionCanStart: true});
        }

    const startXX = cellXX * this.state.drawZoom;
    const startYY = cellYY * this.state.drawZoom;
    e.target.parentElement.style.mixBlendMode = "difference";
    drawContext.lineWidth = 2;
    drawContext.strokeStyle = "rgba(255,255,255,1)";
    drawContext.beginPath();
    //Right
    if(startXX<=startX){
      //Top
      if(startYY>startY) {
        drawContext.rect(startXX,startYY+this.state.drawZoom,(startX+this.state.drawZoom)-startXX,startY-startYY-this.state.drawZoom);
      }
      //Bottom 
      else {
        drawContext.rect(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
      }
    }
    //Left 
    else if(startXX>startX) {
      //Top
      if(startYY>startY) {
      drawContext.rect(startXX+this.state.drawZoom,startYY+this.state.drawZoom,startX-startXX-this.state.drawZoom,startY-startYY-this.state.drawZoom);
      }
      //Bottom
      else {
        drawContext.rect(startXX+this.state.drawZoom,startYY,startX-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
      }
    }
    drawContext.stroke();
    /* //Right
    if(startXX<=startX){
      //Top
      if(startYY>startY) {
        //Bottom
        drawContext.fillRect(startXX,startYY,(startX+this.state.drawZoom)-startXX,this.state.drawZoom);
        //Left
        drawContext.fillRect(startX,startYY,this.state.drawZoom,startY-startYY);
        //Top
        drawContext.fillRect(startXX+this.state.drawZoom,startY,(startX-startXX),this.state.drawZoom);
        //Right
        drawContext.fillRect(startXX,startYY,this.state.drawZoom,startY-startYY);
      }
      //Bottom 
      else {
        //Bottom
        drawContext.fillRect(startXX+this.state.drawZoom,startYY,(startX-this.state.drawZoom)-startXX,this.state.drawZoom);
        //Left
        drawContext.fillRect(startX,startYY,this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
        //Top
        drawContext.fillRect(startXX+this.state.drawZoom,startY,(startX-this.state.drawZoom)-startXX,this.state.drawZoom);
        //Right
        drawContext.fillRect(startXX,startYY,this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
      }
    }
    //Left 
    else if(startXX>startX) {
      //Top
      if(startYY>startY) {
              //Bottom
              drawContext.fillRect(startXX+this.state.drawZoom,startYY,startX-startXX-this.state.drawZoom,this.state.drawZoom);
              //Left
              drawContext.fillRect(startX,startYY,this.state.drawZoom,startY-startYY);
              //Top
              drawContext.fillRect(startXX,startY,startX-startXX+this.state.drawZoom,this.state.drawZoom);
              //Right
              drawContext.fillRect(startXX,startYY,this.state.drawZoom,startY-startYY);
      } 
      //Bottom
      else {
                //Bottom
                drawContext.fillRect(startXX,startYY,startX-startXX+this.state.drawZoom,this.state.drawZoom);
                //Left
                drawContext.fillRect(startX,startYY,this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
                //Top
                drawContext.fillRect(startXX,startY,startX-startXX+this.state.drawZoom,this.state.drawZoom);
                //Right
                drawContext.fillRect(startXX,startYY,this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
      }
    } */
}

/* drawEllipse = (ctx, x, y, radius, fill, stroke, strokeWidth) => {
  ctx.beginPath()
  ctx.arc(x, y, radius, 0, 2 * Math.PI, false)
  if (fill) {
    ctx.fillStyle = fill
    ctx.fill()
  }
  if (stroke) {
    ctx.lineWidth = strokeWidth
    ctx.strokeStyle = stroke
    ctx.stroke()
  }
} */

drawEllipse = (ctx, x1, y1, x2, y2) => {

  var radiusX = (x2 - x1) * 0.5,   /// radius for x based on input
      radiusY = (y2 - y1) * 0.5,   /// radius for y based on input
      centerX = x1 + radiusX,      /// calc center
      centerY = y1 + radiusY,
      step = 0.01,                 /// resolution of ellipse
      a = step,                    /// counter
      pi2 = Math.PI * 2 - step;    /// end angle
  
  /// start a new path
  ctx.beginPath();

  /// set start point at angle 0
  ctx.moveTo(centerX + radiusX * Math.cos(0),
             centerY + radiusY * Math.sin(0));

  /// create the ellipse    
  for(; a < pi2; a += step) {
      ctx.lineTo(centerX + radiusX * Math.cos(a),
                 centerY + radiusY * Math.sin(a));
  }
  
  /// close it and stroke it for demo
  ctx.closePath();
  ctx.stroke();

}
drawEllipseAlgorithmScale = (x0, y0, x1, y1, fill, ctx) =>
{
  var xb, yb, xc, yc;

    // Calculate height
    yb = yc = (y0 + y1) / 2;
    var qb = (y0 < y1) ? (y1 - y0) : (y0 - y1);
    var qy = qb;
    var dy = qb / 2;
    if (qb % 2 != 0)
        // Bounding box has even pixel height
        yc++;

    // Calculate width
    xb = xc = (x0 + x1) / 2;
    var qa = (x0 < x1) ? (x1 - x0) : (x0 - x1);
    var qx = qa % 2;
    var dx = 0;
    var qt = qa*qa + qb*qb -2*qa*qa*qb;
    if (qx != 0) {
        // Bounding box has even pixel width
        xc++;
        qt += 3*qb*qb;
    }

    // Start at (dx, dy) = (0, b) and iterate until (a, 0) is reached
    while (qy >= 0 && qx <= qa) {
        // Draw the new points
        if (!fill) {
          
          ctx.fillRect(xb-dx, yb-dy,1,1);

         if (dx != 0 || xb != xc) 
          ctx.fillRect(xc+dx, yb-dy,1,1); 
          
          
          //Right bottom
             if (dy != 0 || yb != yc) 
            ctx.fillRect(xc+dx, yc+dy,1,1);           
        
        if (dy != 0 || yb != yc) 
          ctx.fillRect(xb-dx, yc+dy,1,1); 
           
        }

        // If a (+1, 0) step stays inside the ellipse, do it
        if (qt + 2*qb*qb*qx + 3*qb*qb <= 0 || 
            qt + 2*qa*qa*qy - qa*qa <= 0) {
            qt += 8*qb*qb + 4*qb*qb*qx;
            dx++;
            qx += 2;
        // If a (0, -1) step stays outside the ellipse, do it
        } else if (qt - 2*qa*qa*qy + 3*qa*qa > 0) {
            if (fill) {
              ctx.fillRect(xb-dx, xc+dx, yc+dy, yc+dy);
                if (dy != 0 || yb != yc)
                ctx.fillRect(xb-dx, xc+dx, yb-dy, yc-dy);
            }
            qt += 8*qa*qa - 4*qa*qa*qy;
            dy--;
            qy -= 2;

        // Else step (+1, -1)
        } else {
            if (fill) {
              ctx.fillRect(xb-dx, xc+dx, yc+dy, yc+dy);
                if (dy != 0 || yb != yc)
                ctx.fillRect(xb-dx, xc+dx, yb-dy, yc-dy);
            }
            qt += 8*qb*qb + 4*qb*qb*qx + 8*qa*qa - 4*qa*qa*qy;
            dx++;
            qx += 2;
            dy--;
            qy -= 2;
        }
    }   // End of while loop
    return;
}



drawEllipseAlgorithm = (x0, y0, x1, y1, fill, ctx, pxWidth, pxHeight) =>
{
    var xb, yb, xc, yc;
    let mySet1 = new Set();
    // Calculate height
    yb = yc = (y0 + y1) / 2;
    var qb = (y0 < y1) ? (y1 - y0) : (y0 - y1);
    var qy = qb;
    var dy = qb / 2;
    if (qb % 2 != 0)
        // Bounding box has even pixel height
        yc++;

    // Calculate width
    xb = xc = (x0 + x1) / 2;
    var qa = (x0 < x1) ? (x1 - x0) : (x0 - x1);
    var qx = qa % 2;
    var dx = 0;
    var qt = qa*qa + qb*qb -2*qa*qa*qb;
    if (qx != 0) {
        // Bounding box has even pixel width
        xc++;
        qt += 3*qb*qb;
    }

    // Start at (dx, dy) = (0, b) and iterate until (a, 0) is reached
    while (qy >= 0 && qx <= qa) {
        // Draw the new points
        if (!fill) {
          
          
          let pointX = xb-dx;
          let pointY = yb-dy;
          let cellX = Math.floor(pointX / this.state.drawZoom);
          let cellY = Math.floor(pointY / this.state.drawZoom);

          let startX = cellX * this.state.drawZoom;
          let startY = cellY * this.state.drawZoom;
          
/*           let startPointY = Math.floor(y0 / this.state.drawZoom)*this.state.drawZoom;
          let endPointY = Math.floor(y1 / this.state.drawZoom)*this.state.drawZoom;
          let startPointX = Math.floor(x0 / this.state.drawZoom)*this.state.drawZoom;
          let endPointX = Math.floor(x1 / this.state.drawZoom)*this.state.drawZoom; */
          //(Math.abs(pxHeight)*this.state.drawZoom)
          //top left
          /* ctx.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom); */
          //bottom left
          /* ctx.fillRect(startX,-startY+startPointY+endPointY,this.state.drawZoom,this.state.drawZoom); */
          //top right
          /* ctx.fillRect(-startX+startPointX+endPointX,startY,this.state.drawZoom,this.state.drawZoom); */
          //bottom right
          /* ctx.fillRect(-startX+startPointX+endPointX,-startY+startPointY+endPointY,this.state.drawZoom,this.state.drawZoom); */
          mySet1.add("x"+startX+",y"+startY);
/*           let calc = -startY+startPointY+endPointY
          mySet1.add("x"+startX+",y"+calc);
          calc = -startX+startPointX+endPointX
          mySet1.add("x"+calc+",y"+startY);
          calc = -startX+startPointX+endPointX
          let calc2 = -startY+startPointY+endPointY;
          mySet1.add("x"+calc+",y"+calc2); */
/*           
          ctx.fillStyle = "red";
          ctx.fillRect(xb-dx, yb-dy,1,1);
          ctx.fillStyle = this.state.drawColor; */
/*         if (dx != 0 || xb != xc) {
          ctx.fillRect(xc+dx, yb-dy,1,1); */
/*           pointX = xb+dx;
          pointY = yb-dy;
          cellX = Math.floor(pointX / this.state.drawZoom);
          cellY = Math.floor(pointY / this.state.drawZoom);

          startX = cellX * this.state.drawZoom;
          startY = cellY * this.state.drawZoom;
          ctx.fillStyle = "rgb(0,0,255)";
          ctx.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom); */
          
          
          //Right bottom
/*             if (dy != 0 || yb != yc) {
            ctx.fillRect(xc+dx, yc+dy,1,1); */
/*             pointX = xc+dx;
            pointY = yc+dy;

            cellX = Math.floor(pointX / this.state.drawZoom);
            cellY = Math.floor(pointY / this.state.drawZoom);
  
            startX = cellX * this.state.drawZoom;
            startY = cellY * this.state.drawZoom;
            ctx.fillStyle = "rgb(255,0,0)";
            ctx.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom); */
/*             }
        }
        if (dy != 0 || yb != yc) {
          ctx.fillRect(xb-dx, yc+dy,1,1); */
/*           pointX = xb-dx;
          pointY = yc+dy;
          cellX = Math.floor(pointX / this.state.drawZoom);
            cellY = Math.floor(pointY / this.state.drawZoom);
  
            startX = cellX * this.state.drawZoom;
            startY = cellY * this.state.drawZoom;
            ctx.fillStyle = "yellow";
            ctx.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom); */
/*           } */
        }

        // If a (+1, 0) step stays inside the ellipse, do it
        if (qt + 2*qb*qb*qx + 3*qb*qb <= 0 || 
            qt + 2*qa*qa*qy - qa*qa <= 0) {
            qt += 8*qb*qb + 4*qb*qb*qx;
            dx++;
            qx += 2;
        // If a (0, -1) step stays outside the ellipse, do it
        } else if (qt - 2*qa*qa*qy + 3*qa*qa > 0) {
            if (fill) {
              ctx.fillRect(xb-dx, xc+dx, yc+dy, yc+dy);
                if (dy != 0 || yb != yc)
                ctx.fillRect(xb-dx, xc+dx, yb-dy, yc-dy);
            }
            qt += 8*qa*qa - 4*qa*qa*qy;
            dy--;
            qy -= 2;

        // Else step (+1, -1)
        } else {
            if (fill) {
              ctx.fillRect(xb-dx, xc+dx, yc+dy, yc+dy);
                if (dy != 0 || yb != yc)
                ctx.fillRect(xb-dx, xc+dx, yb-dy, yc-dy);
            }
            qt += 8*qb*qb + 4*qb*qb*qx + 8*qa*qa - 4*qa*qa*qy;
            dx++;
            qx += 2;
            dy--;
            qy -= 2;
        }
    }   // End of while loop
    return mySet1;
}




handleMouseDownCircleToolCanvasMouseMove = (e) => {
  const drawContext = e.target.getContext("2d");
  drawContext.clearRect(0,0,e.target.width,e.target.height);
  const canvasBoundingRect = e.target.getBoundingClientRect();
    const x = e.clientX - canvasBoundingRect.left;
    const y = e.clientY - canvasBoundingRect.top;
    const cellX = Math.floor(x / this.state.drawZoom);
    const cellY = Math.floor(y / this.state.drawZoom);

    let startX = cellX * this.state.drawZoom;
    let startY = cellY * this.state.drawZoom;
    const xx = this.state.toolStartX - canvasBoundingRect.left;
    const yy = this.state.toolStartY - canvasBoundingRect.top;
    const cellXX = Math.floor(xx / this.state.drawZoom);
    const cellYY = Math.floor(yy / this.state.drawZoom);

    const startXX = cellXX * this.state.drawZoom;
    const startYY = cellYY * this.state.drawZoom;

    drawContext.fillStyle = this.state.drawColor;
    drawContext.strokeStyle = this.state.drawColor;

    let pixelsWidth = (startX-startXX)/this.state.drawZoom;
    let pixelsHeight = (startY-startYY)/this.state.drawZoom;
    if(this.state.shiftKey===true) {
      //Left
      if(startX<=startXX) {
        //top
        if(startY<=startYY) {
          if(Math.abs(pixelsWidth*this.state.drawZoom)>=Math.abs(pixelsHeight*this.state.drawZoom)) {
            startY=startYY+(pixelsWidth*this.state.drawZoom);
          } else {
            startX=startXX+(pixelsHeight*this.state.drawZoom);
          }
        }
        //bottom 
        else {
          if(Math.abs(pixelsWidth*this.state.drawZoom)>=Math.abs(pixelsHeight*this.state.drawZoom)) {
            startY=startYY+(-pixelsWidth*this.state.drawZoom);
          } else {
            startX=startXX+(-pixelsHeight*this.state.drawZoom);
          }
        }
       
      }
      //right 
      else {
        if(startY<=startYY) {
        if(Math.abs(pixelsWidth*this.state.drawZoom)>=Math.abs(pixelsHeight*this.state.drawZoom)) {
          startY=startYY+(-pixelsWidth*this.state.drawZoom);
        } else {
          startX=startXX+(-pixelsHeight*this.state.drawZoom);
        }
        } else {
          if(Math.abs(pixelsWidth*this.state.drawZoom)>=Math.abs(pixelsHeight*this.state.drawZoom)) {
            startY=startYY+(pixelsWidth*this.state.drawZoom);
          } else {
            startX=startXX+(pixelsHeight*this.state.drawZoom);
          }
        }

      }
      
    } 
/* 
    let pixelsInBetweenX = (startX-startXX);
    let pixelsInBetweenY = (startY-startYY); */

    //this.drawEllipse(drawContext,startXX,startYY,startXX+pixelsWidth,startYY+pixelsHeight);
    const points = this.drawEllipseAlgorithm(startXX,startYY,startX,startY,false,drawContext,pixelsWidth,pixelsHeight);
    let pointArr = Array.from(points);
    let newPointArr = [];
    for(let i=0;i<pointArr.length;i++) {
      let x = parseInt(pointArr[i].split(",")[0].replace("x",""));
      let y = parseInt(pointArr[i].split(",")[1].replace("y",""));
      
      if(i<pointArr.length-1 && i>0) {
        let x1 = parseInt(pointArr[i+1].split(",")[0].replace("x",""));
        let y1 = parseInt(pointArr[i+1].split(",")[1].replace("y",""));
        //let xn1 = parseInt(pointArr[i-1].split(",")[0].replace("x",""));
        let yn1 = parseInt(pointArr[i-1].split(",")[1].replace("y",""));
        if(x1===x && y1>y && yn1===y) {
          //drawContext.clearRect(x,y,this.state.drawZoom,this.state.drawZoom);
          
        } else {
          let o = {x: x,y: y};
          newPointArr.push(o);
        }
             
        } else {
          let o = {x: x,y: y};
          newPointArr.push(o);
        }
    }
    for(let i=0;i<newPointArr.length;i++) {
      let x = newPointArr[i].x;
      let y = newPointArr[i].y;
      if(this.state.circleToolOutline===true) {
      //top left
      drawContext.fillRect(x,y,this.state.drawZoom,this.state.drawZoom);
      //bottom left
      drawContext.fillRect(x,-y+startYY+startY,this.state.drawZoom,this.state.drawZoom);
      //top right
      drawContext.fillRect(-x+startXX+startX,y,this.state.drawZoom,this.state.drawZoom);
      //bottom right
      drawContext.fillRect(-x+startXX+startX,-y+startYY+startY,this.state.drawZoom,this.state.drawZoom);
      } else {

        drawContext.fillRect(x,y,(-x+startXX+startX)-x+this.state.drawZoom,this.state.drawZoom);

        drawContext.fillRect(x,-y+startYY+startY,(-x+startXX+startX)-x+this.state.drawZoom,this.state.drawZoom);
      }
    }

    //this.drawEllipseAlgorithmScale(startXX,startYY,startXX+pixelsWidth,startYY+pixelsHeight,false,drawContext);
/*     drawContext.transform(this.state.drawZoom,0,0,this.state.drawZoom,0,0);
     drawContext.transform(1/this.state.drawZoom,0,0,1/this.state.drawZoom,0,0);  */

    //drawContext.transform(1/this.state.drawZoom,0,0,1/this.state.drawZoom,0,0);
    //drawContext.transform(1,0,0,-1,0,Math.abs(pxHeight)*this.state.drawZoom);
/*     for(let i=0; i<points.length; i++) {
      const x = points[i]["x"] - canvasBoundingRect.left;
      const y = points[i]["y"]- canvasBoundingRect.top;
      const cellX = Math.floor(x / this.state.drawZoom);
      const cellY = Math.floor(y / this.state.drawZoom);
  
      const startX = cellX * this.state.drawZoom;
      const startY = cellY * this.state.drawZoom;
      drawContext.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom);
    } */

/* 
    //Right
    if(startXX<=startX){
      //Top
      if(startYY>startY) {
        if(this.state.squareToolOutline===false) {
          drawContext.fillRect(startXX,startYY+this.state.drawZoom,(startX+this.state.drawZoom)-startXX,startY-startYY-this.state.drawZoom);
        } else {
          drawContext.lineWidth = this.state.drawZoom;
          if(startXX===startX) {
            drawContext.fillRect(startXX,startYY+this.state.drawZoom,(startX+this.state.drawZoom)-startXX,startY-startYY-this.state.drawZoom);
          } else {
            drawContext.strokeRect(startXX+(this.state.drawZoom/2),startYY+this.state.drawZoom-(this.state.drawZoom/2),(startX+this.state.drawZoom)-startXX-(this.state.drawZoom),startY-startYY-this.state.drawZoom+(this.state.drawZoom));
          }
          
        }
        
      }
      //Bottom 
      else {
        if(this.state.squareToolOutline===false) {
          drawContext.fillRect(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
        } else {
          drawContext.lineWidth = this.state.drawZoom;
          if(startYY===startY) {
            drawContext.fillRect(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
            //drawContext.strokeRect(startXX,startYY+(this.state.drawZoom/2),(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY-this.state.drawZoom);
          } else if (startX===startXX) {
            drawContext.fillRect(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
          } else {
            drawContext.strokeRect(startXX+(this.state.drawZoom/2),startYY+(this.state.drawZoom/2),(startX+this.state.drawZoom)-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY-this.state.drawZoom);
          }
         
        }
      }
    }
    //Left 
    else if(startXX>startX) {
      //Top
      if(startYY>startY) {
        if(this.state.squareToolOutline===false) {
      drawContext.fillRect(startXX+this.state.drawZoom,startYY+this.state.drawZoom,startX-startXX-this.state.drawZoom,startY-startYY-this.state.drawZoom);
        } else {
          drawContext.lineWidth = this.state.drawZoom;
          drawContext.strokeRect(startXX+this.state.drawZoom-(this.state.drawZoom/2),startYY+this.state.drawZoom-(this.state.drawZoom/2),startX-startXX,startY-startYY);
        } 
    }
      //Bottom
      else {
        if(this.state.squareToolOutline===false) {
        drawContext.fillRect(startXX+this.state.drawZoom,startYY,startX-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
        } else {
          drawContext.lineWidth = this.state.drawZoom;
          if(startYY===startY) {
            drawContext.fillRect(startXX+this.state.drawZoom,startYY,startX-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
          } else {
            drawContext.strokeRect(startXX+(this.state.drawZoom/2),startYY+(this.state.drawZoom/2),startX-startXX,(startY+this.state.drawZoom)-startYY-(this.state.drawZoom));
          }
        }
      }
    } */
    
}


handleMouseDownSquareToolCanvasMouseMove = (e) => {
  const drawContext = e.target.getContext("2d");
  drawContext.clearRect(0,0,e.target.width,e.target.height);
  const canvasBoundingRect = e.target.getBoundingClientRect();
    const x = e.clientX - canvasBoundingRect.left;
    const y = e.clientY - canvasBoundingRect.top;
    const cellX = Math.floor(x / this.state.drawZoom);
    const cellY = Math.floor(y / this.state.drawZoom);

    let startX = cellX * this.state.drawZoom;
    let startY = cellY * this.state.drawZoom;
    const xx = this.state.toolStartX - canvasBoundingRect.left;
    const yy = this.state.toolStartY - canvasBoundingRect.top;
    const cellXX = Math.floor(xx / this.state.drawZoom);
    const cellYY = Math.floor(yy / this.state.drawZoom);

    const startXX = cellXX * this.state.drawZoom;
    const startYY = cellYY * this.state.drawZoom;

    drawContext.fillStyle = this.state.drawColor;
    drawContext.strokeStyle = this.state.drawColor;


    let pixelsWidth = startX-startXX;
    let pixelsHeight = startY-startYY;
    if(this.state.shiftKey===true) {
      //Left
      if(startX<=startXX) {
        //top
        if(startY<=startYY) {
          if(Math.abs(pixelsWidth)>=Math.abs(pixelsHeight)) {
            startY=startYY+pixelsWidth;
          } else {
            startX=startXX+pixelsHeight;
          }
        }
        //bottom 
        else {
          if(Math.abs(pixelsWidth)>=Math.abs(pixelsHeight)) {
            startY=startYY-pixelsWidth;
          } else {
            startX=startXX-pixelsHeight;
          }
        }
       
      }
      //right 
      else {
        if(startY<=startYY) {
        if(Math.abs(pixelsWidth)>=Math.abs(pixelsHeight)) {
          startY=startYY-pixelsWidth;
        } else {
          startX=startXX-pixelsHeight;
        }
        } else {
          if(Math.abs(pixelsWidth)>=Math.abs(pixelsHeight)) {
            startY=startYY+pixelsWidth;
          } else {
            startX=startXX+pixelsHeight;
          }
        }

      }
      
    } 

    //Right
    if(startXX<=startX){
      //Top
      if(startYY>startY) {
        if(this.state.squareToolOutline===false) {
          drawContext.fillRect(startXX,startYY+this.state.drawZoom,(startX+this.state.drawZoom)-startXX,startY-startYY-this.state.drawZoom);
        } else {
          drawContext.lineWidth = this.state.drawZoom;
          if(startXX===startX) {
            drawContext.fillRect(startXX,startYY+this.state.drawZoom,(startX+this.state.drawZoom)-startXX,startY-startYY-this.state.drawZoom);
          } else {
            drawContext.strokeRect(startXX+(this.state.drawZoom/2),startYY+this.state.drawZoom-(this.state.drawZoom/2),(startX+this.state.drawZoom)-startXX-(this.state.drawZoom),startY-startYY-this.state.drawZoom+(this.state.drawZoom));
          }
          
        }
        
      }
      //Bottom 
      else {
        if(this.state.squareToolOutline===false) {
          drawContext.fillRect(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
        } else {
          drawContext.lineWidth = this.state.drawZoom;
          if(startYY===startY) {
            drawContext.fillRect(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
            //drawContext.strokeRect(startXX,startYY+(this.state.drawZoom/2),(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY-this.state.drawZoom);
          } else if (startX===startXX) {
            drawContext.fillRect(startXX,startYY,(startX+this.state.drawZoom)-startXX,(startY+this.state.drawZoom)-startYY);
          } else {
            drawContext.strokeRect(startXX+(this.state.drawZoom/2),startYY+(this.state.drawZoom/2),(startX+this.state.drawZoom)-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY-this.state.drawZoom);
          }
         
        }
      }
    }
    //Left 
    else if(startXX>startX) {
      //Top
      if(startYY>startY) {
        if(this.state.squareToolOutline===false) {
      drawContext.fillRect(startXX+this.state.drawZoom,startYY+this.state.drawZoom,startX-startXX-this.state.drawZoom,startY-startYY-this.state.drawZoom);
        } else {
          drawContext.lineWidth = this.state.drawZoom;
          drawContext.strokeRect(startXX+this.state.drawZoom-(this.state.drawZoom/2),startYY+this.state.drawZoom-(this.state.drawZoom/2),startX-startXX,startY-startYY);
        } 
    }
      //Bottom
      else {
        if(this.state.squareToolOutline===false) {
        drawContext.fillRect(startXX+this.state.drawZoom,startYY,startX-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
        } else {
          drawContext.lineWidth = this.state.drawZoom;
          if(startYY===startY) {
            drawContext.fillRect(startXX+this.state.drawZoom,startYY,startX-startXX-this.state.drawZoom,(startY+this.state.drawZoom)-startYY);
          } else {
            drawContext.strokeRect(startXX+(this.state.drawZoom/2),startYY+(this.state.drawZoom/2),startX-startXX,(startY+this.state.drawZoom)-startYY-(this.state.drawZoom));
          }
        }
      }
    }
    
}


handleMouseDownLineToolCanvasMouseMove = (e) => {
  const drawContext = e.target.getContext("2d");
  drawContext.clearRect(0,0,e.target.width,e.target.height);
  const canvasBoundingRect = e.target.getBoundingClientRect();
  const x = e.clientX - canvasBoundingRect.left;
    const y = e.clientY - canvasBoundingRect.top;
    const cellX = Math.floor(x / this.state.drawZoom);
    const cellY = Math.floor(y / this.state.drawZoom);

    const startX = cellX * this.state.drawZoom;
    const startY = cellY * this.state.drawZoom;
     const points = this.bresenhamAlgorithm(e.clientX,e.clientY,this.state.toolStartX,this.state.toolStartY);   
     
     
     
/*   const canvasBoundingRect = e.target.getBoundingClientRect();*/

/* this.draw_line(startX,startY,this.state.toolStartX,this.state.toolStartY,e.target); */
const pointsToFix = [];
for(let i=0; i<points.length; i++) {
  drawContext.fillStyle = this.state.drawColor;
const x = points[i]["x"] - canvasBoundingRect.left;
    const y = points[i]["y"]- canvasBoundingRect.top;
    const cellX = Math.floor(x / this.state.drawZoom);
    const cellY = Math.floor(y / this.state.drawZoom);

    const startX = cellX * this.state.drawZoom;
    const startY = cellY * this.state.drawZoom;
    pointsToFix.push({x:startX,y:startY});
/*     if(this.state.stitchMode===true) {      
      var drawZm = this.state.drawZoom;   
      const img = document.getElementById('stitchImage');
      if(img) {
        drawContext.clearRect(startX,startY,drawZm,drawZm);
        drawContext.fillRect(startX,startY,1,1);
        drawContext.beginPath();
        drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
        drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
        drawContext.fill();
        drawContext.drawImage(img, startX, startY, drawZm, drawZm); 
      }
  } else { */
    drawContext.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom);
  /* } */
  
}

//Fix line rasterization!!
for(let i=0; i<pointsToFix.length; i++) {
  if(i!==0 && i<pointsToFix.length-1){
    //X and y
    const x = points[i].x- canvasBoundingRect.left;
    const y = points[i].y - canvasBoundingRect.top;
    const cellX = Math.floor(x / this.state.drawZoom);
    const cellY = Math.floor(y / this.state.drawZoom);

    const startX = cellX * this.state.drawZoom;
    const startY = cellY * this.state.drawZoom;
    //X and y minus
    const xminus = points[i-1].x- canvasBoundingRect.left;
    const yminus = points[i-1].y - canvasBoundingRect.top;
    const cellXminus = Math.floor(xminus / this.state.drawZoom);
    const cellYminus = Math.floor(yminus / this.state.drawZoom);

    const startXminus = cellXminus * this.state.drawZoom;
    const startYminus = cellYminus * this.state.drawZoom;
    //X and y plus
    const xplus = points[i+1].x- canvasBoundingRect.left;
    const yplus = points[i+1].y - canvasBoundingRect.top;
    const cellXplus = Math.floor(xplus / this.state.drawZoom);
    const cellYplus = Math.floor(yplus / this.state.drawZoom);

    const startXplus = cellXplus * this.state.drawZoom;
    const startYplus = cellYplus * this.state.drawZoom;
    const differenceX = Math.abs(e.clientX-this.state.toolStartX);
    const differenceY = Math.abs(e.clientY-this.state.toolStartY);
/*     if(differenceX>differenceY) {
      console.log("Differnecebigger!!");
    } */


    //Left bottom bottom 
    if(e.clientX<this.state.toolStartX && e.clientY>this.state.toolStartY && differenceX<differenceY) {
      if(startXminus!==startX && startYminus===startY && startXplus===startX) {
        drawContext.clearRect(startX,startY,this.state.drawZoom,this.state.drawZoom);
      }

}
//Left bottom top 
else if(e.clientX<this.state.toolStartX && e.clientY>this.state.toolStartY && differenceX>=differenceY) {
  if(startXminus===startX && startYminus!==startY && startYplus===startY ) {
/*     drawContext.fillStyle = "green";
    drawContext.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom);
    drawContext.fillStyle = "blue";
    drawContext.fillRect(startXplus,startYplus,this.state.drawZoom,this.state.drawZoom); */

    drawContext.clearRect(startXminus,startYminus,this.state.drawZoom,this.state.drawZoom);
  }
}
//Left top bottom
else if(e.clientX<this.state.toolStartX && e.clientY<this.state.toolStartY && differenceX>differenceY) {
  if(/* startXminus!==startX && (startXminus+this.state.drawZoom)===startX && startYminus===startY && */  startYminus!==startY && (startXminus+this.state.drawZoom)!==startX ) {
    drawContext.clearRect(startX,startY,this.state.drawZoom,this.state.drawZoom); 
/*      drawContext.fillStyle = "yellow"; 
    drawContext.fillRect(startXminus,startYminus,this.state.drawZoom,this.state.drawZoom);   */
    if(startXplus===startX) {
    drawContext.clearRect(startXplus,startYplus,this.state.drawZoom,this.state.drawZoom);   
    }
  }
}
//Left top top
else if(e.clientX<this.state.toolStartX && e.clientY<this.state.toolStartY && differenceX<=differenceY) {
  if(startXminus!==startX && (startXminus+this.state.drawZoom)===startX && startYminus===startY ) {
/*         drawContext.fillStyle = "blue";
    drawContext.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom);  */
    drawContext.clearRect(startXminus,startYminus,this.state.drawZoom,this.state.drawZoom);
/*     if (startYplus===(startY+this.state.drawZoom)) {  
      drawContext.fillStyle = "green";
    drawContext.fillRect(startXplus,startYplus,this.state.drawZoom,this.state.drawZoom);   
    } */

  }
} //Right bottom bottom 
else if(e.clientX>this.state.toolStartX && e.clientY>this.state.toolStartY && differenceX<differenceY) {
      if(startXminus!==startX && startYminus===startY && startXplus===startX) {
        drawContext.clearRect(startX,startY,this.state.drawZoom,this.state.drawZoom);
      }

}
//Right bottom top 
else if(e.clientX>this.state.toolStartX && e.clientY>this.state.toolStartY && differenceX>=differenceY) {
  if(startXminus===startX && startYminus!==startY && startYplus===startY ) {
/*     drawContext.fillStyle = "green";
    drawContext.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom);
    drawContext.fillStyle = "blue";
    drawContext.fillRect(startXplus,startYplus,this.state.drawZoom,this.state.drawZoom); */
    drawContext.clearRect(startXminus,startYminus,this.state.drawZoom,this.state.drawZoom);
  }
}
//Right top bottom
else if(e.clientX>this.state.toolStartX && e.clientY<this.state.toolStartY && differenceX>differenceY) {
  if(startX===startXplus && startY!==startYplus) {

    drawContext.clearRect(startX,startY,this.state.drawZoom,this.state.drawZoom);  
    if(startXminus!==(startX+this.state.drawZoom)){
/*         if(startXminus!==(startX-this.state.drawZoom) && startX) {  */
    drawContext.clearRect(startXminus,startYminus,this.state.drawZoom,this.state.drawZoom);  
    }
 /*        }  */ 
/*  if(startXplus!==(startXminus+this.state.drawZoom)) { */
     /*   drawContext.fillStyle = "green";
    drawContext.fillRect(startXplus,startYplus,this.state.drawZoom,this.state.drawZoom);    */
/*  } */
    
  }
}
//Right top top
else if(e.clientX>this.state.toolStartX && e.clientY<this.state.toolStartY && differenceX<=differenceY) {
  if(startXplus!==startX && (startXplus+this.state.drawZoom)===startX && startYplus===startY ) {
/*          drawContext.fillStyle = "blue";
    drawContext.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom);   */
/*     if (startYminus===(startY+this.state.drawZoom)) { 
    drawContext.fillStyle = "yellow"; 
    drawContext.fillRect(startXminus,startYminus,this.state.drawZoom,this.state.drawZoom);
    } */
    drawContext.clearRect(startXplus,startYplus,this.state.drawZoom,this.state.drawZoom);   
    

  }
}


}

}
drawContext.fillStyle = this.state.drawColor;

/* if(this.state.stitchMode===true) {      
  var drawZm = this.state.drawZoom;   
  const img = document.getElementById('stitchImage');
  if(img) {
    drawContext.clearRect(startX,startY,drawZm,drawZm);
    drawContext.fillRect(startX,startY,1,1);
    drawContext.beginPath();
    drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
    drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
    drawContext.fill();
    drawContext.drawImage(img, startX, startY, drawZm, drawZm); 
  }
}  else { */
  drawContext.fillRect(startX,startY,this.state.drawZoom,this.state.drawZoom);
/* } */
     


}
 

 bresenhamAlgorithm = (startX, startY, endX, endY) => { 

  const deltaCol = Math.abs(endX - startX) // zero or positive number
  const deltaRow = Math.abs(endY - startY) // zero or positive number
  
  let pointX = startX
  let pointY = startY
  
  const horizontalStep = (startX < endX) ? 1: -1 
  
  const verticalStep = (startY < endY) ? 1 : -1
  
  const points = [ ]
  
  let difference = deltaCol - deltaRow
  
  while (true) {
  
      const doubleDifference = 2 * difference // necessary to store this value
      if (doubleDifference > -deltaRow) { difference -= deltaRow; pointX += horizontalStep; }
      if (doubleDifference <  deltaCol) { difference += deltaCol; pointY += verticalStep; }
  
      if ((pointX == endX) && (pointY == endY)) { break } // doesnt include the end point

      points.push({ "x": pointX, "y": pointY })
      
      
  }    
  
  return points
}

handleMouseDownLineToolCanvas = (e) => {

  if(this.state.selectBox===true) {
/*     this.setState({selectBox: false, selectingArea: false});
    const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
  if(topSelectionParent) {
    while(topSelectionParent.firstChild) {
      topSelectionParent.removeChild(topSelectionParent.lastChild);
    }
  } */
  
  this.setState({
    toolStartX: e.clientX,
    toolStartY: e.clientY,
          toolStartBoundingClientLeft: e.target.getBoundingClientRect().left,
        toolStartBoundingClientTop: e.target.getBoundingClientRect().top
  });
    return} else {
      this.setState({
        selectionCanStart: true
      });
    }
  const canvasBoundingRect = e.target.getBoundingClientRect();
  const x = e.clientX - canvasBoundingRect.left;
  const y = e.clientY - canvasBoundingRect.top;
  const cellX = Math.floor(x / this.state.drawZoom);
  const cellY = Math.floor(y / this.state.drawZoom);

  const startX = cellX * this.state.drawZoom;
  const startY = cellY * this.state.drawZoom;

  const drawContext = e.target.getContext("2d");
  drawContext.clearRect(0,0,e.target.width,e.target.height);
  const canvasTop = document.getElementById("drawerWrapperCanvasTop");
  if(canvasTop) {
    canvasTop.style.mixBlendMode = "normal";
  }
  const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
  if(topSelectionParent) {
    while(topSelectionParent.firstChild) {
      topSelectionParent.removeChild(topSelectionParent.lastChild);
    }
  }
  
    this.setState({
      toolStartX: e.clientX,
      toolStartY: e.clientY,
          toolStartBoundingClientLeft: e.target.getBoundingClientRect().left,
        toolStartBoundingClientTop: e.target.getBoundingClientRect().top
    },()=> {
      if(this.state.advancedMode!=="selectiontool") {
        drawContext.fillStyle = this.state.drawColor;
        /*     if(this.state.drawSize===1) { */
        if(this.state.stitchMode===true) {      
          var drawZm = this.state.drawZoom;   
          const img = document.getElementById('stitchImage');
          if(img) {
            drawContext.clearRect(startX,startY,drawZm,drawZm);
            
            drawContext.beginPath();
/*             drawContext.filter = "saturate(120%)"; */
drawContext.fillStyle = this.state.drawColorBright;
            drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
            drawContext.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
            drawContext.fill();
            drawContext.drawImage(img, startX, startY, drawZm, drawZm); 
/*             drawContext.filter = "saturate(100%)"; */
drawContext.fillStyle = this.state.drawColor;
            drawContext.fillRect(startX,startY,1,1);
            drawContext.fillRect(startX+drawZm-1,startY,1,1);
            drawContext.fillRect(startX+drawZm-1,startY+drawZm-1,1,1);
            drawContext.fillRect(startX,startY+drawZm-1,1,1);
          }
      }  else {
        drawContext.fillRect(startX, startY, this.state.drawZoom, this.state.drawZoom);
      }
              
      } 
    })
    /* }  *//* else if (this.state.drawSize===2) {
      drawContext.fillRect(startX-this.state.drawZoom, startY-this.state.drawZoom, this.state.drawZoom*3, this.state.drawZoom*3);
    } else {
      drawContext.fillRect(startX-(this.state.drawZoom*2), startY-(this.state.drawZoom*2), this.state.drawZoom*5, this.state.drawZoom*5);
    } */
}


createPixelRGBMatrixFromRGBArray = (rgbArray, width, height) => {
  var arr = new Array(width);
  for (var i = 0; i < arr.length; i++) {
    arr[i] = new Array(length);
}

var number = 0;
   do { 
      for(var x=0; x<width;x++) {
        var num = x+(width*number);  

          arr[x][number] = "rgba("+rgbArray[num][0]+","+rgbArray[num][1]+","+rgbArray[num][2]+","+rgbArray[num][3]+")";
        
        
        /* if(rgbArray[num][3]===0) {
         
        } */
      }
      number++;
    } while(number<height);
    return arr;
}


createDrawingSavedPattern = () => {

  var pal = document.getElementById('previewCanvasPattern');
  var ctx = pal.getContext("2d");
  const pixel = ctx.getImageData(0, 0, pal.width, pal.height);
  const data = pixel.data;
    var rgbArray = [];
  for (var i = 0; i < data.length; i += 4) {
    rgbArray.push([data[i], data[i + 1], data[i + 2]])
  }
  //this.createRGBArray(data);

const parentDiv = document.getElementById('drawerWrapper');
if(parentDiv.children.length<1) {
parentDiv.style.setProperty('--drawergrid-cols', pal.width);
  rgbArray.forEach(element => {
    const newDiv = document.createElement('div');
    parentDiv.appendChild(newDiv);
    newDiv.innerHTML = "x"//"&times;";
    newDiv.classList.add('drawDiv');
    const cursorRounded = document.querySelector('.cRounded');
/*  const cursorPointed = document.querySelector('.cPointed');  */

    parentDiv.addEventListener('mouseenter', (e)=> {
      if(this.state.advancedMode!=='colorpicker') return;
      cursorRounded.style.visibility = 'visible'
    });
    parentDiv.addEventListener('mouseleave', (e)=> {
      if(this.state.advancedMode!=='colorpicker') return;
      cursorRounded.style.visibility = 'hidden'
    });
    parentDiv.style.setProperty('--drawzoom-pixels', this.state.drawZoom+"px");
    if(this.state.savedPatternMode===true) {
      parentDiv.style.setProperty('--drawzoom-stitches', this.state.drawZoom/10+0.5+"em");
      parentDiv.style.setProperty('--drawzoom-stitchLine', (this.state.drawZoom*0.9/2)/10+"rem");
    } else {
      parentDiv.style.setProperty('--drawzoom-stitches', this.state.drawZoom*1.3/10+0.4+"em");
      parentDiv.style.setProperty('--drawzoom-stitchLine', (this.state.drawZoom*1.3/2)/10+0.1+"rem");
    }
     newDiv.addEventListener("mousemove", (e) => {
      
        this.handleColorPickerCursorMove(e,cursorRounded);
    }) 
    //newDiv.style.background = 'rgb('+element[0]+','+element[1]+','+element[2]+')';
    var colNormal = 'rgb('+Math.min(Math.max(parseInt(element[0]+20), 0), 255)+','+Math.min(Math.max(parseInt(element[1]+20), 0), 255)+','+Math.min(Math.max(parseInt(element[2]+20), 0), 255)+')';
    var colDark = 'rgb('+Math.min(Math.max(parseInt(element[0]+stitchDarkAmount), 0), 255)+','+Math.min(Math.max(parseInt(element[1]+stitchDarkAmount), 0), 255)+','+Math.min(Math.max(parseInt(element[2]+stitchDarkAmount), 0), 255)+')';
    //newDiv.style.background = 'rgb('+element[0]+','+element[1]+','+element[2]+')';
    newDiv.style.color = 'rgb('+element[0]+','+element[1]+','+element[2]+')';
    newDiv.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ colNormal+' '+stitchPercentBright+'%,'+colDark+')';
    //newDiv.setAttribute("data-id","");
    newDiv.addEventListener("pointerdown",(e)=>{
      if(this.state.advancedMode==="draw") {
        if(this.state.drawSize===1) {
          this.drawSomething(e,newDiv); 
        } else if (this.state.drawSize===2) {
          this.drawSomethingSize2(e, newDiv);
        }
     
      } else if(this.state.advancedMode==="colorpicker") {
        this.handleColorPickerClick(e);
      } else if(this.state.advancedMode==="buckettool") {
        this.floodFill(newDiv);
      } else if(this.state.advancedMode==="eraser") {
        if(this.state.drawSize===1) {
          this.eraseSomething(e,newDiv); 
        } else if (this.state.drawSize===2) {
          this.eraseSomethingSize2(e, newDiv);
        }
      }
      
    });
    //newDiv.addEventListener('pointerup', (e)=>{this.handleMouseUpDrawing(e)});
    newDiv.addEventListener("mouseover", (e) => {
      if(this.state.advancedMode==="draw")
      {
        if (e.buttons == 1) 
        {
          if(this.state.drawSize===1) {
            this.drawSomething(e,newDiv); 
          } else if (this.state.drawSize===2) {
            this.drawSomethingSize2(e, newDiv);
          }
        }
      }else if(this.state.advancedMode==="colorpicker") {
        this.handleColorPickerCursorColor(e,cursorRounded);
      }else if(this.state.advancedMode==="buckettool") {

      }else if(this.state.advancedMode==="eraser") {
        if (e.buttons == 1) 
        {
        if(this.state.drawSize===1) {
          this.eraseSomething(e,newDiv); 
        } else if (this.state.drawSize===2) {
          this.eraseSomethingSize2(e, newDiv);
        }
      }
      }
      
    });
  });
}
  pal.width = this.state.cropWidth;
  pal.height = this.state.cropHeight;
  
  const image = this.createPixelMatrix(parentDiv.children, pal.width, pal.height);
  this.setState({
    pixelMatrix: image,
    showLoadingAdvanced: false
  })
}

rotateClockwiseBy90 = (canvas, context, img) => {
  const width = canvas.width;
  const height = canvas.height;

  const imageData = context.getImageData(0, 0, width, height);
  const rotatedImageData = context.createImageData(height, width);

  //[redIndex, greenIndex, blueIndex, alphaIndex]
  const width4 = width * 4;
  const height4 = height * 4;

  for (let y = 0; y < height4; y += 4) {
      for (let x = 0; x < width4; x += 4) {
          rotatedImageData.data[x * height + (height4 - y - 1) - 3] = imageData.data[y * width + x];
          rotatedImageData.data[x * height + (height4 - y - 1) - 2] = imageData.data[y * width + x + 1];
          rotatedImageData.data[x * height + (height4 - y - 1) - 1] = imageData.data[y * width + x + 2];
          rotatedImageData.data[x * height + (height4 - y - 1)] = imageData.data[y * width + x + 3];
      }
  }

  const cw = canvas.width;
  canvas.width = canvas.height;
  canvas.height = cw;
  context.putImageData(rotatedImageData, 0, 0);
  //Selectionbox outline needs to be updated aswell
  const selectBoxOutline = document.getElementById("selectionBoxBorderDiv");
  selectBoxOutline.style.width = canvas.width+"px";
  selectBoxOutline.style.height = canvas.height+"px";
  this.setState({selectionChange: true});
}

rotateCounterClockwiseBy90 = (canvas, context, img) => {
  const width = canvas.width;
  const height = canvas.height;

  const imageData = context.getImageData(0, 0, width, height);
  const rotatedImageData = context.createImageData(height, width);

  //[redIndex, greenIndex, blueIndex, alphaIndex]
  const width4 = width * 4;
  const height4 = height * 4;

  for (let y = 0; y < height4; y += 4) {
    for (let x = 0; x < width4; x += 4) {
        rotatedImageData.data[(height4 * (width - x/4) - height4 + y)] = imageData.data[y * width + x];
        rotatedImageData.data[(height4 * (width - x/4) - height4 + y) + 1] = imageData.data[y * width + x + 1];
        rotatedImageData.data[(height4 * (width - x/4) - height4 + y) + 2] = imageData.data[y * width + x + 2];
        rotatedImageData.data[(height4 * (width - x/4) - height4 + y) + 3] = imageData.data[y * width + x + 3];
    }
}

  const cw = canvas.width;
  canvas.width = canvas.height;
  canvas.height = cw;
  context.putImageData(rotatedImageData, 0, 0);
  //Selectionbox outline needs to be updated aswell
  const selectBoxOutline = document.getElementById("selectionBoxBorderDiv");
  selectBoxOutline.style.width = canvas.width+"px";
  selectBoxOutline.style.height = canvas.height+"px";
  this.setState({selectionChange: true});
}

mirrorImage = (ctx, image, x = 0, y = 0, horizontal = false, vertical = false) => {
  const tempcanvas = document.createElement("canvas");
  tempcanvas.width = image.width;
  tempcanvas.height = image.height;
  const tempCtx = tempcanvas.getContext("2d");
  tempCtx.drawImage(image,0,0);
  ctx.save();  // save the current canvas state
  ctx.setTransform(
      horizontal ? -1 : 1, 0, // set the direction of x axis
      0, vertical ? -1 : 1,   // set the direction of y axis
      x + (horizontal ? image.width : 0), // set the x origin
      y + (vertical ? image.height : 0)   // set the y origin
  );
  ctx.clearRect(0,0,image.width,image.height);
  ctx.drawImage(tempcanvas,0,0);
  ctx.restore(); // restore the state as it was when this function was called
  tempcanvas.remove();
  
}

handleFlipSelectionHorizontally = () => {
  const canvas = document.getElementById('selectionBox');
  if(canvas) {
    const canvasContext = canvas.getContext('2d');
    this.mirrorImage(canvasContext,canvas,0,0,true,false);
    this.setState({selectionChange: true});
  }
}

handleFlipSelectionVertically = () => {
  const canvas = document.getElementById('selectionBox');
  if(canvas) {
    const canvasContext = canvas.getContext('2d');
    this.mirrorImage(canvasContext,canvas,0,0,false,true);
    this.setState({selectionChange: true});
  }
}

handleRotateSelectionLeft = () => {
  /* if(this.state.selectBox===false) {
    console.log("No select box!");
    return;} */
      const canvasToApply = document.getElementById("selectionBox");
    if(canvasToApply) {
      const ctx = canvasToApply.getContext("2d");/* 
      const imageData = ctx.getImageData(0,0,canvasToApply.width,canvasToApply.height);
      ctx.rotate(90 * (Math.PI / 180));
      ctx.putImageData(imageData,0,0); */
      this.rotateCounterClockwiseBy90(canvasToApply,ctx,null);
    } 
}

handleRotateSelectionRight = () => {
  /* if(this.state.selectBox===false) {
    console.log("No select box!");
    return;} */
      const canvasToApply = document.getElementById("selectionBox");
    if(canvasToApply) {
      const ctx = canvasToApply.getContext("2d");/* 
      const imageData = ctx.getImageData(0,0,canvasToApply.width,canvasToApply.height);
      ctx.rotate(90 * (Math.PI / 180));
      ctx.putImageData(imageData,0,0); */
      this.rotateClockwiseBy90(canvasToApply,ctx,null);
    } 
}

  createDrawing = () => {

    var pal = document.getElementById('pal_final');
    var ctx = pal.getContext("2d");
    const pixel = ctx.getImageData(0, 0, pal.width, pal.height);
    const data = pixel.data;
      var rgbArray = [];
    for (var i = 0; i < data.length; i += 4) {
      rgbArray.push([data[i], data[i + 1], data[i + 2]])
    }
    //this.createRGBArray(data);

const parentDiv = document.getElementById('drawerWrapper');
parentDiv.style.setProperty('--drawergrid-cols', pal.width);
    rgbArray.forEach(element => {
      const newDiv = document.createElement('div');
      parentDiv.appendChild(newDiv);
      newDiv.innerHTML = "&times;";
      newDiv.classList.add('drawDiv');
      const cursorRounded = document.querySelector('.cRounded');
 /*  const cursorPointed = document.querySelector('.cPointed');  */

      parentDiv.addEventListener('mouseenter', (e)=> {
        if(this.state.advancedMode!=='colorpicker') return;
        cursorRounded.style.visibility = 'visible'
      });
      parentDiv.addEventListener('mouseleave', (e)=> {
        if(this.state.advancedMode!=='colorpicker') return;
        cursorRounded.style.visibility = 'hidden'
      });
      parentDiv.style.setProperty('--drawzoom-pixels', this.state.drawZoom+"px");
      if(this.state.savedPatternMode===true) {
        parentDiv.style.setProperty('--drawzoom-stitches', this.state.drawZoom/10+0.5+"em");
        parentDiv.style.setProperty('--drawzoom-stitchLine', (this.state.drawZoom*0.9/2)/10+"rem");
      } else {
        parentDiv.style.setProperty('--drawzoom-stitches', this.state.drawZoom*1.3/10+0.4+"em");
        parentDiv.style.setProperty('--drawzoom-stitchLine', (this.state.drawZoom*1.3/2)/10+0.1+"rem");
      }
       newDiv.addEventListener("mousemove", (e) => {
        
          this.handleColorPickerCursorMove(e,cursorRounded);
      }) 
      var colNormal = 'rgb('+Math.min(Math.max(parseInt(element[0]+20), 0), 255)+','+Math.min(Math.max(parseInt(element[1]+20), 0), 255)+','+Math.min(Math.max(parseInt(element[2]+20), 0), 255)+')';
    var colDark = 'rgb('+Math.min(Math.max(parseInt(element[0]+stitchDarkAmount), 0), 255)+','+Math.min(Math.max(parseInt(element[1]+stitchDarkAmount), 0), 255)+','+Math.min(Math.max(parseInt(element[2]+stitchDarkAmount), 0), 255)+')';
/*     newDiv.style.background = 'rgb('+element[0]+','+element[1]+','+element[2]+')'; */
    newDiv.style.color = 'rgb('+element[0]+','+element[1]+','+element[2]+')';
    newDiv.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ colNormal+' '+stitchPercentBright+'%,'+colDark+')';
      //newDiv.setAttribute("data-id","");
      newDiv.addEventListener("pointerdown",(e)=>{
        if(this.state.advancedMode==="draw") {
          if(this.state.drawSize===1) {
            this.drawSomething(e,newDiv); 
          } else if (this.state.drawSize===2) {
            this.drawSomethingSize2(e, newDiv);
          }
       
        } else if(this.state.advancedMode==="colorpicker") {
          this.handleColorPickerClick(e);
        } else if(this.state.advancedMode==="buckettool") {
          this.floodFill(newDiv);
        } else if(this.state.advancedMode==="eraser") {
          if(this.state.drawSize===1) {
            this.eraseSomething(e,newDiv); 
          } else if (this.state.drawSize===2) {
            this.eraseSomethingSize2(e, newDiv);
          }
        }
        
      });
      //newDiv.addEventListener('pointerup', (e)=>{this.handleMouseUpDrawing(e)});
      newDiv.addEventListener("mouseover", (e) => {
        if(this.state.advancedMode==="draw")
        {
          if (e.buttons == 1) 
          {
            if(this.state.drawSize===1) {
              this.drawSomething(e,newDiv); 
            } else if (this.state.drawSize===2) {
              this.drawSomethingSize2(e, newDiv);
            }
          }
        }else if(this.state.advancedMode==="colorpicker") {
          this.handleColorPickerCursorColor(e,cursorRounded);
        }else if(this.state.advancedMode==="buckettool") {

        }else if(this.state.advancedMode==="eraser") {
          if (e.buttons == 1) 
          {
          if(this.state.drawSize===1) {
            this.eraseSomething(e,newDiv); 
          } else if (this.state.drawSize===2) {
            this.eraseSomethingSize2(e, newDiv);
          }
        }
        }
        
      });
    });
    
    const image = this.createPixelMatrix(parentDiv.children, pal.width, pal.height);
    this.setState({
      pixelMatrix: image,
      showLoadingAdvanced: false
    })
  }

  handleDrawSizeSliderChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      this.setState({
        drawSize: newValue
      })
    }
  };

  handleDrawSizeSwitchChange = (event) => {
    var state = this.state.drawSize;
    switch(state) {
      case 1:
        this.setState({
          drawSize: 2
        })
        break;
      case 2:
        this.setState({
          drawSize: 1
        })
        break;
      default:
        this.setState({
          drawSize: 1
        })
      break;
    }
  };


  
  handleSquareToolOutlineChange = (event) => {
    var state = this.state.squareToolOutline;
    switch(state) {
      case false:
        this.setState({
          squareToolOutline: true
        })
        break;
      case true:
        this.setState({
          squareToolOutline: false
        })
        break;
      default:
        this.setState({
          squareToolOutline: false
        })
      break;
    }
  };

  handleCircleToolOutlineChange = (event) => {
    var state = this.state.circleToolOutline;
    switch(state) {
      case false:
        this.setState({
          circleToolOutline: true
        })
        break;
      case true:
        this.setState({
          circleToolOutline: false
        })
        break;
      default:
        this.setState({
          circleToolOutline: false
        })
      break;
    }
  };

  drawSomething = (e, div) => {
/*     e.preventDefault();
    var rgb = null;
    if(this.state.stitchMode===true) {
      rgb = div.style.color.replace(/ /g,'');
    } else {
      rgb = div.style.background.replace(/ /g,'');
    }

    if(rgb===this.state.drawColor.replace(/ /g,'')) {
      return;
    }
    var state = this.state.undo+':'+rgb;
    if(div.getAttribute("data-id")!== null) {
      if (!div.getAttribute("data-id").includes(this.state.undo+':')) {
        div.setAttribute("data-id",`${div.getAttribute("data-id")}`+state+' ');
      }
    } else {
        div.setAttribute("data-id",state+' ');
    }
    if(this.state.stitchMode===true) {
      div.style.background = '';
      div.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ this.state.drawColorBright+' '+stitchPercentBright+'%,'+this.state.drawColorDark+')';
      div.style.color = this.state.drawColor;
    } else {
      div.style.background = this.state.drawColor;
      div.style.color = this.state.drawColor;
    } */
    
    
    //div.setAttribute('style', `${div.getAttribute("style")} background: `+this.state.drawColor);
  }

  eraseSomething = (e, div) => {
/*     e.preventDefault();
    var rgb = null;
    if(this.state.stitchMode===true) {
      rgb = div.style.color.replace(/ /g,'');
    } else {
      rgb = div.style.background.replace(/ /g,'');
    }
    var state = this.state.undo+':'+rgb;
    if(div.getAttribute("data-id")=== null) {
    return;
    }
      if (!div.getAttribute("data-id").includes(this.state.undo+':')) {
        div.setAttribute("data-id",`${div.getAttribute("data-id")}`+state+' ');
      }

      var originalCol = div.getAttribute("data-id").split(" ");
      var newArr = originalCol[0].split(":");
      originalCol = newArr[1];
      if(this.state.stitchMode===true) {
        var bCol = this.makeRgbStringDarkerOrLighter(originalCol, 20)
        var dCol = this.makeRgbStringDarkerOrLighter(originalCol,stitchDarkAmount);
        div.style.background = '';
        div.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
        div.style.color = originalCol;
      } else {
        div.style.background = originalCol;
        div.style.color = originalCol
      } */
      
      
  }

   getIndexOfK = (arr, k) => {
    for (var i = 0; i < arr.length; i++) {
      var index = arr[i].indexOf(k);
      if (index > -1) {
        return [i, index];
      }
    }
  }

  floodFill = (div) => {
    const image = this.state.pixelMatrix;
    var prevColor = null;
    if(this.state.stitchMode===true) {
      prevColor = div.style.color.replace(/ /g,'');
    } else {
      prevColor = div.style.background.replace(/ /g,'');
    }
    const newColor = this.state.drawColor.replace(/ /g,'');
    if(prevColor === newColor){

      return;
  }

  const xAndY = this.getIndexOfK(image,div);
  if(xAndY.length === 2)
  {
    this.bucketTool(image, xAndY[0], xAndY[1], prevColor, newColor);
  }
  }

  handleFormatColorRGBA = (color) => {
    var rgb = color.replace('rgb(','');
      rgb = rgb.replace(')','');
      rgb = rgb.replace(' ','');
      rgb = rgb.split(',');
      var red = Number.parseInt(rgb[0],10);
      var green = Number.parseInt(rgb[1],10);
      var blue = Number.parseInt(rgb[2],10);
      return 'rgba('+red+','+green+','+blue+',255)';
  }

  floodFillCanvas = (e) => {
    const canvas = document.getElementById("drawerCanvas");
    const ctx = canvas.getContext("2d");
    const canvasBoundingRect = e.target.getBoundingClientRect();
    const x = e.clientX - canvasBoundingRect.left;
    const y = e.clientY - canvasBoundingRect.top;
    const cellX = Math.floor(x / this.state.drawZoom);
    const cellY = Math.floor(y / this.state.drawZoom);
    const startX = cellX * this.state.drawZoom;
    const startY = cellY * this.state.drawZoom;
    let color = ctx.getImageData(startX,startY,1,1).data;
    let rgba = "rgba("+color[0]+","+color[1]+","+color[2]+","+color[3]+")";
    
    const newColor = this.state.drawColor.replace(/ /g,'');
    const rgbaNewColor = this.handleFormatColorRGBA(newColor);
    if(rgba===rgbaNewColor) {
      return;
    }
    let pixelArr = [];
    for(let yy=0; yy<this.state.heightCanvasDraw; yy++) {
      for(let xx=0; xx<this.state.widthCanvasDraw; xx++) {

          const pixel = ctx.getImageData(xx*this.state.drawZoom, yy*this.state.drawZoom, 1, 1).data;
          const rgb = [pixel[0],pixel[1],pixel[2],pixel[3]];
          pixelArr.push(rgb);
      } 
  }
    const rgbMatrix = this.createPixelRGBMatrixFromRGBArray(pixelArr,this.state.widthCanvasDraw,this.state.heightCanvasDraw);

    this.bucketToolCanvas(rgbMatrix, cellX, cellY, rgba, rgbaNewColor, ctx);
  }

  bucketToolCanvas = (pixelMatrix, x, y, prevColor, newColor, context) => {
    if(x < 0 || y < 0 || x > pixelMatrix.length - 1 || y > pixelMatrix[x].length - 1){
      return;
  }
  if(pixelMatrix[x][y].replace(/ /g,'') !== prevColor) {
    return;
  }

  //Update color
  pixelMatrix[x][y] = newColor;

  const startX = x * this.state.drawZoom;
  const startY = y * this.state.drawZoom;
  context.fillStyle = newColor;
  if(this.state.stitchMode===true) {      
    var drawZm = this.state.drawZoom;   
    const img = document.getElementById('stitchImage');
    if(img) {
      context.clearRect(startX,startY,drawZm,drawZm);
      
      context.beginPath();
/*       context.filter = "saturate(120%)"; */
context.fillStyle = this.state.drawColorBright;
      context.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),drawZm*0.65,(drawZm/2.75), Math.PI / 4, 0, 2 * Math.PI);
      context.ellipse(startX+ (drawZm/2),startY+ (drawZm/2),(drawZm/2.75) , drawZm*0.65, Math.PI / 4, 0, 2 * Math.PI);
      context.fill();
      context.drawImage(img, startX, startY, drawZm, drawZm); 
/*       context.filter = "saturate(100%)"; */
context.fillStyle = this.state.drawColor;
      context.fillRect(startX,startY,1,1);
      context.fillRect(startX+drawZm-1,startY,1,1);
      context.fillRect(startX+drawZm-1,startY+drawZm-1,1,1);
      context.fillRect(startX,startY+drawZm-1,1,1);
    }
} else {
  context.fillRect(startX, startY, this.state.drawZoom, this.state.drawZoom);
}
  

   //Fill in all four directions
   //Fill Prev row
   this.bucketToolCanvas(pixelMatrix, x - 1, y, prevColor, newColor, context);
   //Fill Next row
   this.bucketToolCanvas(pixelMatrix, x + 1, y, prevColor, newColor,context);
   //Fill Prev col
   this.bucketToolCanvas(pixelMatrix, x, y - 1, prevColor, newColor,context);
   //Fill next col
   this.bucketToolCanvas(pixelMatrix, x, y + 1, prevColor, newColor,context);
  }

  toggleDrawGrid = () => {
    const grid = this.state.drawGrid;
/*     const elements = document.querySelectorAll(".drawDiv"); */
/*     const canvasGuideElements = document.querySelectorAll(".drawGuideElement");
    const grid = this.state.drawGrid;
    if(grid===true) { */
/*       elements.forEach(element => {
        element.style.border = 'none';
      }); */
/*       canvasGuideElements.forEach(element => {
        if(element.style.borderTop === "1px solid rgba(0, 0, 0, 0.2)") {
          element.style.borderTop = '1px solid rgba(0, 0, 0, 0)';
        } else if(element.style.borderTop === "1px solid rgba(0, 0, 0, 0.6)") {
          element.style.borderTop = '1px dashed rgba(0, 0, 0, 0)';
        } else if(element.style.borderTop === "1px solid rgba(255, 0, 0, 0.6)") {
          element.style.borderTop = '1px solid rgba(255, 0, 0, 0)';
        }
        if(element.style.borderLeft === "1px solid rgba(0, 0, 0, 0.2)") {
          element.style.borderLeft = '1px solid rgba(0, 0, 0, 0)';
        } else if(element.style.borderLeft === "1px solid rgba(0, 0, 0, 0.6)") {
          element.style.borderLeft = '1px dashed rgba(0, 0, 0, 0)';
        } else if(element.style.borderLeft === "1px solid rgba(255, 0, 0, 0.6)") {
          element.style.borderLeft = '1px solid rgba(255, 0, 0, 0)';
        }
        
      });
      
    } else { */
/*       elements.forEach(element => {
        element.style.border = '1px solid rgba(0,0,0,0.1)';
      }); */
/*       canvasGuideElements.forEach(element => {
        if(element.style.borderTop==="1px solid rgba(0, 0, 0, 0)") {
          element.style.borderTop = '1px solid rgba(0, 0, 0, 0.2)';
        } else if(element.style.borderTop==="1px dashed rgba(0, 0, 0, 0)") {
          element.style.borderTop = '1px solid rgba(0, 0, 0, 0.6)';
        } else if(element.style.borderTop === "1px solid rgba(255, 0, 0, 0)") {
          element.style.borderTop = '1px solid rgba(255, 0, 0, 0.6)';
        }

        if(element.style.borderLeft==="1px solid rgba(0, 0, 0, 0)") {
          element.style.borderLeft = '1px solid rgba(0, 0, 0, 0.2)';
        } else if(element.style.borderLeft==="1px dashed rgba(0, 0, 0, 0)") {
          element.style.borderLeft = '1px solid rgba(0, 0, 0, 0.6)';
        } else if(element.style.borderLeft === "1px solid rgba(255, 0, 0, 0)") {
          element.style.borderLeft = '1px solid rgba(255, 0, 0, 0.6)';
        }
        
      }); 
    }*/
    this.setState({
      drawGrid: !grid
    },(e)=> {
this.handleToggleDrawGridSettings(e);
    })
  }

  toggleStitchMode = () => {
/*     const elements = document.querySelectorAll(".drawDiv"); */
    const mode = this.state.stitchMode;
/*     if(mode===true) {
      elements.forEach(element => {
        var col = element.style.color;
        element.style.background = col;
        element.style.fontSize = '0px!important';
      });
      
    } else {
      elements.forEach(element => {
        var col = element.style.color;
        var bCol = this.makeRgbStringDarkerOrLighter(col, 20)
        var dCol = this.makeRgbStringDarkerOrLighter(col,stitchDarkAmount);
        element.style.background = '';
        element.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
        element.style.fontSize = 'var(--drawzoom-stitches)';
      });
    } */
    this.setState({
      stitchMode: !mode
    },()=> {
      this.createDrawingCanvasSavedPattern(this.state.drawZoom);
    })
  }


  makeRgbStringDarkerOrLighter = (color,num) => {

    
    var rgb = null;
    if(color.includes('rgba')) {
      rgb = color.replace('rgba(','');
    } else {
      rgb = color.replace('rgb(','');
    }
      
      rgb = rgb.replace(')','');
      rgb = rgb.replace(' ','');
      rgb = rgb.split(',');
      var red = Number.parseInt(rgb[0],10);
      red = Math.min(Math.max(parseInt(red+num), 0), 255);
      var green = Number.parseInt(rgb[1],10);
      green = Math.min(Math.max(parseInt(green+num), 0), 255);
      var blue = Number.parseInt(rgb[2],10);
      blue = Math.min(Math.max(parseInt(blue+num), 0), 255);
/*        if(num>0) {
        var hsv = this.RGBtoHSV([red,green,blue]);
        hsv[1] *= 1.1;
        var rgbs = this.HSVtoRGB(hsv);
        return 'rgb('+rgbs[0]+','+rgbs[1]+','+rgbs[2]+')';
      }  */
      return 'rgb('+red+','+green+','+blue+')';
  }

  RGBtoHSV = (color) => {
    var r,g,b,h,s,v;
    r= color[0];
    g= color[1];
    b= color[2];
    var min = Math.min( r, g, b );
    var max = Math.max( r, g, b );


    v = max;
    var delta = max - min;
    if( max != 0 )
        s = delta / max;        // s
    else {
        // r = g = b = 0        // s = 0, v is undefined
        s = 0;
        h = -1;
        return [h, s, undefined];
    }
    if( r === max )
        h = ( g - b ) / delta;      // between yellow & magenta
    else if( g === max )
        h = 2 + ( b - r ) / delta;  // between cyan & yellow
    else
        h = 4 + ( r - g ) / delta;  // between magenta & cyan
    h *= 60;                // degrees
    if( h < 0 )
        h += 360;
    if ( isNaN(h) )
        h = 0;
    return [h,s,v];
};

HSVtoRGB = (color) => {
    var i;
    var h,s,v,r,g,b;
    h= color[0];
    s= color[1];
    v= color[2];
    if(s === 0 ) {
        // achromatic (grey)
        r = g = b = v;
        return [r,g,b];
    }
    h /= 60;            // sector 0 to 5
    i = Math.floor( h );
    var f = h - i;          // factorial part of h
    var p = v * ( 1 - s );
    var q = v * ( 1 - s * f );
    var t = v * ( 1 - s * ( 1 - f ) );
    switch( i ) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;
        case 1:
            r = q;
            g = v;
            b = p;
            break;
        case 2:
            r = p;
            g = v;
            b = t;
            break;
        case 3:
            r = p;
            g = q;
            b = v;
            break;
        case 4:
            r = t;
            g = p;
            b = v;
            break;
        default:        // case 5:
            r = v;
            g = p;
            b = q;
            break;
    }
    return [r,g,b];
}

  bucketTool = (image, sr, sc, prevColor, newColor ) => {
/*     //If row is less than 0
    if(sr < 0 || sc < 0 || sr > image.length - 1 || sc > image[sr].length - 1){
      return;
  }
  //If the current pixel is not which needs to be replaced
  if(this.state.stitchMode===true) {
    if(image[sr][sc].style.color.replace(/ /g,'') !== prevColor){
      return;
  }
  } else {
    if(image[sr][sc].style.background.replace(/ /g,'') !== prevColor){
      return;
  }
  }
  
  var rgb = null;
  if(this.state.stitchMode===true) {
    rgb = image[sr][sc].style.color.replace(/ /g,'');
  } else {
    rgb = image[sr][sc].style.background.replace(/ /g,'');
  }
  var state = this.state.undo+':'+rgb;
  if(image[sr][sc].getAttribute("data-id")!== null) {
    if (!image[sr][sc].getAttribute("data-id").includes(this.state.undo+':')) {
      image[sr][sc].setAttribute("data-id",`${image[sr][sc].getAttribute("data-id")}`+state+' ');
    }
  } else {
    image[sr][sc].setAttribute("data-id",state+' ');
  }
   //Update the new color
   if(this.state.stitchMode===true) {
    var bCol = this.makeRgbStringDarkerOrLighter(newColor, 20)
    var dCol = this.makeRgbStringDarkerOrLighter(newColor,stitchDarkAmount);
    image[sr][sc].style.background = '';
    image[sr][sc].style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
    image[sr][sc].style.color = newColor;
  } else {
    image[sr][sc].style.background = newColor;
    image[sr][sc].style.color = newColor;
  }
   
   //Fill in all four directions
   //Fill Prev row
   this.bucketTool(image, sr - 1, sc, prevColor, newColor);
   //Fill Next row
   this.bucketTool(image, sr + 1, sc, prevColor, newColor);
   //Fill Prev col
   this.bucketTool(image, sr, sc - 1, prevColor, newColor);
   //Fill next col
   this.bucketTool(image, sr, sc + 1, prevColor, newColor); */
  }

  eraseSomethingSize2 = (e, div) => {
  /*   e.preventDefault();
    const image = this.state.pixelMatrix;
    const xAndY = this.getIndexOfK(image,div);
    if(xAndY.length !== 2)
    {
      return;
    }
    var divX = xAndY[0];
    var divY = xAndY[1];
    var rgb = null;
    if(this.state.stitchMode===true) {
      rgb = div.style.color.replace(/ /g,'');
    } else {
      rgb = div.style.background.replace(/ /g,'');
    }
    var state = this.state.undo+':'+rgb;

    var xMinus = undefined;
    var xPlus = undefined;
    var yMinus = undefined;
    var yPlus = undefined;
    if(divX!==0)
    {
      xMinus  = image[divX-1][divY];
    } 
    if(image.length-1!==divX)
    {
      xPlus   = image[divX+1][divY];
    }
    if(divY!==0)
    {
      yMinus  = image[divX][divY-1];
    }
    if(image[0].length-1!==divY)
    {
      yPlus   = image[divX][divY+1];
    }
    if(div.getAttribute("data-id")!== null) {
      if (!div.getAttribute("data-id").includes(this.state.undo+':')) {
        div.setAttribute("data-id",`${div.getAttribute("data-id")}`+state+' ');
      }
    } 
    //Check boundary and then check if same color
    if(xMinus!==undefined) {
      var rgbxM = null;
      if(this.state.stitchMode===true) {
        rgbxM = xMinus.style.color.replace(/ /g,'');
      } else {
        rgbxM = xMinus.style.background.replace(/ /g,'');
      }
        var statexM = this.state.undo+':'+rgbxM;
        if(xMinus.getAttribute("data-id")!== null) {
          if (!xMinus.getAttribute("data-id").includes(this.state.undo+':')) {
            xMinus.setAttribute("data-id",`${xMinus.getAttribute("data-id")}`+statexM+' ');
          }
        } 
        var originalCol;
        if(xMinus.getAttribute("data-id")!== null) {
          originalCol = xMinus.getAttribute("data-id").split(" ");
          var newArr = originalCol[0].split(":");
          originalCol = newArr[1];
          if(this.state.stitchMode===true) {
            var bCol = this.makeRgbStringDarkerOrLighter(originalCol, 20)
            var dCol = this.makeRgbStringDarkerOrLighter(originalCol,stitchDarkAmount);
            xMinus.style.background = '';
            xMinus.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
            xMinus.style.color = originalCol;
          } else {
            xMinus.style.background = originalCol;
            xMinus.style.color = originalCol;
          }
          
        }

  }
  if(xPlus!==undefined) {
    var rgbxP = null;
    if(this.state.stitchMode===true) {
      rgbxP = xPlus.style.color.replace(/ /g,'');
    } else {
      rgbxP = xPlus.style.background.replace(/ /g,'');
    }
      var statexP = this.state.undo+':'+rgbxP;
      if(xPlus.getAttribute("data-id")!== null) {
        if (!xPlus.getAttribute("data-id").includes(this.state.undo+':')) {
          xPlus.setAttribute("data-id",`${xPlus.getAttribute("data-id")}`+statexP+' ');
        }
      } 

      var originalCol;
      if(xPlus.getAttribute("data-id")!== null) {
        originalCol = xPlus.getAttribute("data-id").split(" ");
        var newArr = originalCol[0].split(":");
      originalCol = newArr[1];
      if(this.state.stitchMode===true) {
        var bCol = this.makeRgbStringDarkerOrLighter(originalCol, 20)
        var dCol = this.makeRgbStringDarkerOrLighter(originalCol,stitchDarkAmount);
        xPlus.style.background = '';
        xPlus.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
        xPlus.style.color = originalCol;
      } else {
        xPlus.style.background = originalCol;
        xPlus.style.color = originalCol;
      }
      }
}
if(yMinus!==undefined) {
  var rgbyM = null;
      if(this.state.stitchMode===true) {
        rgbyM = yMinus.style.color.replace(/ /g,'');
      } else {
        rgbyM = yMinus.style.background.replace(/ /g,'');
      }
  var stateyM = this.state.undo+':'+rgbyM;
  if(yMinus.getAttribute("data-id")!== null) {
    if (!yMinus.getAttribute("data-id").includes(this.state.undo+':')) {
      yMinus.setAttribute("data-id",`${yMinus.getAttribute("data-id")}`+stateyM+' ');
    }
  } 
  var originalCol;
  if(yMinus.getAttribute("data-id")!== null) {
    originalCol = yMinus.getAttribute("data-id").split(" ");
    var newArr = originalCol[0].split(":");
      originalCol = newArr[1];
      if(this.state.stitchMode===true) {
        var bCol = this.makeRgbStringDarkerOrLighter(originalCol, 20)
        var dCol = this.makeRgbStringDarkerOrLighter(originalCol,stitchDarkAmount);
        yMinus.style.background = '';
        yMinus.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
        yMinus.style.color = originalCol;
      } else {
        yMinus.style.background = originalCol;
        yMinus.style.color = originalCol;
      }
  
  
}
    
  }
  if(yPlus!==undefined) {
    var rgbyP = null;
      if(this.state.stitchMode===true) {
        rgbyP = yPlus.style.color.replace(/ /g,'');
      } else {
        rgbyP = yPlus.style.background.replace(/ /g,'');
      }

    var stateyP = this.state.undo+':'+rgbyP;
    if(yPlus.getAttribute("data-id")!== null) {
      if (!yPlus.getAttribute("data-id").includes(this.state.undo+':')) {
        yPlus.setAttribute("data-id",`${yPlus.getAttribute("data-id")}`+stateyP+' ');
      }
    } 
    var originalCol;
    if(yPlus.getAttribute("data-id")!== null) {
      originalCol = yPlus.getAttribute("data-id").split(" ");
      var newArr = originalCol[0].split(":");
      originalCol = newArr[1];
      if(this.state.stitchMode===true) {
        var bCol = this.makeRgbStringDarkerOrLighter(originalCol, 20)
        var dCol = this.makeRgbStringDarkerOrLighter(originalCol,stitchDarkAmount);
        yPlus.style.background = '';
        yPlus.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
        yPlus.style.color = originalCol;
      } else {
        yPlus.style.background = originalCol;
        yPlus.style.color = originalCol;
      }
    }
  
    
  }
  //erase color to self
  var originalCol;
  if(div.getAttribute("data-id")!== null) {
    originalCol = div.getAttribute("data-id").split(" ");
    var newArr = originalCol[0].split(":");
      originalCol = newArr[1];
      if(this.state.stitchMode===true) {
        var bCol = this.makeRgbStringDarkerOrLighter(originalCol, 20)
        var dCol = this.makeRgbStringDarkerOrLighter(originalCol,stitchDarkAmount);
        div.style.background = '';
        div.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
        div.style.color = originalCol;
      } else {
        div.style.background = originalCol;
        div.style.color = originalCol;
      }
  }
   */
  }




  drawSomethingSize2 = (e, div) => {
    /*
    e.preventDefault();
    const image = this.state.pixelMatrix;
    const xAndY = this.getIndexOfK(image,div);
    
    if(xAndY.length !== 2)
    {
      return;
    }
    var divX = xAndY[0];
    var divY = xAndY[1];
    var rgb = null;
    if(this.state.stitchMode===true) {
      rgb = div.style.color.replace(/ /g,'');
    } else {
      rgb = div.style.background.replace(/ /g,'');
    }
    var state = this.state.undo+':'+rgb;

    
    var xMinus = undefined;
    var xPlus = undefined;
    var yMinus = undefined;
    var yPlus = undefined;
    if(divX!==0)
    {
      xMinus  = image[divX-1][divY];
    } 
    if(image.length-1!==divX)
    {
      xPlus   = image[divX+1][divY];
    }
    if(divY!==0)
    {
      yMinus  = image[divX][divY-1];
    }
    if(image[0].length-1!==divY)
    {
      yPlus   = image[divX][divY+1];
    }
    if(div.getAttribute("data-id")!== null) {
      if (!div.getAttribute("data-id").includes(this.state.undo+':')) {
        div.setAttribute("data-id",`${div.getAttribute("data-id")}`+state+' ');
      }
    } else {
        div.setAttribute("data-id",state+' ');
    }
    //Check boundary and then check if same color
    if(xMinus!==undefined) {

          var rgbxM = null;
        if(this.state.stitchMode===true) {
          rgbxM = xMinus.style.color.replace(/ /g,'');
        } else {
          rgbxM = xMinus.style.background.replace(/ /g,'');
        }

        if(rgbxM!==this.state.drawColor)
        {
        var statexM = this.state.undo+':'+rgbxM;
        if(xMinus.getAttribute("data-id")!== null) {
          if (!xMinus.getAttribute("data-id").includes(this.state.undo+':')) {
            xMinus.setAttribute("data-id",`${xMinus.getAttribute("data-id")}`+statexM+' ');
          }
        } else {
          xMinus.setAttribute("data-id",statexM+' ');
        }
        if(this.state.stitchMode===true) {
          xMinus.style.background = '';
          xMinus.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ this.state.drawColorBright+' '+stitchPercentBright+'%,'+this.state.drawColorDark+')';
          xMinus.style.color = this.state.drawColor;
        } else {
          xMinus.style.background = this.state.drawColor;
          xMinus.style.color = this.state.drawColor;
        }
        
       
      }

        
    
  }
  if(xPlus!==undefined) {

      var rgbxP = null;
        if(this.state.stitchMode===true) {
          rgbxP = xPlus.style.color.replace(/ /g,'');
        } else {
          rgbxP = xPlus.style.background.replace(/ /g,'');
        }
      if(rgbxP!==this.state.drawColor)
      {
      var statexP = this.state.undo+':'+rgbxP;
      if(xPlus.getAttribute("data-id")!== null) {
        if (!xPlus.getAttribute("data-id").includes(this.state.undo+':')) {
          xPlus.setAttribute("data-id",`${xPlus.getAttribute("data-id")}`+statexP+' ');
        }
      } else {
        xPlus.setAttribute("data-id",statexP+' ');
      }
      if(this.state.stitchMode===true) {
        xPlus.style.background = '';
        xPlus.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ this.state.drawColorBright+' '+stitchPercentBright+'%,'+this.state.drawColorDark+')';
        xPlus.style.color = this.state.drawColor;
      } else {
        xPlus.style.background = this.state.drawColor;
        xPlus.style.color = this.state.drawColor;
      }
    }
    

 }
if(yMinus!==undefined) {
  var rgbyM = null;
        if(this.state.stitchMode===true) {
          rgbyM = yMinus.style.color.replace(/ /g,'');
        } else {
          rgbyM = yMinus.style.background.replace(/ /g,'');
        }
  if(rgbyM!==this.state.drawColor)
      {
  var stateyM = this.state.undo+':'+rgbyM;
  if(yMinus.getAttribute("data-id")!== null) {
    if (!yMinus.getAttribute("data-id").includes(this.state.undo+':')) {
      yMinus.setAttribute("data-id",`${yMinus.getAttribute("data-id")}`+stateyM+' ');
    }
  } else {
    yMinus.setAttribute("data-id",stateyM+' ');
  }
  if(this.state.stitchMode===true) {
    yMinus.style.background = '';
    yMinus.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ this.state.drawColorBright+' '+stitchPercentBright+'%,'+this.state.drawColorDark+')';
    yMinus.style.color = this.state.drawColor;
  } else {
    yMinus.style.background = this.state.drawColor;
    yMinus.style.color = this.state.drawColor;
  }

}
    
  }
  if(yPlus!==undefined) {
    var rgbyP = null;
        if(this.state.stitchMode===true) {
          rgbyP = yPlus.style.color.replace(/ /g,'');
        } else {
          rgbyP = yPlus.style.background.replace(/ /g,'');
        }
    if(rgbyP!==this.state.drawColor)
    {
    var stateyP = this.state.undo+':'+rgbyP;
    if(yPlus.getAttribute("data-id")!== null) {
      if (!yPlus.getAttribute("data-id").includes(this.state.undo+':')) {
        yPlus.setAttribute("data-id",`${yPlus.getAttribute("data-id")}`+stateyP+' ');
      }
    } else {
      yPlus.setAttribute("data-id",stateyP+' ');
    }
    if(this.state.stitchMode===true) {
      yPlus.style.background = '';
      yPlus.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ this.state.drawColorBright+' '+stitchPercentBright+'%,'+this.state.drawColorDark+')';
      yPlus.style.color = this.state.drawColor;
    } else {
      yPlus.style.background = this.state.drawColor;
      yPlus.style.color = this.state.drawColor;
    }
   
  }
      
  }
  //Add color to self
  if(this.state.stitchMode===true) {
    div.style.background = '';
    div.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ this.state.drawColorBright+' '+stitchPercentBright+'%,'+this.state.drawColorDark+')';
    div.style.color = this.state.drawColor;
  } else {
    div.style.background = this.state.drawColor;
    div.style.color = this.state.drawColor;
  } */
  }


  handleClearAllColorsCanvas = (e) => {
    e.preventDefault();
    let undoStateArr = this.state.drawCanvasUndoStates;
    
    const firstState = undoStateArr[0];
    
    var undoNumber = this.state.undo;
    while(undoStateArr.length-1!==undoNumber) {
      //Delete all the states after the current state when creating a new state if you have undone an action
      undoStateArr.pop();
    }
    
    if(this.state.savedPatternMode===true) {
      this.setState({
        madeChangeSavedPattern: true
      })
    }
   
    undoNumber++;
    let addToTop = 0;
    let addToLeft = 0;
    let addToRight = 0;
    let addToBottom = 0;

    //Calculate
    for(let i =0; i<undoStateArr.length; i++) {
      addToTop+=undoStateArr[i].canvasSize.top;
      addToLeft+=undoStateArr[i].canvasSize.left;
      addToRight+=undoStateArr[i].canvasSize.right;
      addToBottom+=undoStateArr[i].canvasSize.bottom;
    }
    var newFirstStateObj = {rgb: firstState.rgb,canvasSize: {top:addToTop,left:addToLeft,right:addToRight,bottom:addToBottom, orgWidth:firstState.canvasSize.orgWidth,orgHeight:firstState.canvasSize.orgHeight}}
    undoStateArr.push(newFirstStateObj);
    //console.log("increasing undo!");
    this.setState({drawCanvasUndoStates: undoStateArr, undo: undoNumber, madeChangeDrawCanvas: false, canClear: false},()=>{

      
      if(addToTop!==0 || addToLeft!==0 ||
        addToRight!==0 || addToBottom!==0) {
          this.setState({
            cropHeight: firstState.canvasSize.orgHeight, 
            cropWidth: firstState.canvasSize.orgWidth, 
            heightCanvasDraw: firstState.canvasSize.orgHeight,
            widthCanvasDraw: firstState.canvasSize.orgWidth
          }, (e)=> {
          this.createDrawingCanvasResize(-addToTop,-addToLeft,
            -addToRight,-addToBottom, this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgWidth,
            this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgHeight, true,true);
          });
        } else {
          this.createDrawingCanvasSavedPattern(this.state.drawZoom);
        }
      
      if(this.state.advancedMode==='colorreplacer') {
        this.createDMCDataDrawingCanvas();
      }
    });
  }

  clearAllColors = (e) => {
    e.preventDefault();
    var elements = document.querySelectorAll('[data-id]');
    elements.forEach(element => {
      
      var rgb = null;
      if(this.state.stitchMode===true) {
        rgb = element.style.color.replace(/ /g,'');
      } else {
        rgb = element.style.background.replace(/ /g,'');
      }
      var state = this.state.undo+':'+rgb;
      var originalCol = element.getAttribute("data-id").split(" ");
      var newArr = originalCol[0].split(":");
      originalCol = newArr[1];
      if(this.state.stitchMode===true) {
        var bCol = this.makeRgbStringDarkerOrLighter(originalCol, stitchPercentBright)
        var dCol = this.makeRgbStringDarkerOrLighter(originalCol,stitchDarkAmount);
        element.style.background = '';
        element.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
        element.style.color = originalCol;
      } else {
        element.style.background = originalCol;
        element.style.color = originalCol;
      }
      element.setAttribute("data-id",`${element.getAttribute("data-id")}`+state+' ');
        
    });
    if(this.state.advancedMode==='colorreplacer') {
      this.createDMCDataDrawing();
    }
    this.setState({
      canClear: false
    })
  }

  handleUndoDrawing = (e) => {
    e.preventDefault();
    //Undo!
/*     var elements = document.querySelectorAll('[data-id]');
    var undoNumber = this.state.undo;
    var col = null;
    elements.forEach(element => {

      
      var mainArray = element.dataset.id.split(" ");
   
      if(undoNumber===1){
        
        
        if(mainArray.length>1)
        {
            var arr = mainArray[mainArray.length-2].split(":");
        } else {
          var arr = mainArray[mainArray.length-1].split(":");
          
        }
            var state = this.state.undo-1;
         
            if(arr[0]===state.toString()) {
              
              if(this.state.stitchMode===true) {
                var bCol = this.makeRgbStringDarkerOrLighter(arr[1], 20)
                var dCol = this.makeRgbStringDarkerOrLighter(arr[1],stitchDarkAmount);
                element.style.background = '';
                element.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
                element.style.color = arr[1];
              } else {
                element.style.background = arr[1];
                element.style.color = arr[1];
              }
              
              col = arr[1];
            }
            element.removeAttribute("data-id");
        
      } else {

      if(mainArray.length===1) {
        if(mainArray[0]="")
        {
          //console.log("Removing1");
          element.removeAttribute("data-id");
        }
      } else { */

      
/*      */
   /*    if(mainArray.length>1)
      {
          var arr = mainArray[mainArray.length-2].split(":");
      } else {
        var arr = mainArray[mainArray.length-1].split(":");
        
      }
          var state = this.state.undo-1;
   
          if(arr[0]===state.toString()) {
            
            if(this.state.stitchMode===true) {
              var bCol = this.makeRgbStringDarkerOrLighter(arr[1], 20)
              var dCol = this.makeRgbStringDarkerOrLighter(arr[1],stitchDarkAmount);
              element.style.background = '';
              element.style.backgroundImage = 'radial-gradient(circle at 15% 15%,'+ bCol+' '+stitchPercentBright+'%,'+dCol+')';
              element.style.color = arr[1];
            } else {
              element.style.background = arr[1];
              element.style.color = arr[1];
            }
            col = arr[1];
            if(arr[0]==="0")
            {
            element.removeAttribute("data-id");

            } else {
              var index = mainArray.indexOf(mainArray[mainArray.length-2])
              //console.log(index);
              if(index > -1) {
                mainArray.splice(index,1);
                element.setAttribute("data-id",mainArray.join(' '));
               
              }
              
            }
            
          }
          
        
        }
      
      } */
        /* if(mainArray.length === this.state.undo) {
          
          var arr = mainArray[mainArray.length-1].split(":");
          element.style.background = arr[1];
          mainArray.splice(mainArray.length-1,1);
          element.setAttribute("data-id",mainArray.toString());
        } */
      
      
   
      //if(arr[0]===this.state.undo.toString()) {
        
        //element.setAttribute("data-id",`${element.getAttribute("data-id")}`+state);
      //}
      
      
   /*  }); */
/*      if(this.state.advancedMode==='colorreplacer') {
      if(col!==null) {
        this.setState({colorToReplace: "rgba("+tempRows[Object.keys(tempRows)[0]].red+","+tempRows[Object.keys(tempRows)[0]].green+","+tempRows[Object.keys(tempRows)[0]].blue+",255)"});
      }
    }  */

    const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
    if(topSelectionParent) {
      while(topSelectionParent.firstChild) {
        topSelectionParent.removeChild(topSelectionParent.lastChild);
      }
    } 

    var undoNumber = this.state.undo;
/*     var undoCounterColorReplacer = this.state.undoColorReplacerCounter;
    if(undoCounterColorReplacer>=1) {
    undoCounterColorReplacer--;
    }
    var colToReplace = this.state.drawCanvasUndoColorReplacerStates[undoCounterColorReplacer];
    console.log(this.state.drawCanvasUndoColorReplacerStates);
    console.log(colToReplace); */

/*     let undoStateArr = this.state.drawCanvasUndoStates;
    undoStateArr.pop(); */
    if(undoNumber===1) {
      //console.log("decreasing undo!");
      undoNumber--;
      this.setState({
/*         undoColorReplacerCounter: undoCounterColorReplacer,
        colorToReplace: colToReplace, */
        canClear: false,
        undo: undoNumber,
        selectBox: false,
        selectingArea: false
/*         drawCanvasUndoStates: undoStateArr */
      },()=>{
        if(this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.top!==0 || this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.left!==0 ||
          this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.right!==0 || this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.bottom!==0) {
            //console.log(this.state.drawCanvasUndoStates);
            this.setState({
              cropHeight: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgHeight, 
              cropWidth: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgWidth, 
              heightCanvasDraw: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgHeight,
              widthCanvasDraw: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgWidth
            }, (e)=> {
            this.createDrawingCanvasResize(-this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.top,-this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.left,
              -this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.right,-this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.bottom, this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.orgWidth,
              this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.orgHeight, true);
            });
          } else {
            this.createDrawingCanvasSavedPattern(this.state.drawZoom);
          }
        
      }) 
    } else {
      //console.log("decreasing undo!");
      undoNumber--;
      this.setState({
/*         undoColorReplacerCounter: undoCounterColorReplacer,
        colorToReplace: colToReplace, */
        undo: undoNumber,
        canClear: true,
        selectBox: false,
        selectingArea: false
    /*     drawCanvasUndoStates: undoStateArr */
      },()=>{
        if(this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.top!==0 || this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.left!==0 ||
          this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.right!==0 || this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.bottom!==0) {

            this.setState({
              cropHeight: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgHeight, 
              cropWidth: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgWidth, 
              heightCanvasDraw: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgHeight,
              widthCanvasDraw: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgWidth
            }, (e)=> {
            this.createDrawingCanvasResize(-this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.top,-this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.left,
              -this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.right,-this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.bottom, this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.orgWidth,
              this.state.drawCanvasUndoStates[undoNumber+1].canvasSize.orgHeight, true);
            });
          } else {
            this.createDrawingCanvasSavedPattern(this.state.drawZoom);
          }
      }) 
    }

  }



  handleRedoDrawing = (e) => {
    e.preventDefault();
/*     var undoNumber = this.state.undo;
    var undoCounterColorReplacer = this.state.undoColorReplacerCounter;
    undoCounterColorReplacer++;
    var colToReplace = this.state.drawCanvasUndoColorReplacerStates[undoCounterColorReplacer];
    console.log(this.state.drawCanvasUndoColorReplacerStates);
    console.log(undoCounterColorReplacer);
    console.log(colToReplace); */
    const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
    if(topSelectionParent) {
      while(topSelectionParent.firstChild) {
        topSelectionParent.removeChild(topSelectionParent.lastChild);
      }
    } 
    var undoNumber = this.state.undo;
/*     let undoStateArr = this.state.drawCanvasUndoStates;
    undoStateArr.pop(); */
    if(undoNumber!==this.state.drawCanvasUndoStates.length-1) {
      //console.log("increasing undo!");
      undoNumber++;
      this.setState({
/*         colorToReplace: colToReplace,
        undoColorReplacerCounter: undoCounterColorReplacer, */
        undo: undoNumber,
        canClear: true,
        selectBox: false,
        selectingArea: false,
        /* drawCanvasUndoStates: undoStateArr */
      },()=>{
        if(this.state.drawCanvasUndoStates[undoNumber].canvasSize.top!==0 || this.state.drawCanvasUndoStates[undoNumber].canvasSize.left!==0 ||
          this.state.drawCanvasUndoStates[undoNumber].canvasSize.right!==0 || this.state.drawCanvasUndoStates[undoNumber].canvasSize.bottom!==0) 
          {
            this.setState({
              cropHeight: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgHeight, 
              cropWidth: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgWidth, 
              heightCanvasDraw: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgHeight,
              widthCanvasDraw: this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgWidth
            }, (e)=> {
            this.createDrawingCanvasResize(this.state.drawCanvasUndoStates[undoNumber].canvasSize.top,this.state.drawCanvasUndoStates[undoNumber].canvasSize.left,
              this.state.drawCanvasUndoStates[undoNumber].canvasSize.right,this.state.drawCanvasUndoStates[undoNumber].canvasSize.bottom, this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgWidth,
              this.state.drawCanvasUndoStates[undoNumber].canvasSize.orgHeight, true,true);
            });
          }
          else {
            this.createDrawingCanvasSavedPattern(this.state.drawZoom);
          }
        
      }) 
    }

  }


 createCSSSelector = (selector, style) => {
  var style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = selector+' {'+style+'}';
  document.getElementsByTagName('head')[0].appendChild(style);
  return style;
  }

  zoomIn =() => {
    var zoomState = this.state.drawZoom;
    if(zoomState===1)
    {
      zoomState+=1;
    } else {
      if(zoomState<20)
      {
        zoomState+=2;
      }
    }
    const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
    if(topSelectionParent) {
      while(topSelectionParent.firstChild) {
        topSelectionParent.removeChild(topSelectionParent.lastChild);
      }
    } 
    
    
    this.setState(
      {
       drawZoom: zoomState,
       selectionBoxCopyData: null,
       selectBox: false,
       selectingArea: false,
       selectionCanStart: true
      },(e)=>{
        /* this.updateDrawDivs(e); */
        this.createDrawingCanvasSavedPattern(zoomState);
      }
    )
  }

  updateDrawDivs = () => {
    const parentDiv = document.getElementById("drawerWrapper");
    parentDiv.style.setProperty('--drawzoom-pixels', this.state.drawZoom+"px");
    if(this.state.savedPatternMode===true) {
      parentDiv.style.setProperty('--drawzoom-stitches', this.state.drawZoom/10+0.5+"em");
      parentDiv.style.setProperty('--drawzoom-stitchLine', (this.state.drawZoom*0.9/2)/10+"rem");
    } else {
      parentDiv.style.setProperty('--drawzoom-stitches', this.state.drawZoom*1.3/10+0.4+"em");
      parentDiv.style.setProperty('--drawzoom-stitchLine', (this.state.drawZoom*1.3/2)/10+0.1+"rem");
    }
    
  }


  zoomOut =() => {
    var zoomState = this.state.drawZoom;
    if(zoomState===4 && this.state.drawGrid===true) {
      this.toggleDrawGrid();
    }
    if(zoomState===2)
    {
      zoomState-=1;
    } else {
      if(zoomState>2)
      {
        zoomState-=2;
      }
    }

    const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
    if(topSelectionParent) {
      while(topSelectionParent.firstChild) {
        topSelectionParent.removeChild(topSelectionParent.lastChild);
      }
    } 

    this.setState(
      {
       drawZoom: zoomState,
       selectionBoxCopyData: null,
       selectionCanStart: true,
       selectBox: false,
       selectingArea: false,
      },(e)=>{
        /* this.updateDrawDivs(e); */
        this.createDrawingCanvasSavedPattern(zoomState);
      }
    )
  }

  handleSelectFontChange = (e) => {
    var font = e.target.value;
    this.setState({
      textToolFontSelected: font
    },()=>{
      this.handleUpdateTextTool();
    });
  }

  handleSelectZoomChange =(e) => {
    var zoomTo = e.target.value;
    if(zoomTo<4 && this.state.drawGrid===true) {
      this.toggleDrawGrid();
    }
    this.setState(
      {
       drawZoom: zoomTo,
       selectBox: false,
       selectingArea: false,
       selectionCanStart: true
      },(e)=>{
        /* this.updateDrawDivs(e) */
        this.createDrawingCanvasSavedPattern(zoomTo);
      }
    )
  }

  
/*    resizeTo = (canvas,pct) =>{
    var ctx = canvas.getContext("2d");
    var tempCanvas=document.createElement("canvas");
    var tctx=tempCanvas.getContext("2d");
    var cw=canvas.width;
    var ch=canvas.height;
    tempCanvas.width=cw;
    tempCanvas.height=ch;
    tctx.drawImage(canvas,0,0);
    canvas.width*=pct;
    canvas.height*=pct;
    var ctx=canvas.getContext('2d');
    ctx.drawImage(tempCanvas,0,0,cw,ch,0,0,cw*pct,ch*pct);
  } */



  

  /* handleWaitForLoad = async (img, durl) => {
    if(img.src === durl) {
      return true;
    }else {
      this.handleWaitForLoad(img, durl)
    }
  } */

  handleCloseAdvancedEdit = (e) => {
    if (e) e.preventDefault()
    const parentDiv = document.getElementById('drawerWrapperCanvas');

    if(parentDiv) {
      var pal = document.getElementById('pal_final');
      parentDiv.style.position = 'absolute';
      parentDiv.style.visibility = 'hidden';
      pal.style.position = 'unset';
      pal.style.visibility = 'inherit';
      var child = parentDiv.lastElementChild; 
      while (child) {
        parentDiv.removeChild(child);
          child = parentDiv.lastElementChild;
      }
    }

    const rulerParentX = document.getElementById('drawRulerXLineWrapper');
    const rulerX = document.getElementById('drawRulerXWrapper');
    const rulerParentY = document.getElementById('drawRulerYLineWrapper');
    const guideParentX = document.getElementById('drawGuideWrapperX');
    const guideParentY = document.getElementById('drawGuideWrapperY');
    if(rulerParentX) {
      rulerX.style.width = "0px";
    }

    if(rulerParentX) {
      var child = rulerParentX.lastElementChild; 
      while (child) {
        rulerParentX.removeChild(child);
          child = rulerParentX.lastElementChild;
      }
    }
    if(rulerParentY) {
      var child = rulerParentY.lastElementChild; 
      while (child) {
        rulerParentY.removeChild(child);
          child = rulerParentY.lastElementChild;
      }
    }
    if(guideParentX) {
      var child = guideParentX.lastElementChild; 
      while (child) {
        guideParentX.removeChild(child);
          child = guideParentX.lastElementChild;
      }
    }
    if(guideParentY) {
      var child = guideParentY.lastElementChild; 
      while (child) {
        guideParentY.removeChild(child);
          child = guideParentY.lastElementChild;
      }
    }

    const topSelectionParent = document.getElementById("drawerWrapperCanvasTopSelection");
      
    if(topSelectionParent) {
      while(topSelectionParent.firstChild) {
        topSelectionParent.removeChild(topSelectionParent.lastChild);
      }
    } 
    
    this.setState({ advancedEdit: false, wasInAdvancedEdit: false, openAreYouSureGoingBackFromAdvancedEdit: false, drawGrid: true, rgbArrayCanvas: [], drawCanvasUndoStates: [], undo: 0, selectionBoxCopyData: null, }, (e)=>{
      this.cropImage(e);
    });
  }

  handleOpenAreYouSure = (e) => {
    if (e) e.preventDefault()
    this.setState({ openAreYouSure: true });
  }

  handleCloseAreYouSure = (e) => {
    if (e) e.preventDefault()
    this.setState({ openAreYouSure: false });
  }

  handleChangeShareInput = (e,input) => {
    switch(input) {
      case "name":
        this.setState({
          shareName: e.target.value
        });
        break;
        case "description":
          this.setState({
            shareDescription: e.target.value
          });
          break;
        default:

          break;
    }
    
  }

  handleSharePatternSavedPattern = async (e) => {
    e.preventDefault();
    let shouldLoad = true;
    let errorText = null;
    
    //Get input data from share form
    const data = new FormData(e.currentTarget);
    var name = data.get("name");
    var desc = data.get("description");
    var categories = JSON.stringify(this.state.pickedCategories);
    
    if(name==="") {
      shouldLoad = false;
      errorText = t('share_dialog.error');
    }
    if(shouldLoad) {
      this.setState({
        loadingShare: true,
        shareError: null
      })
 try {
      /* await new Promise(r => setTimeout(r, 1000)); */
      var shareImageStorage = null;
      if(this.state.shareUploadImage.length>0) {
        var currentTime = +new Date();
        shareImageStorage = this.context.user.uid+currentTime+'';
        const imageRef = ref(storageRef, 'finished_embroideries/'+shareImageStorage);
        await uploadBytes(imageRef,this.state.shareUploadImage[0]);
      }

              //Create new image in storage and assign reference to the new doc.
              var currentTime = +new Date();
              const storageName = this.context.user.uid+currentTime+'';
             const imageRefOld = ref(storageRef, this.state.shareDatabaseRef.storageName);
             await getBlob(imageRefOld)
               .then((blob) => {
                 const newImageRef = ref(storageRef, storageName);
                 uploadBytes(newImageRef, blob);
                       });

      let docRef = doc(db, 'shared_patterns',this.state.shareDatabaseRef.storageName)
      const date = new Date().toLocaleString() + "";
      const d = new Date();
      let time = d.getTime();
      await setDoc(docRef, { 
              a4Pages: this.state.shareDatabaseRef.a4Pages,
              cmHeight: this.state.shareDatabaseRef.cmHeight,
              cmWidth: this.state.shareDatabaseRef.cmWidth,
              colorCount: this.state.shareDatabaseRef.colorCount,
              shared_time: date,
              fabric: this.state.shareDatabaseRef.fabric,
              name: this.state.shareDatabaseRef.name,
              stitchHeight: this.state.shareDatabaseRef.stitchHeight,
              stitchWidth: this.state.shareDatabaseRef.stitchWidth,
              storageName: storageName,
              photoUrl: '',
              totalStitches: this.state.shareDatabaseRef.totalStitches,
              dmcArray: this.state.shareDatabaseRef.dmcArray,
              userName: this.context.user.displayName,
              userId: this.context.user.uid,
              share_name: name, 
              share_description: desc, 
              share_categories: categories,
              share_milis : time,
              share_image_storage: shareImageStorage,
              downloads: JSON.stringify([]),
              downloadsNumber: 0,
              activeUsers: JSON.stringify([this.context.user.uid])
            });
      this.setState({
        loadingShare: false,
        isAlreadySharedByYou: true
      })
      this.handleCloseShare(e);
      this.handleOpenSnackbarTheSnackBar("success","Success! Thank you for sharing your pattern!");
    } catch(err) {
      console.log(err.message);
      this.setState({
        loadingShare: false
      })
      this.handleOpenSnackbarTheSnackBar("error","There has been an error:( Please refresh the page and try again");
    }
  
    } else {
      this.setState({
        shareError: errorText
      })
    }
   
  }

  handleSelectShareCategory = (e) => {
    e.preventDefault();
    var target = e.target;
    if(e.target.tagName==="DIV") {
      target = e.target.children[0];
    }
    var catObj = this.state.shareCategories;
    var categoriesThatHaveBeenPicked = this.state.pickedCategories;
 
    catObj.forEach(category => {
      if(target.innerHTML===this.state.categoryTranslator[category.name]) {
        
        var checked = category.checked;
        if(checked) {
          const index = categoriesThatHaveBeenPicked.indexOf(category.name);
          categoriesThatHaveBeenPicked.splice(index,1);
        } else {
          if(!categoriesThatHaveBeenPicked.includes(category.name)) {
            categoriesThatHaveBeenPicked.push(category.name);
          }
        }
        category.checked = !checked;
       /* console.log("Setting "+category.name+" to: "+!checked); */
      }
    })
    this.setState({
      shareCategories: catObj,
      pickedCategories: categoriesThatHaveBeenPicked
    });
  }
  handleRemoveCategory = (e) => {
    e.preventDefault();
    var target = null;
    if(e.target.tagName==="svg") {
      target = e.target.parentElement.children[0];
    } else {
      target = e.target.parentElement.parentElement.children[0];
    }
    var categoriesThatHaveBeenPicked = this.state.pickedCategories;
    var translatedPickedCategories = [];
    categoriesThatHaveBeenPicked.forEach((cat)=>{
      translatedPickedCategories.push(this.state.categoryTranslator[cat]);
    })
    var catObj = this.state.shareCategories;
     if(translatedPickedCategories.includes(target.innerHTML)) {
      catObj.forEach(category => {
        if(target.innerHTML===this.state.categoryTranslator[category.name]) {
         /*  var checked = category.checked; */
          category.checked = false;
        }
      })
      const index = translatedPickedCategories.indexOf(target.innerHTML);
      categoriesThatHaveBeenPicked.splice(index,1);
    }
    this.setState({
      shareCategories: catObj,
      pickedCategories: categoriesThatHaveBeenPicked
    }) 
  }
  handleOpenShare = (e) => {
    if (e) e.preventDefault()
    this.setState({ openShare: true });

  }

  handleCloseShare = (e) => {
    if (e) e.preventDefault()
    var cat = this.state.shareCategories;
    cat.forEach(el => {
      el.checked = false;
    }); 
    this.setState({ openShare: false , shareError: null, shareCategories: cat, pickedCategories: []  });
    /* var nameElement = document.getElementById("share_name");
    var descriptionElement = document.getElementById("share_description");
    this.shareNameRef.current.value = '';
    this.shareDescriptionRef.current.value = '';  */
  }

  handleOpenAreYouSureGoingBackFromAdvancedEdit = (e) => {
    if (e) e.preventDefault()
    this.setState({ openAreYouSureGoingBackFromAdvancedEdit: true });
  }

  handleCloseAreYouSureGoingBackFromAdvancedEdit = (e) => {
    if (e) e.preventDefault()
    this.setState({ openAreYouSureGoingBackFromAdvancedEdit: false });
  }

  handleOpenColorPickerReplaceMode = (e, colorToReplace) => {
    if (e) e.preventDefault()
    var col = null;
    Object.keys(DMCJSONColors).forEach(key => {
      if(key===colorToReplace) {
        col = "rgba("+DMCJSONColors[key].Red+","+DMCJSONColors[key].Green+","+DMCJSONColors[key].Blue+",255)";
      }
    });
    if(col!==null) {
      this.createDMCDataDrawingCanvas();
      this.setState({ replaceMode: true, colorToReplace: col });
    }
    
  }

  handleOpenColorPicker = (e) => {
    if (e) e.preventDefault()
    this.createDMCDataDrawingCanvas();
    this.setState({ openColorPicker: true });
  }

   handleCloseColorPicker = (e) => {
    if (e) e.preventDefault()
/*     var replaceM = this.state.replaceMode;
    if(replaceM===true) {
      this.createDMCDataDrawing();
    } */
    this.setState({ openColorPicker: false, replaceMode: false, colorToReplaceSelected: false });
  } 

  handleOpenAreYouSureUseTicket = (e) => {
    if (e) e.preventDefault()
    this.setState({ openAreYouSureUseTicket: true });
  }

  handleCloseAreYouSureUseTicket = (e) => {
    if (e) e.preventDefault()
    this.setState({ openAreYouSureUseTicket: false });
  }

  handleOpenUpgrade = (e) => {
    if (e) e.preventDefault()
    this.setState({ openUpgrade: true });
  }

  handleCloseUpgrade = (e) => {
    if (e) e.preventDefault()
    this.setState({ openUpgrade: false });
  }

  handleOpenGuide = (e) => {
    if (e) e.preventDefault()
    this.setState({ openGuide: true });
  }

  handleCloseGuide = (e) => {
    if (e) e.preventDefault()
    this.setState({ openGuide: false });
  }

  handleOpenDrawerCrop = (e) => {
    if (e) e.preventDefault()
    this.setState({ openDrawerCrop: true, canCrop: false },()=> {
      setTimeout((e)=>{this.handleMoveCropperTo(e)});
    });
  }

  handleCloseDrawerCrop = (e) => {
    if (e) e.preventDefault()
    this.setState({ openDrawerCrop: false, canCrop: true },()=> {
      setTimeout((e)=>{ this.handleMoveCropperFrom(e)},100);
    });
    if(this.state.madeChange===true) {
    setTimeout((e)=>{
        this.cropImage(e);

    },300)
  };

  }


  handleOpenDrawerEdit = (e) => {
    if (e) e.preventDefault()
    this.setState({ openDrawerEdit: true, canCrop: false });
  }

  handleSetEditModeTrueSavedPatterns = (e) => {
    if (e) e.preventDefault()
    this.setState({ editModeSavedPatterns: true });
  }

  handleSetEditModeFalseSavedPatterns = (e) => {
    if (e) e.preventDefault()
    this.setState({ editModeSavedPatterns: false });
  }

  handleCloseDrawerEdit = (e) => {
    if (e) e.preventDefault()
    this.setState({ openDrawerEdit: false, canCrop: true });
    if(this.state.madeChange===true) {
    setTimeout((e)=>{
        this.cropImage(e);
    },300)};

  }



  handleOpenSavedPatterns = async (e) => {
    if (e) e.preventDefault()
    if(this.props.allSavedPatterns.length===0) {
      await this.props.handleFetchAllPatterns(this.context.user);
    }
    this.setState({
      openSavedPatterns: true,
      compressionLoading: false
    })
      //Check if user matches the patterns in allSavedPatterns
/*       const q = collection(db, "users",this.context.user.uid+'/saved_patterns');
      const querySnapshot = await getDocs(q);
      if(querySnapshot.size!==this.props.allSavedPatterns.length) 
      {
        await this.props.handleFetchAllPatterns(this.context.user);
        this.setState({
          openSavedPatterns: true,
          compressionLoading: false
        })
        return;
      }

      if(querySnapshot.size !== 0) {
        let notYours = true;
        querySnapshot.forEach(doc => {
          let isPresent = false;
          this.props.allSavedPatterns.forEach((pattern)=> {
            if(pattern.id===doc.id) {
              isPresent=true;
            }
          })
          
          if(isPresent===false) {
            notYours=true;
          }
        });

        if(notYours) {
          await this.props.handleFetchAllPatterns(this.context.user);
          this.setState({
            openSavedPatterns: true,
            compressionLoading: false
          })
        }
      } */
      //Check if the length of the list of patterns has changed
    

  }

  handleCloseSavedPatterns = (e) => {
    if (e) e.preventDefault()
    this.setState({
      openSavedPatterns: false
    },async()=> {
      await new Promise(r => setTimeout(r, 100));
      this.setState({
        editModeSavedPatterns: false
      })
    })
  }

  getCanvasDataDromImage = (img) => {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL();
    return dataURL;
  }


  handleOpenSavedPatternPreview = async (pattern) => {
    let patternToGet = null;

    for(let i = 0; i<this.props.allSavedPatterns.length; i++) {
      if(pattern[0]===this.props.allSavedPatterns[i].milis) {
        patternToGet = this.props.allSavedPatterns[i];
      }
    }
    this.setState({
      savedPatternPreviewData: patternToGet,
      openSavedPattern: true,
      madeChangeSavedPattern: false
    }) 
  }

  handleCheckIfPatternAlreadyShared = async (pattern) => {
    const docRef = doc(db, "shared_patterns",pattern.storageName);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
      return true;
    }
    return false;
  }

  handleOpenSavedPatternPreviewNewNew = async (pattern) => {
    let patternToGet = null;
    for(let i = 0; i<this.props.allSavedPatterns.length; i++) {
      if(pattern[0]===this.props.allSavedPatterns[i].milis) {
        patternToGet = this.props.allSavedPatterns[i];
      }
    }
    if(patternToGet!==null) {
      let isAlreadyShared = await this.handleCheckIfPatternAlreadyShared(patternToGet);

      let dmc = JSON.parse(patternToGet.dmcArray);
      let counter = 0;
      let parentDivStateArray = [];
      let xCounterLocal = 0;
      let yCounterLocal = 0;
      if ((patternToGet.stitchWidth > this.state.parentDivSizeX) || (patternToGet.stitchHeight > this.state.parentDivSizeY)) {
  
        for (let y = 0; y < Math.ceil(patternToGet.stitchHeight / this.state.parentDivSizeY); y++) {
          for (let x = 0; x < Math.ceil(patternToGet.stitchWidth / this.state.parentDivSizeX); x++) {
            var mockObject = {};
            mockObject["x"] = x;
            mockObject["y"] = y;
            parentDivStateArray.push(mockObject);
            if (y === 0) {
              xCounterLocal++;
            }
            if (x === 0) {
              yCounterLocal++;
            }
            counter++
          }
        }
  
      }
      else {
        var mockObject = {};
        mockObject["x"] = 0;
        mockObject["y"] = 0;
        parentDivStateArray.push(mockObject);
        xCounterLocal = 1;
        yCounterLocal = 1;
        counter = 1;
      }
      this.setState({
        isSubscribed: true,
        openingOldPattern: true,
        savedPatternMode: true,
        donePage: false,
        displayStyleEdit: {
          visibility: 'hidden',
          position: 'fixed'
        },
        isASharedPattern: patternToGet.shared===undefined? false: patternToGet.shared,
        isAlreadySharedByYou: isAlreadyShared,
        cropResult: patternToGet.photoUrl,
        sharePreviewImg: patternToGet.photoUrl,
        imgSrc: patternToGet.photoUrl,
        imgSrcExt: patternToGet.photoUrl,
        cropWidth: patternToGet.stitchWidth,
        cropHeight: patternToGet.stitchHeight,
        shareDatabaseRef: patternToGet,
        dmcArray: dmc,
        numberOfPages: counter,
        a4PagesArray: parentDivStateArray,
        xCounter: xCounterLocal,
        yCounter: yCounterLocal,
        /* openSavedPattern: true, */
        compressionLoading: false,
        openSavedPatterns: false,
        madeChangeSavedPattern: false
      }) 
      await new Promise(r => setTimeout(r, 100));
    var child = null;
    let checkIfElementExistsAlready = document.getElementById('previewCanvasPattern');
    if(checkIfElementExistsAlready!==null) {
      checkIfElementExistsAlready.remove();
    }
    var can = document.createElement("canvas");
    can.setAttribute("id", "previewCanvasPattern");
    can.width = patternToGet.stitchWidth;
    can.height = patternToGet.stitchHeight;
    var destinationCanvasContext = can.getContext('2d');
    var myImg = new Image();
    myImg.onload = function() {
        destinationCanvasContext.drawImage(myImg, 0, 0, can.width, can.height);
      };
      myImg.src = patternToGet.photoUrl;
      myImg.crossOrigin = "Anonymous";
    var previewDiv = document.getElementById("patternPreviewDoneDiv");
    if (previewDiv !== null) {
      child = previewDiv.appendChild(can);
    }
    child.classList.add('upscaledCanvas1to1');
    let propWidth = patternToGet.stitchWidth;
    let propHeight = patternToGet.stitchHeight;
    let ratio;
    if (propWidth > propHeight) {
      ratio = propWidth / propHeight;
      propWidth = 100;
      propHeight = 100 * (1 / ratio);
    } else if (propHeight > propWidth) {
      ratio = propHeight / propWidth;
      propHeight = 100;
      propWidth = 100 * (1 / ratio);

    } else {
      propWidth = 100;
      propHeight = 100;
    }

    child.style.setProperty("--cropWidth", propWidth);
    child.style.setProperty("--cropHeight", propHeight);
  } else {
    this.setState({
      compressionLoading: false,
    }) 
  }

  }



  handleOpenSavedPatternPreviewFromSearchParams = async (storageName, subbed=false) => {
    

    let res = this.props.allSavedPatterns;
    if(res.length===0) {
     res = await this.props.handleFetchAllPatterns(this.context.user);
    }

    let patternToGet = null;
    for(let i = 0; i<res.length; i++) {

      if(storageName===res[i].storageName) {
        patternToGet = res[i];
      } else if(storageName===res[i].shareId) {
        patternToGet = res[i];

      }
    }
    if(patternToGet!==null) {
      if(patternToGet.shared===true && subbed===false) {
          this.setState({isSubscribed: false, showWelcome: true,compressionLoading: false});
          return;
      }
      let isAlreadyShared = await this.handleCheckIfPatternAlreadyShared(patternToGet);
      let dmc = JSON.parse(patternToGet.dmcArray);
      let counter = 0;
      let parentDivStateArray = [];
      let xCounterLocal = 0;
      let yCounterLocal = 0;
      if ((patternToGet.stitchWidth > this.state.parentDivSizeX) || (patternToGet.stitchHeight > this.state.parentDivSizeY)) {
  
        for (let y = 0; y < Math.ceil(patternToGet.stitchHeight / this.state.parentDivSizeY); y++) {
          for (let x = 0; x < Math.ceil(patternToGet.stitchWidth / this.state.parentDivSizeX); x++) {
            var mockObject = {};
            mockObject["x"] = x;
            mockObject["y"] = y;
            parentDivStateArray.push(mockObject);
            if (y === 0) {
              xCounterLocal++;
            }
            if (x === 0) {
              yCounterLocal++;
            }
            counter++
          }
        }
  
      }
      else {
        var mockObject = {};
        mockObject["x"] = 0;
        mockObject["y"] = 0;
        parentDivStateArray.push(mockObject);
        xCounterLocal = 1;
        yCounterLocal = 1;
        counter = 1;
      }
      this.setState({
        openingOldPattern: true,
        savedPatternMode: true,
        donePage: false,
        displayStyleEdit: {
          visibility: 'hidden',
          position: 'fixed'
        },
        isASharedPattern: patternToGet.shared===undefined? false: patternToGet.shared,
        isAlreadySharedByYou: isAlreadyShared,
        cropResult: patternToGet.photoUrl,
        sharePreviewImg: patternToGet.photoUrl,
        imgSrc: patternToGet.photoUrl,
        imgSrcExt: patternToGet.photoUrl,
        cropWidth: patternToGet.stitchWidth,
        cropHeight: patternToGet.stitchHeight,
        shareDatabaseRef: patternToGet,
        dmcArray: dmc,
        numberOfPages: counter,
        a4PagesArray: parentDivStateArray,
        xCounter: xCounterLocal,
        yCounter: yCounterLocal,
        /* openSavedPattern: true, */
        compressionLoading: false,
        madeChangeSavedPattern: false
      }) 
      await new Promise(r => setTimeout(r, 100));
    var child = null;
    var can = document.getElementById('previewCanvasPattern');
    if(can===null) {
      can = document.createElement("canvas");
      can.setAttribute("id", "previewCanvasPattern");
      
    }
    can.width = patternToGet.stitchWidth;
    can.height = patternToGet.stitchHeight;

    var destinationCanvasContext = can.getContext('2d');
    var myImg = new Image();
    myImg.onload = function() {
        destinationCanvasContext.drawImage(myImg, 0, 0, can.width, can.height);
      };
      myImg.src = patternToGet.photoUrl;
      myImg.crossOrigin = "Anonymous";
    var previewDiv = document.getElementById("patternPreviewDoneDiv");

    if (previewDiv !== null) {
      child = previewDiv.appendChild(can);
    }
    child.classList.add('upscaledCanvas1to1');
    let propWidth = patternToGet.stitchWidth;
    let propHeight = patternToGet.stitchHeight;
    let ratio;
    if (propWidth > propHeight) {
      ratio = propWidth / propHeight;
      propWidth = 100;
      propHeight = 100 * (1 / ratio);
    } else if (propHeight > propWidth) {
      ratio = propHeight / propWidth;
      propHeight = 100;
      propWidth = 100 * (1 / ratio);

    } else {
      propWidth = 100;
      propHeight = 100;
    }

    child.style.setProperty("--cropWidth", propWidth);
    child.style.setProperty("--cropHeight", propHeight);

  } else {
    if(!subbed) {
      this.setState({isSubscribed: false, showWelcome: true});
    }
    this.setState({
      compressionLoading: false,
    }) 

  }
  }




  handleOpenSavedPatternPreviewFromDropNewNew = async (number) => {
    let res = this.props.allSavedPatterns;
    if(this.props.allSavedPatterns.length===0) {
     res = await this.props.handleFetchAllPatterns(this.context.user);
    }
    let patternToGet = null;
    for(let i = 0; i<res.length; i++) {
      if(this.props.savedPatterns[number].photoUrl===res[i].photoUrl) {
        patternToGet = res[i];
      }
    }
    if(patternToGet!==null) {
      let isAlreadyShared = await this.handleCheckIfPatternAlreadyShared(patternToGet);

      let dmc = JSON.parse(patternToGet.dmcArray);
      let counter = 0;
      let parentDivStateArray = [];
      let xCounterLocal = 0;
      let yCounterLocal = 0;
      if ((patternToGet.stitchWidth > this.state.parentDivSizeX) || (patternToGet.stitchHeight > this.state.parentDivSizeY)) {
  
        for (let y = 0; y < Math.ceil(patternToGet.stitchHeight / this.state.parentDivSizeY); y++) {
          for (let x = 0; x < Math.ceil(patternToGet.stitchWidth / this.state.parentDivSizeX); x++) {
            var mockObject = {};
            mockObject["x"] = x;
            mockObject["y"] = y;
            parentDivStateArray.push(mockObject);
            if (y === 0) {
              xCounterLocal++;
            }
            if (x === 0) {
              yCounterLocal++;
            }
            counter++
          }
        }
  
      }
      else {
        var mockObject = {};
        mockObject["x"] = 0;
        mockObject["y"] = 0;
        parentDivStateArray.push(mockObject);
        xCounterLocal = 1;
        yCounterLocal = 1;
        counter = 1;
      }
      this.setState({
        openingOldPattern: true,
        savedPatternMode: true,
        donePage: false,
        displayStyleEdit: {
          visibility: 'hidden',
          position: 'fixed'
        },
        isASharedPattern: patternToGet.shared===undefined? false: patternToGet.shared,
        isAlreadySharedByYou: isAlreadyShared,
        cropResult: patternToGet.photoUrl,
        sharePreviewImg: patternToGet.photoUrl,
        imgSrc: patternToGet.photoUrl,
        imgSrcExt: patternToGet.photoUrl,
        cropWidth: patternToGet.stitchWidth,
        cropHeight: patternToGet.stitchHeight,
        shareDatabaseRef: patternToGet,
        dmcArray: dmc,
        numberOfPages: counter,
        a4PagesArray: parentDivStateArray,
        xCounter: xCounterLocal,
        yCounter: yCounterLocal,
        /* openSavedPattern: true, */
        compressionLoading: false,
        madeChangeSavedPattern: false
      }) 
      await new Promise(r => setTimeout(r, 100));
    var child = null;
    var can = document.createElement("canvas");
    can.setAttribute("id", "previewCanvasPattern");
    can.width = patternToGet.stitchWidth;
    can.height = patternToGet.stitchHeight;
    var destinationCanvasContext = can.getContext('2d');
    var myImg = new Image();
    myImg.onload = function() {
        destinationCanvasContext.drawImage(myImg, 0, 0, can.width, can.height);
      };
      myImg.src = patternToGet.photoUrl;
      myImg.crossOrigin = "Anonymous";
    var previewDiv = document.getElementById("patternPreviewDoneDiv");
    if (previewDiv !== null) {
      child = previewDiv.appendChild(can);
    }
    child.classList.add('upscaledCanvas1to1');
    let propWidth = patternToGet.stitchWidth;
    let propHeight = patternToGet.stitchHeight;
    let ratio;
    if (propWidth > propHeight) {
      ratio = propWidth / propHeight;
      propWidth = 100;
      propHeight = 100 * (1 / ratio);
    } else if (propHeight > propWidth) {
      ratio = propHeight / propWidth;
      propHeight = 100;
      propWidth = 100 * (1 / ratio);

    } else {
      propWidth = 100;
      propHeight = 100;
    }

    child.style.setProperty("--cropWidth", propWidth);
    child.style.setProperty("--cropHeight", propHeight);

  } else {
    this.setState({
      compressionLoading: false,
    }) 
  }
  }
/* 
  handleOpenSavedPatternPreviewFromDrop = async (number) => {
    let res = this.props.allSavedPatterns;
    if(this.props.allSavedPatterns.length===0) {
     res = await this.handleFetchAllPatterns(this.context.user);
    }
    let patternToGet = null;
    for(let i = 0; i<res.length; i++) {
      if(this.props.savedPatterns[number].photoUrl===res[i].photoUrl) {
        patternToGet = res[i];
      }
    }
    if(patternToGet!==null) {
    console.log(this.props.savedPatterns);
    console.log(patternToGet.photoUrl);
     this.setState({
      savedPatternPreviewData: patternToGet,
      imgSrc: patternToGet.photoUrl,
      imgSrcExt: patternToGet.photoUrl,
      //openSavedPattern: true, 
      compressionLoading: false,
    }) 
  } else {
    this.setState({
      compressionLoading: false,
    }) 
  }
  } */

  handleCloseSavedPatternPreview = (e) => {
    if (e) e.preventDefault()
    this.setState({
      openSavedPattern: false
    })
  }
/*   handleMoveCropConfigurationTo= (e) => {
    if (e) e.preventDefault()
    var el = document.getElementById("cropKonfiguration");
    var container = document.getElementById("drawerKonfiguration");
    container.prepend(el);
  }

  handleMoveCropConfigurationFrom= (e) => {
    if (e) e.preventDefault()
    var el = document.getElementById("cropWrapper");
    var container = document.getElementById("cropCard");
    container.appendChild(el);
  } */

  handleMoveCropperTo= (e) => {
    if (e) e.preventDefault()
/*     let konf = document.querySelectorAll('.konfiguration');
    for(let i = 0; i<konf.length; i++) {
      konf[i].style.display = 'initial!important';
    } */
    var el = document.querySelector(".cropperClass");
    var container = document.getElementById("drawerCropContainer");
    container.appendChild(el);
  }

  handleMoveCropperFrom= (e) => {
    if (e) e.preventDefault()
/*     let konf = document.querySelectorAll('.konfiguration');
    for(let i = 0; i<konf.length; i++) {
      konf[i].style.display = 'none';
    } */
    var el = document.querySelector(".cropperClass");
    var container = document.getElementById("cropWrapper");
    container.prepend(el);
  }

  handleClearToDefault = event => {
    if (event) event.preventDefault()
    window.removeEventListener('beforeunload', this.handleDontCloseWindow);
    
    document.body.classList = '';
    this.setState({
      rgbArrayCanvas: [],
      drawCanvasUndoStates: [],
      cropResult: null,
      selectionBoxCopyData: null,
      cropWidth: 100,
      cropHeight: 100,
      createBlankCanvasMode: false,
      undo: 0,
      isASharedPattern: false,
      isAlreadySharedByYou: false,
      wasInAdvancedEdit: false,
      advancedEdit: false,
      openingOldPattern: false,
      imgSrc: null,
      imgSrcExt: null,
      openAreYouSure: false,
      showLoading: true,
      donePage: true,
      PDFPage: true,
      savedPatternMode: false,
      displayStylePDFPage: {
        visibility: 'hidden',
        position: 'fixed'
      },
      displayStyleDonePage: {
        visibility: 'visible',
        position: 'static'
      },
      displayStyleEdit: {
        visibility: 'visible',
        position: 'static'
      }
    })
    if(this.props.freeMode === false) {
      this.isSubscribed(this.context.user);
      this.props.handleFetchLatestPatterns(this.context.user);
    }

    if(window.location.search)
    {
      this.removeHash();
    }
    
    //this.fileInputRef.current.value = null
  }


  removeHash = () => { 
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history)
        history.pushState("", document.title, loc.pathname);
    else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;

        loc.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

  handleChangeOnInputFieldWidth = (event) => {
    if (event) event.preventDefault()
    this.setState({ cropWidth: event.target.value });
    //console.log(this.cropper.getData(true))
    this.cropper.setCropBoxData({ width: this.state.cropWidth })
  }

  handleChangeOnInputFieldHeight = (event) => {
    if (event) event.preventDefault()
    this.setState({ cropHeight: event.target.value });
    //console.log(this.cropper.getData(true))
    //this.setState({height: event.target.value});
    this.cropper.setCropBoxData({ height: this.state.cropHeight })
  }

  handleChangeAspectFree = event => {
    if (event) event.preventDefault()
    this.setState(
      {
        madeChange: true,
        freeAspect: true,
        cropAspect: 'free'
      },()=> {
        setTimeout((e)=> { this.cropImage(e) }, 10);
      }
    )
  }

  handleChangeAspect1by1 = event => {
    if (event) event.preventDefault()
    this.setState(
      {
        madeChange: true,
        freeAspect: false,
        cropAspect: '11',
        aspectRatio1: 1,
        aspectRatio2: 1
      },()=> {
        setTimeout((e)=> { this.cropImage(e) }, 10);
      }
    )
  }

  handleChangeAspect16by9 = event => {
    if (event) event.preventDefault()
    this.setState(
      {
        cropAspect: '169',
        aspectRatio1: 16,
        aspectRatio2: 9
      }
    )
  }

  handleChangeAspect5by4 = event => {
    if (event) event.preventDefault()
    this.setState(
      {
        cropAspect: '54',
        aspectRatio1: 5,
        aspectRatio2: 4
      }
    )
  }

  handleFileSelect = event => {
    // console.log(event)
    const files = event.target.files
    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files)
      if (isVerified) {
        // imageBase64Data 
        const currentFile = files[0]
        const myFileItemReader = new FileReader()
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result
          this.setState({
            imgSrc: myResult,
            imgSrcExt: extractImageFileExtensionFromBase64(myResult)
          })
        }, false)

        myFileItemReader.readAsDataURL(currentFile)

      }
    }
  }
  selectItem = (item) => {
    const { resetThenSet } = this.props;
    const { title, id, key } = item;

    this.setState({
      headerTitle: title,
      isListOpen: false,
    }, () => resetThenSet(id, key));
  }

  toggleList = () => {
    this.setState(prevState => ({
      isListOpen: !prevState.isListOpen
    }))
  }

  pdfTableSizeRegulator = (e) => {
    if (e) e.preventDefault();

    let el = document.getElementById('tableToPrint');
    let child = el.children[1];
    let textSize = '20px';
    let symbolSize = '20px';
    let tableHeadHeight = '22px';
    if (this.state.cropWidth > 60) {
      if (this.state.dmcArray.length < 40 && this.state.dmcArray.length >= 30) {
        textSize = '22px';
        symbolSize = '26px';
        tableHeadHeight = '36px';
      }
      else if (this.state.dmcArray.length < 30 && this.state.dmcArray.length >= 15) {
        textSize = '24px';
        symbolSize = '30px';
        tableHeadHeight = '40px';
      }
      else if (this.state.dmcArray.length < 15) {
        textSize = '30px';
        symbolSize = '36px';
        tableHeadHeight = '50px';
      }
    }
    else {
      symbolSize = '24px';
    }
    child.style.fontSize = textSize;
    let symbols = child.querySelectorAll(".material-icons");
    let tableHead = child.querySelector("#pdfTableHead");
    tableHead.style.height = tableHeadHeight;
    for (let k = 0; k < symbols.length; k++) {
      symbols[k].style.fontSize = symbolSize;
    }
  }

  handleMadeChange = (e) => {
    let val = e.target.value;
    this.setState({
      madeChange: true,
      quant_colors: val
    });
  }

  handleSelectColorChange = (event) => {
    if (event) event.preventDefault()
    switch (event.target.value) {
      case 'profil1':
        this.setState({
          madeChange: true,
          selectColorValue: event.target.value,
        }, (e) => {
          this.handleChangePalette(e);
        }
        )
        break;

      case 'profil2':
        this.setState({
          madeChange: true,
          selectColorValue: event.target.value,
        }, (e) => {
          this.handleChangePalette(e);
        }
        )
        break;
    }

  }

  convertToKorssting = (cm) => {
    switch (this.state.selectBroderistof) {
      case 5.4:
        return Math.round(cm * 5.4);
      case 4.4:
        return Math.round(cm * 4.4);
      default:
        return Math.round(cm * 5.4);
    }
  }

  handleSelectMeasuringUnitChange = (event) => {
    if (event) event.preventDefault()
    if (event.target.value !== this.state.selectMeasuringUnit) {
      switch (event.target.value) {
        case this.props.t('common.unit_1_small'):
          if (this.state.widthInputError === false) {
            this.setState({ widthInputErrorText: this.props.t('create_page_2.result_width_description_1') + event.target.value + this.props.t('create_page_2.result_width_description_2'), widthInputErrorTextSmall: this.props.t('create_page_2.result_width_description_1_small') });
          }
          this.setState({ selectMeasuringUnitBool: true });
          break;
        case this.props.t('common.unit_2_small'):
          if (this.state.widthInputError === false) {
            this.setState({ widthInputErrorText: this.props.t('create_page_2.result_width_description_1') + event.target.value + this.props.t('create_page_2.result_width_description_2'), widthInputErrorTextSmall: this.props.t('create_page_2.result_width_description_1_small') });
          }
          this.setState({ selectMeasuringUnitBool: false });
          break;
          case this.props.t('common.unit_3_small'):
            if (this.state.widthInputError === false) {
              this.setState({ widthInputErrorText: this.props.t('create_page_2.result_width_description_1') + event.target.value + this.props.t('create_page_2.result_width_description_2'), widthInputErrorTextSmall: this.props.t('create_page_2.result_width_description_1_small') });
            }
            this.setState({ selectMeasuringUnitBool: false });
            break;
      }
    }
    this.setState({ madeChange: true, selectMeasuringUnit: event.target.value }, (e) => { this.validateWidthInputNoTarget(e) });

  }

  handleSelectBroderistofChange = (event) => {
    if (event) event.preventDefault()
    switch (event.target.value) {
      case 5.4:
        this.setState({ madeChange: true, selectBroderistof: event.target.value, selectBroderistofName: "AIDA 5.4" }, (e) => { this.validateWidthInputNoTarget(e) });
        break;

      case 4.4:
        this.setState({ madeChange: true, selectBroderistof: event.target.value, selectBroderistofName: "AIDA 4.4" }, (e) => { this.validateWidthInputNoTarget(e) });
        break;

      default:
        this.setState({madeChange: true, selectBroderistof: event.target.value, selectBroderistofName: "AIDA 5.4" }, (e) => { this.validateWidthInputNoTarget(e) });
        break;
    }

  }

  handleSelectChange = (event) => {
    if (event) event.preventDefault()
    switch (event.target.value) {
      case 'popart1':
        this.setState({
          madeChange: true,
          selectValue: event.target.value,
          quant_dithKern: 0,
          quant_method: 1,
          quant_minHueCols: 9999,
          quant_boxSize: [32, 32],
          quant_initColors: 9999,
          quant_boxPxls: 2,
        }, (e) => {
          this.handleUpdateQuant(e);
          this.cropImage(e);
        }
        )
        break;

      case 'popart2':
        this.setState({
          madeChange: true,
          selectValue: event.target.value,
          quant_dithKern: 0,
          quant_method: 2,
          quant_minHueCols: 0,
          quant_boxSize: [32, 32],
          quant_initColors: 512,
          quant_boxPxls: 2,
        }, (e) => {
          this.handleUpdateQuant(e);
          this.cropImage(e);
        }
        )
        break;
      case 'realistisk1':
        this.setState({
          madeChange: true,
          selectValue: event.target.value,
          quant_dithKern: "FloydSteinberg",
          quant_dithSerp: false,
          quant_method: 1,
          quant_minHueCols: 9999,
          quant_boxSize: [32, 32],
          quant_initColors: 4096,
          quant_boxPxls: 2,
        }, (e) => {
          this.handleUpdateQuant(e);
          this.cropImage(e);
        }
        )
        break;
      case 'realistisk2':
        this.setState({
          madeChange: true,
          selectValue: event.target.value,
          quant_dithKern: "FloydSteinberg",
          quant_dithSerp: false,
          quant_method: 2,
          quant_minHueCols: 0,
          quant_boxSize: [32, 32],
          quant_initColors: 0,
          quant_boxPxls: 2,
        }, (e) => {
          this.handleUpdateQuant(e);
          this.cropImage(e);
        }
        )
        break;
      case 'realistisk3':
        this.setState({
          madeChange: true,
          selectValue: event.target.value,
          quant_dithKern: "FloydSteinberg",
          quant_dithSerp: false,
          quant_method: 2,
          quant_minHueCols: 9999,
          quant_boxSize: [8, 8],
          quant_initColors: 0,
          quant_boxPxls: 6,
        }, (e) => {
          this.handleUpdateQuant(e);
          this.cropImage(e);
        }
        )
        break;
    }

  }



  handleValidateStitchCount = (e) => {
    if (e) e.preventDefault();
    let val;
    let accepted = true;
    let widthError = false;
    let widthMessage = this.props.t('create_page_2.result_width_description_1') + this.state.selectMeasuringUnit + this.props.t('create_page_2.result_width_description_2');
    let widthMessage2 = this.props.t('create_page_2.result_width_description_1_small');
    let heightValue = this.state.cropHeight;
    switch (this.state.selectMeasuringUnit) {
      case this.props.t('common.unit_1_small'):
        val = document.getElementById("widthInput").value;
        break;
      case this.props.t('common.unit_2_small'):
        val = Math.round(document.getElementById("widthInput").value * this.state.selectBroderistof);
        break;
        case this.props.t('common.unit_3_small'):
          val = Math.round(document.getElementById("widthInput").value * (this.state.selectBroderistof*2.54));
          break;
      default:
        val = document.getElementById("widthInput").value;
        break;
    }

    if (this.state.freeAspect === true) {
      let data = this.cropper.getData(true);
      let tempRatio = 1;
      if (data['width'] < data['height']) {
        tempRatio = data['height'] / data['width'];
        heightValue = Math.round(val * tempRatio);
      } else if (data['width'] > data['height']) {
        tempRatio = data['width'] / data['height'];
        heightValue = Math.round(val * (1 / tempRatio));
      } else {
        heightValue = val;
      }
      if (heightValue <= 0) {
        heightValue = 1
      }

    } else if (this.state.cropAspect === '11') {
      heightValue = val;
    }
    if(this.props.freeMode === true && val * heightValue > this.state.freeModeMax) {
      accepted = false
      widthMessage = this.props.t('create_page_2.width_error_4');
      widthMessage2 = this.props.t('create_page_2.width_error_4');
    }
    if (val * heightValue > this.state.maxStitchCount) {
      if(this.props.freeMode === false && this.props.settings.isUncapped===false) {
        accepted = false
        widthMessage = this.props.t('create_page_2.width_error_2');
        widthMessage2 = this.props.t('create_page_2.width_error_2');
      }
    } else if (Math.round(val) < 10) {
      accepted = false
      widthMessage = this.props.t('create_page_2.width_error_1');
      widthMessage2 = this.props.t('create_page_2.width_error_1');
    }
    const pattern = /^[0-9]+$/;
    if (!pattern.test(val) && this.state.selectMeasuringUnit!== t('common.unit_3_small')) {
      widthError = true;
      accepted= false;
      widthMessage = this.props.t('create_page_2.width_error_3');
      widthMessage2 = this.props.t('create_page_2.width_error_3');
    }

    if (accepted) {
      this.setState({ showError: false, widthInputError: widthError, widthInputErrorText: widthMessage, widthInputErrorTextSmall: widthMessage2 });
      return true;
    }
    else {
      this.setState({ showError: true, widthInputError: true, widthInputErrorText: widthMessage, widthInputErrorTextSmall: widthMessage2 });
      return false;
    }
  }

  handleCreatePDFPattern = (event) => {
    this.setState({ createPDFProgress: 0 });
/*     let widthValue;
    let heightValue = this.state.cropHeight;
    if(this.state.savedPatternMode===false) {
    const widthInput = document.getElementById("widthInput");
    switch (this.state.selectMeasuringUnit) {
      case this.props.t('common.unit_1_small'):
        widthValue = widthInput.value
        break;

      case this.props.t('common.unit_2_small'):
        widthValue = Math.round(widthInput.value * this.state.selectBroderistof)
        break;
        case this.props.t('common.unit_3_small'):
          widthValue = Math.round(widthInput.value * (this.state.selectBroderistof*2.54))
          break;
      default:
        widthValue = widthInput.value
        break;
    }

    if (widthValue <= 0) {
      widthValue = 1
    }
    
    if (this.state.freeAspect === true) {
      let data = this.cropper.getData(true);
      let tempRatio = 1;

      if (data['width'] < data['height']) {
        tempRatio = data['height'] / data['width'];
        heightValue = Math.round(widthValue * tempRatio);
      } else if (data['width'] > data['height']) {
        tempRatio = data['width'] / data['height'];
        heightValue = Math.round(widthValue * (1 / tempRatio));
      } else {
        heightValue = widthValue;
      } */

      /*THE ABSOLUTE PIXEL APPROACH */
      /*
      let scale = 100;
      heightValue = Math.round((data['height']/750)*scale);
      widthValue =  Math.round((data['width']/750)*scale);*/


/*       if (heightValue <= 0) {
        heightValue = 1
      }

    } else if (this.state.cropAspect === '11') {
      heightValue = widthValue
    }

    if (heightValue <= 0) {
      heightValue = 1
    }
  } else {
    widthValue = this.state.cropWidth;
    let tempRatio = 1;
    if (this.state.cropWidth < this.state.cropHeight) {
      tempRatio = this.state.cropHeight / this.state.cropWidth;
      heightValue = Math.round(widthValue * tempRatio);
    } else if (this.state.cropWidth > this.state.cropHeight) {
      tempRatio = this.state.cropWidth / this.state.cropHeight;
      heightValue = Math.round(widthValue * (1 / tempRatio));
    } else {
      heightValue = widthValue;
    } */

    /*THE ABSOLUTE PIXEL APPROACH */
    /*
    let scale = 100;
    heightValue = Math.round((data['height']/750)*scale);
    widthValue =  Math.round((data['width']/750)*scale);*/


/*     if (heightValue <= 0) {
      heightValue = 1
    }
  } */
  this.makeRows(this.state.cropWidth, this.state.cropHeight);
    /* setTimeout(function () {  }.bind(this), 10); */

  }



  handleCreatePreviewImage = (event) => {
    var element = document.getElementById("previewCanvasPattern");
    if (typeof (element) !== 'undefined' && element !== null) {
      element.remove()
    }
    var child = null;
    var can = document.createElement("canvas");
    can.setAttribute("id", "previewCanvasPattern");
    const widthInput = document.getElementById("widthInput")
    let widthValue;
    let heightValue = this.state.cropHeight;
    switch (this.state.selectMeasuringUnit) {
      case this.props.t('common.unit_1_small'):
        widthValue = widthInput.value
        break;

      case this.props.t('common.unit_2_small'):
        widthValue = Math.round(widthInput.value * this.state.selectBroderistof)
        break;
        case this.props.t('common.unit_3_small'):
          widthValue = Math.round(widthInput.value * (this.state.selectBroderistof*2.54))
          break;
      default:
        widthValue = widthInput.value
        break;
    }
    if (widthValue <= 0) {
      widthValue = 1
    }
    if (this.state.freeAspect === true) {
      let data = this.cropper.getData(true);
      let tempRatio = 1;

      if (data['width'] < data['height']) {
        tempRatio = data['height'] / data['width'];
        heightValue = Math.round(widthValue * tempRatio);
      } else if (data['width'] > data['height']) {
        tempRatio = data['width'] / data['height'];
        heightValue = Math.round(widthValue * (1 / tempRatio));
      } else {
        heightValue = widthValue;
      }

      /*THE ABSOLUTE PIXEL APPROACH */
      /*
      let scale = 100;
      heightValue = Math.round((data['height']/750)*scale);
      widthValue =  Math.round((data['width']/750)*scale);*/


      if (heightValue <= 0) {
        heightValue = 1
      }

    } else if (this.state.cropAspect === '11') {
      heightValue = widthValue
    }


    can.width = this.state.cropWidth;
    can.height = this.state.cropHeight;
    var sourceCanvasWebGL = document.getElementById("pal_final");
    var destinationCanvasContext = can.getContext('2d');
    destinationCanvasContext.drawImage(sourceCanvasWebGL, 0, 0, can.width, can.height);
    var previewDiv = document.getElementById("patternPreviewDoneDiv");
    if (previewDiv !== null) {
      child = previewDiv.appendChild(can);
    }
    if (this.state.aspectRatio1 === 5) {
      child.classList.add('upscaledCanvas5to4')
    } else if (this.state.aspectRatio1 === 16) {
      child.classList.add('upscaledCanvas16to9')
    }
    else {
      child.classList.add('upscaledCanvas1to1')
    }
    let propWidth = this.state.cropWidth;
    let propHeight = this.state.cropHeight;
    let ratio;
    if (propWidth > propHeight) {
      ratio = propWidth / propHeight;
      propWidth = 100;
      propHeight = 100 * (1 / ratio);
    } else if (propHeight > propWidth) {
      ratio = propHeight / propWidth;
      propHeight = 100;
      propWidth = 100 * (1 / ratio);

    } else {
      propWidth = 100;
      propHeight = 100;
    }

    child.style.setProperty("--cropWidth", propWidth);
    child.style.setProperty("--cropHeight", propHeight);
  }


  handleCreatePDFImage = (event) => {

    var element = document.getElementById("pdfCanvasPattern");
    if (typeof (element) !== 'undefined' && element !== null) {
      element.remove()
    }
    var child = null;
    var can = document.createElement("canvas");
    can.setAttribute("id", "pdfCanvasPattern");
    let widthValue;
    let heightValue = this.state.cropHeight;
    var sourceCanvasWebGL = null;
    if(this.state.savedPatternMode===false) {
    const widthInput = document.getElementById("widthInput")
    switch (this.state.selectMeasuringUnit) {
      case this.props.t('common.unit_1_small'):
        widthValue = widthInput.value
        break;

      case this.props.t('common.unit_2_small'):
        widthValue = Math.round(widthInput.value * this.state.selectBroderistof)
        break;
        case this.props.t('common.unit_3_small'):
          widthValue = Math.round(widthInput.value * (this.state.selectBroderistof*2.54))
          break;
      default:
        widthValue = widthInput.value
        break;
    }


    if (widthValue <= 0) {
      widthValue = 1
    }
    if (this.state.freeAspect === true) {
      let data = this.cropper.getData(true);
      let tempRatio = 1;

      if (data['width'] < data['height']) {
        tempRatio = data['height'] / data['width'];
        heightValue = Math.round(widthValue * tempRatio);
      } else if (data['width'] > data['height']) {
        tempRatio = data['width'] / data['height'];
        heightValue = Math.round(widthValue * (1 / tempRatio));
      } else {
        heightValue = widthValue;
      }

      /*THE ABSOLUTE PIXEL APPROACH */
      /*
      let scale = 100;
      heightValue = Math.round((data['height']/750)*scale);
      widthValue =  Math.round((data['width']/750)*scale);*/


      if (heightValue <= 0) {
        heightValue = 1
      }

    } else if (this.state.cropAspect === '11') {
      heightValue = widthValue
    }
    can.width = this.state.cropWidth;
      can.height = this.state.cropHeight;
    
      sourceCanvasWebGL = document.getElementById("pal_final");
    } else {
      widthValue = this.state.cropWidth;
      let tempRatio = 1;
      if (this.state.cropWidth < this.state.cropHeight) {
        tempRatio = this.state.cropHeight / this.state.cropWidth;
        heightValue = Math.round(widthValue * tempRatio);
      } else if (this.state.cropWidth > this.state.cropHeight) {
        tempRatio = this.state.cropWidth / this.state.cropHeight;
        heightValue = Math.round(widthValue * (1 / tempRatio));
      } else {
        heightValue = widthValue;
      }
      if (heightValue <= 0) {
        heightValue = 1
      }
      can.width = this.state.cropWidth;
      can.height = this.state.cropHeight;
      sourceCanvasWebGL = document.getElementById("previewCanvasPattern");
    }
    if(sourceCanvasWebGL!==null) {
    var destinationCanvasContext = can.getContext('2d');
    destinationCanvasContext.drawImage(sourceCanvasWebGL, 0, 0, can.width, can.height);
    var previewDiv = document.getElementById("patternPdfDiv");

    if (previewDiv !== null) {
      child = previewDiv.appendChild(can);
    }
    child.classList.add('upscaledCanvas1to1PDF');

    let div = document.getElementById("mønsterOpdeling");
    div.style.setProperty('--previewgrid-cols', this.state.xCounter);
    let propWidth = this.state.cropWidth;
    let propHeight = this.state.cropHeight;
    let ratio;
    if (propWidth > propHeight) {
      ratio = propWidth / propHeight;
      propWidth = 100;
      propHeight = 100 * (1 / ratio);
    } else if (propHeight > propWidth) {
      ratio = propHeight / propWidth;
      propHeight = 100;
      propWidth = 100 * (1 / ratio);

    } else {
      propWidth = 100;
      propHeight = 100;
    }


    child.style.setProperty("--cropWidth", propWidth);
    child.style.setProperty("--cropHeight", propHeight);
    setTimeout((e) => { this.handleMønsterOpdelingA4(e); }, 200);
    }
  }


  handleMønsterOpdelingA4 = (e) => {
    if (e) e.preventDefault();
    var mønsterOpdelingA4 = document.getElementById("mønsterOpdeling");
    let ratio;
    for (let i = 0; i < mønsterOpdelingA4.children.length; i++) {
      if (this.state.yCounter > 1) {
        if (this.state.xCounter > 1) {
          //Both are bigger than 1
          if (this.state.cropHeight > this.state.cropWidth) {
            ratio = this.state.cropWidth / this.state.cropHeight;
            let h = ((1 / this.state.cropHeight * 6) * this.state.parentDivSizeY * 100);
            mønsterOpdelingA4.children[i].style.height = h + 'px';
            mønsterOpdelingA4.children[i].style.width = ((1 / this.state.cropWidth * 6) * this.state.parentDivSizeX * 100) * (ratio) + 'px';
          } else {
            ratio = this.state.cropHeight / this.state.cropWidth;
            mønsterOpdelingA4.children[i].style.height = ((1 / this.state.cropHeight * 6) * this.state.parentDivSizeY * 100) * (ratio) + 'px';
            mønsterOpdelingA4.children[i].style.width = ((1 / this.state.cropWidth * 6) * this.state.parentDivSizeX * 100) + 'px';
          }
        } else {
          //Only y is bigger than 1
          ratio = this.state.cropWidth / this.state.cropHeight;
          let h = ((1 / this.state.cropHeight * 6) * this.state.parentDivSizeY * 100);
          mønsterOpdelingA4.children[i].style.height = h + 'px';
          mønsterOpdelingA4.children[i].style.width = ((1 / this.state.cropWidth * 6) * this.state.parentDivSizeX * 100) * (ratio) + 'px';
        }
      } else {
        if (this.state.xCounter > 1) {
          //Only x is bigger than 1
          if (this.state.cropHeight > this.state.cropWidth) {
            ratio = this.state.cropWidth / this.state.cropHeight;
            mønsterOpdelingA4.children[i].style.height = ((1 / this.state.cropWidth * 6) * this.state.parentDivSizeX * 100) * 1.41 + 'px';
            mønsterOpdelingA4.children[i].style.width = ((1 / this.state.cropWidth * 6) * this.state.parentDivSizeX * 100) * (ratio) + 'px';
          } else {
            mønsterOpdelingA4.children[i].style.height = ((1 / this.state.cropWidth * 6) * this.state.parentDivSizeX * 100) * 1.41 + 'px';
            mønsterOpdelingA4.children[i].style.width = ((1 / this.state.cropWidth * 6) * this.state.parentDivSizeX * 100) + 'px';
          }
        } else {
          //None are bigger than one
          mønsterOpdelingA4.children[i].style.height = 800 + 'px';
          mønsterOpdelingA4.children[i].style.width = 600 + 'px';
        }
      }


    }
  }

  handleToggleDrawGridSettings = (e) => {
    const centerGridElements = document.querySelectorAll(".drawGuideElementCenter");
    const gridElements = document.querySelectorAll(".drawGuideElement");
    const rulerY = document.getElementById("drawRulerYWrapper");
    const rulerX = document.getElementById("drawRulerXWrapper");

    if(this.state.drawGrid===false) {
      centerGridElements.forEach((el)=> {
        el.style.visibility = 'hidden';
      })
      gridElements.forEach((el)=> {
        el.style.visibility = 'hidden';
      })
      rulerY.style.opacity = '0';
        rulerX.style.opacity = '0';
    } else {
      if(this.state.drawGridSettingCenter===true) {
        centerGridElements.forEach((el)=> {
          el.style.visibility = 'visible';
        })
      } else {
        centerGridElements.forEach((el)=> {
          el.style.visibility = 'hidden';
        })
      }
      if(this.state.drawGridSettingGrid===true) {
        gridElements.forEach((el)=> {
          el.style.visibility = 'visible';
        })
      } else {
        gridElements.forEach((el)=> {
          el.style.visibility = 'hidden';
        })
      }
  
      if(this.state.drawGridSettingRulers===true) {
          rulerY.style.opacity = '1';
          rulerX.style.opacity = '1';
      } else {
        rulerY.style.opacity = '0';
        rulerX.style.opacity = '0';
      }
    }
    

  }


 handleGoBackToAdvancedEdit = () => {
  //console.log('going back!');
  this.setState({
    advancedEdit: true,
    donePage: true,
    PDFPage: false,
    displayStyleEdit: {
      visibility: 'visible',
      position: 'static'
    }
  })
 }

  handleToggleDonePage = (event) => {
    //console.log(dmcSymbols.length);
    var doneP = this.state.donePage;
    var advancedEdit = this.state.advancedEdit;
    if(advancedEdit===false) {
      this.createDMCData();
    }
    
    
    let counter = 0;
    let parentDivStateArray = [];
    let xCounterLocal = 0;
    let yCounterLocal = 0;

    
    
    if ((this.state.cropWidth > this.state.parentDivSizeX) || (this.state.cropHeight > this.state.parentDivSizeY)) {

      for (let y = 0; y < Math.ceil(this.state.cropHeight / this.state.parentDivSizeY); y++) {
        for (let x = 0; x < Math.ceil(this.state.cropWidth / this.state.parentDivSizeX); x++) {
          var mockObject = {};
          mockObject["x"] = x;
          mockObject["y"] = y;
          parentDivStateArray.push(mockObject);
          if (y === 0) {
            xCounterLocal++;
          }
          if (x === 0) {
            yCounterLocal++;
          }
          counter++
        }
      }

    }
    else {
      var mockObject = {};
      mockObject["x"] = 0;
      mockObject["y"] = 0;
      parentDivStateArray.push(mockObject);
      xCounterLocal = 1;
      yCounterLocal = 1;
      counter = 1;
    }
    this.setState({ numberOfPages: counter, a4PagesArray: parentDivStateArray, xCounter: xCounterLocal, yCounter: yCounterLocal});

    // var palFinalCan = document.getElementById('pal_final');
    // var can = document.getElementById('doneCanvas');
    // var ctx2 = palFinalCan.getContext("2d");
    // var imgd = ctx2.getImageData(0,0,600,600);
    // if(can!=null) {
    //   var ctx = can.getContext('2d');
    //   ctx.putImageData(imgd,0,0);
    // }
    //console.log(DMCJSONColors[1]['Floss Name']);
    this.setState({
      donePage: !doneP,
      PDFPage: true
    }, () => {
      if (doneP === false) {
        window.addEventListener('beforeunload', this.handleDontCloseWindow);
        this.handleCloseAdvancedEdit();
        this.setState({
          wasInAdvancedEdit: false,
          displayStyleEdit: {
            visibility: 'visible',
            position: 'static'
          }
        })
      } else {
        
        this.setState({
          advancedEdit: false,
          drawGrid: true,
          selectionBoxCopyData: null,
          drawGridSettingCenter: true,
          drawGridSettingGrid: true,
          drawGridSettingRulers: true,
          displayStyleEdit: {
            visibility: 'hidden',
            position: 'fixed'
          }
        }, (e) => {
          this.handleCreatePreviewImage(e);
        })
      }
    });
  }


  createHeaders = (keys) => {
    return keys.map(key => ({
      'name': key,
      'prompt': key,
      'width': 65,
      'align': 'center',
      'padding': 0
    }));
  }
  handlePrintPattern = (e) => {
    window.print();
  }

  handleCheckBoughtPatterns = (e) => {
    if(this.state.isASharedPattern===true && this.state.isSubscribedCheck===false) {
      this.handleOpenAreYouSureUseTicket(e);
    } else {
      if(this.state.madeChangeSavedPattern===true && this.state.isSubscribedCheck===false) {
        this.handleOpenAreYouSureUseTicket(e);
      } 
    else if(this.state.isSubscribedCheck===false && this.state.openingOldPattern===false && this.props.freeMode === false) {
      this.handleOpenAreYouSureUseTicket(e);
    } else {
      this.handleTogglePDFPage(e);
    }
  }
  }

  handleTogglePDFPage = (event) => {
    this.handleCloseAreYouSureUseTicket(event);
    document.body.classList = '';
    var pdfP = this.state.PDFPage;
    const container = document.getElementById("pdfDiv");
    // var palFinalCan = document.getElementById('pal_final');
    // var can = document.getElementById('doneCanvas');
    // var ctx2 = palFinalCan.getContext("2d");
    // var imgd = ctx2.getImageData(0,0,600,600);
    // if(can!=null) {
    //   var ctx = can.getContext('2d');
    //   ctx.putImageData(imgd,0,0);
    // }
    //console.log(DMCJSONColors[1]['Floss Name']);
      this.setState({ PDFPage: !pdfP }, () => {
        if (pdfP === false) {
          this.setState({
            displayStylePDFPage: {
              visibility: 'hidden',
              position: 'fixed'
            },
            displayStyleDonePage: {
              visibility: 'visible',
              position: 'static'
            }
          })
        } else {
          if (container.children.length > 0) {
            this.handleCreatePDFPattern(event);
          } else {
            this.setState({ /* loadingCreatePDF: true, */ compressionLoading: true }, (e) => {
              this.handleCreatePDFPattern(e);
            })
          }
        }
      });
  }

  handleOnZoom = (event) => {
    if (event) event.preventDefault()
    if (event.detail.ratio > event.detail.oldRatio) {
      this.cropper.zoom(0.1);
    }
    else {
      this.cropper.zoom(-0.1);
    }

  }
  validateColorInput = (e) => {
    let val = e.target.value;
    this.setState({ quant_colors: val },()=> {
      setTimeout((e)=>{this.cropImage(e)},100);
    });

  }

  validateResizerCentralInput = (e, input) => {
    let inputVal = e.target.value;
    if(inputVal.startsWith("0")) {
      inputVal = "0";
    }
    let error = false;
    let newNum = 0;
    let inputValInt = parseInt(inputVal);

    if(inputValInt<=0 || inputValInt>=999) {
      error = true;
    }
    switch(input) {
      case "width":
        newNum = (this.state.cropWidth-inputValInt)*-1
      this.setState({resizeValueWidth: inputVal, resizeInputWidthError: error, resizeValueRight: newNum});
        break;
      case "height":
        newNum = (this.state.cropHeight-inputValInt)*-1
        this.setState({resizeValueHeight: inputVal, resizeInputHeightError: error, resizeValueBottom: newNum});
        break;
      default:
        break;
    }

  }

  validateResizerInput = (e, input) => {
    let inputVal = e.target.value;
    if(inputVal.startsWith("0")) {
      inputVal = "0";
    }
    let widthNum = this.state.resizeValueWidth;
    let heightNum = this.state.resizeValueHeight;
    let error = false;


    switch(input) {
      case "top":
        heightNum = parseInt(this.state.cropHeight) + parseInt(inputVal) + parseInt(this.state.resizeValueBottom);
        if(heightNum<=0 || inputVal==="") {
          error = true;
        }
        this.setState({resizeValueTop: inputVal, resizeValueHeight: heightNum, resizeInputHeightError: error});
        break;
      case "left":
        widthNum = parseInt(this.state.cropWidth) + parseInt(inputVal) + parseInt(this.state.resizeValueRight);
        if(widthNum<=0 || inputVal==="") {
          error = true;
        }
        this.setState({resizeValueLeft: inputVal, resizeValueWidth: widthNum, resizeInputWidthError: error});
        break;
      case "right":
        widthNum = parseInt(this.state.cropWidth) + parseInt(inputVal) + parseInt(this.state.resizeValueLeft);
        if(widthNum<=0 || inputVal==="") {
          error = true;
        }
        this.setState({resizeValueRight: inputVal, resizeValueWidth: widthNum, resizeInputWidthError: error});
        break;
      case "bottom":
        heightNum = parseInt(this.state.cropHeight) + parseInt(inputVal) + parseInt(this.state.resizeValueTop);
        if(heightNum<=0 || inputVal==="") {
          error = true;
        }
        this.setState({resizeValueBottom: inputVal, resizeValueHeight: heightNum, resizeInputHeightError: error});
        break;
      default:
        break;
    }

  }

  validateWidthInput = (e) => {
    let val = e.target.value;
    let accepted = true;
    let message = this.props.t('create_page_2.result_width_description_1') + this.state.selectMeasuringUnit + this.props.t('create_page_2.result_width_description_2');
    let message2 =  this.props.t('create_page_2.result_width_description_1_small');
    let heightValue = this.state.cropHeight;
    let showE = false;
    this.setState({ widthInput: val });

    let korsstingValue;

    switch (this.state.selectMeasuringUnit) {
      case this.props.t('common.unit_1_small'):
        korsstingValue = val;
        break;
      case this.props.t('common.unit_2_small'):
        korsstingValue = Math.round(val * this.state.selectBroderistof);
        break;
        case this.props.t('common.unit_3_small'):
          korsstingValue = Math.round(widthInput.value * (this.state.selectBroderistof*2.54))
          break;
      default:
        korsstingValue = val;
        break;
    }


    if (this.state.freeAspect === true) {
      let data = this.cropper.getData(true);
      let tempRatio = 1;

      if (data['width'] < data['height']) {
        tempRatio = data['height'] / data['width'];
        heightValue = Math.round(korsstingValue * tempRatio);
      } else if (data['width'] > data['height']) {
        tempRatio = data['width'] / data['height'];
        heightValue = Math.round(korsstingValue * (1 / tempRatio));
      } else {
        heightValue = korsstingValue;
      }

      if (heightValue <= 0) {
        heightValue = 1
      }

    }
    else if (this.state.cropAspect === '11') {
      heightValue = korsstingValue
    }
    if(this.props.freeMode === true && val * heightValue > this.state.freeModeMax) {
      accepted = false
      message = this.props.t('create_page_2.width_error_4');
      message2 = this.props.t('create_page_2.width_error_4');
    }
    if (Math.round(korsstingValue) < 10) {
      accepted = false
      message = this.props.t('create_page_2.width_error_1');
      message2 = this.props.t('create_page_2.width_error_1');
    } else if (korsstingValue * heightValue > this.state.maxStitchCount) {
      if(this.props.freeMode === false && this.props.settings.isUncapped===false) {
        accepted = false
        showE = true;
        message = this.props.t('create_page_2.width_error_2');
        message2 = this.props.t('create_page_2.width_error_2');
      }

    }
    const pattern = /^[0-9]+$/;
    if (!pattern.test(val) && this.state.selectMeasuringUnit!== t('common.unit_3_small')) {
      accepted = false;
      message = this.props.t('create_page_2.width_error_3');
      message2 = this.props.t('create_page_2.width_error_3');
    }



    if (accepted) {
      this.setState({ madeChange: true, showError: false, widthInputError: false, widthInputErrorText: message, widthInputErrorTextSmall: message2 }, (e) => { this.cropImage(e); });
    }
    else {
      this.setState({ madeChange: true, showError: showE, widthInputError: true, widthInputErrorText: message, widthInputErrorTextSmall: message2 });
    }
  }

  validateWidthInputNoTarget = () => {
    let val;
    let accepted = true;
    let showE = false;
    let message = this.props.t('create_page_2.result_width_description_1') + this.state.selectMeasuringUnit + this.props.t('create_page_2.result_width_description_2');
    let message2 = this.props.t('create_page_2.result_width_description_1_small');
    let heightValue = this.state.cropHeight;
    switch (this.state.selectMeasuringUnit) {
      case this.props.t('common.unit_1_small'):
        val = document.getElementById("widthInput").value;
        break;
      case this.props.t('common.unit_2_small'):
        val = Math.round(document.getElementById("widthInput").value * this.state.selectBroderistof);
        break;
        case this.props.t('common.unit_3_small'):
          val = Math.round(document.getElementById("widthInput").value * (this.state.selectBroderistof*2.54))
          break;
      default:
        val = document.getElementById("widthInput").value;
        break;
    }
    if (this.state.freeAspect === true) {
      let data = this.cropper.getData(true);
      let tempRatio = 1;

      if (data['width'] < data['height']) {
        tempRatio = data['height'] / data['width'];
        heightValue = Math.round(val * tempRatio);
      } else if (data['width'] > data['height']) {
        tempRatio = data['width'] / data['height'];
        heightValue = Math.round(val * (1 / tempRatio));
      } else {
        heightValue = val;
      }

      if (heightValue <= 0) {
        heightValue = 1
      }

    }
    else if (this.state.cropAspect === '11') {
      heightValue = val
    }
    if (this.state.selectMeasuringUnit === this.props.t('common.unit_2_small')) {
      heightValue = heightValue * this.state.selectBroderistof;
    }
    if(this.props.freeMode === true && val * heightValue > this.state.freeModeMax) {
      accepted = false
      message = this.props.t('create_page_2.width_error_4');
      message2 = this.props.t('create_page_2.width_error_4');
    }
    if (Math.round(val) < 10) {
      accepted = false
      message = this.props.t('create_page_2.width_error_1');
      message2 = this.props.t('create_page_2.width_error_1');
    } else if (val * heightValue > this.state.maxStitchCount) {
      if (this.props.freeMode === false && this.props.settings.isUncapped===false) {
        accepted = false
        showE = true;
        message = this.props.t('create_page_2.width_error_2');
        message2 = this.props.t('create_page_2.width_error_2');
      }

    }
    const pattern = /^[0-9]+$/;
    if (!pattern.test(val) && this.state.selectMeasuringUnit!== t('common.unit_3_small')) {
      accepted = false;
      message = this.props.t('create_page_2.width_error_3');
      message2 = this.props.t('create_page_2.width_error_3');
    }



    if (accepted) {
      this.setState({ showError: false, widthInputError: false, widthInputErrorText: message, widthInputErrorTextSmall: message2 }, (e) => { this.cropImage(e); });
    }
    else {
      this.setState({ showError: showE, widthInputError: true, widthInputErrorText: message, widthInputErrorTextSmall: message2 });
    }
  }

  makeRows = (rows, cols, changePrint=false) => {

    const container = document.getElementById("pdfDiv");
    if (container.children.length < 1) {
      var can = null;
      if(this.state.savedPatternMode===false) {
        can = document.getElementById("pal_final");
      } else {
        can = document.getElementById("previewCanvasPattern");
      }
      if (can !== null) {
        var ctx = can.getContext('2d');
        let xCounter = 0;
        let yCounter = 0;

        let parentDivArray = [];
        //let a4PageArray = [];
        if ((rows > this.state.parentDivSizeX) || (cols > this.state.parentDivSizeY)) {
          for (let y = 0; y < Math.ceil(cols / this.state.parentDivSizeY); y++) {
            for (let x = 0; x < Math.ceil(rows / this.state.parentDivSizeX); x++) {
              let a4PageContainer = document.createElement("div");
              let partContainer = document.createElement("div");
              let sizeRegulatorDiv = document.createElement("div");
              var mockObject = {};
              mockObject["x"] = x;
              mockObject["y"] = y;
              mockObject["element"] = partContainer;
              mockObject["a4Element"] = a4PageContainer;
              mockObject["sizeRegulatorElement"] = sizeRegulatorDiv;
              mockObject["xFrom"] = this.state.parentDivSizeX * x;
              if (x === Math.ceil(rows / this.state.parentDivSizeX) - 1) {
                mockObject["xTo"] = rows * 1;
              }
              else {
                mockObject["xTo"] = this.state.parentDivSizeX * x + this.state.parentDivSizeX;
              }
              mockObject["yFrom"] = this.state.parentDivSizeY * y;
              if (y === Math.ceil(cols / this.state.parentDivSizeY) - 1) {
                mockObject["yTo"] = cols * 1;
              }
              else {
                mockObject["yTo"] = this.state.parentDivSizeY * y + this.state.parentDivSizeY;
              }
              parentDivArray.push(mockObject);

              //a4PageArray.push(a4PageContainer);
              if (y === 0) {
                xCounter++;
              }
              if (x === 0) {
                yCounter++;
              }
            }
          }

        }
        else {
          let a4PageContainer = document.createElement("div");
          let partContainer = document.createElement("div");
          let sizeRegulatorDiv = document.createElement("div");
          var mockObject = {};
          mockObject["x"] = 0;
          mockObject["y"] = 0;
          mockObject["element"] = partContainer;
          mockObject["xFrom"] = 0;
          mockObject["xTo"] = rows * 1;
          mockObject["yFrom"] = 0;
          mockObject["yTo"] = cols * 1;
          mockObject["a4Element"] = a4PageContainer;
          mockObject["sizeRegulatorElement"] = sizeRegulatorDiv;
          parentDivArray.push(mockObject);
          //a4PageArray.push(a4PageContainer);
        }
        //console.log(parentDivArray);


        /* The Big ONE!! */
       // this.placeElements(rows, cols, parentDivArray, 1, ctx, container, yCounter, xCounter, 0, 0);
         //Load material icons font to be used in canvas
  const material_font = new FontFace( 'material-icons',
 // pass the url to the file in CSS url() notation
 'url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)' );
 document.fonts.add( material_font ); // add it to the document's FontFaceSet
 // wait the font loads
 material_font.load().then( () => {
        this.placeElementsNewMethod(rows,cols,ctx,parentDivArray,container, changePrint);
      }).catch( console.error ); 


      }
    } else {
      this.setState({
        loadingCreatePDF: false,
        compressionLoading: false,
        displayStylePDFPage: {
          visibility: 'visible',
          position: 'static'
        },
        displayStyleDonePage: {
          visibility: 'hidden',
          position: 'fixed'
        }
      }, (e) => {
        window.scrollTo(0, 0);
        document.body.classList.add('overflowHidden');
        if(this.props.freeMode === true)  {
          this.handleOpenUpgrade(e);
        }
        //setTimeout(()=> { window.print(); },500);
      });


    }

    //this.handleDownloadPDF();
  }

  pickTextColorBasedOnBgColorAdvanced = (bgColor, lightColor, darkColor) => {
    var hexBgColor = rgbHex(bgColor[0],bgColor[1],bgColor[2]);
    var color = (hexBgColor.charAt(0) === '#') ? hexBgColor.substring(1, 7) : hexBgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
  }


  placeElementsNewMethod = (xWidth, yHeight, ctx, parentDivArray, container, changePrint=false) => {

    let pdfTableLength = 60;
    const tempRowsMultiple = [];
    let isMultiple = false;
    const canvasPadding = 50;
    
    for(let i = 0; i<parentDivArray.length; i++) {


/*     let yFrom = parentDivArray[i]["yFrom"] + Math.round(((parentDivArray[i]["yTo"] - parentDivArray[i]["yFrom"])) /** (num - 1));
    let yTo = Math.round(((parentDivArray[i]["yTo"] - parentDivArray[i]["yFrom"])) *  num) + parentDivArray[i]["yFrom"];
    let xFrom = parentDivArray[i]["xFrom"];
    let xTo = parentDivArray[i]["xTo"]; */
    let yFrom = parentDivArray[i]["yFrom"];
    let yTo = parentDivArray[i]["yTo"];
    let xFrom = parentDivArray[i]["xFrom"];
    let xTo = parentDivArray[i]["xTo"]; 
 //Canvas implementation!!
 const canvas = document.createElement("canvas");
 
 canvas.width = (xTo-xFrom)*16+(canvasPadding*2);
 canvas.height = (yTo-yFrom)*16+(canvasPadding*2);
 
 //Append the canvas!
 container.appendChild(parentDivArray[i]["a4Element"]).classList.add("a4Page");
 parentDivArray[i]["a4Element"].appendChild(parentDivArray[i]["sizeRegulatorElement"]).className = "sizeRegulatorDivA4";
 parentDivArray[i]["sizeRegulatorElement"].appendChild(parentDivArray[i]["element"]).className = "parentDiv";
 parentDivArray[i]["element"].appendChild(canvas).id = "canvasPDF"+i;
 

 const canvasContext = parentDivArray[i]["element"].children[0].getContext("2d");


// we're good to use it
//Add the font the right places!
for (let y = yFrom; y < yTo; y++) {
 for (let x = xFrom; x < xTo; x++) {
   
         //Fetch which symbol to use
   var pixelDataIndividual = ctx.getImageData(x, y, 1, 1).data;
     var symbolString = "warning";
     for (let k in this.state.dmcArray) {
       if(pixelDataIndividual[3]!==255) {
         symbolString = "none";
       } else {
         if (pixelDataIndividual[0] === this.state.dmcArray[k]["red"] && pixelDataIndividual[1] === this.state.dmcArray[k]["green"] && pixelDataIndividual[2] === this.state.dmcArray[k]["blue"]) {
           symbolString = this.state.dmcArray[k]["symbol"];
         }
       }
       
     }

     //Place symbols and paint cell backgrounds!
     if(this.props.freeMode === true) {
      if(symbolString!=="none") {
      canvasContext.fillStyle = 'rgb('+pixelDataIndividual[0]+','+pixelDataIndividual[1]+','+pixelDataIndividual[2]+')';
      canvasContext.fillRect((x-xFrom)*16+canvasPadding,(y-yFrom)*16+canvasPadding,16,16);
      }
    } else {
      switch(this.props.settings.printSettings.colorPrint) {
        case "onlySymbols":
          canvasContext.fillStyle = 'black';
          canvasContext.font = '13px material-icons';
          canvasContext.fillText(symbolString, (x-xFrom)*16+canvasPadding+2, (y-yFrom+1)*16+canvasPadding-1);
          break;
        case "symbolsAndColor":
          var bgColor = [pixelDataIndividual[0],pixelDataIndividual[1],pixelDataIndividual[2]];
          if(symbolString!=="none") {
            canvasContext.fillStyle = 'rgb('+pixelDataIndividual[0]+','+pixelDataIndividual[1]+','+pixelDataIndividual[2]+')';
            canvasContext.fillRect((x-xFrom)*16+canvasPadding,(y-yFrom)*16+canvasPadding,16,16);
          }
          var color = this.pickTextColorBasedOnBgColorAdvanced(bgColor,'white','black');
          canvasContext.fillStyle = color;
          canvasContext.font = '13px material-icons';
          canvasContext.fillText(symbolString, (x-xFrom)*16+canvasPadding+2, (y-yFrom+1)*16+canvasPadding-1);
          break;
        case "onlyColor":
          if(symbolString!=="none") {
            canvasContext.fillStyle = 'rgb('+pixelDataIndividual[0]+','+pixelDataIndividual[1]+','+pixelDataIndividual[2]+')';
            canvasContext.fillRect((x-xFrom)*16+canvasPadding,(y-yFrom)*16+canvasPadding,16,16);
          }
          break;
        default:
          break;
      }
    }

         //End labels

         //Bottom and Right point
         if (y === yHeight - 1 && x === xWidth - 1) {
          canvasContext.fillStyle = 'black';
          canvasContext.font = "bold 18px arial";
          canvasContext.textAlign = "center";
          canvasContext.fillText(x+1, ((x+1)-xFrom)*16+canvasPadding, (y-yFrom)*16+(canvasPadding*2)-10);
          canvasContext.textAlign = "left";
          
          
        }

        //Every 10 bottom
        if (y === yHeight - 1 && x % 10 === 0 && x !== 0) {
          canvasContext.fillStyle = 'black';
          canvasContext.font = "bold 18px arial";
          canvasContext.textAlign = "center";
          canvasContext.fillText(x, (x-xFrom)*16+canvasPadding, (y-yFrom)*16+(canvasPadding*2)-10);
          canvasContext.textAlign = "left";

        }

    //Every 10 right
    if (x === xWidth - 1 && y % 10 === 0 && y !== 0) {
      canvasContext.fillStyle = 'black';
      canvasContext.font = "bold 18px arial";
      canvasContext.textAlign = "left";
      canvasContext.fillText(y, (x-xFrom)*16+(canvasPadding*2)-26, (y-yFrom)*16+canvasPadding+6);
    }

 }

}


//Lines and text!
//Y
for (let y = yFrom; y < yTo; y++) {
  //Place lines Y and make them 2 thick for every 10
  canvasContext.fillStyle = 'black';
  if((y-yFrom) % 10 ===0 && y!==0) {
  if(xFrom===0 && y !== Math.round(yHeight / 2)) {
   canvasContext.font = "bold 18px arial";
   canvasContext.textAlign = "right";
   canvasContext.fillText(y, 40, (y-yFrom)*16+canvasPadding+6);
   canvasContext.textAlign = "left";
  }
   canvasContext.fillRect(canvasPadding,(y-yFrom)*16+canvasPadding,(xTo-xFrom)*16,2);
 } else {
   
   canvasContext.fillRect(canvasPadding,(y-yFrom)*16+canvasPadding,(xTo-xFrom)*16,1);
 }

  //At middle Y
 
  if (y === Math.round(yHeight / 2)) {
    if(xFrom===0) {
      canvasContext.fillStyle = 'black';
      canvasContext.beginPath();
      canvasContext.moveTo(23, (y-yFrom)*16+canvasPadding+1-15);
      canvasContext.lineTo(48,(y-yFrom)*16+canvasPadding+1);
      canvasContext.lineTo(23,(y-yFrom)*16+canvasPadding+1+15);
      canvasContext.fill();
      if((y-yFrom) % 10 ===0 && y!==0) {
        canvasContext.font = "bold 18px arial";
        canvasContext.textAlign = "right";
        canvasContext.fillText(y, 20, (y-yFrom)*16+canvasPadding+6);
        canvasContext.textAlign = "left";
      }
    }
    canvasContext.fillStyle = 'red';
    canvasContext.fillRect(canvasPadding,(y-yFrom)*16+canvasPadding,(xTo-xFrom)*16,3);
    
  }

          //Bottom and Left point
          if (y === yHeight - 1 && xFrom === 0) {
            canvasContext.fillStyle = 'black';
            canvasContext.font = "bold 18px arial";
            canvasContext.textAlign = "right";
            canvasContext.fillText(y+1, 40, ((y-yFrom)+1)*16+canvasPadding+6);
            canvasContext.textAlign = "left";
          }
 
}


//X
for (let x = xFrom; x < xTo; x++) {
     //Place lines X and make them 2 thick for every 10
   if( (x-xFrom) % 10 === 0 && x!==0) {
    canvasContext.fillStyle = 'black';
    if((yFrom)===0 && x !== Math.round(xWidth / 2)) {
     canvasContext.font = "bold 18px arial";
     canvasContext.textAlign = "center";
     canvasContext.fillText(x, (x-xFrom)*16+canvasPadding, 36);
     canvasContext.textAlign = "left";
    }
     canvasContext.fillRect((x-xFrom)*16+canvasPadding,canvasPadding,2,(yTo-yFrom)*16);
    
   } else {
    canvasContext.fillStyle = 'black';
      canvasContext.fillRect((x-xFrom)*16+canvasPadding,canvasPadding,1,(yTo-yFrom)*16);
   }
  

             //At middle X
             if (x === Math.round(xWidth / 2)) {
              if((yFrom)===0) {
                canvasContext.fillStyle = 'black';
                canvasContext.beginPath();
                canvasContext.moveTo((x-xFrom)*16-15+canvasPadding+1, 23);
                canvasContext.lineTo((x-xFrom)*16+canvasPadding+1, 48);
                canvasContext.lineTo((x-xFrom)*16+15+canvasPadding+1,23);
                canvasContext.fill();
                if((x-xFrom) % 10 === 0 && x!==0) {
                  canvasContext.font = "bold 18px arial";
                  canvasContext.textAlign = "center";
                  canvasContext.fillText(x, (x-xFrom)*16+canvasPadding, 16);
                  canvasContext.textAlign = "left";
                }
              }
              canvasContext.fillStyle = 'red';
              canvasContext.fillRect((x-xFrom)*16+canvasPadding,canvasPadding,3,(yTo-yFrom)*16);
              
            }

                    //Top and Right point
        if ( x === xWidth - 1 && yFrom===0) {
          canvasContext.fillStyle = 'black';
          canvasContext.font = "bold 18px arial";
          canvasContext.textAlign = "center";
          canvasContext.fillText(x+1, ((x+1)-xFrom)*16+canvasPadding, 36);
          canvasContext.textAlign = "left";
        }
}

  //Create canvas border
  canvasContext.strokeStyle = 'black';
  canvasContext.lineWidth = 2;
  canvasContext.strokeRect(canvasPadding, canvasPadding, canvas.width-(canvasPadding*2), canvas.height-(canvasPadding*2));

  //Make sure the final part of the pattern is always the last page!
  if(i===parentDivArray.length-1) {
    console.log(parentDivArray[i]["a4Element"].style);
    parentDivArray[i]["a4Element"].setAttribute('style', 'page-break-after:avoid!important');
  }
  
    }

if (parentDivArray.length > 1) {
/*   for (let i = 0; i < parentDivArray.length; i++) {
    //Bottom row
    if (parentDivArray[i]["y"] === this.clamp(yCounter, 1, 99999) - 1 && parentDivArray[i]["y"] !== 0 && xCounter === yCounter) {
      //Right bottom (The last one)
      if (parentDivArray[i]["x"] === parentDivArray[i]["y"]) {
        parentDivArray[i]["element"].style.setProperty('--grid-cols', rows - (this.state.parentDivSizeX * (xCounter - 1)));
      }
      //Bottom 
      else {
        parentDivArray[i]["element"].style.setProperty('--grid-cols', this.state.parentDivSizeX);
      }
    }
    //Right row
    else if (parentDivArray[i]["x"] === this.clamp(xCounter, 1, 99999) - 1) {
      parentDivArray[i]["element"].style.setProperty('--grid-cols', rows - (this.state.parentDivSizeX * (xCounter - 1)));
    }
    else {
      parentDivArray[i]["element"].style.setProperty('--grid-cols', this.state.parentDivSizeX);
    }

  } */
  if (this.state.dmcArray.length <= 170) {
    setTimeout((e) => { this.pdfTableSizeRegulator(e) }, 300);
  }
}
else {
  if (xWidth <= 60) {
    pdfTableLength = 45;
  }
  if (xWidth > 50 && xWidth <= 60) {
    setTimeout((e) => { this.pdfTableSizeRegulator(e) }, 300);
  }
  //parentDivArray[0]["element"].style.setProperty('--grid-cols', xWidth);

}
if(parentDivArray.length===1) {
  parentDivArray[0]["a4Element"].style.pageBreakAfter = 'avoid';
}

if (this.state.dmcArray.length > pdfTableLength) {
  isMultiple = true;
  let tempTemp = [...this.state.dmcArray];
  for (let i = Math.floor(this.state.dmcArray.length / pdfTableLength) + 1; i > 0; i--) {
    tempRowsMultiple.push(tempTemp.splice(0, Math.ceil(tempTemp.length / i)));
  }
}
container.style.height = this.state.cellSize * yHeight + 6 + "px";
if(this.state.savedPatternMode===false && this.props.freeMode === false) {
  this.props.handleResetAllPatterns();
  this.saveImageToGoogleStorageAndFirestore();
}
if(this.state.isASharedPattern===true && this.state.isSubscribedCheck===false) {
  this.checkAndUseBoughtPatterns(this.context.user);
} else {
  if(this.state.madeChangeSavedPattern===true && this.state.isSubscribedCheck===false) {
    this.checkAndUseBoughtPatterns(this.context.user);
  } 
else if(this.state.isSubscribedCheck===false && this.state.openingOldPattern===false && this.props.freeMode === false) {
  this.checkAndUseBoughtPatterns(this.context.user);
}
}
if(this.state.savedPatternMode===true && this.state.madeChangeSavedPattern===true && this.state.undo!==0) {
  this.props.handleResetAllPatterns();
  this.saveImageToGoogleStorageAndFirestore();
  this.setState({
    madeChangeSavedPattern: false
  })
}
this.setState({
  openingOldPattern: true,
  dmcSymbolTableIsMultiple: isMultiple,
  dmcSymbolTable: tempRowsMultiple,
  loadingCreatePDF: false,
  compressionLoading: false,
  displayStylePDFPage: {
    visibility: 'visible',
    position: 'static'
  },
  displayStyleDonePage: {
    visibility: 'hidden',
    position: 'fixed'
  }
}, (e) => {
  if(changePrint===false) {
    window.scrollTo(0, 0);
    this.handleCreatePDFImage(e);
    document.body.classList.add('overflowHidden');
  }
  if(this.props.freeMode === true) {
      this.handleOpenUpgrade(e);
  }
  
  let doneicon = document.getElementById('doneIcon');
  doneicon.classList.add('doneIcon');
  //setTimeout(()=> { window.print(); },100);
  window.removeEventListener('beforeunload', this.handleDontCloseWindow);
});
  }


  placeElements = (rows, cols, parentDivArray, num, ctx, container, yCnt, xCnt, num2, num3) => {

    const fractions = 8;
    if (parentDivArray.length === 1) {
      this.setState({ createPDFProgress: Math.round((num3 / fractions) * 100) });
    }
    else {
      this.setState({ createPDFProgress: Math.round(num3 / ((parentDivArray.length) * fractions) * 100) });
    }
    const tempRowsMultiple = [];
    let isMultiple = false;
    let pdfTableLength = 60;
    let yCounter = yCnt;
    let xCounter = xCnt;
    //console.log("xCounter: "+xCounter);
    //let parentDivCounterX = 0;
    let yFrom = parentDivArray[num2]["yFrom"] + Math.round(((parentDivArray[num2]["yTo"] - parentDivArray[num2]["yFrom"]) / fractions) * (num - 1));
    let yTo = Math.round(((parentDivArray[num2]["yTo"] - parentDivArray[num2]["yFrom"]) / fractions) * num) + parentDivArray[num2]["yFrom"];
    let xFrom = parentDivArray[num2]["xFrom"];
    let xTo = parentDivArray[num2]["xTo"];

   

    for (let y = yFrom; y < yTo; y++) {
      for (let x = xFrom; x < xTo; x++) {
        let cell = document.createElement("div");
        let label = document.createElement("p");
        let pointer = document.createElement("i");
        let symbol = document.createElement("i");
        let labelChild = null;
        let symbolPlacement = 0;

        // for(let k = 0; k<parentDivArray.length; k++) {
        //   if(parentDivArray[k]["x"] === Math.floor(x/parentDivSizeX) && parentDivArray[k]["y"] === Math.floor(y/parentDivSizeY) ) {
        //     parentDivCounterX= k;
        //   }
        // }
        if (y % 10 === 0 && y !== 0 && x !== 0) {
          symbolPlacement = "symbolHorEvery10Line";
        }
        /*---Labels---*/
        //Every 10 top
        if (y === 0 && x % 10 === 0 && x !== 0) {
          label.innerText = x;
          labelChild = cell.appendChild(label).className = "pdfStitchLabelTop";
          symbolPlacement = 1;
          if (x === Math.round(rows / 2)) {
            label.setAttribute("id", "pointerLabelTop");
          }
        }

        //Every 10 bottom
        if (y === cols - 1 && x % 10 === 0 && x !== 0) {
          label.innerText = x;
          labelChild = cell.appendChild(label).className = "pdfStitchLabelBottom";
          symbolPlacement = 2;

        }
        //End labels

        //Bottom and Right point
        if (y === cols - 1 && x === rows - 1) {
          label.innerText = rows;

          labelChild = cell.appendChild(label).className = "pdfStitchLabelEndBottom";
          symbolPlacement = 2;
        }

        //Top and Right point
        if (y === 0 && x === rows - 1) {
          label.innerText = rows;

          labelChild = cell.appendChild(label).className = "pdfStitchLabelEndTop";
          symbolPlacement = 2;
        }

        //Bottom and Left point
        if (y === cols - 1 && x === 0) {
          label.innerText = cols;

          labelChild = cell.appendChild(label).className = "pdfStitchLabelEndLeft";
          symbolPlacement = 2;
        }

        //Every 10 left
        if (x === 0 && y % 10 === 0 && y !== 0) {
          label.innerText = y;
          labelChild = cell.appendChild(label).className = "pdfStitchLabelLeft";
          symbolPlacement = "every10Left";
          if (y === Math.round(cols / 2)) {
            label.setAttribute("id", "pointerLabelLeft");
          }
        }

        //Every 10 right
        if (x === rows - 1 && y % 10 === 0 && y !== 0) {
          label.innerText = y;
          labelChild = cell.appendChild(label).className = "pdfStitchLabelRight";
          symbolPlacement = "every10Right";
        }

        /*---Pointers---*/
        //At middle top horizontal
        if (x === Math.round(rows / 2) && y === 0) {

          cell.appendChild(pointer).className = "material-icons";
          if (labelChild != null) {
            pointer.setAttribute("id", "pdfPointerTop");
            symbolPlacement = "pointerTopLabel";
          }
          else {
            pointer.setAttribute("id", "pdfPointerTopNoLabel");
            symbolPlacement = "pointerTopNoLabel";
          }
          pointer.innerText = "play_arrow";
        }
        //At middle left vertical

        if (y === Math.round(cols / 2) && x === 0) {

          cell.appendChild(pointer).className = "material-icons";
          if (labelChild != null) {
            pointer.setAttribute("id", "pdfPointerLeft");
            symbolPlacement = "pointerLeftLabel";
          }
          else {
            pointer.setAttribute("id", "pdfPointerLeftNoLabel");
            symbolPlacement = "pointerLeftNoLabel";
          }

          pointer.innerText = "play_arrow";
        }


        var pixelDataIndividual = ctx.getImageData(x, y, 1, 1).data;
        var symbolString = "warning";
        for (let k in this.state.dmcArray) {
          if(pixelDataIndividual[3]!==255) {
            symbolString = "none";
          } else {
            if (pixelDataIndividual[0] === this.state.dmcArray[k]["red"] && pixelDataIndividual[1] === this.state.dmcArray[k]["green"] && pixelDataIndividual[2] === this.state.dmcArray[k]["blue"]) {
              symbolString = this.state.dmcArray[k]["symbol"];
            }
          }
          
        }
        
        symbol.style.fontSize = '13px';
        symbol.style.position = 'relative';
        symbol.style.top = '-3px';
        symbol.style.color = 'black';
        //symbol.setAttribute('style','color: '+this.props.theme.palette.mode === 'dark' ? 'white': 'black');
        switch (symbolPlacement) {
          case 1:
            symbol.style.top = "-60px";
            break;
          case 2:
            symbol.style.top = '-60px';
            break;
          case "pointerTopLabel":
            symbol.style.top = "-117px";
            break;
          case "pointerTopNoLabel":
            symbol.style.top = '-62px';
            break;
          case "pointerLeftNoLabel":
            symbol.style.top = '-62px';
            break;
          case "pointerLeftLabel":
            symbol.style.top = '-118px';
            break;
          case "symbolHorEvery10Line":
            symbol.style.top = '-5px';
            break;
          case "every10Left":
            symbol.style.top = '-61px';
            break;
          case "every10Right":
            symbol.style.top = '-61px';
            break;
        }
        if (symbolString === "warning") {
          symbol.style.color = "red";
        }
        symbol.innerHTML = symbolString;
        if (this.props.freeMode === false) {
          cell.appendChild(symbol).className = "material-icons";
        }
        
        //Append the cell!
         container.appendChild(parentDivArray[num2]["a4Element"]).classList.add("a4Page");
        parentDivArray[num2]["a4Element"].appendChild(parentDivArray[num2]["sizeRegulatorElement"]).className = "sizeRegulatorDivA4";
        parentDivArray[num2]["sizeRegulatorElement"].appendChild(parentDivArray[num2]["element"]).className = "parentDiv";
        parentDivArray[num2]["element"].appendChild(cell).className = "grid-item";  

        var bgColor = [pixelDataIndividual[0],pixelDataIndividual[1],pixelDataIndividual[2]];
        switch(this.props.settings.printSettings.colorPrint) {
          case "onlySymbols":
            cell.style.backgroundColor = "white";
            symbol.style.color = "black";
            break;
          case "symbolsAndColor":
            cell.style.backgroundColor = 'rgb('+pixelDataIndividual[0]+','+pixelDataIndividual[1]+','+pixelDataIndividual[2]+')';
            var color = this.pickTextColorBasedOnBgColorAdvanced(bgColor,'white','black');
            symbol.style.color = color;
            break;
          case "onlyColor":
            cell.style.backgroundColor = 'rgb('+pixelDataIndividual[0]+','+pixelDataIndividual[1]+','+pixelDataIndividual[2]+')';
            symbol.style.color = 'rgb('+pixelDataIndividual[0]+','+pixelDataIndividual[1]+','+pixelDataIndividual[2]+')';
            break;
          default:
            cell.style.backgroundColor = "white";
            symbol.style.color = "black";
            break;
        }

        if(this.props.freeMode === true) {
          cell.style.backgroundColor = 'rgb('+pixelDataIndividual[0]+','+pixelDataIndividual[1]+','+pixelDataIndividual[2]+')';
        }
        cell.setAttribute("data-id",'rgb('+pixelDataIndividual[0]+','+pixelDataIndividual[1]+','+pixelDataIndividual[2]+')');
        if (x % 10 === 0 && x !== 0) {
          cell.style.borderLeft = "1px solid black";
        }

        if (y % 10 === 0 && y !== 0) {
          cell.style.borderTop = "1px solid black";
        }

        if (x === Math.round(rows / 2) - 1) {
          cell.style.borderRight = "1px solid red";
        }
        if (x === Math.round(rows / 2)) {
          cell.style.borderLeft = "1px solid red";
        }

        if (y === Math.round(cols / 2) - 1) {
          cell.style.borderBottom = "1px solid red";
        }

        if (y === Math.round(cols / 2)) {
          cell.style.borderTop = "1px solid red";
        }
      }
    }
    ///RECURSIVE CALL!
    if (num2 < parentDivArray.length - 1) {
      if (num % fractions === 0 && num !== 0) {
        num = 0;
        setTimeout(() => {
          this.placeElements(rows, cols, parentDivArray, num + 1, ctx, container, yCounter, xCounter, num2 + 1, num3 + 1);
        });
      }
      else {
        setTimeout(() => {
          this.placeElements(rows, cols, parentDivArray, num + 1, ctx, container, yCounter, xCounter, num2, num3 + 1);
        });
      }
    } else if (num % fractions === 0) {
      if (parentDivArray.length > 1) {
        for (let i = 0; i < parentDivArray.length; i++) {
          //Bottom row
          if (parentDivArray[i]["y"] === this.clamp(yCounter, 1, 99999) - 1 && parentDivArray[i]["y"] !== 0 && xCounter === yCounter) {
            //Right bottom (The last one)
            if (parentDivArray[i]["x"] === parentDivArray[i]["y"]) {
              parentDivArray[i]["element"].style.setProperty('--grid-cols', rows - (this.state.parentDivSizeX * (xCounter - 1)));
            }
            //Bottom 
            else {
              parentDivArray[i]["element"].style.setProperty('--grid-cols', this.state.parentDivSizeX);
            }
            //console.log("bottom");
          }
          //Right row
          else if (parentDivArray[i]["x"] === this.clamp(xCounter, 1, 99999) - 1) {
            parentDivArray[i]["element"].style.setProperty('--grid-cols', rows - (this.state.parentDivSizeX * (xCounter - 1)));
            //console.log("right");
          }
          else {
            parentDivArray[i]["element"].style.setProperty('--grid-cols', this.state.parentDivSizeX);
            //console.log("its here");
          }

          /*           let heightNeeded = (1406 - parentDivArray[i]["element"].offsetHeight) / 2;
                    parentDivArray[i]["element"].style.marginBottom = heightNeeded + "px";
                    parentDivArray[i]["element"].style.marginTop = heightNeeded + "px"; */

          /*     if(parentDivArray[i]["element"].offsetHeight!==1406 && (parentDivArray[i]["y"]===this.clamp(yCounter,1,99999)-1 || parentDivArray[i]["x"]===this.clamp(xCounter,1,99999)-1)) {
                let heightNeeded = (1406-parentDivArray[i]["element"].offsetHeight)/2;
                parentDivArray[i]["element"].style.marginBottom = heightNeeded+"px";
                parentDivArray[i]["element"].style.marginTop = heightNeeded+"px";
              }
              if(parentDivArray[i]["element"].offsetHeight!==1406 && rows<this.state.parentDivSizeY && rows>this.state.parentDivSizeX) {
                let heightNeeded = (1406-parentDivArray[i]["element"].offsetHeight)/2;
                parentDivArray[i]["element"].style.marginBottom = heightNeeded+"px";
                parentDivArray[i]["element"].style.marginTop = heightNeeded+"px";
              } */


        }
        if (this.state.dmcArray.length <= 170) {
          setTimeout((e) => { this.pdfTableSizeRegulator(e) }, 300);
        }
      }
      else {
        if (rows <= 60) {
          pdfTableLength = 45;
        }
        if (rows > 50 && rows <= 60) {
          setTimeout((e) => { this.pdfTableSizeRegulator(e) }, 300);
        }
        parentDivArray[0]["element"].style.setProperty('--grid-cols', rows);

      }
      if(parentDivArray.length===1) {
        parentDivArray[0]["a4Element"].style.pageBreakAfter = 'avoid';
      }
      //Split the pdf symbol table if above a certain length
      if (this.state.dmcArray.length > pdfTableLength) {
        isMultiple = true;
        let tempTemp = [...this.state.dmcArray];
        for (let i = Math.floor(this.state.dmcArray.length / pdfTableLength) + 1; i > 0; i--) {
          tempRowsMultiple.push(tempTemp.splice(0, Math.ceil(tempTemp.length / i)));
        }
      }
      container.style.height = this.state.cellSize * cols + 6 + "px";
      if(this.state.savedPatternMode===false && this.props.freeMode === false) {
        this.props.handleResetAllPatterns();
        this.saveImageToGoogleStorageAndFirestore();
      }
      if(this.state.isASharedPattern===true && this.state.isSubscribedCheck===false) {
        this.checkAndUseBoughtPatterns(this.context.user);
      } else {
        if(this.state.madeChangeSavedPattern===true && this.state.isSubscribedCheck===false) {
          this.checkAndUseBoughtPatterns(this.context.user);
        } 
      else if(this.state.isSubscribedCheck===false && this.state.openingOldPattern===false && this.props.freeMode === false) {
        this.checkAndUseBoughtPatterns(this.context.user);
      }
    }
      if(this.state.savedPatternMode===true && this.state.madeChangeSavedPattern===true && this.state.undo!==0) {
        this.props.handleResetAllPatterns();
        this.saveImageToGoogleStorageAndFirestore();
        this.setState({
          madeChangeSavedPattern: false
        })
      }
      this.setState({
        openingOldPattern: true,
        dmcSymbolTableIsMultiple: isMultiple,
        dmcSymbolTable: tempRowsMultiple,
        loadingCreatePDF: false,
        compressionLoading: false,
        displayStylePDFPage: {
          visibility: 'visible',
          position: 'static'
        },
        displayStyleDonePage: {
          visibility: 'hidden',
          position: 'fixed'
        }
      }, (e) => {
        window.scrollTo(0, 0);
        this.handleCreatePDFImage(e);
        document.body.classList.add('overflowHidden');
        if(this.props.freeMode === true) {
            this.handleOpenUpgrade(e);
        }
        
        let doneicon = document.getElementById('doneIcon');
        doneicon.classList.add('doneIcon');
        //setTimeout(()=> { window.print(); },100);
        window.removeEventListener('beforeunload', this.handleDontCloseWindow);
      });
    }
    else {
      if (num % fractions === 0 && num !== 0) {
        num = 0;
        setTimeout(() => {
          this.placeElements(rows, cols, parentDivArray, num + 1, ctx, container, yCounter, xCounter, num2 + 1, num3 + 1);
        });
      }
      else {
        setTimeout(() => {
          this.placeElements(rows, cols, parentDivArray, num + 1, ctx, container, yCounter, xCounter, num2, num3 + 1);
        });
      }
    }
  }

  saveImageToGoogleStorageAndFirestore = async () => {
    var can = document.getElementById("pal_final");
    var imageUrl = can.toDataURL();
    this.setState({sharePreviewImg: imageUrl});
    var currentTime = +new Date();
    const storageName = this.context.user.uid+currentTime+'';
    await can.toBlob((blob) => {
      var image = new Image();
      image.src = blob;
      const imageRef = ref(storageRef, storageName);
      uploadBytes(imageRef, blob);
    })
    const docRef = collection(db, "users", this.context.user.uid+'/saved_patterns');
    const date = new Date().toLocaleString() + "";
    const d = new Date();
    let time = d.getTime();
    let totalStitchCount = this.state.cropHeight*this.state.cropWidth;
    if(this.state.wasInAdvancedEdit===true) {
      totalStitchCount = this.state.totalStitches;
    }
    const cmWidth = Math.round((this.state.cropWidth / this.state.selectBroderistof) * 10) / 10;
    const cmHeight = Math.round((this.state.cropHeight / this.state.selectBroderistof) * 10) / 10;
    let jsonPaletteData = JSON.stringify(this.state.dmcArray);
    let shareRef = {
      storageName: storageName,
      name: '',
      created: date,
      totalStitches: totalStitchCount,
      stitchWidth: this.state.cropWidth,
      stitchHeight: this.state.cropHeight,
      fabric: this.state.selectBroderistofName,
      cmWidth: cmWidth,
      cmHeight: cmHeight,
      colorCount: this.state.dmcArray.length,
      a4Pages: this.state.a4PagesArray.length,
      milis: time,
      dmcArray: jsonPaletteData,
      shared: this.state.isASharedPattern
      };

    await addDoc(docRef, {
      name: '',
      storageName: storageName,
      created: date,
      totalStitches: totalStitchCount,
      stitchWidth: this.state.cropWidth,
      stitchHeight: this.state.cropHeight,
      fabric: this.state.selectBroderistofName,
      cmWidth: cmWidth,
      cmHeight: cmHeight,
      colorCount: this.state.dmcArray.length,
      a4Pages: this.state.a4PagesArray.length,
      milis: time,
      dmcArray: jsonPaletteData,
      shared: this.state.isASharedPattern
    })
    this.setState({shareDatabaseRef: shareRef});
  }

  render() {
    const { t } = this.props;
    /* const { showing } = this.state; */
    const { imgSrc } = this.state;
    const { donePage } = this.state;

    //Image drop style
    const baseStyle = {
/*       position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', */
      cursor: 'pointer',
      margin: 'auto',
      justifyContent: 'center',
      display: 'grid',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 4,
      borderColor: 'rgb(203 203 203)',
      borderStyle: 'dashed',
      backgroundColor: this.props.theme.palette.mode === 'dark' ? 'rgb(70 70 70)' : 'rgb(245 245 245)',
      color: 'rgb(149 149 149)',
      outline: 'none',
      transition: 'all .1s',
    }

    const focusedStyle = {
      borderColor: '#2196f3'
    };

    const activeStyle = {
      borderColor: '#2196f3'
    }

    const acceptStyle = {
      // filter: 'brightness(1.25)',
      borderColor: '#00e676'
    }

    const rejectStyle = {
      borderColor: '#ff1744'
    }


    return (
      <div >
        <div className="cursor cRounded" ></div>

        


        <Dialog
              disableScrollLock={true}
              open={this.state.openAreYouSureGoingBackFromAdvancedEdit}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle /* dividers={true} */ sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: '400'}}>{t('common.are_you_sure')}</DialogTitle>
              <DialogContent dividers={true} >
                <Typography gutterBottom variant="h7" align='center' sx={{ float: 'left' }}>{t('advanced.are_you_sure_1')}<br></br>{t('advanced.are_you_sure_2')}</Typography>
              </DialogContent >
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button startIcon={<Close />} onClick={this.handleCloseAreYouSureGoingBackFromAdvancedEdit}>{t('common.no')}</Button>
                <Button startIcon={<Check />} onClick={this.handleCloseAdvancedEdit}>{t('common.yes')}</Button>
              </DialogActions>
            </Dialog>
        <Dialog
                      disableScrollLock={true}
                      className='no-print'
                      open={this.state.showLoadingAdvanced}
                      keepMounted
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <Typography gutterBottom variant="h5" component="h1" align='center' sx={{ m: 0 }}>{t('advanced.opening')}</Typography>
                        <div className="progressDiv">
                          <CircularProgress disableShrink width={80} height={80} sx={{ height: '80px!important', width: '80px!important' }} />

                        </div>
                        <Typography variant="h7" component="div" align='center'>
                          {t('create_page_3.loading_description_3')}
                        </Typography>
                      </DialogContent>
                    </Dialog>
       {/*  <div class="cursor cPointed" style={this.state.advancedMode==='colorpicker' ? {position:'fixed', visibility: 'visible'} : {position:'absolute', visibility: 'hidden'}}></div> */}
                     {/* <CustomCursor
                      customClass='custom-cursor'
                      dimensions={50}
                      fill='none'
                      
                      smoothness={{
                        movement: 1,
                        scale: 0.1,
                        opacity: 0.2,
                      }}
                      targetOpacity={0.5}
                    /> */}
                     
                     <Dialog
      disableScrollLock={true}
                open={this.state.openGuide}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                
                PaperProps={{
                  sx:{height: 'calc(100% - 200px)', minHeight: '300px'}
                }}
              >
                <DialogTitle sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '3', borderBottom: '1px solid rgba(0 0 0 / 10%)'}}><div style={{display: 'flex', alignItems:'center'}}><HelpRounded sx={{mr: '10px'}}/>{t('worth_knowing.title')}</div><Button startIcon={<Close/>} onClick={this.handleCloseGuide}><span style={{marginTop: '2px'}}>{t('common.close')}</span></Button></DialogTitle>
                <DialogContent className='manualWrapper'  dividers={false}  sx={{padding: '20px', paddingTop: '20px!important', border: 'none!important'}}>
                <Typography align='left' component="div" variant="h6">
                {t('worth_knowing.title_1')}
                </Typography>
                <Typography sx={{mt: '5px', mb: '14px' }} align='left' color="text.secondary" component="div" variant="h7">
                {t('worth_knowing.text_1_1')}<br></br>{t('worth_knowing.text_1_2')}<br></br>
                {t('worth_knowing.text_1_3')}
            </Typography>
                <Typography align='left' component="div" variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
                {t('worth_knowing.title_2')}   <CropRounded sx={{ml:'10px'}}/>
                </Typography>
                <Typography sx={{mt: '5px', mb: '14px' }} align='left' color="text.secondary" component="div" variant="h7">
                {t('worth_knowing.text_2_1')}<br></br>
                {t('worth_knowing.text_2_2')}<br></br>
                {t('worth_knowing.text_2_3')}<br></br>
                {t('worth_knowing.text_2_4')}<br></br>
                {t('worth_knowing.text_2_5')}
            </Typography>
            <Typography align='left' component="div" variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
            {t('worth_knowing.title_3')} <EditRounded sx={{ml:'10px'}}/>
                </Typography>
                <Typography sx={{mt: '5px', mb: '14px' }} align='left' color="text.secondary" component="div" variant="h7">
                {t('worth_knowing.text_3_1')}<br></br>
              <strong>{t('worth_knowing.text_3_2')}</strong><br></br>
              {t('worth_knowing.text_3_3')}
              {t('worth_knowing.text_3_4')}
              {t('worth_knowing.text_3_5')}<br></br>
              <strong>{t('worth_knowing.text_3_6')}</strong><br></br>
              {t('worth_knowing.text_3_7')}<br></br>{t('worth_knowing.text_3_8')}<br></br>
              {t('worth_knowing.text_3_9')}<br></br>
              {t('worth_knowing.text_3_10')}
            </Typography>
            <Typography align='left' component="div" variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
            {t('worth_knowing.title_4')} <CopyrightRounded sx={{ml:'10px'}}/>
                </Typography>
                <Typography sx={{mt: '5px', mb: '14px' }} align='left' color="text.secondary" component="div" variant="h7">
                {t('worth_knowing.text_4_1')}<br></br>
                {t('worth_knowing.text_4_2')}
            </Typography>
                </DialogContent>
              </Dialog>
              <Dialog
      disableScrollLock={true}
                open={this.state.openSavedPatterns}
                keepMounted
                aria-describedby="alert-dialog-slide-description"

              >
                <DialogTitle sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '3', boxShadow: '0px 2px 12px rgb(0 0 0 / 10%)', borderBottom: '1px solid rgba(0 0 0 / 10%)'}}><div style={{display: 'flex', alignItems:'center'}}><SaveRounded sx={{mr: '10px'}}/>{t('saved_patterns_menu.title')}</div><Button startIcon={<Close/>} onClick={this.handleCloseSavedPatterns}><span style={{marginTop: '2px'}}>{t('common.close')}</span></Button></DialogTitle>
                <DialogContent  dividers={false}  sx={{padding: '0px', border: 'none!important'}}>
                <EnhancedTablePatterns isSubscribed={this.state.isSubscribed} openSavedPatterns={this.state.openSavedPatterns} handleSetEditModeFalseSavedPatterns={this.handleSetEditModeFalseSavedPatterns} handleSetEditModeTrueSavedPatterns={this.handleSetEditModeTrueSavedPatterns} editMode={this.state.editModeSavedPatterns} handleDeletePatterns={this.handleDeletePatterns} handleOpenSavedPatternPreview={this.handleOpenSavedPatternPreviewNewNew} data={this.props.allSavedPatterns} theme={this.props.theme}/>
                </DialogContent>
              </Dialog>
              <Dialog
                      disableScrollLock={true}
                      className='no-print'
                      open={this.state.openDeletePatterns}
                      keepMounted
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <Typography gutterBottom variant="h5" component="h1" align='center' sx={{ m: 0 }}>{ this.state.numberOfPatternsToDelete>1 ? t('saved_patterns_menu.deleting')+t('saved_patterns_menu.pattern_plural') : t('saved_patterns_menu.deleting')+t('saved_patterns_menu.pattern_singular')}</Typography>
                        <div className="progressDiv">
                          <CircularProgress disableShrink width={80} height={80} sx={{ height: '80px!important', width: '80px!important' }} />

                        </div>
                        <Typography variant="h7" component="div" align='center'>
                        {t('saved_patterns_menu.deleting') +this.state.numberOfPatternsToDelete + (this.state.numberOfPatternsToDelete>1 ? ' '+t('saved_patterns_menu.pattern_plural') : ' '+t('saved_patterns_menu.pattern_singular'))}<br></br>{t('saved_patterns_menu.please_wait')}
                        </Typography>
                      </DialogContent>
                    </Dialog>
              <SavedPatternDialog handleDeletePatterns={this.handleDeletePatterns} savedPatternPreviewData={this.state.savedPatternPreviewData} openSavedPattern={this.state.openSavedPattern} handleCloseSavedPatternPreview={this.handleCloseSavedPatternPreview}/>

              
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.compressionLoading}
        >
          <CircularProgress disableShrink={true} color="inherit" />
        </Backdrop>
{/*         {this.props.freeMode===true ?
        <Box className='no-print' sx={{ flexGrow: 1 }}>
          <Paper position="static" sx={{ boxShadow: '0 4px 12px rgba(0,0,0,0.2);', borderRadius: '0px' }}>
            <Toolbar sx={{ height: '80px', justifyContent: 'space-between' }}>
              <div className="left" style={{ display: 'flex', alignItems: 'center' }}>
              {this.state.goHome && <Navigate to={'/home'} >EMBRODIA</Navigate>}
              <h1 className="korsstingOverskift" style={{cursor: "pointer"}} onClick={this.handleGoHome}>EMBRODIA</h1> */}
                {/* <Typography className='fabrikkenTekst' gutterBottom variant="h2" component="h1" sx={{ mb: 0, ml: '4px', fontSize: '25px' }}>fabrikken</Typography> */}
         {/*      </div>

              <div className="right">
                 
                <div> 
                  <h1 className="korsstingOverskift">{t('common.free_version')}</h1>
                  </div>
                
              </div> */}
              {/*            
            <Button size='medium' variant="contained" sx={{float: 'right',transition: 'all 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));", boxShadow: 'rgb(76 175 80 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(76 175 80 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(76 175 80 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(76 175 80 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(76 175 80 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(76 175 80 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>Sign Up</Button>  */}
        {/*     </Toolbar>
          </Paper>
        </Box>: <div></div>} */}
        {!this.state.isSubscribed ? 
    ( this.state.showWelcome ? <Welcome theme={this.props.theme} tiers2={this.state.tiers2} user={this.context.user} isFirstTime={this.state.isFirstTime}/> : <div></div>)
          :
<div>


        {imgSrc !== null ?
          <div>
            <Card className='hovedKnapperBack'></Card>
            <Dialog
              disableScrollLock={true}
              open={this.state.openAreYouSureDeletePattern}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle  /* dividers={true}  */ sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: '400'}}>{t('common.are_you_sure')}</DialogTitle>
              <DialogContent dividers={true} >
                <Typography gutterBottom variant="h7" align='center' sx={{ float: 'left' }}>{t('common.are_you_sure_exit_1')}<br></br>{t('common.are_you_sure_exit_2')}</Typography>
              </DialogContent >
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button startIcon={<Close />} onClick={this.handleCloseAreYouSureDeletePattern}>{t('common.no')}</Button>
                <Button startIcon={<Check />} onClick={this.handleClearToDefault}>{t('common.yes')}</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              disableScrollLock={true}
              open={this.state.openAreYouSureUseTicket}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
              
            >
              <DialogTitle /* dividers={true} */ sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: '400'}}>{t('common.are_you_sure')}</DialogTitle>
              <DialogContent dividers={true} >
                <Typography gutterBottom variant="h7" align='center' sx={{ float: 'left' }}>{t('create_page_3.are_you_done_text_1')}<br></br>{this.state.isASharedPattern===true ? t('create_page_1.from_catalog') : t('create_page_3.are_you_done_text_2')}</Typography>
              </DialogContent >
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button startIcon={<Close />} onClick={this.handleCloseAreYouSureUseTicket}>{t('common.no')}</Button>
                <Button startIcon={<Check />} onClick={this.handleTogglePDFPage}>{t('common.yes')}</Button>
              </DialogActions>
            </Dialog>
            <Dialog
              disableScrollLock={true}
              open={this.state.openAreYouSure}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle /* dividers={true}  */sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: '400'}}>{t('common.are_you_sure')}</DialogTitle>
              <DialogContent dividers={true} >
                <Typography gutterBottom variant="h7" align='center' sx={{ float: 'left' }}>{t('common.are_you_sure_exit_1')}<br></br>{t('common.are_you_sure_exit_2')}</Typography>
              </DialogContent >
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button startIcon={<Close />} onClick={this.handleCloseAreYouSure}>{t('common.no')}</Button>
                <Button startIcon={<Check />} onClick={this.handleClearToDefault}>{t('common.yes')}</Button>
              </DialogActions>
            </Dialog>
            <div className='backgroundDiv' style={this.state.displayStyleEdit}>
              <div className="overskrift" style={this.state.advancedEdit === true ? {height: '100px'} : {}}>
                <div>
                  <Typography className='overskriftTekst no-selection' id='overskriftTekst' gutterBottom variant="h4" component="h1" sx={{ m: 0 }}>{this.state.advancedEdit===false ? t('create_page_2.title') : t('advanced.title')}</Typography>
                </div>
                {this.state.advancedEdit === false ? 
                 <div style={{lineHeight: '15px'}}>
                <Typography className='underskriftTekst no-selection' id='underskriftTekst' color="text.secondary" sx={{ m: 0 }}>{/* {t('create_page_2.description_1')}<br></br>{t('create_page_2.description_2')}<br></br> */}{t('create_page_2.description_3')}<Link sx={{cursor: 'pointer'}} onClick={this.handleOpenGuide}>{t('create_page_2.description_4')}</Link>{t('create_page_2.description_5')}</Typography>
              </div> 
              
                : 
                <div style={{lineHeight: '15px'}}>
                  <Typography className='underskriftTekst no-selection' id='underskriftTekst' color="text.secondary" sx={{ m: 0 }}>{t('advanced.description_1')}<br></br>{t('advanced.description_2')}<br></br>{t('advanced.description_3')}</Typography>
                </div>
                }
                
              </div>
              <div className='topNavigationWrapper' style={this.state.advancedEdit===true ?{maxWidth: '1120px'} : {}}>
             {/*  { this.state.savedPatternMode===false &&  */}
              <div className='topNavigationButtonWrapper' style={{justifyContent: 'flex-start'}}>
              <Button className='no-flickr' size='large' variant="outlined" startIcon={<ArrowBack />} onClick={this.state.savedPatternMode===false ? this.state.advancedEdit===false ? this.handleOpenAreYouSure :  this.state.createBlankCanvasMode===true ? this.handleClearToDefault : this.state.undo>0 ? this.handleOpenAreYouSureGoingBackFromAdvancedEdit: this.state.createBlankCanvasMode===true ? this.handleClearToDefault : this.handleCloseAdvancedEdit :  this.handleConvertPixelDrawingToCanvas} sx={{ transition: 'background-color border 150ms ease-in 0s', margin: '8px', }}>{t('common.back_button')}</Button>
            </div>
            {/*   } */}
              <div className='topNavigationButtonWrapper buttonSmallWrapper' style={{justifyContent: 'flex-end'}}>
                <Button disabled={this.state.showLoading ? true : false} className='doneButtonSmall' size='large' variant="contained" startIcon={<Done />} onClick={this.handleToggleDonePage} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button_small')}</Button>
                </div>
                { this.state.savedPatternMode===false ?
                <div className='topNavigationButtonWrapper' style={{justifyContent: 'flex-end'}}>
                  <Button disabled={this.state.showLoading ? true : false} className='doneButtonLarge' size='large' variant="contained" startIcon={<Done />} onClick={this.state.advancedEdit===false ? this.handleToggleDonePage : this.handleConvertPixelDrawingToCanvas} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button')}</Button> 
                  </div>
                  :
                  <div className='topNavigationButtonWrapper' style={{justifyContent: 'flex-end'}}>
                  <Button className='doneButtonLarge' size='large' variant="contained" startIcon={<Done />} onClick={this.handleConvertPixelDrawingToCanvas} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button')}</Button>
                </div>
                }
                {/* <Button size='large' variant="contained" startIcon={<Download />} onClick={this.handleDownloadClick} sx={{transition: 'all 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>Download Mønster</Button> */}
             {/*  { this.state.savedPatternMode===false && 
              <div className='topNavigationButtonWrapper' style={{justifyContent: 'flex-start'}}>
              <Button className='no-flickr' size='large' variant="outlined" startIcon={<ArrowBack />} onClick={this.state.advancedEdit===false ? this.handleOpenAreYouSure : this.state.undo>0 ? this.handleOpenAreYouSureGoingBackFromAdvancedEdit: this.handleCloseAdvancedEdit} sx={{ transition: 'background-color border 150ms ease-in 0s', margin: '8px', }}>{t('common.back_button')}</Button>
              </div> }
                { this.state.savedPatternMode===false ?
                <div className='topNavigationButtonWrapper' style={{justifyContent: 'flex-end'}}>
                  <Button disabled={this.state.showLoading ? true : false} className='doneButtonLarge' size='large' variant="contained" startIcon={<Done />} onClick={this.state.advancedEdit===false ? this.handleToggleDonePage : this.handleConvertPixelDrawingToCanvas} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button')}</Button> 
                  </div>
                  :
                  
                  <div className='topNavigationButtonWrapper' style={{justifyContent: 'flex-end'}}>
                  <Button className='doneButtonLarge' size='large' variant="contained" startIcon={<Done />} onClick={this.handleConvertPixelDrawingToCanvas} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button')}</Button>
                  </div>
                }  */}
                {/* <Button size='large' variant="contained" startIcon={<Download />} onClick={this.handleDownloadClick} sx={{transition: 'all 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>Download Mønster</Button> */}
               </div> 

              <div className="beskærOgResultat" style={this.state.advancedEdit===true ? {justifyContent: 'center', alignItems:'unset' } : {justifyContent: 'space-around', alignItems:'baseline' }}>
                <div className="beskær" style={{visibility: this.state.advancedEdit === true ? 'hidden' : 'inherit', position: this.state.advancedEdit === true ? 'absolute' : 'unset'}}>
                  <div className="beskærOgResultatOverskrift">
                    <Typography className='cardOverskrifter no-selection' gutterBottom variant="h5" component="div">
                      {t('create_page_2.crop_title')}
                    </Typography>

                    <Tooltip sx={{ padding: '0px', m: '0px 0px 0px 0px' }} arrow={true} title={<div>{t('create_page_2.tutorial_1')}<br />{t('create_page_2.tutorial_2')}<br />{t('create_page_2.tutorial_3')}<br />{t('create_page_2.tutorial_4')}</div>}
                    >
                      <IconButton sx={{ padding: '0px', m: '0px 6px 8px' }}>
                        <HelpRounded />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Card id='cropCard' sx={{ overflow: 'initial', maxWidth: '700px', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content', minWidth: '100px' }}>
                    <div className="cropWrapper" id='cropWrapper'>


                      <Cropper
                        className='cropperClass'
                        checkOrientation={false}
                        src={imgSrc}
                        cropend={this.cropImageMaybe}
                        onImageLoaded={this.handleImageLoaded}
                        onComplete={this.handleOnCropComplete}
                        onChange={this.handleOnCropChange}
                        dragMode='move'
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        guides={true}
                        rotatable={true}
                        ref={cropper => { this.cropper = cropper; }}
                        autoCropArea={1}
                        zoomOnTouch={false}
                        responsive={true}
                        background={false}
                        viewMode={1}
                        cropBoxResizable={true}
                        toggleDragModeOnDblclick={false}
                        aspectRatio={this.state.freeAspect === false && (this.state.aspectRatio1 / this.state.aspectRatio2)} />


                      <div className="konfiguration">
                        {/* <TabsUnstyled defaultValue={0}>
                    <TabsList>
                      <Tab onClick={e => {this.handleChangeAspect1by1(e); setTimeout( function(){this.cropImage(e)}.bind(this), 10);}}>1:1</Tab>
                      <Tab onClick={e => {this.handleChangeAspect5by4(e); setTimeout( function(){this.cropImage(e)}.bind(this), 10);}}>5:4</Tab>
                      <Tab onClick={e => {this.handleChangeAspect16by9(e); setTimeout( function(){this.cropImage(e)}.bind(this), 10);}}>16:9</Tab>
                    </TabsList>
                  </TabsUnstyled>
                  <Button size='medium' variant="contained" startIcon={<DeleteForeverRounded />} onClick={this.handleClearToDefault} sx={{transition: 'all 150ms ease-in 0s', margin: '8px', backgroundImage: 'linear-gradient(195deg, rgb(239, 83, 80), rgb(229, 57, 53));', boxShadow:'rgb(244 67 53 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(244 67 53 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(244 67 53 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': {boxShadow: 'rgb(244 67 53 / 15%) 0rem 0.3rem 0.3rem 0rem, rgb(244 67 53 / 20%) 0rem 0.3rem 0.1rem -0.125rem, rgb(244 67 53 / 15%) 0rem 0.3rem 1rem 0rem;'} }}>Nyt Billede</Button> */}
                        <div className="konfigurationsOverskrift">
                          <Typography className='inputOverskrifter' gutterBottom variant="h6" component="div" align='left'>
                          {t('create_page_2.crop_sub_title_1')}
                          </Typography>
                          <Chip label={t('create_page_2.reset_button')} sx={{ height: '22px', mb: '0.5em', ml: '10px', opacity: '0.5' }} onClick={this.handleResetCrop} />
                        </div>
                        <div className='inputKnapper' style={{ justifyContent: 'center' }}>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <ToggleButtonGroup
                              className='tButtonGroup'
                              value={this.state.cropAspect}
                              exclusive
                              aria-label="text alignment"
                            >
                              <ToggleButton className='tButton' value="free" aria-label="left aligned" onClick={ this.handleChangeAspectFree }>
                              {t('create_page_2.crop_size_1')}
                              </ToggleButton>
                              <ToggleButton className='tButton' value="11" aria-label="right aligned" onClick={ this.handleChangeAspect1by1 }>
                              {t('create_page_2.crop_size_2')}
                              </ToggleButton>
                              {/*                     <ToggleButton className='tButton' value="54" aria-label="centered" onClick={e => {this.handleChangeAspect5by4(e); setTimeout( function(){this.cropImage(e)}.bind(this), 100);}}>
                      Bred (5:4)
                    </ToggleButton>
                    <ToggleButton className='tButton' value="169" aria-label="right aligned" onClick={e => {this.handleChangeAspect16by9(e); setTimeout( function(){this.cropImage(e)}.bind(this), 100);}}>
                      Bredere (16:9)
                    </ToggleButton> */}
                            </ToggleButtonGroup>
                            <FormHelperText sx={{ textAlign: 'center' }}>{t('create_page_2.crop_description_1')}</FormHelperText>
                          </FormControl>

                        </div>
                        {this.props.freeMode===false ?
<div>
                        <Divider variant="middle" sx={{ mt: '4px', mb: '16px' }} />
                        <div className="konfigurationsOverskrift">
                          <Typography className='inputOverskrifter' id='drejBilledeOverskrift' gutterBottom variant="h6" component="div" align='left'>
                          {t('create_page_2.crop_sub_title_2')}
                          </Typography>
                          {/* <Chip label={t('create_page_2.reset_button')} sx={{height: '22px', mb:'0.5em', ml:'10px', opacity: '0.5'}} onClick={this.handleResetRotation} /> */}
                        </div>
                        <div className='inputKnapper slider' style={{ justifyContent: 'center' }}>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <RotateSlider handleRotateCrop={this.handleRotateCrop} handleCropImage={this.cropImage} />
                            <FormHelperText sx={{ textAlign: 'center' }}>{t('create_page_2.crop_description_2')}</FormHelperText>
                          </FormControl>
                        </div>
                        <Divider className='dividerKlasse' variant="middle" sx={{ mt: '28px', mb: '16px' }} />
                        <div className="konfigurationsOverskrift">
                          <Typography className='inputOverskrifter' gutterBottom variant="h6" component="div" align='left'>
                          {t('create_page_2.crop_sub_title_3')}
                          </Typography>
                          <Chip label={t('create_page_2.reset_button')} sx={{ height: '22px', mb: '0.5em', ml: '10px', opacity: '0.5' }} onClick={this.handleResetFlip} />
                        </div>
                        <div className='inputKnapper' style={{ justifyContent: 'center', paddingBottom: '20px' }}>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Button className='no-flickr' variant="outlined" onClick={this.handleFlipCropperHorizontal}>
                              <FlipRounded /> {t('create_page_2.crop_flip_button_hor')}
                            </Button>
                            <FormHelperText >{t('create_page_2.crop_description_3')}</FormHelperText>
                          </FormControl>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Button className='no-flickr' variant="outlined" onClick={this.handleFlipCropperVertical}>
                              <FlipRounded sx={{ transform: 'rotate(90deg)' }} /> {t('create_page_2.crop_flip_button_ver')}
                            </Button>
                            <FormHelperText >{t('create_page_2.crop_description_4')}</FormHelperText>
                          </FormControl>

                        </div>

                        {/* <Divider variant="middle" sx={{mt: '28px', mb: '16px'}} /> 

<Typography gutterBottom variant="h6" component="div" align='left'>
              Vælg Andet Billede
            </Typography>
            <div className='inputKnapper'>
<FormControl sx={{ m: 1, minWidth: 120}}>
                   <Button variant="contained"  onClick={this.handleClearToDefault} sx={{transition: 'all 150ms ease-in 0s', margin: '8px', minWidth: '32px', padding: '6px 6px', backgroundImage: 'linear-gradient(195deg, rgb(239, 83, 80), rgb(229, 57, 53));', boxShadow:'rgb(244 67 53 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(244 67 53 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(244 67 53 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': {boxShadow: 'rgb(244 67 53 / 15%) 0rem 0.3rem 0.3rem 0rem, rgb(244 67 53 / 20%) 0rem 0.3rem 0.1rem -0.125rem, rgb(244 67 53 / 15%) 0rem 0.3rem 1rem 0rem;'} } }>
                  <DeleteForeverRounded />
                  </Button> 
        <FormHelperText>Slet og vælg et nyt billede</FormHelperText>
        </FormControl>
        </div> */}




                        {/* <ButtonGroup variant="contained" aria-label="text button group" sx={{transition: 'all 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;'} } }>
                    <Button onClick={e => {this.handleChangeAspect1by1(e); setTimeout( function(){this.cropImage(e)}.bind(this), 10);}}>1:1</Button>
                    <Button onClick={e => {this.handleChangeAspect5by4(e); setTimeout( function(){this.cropImage(e)}.bind(this), 10);}}>5:4</Button>
                    <Button onClick={e => {this.handleChangeAspect16by9(e); setTimeout( function(){this.cropImage(e)}.bind(this), 10); }}>16:9</Button>
                  </ButtonGroup>  */}
</div> : <div></div>}
                      </div> 
                      
                      
                      




                    </div>
                  </Card>
                </div> 
                <Drawer className="konfigurationSmall" anchor='left' open={this.state.openDrawerCrop} onClose={this.handleCloseDrawerCrop}>
<div style={{padding: '20px'}}>
<div id='drawerCropContainer'>

</div>
{this.props.freeMode===false ?
<div className='drawerKonfiguration'>
                        <div className="konfigurationsOverskrift">
                          <Typography className='inputOverskrifter' gutterBottom variant="h6" component="div" align='left'>
                          {t('create_page_2.crop_sub_title_1')}
                          </Typography>
                          <Chip label={t('create_page_2.reset_button')} sx={{ height: '22px', mb: '0.5em', ml: '10px', opacity: '0.5' }} onClick={this.handleResetCrop} />
                        </div>
                        <div className='inputKnapper' style={{ justifyContent: 'center' }}>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <ToggleButtonGroup
                              className='tButtonGroup'
                              value={this.state.cropAspect}
                              exclusive
                              aria-label="text alignment"
                            >
                              <ToggleButton className='tButton' value="free" aria-label="left aligned" onClick={e => { this.handleChangeAspectFree(e); setTimeout(function () { this.cropImage(e) }.bind(this), 100); }}>
                                {t('create_page_2.crop_size_1')}
                              </ToggleButton>
                              <ToggleButton className='tButton' value="11" aria-label="right aligned" onClick={e => { this.handleChangeAspect1by1(e); setTimeout(function () { this.cropImage(e) }.bind(this), 100); }}>
                              {t('create_page_2.crop_size_2')}
                              </ToggleButton>
                            </ToggleButtonGroup>
                            <FormHelperText sx={{ textAlign: 'center' }}>{t('create_page_2.crop_description_1')}</FormHelperText>
                          </FormControl>

                        </div>
                        <Divider variant="middle" sx={{ mt: '4px', mb: '16px' }} />
                        <div className="konfigurationsOverskrift">
                          <Typography className='inputOverskrifter' id='drejBilledeOverskrift' gutterBottom variant="h6" component="div" align='left'>
                            {t('create_page_2.crop_sub_title_2')}
                          </Typography>
                          {/* <Chip label={t('create_page_2.reset_button')} sx={{height: '22px', mb:'0.5em', ml:'10px', opacity: '0.5'}} onClick={this.handleResetRotation} /> */}
                        </div>
                        <div className='inputKnapper slider' style={{ justifyContent: 'center' }}>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <RotateSlider handleRotateCrop={this.handleRotateCrop} handleCropImage={this.cropImage} />
                            <FormHelperText sx={{ textAlign: 'center' }}>{t("create_page_2.crop_description_2")}</FormHelperText>
                          </FormControl>
                        </div>
                        <Divider className='dividerKlasse' variant="middle" sx={{ mt: '28px', mb: '16px' }} />
                        <div className="konfigurationsOverskrift">
                          <Typography className='inputOverskrifter' gutterBottom variant="h6" component="div" align='left'>
                          {t('create_page_2.crop_sub_title_3')}
                          </Typography>
                          <Chip label={t('create_page_2.reset_button')} sx={{ height: '22px', mb: '0.5em', ml: '10px', opacity: '0.5' }} onClick={this.handleResetFlip} />
                        </div>
                        <div className='inputKnapper' style={{ justifyContent: 'center', paddingBottom: '84px' }}>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Button className='no-flickr' variant="outlined" onClick={this.handleFlipCropperHorizontal}>
                              <Flip /> {t('create_page_2.crop_flip_button_hor')}
                            </Button>
                            <FormHelperText >{t('create_page_2.crop_description_3')}</FormHelperText>
                          </FormControl>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Button className='no-flickr' variant="outlined" onClick={this.handleFlipCropperVertical}>
                              <Flip sx={{ transform: 'rotate(90deg)' }} /> {t('create_page_2.crop_flip_button_ver')}
                            </Button>
                            <FormHelperText >{t('create_page_2.crop_description_4')}</FormHelperText>
                          </FormControl>

                        </div>

</div>
  : <div></div>}
</div>
<Card className='callToActionBack' elevation={24}>
<Button size='large' variant="contained" startIcon={<Done />} onClick={this.handleCloseDrawerCrop} sx={{ width: '90%', transition: 'box-shadow 150ms ease-in 0s', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button_small')}</Button>
</Card>

                      </Drawer>
                      <Drawer className="konfigurationSmall" anchor='left' open={this.state.openDrawerEdit} onClose={this.handleCloseDrawerEdit}>
                      <div style={{padding: '20px'}}>
                      <div className="drawerKonfiguration">
                        <Typography className='inputOverskrifter' gutterBottom variant="h6" component="div" align='left'>
                          {t('create_page_2.result_sub_title_1')}
                        </Typography>

                        <div className='inputKnapper'>
                          <FormControl className='formControlInput'>
                            <InputLabel>{t('create_page_2.result_unit_label')}</InputLabel>
                            <Select
                              className='selectInput'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="unit-label"
                              id="unit"
                              /* defaultValue={this.state.selectMeasuringUnit} */
                              value={this.state.selectMeasuringUnit}
                              label={t('create_page_2.result_unit_label')}
                              onChange={this.handleSelectMeasuringUnitChange}
                            >
                              <MenuItem value={t('common.unit_1_small')}>{t('common.unit_1')}</MenuItem>
                              <MenuItem value={t('common.unit_2_small')}>{t('common.unit_2')}</MenuItem>
                              <MenuItem value={t('common.unit_3_small')}>{t('common.unit_3')}</MenuItem>
                            </Select>
                            <FormHelperText>{t('create_page_2.result_unit_description')}</FormHelperText>
                          </FormControl>

                           <FormControl className='formControlInput'>
                            <InputLabel>{t('create_page_2.fabric')}</InputLabel>
                            <Select
                              className='selectInput'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="broderistof-label"
                              id="broderistof"
                              /* defaultValue={this.state.selectBroderistof} */
                              value={this.state.selectBroderistof}
                              label={t('create_page_2.fabric')}
                              onChange={this.handleSelectBroderistofChange}
                            >
                              <MenuItem value={5.4}>AIDA 5.4</MenuItem>
                              <MenuItem value={4.4}>AIDA 4.4</MenuItem>
                            </Select>
                            <FormHelperText className='hjælpetekstStorSkærm'>{t('create_page_2.fabric_description_large')}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm'>{t('create_page_2.fabric_description_small')}</FormHelperText>
                          </FormControl> 



                        </div>

                        <div className='inputKnapper'>
                          <FormControl className='formControlInput'>
                            <TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.widthInput} */
                              value={this.state.widthInput}
                              error={this.state.widthInputError}
                              id="widthInput"
                              label={t('create_page_2.result_width_label') + this.state.selectMeasuringUnit}
                              sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);' }}
                              onChange={this.validateWidthInput}
                            />
                            <FormHelperText className='hjælpetekstStorSkærm' sx={{ color: this.state.widthInputError ? '#d32f2f' : 'text.secondary' }}>{this.state.widthInputErrorText}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm' sx={{ color: this.state.widthInputError ? '#d32f2f' : 'text.secondary' }}>{this.state.widthInputErrorTextSmall}</FormHelperText>
                          </FormControl>
                          <Typography gutterBottom variant="subtitle1" component="div" sx={{ ml: '-22px', mr: '-22px', marginTop: '13px', opacity: '0.4' }} className='lilleXIMidten'>
                            x
                          </Typography>

                          <FormControl className='formControlInput'>
                            <TextField
                              size='small'
                              type={'number'}
                              disabled
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              value={this.state.selectMeasuringUnit === t('common.unit_1_small') ? this.state.cropHeight : this.state.selectMeasuringUnit === t('common.unit_2_small') ? Math.round(this.state.cropHeight / this.state.selectBroderistof): Math.round((this.state.cropHeight / (this.state.selectBroderistof*2.54))*10)/10}
                              id="demo-helper-text-aligned"
                              label={t('create_page_2.result_height_label') + this.state.selectMeasuringUnit}
                            />
                            <FormHelperText className='hjælpetekstStorSkærm' >{t('create_page_2.result_height_description_1') + this.state.selectMeasuringUnit + t('create_page_2.result_height_description_2')}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm' >{t('create_page_2.result_height_description_1') + this.state.selectMeasuringUnit + t('create_page_2.result_height_description_2_small')}</FormHelperText>
                          </FormControl>
                        </div>



                        <div className='inputKnapper'>
                          <Accordion className='alleTalAccordion' elevation={4}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>{t('create_page_2.result_show_all_drawer')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                                {t('create_page_2.result_show_all_1')} {Math.round(this.state.cropHeight) * this.state.cropWidth}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_2')} {this.state.selectMeasuringUnitBool ? Math.round((this.state.cropWidth / this.state.selectBroderistof) * 10) / 10 : Math.round((this.state.cropWidth / this.state.selectBroderistof))}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_3')} {this.state.selectMeasuringUnitBool ? Math.round((this.state.cropHeight / this.state.selectBroderistof) * 10) / 10 : Math.round((this.state.cropHeight / this.state.selectBroderistof))}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_6')} {this.state.selectMeasuringUnitBool ? Math.round((this.state.cropWidth / (this.state.selectBroderistof*2.54)) * 10) / 10 : Math.round((this.state.cropWidth / (this.state.selectBroderistof*2.54))*10)/10}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_7')} {this.state.selectMeasuringUnitBool ? Math.round((this.state.cropHeight / (this.state.selectBroderistof*2.54)) * 10) / 10 : Math.round((this.state.cropHeight / (this.state.selectBroderistof*2.54))*10)/10}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_4')} {this.state.cropWidth}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_5')} {this.state.cropHeight}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <Divider className='inputDivider' variant="middle" sx={{ mt: '28px', mb: '16px' }} />

                        <Typography className='inputOverskrifter' gutterBottom variant="h6" component="div" align='left'>
                        {t('create_page_2.result_sub_title_2')}
                        </Typography>

                        <div className='inputKnapper'>
                          <FormControl className='formControlInput'>
                            <TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.opts.colors} */
                              id="colorInput"
                              label={t('create_page_2.result_max_colors_label')}
                              value={this.state.quant_colors}
                              onChange={this.handleMadeChange}
                            />
                            <FormHelperText className='hjælpetekstStorSkærm'>{t('create_page_2.result_max_colors_description')}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm'>{t('create_page_2.result_max_colors_description_small')}</FormHelperText>
                          </FormControl>

                          <FormControl className='formControlInput'>
                            <TextField
                              size='small'
                              type={'number'}
                              disabled
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              value={this.state.numberOfDMCColors}
                              id="demo-helper-text-aligned"
                              label={t('create_page_2.result_dmc_colors_label')}
                            />
                            <FormHelperText className='hjælpetekstStorSkærm'>{t('create_page_2.result_dmc_colors_description')}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm'>{t('create_page_2.result_dmc_colors_description_small')}</FormHelperText>
                          </FormControl>
                        </div>
                        <div className='inputKnapper' style={{marginBottom: '84px'}}>
                          <FormControl className='formControlInput'>
                            <InputLabel>{t('create_page_2.result_filter_label')}</InputLabel>
                            <Select
                              className='selectInput'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              /* defaultValue={this.state.selectValue} */
                              value={this.state.selectValue}
                              label={t('create_page_2.result_filter_label')}
                              onChange={this.handleSelectChange}
                            >
                              <MenuItem value={'realistisk1'}>{t('create_page_2.result_realistic') + " 1"}</MenuItem>
                              <MenuItem value={'realistisk2'}>{t('create_page_2.result_realistic') + " 2"}</MenuItem>
                              <MenuItem value={'popart1'}>Pop-Art 1</MenuItem>
                              <MenuItem value={'popart2'}>Pop-Art 2</MenuItem>
                            </Select>
                            <FormHelperText>{t('create_page_2.result_filter_description')}</FormHelperText>
                          </FormControl>


                          <FormControl className='formControlInput'>
                            <InputLabel>{t('create_page_2.result_profile_label')}</InputLabel>
                            <Select
                              className='selectInput'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              /* defaultValue={this.state.selectColorValue} */
                              value={this.state.selectColorValue}
                              label={t('create_page_2.result_profile_label')}
                              onChange={this.handleSelectColorChange}
                            >
                              <MenuItem value={'profil1'}>{t('create_page_2.result_profile') + " 1"}</MenuItem>
                              <MenuItem value={'profil2'}>{t('create_page_2.result_profile') + " 2"}</MenuItem>
                            </Select>
                            <FormHelperText className='hjælpetekstStorSkærm'>{t('create_page_2.result_profile_description')}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm'>{t('create_page_2.result_profile_description')}</FormHelperText>
                          </FormControl>
                        </div>
                      </div>
</div>
<Card className='callToActionBack' elevation={24}>
<Button size='large' variant="contained" startIcon={<Done />} onClick={this.handleCloseDrawerEdit} sx={{ width: '90%', transition: 'box-shadow 150ms ease-in 0s', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button_small')}</Button>
</Card>
                      </Drawer>
                      <div className='AvanceretRedigeringZoom'  style={this.state.advancedEdit === true ? (this.state.drawGrid===true && this.state.drawGridSettingRulers===true) ? {visibility: 'visible' , position: 'relative', marginRight: '70px'} : {visibility: 'visible' , position: 'relative', marginRight: '40px'} : {visibility: 'hidden' , position: 'absolute'}}>
                    
                    <Card className='AdvancedEditCardZoom' sx={{marginTop: '60px', minWidth: '0px', overflow: 'initial', maxWidth: '60px', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content' }}>
                    
          
                                
                                <Button variant='outlined' className='buttonZoom'  aria-label="left aligned" sx={{minWidth: '50px', transition: 'background-color border 150ms ease-in 0s'}} onClick={this.zoomIn}>
                                <Tooltip title={t('advanced.zoomIn')} arrow placement="left">
                                <ZoomInRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </Button>
                                
                                
                                <Button variant='outlined' className='buttonZoom' aria-label="right aligned" sx={{minWidth: '50px', transition: 'background-color border 150ms ease-in 0s'}} onClick={this.zoomOut}>
                                <Tooltip title={t('advanced.zoomOut')} arrow placement="left">
                                <ZoomOutRounded sx={{ padding: '10px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </Button>
                                <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
                                {/* <Tooltip title="Indstil Størrelse" arrow placement="left"> */}
                                <Select
                              
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="zoom-select-label"
                              id="zoom-select"
                              sx={{borderRadius: '64px!important' , width: '50px', height: '50px',transition: 'background 150ms ease-in 0s!important', margin: '4px!important', '.MuiOutlinedInput-notchedOutline': {border: 'none!important'}, '&:hover': {background: 'rgba(0, 0, 0, 0.04);'}}}
                              value={this.state.drawZoom}
                              label={t('create_page_2.result_unit_label')}
                              onChange={this.handleSelectZoomChange}
                              IconComponent={''}
                      
                              
                            >
                              <MenuItem value={1}>1x</MenuItem>
                              <MenuItem value={2}>2x</MenuItem>
                              <MenuItem value={4}>4x</MenuItem>
                              <MenuItem value={6}>6x</MenuItem>
                              <MenuItem value={8}>8x</MenuItem>
                              <MenuItem value={10}>10x</MenuItem>
                              <MenuItem value={12}>12x</MenuItem>
                              <MenuItem value={14}>14x</MenuItem>
                              <MenuItem value={16}>16x</MenuItem>
                              <MenuItem value={18}>18x</MenuItem>
                              <MenuItem value={20}>20x</MenuItem>
                            </Select>
                            {/* </Tooltip> */}
                            
                              </Card>
                              <Card className='AdvancedEditCardZoom' sx={{position: "relative", margin: '0px',marginTop: '30px', width: '60px', overflow: 'initial',  boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);' }}>
                    
          
                                
                                <ToggleButton onClick={(e)=>{if(this.state.drawGrid===false){ var element = document.querySelector(".drawGridSettingsWrapper");  element.style.opacity = "1"; element.style.width= "174px"} else {var element = document.querySelector(".drawGridSettingsWrapper"); element.style.opacity = "0"; element.style.width= "0px"}}} onMouseEnter={(e)=>{if(this.state.drawGrid===true){ var element = document.querySelector(".drawGridSettingsWrapper"); element.style.opacity = "1"; element.style.width= "174px"}}} onMouseLeave={(e)=>{var element = document.querySelector(".drawGridSettingsWrapper"); element.style.opacity = "0"; element.style.width= "0px"}}  className="drawGridButton" disabled={this.state.drawZoom<4} value={"check"} selected={this.state.drawGrid} aria-label="left aligned" sx={{  transition: 'background 150ms ease-in 0s!important', zIndex: '1000!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}} onChange={this.toggleDrawGrid}>
                                <Tooltip  title={t('advanced.grid')} arrow placement="left">
                                <Grid4x4Rounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>

                                </ToggleButton>
                                <div className='drawGridSettingsWrapper' onMouseEnter={(e)=>{var element = document.querySelector(".drawGridSettingsWrapper"); element.style.opacity = "1"; element.style.width= "174px"}} onMouseLeave={(e)=>{var element = document.querySelector(".drawGridSettingsWrapper"); element.style.opacity = "0"; element.style.width= "0px"}} >
                                <Card className='drawGridSettingsCard'>
                                <FormGroup>
                                  <FormControlLabel control={<Checkbox checked={this.state.drawGridSettingGrid} onChange={()=> {var grid=this.state.drawGridSettingGrid; this.setState({drawGridSettingGrid: !grid},(e)=>{this.handleToggleDrawGridSettings(e)})}} />} label={t('advanced.grid_setting_1')} />
                                  <FormControlLabel control={<Checkbox checked={this.state.drawGridSettingCenter} onChange={()=> {var grid=this.state.drawGridSettingCenter; this.setState({drawGridSettingCenter: !grid},(e)=>{this.handleToggleDrawGridSettings(e)})}}/>} label={t('advanced.grid_setting_2')} />
                                  <FormControlLabel control={<Checkbox checked={this.state.drawGridSettingRulers} onChange={()=> {var grid=this.state.drawGridSettingRulers; this.setState({drawGridSettingRulers: !grid},(e)=>{this.handleToggleDrawGridSettings(e)})}}/>} label={t('advanced.grid_setting_3')} />
                                </FormGroup>
                                </Card>
                                </div>
                                 <ToggleButton value={"check"} selected={this.state.stitchMode} className='buttonZoom'  aria-label="left aligned" sx={{  transition: 'background 150ms ease-in 0s!important', zIndex: '1000!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}} onChange={this.toggleStitchMode}>
                                <Tooltip title={t('advanced.stitch')} arrow placement="left">
                                <VisibilityRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>

                                </ToggleButton> 
                              </Card>

                              <Card className='AdvancedEditCardZoom' sx={{position: "relative", margin: '0px',marginTop: '30px', width: '60px', overflow: 'initial',  boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);' }}>
                    
          
                                
                                <ToggleButton className='buttonZoom' aria-label="left aligned" sx={{  transition: 'background 150ms ease-in 0s!important', zIndex: '1000!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}  selected={this.state.openResizer}  onChange={this.handleOpenResizer}>
                                <Tooltip  title={t("advanced.resizeCanvas_tooltip")} arrow placement="left">
                                <PhotoSizeSelectLargeRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>

                                </ToggleButton>
                                
                              </Card>
                              
                      </div> 
                      
                          
                <div className="box" id="pixelDiv" style={{marginTop: '-25px'}} >

                    <div className="hiddenDiv">
                      <ImagePixelated width={this.state.cropWidth} height={this.state.cropHeight} src={this.state.cropResult} pixelSize={1}
                      />
                    </div>
                
                    
                  <div className="resultat">
                    <div className='mobileOverskrift'>
                  <Typography className='overskriftTekstSmall' gutterBottom variant="h4" component="h1" sx={{ m: 0 }}>{t("create_page_2.title")}</Typography>
                  <Typography className='underskriftTekstSmall' color="text.secondary" sx={{ mb: '10px' }}>{t("create_page_2.description_1_mobile")}<br></br>{t("create_page_2.description_2_mobile")}</Typography>
                  </div>
                  <div className="beskærOgResultatOverskrift">
                    <Typography className='cardOverskrifter no-selection' gutterBottom variant="h5" component="div">
                    {t('create_page_2.result_title')}
                    </Typography>
                    { this.state.advancedEdit === false &&
                    <Tooltip sx={{ padding: '0px', m: '0px 0px 0px 0px' }} arrow={true} title={<div>{t('create_page_2.tutorial_5')}</div>}
                    >
                      <IconButton sx={{ padding: '0px', m: '0px 6px 8px' }}>
                        <HelpRounded />
                      </IconButton>
                    </Tooltip>}
                    </div>
                    <div className='resultat' style={{position: 'relative'}}>
                      <div style={this.state.advancedEdit===true ? this.state.drawGrid===true ? {visibility: 'visible'}:{visibility: 'hidden'} : {visibility: 'hidden'}}>
                    <div id="drawRulerYWrapper" >
                      
                      <div id="drawRulerYLineWrapper" style={this.props.theme.palette.mode === 'dark' ? {color: 'rgb(255,255,255)'} : {color: 'rgb(0,0,0)'}}></div>
                                                </div>
                                                </div>
                    
                    <Card className='resultCard' sx={this.state.advancedEdit && {width: 'fit-content!important', margin: 'auto' }}>
                      
                      <div className="errorDiv" style={{ display: this.state.showError ? 'flex' : 'none' }}>
                        <div>
                          <ErrorRounded sx={{ fontSize: '6rem', color: 'gray' }} />
                          <Typography className='cardOverskrifter' gutterBottom variant="h4" component="div" sx={{ color: 'gray' }}>
                            <strong>{t('create_page_2.result_big_error_title')}</strong>
                          </Typography>
                          <Typography className='cardOverskrifter' gutterBottom variant="h5" component="div" sx={{ color: 'gray' }}>
                          {t('create_page_2.result_big_error_description_1')}<br></br>{this.props.freeMode===false ? t('create_page_2.result_big_error_description_2') : t('create_page_2.result_big_error_description_2_free')}<br></br>{t('create_page_2.result_big_error_description_3')}
                          </Typography>
                        </div>
                      </div>
                      <div className="loadingDiv" style={{ display: this.state.showLoading ? 'flex' : 'none' }}>
                        <div>
                        <CircularProgress disableShrink width={80} height={80} sx={{ height: '80px!important', width: '80px!important' }} />
                        </div>
                      </div>
                      <div id="quantDiv">
                          

                      </div>
                      <div className="quantCanvasBackground"  style={this.state.advancedEdit===true ? {visibility: 'hidden'} : {visibility: 'inherit'}} ></div>
                      {/* <div id="drawerWrapper" style={(this.state.advancedMode==='draw' || this.state.advancedMode==='buckettool' || this.state.advancedMode==='eraser') ? {cursor: 'crosshair', background: 'none'} : this.state.advancedMode==='colorpicker' ? {cursor: 'none', background: 'none'} : {background: 'none'} }>

                          </div> */}
                          <div style={this.state.advancedEdit===true ? this.state.drawGrid===true ? {position: 'initial', visibility: 'visible', opacity: '1'}:{position: 'absolute', visibility: 'hidden', opacity: '0'} : {position: 'absolute', visibility: 'hidden', opacity: '0'}}>
                       <div className='drawGuideWrapper'>
                          <div id="drawGuideWrapperX" style={{display: "flex", position: 'absolute', pointerEvents: "none", zIndex: '1200'}}>

                          </div>
                          <div id="drawGuideWrapperY" style={{display: "grid", position: 'absolute', pointerEvents: "none", zIndex: '1200'}}>

                          </div>
                          </div>
                          </div>
                          <div id="drawerWrapperCanvasTopSelection" style={ this.state.advancedEdit===true ? this.state.advancedMode==="selectiontool" ? {visibility: 'visible'} : {visibility: 'hidden'} : {visibility: 'hidden'}}>

                          </div>
                          <div id="drawerWrapperCanvasTop" style={this.state.advancedEdit===true ?  (this.state.advancedMode==='linetool' || this.state.advancedMode==='squaretool' || this.state.advancedMode==='circletool' || this.state.advancedMode==='selectiontool') ? {cursor: 'crosshair', background: 'none', pointerEvents: 'initial', visibility: 'visible'} : {background: 'none', pointerEvents: 'none', visibility: 'hidden'} : {background: 'none', pointerEvents: 'none', visibility: 'hidden'}  }>

                          </div>
                          <div id="drawerWrapperCanvas" style={(this.state.advancedMode==='draw' || this.state.advancedMode==='buckettool' || this.state.advancedMode==='eraser') ? {cursor: 'crosshair', background: 'none'} : this.state.advancedMode==='colorpicker' ? {cursor: 'none', background: 'none'} : {background: 'none'} }>

                          </div>
                          <div className='transparentBackground' style={this.state.advancedEdit===true ?{visibility: 'visible'}: {visibility: 'hidden'}}>
                          
                          </div>
                          <img src={require('../images/stitchwhite2transparent25.png')} id='stitchImage'/>
                          
                          
                      {/*             {this.state.matches && (             <GridLines className="grid-area"  cellWidth={(1/this.state.cropWidth)*600} lineColor={'black'} strokeWidth={.2} dasharray="4">
            <GridLines className="grid-area" cellWidth={600/2} strokeWidth={2} lineColor={'red'} cellWidth2={((1/this.state.cropWidth)*600)*10} strokeWidth2={.2} lineColor2={'black'} > 
            
            <canvas style={{width: "600px", height: "594px", position: 'relative'}}></canvas>
            
           </GridLines> 
          </GridLines> )}
          {!this.state.matches && (             <GridLines className="grid-area"  cellWidth={(1/this.state.cropWidth)*400} lineColor={'black'} strokeWidth={.2} dasharray="4">
            <GridLines className="grid-area" cellWidth={400/2} strokeWidth={2} lineColor={'red'} cellWidth2={((1/this.state.cropWidth)*400)*10} strokeWidth2={.2} lineColor2={'black'} > 
            
            <canvas style={{width: "400px", height: "394px", position: 'relative'}}></canvas>
            
           </GridLines> 
          </GridLines> )} */}

                      <div className='quantCanvas' style={{position: this.state.advancedEdit === false ? "relative" : "absolute"}}></div>
                      <div className="konfiguration" style={{visibility: this.state.advancedEdit === true ? 'hidden' : 'inherit', position: this.state.advancedEdit === true ? 'absolute' : 'unset'}}>
                        <Typography className='inputOverskrifter' gutterBottom variant="h6" component="div" align='left'>
                        {t('create_page_2.result_sub_title_1')}
                        </Typography>

                        <div className='inputKnapper'>
                          <FormControl className='formControlInput'>
                            <InputLabel>{t('create_page_2.result_unit_label')}</InputLabel>
                            <Select
                              className='selectInput'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              /* defaultValue={this.state.selectMeasuringUnit} */
                              value={this.state.selectMeasuringUnit}
                              label={t('create_page_2.result_unit_label')}
                              onChange={this.handleSelectMeasuringUnitChange}
                            >
                              <MenuItem value={t('common.unit_1_small')}>{t('common.unit_1')}</MenuItem>
                              <MenuItem value={t('common.unit_2_small')}>{t('common.unit_2')}</MenuItem>
                              <MenuItem value={t('common.unit_3_small')}>{t('common.unit_3')}</MenuItem>
                            </Select>
                            <FormHelperText>{t('create_page_2.result_unit_description')}</FormHelperText>
                          </FormControl>

                          <FormControl className='formControlInput'>
                            <InputLabel>{t('create_page_2.fabric')}</InputLabel>
                            <Select
                              className='selectInput'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="broderistof-label"
                              id="broderistof"
                              /* defaultValue={this.state.selectBroderistof} */
                              value={this.state.selectBroderistof}
                              label={t('create_page_2.fabric')}
                              onChange={this.handleSelectBroderistofChange}
                            >
                              <MenuItem value={5.4}>AIDA 5.4</MenuItem>
                              <MenuItem value={4.4}>AIDA 4.4</MenuItem>
                            </Select>
                            <FormHelperText className='hjælpetekstStorSkærm'>{t('create_page_2.fabric_description_large')}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm'>{t('create_page_2.fabric_description_small')}</FormHelperText>
                          </FormControl> 



                        </div>

                        <div className='inputKnapper'>
                          <FormControl className='formControlInput'>
                            <TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.widthInput} */
                              value={this.state.widthInput}
                              error={this.state.widthInputError}
                              id="widthInput"
                              label={t('create_page_2.result_width_label') + this.state.selectMeasuringUnit}
                              sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);' }}
                              onChange={this.validateWidthInput}
                            />
                            <FormHelperText className='hjælpetekstStorSkærm' sx={{ color: this.state.widthInputError ? '#d32f2f' : 'text.secondary' }}>{this.state.widthInputErrorText}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm' sx={{ color: this.state.widthInputError ? '#d32f2f' : 'text.secondary' }}>{this.state.widthInputErrorTextSmall}</FormHelperText>
                          </FormControl>
                          <Typography gutterBottom variant="subtitle1" component="div" sx={{ ml: '-22px', mr: '-22px', marginTop: '13px', opacity: '0.4' }} className='lilleXIMidten'>
                            x
                          </Typography>

                          <FormControl className='formControlInput'>
                            <TextField
                              size='small'
                              type={'number'}
                              disabled
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              value={this.state.selectMeasuringUnit === t('common.unit_1_small') ? this.state.cropHeight : this.state.selectMeasuringUnit === t('common.unit_2_small') ? Math.round(this.state.cropHeight / this.state.selectBroderistof): Math.round((this.state.cropHeight / (this.state.selectBroderistof*2.54))*10)/10}
                              id="demo-helper-text-aligned"
                              label={t('create_page_2.result_height_label') + this.state.selectMeasuringUnit}
                              onChange={this.cropImage}
                            />
                            <FormHelperText className='hjælpetekstStorSkærm' >{t('create_page_2.result_height_description_1') + this.state.selectMeasuringUnit + t('create_page_2.result_height_description_2') }</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm' >{t('create_page_2.result_height_description_1') + this.state.selectMeasuringUnit + t('create_page_2.result_height_description_2_small') }</FormHelperText>
                          </FormControl>
                        </div>



                        <div className='inputKnapper'>
                          <Accordion className='alleTalAccordion' elevation={4}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>{t('create_page_2.result_show_all_drawer')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_1')} {Math.round(this.state.cropHeight) * this.state.cropWidth}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_2')} {this.state.selectMeasuringUnitBool ? Math.round((this.state.cropWidth / this.state.selectBroderistof) * 10) / 10 : Math.round((this.state.cropWidth / this.state.selectBroderistof))}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_3')} {this.state.selectMeasuringUnitBool ? Math.round((this.state.cropHeight / this.state.selectBroderistof) * 10) / 10 : Math.round((this.state.cropHeight / this.state.selectBroderistof))}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_6')} {this.state.selectMeasuringUnitBool ? Math.round((this.state.cropWidth / (this.state.selectBroderistof*2.54)) * 10) / 10 : Math.round((this.state.cropWidth / (this.state.selectBroderistof*2.54))*10)/10}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_7')} {this.state.selectMeasuringUnitBool ? Math.round((this.state.cropHeight / (this.state.selectBroderistof*2.54)) * 10) / 10 : Math.round((this.state.cropHeight / (this.state.selectBroderistof*2.54))*10)/10}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_4')} {this.state.cropWidth}
                              </Typography>
                              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>
                              {t('create_page_2.result_show_all_5')} {this.state.cropHeight}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        {/* <div className='inputKnapper'>
<FormControl sx={{ m: 1, minWidth: 120}}>
<TextField
size='small'
                  type={'number'}
                  disabled
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={this.state.cropHeight*this.state.cropWidth}
  helperText="Antal Korssting i alt"
  id="demo-helper-text-aligned"
  label="Korssting"
  
  onChange={this.cropImage}
/>
</FormControl>

<FormControl sx={{ m: 1, minWidth: 120}}>
<TextField
size='small'
                  type={'number'}
                  disabled
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={1000}
  helperText="Meter garn"
  id="demo-helper-text-aligned"
  label="Garn"
  
  onChange={this.cropImage}
/>
</FormControl>

</div> */}
                        <Divider className='inputDivider' variant="middle" sx={{ mt: '28px', mb: '16px' }} />

                        <Typography className='inputOverskrifter' gutterBottom variant="h6" component="div" align='left'>
                        {t('create_page_2.result_sub_title_2')}
                        </Typography>

                        <div className='inputKnapper'>
                          <FormControl className='formControlInput'>
                            <TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.opts.colors} */
                              value={this.state.quant_colors}
                              id="colorInput"
                              label={t('create_page_2.result_max_colors_label')}
                              onChange={this.validateColorInput}
                            />
                            <FormHelperText className='hjælpetekstStorSkærm'>{t('create_page_2.result_max_colors_description')}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm'>{t('create_page_2.result_max_colors_description_small')}</FormHelperText>
                          </FormControl>

                          <FormControl className='formControlInput'>
                            <TextField
                              size='small'
                              type={'number'}
                              disabled
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              value={this.state.numberOfDMCColors}
                              id="demo-helper-text-aligned"
                              label={t('create_page_2.result_dmc_colors_label')}

                              onChange={this.cropImage}
                            />
                            <FormHelperText className='hjælpetekstStorSkærm'>{t('create_page_2.result_dmc_colors_description')}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm'>{t('create_page_2.result_dmc_colors_description_small')}</FormHelperText>
                          </FormControl>
                        </div>
                        <div className='inputKnapper'>
                          <FormControl className='formControlInput'>
                            <InputLabel>{t('create_page_2.result_filter_label')}</InputLabel>
                            <Select
                              className='selectInput'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="filter-label"
                              id="filter"
                              /* defaultValue={this.state.selectValue} */
                              value={this.state.selectValue}
                              label={t('create_page_2.result_filter_label')}
                              onChange={this.handleSelectChange}
                            >
                              <MenuItem value={'realistisk1'}>{t('create_page_2.result_realistic') + " 1"}</MenuItem>
                              <MenuItem value={'realistisk2'}>{t('create_page_2.result_realistic') + " 2"}</MenuItem>
                              <MenuItem value={'popart1'}>Pop-Art 1</MenuItem>
                              <MenuItem value={'popart2'}>Pop-Art 2</MenuItem>
                            </Select>
                            <FormHelperText>{t('create_page_2.result_filter_description')}</FormHelperText>
                          </FormControl>


                          <FormControl className='formControlInput'>
                            <InputLabel>{t('create_page_2.result_profile_label')}</InputLabel>
                            <Select
                              className='selectInput'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="colorprofile-label"
                              id="colorprofile"
                              /* defaultValue={this.state.selectColorValue} */
                              value={this.state.selectColorValue}
                              label={t('create_page_2.result_profile_label')}
                              onChange={this.handleSelectColorChange}
                            >
                              <MenuItem value={'profil1'}>{t('create_page_2.result_profile') + " 1"}</MenuItem>
                              <MenuItem value={'profil2'}>{t('create_page_2.result_profile') + " 2"}</MenuItem>
                            </Select>
                            <FormHelperText className='hjælpetekstStorSkærm'>{t('create_page_2.result_profile_description')}</FormHelperText>
                            <FormHelperText className='hjælpetekstLilleSkærm'>{t('create_page_2.result_profile_description')}</FormHelperText>
                          </FormControl>
                        </div>
                        

{/* <Typography gutterBottom variant="h6" component="div" align='left'>
              Farve Look
            </Typography>
            <div className='inputKnapper' style={{justifyContent:'flex-start'}}>
            <FormControl sx={{ m: 1, minWidth: 120}}>
            
              <Stack direction="row" spacing={1} alignItems="center">
              <Typography>1</Typography>
<Switch onChange={this.handleQuantSwitch} checked={this.state.quantSwitch}/>
<Typography>2</Typography>
</Stack>
</FormControl>
<FormControl sx={{ m: 1, minWidth: 120}}>
<div id="paletteDiv">
                    

            </div>
</FormControl>
        </div> */}
{this.props.freeMode===false &&
<div style={{position: 'relative'}}>
 <Divider variant="middle" sx={{mt: '8px', mb: '16px'}} />
<Typography className='inputOverskrifter' gutterBottom variant="h6" component="div" align='left'>
              {t("create_page_2.result_sub_title_3")}
            </Typography>
            <div className="marieSytrådDesignVærksted"></div>
            <div className='inputKnapper'>
<FormControl sx={{ m: 1, minWidth: 120}}>
{/*<Button onClick={this.handleOpenAdvancedEdit} startIcon={<EditRounded/>} size='large' variant="contained" sx={{float: 'right',transition: 'all 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));", boxShadow: 'rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(26 115 232 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(26 115 232 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(26 115 232 / 15%) 0rem 0.1rem 2rem 0rem;'} }}>Avanceret Redigering</Button>*/}
<Button disabled={this.state.showLoading ? true : false} onClick={this.handleOpenAdvancedEdit} startIcon={<EditRounded/>} size='large' variant="outlined" sx={{width: '284px', margin: 'auto',  transition: 'background-color border 150ms ease-in 0s', marginBottom: '8px' }}>{t('advanced.title')}</Button>

        <FormHelperText sx={{textAlign: 'center'}}>{t("create_page_2.result_advanced_description_1")}<br/>{t("create_page_2.result_advanced_description_2")}<br/>{t("create_page_2.result_advanced_description_3")}</FormHelperText>
        </FormControl>
        </div>  
        </div>}

                      </div>

                    </Card>
                    </div>
                    <div style={this.state.advancedEdit===true ? this.state.drawGrid===true ? {position: 'initial', visibility: 'visible'}:{position: 'absolute', visibility: 'hidden'} : {position: 'absolute', visibility: 'hidden'}}>
                    <div id="drawRulerXWrapper">
                <div id="drawRulerXLineWrapper" style={this.props.theme.palette.mode === 'dark' ? {color: 'rgb(255,255,255)'} : {color: 'rgb(0,0,0)'}}></div>
                          </div>
                          </div>
                    <div className='smallKonfiguration'>
<Button onClick={this.handleOpenDrawerCrop} startIcon={<CropRounded/>} size='large' variant="contained" sx={{ width: '132px', transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t("create_page_2.mobile_button_crop")}</Button>
<Button onClick={this.handleOpenDrawerEdit} startIcon={<EditRounded/>} size='large' variant="contained" sx={{ width: '132px', transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t("create_page_2.mobile_button_edit")}</Button>
</div>
                  </div>
                  
                </div>
                
                <div className='AvanceretRedigeringVærktøjsBjælke' style={{marginLeft: '40px',visibility: this.state.advancedEdit === false ? 'hidden' : 'inherit', position: this.state.advancedEdit === false ? 'absolute' : 'unset'}}>
                <Card sx={{margin: 'auto', overflow: 'initial', width:'60px!important', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', mt: '60px' }}>
                    
                    <ToggleButtonGroup
                                className='tButtonGroupAdvanced'
                                value={this.state.advancedMode}
                                exclusive
                                aria-label="text alignment"
                                onChange={this.handleChangeAdvancedEditMode}
                                sx={{display: 'block!important', height:'unset!important'}}
                              >
                                
                                <ToggleButton  className='tButtonAdvanced' value="draw" aria-label="left aligned" sx={{  transition: 'background 150ms ease-in 0s!important', zIndex: '1000!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_pen')} arrow placement="right">
                                <BrushRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
  
                                <ToggleButton  className='tButtonAdvanced' value="eraser" aria-label="left aligned" sx={{  transition: 'background 150ms ease-in 0s!important', zIndex: '1000!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_eraser')} arrow placement="right">
                                 {/* <CallToActionRounded preserveAspectRatio='none' sx={{transform: 'rotate(45deg)',padding: '10px!important', width: '36px!important', height: '2em!important'}}/> */}
                                 <CropPortraitRounded sx={{transform: 'rotate(45deg)', padding:'1px!important', width: '1.35em!important', height: '1.35em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
  
                                <ToggleButton  className='tButtonAdvanced' value="buckettool" aria-label="left aligned" sx={{  transition: 'background 150ms ease-in 0s!important', zIndex: '1000!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_bucket')} arrow placement="right">
                                <FormatColorFillRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
                                
                                
                                <ToggleButton className='tButtonAdvanced' value="colorreplacer" aria-label="right aligned" sx={{ transition: 'background 150ms ease-in 0s!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_swap')} arrow placement="right">
                                <SwapHorizontalCircleRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
  
                                <ToggleButton className='tButtonAdvanced' value="colorpicker" aria-label="right aligned" sx={{ transition: 'background 150ms ease-in 0s!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_picker')} arrow placement="right">
                                <ColorizeRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
                                <ToggleButton className='tButtonAdvanced' value="selectiontool" aria-label="right aligned" sx={{ transition: 'background 150ms ease-in 0s!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_selection')} arrow placement="right">
                                <HighlightAltRounded sx={{padding: '8px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
                                <ToggleButton className='tButtonAdvanced' value="linetool" aria-label="right aligned" sx={{ transition: 'background 150ms ease-in 0s!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_line')} arrow placement="right">
                                <HorizontalRuleRounded sx={{transform: 'rotate(315deg)', padding: '8px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
                                <ToggleButton className='tButtonAdvanced' value="squaretool" aria-label="right aligned" sx={{ transition: 'background 150ms ease-in 0s!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_square')} arrow placement="right">
                                <CropSquareRounded sx={{padding: '8px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
                                <ToggleButton className='tButtonAdvanced' value="circletool" aria-label="right aligned" sx={{ transition: 'background 150ms ease-in 0s!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_circle')} arrow placement="right">
                                <RadioButtonUncheckedRounded sx={{padding: '8px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
                                <ToggleButton onClick={this.handleOpenTextTool} className='tButtonAdvanced' value="texttool" aria-label="right aligned" sx={{ transition: 'background 150ms ease-in 0s!important', "&.Mui-selected": {border: "1px solid var(--primary)!important", color: 'var(--primary)!important', background: 'none!important', '&:hover': {background: 'rgba(233, 30, 99, 0.04)!important'}}}}>
                                <Tooltip title={t('advanced.tool_text')} arrow placement="right">
                                <TextFieldsRounded sx={{padding: '8px!important', width: '2em!important', height: '2em!important'}}/>
                                </Tooltip>
                                </ToggleButton>
                              </ToggleButtonGroup>
                              <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
                                <Button disabled={this.state.undo<1} variant='outlined' className='buttonZoom'  aria-label="left aligned" sx={{minWidth: '50px', transition: 'background-color border 150ms ease-in 0s'}} >
                                  <Tooltip title={t('advanced.undo')} arrow placement="right">
                                  <UndoRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}} onClick={this.handleUndoDrawing}/>
                                  </Tooltip>
                                  </Button>
                                  <Button disabled={this.state.undo===this.state.drawCanvasUndoStates.length-1} variant='outlined' className='buttonZoom'  aria-label="left aligned" sx={{minWidth: '50px', transition: 'background-color border 150ms ease-in 0s'}} >
                                  <Tooltip title={t('advanced.redo')} arrow placement="right">
                                  <RedoRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}} onClick={this.handleRedoDrawing}/>
                                  </Tooltip>
                                  </Button>
                                  <Button disabled={this.state.canClear===false || this.state.drawCanvasUndoStates.length===1} variant='outlined' className='buttonZoom'  aria-label="left aligned" sx={{minWidth: '50px', transition: 'background-color border 150ms ease-in 0s'}} >
                                  <Tooltip title={t('advanced.clear')} arrow placement="right">
                                  <ClearRounded sx={{padding: '10px!important', width: '2em!important', height: '2em!important'}} onClick={this.handleClearAllColorsCanvas}/>
                                  </Tooltip>
                                  </Button>
                              </Card>
                  </div>
                <div className='AvanceretRedigeringVærktøjer' style={{visibility: this.state.advancedEdit === false ? 'hidden' : 'inherit', position: this.state.advancedEdit === false ? 'absolute' : 'unset'}}>
                {/* <Typography className='cardOverskrifter' gutterBottom variant="h5" component="div">
                    Værktøj
                    </Typography> */}
                    <div style={this.state.advancedMode==='colorreplacer' ? {position:'absolute', visibility: 'hidden'} : {position: 'initial', visibility: 'inherit'}}>
                            <Typography className='no-selection' variant="h7" component="div" align='center' sx={{marginTop: '10px', mb: '0.35em'}}>
                            {t('advanced.chosen_color')}
                                  </Typography>
                              <div id="chosenColor">
                              <Card className='chosenColorCard' sx={{ overflow: 'initial', maxWidth: '300px', boxShadow: 'box-shadow: 0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content', minWidth: '100px'}}>
                              <table className='chosenColorTable'>
                          <tbody>

                            <tr className='chosenColorTableRow'>
                              <td className='chosenColorTableRow no-selection' style={{ textAlign: "left" }}>{t('pdf_page.table_color')}</td>
                              <td className='chosenColorTableRow no-selection' style={{ textAlign: "right", width: '50px' }}>DMC</td>
                              <td className='chosenColorTableRow no-selection' style={{ textAlign: "right", width: '90px' }}>{t('pdf_page.table_name')}</td>
                            </tr>
                            <Divider variant="middle" className='chosenColorTableDivider' />
                            <tr className='chosenColorTableRow'>
                              <td className='chosenColorTableRow'  style={{ borderRadius: '8px', width: '44px', height: '24px', background: this.state.drawColor, padding: '0px', border: '1px solid rgba(0,0,0,0.12)', display: 'flex', maxWidth: '44px', maxHeight: '24px', overflow: 'hidden', placeItems: 'center' }}><Tooltip title={t('advanced.choose_color')} arrow placement="bottom"><div id='colorPickButton' onClick={this.handleOpenColorPicker} ></div></Tooltip></td>
                              <td className='chosenColorTableRow no-selection' style={{ textAlign: "right",  width: '50px' }}>{this.state.drawColorCode}</td>
                              <td className='chosenColorTableRow no-selection' style={{ textAlign: "right",  width: '90px', textOverflow: 'ellipsis', overflow: 'hidden', height: '1.2em', whiteSpace: 'nowrap', maxWidth: '90px', paddingLeft: '10px' }}>{this.state.drawColorName.split(':')[0]}</td>
                            </tr>
                            </tbody>
                            </table>
                                
                            </Card>
                            </div>
                              </div>
                              {this.state.advancedMode=== 'colorreplacer' && 
                            <div style={{width: '330px', display: 'grid', placeItems: 'center'}}>
                            <Typography className='no-selection' variant="h7" component="div" align='center' sx={{marginTop: '10px', mb: '0.35em'}}>
                                    {t('advanced.tool_swap')}
                                  </Typography>
                                  <Typography className='underskriftTekst' id='underskriftTekst' color="text.secondary" sx={{ m: 0 }}>{t('advanced.tool_swap_description_1')}</Typography>
                            <Card sx={{margin: 'auto', marginTop:'8px', marginBottom: '8px', overflow: 'initial', maxWidth: '700px', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content', minWidth: '100px'}}>
                            
                              
                          
                              <div >
                              <EnhancedTableColorReplacement replaceMode={this.state.replaceMode} data={this.state.dmcArrayDraw} theme={this.props.theme} handleOpenColorPickerReplaceMode={this.handleOpenColorPickerReplaceMode}/>
                              </div>

                            </Card>

                        <div style={this.state.replaceMode===true? {display: 'grid', placeItems: 'center', position: 'initial', visibility: 'inherit'} : {position: 'absolute', visibility: 'hidden'}}>
                          <ArrowDownwardRounded sx={{color: 'var(--primary)'}}/>
                        <Typography className='underskriftTekst no-selection' id='underskriftTekst' color="text.secondary" sx={{ m: 0 }}>{t('advanced.tool_swap_description_2')}</Typography>
                            <Card className='colorPickerReplace' sx={{margin: 'auto', marginTop:'8px', overflow: 'initial', maxWidth: '700px', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content', minWidth: '100px'}}>
                            <Typography className='no-selection' variant="h7" component="div" align='left'>
                                    {t('advanced.color_in_pattern')}
                                  </Typography>
                                  <div id="colorPickerInPatternReplace">
                                  <EnhancedTableDrawColor data={this.state.dmcArrayDraw} theme={this.props.theme} handleSelectColorFromList={this.handleSelectColorFromListCanvas}/>
                                

                                </div>
                                <div >
                                <Typography className='no-selection' variant="h7" component="div" align='left'>
                                {t('advanced.all_dmc')}
                                  </Typography>
                                
                                <Typography className='no-selection' variant="h7" component="div" align='left'>
                                {t('advanced.red')}
                                  </Typography>
                                <div id='redWrapperReplace' >
                                </div>
                                <Typography className='no-selection' variant="h7" component="div" align='left'>
                                {t('advanced.yellow')}
                                </Typography>
                              <div id='yellowWrapperReplace' >
                              
                              </div>
                                
                                <Typography className='no-selection' variant="h7" component="div" align='left'>
                                {t('advanced.green')}
                                </Typography>
                                <div id='greenWrapperReplace' >
                                
                              </div>
                              <Typography className='no-selection' variant="h7" component="div" align='left'>
                              {t('advanced.blue')}
                                </Typography>
                              <div id='blueWrapperReplace' >
                              
                              </div>
                              <Typography className='no-selection' variant="h7" component="div" align='left'>
                              {t('advanced.violet')}
                                </Typography>
                              <div id='violetWrapperReplace' >
                              
                              </div>
                              <Typography className='no-selection' variant="h7" component="div" align='left'>
                              {t('advanced.brown')}
                                </Typography>
                              <div id='brownWrapperReplace' >
                              
                              </div>
                              <Typography className='no-selection' variant="h7" component="div" align='left'>
                              {t('advanced.grayscale')}
                                </Typography>
                              <div id='grayWrapperReplace' >
                              
                              </div>
                              <Typography className='no-selection' variant="h7" component="div" align='left'>
                              {t('advanced.no_color')}
                                </Typography>
                              <div id='noColorWrapperReplace' >
                              
                              </div>
                              </div>
                            </Card>
                            </div>
                           
                            </div>
                            }
                            {(this.state.advancedMode=== 'draw' || this.state.advancedMode=== 'eraser') && 
                            <div>
                            <Typography className='no-selection' variant="h7" component="div" align='center' sx={{marginTop: '20px', mb: '0.35em'}}>
                                    {this.state.advancedMode=== 'draw' ? t('advanced.tool_pen') : t('advanced.tool_eraser')}
                                  </Typography>
                            <Card sx={{padding: '20px',margin: 'auto', overflow: 'initial', maxWidth: '700px', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content', minWidth: '100px'}}>
                            
                              
                          
                              <div style={{width: '160px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FiberManualRecordRounded sx={{marginRight: '14px', fontSize: '1rem!important', opacity: '0.2!important'}}/>
                               <Slider
                                  aria-label="Size"
                                  value={this.state.drawSize}
                                  onChange={this.handleDrawSizeSliderChange}
                                  valueLabelDisplay="auto"
                                  step={1}
                                  marks
                                  min={1}
                                  max={3}
                                /> 
                               {/*  <Switch sx={{color: 'var(--primary)', '.MuiSwitch-switchBase': {color: 'var(--primary)', ':hover': {backgroundColor: 'rgba(233, 30, 99, 0.04)!important'}}, '.MuiSwitch-track': {backgroundColor:'var(--primary)'}}} checked={this.state.drawSize===2} onClick={this.handleDrawSizeSwitchChange}/> */}
                                <CircleRounded sx={{marginLeft: '14px', opacity: '0.2!important'}}/>
                              </div>

                            </Card>
                            </div>
                            }
                            {(this.state.advancedMode=== 'squaretool') && 
                            <div>
                            <Typography className='no-selection' variant="h7" component="div" align='center' sx={{marginTop: '20px', mb: '0.35em'}}>
                                    { t('advanced.tool_square')}
                                  </Typography>
                            <Card sx={{padding: '20px',margin: 'auto', overflow: 'initial', maxWidth: '700px', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content', minWidth: '100px'}}>
                            
                              
                          
                              <div style={{width: '160px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <SquareRounded sx={{marginRight: '14px', opacity: '0.2!important'}}/>
                                 <Switch sx={{color: 'var(--primary)', '.MuiSwitch-switchBase': {color: 'var(--primary)', ':hover': {backgroundColor: 'rgba(233, 30, 99, 0.04)!important'}}, '.MuiSwitch-track': {backgroundColor:'var(--primary)'}}} checked={this.state.squareToolOutline} onClick={this.handleSquareToolOutlineChange}/> 
                                <CheckBoxOutlineBlankRounded sx={{marginLeft: '14px', opacity: '0.2!important'}}/>
                              </div>

                            </Card>
                            </div>
                            }
                            {(this.state.advancedMode=== 'circletool') && 
                            <div>
                            <Typography className='no-selection' variant="h7" component="div" align='center' sx={{marginTop: '20px', mb: '0.35em'}}>
                                    { t('advanced.tool_circle')}
                                  </Typography>
                            <Card sx={{padding: '20px',margin: 'auto', overflow: 'initial', maxWidth: '700px', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content', minWidth: '100px'}}>
                            
                              
                          
                              <div style={{width: '160px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <CircleRounded sx={{marginRight: '14px', opacity: '0.2!important'}}/>
                                 <Switch sx={{color: 'var(--primary)', '.MuiSwitch-switchBase': {color: 'var(--primary)', ':hover': {backgroundColor: 'rgba(233, 30, 99, 0.04)!important'}}, '.MuiSwitch-track': {backgroundColor:'var(--primary)'}}} checked={this.state.circleToolOutline} onClick={this.handleCircleToolOutlineChange}/> 
                                <RadioButtonUncheckedRounded sx={{marginLeft: '14px', opacity: '0.2!important'}}/>
                              </div>

                            </Card>
                            </div>
                            }
                            {(this.state.advancedMode=== 'selectiontool') && 
                            <div>
                            <Typography className='no-selection' variant="h7" component="div" align='center' sx={{marginTop: '20px', mb: '0.35em'}}>
                                    { t('advanced.tool_selection')}
                                  </Typography>
                            <Card sx={{padding: '20px',margin: 'auto', overflow: 'initial', maxWidth: '700px', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content', minWidth: '100px'}}>
                            
                              
                          
                              <div style={{width: '190px', display: 'grid', placeItems: 'center'}}>
                              <Button disabled={!this.state.selectBox} className='no-flickr' size='large' variant="outlined" startIcon={<RotateRightRounded />} onClick={this.handleRotateSelectionRight} sx={{ width: '184px', justifyContent: 'left', transition: 'background-color border 150ms ease-in 0s', margin: '8px 0px',textTransform: 'none' }}>{t("advanced.selection_90cw")}</Button>
                              <Button disabled={!this.state.selectBox} className='no-flickr' size='large' variant="outlined" startIcon={<RotateLeftRounded />} onClick={this.handleRotateSelectionLeft} sx={{ width: '184px', justifyContent: 'left', transition: 'background-color border 150ms ease-in 0s', margin: '8px 0px', textTransform: 'none'}}>{t("advanced.selection_90ccw")}</Button>
                              <Button disabled={!this.state.selectBox} className='no-flickr' size='large' variant="outlined" startIcon={<FlipRounded />} onClick={this.handleFlipSelectionHorizontally} sx={{ width: '184px', justifyContent: 'left', transition: 'background-color border 150ms ease-in 0s', margin: '8px 0px',textTransform: 'none' }}>{t("advanced.selection_flip_hor")}</Button>
                              <Button disabled={!this.state.selectBox} className='no-flickr' size='large' variant="outlined" startIcon={<FlipRounded sx={{ transform: 'rotate(90deg)' }} />} onClick={this.handleFlipSelectionVertically} sx={{ width: '184px', justifyContent: 'left', transition: 'background-color border 150ms ease-in 0s', margin: '8px 0px', textTransform: 'none'}}>{t("advanced.selection_flip_ver")}</Button>
                              <Button disabled={!this.state.selectBox} className='no-flickr' size='large' variant="outlined" startIcon={<ContentCopyRounded />} onClick={this.handleCopySelectionButton} sx={{ width: '184px', justifyContent: 'left', transition: 'background-color border 150ms ease-in 0s', margin: '8px 0px',textTransform: 'none' }}>{t("advanced.selection_copy")}</Button>
                              <Button disabled={this.state.selectionBoxCopyData === null} className='no-flickr' size='large' variant="outlined" startIcon={<ContentPasteRounded />} onClick={this.handlePasteSelectionButton} sx={{ width: '184px', justifyContent: 'left', transition: 'background-color border 150ms ease-in 0s', margin: '8px 0px', textTransform: 'none'}}>{t("advanced.selection_paste")}</Button>
                              <Button disabled={!this.state.selectBox} className='no-flickr' size='large' variant="outlined" startIcon={<DeleteRounded />} onClick={this.handleDeleteSelection} sx={{ width: '184px', justifyContent: 'left', transition: 'background-color border 150ms ease-in 0s', margin: '8px 0px',textTransform: 'none' }}>{t("advanced.selection_delete")}</Button>
                              </div>

                            </Card>
                            </div>
                            }


<Dialog
      disableScrollLock={true}
                open={this.state.openTextTool}
                keepMounted
                onClose={this.handleCloseTextTool}
                aria-describedby="alert-dialog-slide-description"
                /* sx={{zIndex: '2300'}} */
                
                 PaperProps={{
                  sx:{maxWidth: '700px'}
                }} 
              >
                <DialogTitle sx={{minWidth:'600px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '3', borderBottom: '1px solid rgba(0 0 0 / 10%)'}}><div style={{display: 'flex', alignItems:'center'}}><TextFieldsRounded sx={{mr: '10px'}}/>{t("advanced.tool_text")}</div><Button startIcon={<Close/>} onClick={this.handleCloseTextTool}><span style={{marginTop: '2px'}}>{t('common.close')}</span></Button></DialogTitle>
                <Box component="form" noValidate onSubmit={(e)=>{this.handleCloseAndInsertText(e); return false} } >
                <DialogContent className="colorPickerDialog" dividers={false} sx={{padding: '20px', gridGap: '20px', paddingTop: '20px!important', border: 'none!important', display: 'grid', placeItems: 'center'}}>
                <FormControl className='inputTextTool'>
                <InputLabel sx={{fontFamily: "'Roboto','Helvetica','Arial',sans-serif!important", padding: '0px 4px', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))', backgroundColor: this.props.theme.palette.mode === 'dark' ? 'rgb(45 45 45)' : 'white'}} id="select-font-label">{t('advanced.tool_text_font')}</InputLabel>
                            <Select
                            value={this.state.textToolFontSelected}
                            onChange={this.handleSelectFontChange}
                            labelId="select-font-label"
                              MenuProps={{ disableScrollLock: true, PaperProps: { sx: { maxHeight: 300 } } }}
                              id="font"
                              sx={{fontFamily: this.state.textToolFontSelected}}
                            >
                              <MenuItem value={"Arial,sans-serif"} sx={{fontFamily: "Arial,sans-serif"}}>Arial</MenuItem>
                              <MenuItem value={"Arial Black,sans-serif"} sx={{fontFamily: "Arial Black,sans-serif"}}>Arial Black</MenuItem>
                              <MenuItem value={"Verdana,sans-serif"} sx={{fontFamily: "Verdana,sans-serif"}}>Verdana</MenuItem>
                              <MenuItem value={"Tahoma,sans-serif"} sx={{fontFamily: "Tahoma,sans-serif"}}>Tahoma</MenuItem>
                              <MenuItem value={"'Trebuchet MS',sans-serif"} sx={{fontFamily: "'Trebuchet MS',sans-serif"}}>Trebuchet MS</MenuItem>
                              <MenuItem value={"'Times New Roman',serif"} sx={{fontFamily: "'Times New Roman',serif"}}>Times New Roman</MenuItem>
                              <MenuItem value={"Georgia,serif"} sx={{fontFamily: "Georgia,serif"}}>Georgia</MenuItem>
                              <MenuItem value={"Garamond,serif"} sx={{fontFamily: "Garamond,serif"}}>Garamond</MenuItem>
                              <MenuItem value={"'Courier New',monospace"} sx={{fontFamily: "'Courier New',monospace"}}>Courier New</MenuItem>
                              <MenuItem value={"'Brush Script MT',cursive"} sx={{fontFamily: "'Brush Script MT',cursive"}}>Brush Script MT</MenuItem>
                              <MenuItem value={"'Roboto',sans-serif"} sx={{fontFamily: "'Roboto',sans-serif"}}>Roboto</MenuItem>
                              <MenuItem value={"'Lato',sans-serif"} sx={{fontFamily: "'Lato',sans-serif"}}>Lato</MenuItem>
                              <MenuItem value={"'Comic Sans MS','Comic Sans',cursive"} sx={{fontFamily: "'Comic Sans MS','Comic Sans',cursive"}}>Comic Sans MS</MenuItem>
                              <MenuItem value={"'Concert One',sans-serif"} sx={{fontFamily: "'Concert One',sans-serif"}}>Concert One</MenuItem>
                              <MenuItem value={"'Shadows Into Light',cursive"} sx={{fontFamily: "'Shadows Into Light',cursive"}}>Shadows Into Light</MenuItem>
                              <MenuItem value={"'Architects Daughter',cursive"} sx={{fontFamily: "'Architects Daughter',cursive"}}>Architects Daughter</MenuItem>
                              <MenuItem value={"'Satisfy',cursive"} sx={{fontFamily: "'Satisfy',cursive"}}>Satisfy</MenuItem>
                              <MenuItem value={"'Pacifico',cursive"} sx={{fontFamily: "'Pacifico',cursive"}}>Pacifico</MenuItem>
                              <MenuItem value={"'Kaushan Script',cursive"} sx={{fontFamily: "'Kaushan Script',cursive"}}>Kaushan Script</MenuItem>
                              <MenuItem value={"'Abril Fatface',serif"} sx={{fontFamily: "'Abril Fatface',serif"}}>Abril Fatface</MenuItem>
                              <MenuItem value={"'Pirata One',serif"} sx={{fontFamily: "'Pirata One',serif"}}>Pirata One</MenuItem>
                              <MenuItem value={"'Creepster',serif"} sx={{fontFamily: "'Creepster',serif"}}>Creepster</MenuItem>
                              <MenuItem value={"'Silkscreen',monospace"} sx={{fontFamily: "'Silkscreen',monospace"}}>Silkscreen</MenuItem>
                              <MenuItem value={"'Press Start 2P',monospace"} sx={{fontFamily: "'Press Start 2P',monospace"}}>Press Start 2P</MenuItem>
                              <MenuItem value={"'Henny Penny',serif"} sx={{fontFamily: "'Henny Penny',serif"}}>Henny Penny</MenuItem>
                              <MenuItem value={"'Knewave',sans-serif"} sx={{fontFamily: "'Knewave',sans-serif"}}>Knewave</MenuItem>
                              <MenuItem value={"'Pattaya',cursive"} sx={{fontFamily: "'Pattaya',cursive"}}>Pattaya</MenuItem>
                              <MenuItem value={"'Chewy',sans-serif"} sx={{fontFamily: "'Chewy',sans-serif"}}>Chewy</MenuItem>
                            </Select>
                            </FormControl>
                          <TextField
                          className='inputTextTool'
                              key="inputText"
                              /* value={this.state.shareName}
                              onChange={(e)=>{this.handleChangeShareInput(e,"name")}} */
                              /* inputRef={this.shareNameRef} */
                              fullWidth
                              label={t('advanced.tool_text_text')}
                              value={this.state.textToolText}
                              onChange={(e)=>{this.setState({textToolText: e.target.value},()=>{this.handleUpdateTextTool();})}}/>
                              <Typography className='no-selection' variant="h7" component="div" align='left' sx={{width: '600px'}}>
                              {t('advanced.tool_text_size')}
                                  </Typography>
                              <TextSizeSlider handleSetTextSize={this.handleSetTextSize} />
                              <Typography className='no-selection' variant="h7" component="div" align='left' sx={{width: '600px'}}>
                              {t('advanced.tool_text_preview')}
                                  </Typography>
                                  <div id='textToolCanvasWrapper'>
                                  {/* <div id='textToolCanvasBack' width={600} height={300}></div> */}
                            <canvas id='textToolCanvas' width={600} height={300}/>
                            <canvas id='textToolCanvasTextTemp' width={700} height={100}/>
                           {/*  <canvas id='textToolCanvasRulers' width={600} height={300}/> */}
                            </div>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button startIcon={<Close />} size='large'  onClick={this.handleCloseTextTool}>{t('common.back_button')}</Button>
                <Button type='submit' startIcon={<Check />} size='large' variant="contained" /* onClick={this.handleResizePattern} */ sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t("advanced.tool_text")}</Button>
              </DialogActions>
              </Box>
                            </Dialog>

<Dialog
      disableScrollLock={true}
                open={this.state.openResizer}
                keepMounted
                onClose={this.handleCloseResizer}
                aria-describedby="alert-dialog-slide-description"
                sx={{zIndex: '2300'}}
                
/*                 PaperProps={{
                  sx:{height: 'calc(100% - 800px)'}
                }} */
              >
                <DialogTitle sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '3', borderBottom: '1px solid rgba(0 0 0 / 10%)'}}><div style={{display: 'flex', alignItems:'center'}}><PhotoSizeSelectLargeRounded sx={{mr: '10px'}}/>{t("advanced.resizeCanvas_title")}</div><Button startIcon={<Close/>} onClick={this.handleCloseResizer}><span style={{marginTop: '2px'}}>{t('common.close')}</span></Button></DialogTitle>
                <Box component="form" noValidate onSubmit={(e)=>{this.handleResizePattern(e); return false} } >
                <DialogContent className="colorPickerDialog" dividers={false} sx={{padding: '20px', paddingTop: '20px!important', border: 'none!important'}}>
                           <div>
                           <Typography className='no-selection' variant="h7" component="div" align='center' sx={{marginTop: '20px', mb: '0.35em'}}>
                                    {t("advanced.resizeCanvas_description")+this.state.cropWidth+" x "+this.state.cropHeight}
                                  </Typography>
                           </div>
                           <div style={{margin: '20px'}}>
                           <div style={{display: 'flex', justifyContent: 'center'}}>
                           <TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.widthInput} */
                              value={this.state.resizeValueTop}
                             /*  error={this.state.widthInputError} */
                              id="resizeInputTop"
                              label={t("advanced.resizeCanvas_top")}
                              className='inputResizer'
                              sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);' }}
                              onChange={(e)=>{this.validateResizerInput(e,"top")}}
                            />
                           </div>
                           <div style={{display: 'flex', alignItems: 'center'}}>
                           <TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.widthInput} */
                              value={this.state.resizeValueLeft}
                             /*  error={this.state.widthInputError} */
                              id="resizeInputLeft"
                              label={t("advanced.resizeCanvas_left")}
                              className='inputResizer'
                              sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);' }}
                              onChange={(e)=>{this.validateResizerInput(e,"left")}}
                            /> 
<div className='squareResizer'>
<TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.widthInput} */
                              value={this.state.resizeValueWidth}
                               error={this.state.resizeInputWidthError}  
                              id="resizeInputWidth"
                              label={t("advanced.resizeCanvas_width")}
                              className='inputResizer'
                              sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);' }}
                              onChange={(e)=>{this.validateResizerCentralInput(e,"width")}} 
                            />
                            <div>
                              <Typography  gutterBottom variant="h7" color={"text.secondary"} sx={{ m: 1 }}>x</Typography>
                            </div>
                            <TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.widthInput} */
                              value={this.state.resizeValueHeight}
                              error={this.state.resizeInputHeightError}  
                              id="resizeInputHeight"
                              label={t("advanced.resizeCanvas_height")}
                              className='inputResizer'
                              sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);' }}
                               onChange={(e)=>{this.validateResizerCentralInput(e,"height")}} 
                            />
</div>
<TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.widthInput} */
                              value={this.state.resizeValueRight}
                              /* error={this.state.widthInputError}  */
                              id="resizeInputRight"
                              label={t("advanced.resizeCanvas_right")}
                              className='inputResizer'
                              sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);' }}
                              onChange={(e)=>{this.validateResizerInput(e,"right")}}
                            />
</div>
<div style={{display: 'flex', justifyContent: 'center'}}>
<TextField
                              size='small'
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                              /* defaultValue={this.state.widthInput} */
                              value={this.state.resizeValueBottom}
                              /* error={this.state.widthInputError} */
                              id="resizeInputBottom"
                              label={t("advanced.resizeCanvas_bottom")}
                              className='inputResizer'
                              sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);' }}
                              onChange={(e)=>{this.validateResizerInput(e,"bottom")}}
                            />
</div>
</div>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button startIcon={<Close />} size='large'  onClick={this.handleCloseResizer}>{t('common.back_button')}</Button>
                <Button type='submit' disabled={this.state.resizeInputWidthError || this.state.resizeInputHeightError} startIcon={<Check />} size='large' variant="contained" /* onClick={this.handleResizePattern} */ sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('saved_patterns_menu.done')}</Button>
              </DialogActions>
              </Box>
                            </Dialog>
                            
<Dialog
      disableScrollLock={true}
                open={this.state.openColorPicker}
                keepMounted
                onClose={this.handleCloseColorPicker}
                aria-describedby="alert-dialog-slide-description"
                sx={{zIndex: '2300'}}
                
                PaperProps={{
                  sx:{height: 'calc(100% - 800px)', minHeight: '700px'}
                }}
              >
                <DialogTitle sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '3', borderBottom: '1px solid rgba(0 0 0 / 10%)'}}><div style={{display: 'flex', alignItems:'center'}}><PaletteRounded sx={{mr: '10px'}}/>{t('advanced.choose_color_title')}</div><Button startIcon={<Close/>} onClick={this.handleCloseColorPicker}><span style={{marginTop: '2px'}}>{t('common.close')}</span></Button></DialogTitle>
                <DialogContent className="colorPickerDialog" dividers={false} sx={{padding: '20px', paddingTop: '20px!important', border: 'none!important'}}>
                            {/* <Card className='AdvancedEditCardColorPicker' sx={{ visibility: 'hidden', overflow: 'initial', maxWidth: '700px', boxShadow: '0px 4px 64px rgb(0 0 0 / 20%);', width: 'fit-content', minWidth: '100px'}}> */}
 
                              <Typography variant="h7" component="div" align='left'>
                              {t('advanced.color_in_pattern')}
                                  </Typography>
                                  <div id="colorPickerInPattern">
                                  <EnhancedTableDrawColor data={this.state.dmcArrayDraw} theme={this.props.theme} handleSelectColorFromList={this.handleSelectColorFromListCanvas}/>
                                

                                </div>
                                <div >
                                <Typography variant="h7" component="div" align='left'>
                                {t('advanced.all_dmc')}
                                  </Typography>

                            {/*       {this.state.colorClusters.map((color) => (
                                    <div>
                                      <Typography variant="h7" component="div" align='left'>
                                    {color.name}
                                  </Typography>
                                  <div className='colorPickerWrapper'>
                                  {color.colors.map((col) => (
                                      <div className='colorPickerDiv' style={{background: col._primaryColor}}></div>
                                  ))}
                                  </div>
                                    </div>
                                  ))}
                              <div id="colorPicker">

                                

                                </div> */}
                                
                                <Typography variant="h7" component="div" align='left'>
                                {t('advanced.red')}
                                  </Typography>
                                <div id='redWrapper' >
                                </div>
                                <Typography variant="h7" component="div" align='left'>
                                {t('advanced.yellow')}
                                </Typography>
                              <div id='yellowWrapper' >
                              
                              </div>
                                
                                <Typography variant="h7" component="div" align='left'>
                                {t('advanced.green')}
                                </Typography>
                                <div id='greenWrapper' >
                                
                              </div>
                              <Typography variant="h7" component="div" align='left'>
                              {t('advanced.blue')}
                                </Typography>
                              <div id='blueWrapper' >
                              
                              </div>
                              <Typography variant="h7" component="div" align='left'>
                              {t('advanced.violet')}
                                </Typography>
                              <div id='violetWrapper' >
                              
                              </div>
                              <Typography variant="h7" component="div" align='left'>
                              {t('advanced.brown')}
                                </Typography>
                              <div id='brownWrapper' >
                              
                              </div>
                              <Typography variant="h7" component="div" align='left'>
                              {t('advanced.grayscale')}
                                </Typography>
                              <div id='grayWrapper' >
                              
                              </div>
                              </div>

                            {/* </Card> */}
                            </DialogContent>
                            </Dialog>
                            
                            
                            
                  </div>
              </div>
              <div>

              </div>
              {/* <canvas id='donePixelCanvas'>

              </canvas> */}
              <div className="hovedKnapper hovedKnapperSticky">
              <Button className='no-flickr' size='large' variant="outlined" startIcon={<ArrowBack />} onClick={this.state.savedPatternMode===false ? this.state.advancedEdit===false ? this.handleOpenAreYouSure :  this.state.createBlankCanvasMode===true ? this.handleClearToDefault : this.state.undo>0 ? this.handleOpenAreYouSureGoingBackFromAdvancedEdit: this.state.createBlankCanvasMode===true ? this.handleClearToDefault : this.handleCloseAdvancedEdit :  this.handleConvertPixelDrawingToCanvas} sx={{ transition: 'background-color border 150ms ease-in 0s', margin: '8px', }}>{t('common.back_button')}</Button>
              
                <Button disabled={this.state.showLoading ? true : false} className='doneButtonSmall' size='large' variant="contained" startIcon={<Done />} onClick={this.handleToggleDonePage} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button_small')}</Button>
              
                { this.state.savedPatternMode===false ?
               
                  <Button disabled={this.state.showLoading ? true : false} className='doneButtonLarge' size='large' variant="contained" startIcon={<Done />} onClick={this.state.advancedEdit===false ? this.handleToggleDonePage : this.handleConvertPixelDrawingToCanvas} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button')}</Button> 
                 
                  :
                 
                  <Button className='doneButtonLarge' size='large' variant="contained" startIcon={<Done />} onClick={this.handleConvertPixelDrawingToCanvas} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('create_page_2.done_editing_button')}</Button>
  }
              </div>
            </div>
            {donePage ? <div>

            </div> :
              <div>
                <div style={this.state.displayStyleDonePage}>

                  <div className="no-print">
                  <Dialog
      disableScrollLock={true}
                open={this.state.openShare}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                
                PaperProps={{
                  sx:{ minHeight: '300px'}
                }}
              >
                <DialogTitle sx={{minWidth:'300px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '3', borderBottom: '1px solid rgba(0 0 0 / 10%)'}}><div style={{display: 'flex', alignItems:'center'}}><StarRounded sx={{mr: '10px'}}/>{t('share_dialog.share_title')}</div><Button disabled={this.state.loadingShare} startIcon={<Close/>} onClick={this.handleCloseShare}><span style={{marginTop: '2px' }}>{t('common.close')}</span></Button></DialogTitle>
                <DialogContent  dividers={false}  sx={{ border: 'none!important', padding: '0px!important'}}>
                
                            <div className='sharePreviewImageWrapper' style={{background: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[400] : this.props.theme.palette.grey[900] }}>
                              <img src={this.state.sharePreviewImg} className='sharePreviewImage'/>
                            </div>
                          
                <Box component="form" noValidate onSubmit={(e)=>{this.handleSharePatternSavedPattern(e)}} sx={{ m: 'auto', width: 'calc(100% - 37px)', padding: '20px' }}>
                        <Grid container spacing={2}>
                        
                          <Grid item xs={12} sx={{mt: 1}}>
                            <TextField
                              key="name"
                              /* value={this.state.shareName}
                              onChange={(e)=>{this.handleChangeShareInput(e,"name")}} */
                              /* inputRef={this.shareNameRef} */
                              error={this.state.shareError !== null ? true : false}
                              helperText={this.state.shareError}
                              required
                              fullWidth
                              id="share_name"
                              label={t('share_dialog.name')}
                              name="name"
                              
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              ref="shareDescription"
                              key="description"
                              /* inputRef={this.shareDescriptionRef} */
                              /* value={this.state.shareDescription}
                              onChange={(e)=>{this.handleChangeShareInput(e,"description")}} */
                              multiline
                              minRows={4}
                              maxRows={12}
                              fullWidth
                              id="share_description"
                              label={t('share_dialog.desc')}
                              name="description"
                              
                            />
                          </Grid>
                          <Grid item xs={12}>
                              <div className='shareCategoryWrapper' style={this.state.pickedCategories.length===0 ? {padding: '14.5px 14px', border: 'none'} : {padding: '11px 14px 3px 14px', borderColor: this.props.theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}}>
                                {this.state.pickedCategories.length===0 && <span className='shareCategoryTextfieldText' style={{color: this.props.theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.7)'}}>{t('share_dialog.categories')}</span>}
                              {this.state.pickedCategories.map((category, id) => (

                                  <Chip key={"pickedCat"+id} size='medium' sx={{mr: 1, mb: 1, width: '90px', justifyContent: 'space-between', "& .MuiChip-label": {paddingRight: '7px'}}} label={this.state.categoryTranslator[category]} variant="outlined" onDelete={this.handleRemoveCategory}/>

                                  ))}
                              </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div>
                              {this.state.shareCategories.map((category, id) => (

                                <Chip key={"cat"+id} size='medium' color={category.checked ? 'primary' : 'default'} sx={{mr: 1, mb: 1, padding: category.checked ? '1px' : '0px'}} label={this.state.categoryTranslator[category.name]} variant={category.checked ? "filled" : "outlined"} onClick={this.handleSelectShareCategory}/>

                              ))}
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div>
                            {/* <Button
                          fullWidth
                          size='large'
                          disabled={this.state.loadingShare}
                          variant="outlined"
                          startIcon={ <UploadRounded/>}
                          sx={{ mt: 0, mb: 1 }}
                        >Upload et billede af det færdige broderi
                          </Button> */}
                          <UploadShareImage setUploadImage={this.handleSetUploadShareImage} />
                            </div>
                          </Grid>
                        </Grid>
                        
                        
                        
                        <Button
                          type="submit"
                          fullWidth
                          size='large'
                          disabled={this.state.loadingShare}
                          variant="contained"
                          startIcon={!this.state.loadingShare && <StarRounded/>}
                          sx={{ mt: 3, mb: 3, backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.6rem 1rem 0rem, rgb(233 30 99 / 20%) 0rem 0.4rem 1rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.6rem 1.5rem 0rem;' } }}
                        >
                           {this.state.loadingShare ? <CircularProgress size={26.25} sx={{ color: 'white' }} /> : t('share_dialog.share')}
                        </Button>
                        
                        <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center', mt: '15px' }}>
                          <Grid item sx={{ display: 'flex' }}>
                            <Typography variant="body2" color="text.secondary" >
                              {t('share_dialog.remember')}</Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ alignItems: 'baseline', justifyContent: 'center', mt: '15px' }}>
                          <Grid item sx={{ display: 'flex' }}>
                            <Typography variant="body2" color="text.secondary">
                            {t('share_dialog.policy')}<Link sx={{cursor: 'pointer'}} onClick={()=>{window.open("https://www.embrodia.com/privacypolicy", "_blank");}} variant="body2">
                              {t('common.privacypolicy')}
                              </Link> {t('create_profile_email.by_creating_2')} <Link sx={{cursor: 'pointer'}} onClick={()=>{window.open("https://www.embrodia.com/cookiepolicy", "_blank");}} variant="body2" >
                              {t('common.cookiepolicy')}
                              </Link></Typography>
                          </Grid>
                        </Grid>
                      </Box>
                </DialogContent>
              </Dialog>
                    <Dialog
                      disableScrollLock={true}
                      className='no-print'
                      open={this.state.loadingCreatePDF}
                      keepMounted
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <Typography gutterBottom variant="h5" component="h1" align='center' sx={{ m: 0 }}>{this.state.savedPatternMode ? t('create_page_3.loading_title_1') : this.props.freeMode === false ? t('create_page_3.loading_title_2') : t('create_page_3.loading_title_1')}</Typography>
                        {/* <span id="pdfProgress">{this.state.createPDFProgress + "%"}</span> */}
                        <div className="progressDiv">
                          <CircularProgress disableShrink width={80} height={80} sx={{ height: '80px!important', width: '80px!important' }} />

                        </div>
                        <Typography variant="h7" component="div" align='center'>
                          {t('create_page_3.loading_description_1')}<br></br>{t('create_page_3.loading_description_2')}<br></br>{t('create_page_3.loading_description_3')}
                        </Typography>
                      </DialogContent>
                    </Dialog>
                  </div>
                  <div className="overskrift">
                    <div>
                      <Typography className='overskriftTekst' gutterBottom variant="h4" component="h1" sx={{ m: 0 }}>{t('create_page_3.title')}</Typography>
                    </div>
                    <div>
                      <Typography className='underskriftTekst' color="text.secondary" sx={{ m: 0 }}>{t('create_page_3.description_1')}<br></br>{t('create_page_3.description_2')}<br></br>{this.state.savedPatternMode ? '' : ''/* this.props.freeMode===false ? t('create_page_3.description_3') : t('create_page_3.description_3_free') */}</Typography>
                    </div>
                  </div>

                  <div className='topNavigationWrapper'>
                  <div className='topNavigationButtonWrapper' style={{justifyContent: 'flex-start'}}>
                  <Button className='no-print' size='large' variant="outlined" startIcon={this.state.savedPatternMode ? <HomeRounded/> : <ArrowBack />} onClick={ this.state.savedPatternMode ?  this.handleClearToDefault : this.state.wasInAdvancedEdit ? this.handleGoBackToAdvancedEdit : this.handleToggleDonePage} sx={{ transition: 'background-color border 150ms ease-in 0s', margin: '8px', }}>{t('common.back_button')}</Button>
                    </div>
                    <div className='topNavigationButtonWrapper buttonSmallWrapper' style={{justifyContent: 'flex-end'}}>
                    <Button className='no-print doneButtonSmall' size='large' variant="contained" startIcon={this.state.loadingCreatePDF ? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important', color: "#fff" }} /> : <CheckCircleRounded />} onClick={this.handleCheckBoughtPatterns} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}> {this.props.freeMode===false ? this.state.loadingCreatePDF ? t('create_page_3.saving_small') : t('create_page_3.save_button_small') : this.state.loadingCreatePDF ? t('create_page_3.loading') : t('create_page_3.save_button_small_free')}</Button>
                    </div>
                    <div className='topNavigationButtonWrapper' style={{justifyContent: 'flex-end'}}>
                    <Button className='no-print doneButtonLarge' size='large' variant="contained" startIcon={this.state.loadingCreatePDF ? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important', color: "#fff" }} /> : <CheckCircleRounded />} onClick={this.handleCheckBoughtPatterns} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}> 
                   
                    {
                    this.state.madeChangeSavedPattern===false ?
                    this.props.freeMode===false ? 
                    this.state.savedPatternMode ? 
                    this.state.loadingCreatePDF ? t('create_page_3.loading') : t('create_page_3.print') 
                    : this.state.loadingCreatePDF ? t('create_page_3.saving') : t('create_page_3.save_button') 
                    : this.state.savedPatternMode ? 
                    this.state.loadingCreatePDF ? t('create_page_3.loading') : t('create_page_3.print') 
                    : this.state.loadingCreatePDF ? t('create_page_3.loading') : t('create_page_3.save_button_free')
                  : this.state.loadingCreatePDF ? t('create_page_3.saving') : t('create_page_3.save_button') }
                    </Button>
                    </div>
                  </div>
                  {/* <canvas id='doneCanvas' style={{width: "600px", height: "600px", position: 'relative'}}></canvas> */}

                  {/* <GridLines className="grid-area"  cellWidth={(1/this.state.cropWidth)*600} lineColor={'black'} strokeWidth={.2} dasharray="4">
            <GridLines className="grid-area" cellWidth={600/2} strokeWidth={2} lineColor={'red'} cellWidth2={((1/this.state.cropWidth)*600)*10} strokeWidth2={.2} lineColor2={'black'} >
            
           
            <canvas style={{width: "600px", height: "594px", position: 'relative'}}></canvas>
          </GridLines>
          </GridLines> */}

                  <div className="oversigtTop">
                    <div className={"patternPreviewDone"}>
                      <Typography className='cardOverskrifter' gutterBottom variant="h5" component="div">
                      {t('create_page_3.info_title')}
                      </Typography>
                      <Card className='resultCard'>
                        <div className="quantCanvasBackground"></div>
                        <div id="patternPreviewDoneDiv">

                        </div>
                        {/* <GridLines className="grid-area"  cellWidth={(1/this.state.cropWidth)*600} lineColor={'black'} strokeWidth={.2} dasharray="4">
            <GridLines className="grid-area" cellWidth={600/2} strokeWidth={2} lineColor={'red'} cellWidth2={((1/this.state.cropWidth)*600)*10} strokeWidth2={.2} lineColor2={'black'} >
            
           
            <canvas style={{width: "600px", height: "594px", position: 'relative'}}></canvas>
          </GridLines>
          </GridLines> */}
                        <div className='quantCanvas' style={{position: this.state.advancedEdit === false ? "relative" : "absolute"}}></div>
                        <table style={this.state.savedPatternMode ? this.state.isSubscribed ? {width:'100%', padding: '40px 40px', top: '-3px', paddingBottom: '10px'} : {width:'100%', padding: '40px 40px', top: '-3px'} : {width:'100%', padding: '40px 40px', top: '-3px'}}>
                          <tbody>

                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_stitchcount')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{this.state.wasInAdvancedEdit===true ? this.state.totalStitches : this.state.shareDatabaseRef ? this.state.shareDatabaseRef.totalStitches : this.state.cropHeight*this.state.cropWidth}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDivider' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_widthstitch')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{this.state.cropWidth}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDivider' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_heightstitch')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{this.state.cropHeight}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDivider' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_fabric')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{this.state.selectBroderistofName}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDivider' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_widthcm')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{Math.round((this.state.cropWidth / this.state.selectBroderistof) * 10) / 10}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDivider' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_heightcm')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{Math.round((this.state.cropHeight / this.state.selectBroderistof) * 10) / 10}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDivider' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_widthinch')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{Math.round((this.state.cropWidth / (this.state.selectBroderistof*2.54)) * 10) / 10}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDivider' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_heightinch')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{Math.round((this.state.cropHeight / (this.state.selectBroderistof*2.54)) * 10) / 10}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDivider' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_colorcount')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{this.state.dmcArray.length}</td>
                            </tr>
                            <Divider variant="middle" className='infoTableDivider' />
                            <tr className='infoTableRow'>
                              <td className='infoTableRow' style={{ textAlign: "left" }}>{t('create_page_3.info_pagecount')}</td>
                              <td className='infoTableRow' style={{ textAlign: "right" }}>{this.state.a4PagesArray.length}</td>
                            </tr>

                          </tbody>
                        </table>
                        {this.state.savedPatternMode && this.state.isSubscribed && this.state.noEditOpen && <div className='advancedEditButtonDiv'>
                          <Button onClick={this.handleOpenAdvancedEditSavedPattern} startIcon={<EditRounded/>} size='large' variant="outlined" sx={{width: '284px', margin: 'auto',  transition: 'background-color border 150ms ease-in 0s', marginBottom: '18px', marginTop: '2px' }}>{t('advanced.title')}</Button>
                          {this.state.isASharedPattern===false && this.state.isAlreadySharedByYou===false && this.state.madeChangeSavedPattern===false && <div>
                          <Typography className='underskriftTekst' color="text.secondary" sx={{ mb: '12px', mt: '2px' }}>{t('share_dialog.ad_1')}<br></br>{t('share_dialog.ad_2')}</Typography>
                          <Button onClick={this.handleOpenShare} startIcon={<StarRounded/>} size='large' variant="outlined" sx={{width: '284px', margin: 'auto',  transition: 'background-color border 150ms ease-in 0s', marginBottom: '20px', marginTop: '0px' }}>{t('share_dialog.share_title')}</Button>
                          </div>}
                          </div>}
                      </Card>
                    </div>
                    <div style={{paddingBottom: '1px'}}>
                      <Typography className='cardOverskrifter garnOverskrift' gutterBottom variant="h5" component="div">
                      {t('create_page_3.thread_list')}
                      </Typography>
                      <EnhancedTable freeMode={this.props.freeMode} data={this.state.dmcArray} theme={this.props.theme} settings={this.props.settings} dmcSymbols={dmcSymbols} handleChangeSymbols={this.handleChangeSymbols}/>
                    </div>
                  </div>
                  
                  <div className="hovedKnapper no-print stickyHovedKnapper">

                     <Button className='no-print' size='large' variant="outlined" startIcon={this.state.savedPatternMode ? <HomeRounded/> : <ArrowBack />} onClick={ this.state.savedPatternMode ?  this.handleClearToDefault : this.state.wasInAdvancedEdit ? this.handleGoBackToAdvancedEdit : this.handleToggleDonePage} sx={{ transition: 'background-color border 150ms ease-in 0s', margin: '8px', }}>{t('common.back_button')}</Button>
                    <Button className='no-print doneButtonSmall' size='large' variant="contained" startIcon={this.state.loadingCreatePDF ? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important', color: "#fff" }} /> : <CheckCircleRounded />} onClick={this.handleCheckBoughtPatterns} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}> {this.props.freeMode===false ? this.state.loadingCreatePDF ? t('create_page_3.saving_small') : t('create_page_3.save_button_small') : this.state.loadingCreatePDF ? t('create_page_3.loading') : t('create_page_3.save_button_small_free')}</Button>
                    <Button className='no-print doneButtonLarge' size='large' variant="contained" startIcon={this.state.loadingCreatePDF ? <CircularProgress disableShrink width={22} height={22} sx={{ height: '22px!important', width: '22px!important', color: "#fff" }} /> : <CheckCircleRounded />} onClick={this.handleCheckBoughtPatterns} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}> 
                    {
                    this.state.madeChangeSavedPattern===false ?
                    this.props.freeMode===false ? 
                    this.state.savedPatternMode ? 
                    this.state.loadingCreatePDF ? t('create_page_3.loading') : t('create_page_3.print') 
                    : this.state.loadingCreatePDF ? t('create_page_3.saving') : t('create_page_3.save_button') 
                    : this.state.savedPatternMode ? 
                    this.state.loadingCreatePDF ? t('create_page_3.loading') : t('create_page_3.print') 
                    : this.state.loadingCreatePDF ? t('create_page_3.loading') : t('create_page_3.save_button_free')
                  : this.state.loadingCreatePDF ? t('create_page_3.saving') : t('create_page_3.save_button') }
                    </Button> 
                  </div>
                </div>
                <div style={this.state.displayStylePDFPage} >
                  <div className="overskrift no-print" style={{height: '110px'}}>
                    <div>
                      {/*            <Typography gutterBottom variant="h4" component="h1" sx={{m:0}}>Færdig!</Typography> */}
                      <CheckCircleRounded id='doneIcon' sx={{ width: '6rem', height: '6rem', color: 'primary.main' }} />
                    </div>
                    <div>
                      <Typography className='underskriftTekst' color="text.secondary" sx={{ m: 0 }}>{this.state.savedPatternMode ? t('pdf_page.description_1') : this.props.freeMode === false ? t('pdf_page.description_1_saved') :  t('pdf_page.description_1') }</Typography>
                    </div>
                  </div>
                  
                  {this.props.freeMode===false &&
                  <div className='no-print'>
                  <Typography className='underskriftTekst' color="text.secondary" sx={{ mb: 0, mt:2 }}>{t('pdf_page.print_settings')}</Typography>
                  <Select
                              className='selectInputStatic'
                              MenuProps={{ disableScrollLock: true }}
                              size='small'
                              labelId="printsettingsymbol-label"
                              id="printsettingsymbol"
                              /* defaultValue={this.state.selectMeasuringUnit} */
                              value={this.props.settings.printSettings.colorPrint}
                              onChange={this.handleColorPrintModeChangeNewMethod}
                            >
                              <MenuItem value="onlySymbols">{t('pdf_page.setting_1')}</MenuItem>
                              <MenuItem value="symbolsAndColor">{t('pdf_page.setting_2')}</MenuItem>
                              <MenuItem value="onlyColor">{t('pdf_page.setting_3')}</MenuItem>
                            </Select>
                            </div>}
                            <Button className='no-print' size='large' variant="contained" startIcon={<PrintRounded />} onClick={this.handlePrintPattern} sx={{ transition: 'box-shadow 150ms ease-in 0s', marginTop: '18px', marginBottom: '6px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('pdf_page.print_button')}</Button>
                            <div className='no-print'>
                            <Typography className='underskriftTekst' color="text.secondary" sx={{ m: 'auto', mt: '20px', width: '380px' }}><strong>{t('pdf_page.description_3')}</strong></Typography>
                            </div>
                            <div>
                            {this.state.isASharedPattern===false && this.state.isAlreadySharedByYou===false && this.state.noEditOpen && this.props.freeMode!==true &&
                            <div className='no-print' style={{marginTop: '80px'}}>
                              <Typography className='underskriftTekst' color="text.secondary" component='div' sx={{ mb: 0, mt:0 }}><strong>{t('share_dialog.ad_3')}</strong></Typography>
                 {/*  <Typography className='underskriftTekst' color="text.secondary" sx={{ mb: 0, mt:0 }}>{t('share_dialog.ad_4')}<br></br>{t('share_dialog.ad_5')}</Typography> */}
                  <Button onClick={this.handleOpenShare} startIcon={<StarRounded/>} size='large' variant="outlined" sx={{width: '284px', margin: 'auto',  transition: 'background-color border 150ms ease-in 0s', marginBottom: '0px', marginTop: '2px' }}>{t('share_dialog.share_title')}</Button>
                  
                  
                  </div>}
                  </div>   
                  <div style={{ marginTop: '8px' }}>
                    <Button className='no-print' size='large' variant="outlined" startIcon={<ArrowBack />} onClick={this.handleTogglePDFPage} sx={{ transition: 'background-color border 150ms ease-in 0s', margin: '8px', }}>{t('common.back_button')}</Button>
                    <Button className='no-print' size='large' variant="outlined" startIcon={<HomeRounded />} onClick={this.handleClearToDefault} sx={{ transition: 'background-color border 150ms ease-in 0s', margin: '8px', }}>{t('pdf_page.back_to_start')}</Button>
                  </div>
                  
                 
                      
          <Dialog 
          disableScrollLock={true}
                keepMounted
                aria-describedby="alert-dialog-slide-description" 
                open={this.state.openUpgrade} 
                className='no-print'>
          <DialogContent sx={{padding:'0px'}}>
            {/* <Card className='no-print' sx={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);', margin: "auto", maxWidth: "600px", marginTop: "48px"}}>    */}       
             <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{padding: '20px',
          backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[700],}}
        >
          {t('pdf_page.upgrade_title')}
        </Typography> 
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {t('pdf_page.upgrade_description')}

        </Typography>
        <table style={{ margin: '20px', borderCollapse: 'collapse' }}>
                                <tbody>
                                  <tr>
                                    <th style={{border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}></th>
                                    <th style={{width: '90px', height: '70px', backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700], border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_plan_1')}</th>
                                    <th style={{width: '90px', height: '70px', backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}>{t('pdf_page.upgrade_plan_2')}</th>
                                  </tr>
                                  

                                    <tr>
                                      <td style={{padding: '12px',textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_1')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px',textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_2')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{ opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}> <CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_9')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px' }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_8')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px' }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_3')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_4')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white': this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_5')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>


                                    <tr>
                                      <td style={{padding: '12px',textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_6')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_7')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px' }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                </tbody>
                              </table>
                              <Typography
          component="h1"
          variant="h6"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{marginTop: '12px', marginBottom: '-8px'}}
        >
          {/* <strong style={{color: 'var(--primary)'}}>{t('pdf_page.promo')}</strong> */}

        </Typography>
        <div className='upgradeButtons'>
                              <Button onClick={this.handleUpgrade} className='no-print' size='large' variant="contained" sx={{margin: '20px!important',  transition: 'box-shadow 150ms ease-in 0s', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('pdf_page.upgrade_button')}</Button>
                              <Button onClick={this.handleCloseUpgrade} className='no-print' size='large' variant="outlined" sx={{margin: '20px!important',  }}>{t('pdf_page.upgrade_button_no')}</Button>
                              {this.state.goToUpgrade && <Navigate to={'/login'} ></Navigate>}
                              </div>
            
            </DialogContent>
            </Dialog>

           
                  <div id="downloadPDFDiv">
                    <div className='a4Page'>
                      {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <h1 className="korsstingOverskift" style={{marginLeft: "0px!important"}}>MØNSTER</h1>
          <Typography gutterBottom variant="h2" component="h1" sx={{mb: 0, ml:'4px', fontSize: '25px'}}>fabrikken</Typography>
          </div> */}
                      <div id="tableToPrint">
                        <p className='pdfTableCaption'>{t('pdf_page.colors')}</p>
                        {this.state.dmcSymbolTableIsMultiple ?
                          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {this.state.dmcSymbolTable.map((table, id) => (
                              <table key={id} className='pdfTable' style={{ margin: '0px 6px' }}>
                                <tbody>
                                  <tr className='pdfTableRow' id='pdfTableHead'>
                                    <th className='pdfTableRow'>{t('pdf_page.table_dmc')}</th>
                                    {this.props.freeMode === false && <th className='pdfTableRow'>{t('pdf_page.table_symbol')}</th>}
                                    <th className='pdfTableRow'>{t('pdf_page.table_floss')}</th>
                                    <th className='pdfTableRow'>{t('pdf_page.table_stitches')}</th>
                                    <th className='pdfTableRow'>{t('pdf_page.table_color')}</th>
                                  </tr>
                                  {table.map((row, id) => (

                                    <tr key={id} className='pdfTableRow'>
                                      <td className='pdfTableRow'>{row.name}</td>
                                      {this.props.freeMode === false &&  <td className='pdfTableRow'>{<i className="material-icons" style={{ fontSize: '16px' }}>{row.symbol}</i>}</td>}
                                      <td className='pdfTableRow'>{row.dukke}</td>
                                      <td className='pdfTableRow'>{row.sting}</td>
                                      <td className='pdfTableRow' id='pdfTableColor'><div style={{ backgroundColor: row.hexCode, height: '100%', width: '100%' }}></div></td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ))}
                          </div>
                          :
                          <table className='pdfTable'>
                            <tbody>
                              <tr className='pdfTableRowBig' id='pdfTableHead'>
                                <th className='pdfTableRowBig'>{t('pdf_page.table_dmc')}</th>
                                {this.props.freeMode === false &&  <th className='pdfTableRowBig'>{t('pdf_page.table_symbol')}</th>}
                                <th className='pdfTableRowBig'>{t('pdf_page.table_floss')}</th>
                                <th className='pdfTableRowBig'>{t('pdf_page.table_stitches')}</th>
                                <th className='pdfTableRowBig'>{t('pdf_page.table_color')}</th>
                              </tr>
                              {this.state.dmcArray.map((row) => (
                                <tr key={row} className='pdfTableRowBig'>
                                  <td className='pdfTableRowBig'>{row.name}</td>
                                  {this.props.freeMode === false && <td className='pdfTableRowBig'>{<i className="material-icons" style={{ fontSize: '16px' }}>{row.symbol}</i>}</td>}
                                  <td className='pdfTableRowBig'>{row.dukke}</td>
                                  <td className='pdfTableRowBig'>{row.sting}</td>
                                  <td className='pdfTableRowBig' id='pdfTableColor'><div style={{ backgroundColor: row.hexCode, height: '100%', width: '100%' }}></div></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        }
                      </div>
                    </div>
                    <div className='a4Page'>
                      <div style={{ width: '1000px', height: '850px' }}>
                        <div className='mønsterOpdelingWrapper'>
                          <h1 style={{ width: this.state.xCounter > 1 ? (((1 / this.state.cropWidth * 6) * this.state.parentDivSizeX * 100) * this.state.xCounter) : (this.state.yCounter > 1 ? ((1 / this.state.cropHeight * 6) * this.state.parentDivSizeX * 100) : 600) }} className='mønsterOpdelingOverskrift'>{t('pdf_page.pattern_division')}</h1>
                          <div id="patternPdfDiv" style={{ width: 'fit-content' }}>

                          </div>

                          <div id='mønsterOpdeling' className='mønsterOpdeling'>
                            {this.state.a4PagesArray.map((page, i) => (
                              <div key={i} className='mønsterOpdelingA4'>
                                <span className='mønsterOpdelingA4Label'>
                                  {(i + 1) + '/' + this.state.a4PagesArray.length + t('pdf_page.page') + (i + 3) + '/' + (this.state.a4PagesArray.length + 2) + ')'}
                                </span>
                              </div>


                            ))}
                          </div>
                        </div>
                      </div>
                      {/* <canvas className='quantCanvas'></canvas> */}
                    </div>
                    <div id="pdfDiv">

                    </div>
                  </div>

                  <div id='imageParent'>
                  </div>

                </div>
              </div>}
          </div>


          :
          this.state.noEditOpen &&
          <div>
             <Dialog 
          disableScrollLock={true}
                keepMounted
                aria-describedby="alert-dialog-slide-description" 
                open={this.state.openCreateNewBlankCanvas} 
                className='no-print'

                >
                   <DialogTitle /* dividers={true} */ sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', fontWeight: '400'}}><CreateRounded sx={{ mr: 1 }}/>{t("create_page_1.create_title")}</DialogTitle>
                   <Box component="form" noValidate onSubmit={this.handleCreateNewBlankCanvas } >
          <DialogContent sx={{width: '500px',padding:'0px'}} dividers={true} >
            {/* <Card className='no-print' sx={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.2);', margin: "auto", maxWidth: "600px", marginTop: "48px"}}>    */}       
             
        <div className='createCanvasDialogWrapper'>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <TextField
                    size='small'
                    type={'number'}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={this.state.widthInputCreateCanvas}
                    error={this.state.widthInputErrorCreateCanvas}
                    id="widthInputCreateCanvas"
                    label={t('create_page_1.width')}
                    sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);', width: '70px' }}
                    onChange={(e)=>this.setState({widthInputCreateCanvas: e.target.value})}
                  />
                  <Typography  gutterBottom variant="h7" color={"text.secondary"} sx={{ m: 1 }}>x</Typography>
               <TextField
                  size='small'
                  type={'number'}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={this.state.heightInputCreateCanvas}
                  error={this.state.heightInputErrorCreateCanvas}
                  id="heightInputCreateCanvas"
                  label={t('create_page_1.height')}
                  sx={{ borderRadius: '4px', boxShadow: '0px 4px 6px rgb(0 0 0 / 15%);', width: '70px' }}
                  onChange={(e)=>this.setState({heightInputCreateCanvas: e.target.value})}
                />
                
                 
                  </div>
                  <FormControl>
                  <InputLabel id="unit-label-create-canvas">{t('create_page_1.unit')}</InputLabel>
                  <Select
                    className='selectInput'
                    MenuProps={{ disableScrollLock: true }}
                    size='small'
                  labelId="unit-label-create-canvas" 
                    id="unitCreateCanvas"
                    value={this.state.selectMeasuringUnitCreateCanvas} 
                    label={t('create_page_1.unit')}
                    onChange={(e)=>this.setState({selectMeasuringUnitCreateCanvas: e.target.value})}
                    sx={{width: '140px'}}
                  >
                    <MenuItem value={t('common.unit_1_small')}>{t('common.unit_1')}</MenuItem>
                    <MenuItem value={t('common.unit_2_small')}>{t('common.unit_2')}</MenuItem>
                    <MenuItem value={t('common.unit_3_small')}>{t('common.unit_3')}</MenuItem>
                  </Select>
                  </FormControl>
        </div>
       { this.state.selectMeasuringUnitCreateCanvas!==t('common.unit_1_small') && 
       <div className='createCanvasDialogWrapper' style={{marginTop: '-30px'}}>
       <FormControl>
                  <InputLabel id="fabric-label-create-canvas">{t('create_page_1.fabric')}</InputLabel>
                  <Select
                    className='selectInput'
                    MenuProps={{ disableScrollLock: true }}
                    size='small'
                  labelId="fabric-label-create-canvas" 
                    id="fabricCreateCanvas"
                    value={this.state.selectBroderistofCreateCanvas} 
                    label={t('create_page_1.fabric')}
                    onChange={(e)=>this.setState({selectBroderistofCreateCanvas: e.target.value})}
                    sx={{width: '140px'}}
                  >
                    <MenuItem value={5.4}>AIDA 5.4</MenuItem>
                              <MenuItem value={4.4}>AIDA 4.4</MenuItem>
                  </Select>
                  </FormControl>
        </div>}
        </DialogContent>
                      
        <div className='upgradeButtons'>
        <Button disabled={this.state.loadingCreateCanvas} onClick={this.handleCloseCreateNewBlankCanvas} startIcon={<ClearRounded/>} className='no-print' size='large' variant="outlined" sx={{margin: '20px!important',  }}>{t('common.back_button')}</Button>
                              <Button type="submit" disabled={this.state.loadingCreateCanvas} startIcon={this.state.loadingCreateCanvas===false && <CheckRounded/>}  className='no-print' size='large' variant="contained" sx={{width: "127px", margin: '20px!important',  transition: 'box-shadow 150ms ease-in 0s', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>
                              {this.state.loadingCreateCanvas===true ? <CircularProgress size={26.25} sx={{ color: 'white' }} /> : t('create_page_1.create_button')}</Button>
                             
                             
                              </div>
                              </Box>
                             
            
            
            </Dialog>
            <div className="overskrift" id='overskriftDrop'>
              <div>
                <Typography className='overskriftTekst'  gutterBottom variant="h4" component="h1" sx={{ m: 0 }}>{t('create_page_1.title')}</Typography>
              </div>
              <div>
                <Typography className='underskriftTekst' color="text.secondary" sx={{ m: 0 }}>{t('create_page_1.description_1')}{/* <br></br>{t('create_page_1.description_2')} */}</Typography>
              </div>
            </div>
            <div className='startChoice'>
            <input
            accept={acceptedFileTypes}
    style={{ display: 'none' }}
    id="raised-button-file"
    type="file"
    multiple={false}
    onChange={e=>{this.handleOnUploadImageDrop(e);}}
  />
  <label htmlFor="raised-button-file">
  <Button variant="outlined" component="span" sx={{textTransform:'none', transition: 'background-color border 150ms ease-in 0s', margin: '40px', borderRadius: '24px', width: '300px', height: '300px' }}>
  <div className='dropZoneText'><i className="material-icons" style={{ fontSize: '64px' }}>image</i> <br></br>
  <Typography  gutterBottom variant="h7" sx={{ m: 0 }}>{t('create_page_1.choice_1')}</Typography></div>
  </Button>
</label> 

<Typography id='orText' gutterBottom variant="h7" color={"text.secondary"} sx={{ m: 0 }}>{t('common.or')}</Typography>
            <Button id='createBlankCanvasButton' disabled={this.props.freeMode} onClick={this.handleOpenCreateNewBlankCanvas} className='no-print' size='large' variant="outlined" sx={{ textTransform:'none', transition: 'background-color border 150ms ease-in 0s', margin: '40px', borderRadius: '24px', width: '300px', height: '300px' }}>
              <div className='dropZoneText'>
                <CreateRounded sx={{ fontSize: '64px' }}/><br></br>
                <Typography  gutterBottom variant="h7" sx={{ m: 0 }}>{t('create_page_1.choice_2')}</Typography>
                {this.props.freeMode===true && 
              <Typography  gutterBottom variant="h7" component={'div'} sx={{ m: 0 }}>{t('create_page_1.choice_2_free')}</Typography>}
              </div></Button>
            </div>
            { this.props.freeMode===true &&
            <div style={{maxWidth: '700px', margin: 'auto'}}>
              <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{marginBottom: '50px'}}
        >
          {t('welcome.title_2')}
        </Typography>
<table style={{ margin: '20px', borderCollapse: 'collapse' }}>
                                <tbody>
                                  <tr>
                                    <th style={{border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}></th>
                                    <th style={{width: '90px', height: '70px', backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700], border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_plan_1')}</th>
                                    <th style={{width: '90px', height: '70px', backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}>{t('pdf_page.upgrade_plan_2')}</th>
                                  </tr>
                                  

                                    <tr>
                                      <td style={{padding: '12px',textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_1')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px',textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_2')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{ opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}> <CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_9')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px' }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_8')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px' }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_3')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_4')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white': this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_5')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>


                                    <tr>
                                      <td style={{padding: '12px',textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_6')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px'  }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '12px', textAlign: 'left', border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}>{t('pdf_page.upgrade_benefit_7')}</td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? this.props.theme.palette.grey[100] : this.props.theme.palette.grey[700],border: this.props.theme.palette.mode === 'light' ? '1px solid rgba(0, 0, 0, 0.27)' : '1px solid rgba(255, 255, 255, 0.27)'}}><CancelRounded id='doneIcon' sx={{opacity: '0.4', width: '2rem', height: '2rem', marginLeft: '24px' }}/></td>
                                      <td className='tableVersionsIcons' style={{backgroundColor: this.props.theme.palette.mode === 'light' ? 'white' : this.props.theme.palette.grey[500], border: this.props.theme.palette.mode === 'light' ? '3px solid var(--primary)' : '2px solid rgba(255, 255, 255, 0.87)'}}><CheckCircleRounded id='doneIcon' sx={{ width: '2rem', height: '2rem', color: 'primary.main', marginLeft: '24px'  }}/></td>
                                    </tr>
                                </tbody>
                              </table>
                              {this.state.goToUpgrade && <Navigate to={'/login'} ></Navigate>}
                              <Button onClick={this.handleUpgrade} className='no-print' size='large' variant="contained" sx={{margin: '20px!important',  transition: 'box-shadow 150ms ease-in 0s', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>{t('pdf_page.upgrade_button')}</Button>
                              </div>

            }
            {this.props.savedPatterns.length!==0 && this.props.freeMode===false ?
                <div className='savedPatternsDropWrapper'>
                <Typography variant='h5' color="text.secondary" sx={{ m: 0 }}>{this.props.savedPatterns.length<=1 ? t('create_page_1.saved_title_2') : t('create_page_1.saved_title_1')}<Chip label={t('create_page_1.saved_button')} sx={{ml: '12px'}} onClick={this.handleOpenSavedPatterns}/></Typography>
                {/* <Typography className='underskriftTekst' color="text.secondary" sx={{ m: 0 }}>Her kan du hurtigt finde og printe dine senest gemte mønstre</Typography> */}
                <div className={this.props.savedPatterns.length>2 ? 'savedPatternsDropItemWrapper3' : 'savedPatternsDropItemWrapper'} style={{width: this.props.savedPatterns.length>2 ? '740px' : '450px'}}>
                {this.props.savedPatterns.map((pattern, i) => (
                  <div key={i} className={i===2 ? 'removeThirdOnMobile' : 'noClass'}>
                  <Card className='savedPatternsDropItem' sx={{position: 'relative'}}>
                  {pattern.shared && <Tooltip arrow title={t('common.downloaded_catalog')}><IconButton sx={{ cursor: 'default', position: 'absolute', zIndex: '1', padding: '6px', m: '0px', background: 'rgba(0,0,0,30%)',bottom: '6px', right: '6px', color: 'white', '&:hover': {background: 'rgba(0,0,0,40%)'} }}><DownloadRounded/>  </IconButton></Tooltip>}
                    <div className='savedPatternsDropImageDiv'>
                     
                    <div onClick={()=>{this.handleOpenSavedPatternPreviewFromDropNewNew(i)}} className={this.props.theme.palette.mode === 'dark' ? 'savedPatternsDropPointerDiv savedPatternsDropPointerDivBlack': 'savedPatternsDropPointerDiv savedPatternsDropPointerDivWhite'}>
                      {/* <p>Åben</p> */}
                    </div>
                    <img style={{width: 'inherit', imageRendering: 'pixelated', width: 'inherit', height: 'inherit', objectFit: 'contain'}} src={pattern.photoUrl}></img>
                    
                    </div>
                    
                  </Card>
                  <Typography className='underskriftTekst' color="text.secondary" sx={{ mt: '12px' }}>{(pattern.created!==0 ? (pattern.created + (pattern.created===1 ? t('create_page_1.saved_time_1') : t('create_page_1.saved_time_2'))) : t('create_page_1.saved_time_3'))}</Typography>
                  </div>
                ))}
                </div>
{/*                 <Button className='no-print' size='large' variant="contained" startIcon={<SaveRounded />} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>
                  Vis alle</Button> */}
                </div>
                  :     <div>

                                </div>}
                                {this.props.freeMode===false &&
                                <Button
          startIcon={<ImportContactsRounded/>}
            /* size='small' */
            variant="outlined"
             onClick={this.handleGoToCatalog}  
            sx={{fontSize: '.8rem', padding: '8px 13px', textTransform: 'none' }}
            className='no-flickr'
          >
           {t('create_page_1.catalog')}
          </Button>}
          {this.state.goToCatalog && <Navigate to={'/catalog'} ></Navigate>}
           {/*  <div className='dropWrapper'>
            <Dropzone className={this.props.theme.palette.mode === 'dark' ? 'dropClass' : 'dropClassLight'} style={baseStyle}
              acceptStyle={acceptStyle} activeStyle={activeStyle} focusedstyle={focusedStyle} rejectStyle={rejectStyle} onDrop={e => { this.handleOnDrop(e); }} accept={acceptedFileTypes} multiple={false} maxSize={imageMaxSize}>
              <div className='dropZoneText'><i className="material-icons" style={{ fontSize: '64px' }}>image</i> <br></br>
              {t('create_page_1.image_drop_text')}</div> </Dropzone>
                {this.props.savedPatterns.length!==0 ?
                <div className='savedPatternsDropWrapper'>
                <Typography variant='h5' color="text.secondary" sx={{ m: 0 }}>{this.props.savedPatterns.length<=1 ? t('create_page_1.saved_title_2') : t('create_page_1.saved_title_1')}<Chip label={t('create_page_1.saved_button')} sx={{ml: '12px'}} onClick={this.handleOpenSavedPatterns}/></Typography>
                {/* <Typography className='underskriftTekst' color="text.secondary" sx={{ m: 0 }}>Her kan du hurtigt finde og printe dine senest gemte mønstre</Typography> */}
               {/*  <div className={this.props.savedPatterns.length>2 ? 'savedPatternsDropItemWrapper3' : 'savedPatternsDropItemWrapper'} style={{width: this.props.savedPatterns.length>2 ? '740px' : '450px'}}>
                {this.props.savedPatterns.map((pattern, i) => (
                  <div key={i} className={i===2 ? 'removeThirdOnMobile' : 'noClass'}>
                  <Card className='savedPatternsDropItem' sx={{position: 'relative'}}>
                  {pattern.shared && <Tooltip arrow title="Downloaded from the catalog"><IconButton sx={{ cursor: 'default', position: 'absolute', zIndex: '1', padding: '6px', m: '0px', background: 'rgba(0,0,0,30%)',bottom: '6px', right: '6px', color: 'white', '&:hover': {background: 'rgba(0,0,0,40%)'} }}><DownloadRounded/>  </IconButton></Tooltip>}
                    <div className='savedPatternsDropImageDiv'>
                     
                    <div onClick={()=>{this.handleOpenSavedPatternPreviewFromDropNewNew(i)}} className={this.props.theme.palette.mode === 'dark' ? 'savedPatternsDropPointerDiv savedPatternsDropPointerDivBlack': 'savedPatternsDropPointerDiv savedPatternsDropPointerDivWhite'}>
                      {/* <p>Åben</p> */}
                   {/*  </div>
                    <img style={{width: 'inherit', imageRendering: 'pixelated', width: 'inherit', height: 'inherit', objectFit: 'contain'}} src={pattern.photoUrl}></img>
                    
                    </div>
                    
                  </Card>
                  <Typography className='underskriftTekst' color="text.secondary" sx={{ mt: '12px' }}>{(pattern.created!==0 ? (pattern.created + (pattern.created===1 ? t('create_page_1.saved_time_1') : t('create_page_1.saved_time_2'))) : t('create_page_1.saved_time_3'))}</Typography>
                  </div>
                ))}
                </div> */} 
{/*                 <Button className='no-print' size='large' variant="contained" startIcon={<SaveRounded />} sx={{ transition: 'box-shadow 150ms ease-in 0s', margin: '8px', backgroundImage: "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))", boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(233 30 99 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.0625rem 0.3125rem 0rem;', '&:hover': { boxShadow: 'rgb(233 30 99 / 15%) 0rem 0.3rem 0.5rem 0rem, rgb(233 30 99 / 20%) 0rem 0.3rem 0.5rem -0.125rem, rgb(233 30 99 / 15%) 0rem 0.1rem 2rem 0rem;' } }}>
                  Vis alle</Button> */}
                {/* </div>
                  :     <div>

                                </div>}
                </div>  */}
          </div>
        }
        </div>
}
        {/* <img id="my-image" onClick={this.countColors} src={require('../images/redpanda.jpg')} />
<div id="colorstat"></div> */}

      </div>


    )
  }
}

export default withTranslation('translation')(ImgDropAndCrop)
