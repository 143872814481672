import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserAuth } from '../UserAuthContext'
import { useEffect } from 'react';
import { useState } from 'react';


const ProtectedRoute = ({ children }) => {
    const {user} = useUserAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [numberOfTimesUserUpdated, setUserUpdated] = useState(0);
    const [returnValue, setReturnValue] = useState(<p></p>);
  useEffect(() =>{
    if(user)
    {
      setIsLoggedIn(true);
    } else if(user===null) {
        setReturnValue(<Navigate to="/home"/>)
    }
    if(numberOfTimesUserUpdated>=2){
        setReturnValue(<Navigate to="/home"/>)
    }
    var num = numberOfTimesUserUpdated + 1;
    setUserUpdated(num);
    
  },[user]);

    if(!isLoggedIn) {
        return returnValue;
    }
/*     if(!isSubscribedConst)
    {
        return <Navigate to="/welcome"/>;
    } */
    return children;
};

export default ProtectedRoute;